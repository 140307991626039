@font-face {
    font-family: 'Kentledge';
    src: url('../fonts/Kentledge-Regular.woff2') format('woff2'),
        url('../fonts/Kentledge-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kentledge-Heavy';
    src: url('../fonts/kentledge-heavy.woff2') format('woff2'),
        url('../fonts/kentledge-heavy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kentledge-Bold';
    src: url('../fonts/kentledge-bold.woff2') format('woff2'),
        url('../fonts/kentledge-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

textarea:focus, input:focus{
    outline: none;
}

::-webkit-scrollbar {display:none;}

html{
	min-height: 100vh;
	overscroll-behavior: none;
	background: #eaeaea;
	scroll-behavior: smooth;
}

body{
	font-family: 'Kentledge';
	padding: 0;
	margin: 0;
	background: #eaeaea;
	min-height: 100vh;
}

p{
	font-family: 'Kentledge';
	font-size: 16px;
	line-height: 23px;
	margin: 0;
	padding: 0;
}

h1{
	font-family: 'Kentledge';
	color: #000;
	font-size: 45px;
	line-height: 50px;
	letter-spacing: -1px;
	font-weight: 100;
}

h2{
	color: #000;
	font-size: 22px;	
	font-weight: 200;
}

h3{
	color: #000;
	font-size: 19px;	
	font-weight: 200;
}

h4{
	font-family: 'Kentledge';
	font-size: 36px; 
	color: #000;
	margin: 0px;
	letter-spacing: -1px;
	font-weight: 100;
}

ul{
	padding-left: 20px;
}

li{
	padding: 15px;
}

.app{
	position: relative;
	padding: 0;
	margin: 0 auto;
	min-height: 100vh;
	width: 100%;
	overflow-x: hidden;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.eventlist{
	width: 100%;
	max-width: 350px;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	position: relative;
	padding: 20px;
	background: #f7f7f7;
}

.event{
	width: 100%;
	min-height: 100vh;
	max-width: 390px;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	position: relative;
	background: #f7f7f7;
}

.logo{
	width: 175px;
}

/* ------------------------------ Eventlist -----------------------------*/

.eventItem{
	position: relative;
	width: 100%;
	padding-bottom: 20px;
	border-radius: 7px;
	cursor: pointer;
	background-color: #fff;
	-webkit-box-shadow: 10px 10px 28px -13px rgba(0,0,0,0.18);
	-moz-box-shadow: 10px 10px 28px -13px rgba(0,0,0,0.18);
	box-shadow: 10px 10px 28px -13px rgba(0,0,0,0.18);
}

.searchBarContainer{
	display: flex;
	flex-direction: row; 
	align-items: center;
	background-color: #fff; 
	min-width: 100%; 
	border-radius: 10px;
	padding: 0px;
	-webkit-box-shadow: 10px 10px 28px -13px rgba(0,0,0,0.18);
	-moz-box-shadow: 10px 10px 28px -13px rgba(0,0,0,0.18);
	box-shadow: 10px 10px 28px -13px rgba(0,0,0,0.18);
}

.searchBar{
	color: rgba(0,0,0,0.5);
	padding: 15px; 
	margin: 0px; 
	font-family: 'Kentledge'; 
	font-size: 14px;
	border-width: 0px;
	width: 100%;
	background: none;
}


/* ------------------------------ Event -----------------------------*/

.contentContainer{
	width: '100%'; 
	padding-left: 20px; 
	padding-right: 20px;
	margin-top: -48px;
	padding-bottom: 50px;
}

.titleContainer{ 
	position: relative;
	z-index: 100000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 12px;
	background-color: #f7f7f7; 
	padding: 15px 25px 19px 25px;
	-webkit-box-shadow: 3px 3px 20px -3px rgba(0,0,0,0.12);
	-moz-box-shadow: 3px 3px 20px -3px rgba(0,0,0,0.12);
	box-shadow: 3px 3px 20px -3px rgba(0,0,0,0.12);
}

.titleContainer p{
	color: #22293b;
}

.topTitle{
	padding: 15px;
	margin-top: 12px; 
	display: 'flex';
	flex-direction: 'column';
	align-items: 'center';

}

.titleDivider {
	width: 100%;
	height: 1px;
	margin-top: 12px;
	margin-bottom: 17px;
	background-image: repeating-linear-gradient(90deg, rgba(0, 0, 0, 0.09) 0px, rgba(0, 0, 0, 0.09) 2px, transparent 2px, transparent 4px);
}

.backbutton{
	position: absolute; 
	top: 17px;
	left: 17px;
	display: flex;
	width: 32px;
	height: 32px;
	border-radius: 16px;
	background-color: #f1f2f4;
	justify-content: center; 
	align-items: center;
	cursor: pointer;
	-webkit-box-shadow: 10px 10px 28px -13px rgba(0,0,0,0.18);
	-moz-box-shadow: 10px 10px 28px -13px rgba(0,0,0,0.18);
	box-shadow: 10px 10px 28px -13px rgba(0,0,0,0.18);
}

.saldo{
	position: relative;
	padding-top: 25px;
	padding-bottom: 10px;
	border-radius: 12px;
	padding-left: 25px;
	padding-right: 25px;
	-webkit-box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.25);
	-moz-box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.25);
	box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.25);
}

.btn_container{
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;
	padding: 22px;
	border-radius: 12px;
	overflow: hidden;
	background-color: #fcfcfc; 
	text-decoration: none;
	-webkit-box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.05);
	-moz-box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.05);
	box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.05);
}

.announcement_container{
	position: relative;
	display: flex;
	flex-direction: column;
	margin-top: 15px;
	padding: 30px;
	border-radius: 12px;
	overflow: hidden;
	background-color: #fcfcfc; 
	-webkit-box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.05);
	-moz-box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.05);
	box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.05);
}

.announcement_container a{
	text-decoration: none;
	color: #fff;
}

.announcement_container p{
	white-space: pre-line;
}


.rotating{
	animation: rotate 2s linear infinite; 
}
@keyframes rotate{
	to{ transform: rotate(360deg); }
}

.transaction{
	display: flex; 
	flex-direction: column;
	align-items: center;
	justify-content: center; 
	margin-top: 20px;
	padding: 20px 16px;
	background-color: #fcfcfc; 
	border-radius: 10px;
	-webkit-box-shadow: 10px 10px 22px -10px rgba(0,0,0,0.08);
	-moz-box-shadow: 10px 10px 22px -10px rgba(0,0,0,0.08);
	box-shadow: 10px 10px 22px -10px rgba(0,0,0,0.08);
}

/* ------------------------------ LOCKERS -----------------------------*/

.lockerContainer{
	margin-top: 30px;
	width: '100%';
	align-items: center; 
	justify-content: center;
	border-radius: 12px; 
	-webkit-box-shadow: 3px 3px 20px -3px rgba(0,0,0,0.10);
	-moz-box-shadow: 3px 3px 20px -3px rgba(0,0,0,0.10);
	box-shadow: 3px 3px 20px -3px rgba(0,0,0,0.10);
}

.newLockerContainer{
	width: '100%';
	padding: 25px 25px 40px 25px;
	display: flex;
	flex-direction: column;
	align-items: center; 
	justify-content: center;
	background-color: #b32e5d;
	border-radius: 12px;
	margin-top: 20px;
	-webkit-box-shadow: 3px 3px 20px -3px rgba(0,0,0,0.10);
	-moz-box-shadow: 3px 3px 20px -3px rgba(0,0,0,0.10);
	box-shadow: 3px 3px 20px -3px rgba(0,0,0,0.10);
}

/* ------------------------------ MODAL -----------------------------*/

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
  }
  
  .modal-content {
	background: white;
	padding: 20px;
	border-radius: 8px;
	width: 300px;
	text-align: center;
	position: relative;
  }

/* ------------------------------ FOOTER -----------------------------*/

.footer{
	background-color: #000;
	max-width: 360px;
	width: 100%;
	height: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 20px;
	align-items: center;
}

.conditionContainer{
	max-width: 200px;
	display: flex;
}

.conditionContainer h4, .footer p{
	font-size: 11px;
	color: rgba(255,255,255,0.8);
}

.conditionContainer a{
	text-decoration: none;
}

/* ------------------------------ MOBILE -----------------------------*/

@media only screen and (max-width: 768px) {


h1{
	font-family: 'Kentledge';
	color: #000;
	font-size: 30px;
	line-height: 32px;
	letter-spacing: -1px;
}

h2{
	color: #000;
	font-size: 20px;	
	font-weight: 200;
}

h3{
	color: #000;
	font-size: 19px;	
	font-weight: 200;
}

h4{
	font-family: 'Kentledge';
	font-size: 36px; 
	color: #000;
	margin: 0px;
}

.mobileHide{
	display: none;
}


}


/* ------------------------------ DESKTOP -----------------------------*/

@media only screen and (min-width: 768px) {
	
.desktopHide{
	display: none;
}
	
}

/* ------------------------------ mobile menu settings -----------------------------*/


/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 29px;
  height: 23px;
  right: 8px;
  top: 38px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #000;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 1.5em 0em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #000;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding-top: 40px;
  outline: none;
}

.bm-item:focus{
	outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: none !important;
}
