/*!
 * Font Awesome Pro 6.0.0-beta1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa {
  font-family: "Font Awesome 6 Pro";
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: 900;
  font-weight: var(--fa-style, 900); }

.fa,
.fas,
.fa-solid,
.far,
.fa-regular,
.fal,
.fa-light,
.fat,
.fa-thin,
.fad,
.fa-duotone,
.fab,
.fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(2em * -1);
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: 2em;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: #eee;
  border-color: var(--fa-border-color, #eee);
  border-radius: 0.1em;
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: solid;
  border-style: var(--fa-border-style, solid);
  border-width: 0.08em;
  border-width: var(--fa-border-width, 0.08em);
  padding: 0.2em 0.25em 0.15em;
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: 0.3em;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: 0.3em;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  -webkit-animation-name: fa-beat;
          animation-name: fa-beat;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
          animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-fade {
  -webkit-animation-name: fa-fade;
          animation-name: fa-fade;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
          animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
          animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flash {
  -webkit-animation-name: fa-flash;
          animation-name: fa-flash;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
          animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
          animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1));
          animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  -webkit-animation-name: fa-flip;
          animation-name: fa-flip;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
          animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
          animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-spin {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-delay: 0;
          animation-delay: 0;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
          animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
          animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
          animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  -webkit-animation-name: fa-spin;
          animation-name: fa-spin;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
          animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
          animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
          animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: steps(8);
          animation-timing-function: steps(8);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
          animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-fade,
  .fa-flash,
  .fa-flip,
  .fa-pulse,
  .fa-spin,
  .fa-spin-pulse {
    -webkit-animation-delay: -1ms;
            animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
            animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-duration: 0s;
            transition-duration: 0s; } }

@-webkit-keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  45% {
    -webkit-transform: scale(1.25);
            transform: scale(1.25);
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
            transform: scale(var(--fa-beat-scale, 1.25)); } }

@-webkit-keyframes fa-fade {
  50% {
    opacity: 0.4;
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-fade {
  50% {
    opacity: 0.4;
    opacity: var(--fa-fade-opacity, 0.4); } }

@-webkit-keyframes fa-flash {
  0%, 100% {
    opacity: 0.4;
    opacity: var(--fa-flash-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.125);
            transform: scale(1.125);
    -webkit-transform: scale(var(--fa-flash-scale, 1.125));
            transform: scale(var(--fa-flash-scale, 1.125)); } }

@keyframes fa-flash {
  0%, 100% {
    opacity: 0.4;
    opacity: var(--fa-flash-opacity, 0.4);
    -webkit-transform: scale(1);
            transform: scale(1); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.125);
            transform: scale(1.125);
    -webkit-transform: scale(var(--fa-flash-scale, 1.125));
            transform: scale(var(--fa-flash-scale, 1.125)); } }

@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -180deg);
            transform: rotate3d(0, 1, 0, -180deg);
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(0, 1, 0, -180deg);
            transform: rotate3d(0, 1, 0, -180deg);
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
            transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg); }

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1); }

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1); }

.fa-rotate-by {
  -webkit-transform: rotate(none);
          transform: rotate(none);
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
          transform: rotate(var(--fa-rotate-angle, none)); }

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: auto;
  z-index: var(--fa-stack-z-index, auto); }

.fa-stack-1x {
  line-height: inherit; }

.fa-stack-2x {
  font-size: 2em; }

.fa-inverse {
  color: #fff;
  color: var(--fa-inverse, #fff); }

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-0::before {
  content: "\30"; }

.fa-1::before {
  content: "\31"; }

.fa-2::before {
  content: "\32"; }

.fa-3::before {
  content: "\33"; }

.fa-4::before {
  content: "\34"; }

.fa-5::before {
  content: "\35"; }

.fa-6::before {
  content: "\36"; }

.fa-7::before {
  content: "\37"; }

.fa-8::before {
  content: "\38"; }

.fa-9::before {
  content: "\39"; }

.fa-360-degrees::before {
  content: "\e2dc"; }

.fa-a::before {
  content: "\41"; }

.fa-abacus::before {
  content: "\1f9ee"; }

.fa-accent-grave::before {
  content: "\60"; }

.fa-acorn::before {
  content: "\f6ae"; }

.fa-address-book::before {
  content: "\f2b9"; }

.fa-contact-book::before {
  content: "\f2b9"; }

.fa-address-card::before {
  content: "\f2bb"; }

.fa-contact-card::before {
  content: "\f2bb"; }

.fa-vcard::before {
  content: "\f2bb"; }

.fa-air-conditioner::before {
  content: "\f8f4"; }

.fa-airplay::before {
  content: "\e089"; }

.fa-alarm-clock::before {
  content: "\23f0"; }

.fa-alarm-exclamation::before {
  content: "\f843"; }

.fa-alarm-plus::before {
  content: "\f844"; }

.fa-alarm-snooze::before {
  content: "\f845"; }

.fa-album::before {
  content: "\1f4bd"; }

.fa-album-collection::before {
  content: "\f8a0"; }

.fa-alicorn::before {
  content: "\f6b0"; }

.fa-alien::before {
  content: "\1f47d"; }

.fa-alien-8bit::before {
  content: "\1f47e"; }

.fa-alien-monster::before {
  content: "\1f47e"; }

.fa-align-center::before {
  content: "\f037"; }

.fa-align-justify::before {
  content: "\f039"; }

.fa-align-left::before {
  content: "\f036"; }

.fa-align-right::before {
  content: "\f038"; }

.fa-align-slash::before {
  content: "\f846"; }

.fa-alt::before {
  content: "\e08a"; }

.fa-amp-guitar::before {
  content: "\f8a1"; }

.fa-ampersand::before {
  content: "\26"; }

.fa-anchor::before {
  content: "\2693"; }

.fa-angel::before {
  content: "\f779"; }

.fa-angle::before {
  content: "\e08c"; }

.fa-angle-90::before {
  content: "\e08d"; }

.fa-angle-down::before {
  content: "\2304"; }

.fa-angle-left::before {
  content: "\2039"; }

.fa-angle-right::before {
  content: "\203a"; }

.fa-angle-up::before {
  content: "\2303"; }

.fa-angles-down::before {
  content: "\f103"; }

.fa-angle-double-down::before {
  content: "\f103"; }

.fa-angles-left::before {
  content: "\ab"; }

.fa-angle-double-left::before {
  content: "\ab"; }

.fa-angles-right::before {
  content: "\bb"; }

.fa-angle-double-right::before {
  content: "\bb"; }

.fa-angles-up::before {
  content: "\f102"; }

.fa-angle-double-up::before {
  content: "\f102"; }

.fa-ankh::before {
  content: "\2625"; }

.fa-aperture::before {
  content: "\e2df"; }

.fa-apostrophe::before {
  content: "\27"; }

.fa-apple-core::before {
  content: "\e08f"; }

.fa-apple-whole::before {
  content: "\1f34e"; }

.fa-apple-alt::before {
  content: "\1f34e"; }

.fa-archway::before {
  content: "\f557"; }

.fa-arrow-down::before {
  content: "\2193"; }

.fa-arrow-down-1-9::before {
  content: "\f162"; }

.fa-sort-numeric-asc::before {
  content: "\f162"; }

.fa-sort-numeric-down::before {
  content: "\f162"; }

.fa-arrow-down-9-1::before {
  content: "\f886"; }

.fa-sort-numeric-desc::before {
  content: "\f886"; }

.fa-sort-numeric-down-alt::before {
  content: "\f886"; }

.fa-arrow-down-a-z::before {
  content: "\f15d"; }

.fa-sort-alpha-asc::before {
  content: "\f15d"; }

.fa-sort-alpha-down::before {
  content: "\f15d"; }

.fa-arrow-down-arrow-up::before {
  content: "\f883"; }

.fa-sort-alt::before {
  content: "\f883"; }

.fa-arrow-down-big-small::before {
  content: "\f88c"; }

.fa-sort-size-down::before {
  content: "\f88c"; }

.fa-arrow-down-from-dotted-line::before {
  content: "\e090"; }

.fa-arrow-down-from-line::before {
  content: "\21a7"; }

.fa-arrow-from-top::before {
  content: "\21a7"; }

.fa-arrow-down-left::before {
  content: "\e091"; }

.fa-arrow-down-left-and-arrow-up-right-to-center::before {
  content: "\e092"; }

.fa-arrow-down-long::before {
  content: "\f175"; }

.fa-long-arrow-down::before {
  content: "\f175"; }

.fa-arrow-down-right::before {
  content: "\e093"; }

.fa-arrow-down-short-wide::before {
  content: "\f884"; }

.fa-sort-amount-desc::before {
  content: "\f884"; }

.fa-sort-amount-down-alt::before {
  content: "\f884"; }

.fa-arrow-down-small-big::before {
  content: "\f88d"; }

.fa-sort-size-down-alt::before {
  content: "\f88d"; }

.fa-arrow-down-square-triangle::before {
  content: "\f889"; }

.fa-sort-shapes-down-alt::before {
  content: "\f889"; }

.fa-arrow-down-to-bracket::before {
  content: "\e094"; }

.fa-arrow-down-to-dotted-line::before {
  content: "\e095"; }

.fa-arrow-down-to-line::before {
  content: "\f33d"; }

.fa-arrow-to-bottom::before {
  content: "\f33d"; }

.fa-arrow-down-to-square::before {
  content: "\e096"; }

.fa-arrow-down-triangle-square::before {
  content: "\f888"; }

.fa-sort-shapes-down::before {
  content: "\f888"; }

.fa-arrow-down-wide-short::before {
  content: "\f160"; }

.fa-sort-amount-asc::before {
  content: "\f160"; }

.fa-sort-amount-down::before {
  content: "\f160"; }

.fa-arrow-down-z-a::before {
  content: "\f881"; }

.fa-sort-alpha-desc::before {
  content: "\f881"; }

.fa-sort-alpha-down-alt::before {
  content: "\f881"; }

.fa-arrow-left::before {
  content: "\2190"; }

.fa-arrow-left-from-line::before {
  content: "\21a4"; }

.fa-arrow-from-right::before {
  content: "\21a4"; }

.fa-arrow-left-long::before {
  content: "\f177"; }

.fa-long-arrow-left::before {
  content: "\f177"; }

.fa-arrow-left-to-line::before {
  content: "\21e4"; }

.fa-arrow-to-left::before {
  content: "\21e4"; }

.fa-arrow-pointer::before {
  content: "\f245"; }

.fa-mouse-pointer::before {
  content: "\f245"; }

.fa-arrow-right::before {
  content: "\2192"; }

.fa-arrow-right-arrow-left::before {
  content: "\21c4"; }

.fa-exchange::before {
  content: "\21c4"; }

.fa-arrow-right-from-bracket::before {
  content: "\f08b"; }

.fa-sign-out::before {
  content: "\f08b"; }

.fa-arrow-right-from-line::before {
  content: "\21a6"; }

.fa-arrow-from-left::before {
  content: "\21a6"; }

.fa-arrow-right-long::before {
  content: "\f178"; }

.fa-long-arrow-right::before {
  content: "\f178"; }

.fa-arrow-right-to-bracket::before {
  content: "\f090"; }

.fa-sign-in::before {
  content: "\f090"; }

.fa-arrow-right-to-line::before {
  content: "\21e5"; }

.fa-arrow-to-right::before {
  content: "\21e5"; }

.fa-arrow-rotate-left::before {
  content: "\21ba"; }

.fa-arrow-left-rotate::before {
  content: "\21ba"; }

.fa-arrow-rotate-back::before {
  content: "\21ba"; }

.fa-arrow-rotate-backward::before {
  content: "\21ba"; }

.fa-undo::before {
  content: "\21ba"; }

.fa-arrow-rotate-right::before {
  content: "\21bb"; }

.fa-arrow-right-rotate::before {
  content: "\21bb"; }

.fa-arrow-rotate-forward::before {
  content: "\21bb"; }

.fa-redo::before {
  content: "\21bb"; }

.fa-arrow-trend-down::before {
  content: "\e097"; }

.fa-arrow-trend-up::before {
  content: "\e098"; }

.fa-arrow-turn-down::before {
  content: "\f149"; }

.fa-level-down::before {
  content: "\f149"; }

.fa-arrow-turn-down-left::before {
  content: "\e2e1"; }

.fa-arrow-turn-up::before {
  content: "\f148"; }

.fa-level-up::before {
  content: "\f148"; }

.fa-arrow-up::before {
  content: "\2191"; }

.fa-arrow-up-1-9::before {
  content: "\f163"; }

.fa-sort-numeric-up::before {
  content: "\f163"; }

.fa-arrow-up-9-1::before {
  content: "\f887"; }

.fa-sort-numeric-up-alt::before {
  content: "\f887"; }

.fa-arrow-up-a-z::before {
  content: "\f15e"; }

.fa-sort-alpha-up::before {
  content: "\f15e"; }

.fa-arrow-up-arrow-down::before {
  content: "\e099"; }

.fa-sort-up-down::before {
  content: "\e099"; }

.fa-arrow-up-big-small::before {
  content: "\f88e"; }

.fa-sort-size-up::before {
  content: "\f88e"; }

.fa-arrow-up-from-bracket::before {
  content: "\e09a"; }

.fa-arrow-up-from-dotted-line::before {
  content: "\e09b"; }

.fa-arrow-up-from-line::before {
  content: "\21a5"; }

.fa-arrow-from-bottom::before {
  content: "\21a5"; }

.fa-arrow-up-from-square::before {
  content: "\e09c"; }

.fa-arrow-up-left::before {
  content: "\e09d"; }

.fa-arrow-up-left-from-circle::before {
  content: "\e09e"; }

.fa-arrow-up-long::before {
  content: "\f176"; }

.fa-long-arrow-up::before {
  content: "\f176"; }

.fa-arrow-up-right::before {
  content: "\e09f"; }

.fa-arrow-up-right-and-arrow-down-left-from-center::before {
  content: "\e0a0"; }

.fa-arrow-up-right-from-square::before {
  content: "\f08e"; }

.fa-external-link::before {
  content: "\f08e"; }

.fa-arrow-up-short-wide::before {
  content: "\f885"; }

.fa-sort-amount-up-alt::before {
  content: "\f885"; }

.fa-arrow-up-small-big::before {
  content: "\f88f"; }

.fa-sort-size-up-alt::before {
  content: "\f88f"; }

.fa-arrow-up-square-triangle::before {
  content: "\f88b"; }

.fa-sort-shapes-up-alt::before {
  content: "\f88b"; }

.fa-arrow-up-to-dotted-line::before {
  content: "\e0a1"; }

.fa-arrow-up-to-line::before {
  content: "\f341"; }

.fa-arrow-to-top::before {
  content: "\f341"; }

.fa-arrow-up-triangle-square::before {
  content: "\f88a"; }

.fa-sort-shapes-up::before {
  content: "\f88a"; }

.fa-arrow-up-wide-short::before {
  content: "\f161"; }

.fa-sort-amount-up::before {
  content: "\f161"; }

.fa-arrow-up-z-a::before {
  content: "\f882"; }

.fa-sort-alpha-up-alt::before {
  content: "\f882"; }

.fa-arrows-cross::before {
  content: "\e0a2"; }

.fa-arrows-from-dotted-line::before {
  content: "\e0a3"; }

.fa-arrows-from-line::before {
  content: "\e0a4"; }

.fa-arrows-left-right::before {
  content: "\f07e"; }

.fa-arrows-h::before {
  content: "\f07e"; }

.fa-arrows-maximize::before {
  content: "\f31d"; }

.fa-expand-arrows::before {
  content: "\f31d"; }

.fa-arrows-minimize::before {
  content: "\e0a5"; }

.fa-compress-arrows::before {
  content: "\e0a5"; }

.fa-arrows-repeat::before {
  content: "\f364"; }

.fa-repeat-alt::before {
  content: "\f364"; }

.fa-arrows-repeat-1::before {
  content: "\f366"; }

.fa-repeat-1-alt::before {
  content: "\f366"; }

.fa-arrows-retweet::before {
  content: "\f361"; }

.fa-retweet-alt::before {
  content: "\f361"; }

.fa-arrows-rotate::before {
  content: "\1f5d8"; }

.fa-refresh::before {
  content: "\1f5d8"; }

.fa-sync::before {
  content: "\1f5d8"; }

.fa-arrows-to-dotted-line::before {
  content: "\e0a6"; }

.fa-arrows-to-line::before {
  content: "\e0a7"; }

.fa-arrows-up-down::before {
  content: "\f07d"; }

.fa-arrows-v::before {
  content: "\f07d"; }

.fa-arrows-up-down-left-right::before {
  content: "\f047"; }

.fa-arrows::before {
  content: "\f047"; }

.fa-asterisk::before {
  content: "\2a"; }

.fa-at::before {
  content: "\40"; }

.fa-atom::before {
  content: "\269b"; }

.fa-atom-simple::before {
  content: "\f5d3"; }

.fa-atom-alt::before {
  content: "\f5d3"; }

.fa-audio-description::before {
  content: "\f29e"; }

.fa-audio-description-slash::before {
  content: "\e0a8"; }

.fa-austral-sign::before {
  content: "\e0a9"; }

.fa-avocado::before {
  content: "\e0aa"; }

.fa-award::before {
  content: "\f559"; }

.fa-award-simple::before {
  content: "\e0ab"; }

.fa-axe::before {
  content: "\1fa93"; }

.fa-axe-battle::before {
  content: "\f6b3"; }

.fa-b::before {
  content: "\42"; }

.fa-baby::before {
  content: "\f77c"; }

.fa-baby-carriage::before {
  content: "\f77d"; }

.fa-carriage-baby::before {
  content: "\f77d"; }

.fa-backpack::before {
  content: "\1f392"; }

.fa-backward::before {
  content: "\23ea"; }

.fa-backward-fast::before {
  content: "\23ee"; }

.fa-fast-backward::before {
  content: "\23ee"; }

.fa-backward-step::before {
  content: "\f048"; }

.fa-step-backward::before {
  content: "\f048"; }

.fa-bacon::before {
  content: "\1f953"; }

.fa-bacteria::before {
  content: "\e059"; }

.fa-bacterium::before {
  content: "\e05a"; }

.fa-badge::before {
  content: "\f335"; }

.fa-badge-check::before {
  content: "\f336"; }

.fa-badge-dollar::before {
  content: "\f645"; }

.fa-badge-percent::before {
  content: "\f646"; }

.fa-badge-sheriff::before {
  content: "\f8a2"; }

.fa-badger-honey::before {
  content: "\1f9a1"; }

.fa-badminton::before {
  content: "\e33a"; }

.fa-bag-shopping::before {
  content: "\f290"; }

.fa-shopping-bag::before {
  content: "\f290"; }

.fa-bags-shopping::before {
  content: "\1f6cd"; }

.fa-bahai::before {
  content: "\f666"; }

.fa-baht-sign::before {
  content: "\e0ac"; }

.fa-ball-pile::before {
  content: "\f77e"; }

.fa-balloon::before {
  content: "\e2e3"; }

.fa-balloons::before {
  content: "\e2e4"; }

.fa-ballot::before {
  content: "\f732"; }

.fa-ballot-check::before {
  content: "\f733"; }

.fa-ban::before {
  content: "\1f6ab"; }

.fa-cancel::before {
  content: "\1f6ab"; }

.fa-ban-bug::before {
  content: "\f7f9"; }

.fa-debug::before {
  content: "\f7f9"; }

.fa-ban-parking::before {
  content: "\f616"; }

.fa-parking-circle-slash::before {
  content: "\f616"; }

.fa-ban-smoking::before {
  content: "\1f6ad"; }

.fa-smoking-ban::before {
  content: "\1f6ad"; }

.fa-banana::before {
  content: "\e2e5"; }

.fa-bandage::before {
  content: "\1fa79"; }

.fa-band-aid::before {
  content: "\1fa79"; }

.fa-bangladeshi-taka-sign::before {
  content: "\e2e6"; }

.fa-banjo::before {
  content: "\1fa95"; }

.fa-bank::before {
  content: "\f19c"; }

.fa-institution::before {
  content: "\f19c"; }

.fa-university::before {
  content: "\f19c"; }

.fa-barcode::before {
  content: "\f02a"; }

.fa-barcode-read::before {
  content: "\f464"; }

.fa-barcode-scan::before {
  content: "\f465"; }

.fa-bars::before {
  content: "\f0c9"; }

.fa-navicon::before {
  content: "\f0c9"; }

.fa-bars-filter::before {
  content: "\e0ad"; }

.fa-bars-progress::before {
  content: "\f828"; }

.fa-tasks-alt::before {
  content: "\f828"; }

.fa-bars-sort::before {
  content: "\e0ae"; }

.fa-bars-staggered::before {
  content: "\f550"; }

.fa-reorder::before {
  content: "\f550"; }

.fa-stream::before {
  content: "\f550"; }

.fa-baseball::before {
  content: "\26be"; }

.fa-baseball-ball::before {
  content: "\26be"; }

.fa-baseball-bat-ball::before {
  content: "\f432"; }

.fa-basket-shopping::before {
  content: "\f291"; }

.fa-shopping-basket::before {
  content: "\f291"; }

.fa-basket-shopping-simple::before {
  content: "\e0af"; }

.fa-shopping-basket-alt::before {
  content: "\e0af"; }

.fa-basketball::before {
  content: "\1f3c0"; }

.fa-basketball-ball::before {
  content: "\1f3c0"; }

.fa-basketball-hoop::before {
  content: "\f435"; }

.fa-bat::before {
  content: "\f6b5"; }

.fa-bath::before {
  content: "\1f6c1"; }

.fa-bathtub::before {
  content: "\1f6c1"; }

.fa-battery-bolt::before {
  content: "\f376"; }

.fa-battery-empty::before {
  content: "\f244"; }

.fa-battery-0::before {
  content: "\f244"; }

.fa-battery-exclamation::before {
  content: "\e0b0"; }

.fa-battery-full::before {
  content: "\1f50b"; }

.fa-battery::before {
  content: "\1f50b"; }

.fa-battery-5::before {
  content: "\1f50b"; }

.fa-battery-half::before {
  content: "\f242"; }

.fa-battery-3::before {
  content: "\f242"; }

.fa-battery-low::before {
  content: "\e0b1"; }

.fa-battery-1::before {
  content: "\e0b1"; }

.fa-battery-quarter::before {
  content: "\f243"; }

.fa-battery-2::before {
  content: "\f243"; }

.fa-battery-slash::before {
  content: "\f377"; }

.fa-battery-three-quarters::before {
  content: "\f241"; }

.fa-battery-4::before {
  content: "\f241"; }

.fa-bed::before {
  content: "\1f6cc"; }

.fa-bed-bunk::before {
  content: "\f8f8"; }

.fa-bed-empty::before {
  content: "\1f6cf"; }

.fa-bed-front::before {
  content: "\f8f7"; }

.fa-bed-alt::before {
  content: "\f8f7"; }

.fa-bed-pulse::before {
  content: "\f487"; }

.fa-procedures::before {
  content: "\f487"; }

.fa-bee::before {
  content: "\e0b2"; }

.fa-beer-mug::before {
  content: "\e0b3"; }

.fa-beer-foam::before {
  content: "\e0b3"; }

.fa-beer-mug-empty::before {
  content: "\f0fc"; }

.fa-beer::before {
  content: "\f0fc"; }

.fa-bell::before {
  content: "\1f514"; }

.fa-bell-concierge::before {
  content: "\1f6ce"; }

.fa-concierge-bell::before {
  content: "\1f6ce"; }

.fa-bell-exclamation::before {
  content: "\f848"; }

.fa-bell-on::before {
  content: "\1f56d"; }

.fa-bell-plus::before {
  content: "\f849"; }

.fa-bell-school::before {
  content: "\f5d5"; }

.fa-bell-school-slash::before {
  content: "\f5d6"; }

.fa-bell-slash::before {
  content: "\1f515"; }

.fa-bells::before {
  content: "\f77f"; }

.fa-bench-tree::before {
  content: "\e2e7"; }

.fa-bezier-curve::before {
  content: "\f55b"; }

.fa-bicycle::before {
  content: "\1f6b2"; }

.fa-binary::before {
  content: "\e33b"; }

.fa-binary-circle-check::before {
  content: "\e33c"; }

.fa-binary-lock::before {
  content: "\e33d"; }

.fa-binary-slash::before {
  content: "\e33e"; }

.fa-binoculars::before {
  content: "\f1e5"; }

.fa-biohazard::before {
  content: "\2623"; }

.fa-bitcoin-sign::before {
  content: "\e0b4"; }

.fa-blanket::before {
  content: "\f498"; }

.fa-blender::before {
  content: "\f517"; }

.fa-blender-phone::before {
  content: "\f6b6"; }

.fa-blinds::before {
  content: "\f8fb"; }

.fa-blinds-open::before {
  content: "\f8fc"; }

.fa-blinds-raised::before {
  content: "\f8fd"; }

.fa-block-quote::before {
  content: "\e0b5"; }

.fa-blog::before {
  content: "\f781"; }

.fa-blueberries::before {
  content: "\e2e8"; }

.fa-bold::before {
  content: "\f032"; }

.fa-bolt::before {
  content: "\26a1"; }

.fa-flash::before {
  content: "\26a1"; }

.fa-bolt-auto::before {
  content: "\e0b6"; }

.fa-bolt-lightning::before {
  content: "\e0b7"; }

.fa-bolt-slash::before {
  content: "\e0b8"; }

.fa-bomb::before {
  content: "\1f4a3"; }

.fa-bone::before {
  content: "\1f9b4"; }

.fa-bone-break::before {
  content: "\f5d8"; }

.fa-bong::before {
  content: "\f55c"; }

.fa-book::before {
  content: "\1f4d4"; }

.fa-book-arrow-right::before {
  content: "\e0b9"; }

.fa-book-arrow-up::before {
  content: "\e0ba"; }

.fa-book-atlas::before {
  content: "\f558"; }

.fa-atlas::before {
  content: "\f558"; }

.fa-book-bible::before {
  content: "\f647"; }

.fa-bible::before {
  content: "\f647"; }

.fa-book-blank::before {
  content: "\1f4d5"; }

.fa-book-alt::before {
  content: "\1f4d5"; }

.fa-book-bookmark::before {
  content: "\e0bb"; }

.fa-book-circle-arrow-right::before {
  content: "\e0bc"; }

.fa-book-circle-arrow-up::before {
  content: "\e0bd"; }

.fa-book-copy::before {
  content: "\e0be"; }

.fa-book-font::before {
  content: "\e0bf"; }

.fa-book-heart::before {
  content: "\f499"; }

.fa-book-journal-whills::before {
  content: "\f66a"; }

.fa-journal-whills::before {
  content: "\f66a"; }

.fa-book-medical::before {
  content: "\f7e6"; }

.fa-book-open::before {
  content: "\1f4d6"; }

.fa-book-open-cover::before {
  content: "\e0c0"; }

.fa-book-open-alt::before {
  content: "\e0c0"; }

.fa-book-open-reader::before {
  content: "\f5da"; }

.fa-book-reader::before {
  content: "\f5da"; }

.fa-book-quran::before {
  content: "\f687"; }

.fa-quran::before {
  content: "\f687"; }

.fa-book-section::before {
  content: "\e0c1"; }

.fa-book-law::before {
  content: "\e0c1"; }

.fa-book-skull::before {
  content: "\f6b7"; }

.fa-book-dead::before {
  content: "\f6b7"; }

.fa-book-sparkles::before {
  content: "\f6b8"; }

.fa-book-spells::before {
  content: "\f6b8"; }

.fa-book-tanakh::before {
  content: "\f827"; }

.fa-tanakh::before {
  content: "\f827"; }

.fa-book-user::before {
  content: "\f7e7"; }

.fa-bookmark::before {
  content: "\1f516"; }

.fa-bookmark-slash::before {
  content: "\e0c2"; }

.fa-books::before {
  content: "\1f4da"; }

.fa-books-medical::before {
  content: "\f7e8"; }

.fa-boombox::before {
  content: "\1f4fe"; }

.fa-boot::before {
  content: "\1f97e"; }

.fa-boot-heeled::before {
  content: "\e33f"; }

.fa-booth-curtain::before {
  content: "\f734"; }

.fa-border-all::before {
  content: "\f84c"; }

.fa-border-bottom::before {
  content: "\f84d"; }

.fa-border-bottom-right::before {
  content: "\f854"; }

.fa-border-style-alt::before {
  content: "\f854"; }

.fa-border-center-h::before {
  content: "\f89c"; }

.fa-border-center-v::before {
  content: "\f89d"; }

.fa-border-inner::before {
  content: "\f84e"; }

.fa-border-left::before {
  content: "\f84f"; }

.fa-border-none::before {
  content: "\f850"; }

.fa-border-outer::before {
  content: "\f851"; }

.fa-border-right::before {
  content: "\f852"; }

.fa-border-top::before {
  content: "\f855"; }

.fa-border-top-left::before {
  content: "\f853"; }

.fa-border-style::before {
  content: "\f853"; }

.fa-bow-arrow::before {
  content: "\1f3f9"; }

.fa-bowl-chopsticks::before {
  content: "\e2e9"; }

.fa-bowl-chopsticks-noodles::before {
  content: "\e2ea"; }

.fa-bowl-hot::before {
  content: "\f823"; }

.fa-soup::before {
  content: "\f823"; }

.fa-bowl-rice::before {
  content: "\e2eb"; }

.fa-bowling-ball::before {
  content: "\f436"; }

.fa-bowling-ball-pin::before {
  content: "\e0c3"; }

.fa-bowling-pins::before {
  content: "\f437"; }

.fa-box::before {
  content: "\1f4e6"; }

.fa-box-archive::before {
  content: "\f187"; }

.fa-archive::before {
  content: "\f187"; }

.fa-box-ballot::before {
  content: "\1f5f3"; }

.fa-box-check::before {
  content: "\f467"; }

.fa-box-circle-check::before {
  content: "\e0c4"; }

.fa-box-dollar::before {
  content: "\f4a0"; }

.fa-box-usd::before {
  content: "\f4a0"; }

.fa-box-heart::before {
  content: "\f49d"; }

.fa-box-open::before {
  content: "\f49e"; }

.fa-box-open-full::before {
  content: "\f49c"; }

.fa-box-full::before {
  content: "\f49c"; }

.fa-box-taped::before {
  content: "\f49a"; }

.fa-box-alt::before {
  content: "\f49a"; }

.fa-box-tissue::before {
  content: "\e05b"; }

.fa-boxes-stacked::before {
  content: "\f468"; }

.fa-boxes::before {
  content: "\f468"; }

.fa-boxes-alt::before {
  content: "\f468"; }

.fa-boxing-glove::before {
  content: "\1f94a"; }

.fa-glove-boxing::before {
  content: "\1f94a"; }

.fa-bracket-curly::before {
  content: "\7b"; }

.fa-bracket-curly-left::before {
  content: "\7b"; }

.fa-bracket-curly-right::before {
  content: "\7d"; }

.fa-bracket-round::before {
  content: "\28"; }

.fa-parenthesis::before {
  content: "\28"; }

.fa-bracket-round-right::before {
  content: "\29"; }

.fa-bracket-square::before {
  content: "\5b"; }

.fa-bracket::before {
  content: "\5b"; }

.fa-bracket-left::before {
  content: "\5b"; }

.fa-bracket-square-right::before {
  content: "\5d"; }

.fa-brackets-curly::before {
  content: "\f7ea"; }

.fa-brackets-round::before {
  content: "\e0c5"; }

.fa-parentheses::before {
  content: "\e0c5"; }

.fa-brackets-square::before {
  content: "\f7e9"; }

.fa-brackets::before {
  content: "\f7e9"; }

.fa-braille::before {
  content: "\f2a1"; }

.fa-brain::before {
  content: "\1f9e0"; }

.fa-brain-arrow-curved-right::before {
  content: "\f677"; }

.fa-mind-share::before {
  content: "\f677"; }

.fa-brain-circuit::before {
  content: "\e0c6"; }

.fa-brake-warning::before {
  content: "\e0c7"; }

.fa-bread-loaf::before {
  content: "\1f35e"; }

.fa-bread-slice::before {
  content: "\f7ec"; }

.fa-briefcase::before {
  content: "\1f4bc"; }

.fa-briefcase-arrow-right::before {
  content: "\e2f2"; }

.fa-briefcase-blank::before {
  content: "\e0c8"; }

.fa-briefcase-clock::before {
  content: "\f64a"; }

.fa-business-time::before {
  content: "\f64a"; }

.fa-briefcase-medical::before {
  content: "\f469"; }

.fa-brightness::before {
  content: "\e0c9"; }

.fa-brightness-low::before {
  content: "\e0ca"; }

.fa-bring-forward::before {
  content: "\f856"; }

.fa-bring-front::before {
  content: "\f857"; }

.fa-broom::before {
  content: "\1f9f9"; }

.fa-browser::before {
  content: "\1f5d4"; }

.fa-browsers::before {
  content: "\e0cb"; }

.fa-brush::before {
  content: "\f55d"; }

.fa-bug::before {
  content: "\f188"; }

.fa-building::before {
  content: "\1f3e2"; }

.fa-buildings::before {
  content: "\e0cc"; }

.fa-bullhorn::before {
  content: "\1f4e2"; }

.fa-bullseye::before {
  content: "\f140"; }

.fa-bullseye-arrow::before {
  content: "\1f3af"; }

.fa-bullseye-pointer::before {
  content: "\f649"; }

.fa-burger::before {
  content: "\f805"; }

.fa-hamburger::before {
  content: "\f805"; }

.fa-burger-cheese::before {
  content: "\1f354"; }

.fa-cheeseburger::before {
  content: "\1f354"; }

.fa-burger-fries::before {
  content: "\e0cd"; }

.fa-burger-glass::before {
  content: "\e0ce"; }

.fa-burger-soda::before {
  content: "\f858"; }

.fa-burrito::before {
  content: "\1f32f"; }

.fa-bus::before {
  content: "\1f68d"; }

.fa-bus-school::before {
  content: "\f5dd"; }

.fa-bus-simple::before {
  content: "\f55e"; }

.fa-bus-alt::before {
  content: "\f55e"; }

.fa-c::before {
  content: "\43"; }

.fa-cabinet-filing::before {
  content: "\1f5c4"; }

.fa-cable-car::before {
  content: "\e0cf"; }

.fa-cactus::before {
  content: "\1f335"; }

.fa-cake-candles::before {
  content: "\1f382"; }

.fa-birthday-cake::before {
  content: "\1f382"; }

.fa-calculator::before {
  content: "\1f5a9"; }

.fa-calculator-simple::before {
  content: "\f64c"; }

.fa-calculator-alt::before {
  content: "\f64c"; }

.fa-calendar::before {
  content: "\1f4c5"; }

.fa-calendar-arrow-down::before {
  content: "\e0d0"; }

.fa-calendar-download::before {
  content: "\e0d0"; }

.fa-calendar-arrow-up::before {
  content: "\e0d1"; }

.fa-calendar-upload::before {
  content: "\e0d1"; }

.fa-calendar-check::before {
  content: "\f274"; }

.fa-calendar-clock::before {
  content: "\e0d2"; }

.fa-calendar-time::before {
  content: "\e0d2"; }

.fa-calendar-day::before {
  content: "\f783"; }

.fa-calendar-days::before {
  content: "\f073"; }

.fa-calendar-alt::before {
  content: "\f073"; }

.fa-calendar-exclamation::before {
  content: "\f334"; }

.fa-calendar-heart::before {
  content: "\e0d3"; }

.fa-calendar-image::before {
  content: "\e0d4"; }

.fa-calendar-lines::before {
  content: "\e0d5"; }

.fa-calendar-note::before {
  content: "\e0d5"; }

.fa-calendar-minus::before {
  content: "\f272"; }

.fa-calendar-pen::before {
  content: "\f333"; }

.fa-calendar-edit::before {
  content: "\f333"; }

.fa-calendar-plus::before {
  content: "\f271"; }

.fa-calendar-range::before {
  content: "\e0d6"; }

.fa-calendar-star::before {
  content: "\f736"; }

.fa-calendar-week::before {
  content: "\f784"; }

.fa-calendar-xmark::before {
  content: "\f273"; }

.fa-calendar-times::before {
  content: "\f273"; }

.fa-calendars::before {
  content: "\e0d7"; }

.fa-camcorder::before {
  content: "\1f4f9"; }

.fa-video-handheld::before {
  content: "\1f4f9"; }

.fa-camera::before {
  content: "\f030"; }

.fa-camera-alt::before {
  content: "\f030"; }

.fa-camera-cctv::before {
  content: "\f8ac"; }

.fa-cctv::before {
  content: "\f8ac"; }

.fa-camera-movie::before {
  content: "\1f3a5"; }

.fa-camera-polaroid::before {
  content: "\f8aa"; }

.fa-camera-retro::before {
  content: "\1f4f7"; }

.fa-camera-rotate::before {
  content: "\e0d8"; }

.fa-camera-security::before {
  content: "\f8fe"; }

.fa-camera-home::before {
  content: "\f8fe"; }

.fa-camera-slash::before {
  content: "\e0d9"; }

.fa-camera-viewfinder::before {
  content: "\e0da"; }

.fa-camera-web::before {
  content: "\f832"; }

.fa-webcam::before {
  content: "\f832"; }

.fa-camera-web-slash::before {
  content: "\f833"; }

.fa-webcam-slash::before {
  content: "\f833"; }

.fa-campfire::before {
  content: "\f6ba"; }

.fa-campground::before {
  content: "\26fa"; }

.fa-candle-holder::before {
  content: "\1f56f"; }

.fa-candy-cane::before {
  content: "\f786"; }

.fa-candy-corn::before {
  content: "\f6bd"; }

.fa-cannabis::before {
  content: "\f55f"; }

.fa-capsules::before {
  content: "\f46b"; }

.fa-car::before {
  content: "\1f698"; }

.fa-automobile::before {
  content: "\1f698"; }

.fa-car-battery::before {
  content: "\f5df"; }

.fa-battery-car::before {
  content: "\f5df"; }

.fa-car-bolt::before {
  content: "\e341"; }

.fa-car-building::before {
  content: "\f859"; }

.fa-car-bump::before {
  content: "\f5e0"; }

.fa-car-bus::before {
  content: "\f85a"; }

.fa-car-circle-bolt::before {
  content: "\e342"; }

.fa-car-crash::before {
  content: "\f5e1"; }

.fa-car-garage::before {
  content: "\f5e2"; }

.fa-car-mirrors::before {
  content: "\e343"; }

.fa-car-rear::before {
  content: "\f5de"; }

.fa-car-alt::before {
  content: "\f5de"; }

.fa-car-side::before {
  content: "\1f697"; }

.fa-car-side-bolt::before {
  content: "\e344"; }

.fa-car-tilt::before {
  content: "\f5e5"; }

.fa-car-wash::before {
  content: "\f5e6"; }

.fa-car-wrench::before {
  content: "\f5e3"; }

.fa-car-mechanic::before {
  content: "\f5e3"; }

.fa-caravan::before {
  content: "\f8ff"; }

.fa-caravan-simple::before {
  content: "\e000"; }

.fa-caravan-alt::before {
  content: "\e000"; }

.fa-caret-down::before {
  content: "\f0d7"; }

.fa-caret-left::before {
  content: "\f0d9"; }

.fa-caret-right::before {
  content: "\f0da"; }

.fa-caret-up::before {
  content: "\f0d8"; }

.fa-carrot::before {
  content: "\1f955"; }

.fa-cars::before {
  content: "\f85b"; }

.fa-cart-arrow-down::before {
  content: "\f218"; }

.fa-cart-flatbed::before {
  content: "\f474"; }

.fa-dolly-flatbed::before {
  content: "\f474"; }

.fa-cart-flatbed-boxes::before {
  content: "\f475"; }

.fa-dolly-flatbed-alt::before {
  content: "\f475"; }

.fa-cart-flatbed-empty::before {
  content: "\f476"; }

.fa-dolly-flatbed-empty::before {
  content: "\f476"; }

.fa-cart-flatbed-suitcase::before {
  content: "\f59d"; }

.fa-luggage-cart::before {
  content: "\f59d"; }

.fa-cart-minus::before {
  content: "\e0db"; }

.fa-cart-plus::before {
  content: "\f217"; }

.fa-cart-shopping::before {
  content: "\1f6d2"; }

.fa-shopping-cart::before {
  content: "\1f6d2"; }

.fa-cart-shopping-fast::before {
  content: "\e0dc"; }

.fa-cart-xmark::before {
  content: "\e0dd"; }

.fa-cash-register::before {
  content: "\f788"; }

.fa-cassette-betamax::before {
  content: "\f8a4"; }

.fa-betamax::before {
  content: "\f8a4"; }

.fa-cassette-tape::before {
  content: "\1f5ad"; }

.fa-cassette-vhs::before {
  content: "\1f4fc"; }

.fa-vhs::before {
  content: "\1f4fc"; }

.fa-castle::before {
  content: "\e0de"; }

.fa-cat::before {
  content: "\1f408"; }

.fa-cat-space::before {
  content: "\e001"; }

.fa-cauldron::before {
  content: "\f6bf"; }

.fa-cedi-sign::before {
  content: "\e0df"; }

.fa-cent-sign::before {
  content: "\a2"; }

.fa-certificate::before {
  content: "\f0a3"; }

.fa-chair::before {
  content: "\1fa91"; }

.fa-chair-office::before {
  content: "\f6c1"; }

.fa-chalkboard::before {
  content: "\f51b"; }

.fa-blackboard::before {
  content: "\f51b"; }

.fa-chalkboard-user::before {
  content: "\f51c"; }

.fa-chalkboard-teacher::before {
  content: "\f51c"; }

.fa-champagne-glass::before {
  content: "\f79e"; }

.fa-glass-champagne::before {
  content: "\f79e"; }

.fa-champagne-glasses::before {
  content: "\1f942"; }

.fa-glass-cheers::before {
  content: "\1f942"; }

.fa-charging-station::before {
  content: "\f5e7"; }

.fa-chart-area::before {
  content: "\f1fe"; }

.fa-area-chart::before {
  content: "\f1fe"; }

.fa-chart-bar::before {
  content: "\f080"; }

.fa-bar-chart::before {
  content: "\f080"; }

.fa-chart-bullet::before {
  content: "\e0e1"; }

.fa-chart-candlestick::before {
  content: "\e0e2"; }

.fa-chart-column::before {
  content: "\e0e3"; }

.fa-chart-gantt::before {
  content: "\e0e4"; }

.fa-chart-line::before {
  content: "\f201"; }

.fa-line-chart::before {
  content: "\f201"; }

.fa-chart-line-down::before {
  content: "\1f4c9"; }

.fa-chart-line-up::before {
  content: "\e0e5"; }

.fa-chart-mixed::before {
  content: "\f643"; }

.fa-analytics::before {
  content: "\f643"; }

.fa-chart-network::before {
  content: "\f78a"; }

.fa-chart-pie::before {
  content: "\f200"; }

.fa-pie-chart::before {
  content: "\f200"; }

.fa-chart-pie-simple::before {
  content: "\f64e"; }

.fa-chart-pie-alt::before {
  content: "\f64e"; }

.fa-chart-pyramid::before {
  content: "\e0e6"; }

.fa-chart-radar::before {
  content: "\e0e7"; }

.fa-chart-scatter::before {
  content: "\f7ee"; }

.fa-chart-scatter-3d::before {
  content: "\e0e8"; }

.fa-chart-scatter-bubble::before {
  content: "\e0e9"; }

.fa-chart-tree-map::before {
  content: "\e0ea"; }

.fa-chart-user::before {
  content: "\f6a3"; }

.fa-user-chart::before {
  content: "\f6a3"; }

.fa-chart-waterfall::before {
  content: "\e0eb"; }

.fa-check::before {
  content: "\2713"; }

.fa-check-double::before {
  content: "\f560"; }

.fa-check-to-slot::before {
  content: "\f772"; }

.fa-vote-yea::before {
  content: "\f772"; }

.fa-cheese::before {
  content: "\f7ef"; }

.fa-cheese-swiss::before {
  content: "\1f9c0"; }

.fa-cherries::before {
  content: "\e0ec"; }

.fa-chess::before {
  content: "\f439"; }

.fa-chess-bishop::before {
  content: "\265d"; }

.fa-chess-bishop-piece::before {
  content: "\f43b"; }

.fa-chess-bishop-alt::before {
  content: "\f43b"; }

.fa-chess-board::before {
  content: "\f43c"; }

.fa-chess-clock::before {
  content: "\f43d"; }

.fa-chess-clock-flip::before {
  content: "\f43e"; }

.fa-chess-clock-alt::before {
  content: "\f43e"; }

.fa-chess-king::before {
  content: "\265a"; }

.fa-chess-king-piece::before {
  content: "\f440"; }

.fa-chess-king-alt::before {
  content: "\f440"; }

.fa-chess-knight::before {
  content: "\265e"; }

.fa-chess-knight-piece::before {
  content: "\f442"; }

.fa-chess-knight-alt::before {
  content: "\f442"; }

.fa-chess-pawn::before {
  content: "\265f"; }

.fa-chess-pawn-piece::before {
  content: "\f444"; }

.fa-chess-pawn-alt::before {
  content: "\f444"; }

.fa-chess-queen::before {
  content: "\265b"; }

.fa-chess-queen-piece::before {
  content: "\f446"; }

.fa-chess-queen-alt::before {
  content: "\f446"; }

.fa-chess-rook::before {
  content: "\265c"; }

.fa-chess-rook-piece::before {
  content: "\f448"; }

.fa-chess-rook-alt::before {
  content: "\f448"; }

.fa-chevron-down::before {
  content: "\f078"; }

.fa-chevron-left::before {
  content: "\2329"; }

.fa-chevron-right::before {
  content: "\232a"; }

.fa-chevron-up::before {
  content: "\f077"; }

.fa-chevrons-down::before {
  content: "\f322"; }

.fa-chevron-double-down::before {
  content: "\f322"; }

.fa-chevrons-left::before {
  content: "\f323"; }

.fa-chevron-double-left::before {
  content: "\f323"; }

.fa-chevrons-right::before {
  content: "\f324"; }

.fa-chevron-double-right::before {
  content: "\f324"; }

.fa-chevrons-up::before {
  content: "\f325"; }

.fa-chevron-double-up::before {
  content: "\f325"; }

.fa-child::before {
  content: "\f1ae"; }

.fa-chimney::before {
  content: "\f78b"; }

.fa-church::before {
  content: "\26ea"; }

.fa-circle::before {
  content: "\25cf"; }

.fa-circle-0::before {
  content: "\e0ed"; }

.fa-circle-1::before {
  content: "\e0ee"; }

.fa-circle-2::before {
  content: "\e0ef"; }

.fa-circle-3::before {
  content: "\e0f0"; }

.fa-circle-4::before {
  content: "\e0f1"; }

.fa-circle-5::before {
  content: "\e0f2"; }

.fa-circle-6::before {
  content: "\e0f3"; }

.fa-circle-7::before {
  content: "\e0f4"; }

.fa-circle-8::before {
  content: "\e0f5"; }

.fa-circle-9::before {
  content: "\e0f6"; }

.fa-circle-a::before {
  content: "\e0f7"; }

.fa-circle-ampersand::before {
  content: "\e0f8"; }

.fa-circle-arrow-down::before {
  content: "\f0ab"; }

.fa-arrow-circle-down::before {
  content: "\f0ab"; }

.fa-circle-arrow-down-left::before {
  content: "\e0f9"; }

.fa-circle-arrow-down-right::before {
  content: "\e0fa"; }

.fa-circle-arrow-left::before {
  content: "\f0a8"; }

.fa-arrow-circle-left::before {
  content: "\f0a8"; }

.fa-circle-arrow-right::before {
  content: "\f0a9"; }

.fa-arrow-circle-right::before {
  content: "\f0a9"; }

.fa-circle-arrow-up::before {
  content: "\f0aa"; }

.fa-arrow-circle-up::before {
  content: "\f0aa"; }

.fa-circle-arrow-up-left::before {
  content: "\e0fb"; }

.fa-circle-arrow-up-right::before {
  content: "\e0fc"; }

.fa-circle-b::before {
  content: "\e0fd"; }

.fa-circle-bolt::before {
  content: "\e0fe"; }

.fa-circle-book-open::before {
  content: "\e0ff"; }

.fa-book-circle::before {
  content: "\e0ff"; }

.fa-circle-bookmark::before {
  content: "\e100"; }

.fa-bookmark-circle::before {
  content: "\e100"; }

.fa-circle-c::before {
  content: "\e101"; }

.fa-circle-calendar::before {
  content: "\e102"; }

.fa-calendar-circle::before {
  content: "\e102"; }

.fa-circle-camera::before {
  content: "\e103"; }

.fa-camera-circle::before {
  content: "\e103"; }

.fa-circle-caret-down::before {
  content: "\f32d"; }

.fa-caret-circle-down::before {
  content: "\f32d"; }

.fa-circle-caret-left::before {
  content: "\f32e"; }

.fa-caret-circle-left::before {
  content: "\f32e"; }

.fa-circle-caret-right::before {
  content: "\f330"; }

.fa-caret-circle-right::before {
  content: "\f330"; }

.fa-circle-caret-up::before {
  content: "\f331"; }

.fa-caret-circle-up::before {
  content: "\f331"; }

.fa-circle-check::before {
  content: "\f058"; }

.fa-check-circle::before {
  content: "\f058"; }

.fa-circle-chevron-down::before {
  content: "\f13a"; }

.fa-chevron-circle-down::before {
  content: "\f13a"; }

.fa-circle-chevron-left::before {
  content: "\f137"; }

.fa-chevron-circle-left::before {
  content: "\f137"; }

.fa-circle-chevron-right::before {
  content: "\f138"; }

.fa-chevron-circle-right::before {
  content: "\f138"; }

.fa-circle-chevron-up::before {
  content: "\f139"; }

.fa-chevron-circle-up::before {
  content: "\f139"; }

.fa-circle-d::before {
  content: "\e104"; }

.fa-circle-dashed::before {
  content: "\e105"; }

.fa-circle-divide::before {
  content: "\e106"; }

.fa-circle-dollar::before {
  content: "\f2e8"; }

.fa-dollar-circle::before {
  content: "\f2e8"; }

.fa-usd-circle::before {
  content: "\f2e8"; }

.fa-circle-dollar-to-slot::before {
  content: "\f4b9"; }

.fa-donate::before {
  content: "\f4b9"; }

.fa-circle-dot::before {
  content: "\1f518"; }

.fa-dot-circle::before {
  content: "\1f518"; }

.fa-circle-down::before {
  content: "\f358"; }

.fa-arrow-alt-circle-down::before {
  content: "\f358"; }

.fa-circle-down-left::before {
  content: "\e107"; }

.fa-circle-down-right::before {
  content: "\e108"; }

.fa-circle-e::before {
  content: "\e109"; }

.fa-circle-ellipsis::before {
  content: "\e10a"; }

.fa-circle-ellipsis-vertical::before {
  content: "\e10b"; }

.fa-circle-envelope::before {
  content: "\e10c"; }

.fa-envelope-circle::before {
  content: "\e10c"; }

.fa-circle-exclamation::before {
  content: "\f06a"; }

.fa-exclamation-circle::before {
  content: "\f06a"; }

.fa-circle-exclamation-check::before {
  content: "\e10d"; }

.fa-circle-f::before {
  content: "\e10e"; }

.fa-circle-g::before {
  content: "\e10f"; }

.fa-circle-h::before {
  content: "\24bd"; }

.fa-hospital-symbol::before {
  content: "\24bd"; }

.fa-circle-half::before {
  content: "\e110"; }

.fa-circle-half-stroke::before {
  content: "\25d0"; }

.fa-adjust::before {
  content: "\25d0"; }

.fa-circle-heart::before {
  content: "\f4c7"; }

.fa-heart-circle::before {
  content: "\f4c7"; }

.fa-circle-i::before {
  content: "\e111"; }

.fa-circle-info::before {
  content: "\f05a"; }

.fa-info-circle::before {
  content: "\f05a"; }

.fa-circle-j::before {
  content: "\e112"; }

.fa-circle-k::before {
  content: "\e113"; }

.fa-circle-l::before {
  content: "\e114"; }

.fa-circle-left::before {
  content: "\f359"; }

.fa-arrow-alt-circle-left::before {
  content: "\f359"; }

.fa-circle-location-arrow::before {
  content: "\f602"; }

.fa-location-circle::before {
  content: "\f602"; }

.fa-circle-m::before {
  content: "\e115"; }

.fa-circle-microphone::before {
  content: "\e116"; }

.fa-microphone-circle::before {
  content: "\e116"; }

.fa-circle-microphone-lines::before {
  content: "\e117"; }

.fa-microphone-circle-alt::before {
  content: "\e117"; }

.fa-circle-minus::before {
  content: "\f056"; }

.fa-minus-circle::before {
  content: "\f056"; }

.fa-circle-n::before {
  content: "\e118"; }

.fa-circle-notch::before {
  content: "\f1ce"; }

.fa-circle-o::before {
  content: "\e119"; }

.fa-circle-p::before {
  content: "\e11a"; }

.fa-circle-parking::before {
  content: "\f615"; }

.fa-parking-circle::before {
  content: "\f615"; }

.fa-circle-pause::before {
  content: "\f28b"; }

.fa-pause-circle::before {
  content: "\f28b"; }

.fa-circle-phone::before {
  content: "\e11b"; }

.fa-phone-circle::before {
  content: "\e11b"; }

.fa-circle-phone-flip::before {
  content: "\e11c"; }

.fa-phone-circle-alt::before {
  content: "\e11c"; }

.fa-circle-phone-hangup::before {
  content: "\e11d"; }

.fa-phone-circle-down::before {
  content: "\e11d"; }

.fa-circle-play::before {
  content: "\f144"; }

.fa-play-circle::before {
  content: "\f144"; }

.fa-circle-plus::before {
  content: "\f055"; }

.fa-plus-circle::before {
  content: "\f055"; }

.fa-circle-q::before {
  content: "\e11e"; }

.fa-circle-quarter::before {
  content: "\e11f"; }

.fa-circle-question::before {
  content: "\f059"; }

.fa-question-circle::before {
  content: "\f059"; }

.fa-circle-r::before {
  content: "\e120"; }

.fa-circle-radiation::before {
  content: "\2622"; }

.fa-radiation-alt::before {
  content: "\2622"; }

.fa-circle-right::before {
  content: "\f35a"; }

.fa-arrow-alt-circle-right::before {
  content: "\f35a"; }

.fa-circle-s::before {
  content: "\e121"; }

.fa-circle-small::before {
  content: "\e122"; }

.fa-circle-sort::before {
  content: "\e030"; }

.fa-sort-circle::before {
  content: "\e030"; }

.fa-circle-sort-down::before {
  content: "\e031"; }

.fa-sort-circle-down::before {
  content: "\e031"; }

.fa-circle-sort-up::before {
  content: "\e032"; }

.fa-sort-circle-up::before {
  content: "\e032"; }

.fa-circle-star::before {
  content: "\e123"; }

.fa-star-circle::before {
  content: "\e123"; }

.fa-circle-stop::before {
  content: "\f28d"; }

.fa-stop-circle::before {
  content: "\f28d"; }

.fa-circle-t::before {
  content: "\e124"; }

.fa-circle-three-quarters::before {
  content: "\e125"; }

.fa-circle-trash::before {
  content: "\e126"; }

.fa-trash-circle::before {
  content: "\e126"; }

.fa-circle-u::before {
  content: "\e127"; }

.fa-circle-up::before {
  content: "\f35b"; }

.fa-arrow-alt-circle-up::before {
  content: "\f35b"; }

.fa-circle-up-left::before {
  content: "\e128"; }

.fa-circle-up-right::before {
  content: "\e129"; }

.fa-circle-user::before {
  content: "\f2bd"; }

.fa-user-circle::before {
  content: "\f2bd"; }

.fa-circle-v::before {
  content: "\e12a"; }

.fa-circle-video::before {
  content: "\e12b"; }

.fa-video-circle::before {
  content: "\e12b"; }

.fa-circle-w::before {
  content: "\e12c"; }

.fa-circle-waveform-lines::before {
  content: "\e12d"; }

.fa-waveform-circle::before {
  content: "\e12d"; }

.fa-circle-x::before {
  content: "\e12e"; }

.fa-circle-xmark::before {
  content: "\f057"; }

.fa-times-circle::before {
  content: "\f057"; }

.fa-xmark-circle::before {
  content: "\f057"; }

.fa-circle-y::before {
  content: "\e12f"; }

.fa-circle-z::before {
  content: "\e130"; }

.fa-citrus::before {
  content: "\e2f4"; }

.fa-citrus-slice::before {
  content: "\e2f5"; }

.fa-city::before {
  content: "\1f3d9"; }

.fa-clapperboard::before {
  content: "\e131"; }

.fa-clapperboard-play::before {
  content: "\e132"; }

.fa-clarinet::before {
  content: "\f8ad"; }

.fa-claw-marks::before {
  content: "\f6c2"; }

.fa-clipboard::before {
  content: "\1f4cb"; }

.fa-clipboard-check::before {
  content: "\f46c"; }

.fa-clipboard-list::before {
  content: "\f46d"; }

.fa-clipboard-list-check::before {
  content: "\f737"; }

.fa-clipboard-medical::before {
  content: "\e133"; }

.fa-clipboard-prescription::before {
  content: "\f5e8"; }

.fa-clipboard-user::before {
  content: "\f7f3"; }

.fa-clock::before {
  content: "\1f553"; }

.fa-clock-four::before {
  content: "\1f553"; }

.fa-clock-desk::before {
  content: "\e134"; }

.fa-clock-eight::before {
  content: "\e345"; }

.fa-clock-eight-thirty::before {
  content: "\e346"; }

.fa-clock-eleven::before {
  content: "\e347"; }

.fa-clock-eleven-thirty::before {
  content: "\e348"; }

.fa-clock-five::before {
  content: "\e349"; }

.fa-clock-five-thirty::before {
  content: "\e34a"; }

.fa-clock-four-thirty::before {
  content: "\e34b"; }

.fa-clock-nine::before {
  content: "\e34c"; }

.fa-clock-nine-thirty::before {
  content: "\e34d"; }

.fa-clock-one::before {
  content: "\e34e"; }

.fa-clock-one-thirty::before {
  content: "\e34f"; }

.fa-clock-rotate-left::before {
  content: "\f1da"; }

.fa-history::before {
  content: "\f1da"; }

.fa-clock-seven::before {
  content: "\e350"; }

.fa-clock-seven-thirty::before {
  content: "\e351"; }

.fa-clock-six::before {
  content: "\e352"; }

.fa-clock-six-thirty::before {
  content: "\e353"; }

.fa-clock-ten::before {
  content: "\e354"; }

.fa-clock-ten-thirty::before {
  content: "\e355"; }

.fa-clock-three::before {
  content: "\e356"; }

.fa-clock-three-thirty::before {
  content: "\e357"; }

.fa-clock-twelve::before {
  content: "\e358"; }

.fa-clock-twelve-thirty::before {
  content: "\e359"; }

.fa-clock-two::before {
  content: "\e35a"; }

.fa-clock-two-thirty::before {
  content: "\e35b"; }

.fa-clone::before {
  content: "\f24d"; }

.fa-closed-captioning::before {
  content: "\f20a"; }

.fa-closed-captioning-slash::before {
  content: "\e135"; }

.fa-clothes-hanger::before {
  content: "\e136"; }

.fa-cloud::before {
  content: "\2601"; }

.fa-cloud-arrow-down::before {
  content: "\f0ed"; }

.fa-cloud-download::before {
  content: "\f0ed"; }

.fa-cloud-download-alt::before {
  content: "\f0ed"; }

.fa-cloud-arrow-up::before {
  content: "\f0ee"; }

.fa-cloud-upload::before {
  content: "\f0ee"; }

.fa-cloud-upload-alt::before {
  content: "\f0ee"; }

.fa-cloud-bolt::before {
  content: "\1f329"; }

.fa-thunderstorm::before {
  content: "\1f329"; }

.fa-cloud-bolt-moon::before {
  content: "\f76d"; }

.fa-thunderstorm-moon::before {
  content: "\f76d"; }

.fa-cloud-bolt-sun::before {
  content: "\f76e"; }

.fa-thunderstorm-sun::before {
  content: "\f76e"; }

.fa-cloud-check::before {
  content: "\e35c"; }

.fa-cloud-drizzle::before {
  content: "\f738"; }

.fa-cloud-fog::before {
  content: "\1f32b"; }

.fa-fog::before {
  content: "\1f32b"; }

.fa-cloud-hail::before {
  content: "\f739"; }

.fa-cloud-hail-mixed::before {
  content: "\f73a"; }

.fa-cloud-meatball::before {
  content: "\f73b"; }

.fa-cloud-minus::before {
  content: "\e35d"; }

.fa-cloud-moon::before {
  content: "\f6c3"; }

.fa-cloud-moon-rain::before {
  content: "\f73c"; }

.fa-cloud-music::before {
  content: "\f8ae"; }

.fa-cloud-plus::before {
  content: "\e35e"; }

.fa-cloud-rain::before {
  content: "\26c6"; }

.fa-cloud-rainbow::before {
  content: "\f73e"; }

.fa-cloud-showers::before {
  content: "\f73f"; }

.fa-cloud-showers-heavy::before {
  content: "\f740"; }

.fa-cloud-slash::before {
  content: "\e137"; }

.fa-cloud-sleet::before {
  content: "\f741"; }

.fa-cloud-snow::before {
  content: "\1f328"; }

.fa-cloud-sun::before {
  content: "\26c5"; }

.fa-cloud-sun-rain::before {
  content: "\1f326"; }

.fa-cloud-word::before {
  content: "\e138"; }

.fa-cloud-xmark::before {
  content: "\e35f"; }

.fa-clouds::before {
  content: "\f744"; }

.fa-clouds-moon::before {
  content: "\f745"; }

.fa-clouds-sun::before {
  content: "\f746"; }

.fa-clover::before {
  content: "\e139"; }

.fa-club::before {
  content: "\2663"; }

.fa-coconut::before {
  content: "\e2f6"; }

.fa-code::before {
  content: "\f121"; }

.fa-code-branch::before {
  content: "\f126"; }

.fa-code-commit::before {
  content: "\f386"; }

.fa-code-compare::before {
  content: "\e13a"; }

.fa-code-fork::before {
  content: "\e13b"; }

.fa-code-merge::before {
  content: "\f387"; }

.fa-code-pull-request::before {
  content: "\e13c"; }

.fa-code-simple::before {
  content: "\e13d"; }

.fa-coffee-bean::before {
  content: "\e13e"; }

.fa-coffee-beans::before {
  content: "\e13f"; }

.fa-coffee-pot::before {
  content: "\e002"; }

.fa-coffin::before {
  content: "\26b0"; }

.fa-coffin-cross::before {
  content: "\e051"; }

.fa-coin::before {
  content: "\1fa99"; }

.fa-coins::before {
  content: "\f51e"; }

.fa-colon::before {
  content: "\3a"; }

.fa-colon-sign::before {
  content: "\e140"; }

.fa-columns-3::before {
  content: "\e361"; }

.fa-comet::before {
  content: "\e003"; }

.fa-comma::before {
  content: "\2c"; }

.fa-command::before {
  content: "\e142"; }

.fa-comment::before {
  content: "\1f5e9"; }

.fa-comment-arrow-down::before {
  content: "\e143"; }

.fa-comment-arrow-up::before {
  content: "\e144"; }

.fa-comment-arrow-up-right::before {
  content: "\e145"; }

.fa-comment-captions::before {
  content: "\e146"; }

.fa-comment-check::before {
  content: "\f4ac"; }

.fa-comment-code::before {
  content: "\e147"; }

.fa-comment-dollar::before {
  content: "\f651"; }

.fa-comment-dots::before {
  content: "\1f4ac"; }

.fa-commenting::before {
  content: "\1f4ac"; }

.fa-comment-exclamation::before {
  content: "\f4af"; }

.fa-comment-image::before {
  content: "\e148"; }

.fa-comment-lines::before {
  content: "\f4b0"; }

.fa-comment-medical::before {
  content: "\f7f5"; }

.fa-comment-middle::before {
  content: "\e149"; }

.fa-comment-middle-top::before {
  content: "\e14a"; }

.fa-comment-minus::before {
  content: "\f4b1"; }

.fa-comment-music::before {
  content: "\f8b0"; }

.fa-comment-pen::before {
  content: "\f4ae"; }

.fa-comment-edit::before {
  content: "\f4ae"; }

.fa-comment-plus::before {
  content: "\f4b2"; }

.fa-comment-question::before {
  content: "\e14b"; }

.fa-comment-quote::before {
  content: "\e14c"; }

.fa-comment-slash::before {
  content: "\f4b3"; }

.fa-comment-smile::before {
  content: "\f4b4"; }

.fa-comment-sms::before {
  content: "\f7cd"; }

.fa-sms::before {
  content: "\f7cd"; }

.fa-comment-text::before {
  content: "\e14d"; }

.fa-comment-xmark::before {
  content: "\f4b5"; }

.fa-comment-times::before {
  content: "\f4b5"; }

.fa-comments::before {
  content: "\1f5ea"; }

.fa-comments-dollar::before {
  content: "\f653"; }

.fa-comments-question::before {
  content: "\e14e"; }

.fa-comments-question-check::before {
  content: "\e14f"; }

.fa-compact-disc::before {
  content: "\1f4bf"; }

.fa-compass::before {
  content: "\1f9ed"; }

.fa-compass-drafting::before {
  content: "\f568"; }

.fa-drafting-compass::before {
  content: "\f568"; }

.fa-compass-slash::before {
  content: "\f5e9"; }

.fa-compress::before {
  content: "\f066"; }

.fa-compress-wide::before {
  content: "\f326"; }

.fa-computer-classic::before {
  content: "\f8b1"; }

.fa-computer-mouse::before {
  content: "\1f5b1"; }

.fa-mouse::before {
  content: "\1f5b1"; }

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd"; }

.fa-mouse-alt::before {
  content: "\f8cd"; }

.fa-computer-speaker::before {
  content: "\f8b2"; }

.fa-container-storage::before {
  content: "\f4b7"; }

.fa-conveyor-belt::before {
  content: "\f46e"; }

.fa-conveyor-belt-boxes::before {
  content: "\f46f"; }

.fa-conveyor-belt-alt::before {
  content: "\f46f"; }

.fa-conveyor-belt-empty::before {
  content: "\e150"; }

.fa-cookie::before {
  content: "\1f36a"; }

.fa-cookie-bite::before {
  content: "\f564"; }

.fa-copy::before {
  content: "\f0c5"; }

.fa-copyright::before {
  content: "\a9"; }

.fa-corn::before {
  content: "\1f33d"; }

.fa-corner::before {
  content: "\ac"; }

.fa-couch::before {
  content: "\f4b8"; }

.fa-cow::before {
  content: "\1f404"; }

.fa-cowbell::before {
  content: "\f8b3"; }

.fa-cowbell-circle-plus::before {
  content: "\f8b4"; }

.fa-cowbell-more::before {
  content: "\f8b4"; }

.fa-crate-apple::before {
  content: "\f6b1"; }

.fa-apple-crate::before {
  content: "\f6b1"; }

.fa-crate-empty::before {
  content: "\e151"; }

.fa-credit-card::before {
  content: "\1f4b3"; }

.fa-credit-card-alt::before {
  content: "\1f4b3"; }

.fa-credit-card-blank::before {
  content: "\f389"; }

.fa-credit-card-front::before {
  content: "\f38a"; }

.fa-cricket-bat-ball::before {
  content: "\1f3cf"; }

.fa-cricket::before {
  content: "\1f3cf"; }

.fa-croissant::before {
  content: "\1f950"; }

.fa-crop::before {
  content: "\f125"; }

.fa-crop-simple::before {
  content: "\f565"; }

.fa-crop-alt::before {
  content: "\f565"; }

.fa-cross::before {
  content: "\271d"; }

.fa-crosshairs::before {
  content: "\f05b"; }

.fa-crow::before {
  content: "\f520"; }

.fa-crown::before {
  content: "\1f451"; }

.fa-crutch::before {
  content: "\f7f7"; }

.fa-crutches::before {
  content: "\f7f8"; }

.fa-cruzeiro-sign::before {
  content: "\e152"; }

.fa-crystal-ball::before {
  content: "\e362"; }

.fa-cube::before {
  content: "\f1b2"; }

.fa-cubes::before {
  content: "\f1b3"; }

.fa-cup-straw::before {
  content: "\e363"; }

.fa-cup-straw-swoosh::before {
  content: "\e364"; }

.fa-cup-togo::before {
  content: "\f6c5"; }

.fa-coffee-togo::before {
  content: "\f6c5"; }

.fa-curling-stone::before {
  content: "\1f94c"; }

.fa-curling::before {
  content: "\1f94c"; }

.fa-d::before {
  content: "\44"; }

.fa-dagger::before {
  content: "\f6cb"; }

.fa-dash::before {
  content: "\2014"; }

.fa-database::before {
  content: "\f1c0"; }

.fa-deer::before {
  content: "\1f98c"; }

.fa-deer-rudolph::before {
  content: "\f78f"; }

.fa-delete-left::before {
  content: "\232b"; }

.fa-backspace::before {
  content: "\232b"; }

.fa-delete-right::before {
  content: "\e154"; }

.fa-democrat::before {
  content: "\f747"; }

.fa-desktop::before {
  content: "\1f5a5"; }

.fa-desktop-alt::before {
  content: "\1f5a5"; }

.fa-desktop-arrow-down::before {
  content: "\e155"; }

.fa-dharmachakra::before {
  content: "\2638"; }

.fa-diagram-lean-canvas::before {
  content: "\e156"; }

.fa-diagram-nested::before {
  content: "\e157"; }

.fa-diagram-project::before {
  content: "\f542"; }

.fa-project-diagram::before {
  content: "\f542"; }

.fa-diagram-sankey::before {
  content: "\e158"; }

.fa-diagram-venn::before {
  content: "\e15a"; }

.fa-dial::before {
  content: "\e15b"; }

.fa-dial-med-high::before {
  content: "\e15b"; }

.fa-dial-high::before {
  content: "\e15c"; }

.fa-dial-low::before {
  content: "\e15d"; }

.fa-dial-max::before {
  content: "\e15e"; }

.fa-dial-med::before {
  content: "\e15f"; }

.fa-dial-med-low::before {
  content: "\e160"; }

.fa-dial-min::before {
  content: "\e161"; }

.fa-dial-off::before {
  content: "\e162"; }

.fa-diamond::before {
  content: "\2666"; }

.fa-diamond-turn-right::before {
  content: "\f5eb"; }

.fa-directions::before {
  content: "\f5eb"; }

.fa-dice::before {
  content: "\1f3b2"; }

.fa-dice-d10::before {
  content: "\f6cd"; }

.fa-dice-d12::before {
  content: "\f6ce"; }

.fa-dice-d20::before {
  content: "\f6cf"; }

.fa-dice-d4::before {
  content: "\f6d0"; }

.fa-dice-d6::before {
  content: "\f6d1"; }

.fa-dice-d8::before {
  content: "\f6d2"; }

.fa-dice-five::before {
  content: "\2684"; }

.fa-dice-four::before {
  content: "\2683"; }

.fa-dice-one::before {
  content: "\2680"; }

.fa-dice-six::before {
  content: "\2685"; }

.fa-dice-three::before {
  content: "\2682"; }

.fa-dice-two::before {
  content: "\2681"; }

.fa-diploma::before {
  content: "\f5ea"; }

.fa-scroll-ribbon::before {
  content: "\f5ea"; }

.fa-disc-drive::before {
  content: "\f8b5"; }

.fa-disease::before {
  content: "\f7fa"; }

.fa-display::before {
  content: "\e163"; }

.fa-display-arrow-down::before {
  content: "\e164"; }

.fa-display-code::before {
  content: "\e165"; }

.fa-desktop-code::before {
  content: "\e165"; }

.fa-display-medical::before {
  content: "\e166"; }

.fa-desktop-medical::before {
  content: "\e166"; }

.fa-display-slash::before {
  content: "\e2fa"; }

.fa-desktop-slash::before {
  content: "\e2fa"; }

.fa-distribute-spacing-horizontal::before {
  content: "\e365"; }

.fa-distribute-spacing-vertical::before {
  content: "\e366"; }

.fa-ditto::before {
  content: "\22"; }

.fa-divide::before {
  content: "\f7"; }

.fa-dna::before {
  content: "\1f9ec"; }

.fa-do-not-enter::before {
  content: "\f5ec"; }

.fa-dog::before {
  content: "\1f415"; }

.fa-dog-leashed::before {
  content: "\1f9ae"; }

.fa-dollar-sign::before {
  content: "\24"; }

.fa-dollar::before {
  content: "\24"; }

.fa-usd::before {
  content: "\24"; }

.fa-dolly::before {
  content: "\f472"; }

.fa-dolly-box::before {
  content: "\f472"; }

.fa-dolly-empty::before {
  content: "\f473"; }

.fa-dolphin::before {
  content: "\e168"; }

.fa-dong-sign::before {
  content: "\e169"; }

.fa-door-closed::before {
  content: "\1f6aa"; }

.fa-door-open::before {
  content: "\f52b"; }

.fa-dove::before {
  content: "\1f54a"; }

.fa-down::before {
  content: "\f354"; }

.fa-arrow-alt-down::before {
  content: "\f354"; }

.fa-down-from-line::before {
  content: "\f349"; }

.fa-arrow-alt-from-top::before {
  content: "\f349"; }

.fa-down-left::before {
  content: "\e16a"; }

.fa-down-left-and-up-right-to-center::before {
  content: "\f422"; }

.fa-compress-alt::before {
  content: "\f422"; }

.fa-down-long::before {
  content: "\f309"; }

.fa-long-arrow-alt-down::before {
  content: "\f309"; }

.fa-down-right::before {
  content: "\e16b"; }

.fa-down-to-line::before {
  content: "\f34a"; }

.fa-arrow-alt-to-bottom::before {
  content: "\f34a"; }

.fa-download::before {
  content: "\f019"; }

.fa-dragon::before {
  content: "\1f409"; }

.fa-draw-circle::before {
  content: "\f5ed"; }

.fa-draw-polygon::before {
  content: "\f5ee"; }

.fa-draw-square::before {
  content: "\f5ef"; }

.fa-dreidel::before {
  content: "\f792"; }

.fa-drone::before {
  content: "\f85f"; }

.fa-drone-front::before {
  content: "\f860"; }

.fa-drone-alt::before {
  content: "\f860"; }

.fa-droplet::before {
  content: "\1f4a7"; }

.fa-tint::before {
  content: "\1f4a7"; }

.fa-droplet-degree::before {
  content: "\f748"; }

.fa-dewpoint::before {
  content: "\f748"; }

.fa-droplet-percent::before {
  content: "\f750"; }

.fa-humidity::before {
  content: "\f750"; }

.fa-droplet-slash::before {
  content: "\f5c7"; }

.fa-tint-slash::before {
  content: "\f5c7"; }

.fa-drum::before {
  content: "\1f941"; }

.fa-drum-steelpan::before {
  content: "\f56a"; }

.fa-drumstick::before {
  content: "\1f357"; }

.fa-drumstick-bite::before {
  content: "\f6d7"; }

.fa-dryer::before {
  content: "\f861"; }

.fa-dryer-heat::before {
  content: "\f862"; }

.fa-dryer-alt::before {
  content: "\f862"; }

.fa-duck::before {
  content: "\1f986"; }

.fa-dumbbell::before {
  content: "\f44b"; }

.fa-dumpster::before {
  content: "\f793"; }

.fa-dumpster-fire::before {
  content: "\f794"; }

.fa-dungeon::before {
  content: "\f6d9"; }

.fa-e::before {
  content: "\45"; }

.fa-ear::before {
  content: "\1f442"; }

.fa-ear-deaf::before {
  content: "\f2a4"; }

.fa-deaf::before {
  content: "\f2a4"; }

.fa-deafness::before {
  content: "\f2a4"; }

.fa-hard-of-hearing::before {
  content: "\f2a4"; }

.fa-ear-listen::before {
  content: "\f2a2"; }

.fa-assistive-listening-systems::before {
  content: "\f2a2"; }

.fa-ear-muffs::before {
  content: "\f795"; }

.fa-earth-africa::before {
  content: "\1f30d"; }

.fa-globe-africa::before {
  content: "\1f30d"; }

.fa-earth-americas::before {
  content: "\1f30e"; }

.fa-earth::before {
  content: "\1f30e"; }

.fa-globe-americas::before {
  content: "\1f30e"; }

.fa-earth-asia::before {
  content: "\1f30f"; }

.fa-globe-asia::before {
  content: "\1f30f"; }

.fa-earth-europa::before {
  content: "\f7a2"; }

.fa-globe-europe::before {
  content: "\f7a2"; }

.fa-eclipse::before {
  content: "\f749"; }

.fa-egg::before {
  content: "\1f95a"; }

.fa-egg-fried::before {
  content: "\f7fc"; }

.fa-eggplant::before {
  content: "\e16c"; }

.fa-eject::before {
  content: "\23cf"; }

.fa-elephant::before {
  content: "\1f418"; }

.fa-elevator::before {
  content: "\e16d"; }

.fa-ellipsis::before {
  content: "\f141"; }

.fa-ellipsis-h::before {
  content: "\f141"; }

.fa-ellipsis-stroke::before {
  content: "\f39b"; }

.fa-ellipsis-h-alt::before {
  content: "\f39b"; }

.fa-ellipsis-stroke-vertical::before {
  content: "\f39c"; }

.fa-ellipsis-v-alt::before {
  content: "\f39c"; }

.fa-ellipsis-vertical::before {
  content: "\f142"; }

.fa-ellipsis-v::before {
  content: "\f142"; }

.fa-empty-set::before {
  content: "\d8"; }

.fa-engine::before {
  content: "\e16e"; }

.fa-engine-warning::before {
  content: "\f5f2"; }

.fa-engine-exclamation::before {
  content: "\f5f2"; }

.fa-envelope::before {
  content: "\2709"; }

.fa-envelope-dot::before {
  content: "\e16f"; }

.fa-envelope-badge::before {
  content: "\e16f"; }

.fa-envelope-open::before {
  content: "\f2b6"; }

.fa-envelope-open-dollar::before {
  content: "\f657"; }

.fa-envelope-open-text::before {
  content: "\f658"; }

.fa-envelopes::before {
  content: "\e170"; }

.fa-envelopes-bulk::before {
  content: "\f674"; }

.fa-mail-bulk::before {
  content: "\f674"; }

.fa-equals::before {
  content: "\3d"; }

.fa-eraser::before {
  content: "\f12d"; }

.fa-escalator::before {
  content: "\e171"; }

.fa-ethernet::before {
  content: "\f796"; }

.fa-euro-sign::before {
  content: "\20ac"; }

.fa-eur::before {
  content: "\20ac"; }

.fa-euro::before {
  content: "\20ac"; }

.fa-exclamation::before {
  content: "\21"; }

.fa-expand::before {
  content: "\f065"; }

.fa-expand-wide::before {
  content: "\f320"; }

.fa-eye::before {
  content: "\1f441"; }

.fa-eye-dropper::before {
  content: "\f1fb"; }

.fa-eye-dropper-empty::before {
  content: "\f1fb"; }

.fa-eyedropper::before {
  content: "\f1fb"; }

.fa-eye-dropper-full::before {
  content: "\e172"; }

.fa-eye-dropper-half::before {
  content: "\e173"; }

.fa-eye-evil::before {
  content: "\f6db"; }

.fa-eye-low-vision::before {
  content: "\f2a8"; }

.fa-low-vision::before {
  content: "\f2a8"; }

.fa-eye-slash::before {
  content: "\f070"; }

.fa-eyes::before {
  content: "\e367"; }

.fa-f::before {
  content: "\46"; }

.fa-face-angry::before {
  content: "\1f620"; }

.fa-angry::before {
  content: "\1f620"; }

.fa-face-angry-horns::before {
  content: "\e368"; }

.fa-face-anguished::before {
  content: "\e369"; }

.fa-face-anxious-sweat::before {
  content: "\e36a"; }

.fa-face-astonished::before {
  content: "\e36b"; }

.fa-face-confounded::before {
  content: "\e36c"; }

.fa-face-confused::before {
  content: "\e36d"; }

.fa-face-cowboy-hat::before {
  content: "\e36e"; }

.fa-face-disappointed::before {
  content: "\e36f"; }

.fa-face-disguise::before {
  content: "\e370"; }

.fa-face-dizzy::before {
  content: "\f567"; }

.fa-dizzy::before {
  content: "\f567"; }

.fa-face-downcast-sweat::before {
  content: "\e371"; }

.fa-face-drooling::before {
  content: "\e372"; }

.fa-face-explode::before {
  content: "\e2fe"; }

.fa-exploding-head::before {
  content: "\e2fe"; }

.fa-face-expressionless::before {
  content: "\e373"; }

.fa-face-eyes-xmarks::before {
  content: "\e374"; }

.fa-face-fearful::before {
  content: "\e375"; }

.fa-face-flushed::before {
  content: "\1f633"; }

.fa-flushed::before {
  content: "\1f633"; }

.fa-face-frown::before {
  content: "\2639"; }

.fa-frown::before {
  content: "\2639"; }

.fa-face-frown-open::before {
  content: "\1f626"; }

.fa-frown-open::before {
  content: "\1f626"; }

.fa-face-frown-slight::before {
  content: "\e376"; }

.fa-face-glasses::before {
  content: "\e377"; }

.fa-face-grimace::before {
  content: "\1f62c"; }

.fa-grimace::before {
  content: "\1f62c"; }

.fa-face-grin::before {
  content: "\1f600"; }

.fa-grin::before {
  content: "\1f600"; }

.fa-face-grin-beam::before {
  content: "\1f604"; }

.fa-grin-beam::before {
  content: "\1f604"; }

.fa-face-grin-beam-sweat::before {
  content: "\1f605"; }

.fa-grin-beam-sweat::before {
  content: "\1f605"; }

.fa-face-grin-hearts::before {
  content: "\1f60d"; }

.fa-grin-hearts::before {
  content: "\1f60d"; }

.fa-face-grin-squint::before {
  content: "\1f606"; }

.fa-grin-squint::before {
  content: "\1f606"; }

.fa-face-grin-squint-tears::before {
  content: "\1f923"; }

.fa-grin-squint-tears::before {
  content: "\1f923"; }

.fa-face-grin-stars::before {
  content: "\1f929"; }

.fa-grin-stars::before {
  content: "\1f929"; }

.fa-face-grin-tears::before {
  content: "\1f602"; }

.fa-grin-tears::before {
  content: "\1f602"; }

.fa-face-grin-tongue::before {
  content: "\1f61b"; }

.fa-grin-tongue::before {
  content: "\1f61b"; }

.fa-face-grin-tongue-squint::before {
  content: "\1f61d"; }

.fa-grin-tongue-squint::before {
  content: "\1f61d"; }

.fa-face-grin-tongue-wink::before {
  content: "\1f61c"; }

.fa-grin-tongue-wink::before {
  content: "\1f61c"; }

.fa-face-grin-wide::before {
  content: "\1f603"; }

.fa-grin-alt::before {
  content: "\1f603"; }

.fa-face-grin-wink::before {
  content: "\f58c"; }

.fa-grin-wink::before {
  content: "\f58c"; }

.fa-face-hand-over-mouth::before {
  content: "\e378"; }

.fa-face-hand-yawn::before {
  content: "\e379"; }

.fa-face-head-bandage::before {
  content: "\e37a"; }

.fa-face-hushed::before {
  content: "\e37b"; }

.fa-face-icicles::before {
  content: "\e37c"; }

.fa-face-kiss::before {
  content: "\1f617"; }

.fa-kiss::before {
  content: "\1f617"; }

.fa-face-kiss-beam::before {
  content: "\1f619"; }

.fa-kiss-beam::before {
  content: "\1f619"; }

.fa-face-kiss-closed-eyes::before {
  content: "\e37d"; }

.fa-face-kiss-wink-heart::before {
  content: "\1f618"; }

.fa-kiss-wink-heart::before {
  content: "\1f618"; }

.fa-face-laugh::before {
  content: "\f599"; }

.fa-laugh::before {
  content: "\f599"; }

.fa-face-laugh-beam::before {
  content: "\1f601"; }

.fa-laugh-beam::before {
  content: "\1f601"; }

.fa-face-laugh-squint::before {
  content: "\f59b"; }

.fa-laugh-squint::before {
  content: "\f59b"; }

.fa-face-laugh-wink::before {
  content: "\f59c"; }

.fa-laugh-wink::before {
  content: "\f59c"; }

.fa-face-lying::before {
  content: "\e37e"; }

.fa-face-mask::before {
  content: "\e37f"; }

.fa-face-meh::before {
  content: "\1f610"; }

.fa-meh::before {
  content: "\1f610"; }

.fa-face-meh-blank::before {
  content: "\1f636"; }

.fa-meh-blank::before {
  content: "\1f636"; }

.fa-face-monocle::before {
  content: "\e380"; }

.fa-face-nauseated::before {
  content: "\e381"; }

.fa-face-nose-steam::before {
  content: "\e382"; }

.fa-face-party::before {
  content: "\e383"; }

.fa-face-pensive::before {
  content: "\e384"; }

.fa-face-persevering::before {
  content: "\e385"; }

.fa-face-pleading::before {
  content: "\e386"; }

.fa-face-pouting::before {
  content: "\e387"; }

.fa-face-raised-eyebrow::before {
  content: "\e388"; }

.fa-face-relieved::before {
  content: "\e389"; }

.fa-face-rolling-eyes::before {
  content: "\1f644"; }

.fa-meh-rolling-eyes::before {
  content: "\1f644"; }

.fa-face-sad-cry::before {
  content: "\1f62d"; }

.fa-sad-cry::before {
  content: "\1f62d"; }

.fa-face-sad-sweat::before {
  content: "\e38a"; }

.fa-face-sad-tear::before {
  content: "\1f622"; }

.fa-sad-tear::before {
  content: "\1f622"; }

.fa-face-scream::before {
  content: "\e38b"; }

.fa-face-shush::before {
  content: "\e38c"; }

.fa-face-sleeping::before {
  content: "\e38d"; }

.fa-face-sleepy::before {
  content: "\e38e"; }

.fa-face-smile::before {
  content: "\1f642"; }

.fa-smile::before {
  content: "\1f642"; }

.fa-face-smile-beam::before {
  content: "\1f60a"; }

.fa-smile-beam::before {
  content: "\1f60a"; }

.fa-face-smile-halo::before {
  content: "\e38f"; }

.fa-face-smile-hearts::before {
  content: "\e390"; }

.fa-face-smile-horns::before {
  content: "\e391"; }

.fa-face-smile-plus::before {
  content: "\f5b9"; }

.fa-smile-plus::before {
  content: "\f5b9"; }

.fa-face-smile-relaxed::before {
  content: "\e392"; }

.fa-face-smile-tear::before {
  content: "\e393"; }

.fa-face-smile-tongue::before {
  content: "\e394"; }

.fa-face-smile-upside-down::before {
  content: "\e395"; }

.fa-face-smile-wink::before {
  content: "\1f609"; }

.fa-smile-wink::before {
  content: "\1f609"; }

.fa-face-smiling-hands::before {
  content: "\e396"; }

.fa-face-smirking::before {
  content: "\e397"; }

.fa-face-sunglasses::before {
  content: "\e398"; }

.fa-face-surprise::before {
  content: "\1f62e"; }

.fa-surprise::before {
  content: "\1f62e"; }

.fa-face-swear::before {
  content: "\e399"; }

.fa-face-thermometer::before {
  content: "\e39a"; }

.fa-face-thinking::before {
  content: "\e39b"; }

.fa-face-tired::before {
  content: "\1f62b"; }

.fa-tired::before {
  content: "\1f62b"; }

.fa-face-tissue::before {
  content: "\e39c"; }

.fa-face-tongue-money::before {
  content: "\e39d"; }

.fa-face-tongue-sweat::before {
  content: "\e39e"; }

.fa-face-unamused::before {
  content: "\e39f"; }

.fa-face-viewfinder::before {
  content: "\e2ff"; }

.fa-face-vomit::before {
  content: "\e3a0"; }

.fa-face-weary::before {
  content: "\e3a1"; }

.fa-face-woozy::before {
  content: "\e3a2"; }

.fa-face-worried::before {
  content: "\e3a3"; }

.fa-face-zany::before {
  content: "\e3a4"; }

.fa-face-zipper::before {
  content: "\e3a5"; }

.fa-family::before {
  content: "\e300"; }

.fa-family-dress::before {
  content: "\e301"; }

.fa-family-pants::before {
  content: "\e302"; }

.fa-fan::before {
  content: "\f863"; }

.fa-fan-table::before {
  content: "\e004"; }

.fa-farm::before {
  content: "\f864"; }

.fa-barn-silo::before {
  content: "\f864"; }

.fa-faucet::before {
  content: "\e005"; }

.fa-faucet-drip::before {
  content: "\1f6b0"; }

.fa-fax::before {
  content: "\1f4e0"; }

.fa-feather::before {
  content: "\1fab6"; }

.fa-feather-pointed::before {
  content: "\f56b"; }

.fa-feather-alt::before {
  content: "\f56b"; }

.fa-fence::before {
  content: "\e303"; }

.fa-ferris-wheel::before {
  content: "\e174"; }

.fa-field-hockey-stick-ball::before {
  content: "\1f3d1"; }

.fa-field-hockey::before {
  content: "\1f3d1"; }

.fa-file::before {
  content: "\1f4c4"; }

.fa-file-arrow-down::before {
  content: "\f56d"; }

.fa-file-download::before {
  content: "\f56d"; }

.fa-file-arrow-up::before {
  content: "\f574"; }

.fa-file-upload::before {
  content: "\f574"; }

.fa-file-audio::before {
  content: "\f1c7"; }

.fa-file-binary::before {
  content: "\e175"; }

.fa-file-certificate::before {
  content: "\f5f3"; }

.fa-file-award::before {
  content: "\f5f3"; }

.fa-file-chart-column::before {
  content: "\f659"; }

.fa-file-chart-line::before {
  content: "\f659"; }

.fa-file-chart-pie::before {
  content: "\f65a"; }

.fa-file-check::before {
  content: "\f316"; }

.fa-file-code::before {
  content: "\f1c9"; }

.fa-file-contract::before {
  content: "\f56c"; }

.fa-file-csv::before {
  content: "\f6dd"; }

.fa-file-dashed-line::before {
  content: "\f877"; }

.fa-page-break::before {
  content: "\f877"; }

.fa-file-excel::before {
  content: "\f1c3"; }

.fa-file-exclamation::before {
  content: "\f31a"; }

.fa-file-export::before {
  content: "\f56e"; }

.fa-arrow-right-from-file::before {
  content: "\f56e"; }

.fa-file-heart::before {
  content: "\e176"; }

.fa-file-image::before {
  content: "\1f5bb"; }

.fa-file-import::before {
  content: "\f56f"; }

.fa-arrow-right-to-file::before {
  content: "\f56f"; }

.fa-file-invoice::before {
  content: "\f570"; }

.fa-file-invoice-dollar::before {
  content: "\f571"; }

.fa-file-lines::before {
  content: "\1f5b9"; }

.fa-file-alt::before {
  content: "\1f5b9"; }

.fa-file-text::before {
  content: "\1f5b9"; }

.fa-file-lock::before {
  content: "\e3a6"; }

.fa-file-magnifying-glass::before {
  content: "\f865"; }

.fa-file-search::before {
  content: "\f865"; }

.fa-file-medical::before {
  content: "\f477"; }

.fa-file-minus::before {
  content: "\f318"; }

.fa-file-music::before {
  content: "\f8b6"; }

.fa-file-pdf::before {
  content: "\f1c1"; }

.fa-file-pen::before {
  content: "\1f4dd"; }

.fa-file-edit::before {
  content: "\1f4dd"; }

.fa-file-plus::before {
  content: "\f319"; }

.fa-file-plus-minus::before {
  content: "\e177"; }

.fa-file-powerpoint::before {
  content: "\f1c4"; }

.fa-file-prescription::before {
  content: "\f572"; }

.fa-file-signature::before {
  content: "\f573"; }

.fa-file-slash::before {
  content: "\e3a7"; }

.fa-file-spreadsheet::before {
  content: "\f65b"; }

.fa-file-user::before {
  content: "\f65c"; }

.fa-file-video::before {
  content: "\f1c8"; }

.fa-file-waveform::before {
  content: "\f478"; }

.fa-file-medical-alt::before {
  content: "\f478"; }

.fa-file-word::before {
  content: "\f1c2"; }

.fa-file-xmark::before {
  content: "\f317"; }

.fa-file-times::before {
  content: "\f317"; }

.fa-file-zipper::before {
  content: "\f1c6"; }

.fa-file-archive::before {
  content: "\f1c6"; }

.fa-files::before {
  content: "\e178"; }

.fa-files-medical::before {
  content: "\f7fd"; }

.fa-fill::before {
  content: "\f575"; }

.fa-fill-drip::before {
  content: "\f576"; }

.fa-film::before {
  content: "\1f39e"; }

.fa-film-canister::before {
  content: "\f8b7"; }

.fa-film-simple::before {
  content: "\f3a0"; }

.fa-film-alt::before {
  content: "\f3a0"; }

.fa-film-slash::before {
  content: "\e179"; }

.fa-films::before {
  content: "\e17a"; }

.fa-filter::before {
  content: "\f0b0"; }

.fa-filter-circle-dollar::before {
  content: "\f662"; }

.fa-funnel-dollar::before {
  content: "\f662"; }

.fa-filter-circle-xmark::before {
  content: "\e17b"; }

.fa-filter-list::before {
  content: "\e17c"; }

.fa-filter-slash::before {
  content: "\e17d"; }

.fa-filters::before {
  content: "\e17e"; }

.fa-fingerprint::before {
  content: "\f577"; }

.fa-fire::before {
  content: "\1f525"; }

.fa-fire-extinguisher::before {
  content: "\1f9ef"; }

.fa-fire-flame::before {
  content: "\f6df"; }

.fa-flame::before {
  content: "\f6df"; }

.fa-fire-flame-curved::before {
  content: "\f7e4"; }

.fa-fire-alt::before {
  content: "\f7e4"; }

.fa-fire-flame-simple::before {
  content: "\f46a"; }

.fa-burn::before {
  content: "\f46a"; }

.fa-fire-hydrant::before {
  content: "\e17f"; }

.fa-fire-smoke::before {
  content: "\f74b"; }

.fa-fireplace::before {
  content: "\f79a"; }

.fa-fish::before {
  content: "\1f41f"; }

.fa-fish-bones::before {
  content: "\e304"; }

.fa-fish-cooked::before {
  content: "\f7fe"; }

.fa-fishing-rod::before {
  content: "\e3a8"; }

.fa-flag::before {
  content: "\1f3f4"; }

.fa-flag-checkered::before {
  content: "\1f3c1"; }

.fa-flag-pennant::before {
  content: "\1f6a9"; }

.fa-pennant::before {
  content: "\1f6a9"; }

.fa-flag-swallowtail::before {
  content: "\f74c"; }

.fa-flag-alt::before {
  content: "\f74c"; }

.fa-flag-usa::before {
  content: "\f74d"; }

.fa-flashlight::before {
  content: "\1f526"; }

.fa-flask::before {
  content: "\f0c3"; }

.fa-flask-round-poison::before {
  content: "\f6e0"; }

.fa-flask-poison::before {
  content: "\f6e0"; }

.fa-flask-round-potion::before {
  content: "\f6e1"; }

.fa-flask-potion::before {
  content: "\f6e1"; }

.fa-floppy-disk::before {
  content: "\1f4be"; }

.fa-save::before {
  content: "\1f4be"; }

.fa-floppy-disk-circle-arrow-right::before {
  content: "\e180"; }

.fa-save-circle-arrow-right::before {
  content: "\e180"; }

.fa-floppy-disk-circle-xmark::before {
  content: "\e181"; }

.fa-floppy-disk-times::before {
  content: "\e181"; }

.fa-save-circle-xmark::before {
  content: "\e181"; }

.fa-save-times::before {
  content: "\e181"; }

.fa-floppy-disk-pen::before {
  content: "\e182"; }

.fa-floppy-disks::before {
  content: "\e183"; }

.fa-florin-sign::before {
  content: "\e184"; }

.fa-flower::before {
  content: "\273f"; }

.fa-flower-daffodil::before {
  content: "\2698"; }

.fa-flower-tulip::before {
  content: "\1f337"; }

.fa-flute::before {
  content: "\f8b9"; }

.fa-flux-capacitor::before {
  content: "\f8ba"; }

.fa-flying-disc::before {
  content: "\e3a9"; }

.fa-folder::before {
  content: "\1f4c1"; }

.fa-folder-arrow-down::before {
  content: "\e053"; }

.fa-folder-download::before {
  content: "\e053"; }

.fa-folder-arrow-up::before {
  content: "\e054"; }

.fa-folder-upload::before {
  content: "\e054"; }

.fa-folder-blank::before {
  content: "\e185"; }

.fa-folder-bookmark::before {
  content: "\e186"; }

.fa-folder-gear::before {
  content: "\e187"; }

.fa-folder-cog::before {
  content: "\e187"; }

.fa-folder-grid::before {
  content: "\e188"; }

.fa-folder-heart::before {
  content: "\e189"; }

.fa-folder-image::before {
  content: "\e18a"; }

.fa-folder-magnifying-glass::before {
  content: "\e18b"; }

.fa-folder-search::before {
  content: "\e18b"; }

.fa-folder-medical::before {
  content: "\e18c"; }

.fa-folder-minus::before {
  content: "\f65d"; }

.fa-folder-music::before {
  content: "\e18d"; }

.fa-folder-open::before {
  content: "\1f4c2"; }

.fa-folder-plus::before {
  content: "\f65e"; }

.fa-folder-tree::before {
  content: "\f802"; }

.fa-folder-user::before {
  content: "\e18e"; }

.fa-folder-xmark::before {
  content: "\f65f"; }

.fa-folder-times::before {
  content: "\f65f"; }

.fa-folders::before {
  content: "\f660"; }

.fa-font::before {
  content: "\f031"; }

.fa-font-case::before {
  content: "\f866"; }

.fa-football::before {
  content: "\1f3c8"; }

.fa-football-ball::before {
  content: "\1f3c8"; }

.fa-football-helmet::before {
  content: "\f44f"; }

.fa-fork::before {
  content: "\f2e3"; }

.fa-utensil-fork::before {
  content: "\f2e3"; }

.fa-fork-knife::before {
  content: "\f2e6"; }

.fa-utensils-alt::before {
  content: "\f2e6"; }

.fa-forklift::before {
  content: "\f47a"; }

.fa-forward::before {
  content: "\23e9"; }

.fa-forward-fast::before {
  content: "\23ed"; }

.fa-fast-forward::before {
  content: "\23ed"; }

.fa-forward-step::before {
  content: "\f051"; }

.fa-step-forward::before {
  content: "\f051"; }

.fa-franc-sign::before {
  content: "\e18f"; }

.fa-french-fries::before {
  content: "\1f35f"; }

.fa-frog::before {
  content: "\f52e"; }

.fa-function::before {
  content: "\f661"; }

.fa-futbol::before {
  content: "\26bd"; }

.fa-futbol-ball::before {
  content: "\26bd"; }

.fa-soccer-ball::before {
  content: "\26bd"; }

.fa-g::before {
  content: "\47"; }

.fa-galaxy::before {
  content: "\e008"; }

.fa-gallery-thumbnails::before {
  content: "\e3aa"; }

.fa-game-board::before {
  content: "\f867"; }

.fa-game-board-simple::before {
  content: "\f868"; }

.fa-game-board-alt::before {
  content: "\f868"; }

.fa-game-console-handheld::before {
  content: "\f8bb"; }

.fa-gamepad::before {
  content: "\f11b"; }

.fa-gamepad-modern::before {
  content: "\1f3ae"; }

.fa-gamepad-alt::before {
  content: "\1f3ae"; }

.fa-garage::before {
  content: "\e009"; }

.fa-garage-car::before {
  content: "\e00a"; }

.fa-garage-open::before {
  content: "\e00b"; }

.fa-gas-pump::before {
  content: "\26fd"; }

.fa-gas-pump-slash::before {
  content: "\f5f4"; }

.fa-gauge::before {
  content: "\f625"; }

.fa-dashboard::before {
  content: "\f625"; }

.fa-gauge-high::before {
  content: "\f625"; }

.fa-tachometer-alt::before {
  content: "\f625"; }

.fa-tachometer-alt-fast::before {
  content: "\f625"; }

.fa-gauge-low::before {
  content: "\f627"; }

.fa-tachometer-alt-slow::before {
  content: "\f627"; }

.fa-gauge-max::before {
  content: "\f626"; }

.fa-tachometer-alt-fastest::before {
  content: "\f626"; }

.fa-gauge-med::before {
  content: "\f624"; }

.fa-tachometer-alt-average::before {
  content: "\f624"; }

.fa-gauge-min::before {
  content: "\f628"; }

.fa-tachometer-alt-slowest::before {
  content: "\f628"; }

.fa-gauge-simple::before {
  content: "\f62a"; }

.fa-gauge-simple-high::before {
  content: "\f62a"; }

.fa-tachometer::before {
  content: "\f62a"; }

.fa-gauge-simple-low::before {
  content: "\f62c"; }

.fa-tachometer-slow::before {
  content: "\f62c"; }

.fa-gauge-simple-max::before {
  content: "\f62b"; }

.fa-tachometer-fastest::before {
  content: "\f62b"; }

.fa-gauge-simple-med::before {
  content: "\f629"; }

.fa-tachometer-average::before {
  content: "\f629"; }

.fa-gauge-simple-min::before {
  content: "\f62d"; }

.fa-tachometer-slowest::before {
  content: "\f62d"; }

.fa-gavel::before {
  content: "\f0e3"; }

.fa-legal::before {
  content: "\f0e3"; }

.fa-gear::before {
  content: "\2699"; }

.fa-cog::before {
  content: "\2699"; }

.fa-gears::before {
  content: "\f085"; }

.fa-cogs::before {
  content: "\f085"; }

.fa-gem::before {
  content: "\1f48e"; }

.fa-genderless::before {
  content: "\f22d"; }

.fa-ghost::before {
  content: "\1f47b"; }

.fa-gif::before {
  content: "\e190"; }

.fa-gift::before {
  content: "\1f381"; }

.fa-gift-card::before {
  content: "\f663"; }

.fa-gifts::before {
  content: "\f79c"; }

.fa-gingerbread-man::before {
  content: "\f79d"; }

.fa-glass::before {
  content: "\1f95b"; }

.fa-glass-citrus::before {
  content: "\f869"; }

.fa-glass-empty::before {
  content: "\e191"; }

.fa-glass-half::before {
  content: "\e192"; }

.fa-glass-half-empty::before {
  content: "\e192"; }

.fa-glass-half-full::before {
  content: "\e192"; }

.fa-glasses::before {
  content: "\f530"; }

.fa-glasses-round::before {
  content: "\1f453"; }

.fa-glasses-alt::before {
  content: "\1f453"; }

.fa-globe::before {
  content: "\1f310"; }

.fa-globe-snow::before {
  content: "\f7a3"; }

.fa-globe-stand::before {
  content: "\f5f6"; }

.fa-goal-net::before {
  content: "\e3ab"; }

.fa-golf-ball-tee::before {
  content: "\f450"; }

.fa-golf-ball::before {
  content: "\f450"; }

.fa-golf-club::before {
  content: "\f451"; }

.fa-golf-flag-hole::before {
  content: "\e3ac"; }

.fa-gopuram::before {
  content: "\f664"; }

.fa-graduation-cap::before {
  content: "\1f393"; }

.fa-mortar-board::before {
  content: "\1f393"; }

.fa-gramophone::before {
  content: "\f8bd"; }

.fa-grapes::before {
  content: "\e306"; }

.fa-grate::before {
  content: "\e193"; }

.fa-grate-droplet::before {
  content: "\e194"; }

.fa-greater-than::before {
  content: "\3e"; }

.fa-greater-than-equal::before {
  content: "\f532"; }

.fa-grid::before {
  content: "\e195"; }

.fa-grid-3::before {
  content: "\e195"; }

.fa-grid-2::before {
  content: "\e196"; }

.fa-grid-2-plus::before {
  content: "\e197"; }

.fa-grid-4::before {
  content: "\e198"; }

.fa-grid-5::before {
  content: "\e199"; }

.fa-grid-dividers::before {
  content: "\e3ad"; }

.fa-grid-horizontal::before {
  content: "\e307"; }

.fa-grip::before {
  content: "\f58d"; }

.fa-grip-horizontal::before {
  content: "\f58d"; }

.fa-grip-lines::before {
  content: "\f7a4"; }

.fa-grip-lines-vertical::before {
  content: "\f7a5"; }

.fa-grip-vertical::before {
  content: "\f58e"; }

.fa-guarani-sign::before {
  content: "\e19a"; }

.fa-guitar::before {
  content: "\f7a6"; }

.fa-guitar-electric::before {
  content: "\1f3b8"; }

.fa-guitars::before {
  content: "\f8bf"; }

.fa-gun::before {
  content: "\e19b"; }

.fa-gun-slash::before {
  content: "\e19c"; }

.fa-gun-squirt::before {
  content: "\e19d"; }

.fa-h::before {
  content: "\48"; }

.fa-h1::before {
  content: "\f313"; }

.fa-h2::before {
  content: "\f314"; }

.fa-h3::before {
  content: "\f315"; }

.fa-h4::before {
  content: "\f86a"; }

.fa-hammer::before {
  content: "\1f528"; }

.fa-hammer-war::before {
  content: "\f6e4"; }

.fa-hamsa::before {
  content: "\f665"; }

.fa-hand::before {
  content: "\270b"; }

.fa-hand-paper::before {
  content: "\270b"; }

.fa-hand-back-fist::before {
  content: "\f255"; }

.fa-hand-rock::before {
  content: "\f255"; }

.fa-hand-back-point-down::before {
  content: "\e19e"; }

.fa-hand-back-point-left::before {
  content: "\e19f"; }

.fa-hand-back-point-ribbon::before {
  content: "\e1a0"; }

.fa-hand-back-point-right::before {
  content: "\e1a1"; }

.fa-hand-back-point-up::before {
  content: "\e1a2"; }

.fa-hand-dots::before {
  content: "\f461"; }

.fa-allergies::before {
  content: "\f461"; }

.fa-hand-fingers-crossed::before {
  content: "\e1a3"; }

.fa-hand-fist::before {
  content: "\270a"; }

.fa-fist-raised::before {
  content: "\270a"; }

.fa-hand-heart::before {
  content: "\f4bc"; }

.fa-hand-holding::before {
  content: "\f4bd"; }

.fa-hand-holding-box::before {
  content: "\f47b"; }

.fa-hand-holding-dollar::before {
  content: "\f4c0"; }

.fa-hand-holding-usd::before {
  content: "\f4c0"; }

.fa-hand-holding-droplet::before {
  content: "\f4c1"; }

.fa-hand-holding-water::before {
  content: "\f4c1"; }

.fa-hand-holding-heart::before {
  content: "\f4be"; }

.fa-hand-holding-magic::before {
  content: "\f6e5"; }

.fa-hand-holding-medical::before {
  content: "\e05c"; }

.fa-hand-holding-seedling::before {
  content: "\f4bf"; }

.fa-hand-holding-skull::before {
  content: "\e1a4"; }

.fa-hand-horns::before {
  content: "\e1a9"; }

.fa-hand-lizard::before {
  content: "\f258"; }

.fa-hand-love::before {
  content: "\e1a5"; }

.fa-hand-middle-finger::before {
  content: "\1f595"; }

.fa-hand-peace::before {
  content: "\270c"; }

.fa-hand-point-down::before {
  content: "\f0a7"; }

.fa-hand-point-left::before {
  content: "\f0a5"; }

.fa-hand-point-ribbon::before {
  content: "\e1a6"; }

.fa-hand-point-right::before {
  content: "\f0a4"; }

.fa-hand-point-up::before {
  content: "\261d"; }

.fa-hand-pointer::before {
  content: "\f25a"; }

.fa-hand-scissors::before {
  content: "\f257"; }

.fa-hand-sparkles::before {
  content: "\e05d"; }

.fa-hand-spock::before {
  content: "\1f596"; }

.fa-hand-wave::before {
  content: "\e1a7"; }

.fa-hands::before {
  content: "\f2a7"; }

.fa-sign-language::before {
  content: "\f2a7"; }

.fa-signing::before {
  content: "\f2a7"; }

.fa-hands-asl-interpreting::before {
  content: "\f2a3"; }

.fa-american-sign-language-interpreting::before {
  content: "\f2a3"; }

.fa-asl-interpreting::before {
  content: "\f2a3"; }

.fa-hands-american-sign-language-interpreting::before {
  content: "\f2a3"; }

.fa-hands-bubbles::before {
  content: "\e05e"; }

.fa-hands-wash::before {
  content: "\e05e"; }

.fa-hands-clapping::before {
  content: "\e1a8"; }

.fa-hands-holding::before {
  content: "\f4c2"; }

.fa-hands-holding-diamond::before {
  content: "\f47c"; }

.fa-hand-receiving::before {
  content: "\f47c"; }

.fa-hands-holding-dollar::before {
  content: "\f4c5"; }

.fa-hands-usd::before {
  content: "\f4c5"; }

.fa-hands-holding-heart::before {
  content: "\f4c3"; }

.fa-hands-heart::before {
  content: "\f4c3"; }

.fa-hands-praying::before {
  content: "\f684"; }

.fa-praying-hands::before {
  content: "\f684"; }

.fa-handshake::before {
  content: "\f2b5"; }

.fa-handshake-angle::before {
  content: "\f4c4"; }

.fa-hands-helping::before {
  content: "\f4c4"; }

.fa-handshake-simple::before {
  content: "\1f91d"; }

.fa-handshake-alt::before {
  content: "\1f91d"; }

.fa-handshake-simple-slash::before {
  content: "\e05f"; }

.fa-handshake-alt-slash::before {
  content: "\e05f"; }

.fa-handshake-slash::before {
  content: "\e060"; }

.fa-hanukiah::before {
  content: "\1f54e"; }

.fa-hard-drive::before {
  content: "\1f5b4"; }

.fa-hdd::before {
  content: "\1f5b4"; }

.fa-hashtag::before {
  content: "\23"; }

.fa-hat-chef::before {
  content: "\f86b"; }

.fa-hat-cowboy::before {
  content: "\f8c0"; }

.fa-hat-cowboy-side::before {
  content: "\f8c1"; }

.fa-hat-santa::before {
  content: "\f7a7"; }

.fa-hat-winter::before {
  content: "\f7a8"; }

.fa-hat-witch::before {
  content: "\f6e7"; }

.fa-hat-wizard::before {
  content: "\f6e8"; }

.fa-head-side::before {
  content: "\f6e9"; }

.fa-head-side-brain::before {
  content: "\f808"; }

.fa-head-side-cough::before {
  content: "\e061"; }

.fa-head-side-cough-slash::before {
  content: "\e062"; }

.fa-head-side-goggles::before {
  content: "\f6ea"; }

.fa-head-vr::before {
  content: "\f6ea"; }

.fa-head-side-headphones::before {
  content: "\f8c2"; }

.fa-head-side-heart::before {
  content: "\e1aa"; }

.fa-head-side-mask::before {
  content: "\e063"; }

.fa-head-side-medical::before {
  content: "\f809"; }

.fa-head-side-virus::before {
  content: "\e064"; }

.fa-heading::before {
  content: "\f1dc"; }

.fa-header::before {
  content: "\f1dc"; }

.fa-headphones::before {
  content: "\1f3a7"; }

.fa-headphones-simple::before {
  content: "\f58f"; }

.fa-headphones-alt::before {
  content: "\f58f"; }

.fa-headset::before {
  content: "\f590"; }

.fa-heart::before {
  content: "\2665"; }

.fa-heart-crack::before {
  content: "\1f494"; }

.fa-heart-broken::before {
  content: "\1f494"; }

.fa-heart-half::before {
  content: "\e1ab"; }

.fa-heart-half-stroke::before {
  content: "\e1ac"; }

.fa-heart-half-alt::before {
  content: "\e1ac"; }

.fa-heart-pulse::before {
  content: "\f21e"; }

.fa-heartbeat::before {
  content: "\f21e"; }

.fa-heat::before {
  content: "\e00c"; }

.fa-helicopter::before {
  content: "\1f681"; }

.fa-helmet-battle::before {
  content: "\f6eb"; }

.fa-helmet-safety::before {
  content: "\f807"; }

.fa-hard-hat::before {
  content: "\f807"; }

.fa-hat-hard::before {
  content: "\f807"; }

.fa-hexagon::before {
  content: "\2b23"; }

.fa-hexagon-divide::before {
  content: "\e1ad"; }

.fa-hexagon-minus::before {
  content: "\f307"; }

.fa-minus-hexagon::before {
  content: "\f307"; }

.fa-hexagon-plus::before {
  content: "\f300"; }

.fa-plus-hexagon::before {
  content: "\f300"; }

.fa-hexagon-xmark::before {
  content: "\f2ee"; }

.fa-times-hexagon::before {
  content: "\f2ee"; }

.fa-xmark-hexagon::before {
  content: "\f2ee"; }

.fa-high-definition::before {
  content: "\e1ae"; }

.fa-rectangle-hd::before {
  content: "\e1ae"; }

.fa-highlighter::before {
  content: "\f591"; }

.fa-highlighter-line::before {
  content: "\e1af"; }

.fa-hippo::before {
  content: "\1f99b"; }

.fa-hockey-mask::before {
  content: "\f6ee"; }

.fa-hockey-puck::before {
  content: "\f453"; }

.fa-hockey-stick-puck::before {
  content: "\e3ae"; }

.fa-hockey-sticks::before {
  content: "\f454"; }

.fa-holly-berry::before {
  content: "\f7aa"; }

.fa-hood-cloak::before {
  content: "\f6ef"; }

.fa-horizontal-rule::before {
  content: "\2015"; }

.fa-horse::before {
  content: "\1f40e"; }

.fa-horse-head::before {
  content: "\f7ab"; }

.fa-horse-saddle::before {
  content: "\f8c3"; }

.fa-hospital::before {
  content: "\1f3e5"; }

.fa-hospital-user::before {
  content: "\f80d"; }

.fa-hospital-wide::before {
  content: "\f47d"; }

.fa-hospital-alt::before {
  content: "\f47d"; }

.fa-hospitals::before {
  content: "\f80e"; }

.fa-hot-tub-person::before {
  content: "\f593"; }

.fa-hot-tub::before {
  content: "\f593"; }

.fa-hotdog::before {
  content: "\1f32d"; }

.fa-hotel::before {
  content: "\1f3e8"; }

.fa-hourglass::before {
  content: "\23f3"; }

.fa-hourglass-2::before {
  content: "\23f3"; }

.fa-hourglass-half::before {
  content: "\23f3"; }

.fa-hourglass-empty::before {
  content: "\f252"; }

.fa-hourglass-end::before {
  content: "\231b"; }

.fa-hourglass-3::before {
  content: "\231b"; }

.fa-hourglass-start::before {
  content: "\f251"; }

.fa-hourglass-1::before {
  content: "\f251"; }

.fa-house::before {
  content: "\1f3e0"; }

.fa-home::before {
  content: "\1f3e0"; }

.fa-house-blank::before {
  content: "\f80a"; }

.fa-home-blank::before {
  content: "\f80a"; }

.fa-home-lg-alt::before {
  content: "\f80a"; }

.fa-house-building::before {
  content: "\e1b1"; }

.fa-house-chimney::before {
  content: "\e3af"; }

.fa-home-lg::before {
  content: "\e3af"; }

.fa-house-chimney-blank::before {
  content: "\e3b0"; }

.fa-house-chimney-crack::before {
  content: "\f6f1"; }

.fa-house-damage::before {
  content: "\f6f1"; }

.fa-house-chimney-heart::before {
  content: "\e1b2"; }

.fa-house-chimney-medical::before {
  content: "\f7f2"; }

.fa-clinic-medical::before {
  content: "\f7f2"; }

.fa-house-chimney-user::before {
  content: "\e065"; }

.fa-house-chimney-window::before {
  content: "\e00d"; }

.fa-house-crack::before {
  content: "\e3b1"; }

.fa-house-day::before {
  content: "\e00e"; }

.fa-house-flood::before {
  content: "\f74f"; }

.fa-house-heart::before {
  content: "\f4c9"; }

.fa-home-heart::before {
  content: "\f4c9"; }

.fa-house-laptop::before {
  content: "\e066"; }

.fa-laptop-house::before {
  content: "\e066"; }

.fa-house-medical::before {
  content: "\e3b2"; }

.fa-house-night::before {
  content: "\e010"; }

.fa-house-person-leave::before {
  content: "\e00f"; }

.fa-house-person-depart::before {
  content: "\e00f"; }

.fa-house-person-return::before {
  content: "\e011"; }

.fa-house-person-arrive::before {
  content: "\e011"; }

.fa-house-signal::before {
  content: "\e012"; }

.fa-house-tree::before {
  content: "\e1b3"; }

.fa-house-turret::before {
  content: "\e1b4"; }

.fa-house-user::before {
  content: "\e1b0"; }

.fa-home-user::before {
  content: "\e1b0"; }

.fa-house-window::before {
  content: "\e3b3"; }

.fa-hryvnia-sign::before {
  content: "\20b4"; }

.fa-hryvnia::before {
  content: "\20b4"; }

.fa-hurricane::before {
  content: "\f751"; }

.fa-hyphen::before {
  content: "\2d"; }

.fa-i::before {
  content: "\49"; }

.fa-i-cursor::before {
  content: "\f246"; }

.fa-ice-cream::before {
  content: "\1f368"; }

.fa-ice-skate::before {
  content: "\26f8"; }

.fa-icicles::before {
  content: "\f7ad"; }

.fa-icons::before {
  content: "\f86d"; }

.fa-heart-music-camera-bolt::before {
  content: "\f86d"; }

.fa-id-badge::before {
  content: "\f2c1"; }

.fa-id-card::before {
  content: "\f2c2"; }

.fa-drivers-license::before {
  content: "\f2c2"; }

.fa-id-card-clip::before {
  content: "\f47f"; }

.fa-id-card-alt::before {
  content: "\f47f"; }

.fa-igloo::before {
  content: "\f7ae"; }

.fa-image::before {
  content: "\f03e"; }

.fa-image-landscape::before {
  content: "\e1b5"; }

.fa-landscape::before {
  content: "\e1b5"; }

.fa-image-polaroid::before {
  content: "\f8c4"; }

.fa-image-polaroid-user::before {
  content: "\e1b6"; }

.fa-image-portrait::before {
  content: "\f3e0"; }

.fa-portrait::before {
  content: "\f3e0"; }

.fa-image-slash::before {
  content: "\e1b7"; }

.fa-image-user::before {
  content: "\e1b8"; }

.fa-images::before {
  content: "\f302"; }

.fa-images-user::before {
  content: "\e1b9"; }

.fa-inbox::before {
  content: "\f01c"; }

.fa-inbox-full::before {
  content: "\e1ba"; }

.fa-inbox-in::before {
  content: "\1f4e5"; }

.fa-inbox-arrow-down::before {
  content: "\1f4e5"; }

.fa-inbox-out::before {
  content: "\1f4e4"; }

.fa-inbox-arrow-up::before {
  content: "\1f4e4"; }

.fa-inboxes::before {
  content: "\e1bb"; }

.fa-indent::before {
  content: "\f03c"; }

.fa-indian-rupee-sign::before {
  content: "\e1bc"; }

.fa-indian-rupee::before {
  content: "\e1bc"; }

.fa-inr::before {
  content: "\e1bc"; }

.fa-industry::before {
  content: "\f275"; }

.fa-industry-windows::before {
  content: "\1f3ed"; }

.fa-industry-alt::before {
  content: "\1f3ed"; }

.fa-infinity::before {
  content: "\221e"; }

.fa-info::before {
  content: "\f129"; }

.fa-inhaler::before {
  content: "\f5f9"; }

.fa-input-numeric::before {
  content: "\e1bd"; }

.fa-input-pipe::before {
  content: "\e1be"; }

.fa-input-text::before {
  content: "\e1bf"; }

.fa-integral::before {
  content: "\f667"; }

.fa-intersection::before {
  content: "\22c2"; }

.fa-island-tropical::before {
  content: "\1f3dd"; }

.fa-island-tree-palm::before {
  content: "\1f3dd"; }

.fa-italic::before {
  content: "\f033"; }

.fa-j::before {
  content: "\4a"; }

.fa-jack-o-lantern::before {
  content: "\1f383"; }

.fa-jedi::before {
  content: "\f669"; }

.fa-jet-fighter::before {
  content: "\f0fb"; }

.fa-fighter-jet::before {
  content: "\f0fb"; }

.fa-joint::before {
  content: "\f595"; }

.fa-joystick::before {
  content: "\1f579"; }

.fa-jug::before {
  content: "\f8c6"; }

.fa-k::before {
  content: "\4b"; }

.fa-kaaba::before {
  content: "\1f54b"; }

.fa-kazoo::before {
  content: "\f8c7"; }

.fa-kerning::before {
  content: "\f86f"; }

.fa-key::before {
  content: "\1f511"; }

.fa-key-skeleton::before {
  content: "\1f5dd"; }

.fa-key-skeleton-left-right::before {
  content: "\e3b4"; }

.fa-keyboard::before {
  content: "\2328"; }

.fa-keyboard-brightness::before {
  content: "\e1c0"; }

.fa-keyboard-brightness-low::before {
  content: "\e1c1"; }

.fa-keyboard-down::before {
  content: "\e1c2"; }

.fa-keyboard-left::before {
  content: "\e1c3"; }

.fa-keynote::before {
  content: "\f66c"; }

.fa-khanda::before {
  content: "\262c"; }

.fa-kidneys::before {
  content: "\f5fb"; }

.fa-kip-sign::before {
  content: "\e1c4"; }

.fa-kit-medical::before {
  content: "\f479"; }

.fa-first-aid::before {
  content: "\f479"; }

.fa-kite::before {
  content: "\1fa81"; }

.fa-kiwi-bird::before {
  content: "\f535"; }

.fa-kiwi-fruit::before {
  content: "\e30c"; }

.fa-knife::before {
  content: "\f2e4"; }

.fa-utensil-knife::before {
  content: "\f2e4"; }

.fa-knife-kitchen::before {
  content: "\1f52a"; }

.fa-l::before {
  content: "\4c"; }

.fa-lacrosse-stick::before {
  content: "\e3b5"; }

.fa-lacrosse-stick-ball::before {
  content: "\e3b6"; }

.fa-lambda::before {
  content: "\f66e"; }

.fa-lamp::before {
  content: "\f4ca"; }

.fa-lamp-desk::before {
  content: "\e014"; }

.fa-lamp-floor::before {
  content: "\e015"; }

.fa-lamp-street::before {
  content: "\e1c5"; }

.fa-landmark::before {
  content: "\1f3db"; }

.fa-landmark-dome::before {
  content: "\f752"; }

.fa-landmark-alt::before {
  content: "\f752"; }

.fa-language::before {
  content: "\f1ab"; }

.fa-laptop::before {
  content: "\1f4bb"; }

.fa-laptop-arrow-down::before {
  content: "\e1c6"; }

.fa-laptop-code::before {
  content: "\f5fc"; }

.fa-laptop-medical::before {
  content: "\f812"; }

.fa-laptop-mobile::before {
  content: "\f87a"; }

.fa-phone-laptop::before {
  content: "\f87a"; }

.fa-laptop-slash::before {
  content: "\e1c7"; }

.fa-lari-sign::before {
  content: "\e1c8"; }

.fa-lasso::before {
  content: "\f8c8"; }

.fa-lasso-sparkles::before {
  content: "\e1c9"; }

.fa-layer-group::before {
  content: "\f5fd"; }

.fa-layer-minus::before {
  content: "\f5fe"; }

.fa-layer-group-minus::before {
  content: "\f5fe"; }

.fa-layer-plus::before {
  content: "\f5ff"; }

.fa-layer-group-plus::before {
  content: "\f5ff"; }

.fa-leaf::before {
  content: "\f06c"; }

.fa-leaf-heart::before {
  content: "\f4cb"; }

.fa-leaf-maple::before {
  content: "\1f341"; }

.fa-leaf-oak::before {
  content: "\f6f7"; }

.fa-left::before {
  content: "\f355"; }

.fa-arrow-alt-left::before {
  content: "\f355"; }

.fa-left-from-line::before {
  content: "\f348"; }

.fa-arrow-alt-from-right::before {
  content: "\f348"; }

.fa-left-long::before {
  content: "\f30a"; }

.fa-long-arrow-alt-left::before {
  content: "\f30a"; }

.fa-left-right::before {
  content: "\2194"; }

.fa-arrows-alt-h::before {
  content: "\2194"; }

.fa-left-to-line::before {
  content: "\f34b"; }

.fa-arrow-alt-to-left::before {
  content: "\f34b"; }

.fa-lemon::before {
  content: "\1f34b"; }

.fa-less-than::before {
  content: "\3c"; }

.fa-less-than-equal::before {
  content: "\f537"; }

.fa-life-ring::before {
  content: "\f1cd"; }

.fa-light-ceiling::before {
  content: "\e016"; }

.fa-light-switch::before {
  content: "\e017"; }

.fa-light-switch-off::before {
  content: "\e018"; }

.fa-light-switch-on::before {
  content: "\e019"; }

.fa-lightbulb::before {
  content: "\1f4a1"; }

.fa-lightbulb-dollar::before {
  content: "\f670"; }

.fa-lightbulb-exclamation::before {
  content: "\f671"; }

.fa-lightbulb-exclamation-on::before {
  content: "\e1ca"; }

.fa-lightbulb-on::before {
  content: "\f672"; }

.fa-lightbulb-slash::before {
  content: "\f673"; }

.fa-lights-holiday::before {
  content: "\f7b2"; }

.fa-line-columns::before {
  content: "\f870"; }

.fa-line-height::before {
  content: "\f871"; }

.fa-link::before {
  content: "\1f517"; }

.fa-chain::before {
  content: "\1f517"; }

.fa-link-horizontal::before {
  content: "\e1cb"; }

.fa-chain-horizontal::before {
  content: "\e1cb"; }

.fa-link-horizontal-slash::before {
  content: "\e1cc"; }

.fa-chain-horizontal-slash::before {
  content: "\e1cc"; }

.fa-link-simple::before {
  content: "\e1cd"; }

.fa-link-simple-slash::before {
  content: "\e1ce"; }

.fa-link-slash::before {
  content: "\f127"; }

.fa-chain-broken::before {
  content: "\f127"; }

.fa-chain-slash::before {
  content: "\f127"; }

.fa-unlink::before {
  content: "\f127"; }

.fa-lips::before {
  content: "\1f444"; }

.fa-lira-sign::before {
  content: "\20a4"; }

.fa-list::before {
  content: "\f03a"; }

.fa-list-squares::before {
  content: "\f03a"; }

.fa-list-check::before {
  content: "\f0ae"; }

.fa-tasks::before {
  content: "\f0ae"; }

.fa-list-dropdown::before {
  content: "\e1cf"; }

.fa-list-music::before {
  content: "\f8c9"; }

.fa-list-ol::before {
  content: "\f0cb"; }

.fa-list-1-2::before {
  content: "\f0cb"; }

.fa-list-numeric::before {
  content: "\f0cb"; }

.fa-list-radio::before {
  content: "\e1d0"; }

.fa-list-timeline::before {
  content: "\e1d1"; }

.fa-list-tree::before {
  content: "\e1d2"; }

.fa-list-ul::before {
  content: "\f0ca"; }

.fa-list-dots::before {
  content: "\f0ca"; }

.fa-litecoin-sign::before {
  content: "\e1d3"; }

.fa-loader::before {
  content: "\e1d4"; }

.fa-location::before {
  content: "\f041"; }

.fa-map-marker::before {
  content: "\f041"; }

.fa-location-arrow::before {
  content: "\f124"; }

.fa-location-check::before {
  content: "\f606"; }

.fa-map-marker-check::before {
  content: "\f606"; }

.fa-location-crosshairs::before {
  content: "\f601"; }

.fa-location-crosshairs-slash::before {
  content: "\f603"; }

.fa-location-dot::before {
  content: "\f3c5"; }

.fa-map-marker-alt::before {
  content: "\f3c5"; }

.fa-location-dot-slash::before {
  content: "\f605"; }

.fa-map-marker-alt-slash::before {
  content: "\f605"; }

.fa-location-exclamation::before {
  content: "\f608"; }

.fa-map-marker-exclamation::before {
  content: "\f608"; }

.fa-location-minus::before {
  content: "\f609"; }

.fa-map-marker-minus::before {
  content: "\f609"; }

.fa-location-pen::before {
  content: "\f607"; }

.fa-map-marker-edit::before {
  content: "\f607"; }

.fa-location-plus::before {
  content: "\f60a"; }

.fa-map-marker-plus::before {
  content: "\f60a"; }

.fa-location-question::before {
  content: "\f60b"; }

.fa-map-marker-question::before {
  content: "\f60b"; }

.fa-location-slash::before {
  content: "\f60c"; }

.fa-map-marker-slash::before {
  content: "\f60c"; }

.fa-location-smile::before {
  content: "\f60d"; }

.fa-map-marker-smile::before {
  content: "\f60d"; }

.fa-location-xmark::before {
  content: "\f60e"; }

.fa-map-marker-times::before {
  content: "\f60e"; }

.fa-map-marker-xmark::before {
  content: "\f60e"; }

.fa-lock::before {
  content: "\1f512"; }

.fa-lock-keyhole::before {
  content: "\f30d"; }

.fa-lock-alt::before {
  content: "\f30d"; }

.fa-lock-keyhole-open::before {
  content: "\f3c2"; }

.fa-lock-open-alt::before {
  content: "\f3c2"; }

.fa-lock-open::before {
  content: "\f3c1"; }

.fa-loveseat::before {
  content: "\f4cc"; }

.fa-couch-small::before {
  content: "\f4cc"; }

.fa-luchador-mask::before {
  content: "\f455"; }

.fa-luchador::before {
  content: "\f455"; }

.fa-mask-luchador::before {
  content: "\f455"; }

.fa-lungs::before {
  content: "\1fac1"; }

.fa-lungs-virus::before {
  content: "\e067"; }

.fa-m::before {
  content: "\4d"; }

.fa-mace::before {
  content: "\f6f8"; }

.fa-magnet::before {
  content: "\1f9f2"; }

.fa-magnifying-glass::before {
  content: "\1f50d"; }

.fa-search::before {
  content: "\1f50d"; }

.fa-magnifying-glass-dollar::before {
  content: "\f688"; }

.fa-search-dollar::before {
  content: "\f688"; }

.fa-magnifying-glass-location::before {
  content: "\f689"; }

.fa-search-location::before {
  content: "\f689"; }

.fa-magnifying-glass-minus::before {
  content: "\f010"; }

.fa-search-minus::before {
  content: "\f010"; }

.fa-magnifying-glass-plus::before {
  content: "\f00e"; }

.fa-search-plus::before {
  content: "\f00e"; }

.fa-mailbox::before {
  content: "\1f4ea"; }

.fa-manat-sign::before {
  content: "\e1d5"; }

.fa-mandolin::before {
  content: "\f6f9"; }

.fa-mango::before {
  content: "\e30f"; }

.fa-manhole::before {
  content: "\e1d6"; }

.fa-map::before {
  content: "\1f5fa"; }

.fa-map-location::before {
  content: "\f59f"; }

.fa-map-marked::before {
  content: "\f59f"; }

.fa-map-location-dot::before {
  content: "\f5a0"; }

.fa-map-marked-alt::before {
  content: "\f5a0"; }

.fa-map-pin::before {
  content: "\1f4cd"; }

.fa-marker::before {
  content: "\f5a1"; }

.fa-mars::before {
  content: "\2642"; }

.fa-mars-and-venus::before {
  content: "\26a5"; }

.fa-mars-double::before {
  content: "\26a3"; }

.fa-mars-stroke::before {
  content: "\26a6"; }

.fa-mars-stroke-right::before {
  content: "\26a9"; }

.fa-mars-stroke-h::before {
  content: "\26a9"; }

.fa-mars-stroke-up::before {
  content: "\26a8"; }

.fa-mars-stroke-v::before {
  content: "\26a8"; }

.fa-martini-glass::before {
  content: "\1f378"; }

.fa-glass-martini-alt::before {
  content: "\1f378"; }

.fa-martini-glass-citrus::before {
  content: "\f561"; }

.fa-cocktail::before {
  content: "\f561"; }

.fa-martini-glass-empty::before {
  content: "\f000"; }

.fa-glass-martini::before {
  content: "\f000"; }

.fa-mask::before {
  content: "\f6fa"; }

.fa-mask-face::before {
  content: "\e1d7"; }

.fa-mask-snorkel::before {
  content: "\e3b7"; }

.fa-masks-theater::before {
  content: "\1f3ad"; }

.fa-theater-masks::before {
  content: "\1f3ad"; }

.fa-maximize::before {
  content: "\f31e"; }

.fa-expand-arrows-alt::before {
  content: "\f31e"; }

.fa-meat::before {
  content: "\1f356"; }

.fa-medal::before {
  content: "\1f3c5"; }

.fa-megaphone::before {
  content: "\1f4e3"; }

.fa-melon::before {
  content: "\e310"; }

.fa-melon-slice::before {
  content: "\e311"; }

.fa-memo::before {
  content: "\e1d8"; }

.fa-memo-circle-check::before {
  content: "\e1d9"; }

.fa-memo-pad::before {
  content: "\e1da"; }

.fa-memory::before {
  content: "\f538"; }

.fa-menorah::before {
  content: "\f676"; }

.fa-mercury::before {
  content: "\263f"; }

.fa-message::before {
  content: "\f27a"; }

.fa-comment-alt::before {
  content: "\f27a"; }

.fa-message-arrow-down::before {
  content: "\e1db"; }

.fa-comment-alt-arrow-down::before {
  content: "\e1db"; }

.fa-message-arrow-up::before {
  content: "\e1dc"; }

.fa-comment-alt-arrow-up::before {
  content: "\e1dc"; }

.fa-message-arrow-up-right::before {
  content: "\e1dd"; }

.fa-message-bot::before {
  content: "\e3b8"; }

.fa-message-captions::before {
  content: "\e1de"; }

.fa-comment-alt-captions::before {
  content: "\e1de"; }

.fa-message-check::before {
  content: "\f4a2"; }

.fa-comment-alt-check::before {
  content: "\f4a2"; }

.fa-message-code::before {
  content: "\e1df"; }

.fa-message-dollar::before {
  content: "\f650"; }

.fa-comment-alt-dollar::before {
  content: "\f650"; }

.fa-message-dots::before {
  content: "\f4a3"; }

.fa-comment-alt-dots::before {
  content: "\f4a3"; }

.fa-messaging::before {
  content: "\f4a3"; }

.fa-message-exclamation::before {
  content: "\f4a5"; }

.fa-comment-alt-exclamation::before {
  content: "\f4a5"; }

.fa-message-image::before {
  content: "\e1e0"; }

.fa-comment-alt-image::before {
  content: "\e1e0"; }

.fa-message-lines::before {
  content: "\f4a6"; }

.fa-comment-alt-lines::before {
  content: "\f4a6"; }

.fa-message-medical::before {
  content: "\f7f4"; }

.fa-comment-alt-medical::before {
  content: "\f7f4"; }

.fa-message-middle::before {
  content: "\e1e1"; }

.fa-comment-middle-alt::before {
  content: "\e1e1"; }

.fa-message-middle-top::before {
  content: "\e1e2"; }

.fa-comment-middle-top-alt::before {
  content: "\e1e2"; }

.fa-message-minus::before {
  content: "\f4a7"; }

.fa-comment-alt-minus::before {
  content: "\f4a7"; }

.fa-message-music::before {
  content: "\f8af"; }

.fa-comment-alt-music::before {
  content: "\f8af"; }

.fa-message-pen::before {
  content: "\f4a4"; }

.fa-comment-alt-edit::before {
  content: "\f4a4"; }

.fa-message-edit::before {
  content: "\f4a4"; }

.fa-message-plus::before {
  content: "\f4a8"; }

.fa-comment-alt-plus::before {
  content: "\f4a8"; }

.fa-message-question::before {
  content: "\e1e3"; }

.fa-message-quote::before {
  content: "\e1e4"; }

.fa-comment-alt-quote::before {
  content: "\e1e4"; }

.fa-message-slash::before {
  content: "\f4a9"; }

.fa-comment-alt-slash::before {
  content: "\f4a9"; }

.fa-message-smile::before {
  content: "\f4aa"; }

.fa-comment-alt-smile::before {
  content: "\f4aa"; }

.fa-message-sms::before {
  content: "\e1e5"; }

.fa-message-text::before {
  content: "\e1e6"; }

.fa-comment-alt-text::before {
  content: "\e1e6"; }

.fa-message-xmark::before {
  content: "\f4ab"; }

.fa-comment-alt-times::before {
  content: "\f4ab"; }

.fa-message-times::before {
  content: "\f4ab"; }

.fa-messages::before {
  content: "\f4b6"; }

.fa-comments-alt::before {
  content: "\f4b6"; }

.fa-messages-dollar::before {
  content: "\f652"; }

.fa-comments-alt-dollar::before {
  content: "\f652"; }

.fa-messages-question::before {
  content: "\e1e7"; }

.fa-meteor::before {
  content: "\2604"; }

.fa-meter::before {
  content: "\e1e8"; }

.fa-meter-bolt::before {
  content: "\e1e9"; }

.fa-meter-droplet::before {
  content: "\e1ea"; }

.fa-meter-fire::before {
  content: "\e1eb"; }

.fa-microchip::before {
  content: "\f2db"; }

.fa-microchip-ai::before {
  content: "\e1ec"; }

.fa-microphone::before {
  content: "\f130"; }

.fa-microphone-lines::before {
  content: "\1f399"; }

.fa-microphone-alt::before {
  content: "\1f399"; }

.fa-microphone-lines-slash::before {
  content: "\f539"; }

.fa-microphone-alt-slash::before {
  content: "\f539"; }

.fa-microphone-slash::before {
  content: "\f131"; }

.fa-microphone-stand::before {
  content: "\1f3a4"; }

.fa-microscope::before {
  content: "\1f52c"; }

.fa-microwave::before {
  content: "\e01b"; }

.fa-mill-sign::before {
  content: "\e1ed"; }

.fa-minimize::before {
  content: "\f78c"; }

.fa-compress-arrows-alt::before {
  content: "\f78c"; }

.fa-minus::before {
  content: "\2013"; }

.fa-subtract::before {
  content: "\2013"; }

.fa-mistletoe::before {
  content: "\f7b4"; }

.fa-mitten::before {
  content: "\f7b5"; }

.fa-mobile::before {
  content: "\1f4f1"; }

.fa-mobile-android::before {
  content: "\1f4f1"; }

.fa-mobile-phone::before {
  content: "\1f4f1"; }

.fa-mobile-button::before {
  content: "\f10b"; }

.fa-mobile-notch::before {
  content: "\e1ee"; }

.fa-mobile-iphone::before {
  content: "\e1ee"; }

.fa-mobile-screen::before {
  content: "\f3cf"; }

.fa-mobile-android-alt::before {
  content: "\f3cf"; }

.fa-mobile-screen-button::before {
  content: "\f3cd"; }

.fa-mobile-alt::before {
  content: "\f3cd"; }

.fa-mobile-signal::before {
  content: "\e1ef"; }

.fa-mobile-signal-out::before {
  content: "\e1f0"; }

.fa-money-bill::before {
  content: "\f0d6"; }

.fa-money-bill-1::before {
  content: "\f3d1"; }

.fa-money-bill-alt::before {
  content: "\f3d1"; }

.fa-money-bill-1-wave::before {
  content: "\f53b"; }

.fa-money-bill-wave-alt::before {
  content: "\f53b"; }

.fa-money-bill-simple::before {
  content: "\e1f1"; }

.fa-money-bill-simple-wave::before {
  content: "\e1f2"; }

.fa-money-bill-wave::before {
  content: "\f53a"; }

.fa-money-bills::before {
  content: "\e1f3"; }

.fa-money-bills-simple::before {
  content: "\e1f4"; }

.fa-money-bills-alt::before {
  content: "\e1f4"; }

.fa-money-check::before {
  content: "\f53c"; }

.fa-money-check-dollar::before {
  content: "\f53d"; }

.fa-money-check-alt::before {
  content: "\f53d"; }

.fa-money-check-dollar-pen::before {
  content: "\f873"; }

.fa-money-check-edit-alt::before {
  content: "\f873"; }

.fa-money-check-pen::before {
  content: "\f872"; }

.fa-money-check-edit::before {
  content: "\f872"; }

.fa-money-from-bracket::before {
  content: "\e312"; }

.fa-money-simple-from-bracket::before {
  content: "\e313"; }

.fa-monitor-waveform::before {
  content: "\f611"; }

.fa-monitor-heart-rate::before {
  content: "\f611"; }

.fa-monkey::before {
  content: "\1f412"; }

.fa-monument::before {
  content: "\f5a6"; }

.fa-moon::before {
  content: "\23fe"; }

.fa-moon-cloud::before {
  content: "\f754"; }

.fa-moon-over-sun::before {
  content: "\f74a"; }

.fa-eclipse-alt::before {
  content: "\f74a"; }

.fa-moon-stars::before {
  content: "\f755"; }

.fa-moped::before {
  content: "\e3b9"; }

.fa-mortar-pestle::before {
  content: "\f5a7"; }

.fa-mosque::before {
  content: "\1f54c"; }

.fa-motorcycle::before {
  content: "\1f3cd"; }

.fa-mountain::before {
  content: "\1f3d4"; }

.fa-mountains::before {
  content: "\26f0"; }

.fa-mp3-player::before {
  content: "\f8ce"; }

.fa-mug::before {
  content: "\f874"; }

.fa-mug-hot::before {
  content: "\2615"; }

.fa-mug-marshmallows::before {
  content: "\f7b7"; }

.fa-mug-saucer::before {
  content: "\f0f4"; }

.fa-coffee::before {
  content: "\f0f4"; }

.fa-mug-tea::before {
  content: "\f875"; }

.fa-mug-tea-saucer::before {
  content: "\e1f5"; }

.fa-music::before {
  content: "\1f3b5"; }

.fa-music-note::before {
  content: "\f8cf"; }

.fa-music-alt::before {
  content: "\f8cf"; }

.fa-music-note-slash::before {
  content: "\f8d0"; }

.fa-music-alt-slash::before {
  content: "\f8d0"; }

.fa-music-slash::before {
  content: "\f8d1"; }

.fa-n::before {
  content: "\4e"; }

.fa-naira-sign::before {
  content: "\e1f6"; }

.fa-narwhal::before {
  content: "\f6fe"; }

.fa-nesting-dolls::before {
  content: "\e3ba"; }

.fa-network-wired::before {
  content: "\f6ff"; }

.fa-neuter::before {
  content: "\26b2"; }

.fa-newspaper::before {
  content: "\1f4f0"; }

.fa-nfc::before {
  content: "\e1f7"; }

.fa-nfc-lock::before {
  content: "\e1f8"; }

.fa-nfc-magnifying-glass::before {
  content: "\e1f9"; }

.fa-nfc-pen::before {
  content: "\e1fa"; }

.fa-nfc-signal::before {
  content: "\e1fb"; }

.fa-nfc-slash::before {
  content: "\e1fc"; }

.fa-nfc-trash::before {
  content: "\e1fd"; }

.fa-not-equal::before {
  content: "\f53e"; }

.fa-notdef::before {
  content: "\e1fe"; }

.fa-note::before {
  content: "\e1ff"; }

.fa-note-medical::before {
  content: "\e200"; }

.fa-note-sticky::before {
  content: "\f249"; }

.fa-sticky-note::before {
  content: "\f249"; }

.fa-notebook::before {
  content: "\e201"; }

.fa-notes::before {
  content: "\e202"; }

.fa-notes-medical::before {
  content: "\f481"; }

.fa-o::before {
  content: "\4f"; }

.fa-object-group::before {
  content: "\f247"; }

.fa-object-ungroup::before {
  content: "\f248"; }

.fa-objects-align-bottom::before {
  content: "\e3bb"; }

.fa-objects-align-center-horizontal::before {
  content: "\e3bc"; }

.fa-objects-align-center-vertical::before {
  content: "\e3bd"; }

.fa-objects-align-left::before {
  content: "\e3be"; }

.fa-objects-align-right::before {
  content: "\e3bf"; }

.fa-objects-align-top::before {
  content: "\e3c0"; }

.fa-objects-column::before {
  content: "\e3c1"; }

.fa-octagon::before {
  content: "\1f6d1"; }

.fa-octagon-divide::before {
  content: "\e203"; }

.fa-octagon-exclamation::before {
  content: "\e204"; }

.fa-octagon-minus::before {
  content: "\f308"; }

.fa-minus-octagon::before {
  content: "\f308"; }

.fa-octagon-plus::before {
  content: "\f301"; }

.fa-plus-octagon::before {
  content: "\f301"; }

.fa-octagon-xmark::before {
  content: "\f2f0"; }

.fa-times-octagon::before {
  content: "\f2f0"; }

.fa-xmark-octagon::before {
  content: "\f2f0"; }

.fa-oil-can::before {
  content: "\f613"; }

.fa-oil-can-drip::before {
  content: "\e205"; }

.fa-oil-temperature::before {
  content: "\f614"; }

.fa-oil-temp::before {
  content: "\f614"; }

.fa-olive::before {
  content: "\e316"; }

.fa-olive-branch::before {
  content: "\e317"; }

.fa-om::before {
  content: "\1f549"; }

.fa-omega::before {
  content: "\f67a"; }

.fa-option::before {
  content: "\e318"; }

.fa-ornament::before {
  content: "\f7b8"; }

.fa-otter::before {
  content: "\1f9a6"; }

.fa-outdent::before {
  content: "\f03b"; }

.fa-dedent::before {
  content: "\f03b"; }

.fa-outlet::before {
  content: "\e01c"; }

.fa-oven::before {
  content: "\e01d"; }

.fa-overline::before {
  content: "\f876"; }

.fa-p::before {
  content: "\50"; }

.fa-pager::before {
  content: "\1f4df"; }

.fa-paint-brush::before {
  content: "\1f58c"; }

.fa-paint-brush-fine::before {
  content: "\f5a9"; }

.fa-paint-brush-alt::before {
  content: "\f5a9"; }

.fa-paint-roller::before {
  content: "\f5aa"; }

.fa-paintbrush-pencil::before {
  content: "\e206"; }

.fa-palette::before {
  content: "\1f3a8"; }

.fa-pallet::before {
  content: "\f482"; }

.fa-pallet-box::before {
  content: "\e208"; }

.fa-pallet-boxes::before {
  content: "\f483"; }

.fa-palette-boxes::before {
  content: "\f483"; }

.fa-pallet-alt::before {
  content: "\f483"; }

.fa-panorama::before {
  content: "\e209"; }

.fa-paper-plane::before {
  content: "\f1d8"; }

.fa-paper-plane-top::before {
  content: "\e20a"; }

.fa-paper-plane-alt::before {
  content: "\e20a"; }

.fa-send::before {
  content: "\e20a"; }

.fa-paperclip::before {
  content: "\1f4ce"; }

.fa-paperclip-vertical::before {
  content: "\e3c2"; }

.fa-parachute-box::before {
  content: "\f4cd"; }

.fa-paragraph::before {
  content: "\b6"; }

.fa-paragraph-left::before {
  content: "\f878"; }

.fa-paragraph-rtl::before {
  content: "\f878"; }

.fa-party-bell::before {
  content: "\e31a"; }

.fa-party-horn::before {
  content: "\e31b"; }

.fa-passport::before {
  content: "\f5ab"; }

.fa-paste::before {
  content: "\f0ea"; }

.fa-file-clipboard::before {
  content: "\f0ea"; }

.fa-pause::before {
  content: "\23f8"; }

.fa-paw::before {
  content: "\f1b0"; }

.fa-paw-claws::before {
  content: "\f702"; }

.fa-paw-simple::before {
  content: "\f701"; }

.fa-paw-alt::before {
  content: "\f701"; }

.fa-peace::before {
  content: "\262e"; }

.fa-peach::before {
  content: "\e20b"; }

.fa-peapod::before {
  content: "\e31c"; }

.fa-pear::before {
  content: "\e20c"; }

.fa-pedestal::before {
  content: "\e20d"; }

.fa-pegasus::before {
  content: "\f703"; }

.fa-pen::before {
  content: "\1f58a"; }

.fa-pen-circle::before {
  content: "\e20e"; }

.fa-pen-clip::before {
  content: "\f305"; }

.fa-pen-alt::before {
  content: "\f305"; }

.fa-pen-clip-slash::before {
  content: "\e20f"; }

.fa-pen-alt-slash::before {
  content: "\e20f"; }

.fa-pen-fancy::before {
  content: "\2712"; }

.fa-pen-fancy-slash::before {
  content: "\e210"; }

.fa-pen-field::before {
  content: "\e211"; }

.fa-pen-line::before {
  content: "\e212"; }

.fa-pen-nib::before {
  content: "\2711"; }

.fa-pen-paintbrush::before {
  content: "\f618"; }

.fa-pencil-paintbrush::before {
  content: "\f618"; }

.fa-pen-ruler::before {
  content: "\f5ae"; }

.fa-pencil-ruler::before {
  content: "\f5ae"; }

.fa-pen-slash::before {
  content: "\e213"; }

.fa-pen-swirl::before {
  content: "\e214"; }

.fa-pen-to-square::before {
  content: "\f044"; }

.fa-edit::before {
  content: "\f044"; }

.fa-pencil::before {
  content: "\270f"; }

.fa-pencil-alt::before {
  content: "\270f"; }

.fa-pencil-slash::before {
  content: "\e215"; }

.fa-people::before {
  content: "\e216"; }

.fa-people-arrows-left-right::before {
  content: "\e068"; }

.fa-people-arrows::before {
  content: "\e068"; }

.fa-people-carry-box::before {
  content: "\f4ce"; }

.fa-people-carry::before {
  content: "\f4ce"; }

.fa-people-dress::before {
  content: "\e217"; }

.fa-people-dress-simple::before {
  content: "\e218"; }

.fa-people-pants::before {
  content: "\e219"; }

.fa-people-pants-simple::before {
  content: "\e21a"; }

.fa-people-simple::before {
  content: "\e21b"; }

.fa-pepper-hot::before {
  content: "\1f336"; }

.fa-percent::before {
  content: "\25"; }

.fa-percentage::before {
  content: "\25"; }

.fa-period::before {
  content: "\2e"; }

.fa-person::before {
  content: "\1f9cd"; }

.fa-male::before {
  content: "\1f9cd"; }

.fa-person-biking::before {
  content: "\1f6b4"; }

.fa-biking::before {
  content: "\1f6b4"; }

.fa-person-biking-mountain::before {
  content: "\1f6b5"; }

.fa-biking-mountain::before {
  content: "\1f6b5"; }

.fa-person-booth::before {
  content: "\f756"; }

.fa-person-carry-box::before {
  content: "\f4cf"; }

.fa-person-carry::before {
  content: "\f4cf"; }

.fa-person-digging::before {
  content: "\f85e"; }

.fa-digging::before {
  content: "\f85e"; }

.fa-person-dolly::before {
  content: "\f4d0"; }

.fa-person-dolly-empty::before {
  content: "\f4d1"; }

.fa-person-dots-from-line::before {
  content: "\f470"; }

.fa-diagnoses::before {
  content: "\f470"; }

.fa-person-dress::before {
  content: "\f182"; }

.fa-female::before {
  content: "\f182"; }

.fa-person-dress-simple::before {
  content: "\e21c"; }

.fa-person-from-portal::before {
  content: "\e023"; }

.fa-portal-exit::before {
  content: "\e023"; }

.fa-person-hiking::before {
  content: "\f6ec"; }

.fa-hiking::before {
  content: "\f6ec"; }

.fa-person-pinball::before {
  content: "\e21d"; }

.fa-person-praying::before {
  content: "\1f6d0"; }

.fa-pray::before {
  content: "\1f6d0"; }

.fa-person-pregnant::before {
  content: "\e31e"; }

.fa-person-running::before {
  content: "\1f3c3"; }

.fa-running::before {
  content: "\1f3c3"; }

.fa-person-seat::before {
  content: "\e21e"; }

.fa-person-seat-reclined::before {
  content: "\e21f"; }

.fa-person-sign::before {
  content: "\f757"; }

.fa-person-simple::before {
  content: "\e220"; }

.fa-person-skating::before {
  content: "\f7c5"; }

.fa-skating::before {
  content: "\f7c5"; }

.fa-person-ski-jumping::before {
  content: "\f7c7"; }

.fa-ski-jump::before {
  content: "\f7c7"; }

.fa-person-ski-lift::before {
  content: "\f7c8"; }

.fa-ski-lift::before {
  content: "\f7c8"; }

.fa-person-skiing::before {
  content: "\26f7"; }

.fa-skiing::before {
  content: "\26f7"; }

.fa-person-skiing-nordic::before {
  content: "\f7ca"; }

.fa-skiing-nordic::before {
  content: "\f7ca"; }

.fa-person-sledding::before {
  content: "\f7cb"; }

.fa-sledding::before {
  content: "\f7cb"; }

.fa-person-snowboarding::before {
  content: "\1f3c2"; }

.fa-snowboarding::before {
  content: "\1f3c2"; }

.fa-person-snowmobiling::before {
  content: "\f7d1"; }

.fa-snowmobile::before {
  content: "\f7d1"; }

.fa-person-swimming::before {
  content: "\1f3ca"; }

.fa-swimmer::before {
  content: "\1f3ca"; }

.fa-person-to-portal::before {
  content: "\e022"; }

.fa-portal-enter::before {
  content: "\e022"; }

.fa-person-walking::before {
  content: "\1f6b6"; }

.fa-walking::before {
  content: "\1f6b6"; }

.fa-person-walking-with-cane::before {
  content: "\f29d"; }

.fa-blind::before {
  content: "\f29d"; }

.fa-peseta-sign::before {
  content: "\e221"; }

.fa-peso-sign::before {
  content: "\e222"; }

.fa-phone::before {
  content: "\1f4de"; }

.fa-phone-arrow-down-left::before {
  content: "\e223"; }

.fa-phone-arrow-down::before {
  content: "\e223"; }

.fa-phone-incoming::before {
  content: "\e223"; }

.fa-phone-arrow-up-right::before {
  content: "\e224"; }

.fa-phone-arrow-up::before {
  content: "\e224"; }

.fa-phone-outgoing::before {
  content: "\e224"; }

.fa-phone-flip::before {
  content: "\1f57d"; }

.fa-phone-alt::before {
  content: "\1f57d"; }

.fa-phone-hangup::before {
  content: "\e225"; }

.fa-phone-missed::before {
  content: "\e226"; }

.fa-phone-office::before {
  content: "\f67d"; }

.fa-phone-plus::before {
  content: "\f4d2"; }

.fa-phone-rotary::before {
  content: "\260e"; }

.fa-phone-slash::before {
  content: "\f3dd"; }

.fa-phone-volume::before {
  content: "\f2a0"; }

.fa-volume-control-phone::before {
  content: "\f2a0"; }

.fa-phone-xmark::before {
  content: "\e227"; }

.fa-photo-film::before {
  content: "\f87c"; }

.fa-photo-video::before {
  content: "\f87c"; }

.fa-photo-film-music::before {
  content: "\e228"; }

.fa-pi::before {
  content: "\f67e"; }

.fa-piano::before {
  content: "\f8d4"; }

.fa-piano-keyboard::before {
  content: "\1f3b9"; }

.fa-pie::before {
  content: "\1f967"; }

.fa-pig::before {
  content: "\1f416"; }

.fa-piggy-bank::before {
  content: "\f4d3"; }

.fa-pills::before {
  content: "\f484"; }

.fa-pinata::before {
  content: "\e3c3"; }

.fa-pinball::before {
  content: "\e229"; }

.fa-pineapple::before {
  content: "\e31f"; }

.fa-pipe::before {
  content: "\7c"; }

.fa-pipe-smoking::before {
  content: "\e3c4"; }

.fa-pizza::before {
  content: "\1f355"; }

.fa-pizza-slice::before {
  content: "\f818"; }

.fa-place-of-worship::before {
  content: "\f67f"; }

.fa-plane::before {
  content: "\f072"; }

.fa-plane-arrival::before {
  content: "\1f6ec"; }

.fa-plane-departure::before {
  content: "\1f6eb"; }

.fa-plane-engines::before {
  content: "\2708"; }

.fa-plane-alt::before {
  content: "\2708"; }

.fa-plane-prop::before {
  content: "\e22b"; }

.fa-plane-slash::before {
  content: "\e069"; }

.fa-plane-tail::before {
  content: "\e22c"; }

.fa-plane-up::before {
  content: "\e22d"; }

.fa-plane-up-slash::before {
  content: "\e22e"; }

.fa-planet-moon::before {
  content: "\e01f"; }

.fa-planet-ringed::before {
  content: "\1fa90"; }

.fa-play::before {
  content: "\25b6"; }

.fa-play-pause::before {
  content: "\e22f"; }

.fa-plug::before {
  content: "\1f50c"; }

.fa-plus::before {
  content: "\2b"; }

.fa-add::before {
  content: "\2b"; }

.fa-plus-minus::before {
  content: "\b1"; }

.fa-podcast::before {
  content: "\f2ce"; }

.fa-podium::before {
  content: "\f680"; }

.fa-podium-star::before {
  content: "\f758"; }

.fa-police-box::before {
  content: "\e021"; }

.fa-poll-people::before {
  content: "\f759"; }

.fa-poo::before {
  content: "\1f4a9"; }

.fa-poo-bolt::before {
  content: "\f75a"; }

.fa-poo-storm::before {
  content: "\f75a"; }

.fa-pool-8-ball::before {
  content: "\e3c5"; }

.fa-poop::before {
  content: "\f619"; }

.fa-popcorn::before {
  content: "\1f37f"; }

.fa-power-off::before {
  content: "\23fb"; }

.fa-prescription::before {
  content: "\f5b1"; }

.fa-prescription-bottle::before {
  content: "\f485"; }

.fa-prescription-bottle-medical::before {
  content: "\f486"; }

.fa-prescription-bottle-alt::before {
  content: "\f486"; }

.fa-presentation-screen::before {
  content: "\f685"; }

.fa-presentation::before {
  content: "\f685"; }

.fa-print::before {
  content: "\2399"; }

.fa-print-magnifying-glass::before {
  content: "\f81a"; }

.fa-print-search::before {
  content: "\f81a"; }

.fa-print-slash::before {
  content: "\f686"; }

.fa-projector::before {
  content: "\f8d6"; }

.fa-pump-medical::before {
  content: "\e06a"; }

.fa-pump-soap::before {
  content: "\e06b"; }

.fa-pumpkin::before {
  content: "\f707"; }

.fa-puzzle-piece::before {
  content: "\1f9e9"; }

.fa-puzzle-piece-simple::before {
  content: "\e231"; }

.fa-puzzle-piece-alt::before {
  content: "\e231"; }

.fa-q::before {
  content: "\51"; }

.fa-qrcode::before {
  content: "\f029"; }

.fa-question::before {
  content: "\3f"; }

.fa-quidditch::before {
  content: "\f458"; }

.fa-broom-ball::before {
  content: "\f458"; }

.fa-quidditch-broom-ball::before {
  content: "\f458"; }

.fa-quote-left::before {
  content: "\201c"; }

.fa-quote-left-alt::before {
  content: "\201c"; }

.fa-quote-right::before {
  content: "\201d"; }

.fa-quote-right-alt::before {
  content: "\201d"; }

.fa-quotes::before {
  content: "\e234"; }

.fa-r::before {
  content: "\52"; }

.fa-rabbit::before {
  content: "\1f407"; }

.fa-rabbit-running::before {
  content: "\f709"; }

.fa-rabbit-fast::before {
  content: "\f709"; }

.fa-racquet::before {
  content: "\f45a"; }

.fa-radar::before {
  content: "\e024"; }

.fa-radiation::before {
  content: "\f7b9"; }

.fa-radio::before {
  content: "\1f4fb"; }

.fa-radio-tuner::before {
  content: "\f8d8"; }

.fa-radio-alt::before {
  content: "\f8d8"; }

.fa-rainbow::before {
  content: "\1f308"; }

.fa-raindrops::before {
  content: "\f75c"; }

.fa-ram::before {
  content: "\1f40f"; }

.fa-ramp-loading::before {
  content: "\f4d4"; }

.fa-raygun::before {
  content: "\e025"; }

.fa-receipt::before {
  content: "\1f9fe"; }

.fa-record-vinyl::before {
  content: "\f8d9"; }

.fa-rectangle::before {
  content: "\25ac"; }

.fa-rectangle-landscape::before {
  content: "\25ac"; }

.fa-rectangle-ad::before {
  content: "\f641"; }

.fa-ad::before {
  content: "\f641"; }

.fa-rectangle-barcode::before {
  content: "\f463"; }

.fa-barcode-alt::before {
  content: "\f463"; }

.fa-rectangle-code::before {
  content: "\e322"; }

.fa-rectangle-list::before {
  content: "\f022"; }

.fa-list-alt::before {
  content: "\f022"; }

.fa-rectangle-pro::before {
  content: "\e235"; }

.fa-pro::before {
  content: "\e235"; }

.fa-rectangle-terminal::before {
  content: "\e236"; }

.fa-rectangle-vertical::before {
  content: "\f2fb"; }

.fa-rectangle-portrait::before {
  content: "\f2fb"; }

.fa-rectangle-vertical-history::before {
  content: "\e237"; }

.fa-rectangle-wide::before {
  content: "\f2fc"; }

.fa-rectangle-xmark::before {
  content: "\f410"; }

.fa-rectangle-times::before {
  content: "\f410"; }

.fa-times-rectangle::before {
  content: "\f410"; }

.fa-window-close::before {
  content: "\f410"; }

.fa-rectangles-mixed::before {
  content: "\e323"; }

.fa-recycle::before {
  content: "\2672"; }

.fa-reel::before {
  content: "\e238"; }

.fa-refrigerator::before {
  content: "\e026"; }

.fa-registered::before {
  content: "\ae"; }

.fa-repeat::before {
  content: "\1f501"; }

.fa-repeat-1::before {
  content: "\1f502"; }

.fa-reply::before {
  content: "\f3e5"; }

.fa-mail-reply::before {
  content: "\f3e5"; }

.fa-reply-all::before {
  content: "\f122"; }

.fa-mail-reply-all::before {
  content: "\f122"; }

.fa-reply-clock::before {
  content: "\e239"; }

.fa-reply-time::before {
  content: "\e239"; }

.fa-republican::before {
  content: "\f75e"; }

.fa-restroom::before {
  content: "\f7bd"; }

.fa-restroom-simple::before {
  content: "\e23a"; }

.fa-retweet::before {
  content: "\f079"; }

.fa-rhombus::before {
  content: "\e23b"; }

.fa-ribbon::before {
  content: "\1f397"; }

.fa-right::before {
  content: "\2b95"; }

.fa-arrow-alt-right::before {
  content: "\2b95"; }

.fa-right-from-bracket::before {
  content: "\f2f5"; }

.fa-sign-out-alt::before {
  content: "\f2f5"; }

.fa-right-from-line::before {
  content: "\f347"; }

.fa-arrow-alt-from-left::before {
  content: "\f347"; }

.fa-right-left::before {
  content: "\f362"; }

.fa-exchange-alt::before {
  content: "\f362"; }

.fa-right-long::before {
  content: "\f30b"; }

.fa-long-arrow-alt-right::before {
  content: "\f30b"; }

.fa-right-to-bracket::before {
  content: "\f2f6"; }

.fa-sign-in-alt::before {
  content: "\f2f6"; }

.fa-right-to-line::before {
  content: "\f34c"; }

.fa-arrow-alt-to-right::before {
  content: "\f34c"; }

.fa-ring::before {
  content: "\f70b"; }

.fa-rings-wedding::before {
  content: "\f81b"; }

.fa-road::before {
  content: "\1f6e3"; }

.fa-robot::before {
  content: "\1f916"; }

.fa-robot-astromech::before {
  content: "\e2d2"; }

.fa-rocket::before {
  content: "\f135"; }

.fa-rocket-launch::before {
  content: "\1f680"; }

.fa-roller-coaster::before {
  content: "\e324"; }

.fa-rotate::before {
  content: "\1f504"; }

.fa-sync-alt::before {
  content: "\1f504"; }

.fa-rotate-exclamation::before {
  content: "\e23c"; }

.fa-rotate-left::before {
  content: "\f2ea"; }

.fa-rotate-back::before {
  content: "\f2ea"; }

.fa-rotate-backward::before {
  content: "\f2ea"; }

.fa-undo-alt::before {
  content: "\f2ea"; }

.fa-rotate-right::before {
  content: "\f2f9"; }

.fa-redo-alt::before {
  content: "\f2f9"; }

.fa-rotate-forward::before {
  content: "\f2f9"; }

.fa-route::before {
  content: "\f4d7"; }

.fa-route-highway::before {
  content: "\f61a"; }

.fa-route-interstate::before {
  content: "\f61b"; }

.fa-router::before {
  content: "\f8da"; }

.fa-rss::before {
  content: "\f09e"; }

.fa-feed::before {
  content: "\f09e"; }

.fa-ruble-sign::before {
  content: "\20bd"; }

.fa-rouble::before {
  content: "\20bd"; }

.fa-rub::before {
  content: "\20bd"; }

.fa-ruble::before {
  content: "\20bd"; }

.fa-rugby-ball::before {
  content: "\e3c6"; }

.fa-ruler::before {
  content: "\1f4cf"; }

.fa-ruler-combined::before {
  content: "\f546"; }

.fa-ruler-horizontal::before {
  content: "\f547"; }

.fa-ruler-triangle::before {
  content: "\1f4d0"; }

.fa-ruler-vertical::before {
  content: "\f548"; }

.fa-rupee-sign::before {
  content: "\20a8"; }

.fa-rupee::before {
  content: "\20a8"; }

.fa-rupiah-sign::before {
  content: "\e23d"; }

.fa-rv::before {
  content: "\f7be"; }

.fa-s::before {
  content: "\53"; }

.fa-sack::before {
  content: "\f81c"; }

.fa-sack-dollar::before {
  content: "\1f4b0"; }

.fa-salad::before {
  content: "\1f957"; }

.fa-bowl-salad::before {
  content: "\1f957"; }

.fa-sandwich::before {
  content: "\1f96a"; }

.fa-satellite::before {
  content: "\1f6f0"; }

.fa-satellite-dish::before {
  content: "\1f4e1"; }

.fa-sausage::before {
  content: "\f820"; }

.fa-saxophone::before {
  content: "\1f3b7"; }

.fa-saxophone-fire::before {
  content: "\f8db"; }

.fa-sax-hot::before {
  content: "\f8db"; }

.fa-scale-balanced::before {
  content: "\2696"; }

.fa-balance-scale::before {
  content: "\2696"; }

.fa-scale-unbalanced::before {
  content: "\f515"; }

.fa-balance-scale-left::before {
  content: "\f515"; }

.fa-scale-unbalanced-flip::before {
  content: "\f516"; }

.fa-balance-scale-right::before {
  content: "\f516"; }

.fa-scalpel::before {
  content: "\f61d"; }

.fa-scalpel-line-dashed::before {
  content: "\f61e"; }

.fa-scalpel-path::before {
  content: "\f61e"; }

.fa-scanner::before {
  content: "\f8f3"; }

.fa-scanner-image::before {
  content: "\f8f3"; }

.fa-scanner-gun::before {
  content: "\f488"; }

.fa-scanner-keyboard::before {
  content: "\f489"; }

.fa-scanner-touchscreen::before {
  content: "\f48a"; }

.fa-scarecrow::before {
  content: "\f70d"; }

.fa-scarf::before {
  content: "\1f9e3"; }

.fa-school::before {
  content: "\1f3eb"; }

.fa-scissors::before {
  content: "\2700"; }

.fa-cut::before {
  content: "\2700"; }

.fa-screen-users::before {
  content: "\f63d"; }

.fa-users-class::before {
  content: "\f63d"; }

.fa-screencast::before {
  content: "\e23e"; }

.fa-screwdriver::before {
  content: "\1fa9b"; }

.fa-screwdriver-wrench::before {
  content: "\f7d9"; }

.fa-tools::before {
  content: "\f7d9"; }

.fa-scribble::before {
  content: "\e23f"; }

.fa-scroll::before {
  content: "\1f4dc"; }

.fa-scroll-old::before {
  content: "\f70f"; }

.fa-scroll-torah::before {
  content: "\f6a0"; }

.fa-torah::before {
  content: "\f6a0"; }

.fa-scrubber::before {
  content: "\f2f8"; }

.fa-scythe::before {
  content: "\f710"; }

.fa-sd-card::before {
  content: "\f7c2"; }

.fa-sd-cards::before {
  content: "\e240"; }

.fa-seal::before {
  content: "\e241"; }

.fa-seal-exclamation::before {
  content: "\e242"; }

.fa-seal-question::before {
  content: "\e243"; }

.fa-seat-airline::before {
  content: "\e244"; }

.fa-section::before {
  content: "\a7"; }

.fa-seedling::before {
  content: "\1f331"; }

.fa-sprout::before {
  content: "\1f331"; }

.fa-semicolon::before {
  content: "\3b"; }

.fa-send-back::before {
  content: "\f87e"; }

.fa-send-backward::before {
  content: "\f87f"; }

.fa-sensor::before {
  content: "\e028"; }

.fa-sensor-cloud::before {
  content: "\e02c"; }

.fa-sensor-smoke::before {
  content: "\e02c"; }

.fa-sensor-fire::before {
  content: "\e02a"; }

.fa-sensor-on::before {
  content: "\e02b"; }

.fa-sensor-triangle-exclamation::before {
  content: "\e029"; }

.fa-sensor-alert::before {
  content: "\e029"; }

.fa-server::before {
  content: "\f233"; }

.fa-shapes::before {
  content: "\f61f"; }

.fa-triangle-circle-square::before {
  content: "\f61f"; }

.fa-share::before {
  content: "\f064"; }

.fa-arrow-turn-right::before {
  content: "\f064"; }

.fa-mail-forward::before {
  content: "\f064"; }

.fa-share-all::before {
  content: "\f367"; }

.fa-arrows-turn-right::before {
  content: "\f367"; }

.fa-share-from-square::before {
  content: "\f14d"; }

.fa-share-square::before {
  content: "\f14d"; }

.fa-share-nodes::before {
  content: "\f1e0"; }

.fa-share-alt::before {
  content: "\f1e0"; }

.fa-sheep::before {
  content: "\1f411"; }

.fa-shekel-sign::before {
  content: "\20aa"; }

.fa-ils::before {
  content: "\20aa"; }

.fa-shekel::before {
  content: "\20aa"; }

.fa-sheqel::before {
  content: "\20aa"; }

.fa-sheqel-sign::before {
  content: "\20aa"; }

.fa-shelves::before {
  content: "\f480"; }

.fa-inventory::before {
  content: "\f480"; }

.fa-shelves-empty::before {
  content: "\e246"; }

.fa-shield::before {
  content: "\1f6e1"; }

.fa-shield-blank::before {
  content: "\f3ed"; }

.fa-shield-alt::before {
  content: "\f3ed"; }

.fa-shield-check::before {
  content: "\f2f7"; }

.fa-shield-cross::before {
  content: "\26e8"; }

.fa-shield-exclamation::before {
  content: "\e247"; }

.fa-shield-keyhole::before {
  content: "\e248"; }

.fa-shield-minus::before {
  content: "\e249"; }

.fa-shield-plus::before {
  content: "\e24a"; }

.fa-shield-slash::before {
  content: "\e24b"; }

.fa-shield-virus::before {
  content: "\e06c"; }

.fa-shield-xmark::before {
  content: "\e24c"; }

.fa-shield-times::before {
  content: "\e24c"; }

.fa-ship::before {
  content: "\1f6a2"; }

.fa-shirt::before {
  content: "\1f455"; }

.fa-t-shirt::before {
  content: "\1f455"; }

.fa-tshirt::before {
  content: "\1f455"; }

.fa-shirt-long-sleeve::before {
  content: "\e3c7"; }

.fa-shirt-running::before {
  content: "\e3c8"; }

.fa-shirt-tank-top::before {
  content: "\e3c9"; }

.fa-shish-kebab::before {
  content: "\f821"; }

.fa-shoe-prints::before {
  content: "\f54b"; }

.fa-shop::before {
  content: "\f54f"; }

.fa-store-alt::before {
  content: "\f54f"; }

.fa-shop-slash::before {
  content: "\e070"; }

.fa-store-alt-slash::before {
  content: "\e070"; }

.fa-shovel::before {
  content: "\f713"; }

.fa-shovel-snow::before {
  content: "\f7c3"; }

.fa-shower::before {
  content: "\1f6bf"; }

.fa-shower-down::before {
  content: "\e24d"; }

.fa-shower-alt::before {
  content: "\e24d"; }

.fa-shredder::before {
  content: "\f68a"; }

.fa-shuffle::before {
  content: "\1f500"; }

.fa-random::before {
  content: "\1f500"; }

.fa-shuttle-space::before {
  content: "\f197"; }

.fa-space-shuttle::before {
  content: "\f197"; }

.fa-shuttlecock::before {
  content: "\f45b"; }

.fa-sickle::before {
  content: "\f822"; }

.fa-sidebar::before {
  content: "\e24e"; }

.fa-sidebar-flip::before {
  content: "\e24f"; }

.fa-sigma::before {
  content: "\2211"; }

.fa-sign-hanging::before {
  content: "\f4d9"; }

.fa-sign::before {
  content: "\f4d9"; }

.fa-signal::before {
  content: "\1f4f6"; }

.fa-signal-5::before {
  content: "\1f4f6"; }

.fa-signal-perfect::before {
  content: "\1f4f6"; }

.fa-signal-bars::before {
  content: "\f690"; }

.fa-signal-alt::before {
  content: "\f690"; }

.fa-signal-alt-4::before {
  content: "\f690"; }

.fa-signal-bars-strong::before {
  content: "\f690"; }

.fa-signal-bars-fair::before {
  content: "\f692"; }

.fa-signal-alt-2::before {
  content: "\f692"; }

.fa-signal-bars-good::before {
  content: "\f693"; }

.fa-signal-alt-3::before {
  content: "\f693"; }

.fa-signal-bars-slash::before {
  content: "\f694"; }

.fa-signal-alt-slash::before {
  content: "\f694"; }

.fa-signal-bars-weak::before {
  content: "\f691"; }

.fa-signal-alt-1::before {
  content: "\f691"; }

.fa-signal-fair::before {
  content: "\f68d"; }

.fa-signal-2::before {
  content: "\f68d"; }

.fa-signal-good::before {
  content: "\f68e"; }

.fa-signal-3::before {
  content: "\f68e"; }

.fa-signal-slash::before {
  content: "\f695"; }

.fa-signal-stream::before {
  content: "\f8dd"; }

.fa-signal-stream-slash::before {
  content: "\e250"; }

.fa-signal-strong::before {
  content: "\f68f"; }

.fa-signal-4::before {
  content: "\f68f"; }

.fa-signal-weak::before {
  content: "\f68c"; }

.fa-signal-1::before {
  content: "\f68c"; }

.fa-signature::before {
  content: "\f5b7"; }

.fa-signature-lock::before {
  content: "\e3ca"; }

.fa-signature-slash::before {
  content: "\e3cb"; }

.fa-signs-post::before {
  content: "\f277"; }

.fa-map-signs::before {
  content: "\f277"; }

.fa-sim-card::before {
  content: "\f7c4"; }

.fa-sim-cards::before {
  content: "\e251"; }

.fa-sink::before {
  content: "\e06d"; }

.fa-siren::before {
  content: "\e02d"; }

.fa-siren-on::before {
  content: "\e02e"; }

.fa-sitemap::before {
  content: "\f0e8"; }

.fa-skeleton::before {
  content: "\f620"; }

.fa-ski-boot::before {
  content: "\e3cc"; }

.fa-ski-boot-ski::before {
  content: "\e3cd"; }

.fa-skull::before {
  content: "\1f480"; }

.fa-skull-cow::before {
  content: "\f8de"; }

.fa-skull-crossbones::before {
  content: "\2620"; }

.fa-slash::before {
  content: "\f715"; }

.fa-slash-back::before {
  content: "\5c"; }

.fa-slash-forward::before {
  content: "\2f"; }

.fa-sleigh::before {
  content: "\f7cc"; }

.fa-slider::before {
  content: "\e252"; }

.fa-sliders::before {
  content: "\f1de"; }

.fa-sliders-h::before {
  content: "\f1de"; }

.fa-sliders-simple::before {
  content: "\e253"; }

.fa-sliders-up::before {
  content: "\f3f1"; }

.fa-sliders-v::before {
  content: "\f3f1"; }

.fa-slot-machine::before {
  content: "\e3ce"; }

.fa-smog::before {
  content: "\f75f"; }

.fa-smoke::before {
  content: "\f760"; }

.fa-smoking::before {
  content: "\1f6ac"; }

.fa-snake::before {
  content: "\1f40d"; }

.fa-snooze::before {
  content: "\1f4a4"; }

.fa-zzz::before {
  content: "\1f4a4"; }

.fa-snow-blowing::before {
  content: "\f761"; }

.fa-snowflake::before {
  content: "\2744"; }

.fa-snowflakes::before {
  content: "\f7cf"; }

.fa-snowman::before {
  content: "\2603"; }

.fa-snowman-head::before {
  content: "\f79b"; }

.fa-frosty-head::before {
  content: "\f79b"; }

.fa-snowplow::before {
  content: "\f7d2"; }

.fa-soap::before {
  content: "\1f9fc"; }

.fa-socks::before {
  content: "\1f9e6"; }

.fa-solar-panel::before {
  content: "\f5ba"; }

.fa-solar-system::before {
  content: "\e02f"; }

.fa-sort::before {
  content: "\f0dc"; }

.fa-unsorted::before {
  content: "\f0dc"; }

.fa-sort-down::before {
  content: "\f0dd"; }

.fa-sort-desc::before {
  content: "\f0dd"; }

.fa-sort-up::before {
  content: "\f0de"; }

.fa-sort-asc::before {
  content: "\f0de"; }

.fa-spa::before {
  content: "\f5bb"; }

.fa-space-station-moon::before {
  content: "\e033"; }

.fa-space-station-moon-construction::before {
  content: "\e034"; }

.fa-space-station-moon-alt::before {
  content: "\e034"; }

.fa-spade::before {
  content: "\2660"; }

.fa-spaghetti-monster-flying::before {
  content: "\f67b"; }

.fa-pastafarianism::before {
  content: "\f67b"; }

.fa-sparkles::before {
  content: "\2728"; }

.fa-speaker::before {
  content: "\f8df"; }

.fa-speakers::before {
  content: "\f8e0"; }

.fa-spell-check::before {
  content: "\f891"; }

.fa-spider::before {
  content: "\1f577"; }

.fa-spider-black-widow::before {
  content: "\f718"; }

.fa-spider-web::before {
  content: "\1f578"; }

.fa-spinner::before {
  content: "\f110"; }

.fa-spinner-third::before {
  content: "\f3f4"; }

.fa-split::before {
  content: "\e254"; }

.fa-splotch::before {
  content: "\f5bc"; }

.fa-spoon::before {
  content: "\1f944"; }

.fa-utensil-spoon::before {
  content: "\1f944"; }

.fa-spray-can::before {
  content: "\f5bd"; }

.fa-spray-can-sparkles::before {
  content: "\f5d0"; }

.fa-air-freshener::before {
  content: "\f5d0"; }

.fa-sprinkler::before {
  content: "\e035"; }

.fa-square::before {
  content: "\25a0"; }

.fa-square-0::before {
  content: "\e255"; }

.fa-square-1::before {
  content: "\e256"; }

.fa-square-2::before {
  content: "\e257"; }

.fa-square-3::before {
  content: "\e258"; }

.fa-square-4::before {
  content: "\e259"; }

.fa-square-5::before {
  content: "\e25a"; }

.fa-square-6::before {
  content: "\e25b"; }

.fa-square-7::before {
  content: "\e25c"; }

.fa-square-8::before {
  content: "\e25d"; }

.fa-square-9::before {
  content: "\e25e"; }

.fa-square-a::before {
  content: "\e25f"; }

.fa-square-ampersand::before {
  content: "\e260"; }

.fa-square-arrow-down::before {
  content: "\f339"; }

.fa-arrow-square-down::before {
  content: "\f339"; }

.fa-square-arrow-down-left::before {
  content: "\e261"; }

.fa-square-arrow-down-right::before {
  content: "\e262"; }

.fa-square-arrow-left::before {
  content: "\f33a"; }

.fa-arrow-square-left::before {
  content: "\f33a"; }

.fa-square-arrow-right::before {
  content: "\f33b"; }

.fa-arrow-square-right::before {
  content: "\f33b"; }

.fa-square-arrow-up::before {
  content: "\f33c"; }

.fa-arrow-square-up::before {
  content: "\f33c"; }

.fa-square-arrow-up-left::before {
  content: "\e263"; }

.fa-square-arrow-up-right::before {
  content: "\f14c"; }

.fa-external-link-square::before {
  content: "\f14c"; }

.fa-square-b::before {
  content: "\e264"; }

.fa-square-bolt::before {
  content: "\e265"; }

.fa-square-c::before {
  content: "\e266"; }

.fa-square-caret-down::before {
  content: "\f150"; }

.fa-caret-square-down::before {
  content: "\f150"; }

.fa-square-caret-left::before {
  content: "\f191"; }

.fa-caret-square-left::before {
  content: "\f191"; }

.fa-square-caret-right::before {
  content: "\f152"; }

.fa-caret-square-right::before {
  content: "\f152"; }

.fa-square-caret-up::before {
  content: "\f151"; }

.fa-caret-square-up::before {
  content: "\f151"; }

.fa-square-check::before {
  content: "\2611"; }

.fa-check-square::before {
  content: "\2611"; }

.fa-square-chevron-down::before {
  content: "\f329"; }

.fa-chevron-square-down::before {
  content: "\f329"; }

.fa-square-chevron-left::before {
  content: "\f32a"; }

.fa-chevron-square-left::before {
  content: "\f32a"; }

.fa-square-chevron-right::before {
  content: "\f32b"; }

.fa-chevron-square-right::before {
  content: "\f32b"; }

.fa-square-chevron-up::before {
  content: "\f32c"; }

.fa-chevron-square-up::before {
  content: "\f32c"; }

.fa-square-code::before {
  content: "\e267"; }

.fa-square-d::before {
  content: "\e268"; }

.fa-square-dashed::before {
  content: "\e269"; }

.fa-square-divide::before {
  content: "\e26a"; }

.fa-square-dollar::before {
  content: "\f2e9"; }

.fa-dollar-square::before {
  content: "\f2e9"; }

.fa-usd-square::before {
  content: "\f2e9"; }

.fa-square-down::before {
  content: "\2b07"; }

.fa-arrow-alt-square-down::before {
  content: "\2b07"; }

.fa-square-down-left::before {
  content: "\e26b"; }

.fa-square-down-right::before {
  content: "\e26c"; }

.fa-square-e::before {
  content: "\e26d"; }

.fa-square-ellipsis::before {
  content: "\e26e"; }

.fa-square-ellipsis-vertical::before {
  content: "\e26f"; }

.fa-square-envelope::before {
  content: "\f199"; }

.fa-envelope-square::before {
  content: "\f199"; }

.fa-square-exclamation::before {
  content: "\f321"; }

.fa-exclamation-square::before {
  content: "\f321"; }

.fa-square-f::before {
  content: "\e270"; }

.fa-square-fragile::before {
  content: "\f49b"; }

.fa-box-fragile::before {
  content: "\f49b"; }

.fa-square-wine-glass-crack::before {
  content: "\f49b"; }

.fa-square-full::before {
  content: "\2b1b"; }

.fa-square-g::before {
  content: "\e271"; }

.fa-square-h::before {
  content: "\f0fd"; }

.fa-h-square::before {
  content: "\f0fd"; }

.fa-square-heart::before {
  content: "\1f49f"; }

.fa-heart-square::before {
  content: "\1f49f"; }

.fa-square-i::before {
  content: "\e272"; }

.fa-square-info::before {
  content: "\2139"; }

.fa-info-square::before {
  content: "\2139"; }

.fa-square-j::before {
  content: "\e273"; }

.fa-square-k::before {
  content: "\e274"; }

.fa-square-l::before {
  content: "\e275"; }

.fa-square-left::before {
  content: "\2b05"; }

.fa-arrow-alt-square-left::before {
  content: "\2b05"; }

.fa-square-m::before {
  content: "\e276"; }

.fa-square-minus::before {
  content: "\f146"; }

.fa-minus-square::before {
  content: "\f146"; }

.fa-square-n::before {
  content: "\e277"; }

.fa-square-o::before {
  content: "\e278"; }

.fa-square-p::before {
  content: "\e279"; }

.fa-square-parking::before {
  content: "\1f17f"; }

.fa-parking::before {
  content: "\1f17f"; }

.fa-square-parking-slash::before {
  content: "\f617"; }

.fa-parking-slash::before {
  content: "\f617"; }

.fa-square-pen::before {
  content: "\f14b"; }

.fa-pen-square::before {
  content: "\f14b"; }

.fa-pencil-square::before {
  content: "\f14b"; }

.fa-square-phone::before {
  content: "\f098"; }

.fa-phone-square::before {
  content: "\f098"; }

.fa-square-phone-flip::before {
  content: "\f87b"; }

.fa-phone-square-alt::before {
  content: "\f87b"; }

.fa-square-phone-hangup::before {
  content: "\e27a"; }

.fa-phone-square-down::before {
  content: "\e27a"; }

.fa-square-plus::before {
  content: "\f0fe"; }

.fa-plus-square::before {
  content: "\f0fe"; }

.fa-square-poll-horizontal::before {
  content: "\f682"; }

.fa-poll-h::before {
  content: "\f682"; }

.fa-square-poll-vertical::before {
  content: "\f681"; }

.fa-poll::before {
  content: "\f681"; }

.fa-square-q::before {
  content: "\e27b"; }

.fa-square-question::before {
  content: "\f2fd"; }

.fa-question-square::before {
  content: "\f2fd"; }

.fa-square-quote::before {
  content: "\e329"; }

.fa-square-r::before {
  content: "\e27c"; }

.fa-square-right::before {
  content: "\27a1"; }

.fa-arrow-alt-square-right::before {
  content: "\27a1"; }

.fa-square-root::before {
  content: "\221a"; }

.fa-square-root-variable::before {
  content: "\f698"; }

.fa-square-root-alt::before {
  content: "\f698"; }

.fa-square-rss::before {
  content: "\f143"; }

.fa-rss-square::before {
  content: "\f143"; }

.fa-square-s::before {
  content: "\e27d"; }

.fa-square-share-nodes::before {
  content: "\f1e1"; }

.fa-share-alt-square::before {
  content: "\f1e1"; }

.fa-square-sliders::before {
  content: "\f3f0"; }

.fa-sliders-h-square::before {
  content: "\f3f0"; }

.fa-square-sliders-vertical::before {
  content: "\f3f2"; }

.fa-sliders-v-square::before {
  content: "\f3f2"; }

.fa-square-small::before {
  content: "\e27e"; }

.fa-square-star::before {
  content: "\e27f"; }

.fa-square-t::before {
  content: "\e280"; }

.fa-square-terminal::before {
  content: "\e32a"; }

.fa-square-this-way-up::before {
  content: "\f49f"; }

.fa-box-up::before {
  content: "\f49f"; }

.fa-square-u::before {
  content: "\e281"; }

.fa-square-up::before {
  content: "\2b06"; }

.fa-arrow-alt-square-up::before {
  content: "\2b06"; }

.fa-square-up-left::before {
  content: "\e282"; }

.fa-square-up-right::before {
  content: "\2197"; }

.fa-external-link-square-alt::before {
  content: "\2197"; }

.fa-square-user::before {
  content: "\e283"; }

.fa-square-v::before {
  content: "\e284"; }

.fa-square-w::before {
  content: "\e285"; }

.fa-square-x::before {
  content: "\e286"; }

.fa-square-xmark::before {
  content: "\274e"; }

.fa-times-square::before {
  content: "\274e"; }

.fa-xmark-square::before {
  content: "\274e"; }

.fa-square-y::before {
  content: "\e287"; }

.fa-square-z::before {
  content: "\e288"; }

.fa-squirrel::before {
  content: "\f71a"; }

.fa-staff::before {
  content: "\f71b"; }

.fa-stairs::before {
  content: "\e289"; }

.fa-stamp::before {
  content: "\f5bf"; }

.fa-standard-definition::before {
  content: "\e28a"; }

.fa-rectangle-sd::before {
  content: "\e28a"; }

.fa-star::before {
  content: "\2b50"; }

.fa-star-and-crescent::before {
  content: "\262a"; }

.fa-star-christmas::before {
  content: "\f7d4"; }

.fa-star-exclamation::before {
  content: "\f2f3"; }

.fa-star-half::before {
  content: "\f089"; }

.fa-star-half-stroke::before {
  content: "\f5c0"; }

.fa-star-half-alt::before {
  content: "\f5c0"; }

.fa-star-of-david::before {
  content: "\2721"; }

.fa-star-of-life::before {
  content: "\f621"; }

.fa-star-sharp::before {
  content: "\e28b"; }

.fa-star-sharp-half::before {
  content: "\e28c"; }

.fa-star-sharp-half-stroke::before {
  content: "\e28d"; }

.fa-star-sharp-half-alt::before {
  content: "\e28d"; }

.fa-star-shooting::before {
  content: "\1f320"; }

.fa-starfighter::before {
  content: "\e037"; }

.fa-starfighter-twin-ion-engine::before {
  content: "\e038"; }

.fa-starfighter-alt::before {
  content: "\e038"; }

.fa-starfighter-twin-ion-engine-advanced::before {
  content: "\e28e"; }

.fa-starfighter-alt-advanced::before {
  content: "\e28e"; }

.fa-stars::before {
  content: "\f762"; }

.fa-starship::before {
  content: "\e039"; }

.fa-starship-freighter::before {
  content: "\e03a"; }

.fa-steak::before {
  content: "\1f969"; }

.fa-steering-wheel::before {
  content: "\f622"; }

.fa-sterling-sign::before {
  content: "\a3"; }

.fa-gbp::before {
  content: "\a3"; }

.fa-pound-sign::before {
  content: "\a3"; }

.fa-stethoscope::before {
  content: "\1fa7a"; }

.fa-stocking::before {
  content: "\f7d5"; }

.fa-stomach::before {
  content: "\f623"; }

.fa-stop::before {
  content: "\23f9"; }

.fa-stopwatch::before {
  content: "\23f1"; }

.fa-stopwatch-20::before {
  content: "\e06f"; }

.fa-store::before {
  content: "\f54e"; }

.fa-store-slash::before {
  content: "\e071"; }

.fa-strawberry::before {
  content: "\e32b"; }

.fa-street-view::before {
  content: "\f21d"; }

.fa-stretcher::before {
  content: "\f825"; }

.fa-strikethrough::before {
  content: "\f0cc"; }

.fa-stroopwafel::before {
  content: "\f551"; }

.fa-subscript::before {
  content: "\f12c"; }

.fa-suitcase::before {
  content: "\1f9f3"; }

.fa-suitcase-medical::before {
  content: "\f0fa"; }

.fa-medkit::before {
  content: "\f0fa"; }

.fa-suitcase-rolling::before {
  content: "\f5c1"; }

.fa-sun::before {
  content: "\2600"; }

.fa-sun-bright::before {
  content: "\e28f"; }

.fa-sun-alt::before {
  content: "\e28f"; }

.fa-sun-cloud::before {
  content: "\1f324"; }

.fa-sun-dust::before {
  content: "\f764"; }

.fa-sun-haze::before {
  content: "\f765"; }

.fa-sunglasses::before {
  content: "\1f576"; }

.fa-sunrise::before {
  content: "\1f305"; }

.fa-sunset::before {
  content: "\1f307"; }

.fa-superscript::before {
  content: "\f12b"; }

.fa-swatchbook::before {
  content: "\f5c3"; }

.fa-sword::before {
  content: "\1f5e1"; }

.fa-sword-laser::before {
  content: "\e03b"; }

.fa-sword-laser-alt::before {
  content: "\e03c"; }

.fa-swords::before {
  content: "\2694"; }

.fa-swords-laser::before {
  content: "\e03d"; }

.fa-symbols::before {
  content: "\1f523"; }

.fa-icons-alt::before {
  content: "\1f523"; }

.fa-synagogue::before {
  content: "\1f54d"; }

.fa-syringe::before {
  content: "\1f489"; }

.fa-t::before {
  content: "\54"; }

.fa-table::before {
  content: "\f0ce"; }

.fa-table-cells::before {
  content: "\f00a"; }

.fa-th::before {
  content: "\f00a"; }

.fa-table-cells-large::before {
  content: "\f009"; }

.fa-th-large::before {
  content: "\f009"; }

.fa-table-columns::before {
  content: "\f0db"; }

.fa-columns::before {
  content: "\f0db"; }

.fa-table-layout::before {
  content: "\e290"; }

.fa-table-list::before {
  content: "\f00b"; }

.fa-th-list::before {
  content: "\f00b"; }

.fa-table-picnic::before {
  content: "\e32d"; }

.fa-table-pivot::before {
  content: "\e291"; }

.fa-table-rows::before {
  content: "\e292"; }

.fa-rows::before {
  content: "\e292"; }

.fa-table-tennis-paddle-ball::before {
  content: "\1f3d3"; }

.fa-ping-pong-paddle-ball::before {
  content: "\1f3d3"; }

.fa-table-tennis::before {
  content: "\1f3d3"; }

.fa-table-tree::before {
  content: "\e293"; }

.fa-tablet::before {
  content: "\f3fb"; }

.fa-tablet-android::before {
  content: "\f3fb"; }

.fa-tablet-button::before {
  content: "\f10a"; }

.fa-tablet-rugged::before {
  content: "\f48f"; }

.fa-tablet-screen::before {
  content: "\f3fc"; }

.fa-tablet-android-alt::before {
  content: "\f3fc"; }

.fa-tablet-screen-button::before {
  content: "\f3fa"; }

.fa-tablet-alt::before {
  content: "\f3fa"; }

.fa-tablets::before {
  content: "\f490"; }

.fa-tachograph-digital::before {
  content: "\f566"; }

.fa-digital-tachograph::before {
  content: "\f566"; }

.fa-taco::before {
  content: "\1f32e"; }

.fa-tag::before {
  content: "\1f3f7"; }

.fa-tags::before {
  content: "\f02c"; }

.fa-tally::before {
  content: "\f69c"; }

.fa-tally-5::before {
  content: "\f69c"; }

.fa-tally-1::before {
  content: "\e294"; }

.fa-tally-2::before {
  content: "\e295"; }

.fa-tally-3::before {
  content: "\e296"; }

.fa-tally-4::before {
  content: "\e297"; }

.fa-tape::before {
  content: "\f4db"; }

.fa-taxi::before {
  content: "\1f696"; }

.fa-cab::before {
  content: "\1f696"; }

.fa-taxi-bus::before {
  content: "\e298"; }

.fa-teddy-bear::before {
  content: "\e3cf"; }

.fa-teeth::before {
  content: "\f62e"; }

.fa-teeth-open::before {
  content: "\f62f"; }

.fa-telescope::before {
  content: "\1f52d"; }

.fa-temperature-arrow-down::before {
  content: "\e03f"; }

.fa-temperature-down::before {
  content: "\e03f"; }

.fa-temperature-arrow-up::before {
  content: "\e040"; }

.fa-temperature-up::before {
  content: "\e040"; }

.fa-temperature-empty::before {
  content: "\f2cb"; }

.fa-temperature-0::before {
  content: "\f2cb"; }

.fa-thermometer-0::before {
  content: "\f2cb"; }

.fa-thermometer-empty::before {
  content: "\f2cb"; }

.fa-temperature-full::before {
  content: "\f2c7"; }

.fa-temperature-4::before {
  content: "\f2c7"; }

.fa-thermometer-4::before {
  content: "\f2c7"; }

.fa-thermometer-full::before {
  content: "\f2c7"; }

.fa-temperature-half::before {
  content: "\1f321"; }

.fa-temperature-2::before {
  content: "\1f321"; }

.fa-thermometer-2::before {
  content: "\1f321"; }

.fa-thermometer-half::before {
  content: "\1f321"; }

.fa-temperature-high::before {
  content: "\f769"; }

.fa-temperature-list::before {
  content: "\e299"; }

.fa-temperature-low::before {
  content: "\f76b"; }

.fa-temperature-quarter::before {
  content: "\f2ca"; }

.fa-temperature-1::before {
  content: "\f2ca"; }

.fa-thermometer-1::before {
  content: "\f2ca"; }

.fa-thermometer-quarter::before {
  content: "\f2ca"; }

.fa-temperature-snow::before {
  content: "\f768"; }

.fa-temperature-frigid::before {
  content: "\f768"; }

.fa-temperature-sun::before {
  content: "\f76a"; }

.fa-temperature-hot::before {
  content: "\f76a"; }

.fa-temperature-three-quarters::before {
  content: "\f2c8"; }

.fa-temperature-3::before {
  content: "\f2c8"; }

.fa-thermometer-3::before {
  content: "\f2c8"; }

.fa-thermometer-three-quarters::before {
  content: "\f2c8"; }

.fa-tenge-sign::before {
  content: "\20b8"; }

.fa-tenge::before {
  content: "\20b8"; }

.fa-tennis-ball::before {
  content: "\1f3be"; }

.fa-terminal::before {
  content: "\f120"; }

.fa-text::before {
  content: "\f893"; }

.fa-text-height::before {
  content: "\f034"; }

.fa-text-size::before {
  content: "\f894"; }

.fa-text-slash::before {
  content: "\f87d"; }

.fa-remove-format::before {
  content: "\f87d"; }

.fa-text-width::before {
  content: "\f035"; }

.fa-thermometer::before {
  content: "\f491"; }

.fa-theta::before {
  content: "\f69e"; }

.fa-thought-bubble::before {
  content: "\e32e"; }

.fa-thumbs-down::before {
  content: "\1f44e"; }

.fa-thumbs-up::before {
  content: "\1f44d"; }

.fa-thumbtack::before {
  content: "\1f4cc"; }

.fa-thumb-tack::before {
  content: "\1f4cc"; }

.fa-tick::before {
  content: "\e32f"; }

.fa-ticket::before {
  content: "\1f39f"; }

.fa-ticket-airline::before {
  content: "\e29a"; }

.fa-ticket-simple::before {
  content: "\f3ff"; }

.fa-ticket-alt::before {
  content: "\f3ff"; }

.fa-tickets-airline::before {
  content: "\e29b"; }

.fa-tilde::before {
  content: "\7e"; }

.fa-timeline::before {
  content: "\e29c"; }

.fa-timeline-arrow::before {
  content: "\e29d"; }

.fa-timer::before {
  content: "\e29e"; }

.fa-tire::before {
  content: "\f631"; }

.fa-tire-flat::before {
  content: "\f632"; }

.fa-tire-pressure-warning::before {
  content: "\f633"; }

.fa-tire-rugged::before {
  content: "\f634"; }

.fa-toggle-off::before {
  content: "\f204"; }

.fa-toggle-on::before {
  content: "\f205"; }

.fa-toilet::before {
  content: "\1f6bd"; }

.fa-toilet-paper::before {
  content: "\1f9fb"; }

.fa-toilet-paper-blank::before {
  content: "\f71f"; }

.fa-toilet-paper-alt::before {
  content: "\f71f"; }

.fa-toilet-paper-blank-under::before {
  content: "\e29f"; }

.fa-toilet-paper-reverse-alt::before {
  content: "\e29f"; }

.fa-toilet-paper-slash::before {
  content: "\e072"; }

.fa-toilet-paper-under::before {
  content: "\e2a0"; }

.fa-toilet-paper-reverse::before {
  content: "\e2a0"; }

.fa-toilet-paper-under-slash::before {
  content: "\e2a1"; }

.fa-toilet-paper-reverse-slash::before {
  content: "\e2a1"; }

.fa-tomato::before {
  content: "\e330"; }

.fa-tombstone::before {
  content: "\f720"; }

.fa-tombstone-blank::before {
  content: "\1faa6"; }

.fa-tombstone-alt::before {
  content: "\1faa6"; }

.fa-toolbox::before {
  content: "\1f9f0"; }

.fa-tooth::before {
  content: "\1f9b7"; }

.fa-toothbrush::before {
  content: "\1faa5"; }

.fa-torii-gate::before {
  content: "\26e9"; }

.fa-tornado::before {
  content: "\1f32a"; }

.fa-tower-broadcast::before {
  content: "\f519"; }

.fa-broadcast-tower::before {
  content: "\f519"; }

.fa-tower-control::before {
  content: "\e2a2"; }

.fa-tractor::before {
  content: "\1f69c"; }

.fa-trademark::before {
  content: "\2122"; }

.fa-traffic-cone::before {
  content: "\f636"; }

.fa-traffic-light::before {
  content: "\1f6a6"; }

.fa-traffic-light-go::before {
  content: "\f638"; }

.fa-traffic-light-slow::before {
  content: "\f639"; }

.fa-traffic-light-stop::before {
  content: "\f63a"; }

.fa-trailer::before {
  content: "\e041"; }

.fa-train::before {
  content: "\1f686"; }

.fa-train-subway::before {
  content: "\f239"; }

.fa-subway::before {
  content: "\f239"; }

.fa-train-subway-tunnel::before {
  content: "\e2a3"; }

.fa-subway-tunnel::before {
  content: "\e2a3"; }

.fa-train-tram::before {
  content: "\1f68a"; }

.fa-tram::before {
  content: "\1f68a"; }

.fa-transformer-bolt::before {
  content: "\e2a4"; }

.fa-transgender::before {
  content: "\26a7"; }

.fa-transgender-alt::before {
  content: "\26a7"; }

.fa-transporter::before {
  content: "\e042"; }

.fa-transporter-1::before {
  content: "\e043"; }

.fa-transporter-2::before {
  content: "\e044"; }

.fa-transporter-3::before {
  content: "\e045"; }

.fa-transporter-4::before {
  content: "\e2a5"; }

.fa-transporter-5::before {
  content: "\e2a6"; }

.fa-transporter-6::before {
  content: "\e2a7"; }

.fa-transporter-7::before {
  content: "\e2a8"; }

.fa-transporter-empty::before {
  content: "\e046"; }

.fa-trash::before {
  content: "\f1f8"; }

.fa-trash-arrow-up::before {
  content: "\f829"; }

.fa-trash-restore::before {
  content: "\f829"; }

.fa-trash-can::before {
  content: "\f2ed"; }

.fa-trash-alt::before {
  content: "\f2ed"; }

.fa-trash-can-arrow-up::before {
  content: "\f82a"; }

.fa-trash-restore-alt::before {
  content: "\f82a"; }

.fa-trash-can-check::before {
  content: "\e2a9"; }

.fa-trash-can-clock::before {
  content: "\e2aa"; }

.fa-trash-can-list::before {
  content: "\e2ab"; }

.fa-trash-can-plus::before {
  content: "\e2ac"; }

.fa-trash-can-slash::before {
  content: "\e2ad"; }

.fa-trash-alt-slash::before {
  content: "\e2ad"; }

.fa-trash-can-undo::before {
  content: "\f896"; }

.fa-trash-can-arrow-turn-left::before {
  content: "\f896"; }

.fa-trash-undo-alt::before {
  content: "\f896"; }

.fa-trash-can-xmark::before {
  content: "\e2ae"; }

.fa-trash-check::before {
  content: "\e2af"; }

.fa-trash-clock::before {
  content: "\e2b0"; }

.fa-trash-list::before {
  content: "\e2b1"; }

.fa-trash-plus::before {
  content: "\e2b2"; }

.fa-trash-slash::before {
  content: "\e2b3"; }

.fa-trash-undo::before {
  content: "\f895"; }

.fa-trash-arrow-turn-left::before {
  content: "\f895"; }

.fa-trash-xmark::before {
  content: "\e2b4"; }

.fa-treasure-chest::before {
  content: "\f723"; }

.fa-tree::before {
  content: "\1f332"; }

.fa-tree-christmas::before {
  content: "\1f384"; }

.fa-tree-deciduous::before {
  content: "\1f333"; }

.fa-tree-alt::before {
  content: "\1f333"; }

.fa-tree-decorated::before {
  content: "\f7dc"; }

.fa-tree-large::before {
  content: "\f7dd"; }

.fa-tree-palm::before {
  content: "\1f334"; }

.fa-trees::before {
  content: "\f724"; }

.fa-triangle::before {
  content: "\25b2"; }

.fa-triangle-exclamation::before {
  content: "\26a0"; }

.fa-exclamation-triangle::before {
  content: "\26a0"; }

.fa-warning::before {
  content: "\26a0"; }

.fa-triangle-instrument::before {
  content: "\f8e2"; }

.fa-triangle-music::before {
  content: "\f8e2"; }

.fa-triangle-person-digging::before {
  content: "\f85d"; }

.fa-construction::before {
  content: "\f85d"; }

.fa-trophy::before {
  content: "\1f3c6"; }

.fa-trophy-star::before {
  content: "\f2eb"; }

.fa-trophy-alt::before {
  content: "\f2eb"; }

.fa-truck::before {
  content: "\26df"; }

.fa-truck-bolt::before {
  content: "\e3d0"; }

.fa-truck-clock::before {
  content: "\f48c"; }

.fa-shipping-timed::before {
  content: "\f48c"; }

.fa-truck-container::before {
  content: "\f4dc"; }

.fa-truck-container-empty::before {
  content: "\e2b5"; }

.fa-truck-fast::before {
  content: "\f48b"; }

.fa-shipping-fast::before {
  content: "\f48b"; }

.fa-truck-flatbed::before {
  content: "\e2b6"; }

.fa-truck-front::before {
  content: "\e2b7"; }

.fa-truck-medical::before {
  content: "\1f691"; }

.fa-ambulance::before {
  content: "\1f691"; }

.fa-truck-monster::before {
  content: "\f63b"; }

.fa-truck-moving::before {
  content: "\f4df"; }

.fa-truck-pickup::before {
  content: "\1f6fb"; }

.fa-truck-plow::before {
  content: "\f7de"; }

.fa-truck-ramp::before {
  content: "\f4e0"; }

.fa-truck-ramp-box::before {
  content: "\f4de"; }

.fa-truck-loading::before {
  content: "\f4de"; }

.fa-truck-ramp-couch::before {
  content: "\f4dd"; }

.fa-truck-couch::before {
  content: "\f4dd"; }

.fa-truck-tow::before {
  content: "\e2b8"; }

.fa-trumpet::before {
  content: "\1f3ba"; }

.fa-tty::before {
  content: "\f1e4"; }

.fa-teletype::before {
  content: "\f1e4"; }

.fa-tty-answer::before {
  content: "\e2b9"; }

.fa-teletype-answer::before {
  content: "\e2b9"; }

.fa-tugrik-sign::before {
  content: "\e2ba"; }

.fa-turkey::before {
  content: "\f725"; }

.fa-turkish-lira-sign::before {
  content: "\e2bb"; }

.fa-try::before {
  content: "\e2bb"; }

.fa-turkish-lira::before {
  content: "\e2bb"; }

.fa-turn-down::before {
  content: "\2935"; }

.fa-level-down-alt::before {
  content: "\2935"; }

.fa-turn-down-left::before {
  content: "\e331"; }

.fa-turn-up::before {
  content: "\2934"; }

.fa-level-up-alt::before {
  content: "\2934"; }

.fa-turntable::before {
  content: "\f8e4"; }

.fa-turtle::before {
  content: "\1f422"; }

.fa-tv::before {
  content: "\f26c"; }

.fa-television::before {
  content: "\f26c"; }

.fa-tv-alt::before {
  content: "\f26c"; }

.fa-tv-music::before {
  content: "\f8e6"; }

.fa-tv-retro::before {
  content: "\1f4fa"; }

.fa-typewriter::before {
  content: "\f8e7"; }

.fa-u::before {
  content: "\55"; }

.fa-ufo::before {
  content: "\1f6f8"; }

.fa-ufo-beam::before {
  content: "\e048"; }

.fa-umbrella::before {
  content: "\f0e9"; }

.fa-umbrella-beach::before {
  content: "\1f3d6"; }

.fa-umbrella-simple::before {
  content: "\e2bc"; }

.fa-umbrella-alt::before {
  content: "\e2bc"; }

.fa-underline::before {
  content: "\f0cd"; }

.fa-unicorn::before {
  content: "\1f984"; }

.fa-uniform-martial-arts::before {
  content: "\e3d1"; }

.fa-union::before {
  content: "\22c3"; }

.fa-universal-access::before {
  content: "\f29a"; }

.fa-unlock::before {
  content: "\1f513"; }

.fa-unlock-keyhole::before {
  content: "\f13e"; }

.fa-unlock-alt::before {
  content: "\f13e"; }

.fa-up::before {
  content: "\f357"; }

.fa-arrow-alt-up::before {
  content: "\f357"; }

.fa-up-down::before {
  content: "\2195"; }

.fa-arrows-alt-v::before {
  content: "\2195"; }

.fa-up-down-left-right::before {
  content: "\f0b2"; }

.fa-arrows-alt::before {
  content: "\f0b2"; }

.fa-up-from-line::before {
  content: "\f346"; }

.fa-arrow-alt-from-bottom::before {
  content: "\f346"; }

.fa-up-left::before {
  content: "\e2bd"; }

.fa-up-long::before {
  content: "\f30c"; }

.fa-long-arrow-alt-up::before {
  content: "\f30c"; }

.fa-up-right::before {
  content: "\e2be"; }

.fa-up-right-and-down-left-from-center::before {
  content: "\f424"; }

.fa-expand-alt::before {
  content: "\f424"; }

.fa-up-right-from-square::before {
  content: "\f35d"; }

.fa-external-link-alt::before {
  content: "\f35d"; }

.fa-up-to-line::before {
  content: "\f34d"; }

.fa-arrow-alt-to-top::before {
  content: "\f34d"; }

.fa-upload::before {
  content: "\f093"; }

.fa-usb-drive::before {
  content: "\f8e9"; }

.fa-user::before {
  content: "\1f464"; }

.fa-user-alien::before {
  content: "\e04a"; }

.fa-user-astronaut::before {
  content: "\f4fb"; }

.fa-user-bounty-hunter::before {
  content: "\e2bf"; }

.fa-user-check::before {
  content: "\f4fc"; }

.fa-user-chef::before {
  content: "\e3d2"; }

.fa-user-clock::before {
  content: "\f4fd"; }

.fa-user-cowboy::before {
  content: "\f8ea"; }

.fa-user-crown::before {
  content: "\f6a4"; }

.fa-user-doctor::before {
  content: "\f0f0"; }

.fa-user-md::before {
  content: "\f0f0"; }

.fa-user-doctor-message::before {
  content: "\f82e"; }

.fa-user-md-chat::before {
  content: "\f82e"; }

.fa-user-gear::before {
  content: "\f4fe"; }

.fa-user-cog::before {
  content: "\f4fe"; }

.fa-user-graduate::before {
  content: "\f501"; }

.fa-user-group::before {
  content: "\1f465"; }

.fa-user-friends::before {
  content: "\1f465"; }

.fa-user-group-crown::before {
  content: "\f6a5"; }

.fa-users-crown::before {
  content: "\f6a5"; }

.fa-user-hair-buns::before {
  content: "\e3d3"; }

.fa-user-headset::before {
  content: "\f82d"; }

.fa-user-helmet-safety::before {
  content: "\1f477"; }

.fa-user-construction::before {
  content: "\1f477"; }

.fa-user-hard-hat::before {
  content: "\1f477"; }

.fa-user-injured::before {
  content: "\f728"; }

.fa-user-large::before {
  content: "\f406"; }

.fa-user-alt::before {
  content: "\f406"; }

.fa-user-large-slash::before {
  content: "\f4fa"; }

.fa-user-alt-slash::before {
  content: "\f4fa"; }

.fa-user-lock::before {
  content: "\f502"; }

.fa-user-minus::before {
  content: "\f503"; }

.fa-user-music::before {
  content: "\f8eb"; }

.fa-user-ninja::before {
  content: "\1f977"; }

.fa-user-nurse::before {
  content: "\f82f"; }

.fa-user-pen::before {
  content: "\f4ff"; }

.fa-user-edit::before {
  content: "\f4ff"; }

.fa-user-pilot::before {
  content: "\e2c0"; }

.fa-user-pilot-tie::before {
  content: "\e2c1"; }

.fa-user-plus::before {
  content: "\f234"; }

.fa-user-police::before {
  content: "\e333"; }

.fa-user-police-tie::before {
  content: "\e334"; }

.fa-user-robot::before {
  content: "\e04b"; }

.fa-user-secret::before {
  content: "\1f575"; }

.fa-user-shakespeare::before {
  content: "\e2c2"; }

.fa-user-shield::before {
  content: "\f505"; }

.fa-user-slash::before {
  content: "\f506"; }

.fa-user-tag::before {
  content: "\f507"; }

.fa-user-tie::before {
  content: "\f508"; }

.fa-user-unlock::before {
  content: "\e058"; }

.fa-user-visor::before {
  content: "\e04c"; }

.fa-user-xmark::before {
  content: "\f235"; }

.fa-user-times::before {
  content: "\f235"; }

.fa-users::before {
  content: "\f0c0"; }

.fa-group::before {
  content: "\f0c0"; }

.fa-users-gear::before {
  content: "\f509"; }

.fa-users-cog::before {
  content: "\f509"; }

.fa-users-medical::before {
  content: "\f830"; }

.fa-users-slash::before {
  content: "\e073"; }

.fa-utensils::before {
  content: "\1f374"; }

.fa-cutlery::before {
  content: "\1f374"; }

.fa-utility-pole::before {
  content: "\e2c3"; }

.fa-utility-pole-double::before {
  content: "\e2c4"; }

.fa-v::before {
  content: "\56"; }

.fa-vacuum::before {
  content: "\e04d"; }

.fa-vacuum-robot::before {
  content: "\e04e"; }

.fa-value-absolute::before {
  content: "\f6a6"; }

.fa-van-shuttle::before {
  content: "\1f690"; }

.fa-shuttle-van::before {
  content: "\1f690"; }

.fa-vault::before {
  content: "\e2c5"; }

.fa-vector-circle::before {
  content: "\e2c6"; }

.fa-vector-polygon::before {
  content: "\e2c7"; }

.fa-vector-square::before {
  content: "\f5cb"; }

.fa-venus::before {
  content: "\2640"; }

.fa-venus-double::before {
  content: "\26a2"; }

.fa-venus-mars::before {
  content: "\26a4"; }

.fa-vest::before {
  content: "\e085"; }

.fa-vest-patches::before {
  content: "\e086"; }

.fa-vial::before {
  content: "\1f9ea"; }

.fa-vials::before {
  content: "\f493"; }

.fa-video::before {
  content: "\f03d"; }

.fa-video-camera::before {
  content: "\f03d"; }

.fa-video-arrow-down-left::before {
  content: "\e2c8"; }

.fa-video-arrow-up-right::before {
  content: "\e2c9"; }

.fa-video-plus::before {
  content: "\f4e1"; }

.fa-video-slash::before {
  content: "\f4e2"; }

.fa-vihara::before {
  content: "\f6a7"; }

.fa-violin::before {
  content: "\1f3bb"; }

.fa-virus::before {
  content: "\e074"; }

.fa-virus-slash::before {
  content: "\e075"; }

.fa-viruses::before {
  content: "\e076"; }

.fa-voicemail::before {
  content: "\f897"; }

.fa-volcano::before {
  content: "\1f30b"; }

.fa-volleyball::before {
  content: "\1f3d0"; }

.fa-volleyball-ball::before {
  content: "\1f3d0"; }

.fa-volume::before {
  content: "\1f509"; }

.fa-volume-medium::before {
  content: "\1f509"; }

.fa-volume-high::before {
  content: "\1f50a"; }

.fa-volume-up::before {
  content: "\1f50a"; }

.fa-volume-low::before {
  content: "\1f508"; }

.fa-volume-down::before {
  content: "\1f508"; }

.fa-volume-off::before {
  content: "\f026"; }

.fa-volume-slash::before {
  content: "\1f507"; }

.fa-volume-xmark::before {
  content: "\f6a9"; }

.fa-volume-mute::before {
  content: "\f6a9"; }

.fa-volume-times::before {
  content: "\f6a9"; }

.fa-vr-cardboard::before {
  content: "\f729"; }

.fa-w::before {
  content: "\57"; }

.fa-wagon-covered::before {
  content: "\f8ee"; }

.fa-walker::before {
  content: "\f831"; }

.fa-walkie-talkie::before {
  content: "\f8ef"; }

.fa-wallet::before {
  content: "\f555"; }

.fa-wand::before {
  content: "\f72a"; }

.fa-wand-magic::before {
  content: "\f0d0"; }

.fa-magic::before {
  content: "\f0d0"; }

.fa-wand-magic-sparkles::before {
  content: "\e2ca"; }

.fa-magic-wand-sparkles::before {
  content: "\e2ca"; }

.fa-wand-sparkles::before {
  content: "\f72b"; }

.fa-warehouse::before {
  content: "\f494"; }

.fa-warehouse-full::before {
  content: "\f495"; }

.fa-warehouse-alt::before {
  content: "\f495"; }

.fa-washing-machine::before {
  content: "\f898"; }

.fa-washer::before {
  content: "\f898"; }

.fa-watch::before {
  content: "\231a"; }

.fa-watch-apple::before {
  content: "\e2cb"; }

.fa-watch-calculator::before {
  content: "\f8f0"; }

.fa-watch-fitness::before {
  content: "\f63e"; }

.fa-watch-smart::before {
  content: "\e2cc"; }

.fa-water::before {
  content: "\f773"; }

.fa-water-arrow-down::before {
  content: "\f774"; }

.fa-water-lower::before {
  content: "\f774"; }

.fa-water-arrow-up::before {
  content: "\f775"; }

.fa-water-rise::before {
  content: "\f775"; }

.fa-water-ladder::before {
  content: "\f5c5"; }

.fa-ladder-water::before {
  content: "\f5c5"; }

.fa-swimming-pool::before {
  content: "\f5c5"; }

.fa-watermelon-slice::before {
  content: "\e337"; }

.fa-wave-pulse::before {
  content: "\f5f8"; }

.fa-heart-rate::before {
  content: "\f5f8"; }

.fa-wave-sine::before {
  content: "\223f"; }

.fa-wave-square::before {
  content: "\f83e"; }

.fa-wave-triangle::before {
  content: "\f89a"; }

.fa-waveform::before {
  content: "\f8f1"; }

.fa-waveform-lines::before {
  content: "\f8f2"; }

.fa-weight-hanging::before {
  content: "\f5cd"; }

.fa-weight-scale::before {
  content: "\f496"; }

.fa-weight::before {
  content: "\f496"; }

.fa-whale::before {
  content: "\1f40b"; }

.fa-wheat::before {
  content: "\f72d"; }

.fa-wheat-awn::before {
  content: "\e2cd"; }

.fa-wheat-alt::before {
  content: "\e2cd"; }

.fa-wheat-awn-slash::before {
  content: "\e338"; }

.fa-wheat-slash::before {
  content: "\e339"; }

.fa-wheelchair::before {
  content: "\f193"; }

.fa-wheelchair-move::before {
  content: "\e2ce"; }

.fa-wheelchair-alt::before {
  content: "\e2ce"; }

.fa-whiskey-glass::before {
  content: "\1f943"; }

.fa-glass-whiskey::before {
  content: "\1f943"; }

.fa-whiskey-glass-ice::before {
  content: "\f7a1"; }

.fa-glass-whiskey-rocks::before {
  content: "\f7a1"; }

.fa-whistle::before {
  content: "\f460"; }

.fa-wifi::before {
  content: "\f1eb"; }

.fa-wifi-3::before {
  content: "\f1eb"; }

.fa-wifi-strong::before {
  content: "\f1eb"; }

.fa-wifi-exclamation::before {
  content: "\e2cf"; }

.fa-wifi-fair::before {
  content: "\f6ab"; }

.fa-wifi-2::before {
  content: "\f6ab"; }

.fa-wifi-slash::before {
  content: "\f6ac"; }

.fa-wifi-weak::before {
  content: "\f6aa"; }

.fa-wifi-1::before {
  content: "\f6aa"; }

.fa-wind::before {
  content: "\f72e"; }

.fa-wind-turbine::before {
  content: "\f89b"; }

.fa-wind-warning::before {
  content: "\f776"; }

.fa-wind-circle-exclamation::before {
  content: "\f776"; }

.fa-window::before {
  content: "\f40e"; }

.fa-window-flip::before {
  content: "\f40f"; }

.fa-window-alt::before {
  content: "\f40f"; }

.fa-window-frame::before {
  content: "\1fa9f"; }

.fa-window-frame-open::before {
  content: "\e050"; }

.fa-window-maximize::before {
  content: "\1f5d6"; }

.fa-window-minimize::before {
  content: "\1f5d5"; }

.fa-window-restore::before {
  content: "\f2d2"; }

.fa-windsock::before {
  content: "\f777"; }

.fa-wine-bottle::before {
  content: "\f72f"; }

.fa-wine-glass::before {
  content: "\1f377"; }

.fa-wine-glass-crack::before {
  content: "\f4bb"; }

.fa-fragile::before {
  content: "\f4bb"; }

.fa-wine-glass-empty::before {
  content: "\f5ce"; }

.fa-wine-glass-alt::before {
  content: "\f5ce"; }

.fa-won-sign::before {
  content: "\20a9"; }

.fa-krw::before {
  content: "\20a9"; }

.fa-won::before {
  content: "\20a9"; }

.fa-wreath::before {
  content: "\f7e2"; }

.fa-wrench::before {
  content: "\1f527"; }

.fa-wrench-simple::before {
  content: "\e2d1"; }

.fa-x::before {
  content: "\58"; }

.fa-x-ray::before {
  content: "\f497"; }

.fa-xmark::before {
  content: "\d7"; }

.fa-close::before {
  content: "\d7"; }

.fa-multiply::before {
  content: "\d7"; }

.fa-remove::before {
  content: "\d7"; }

.fa-times::before {
  content: "\d7"; }

.fa-xmark-to-slot::before {
  content: "\f771"; }

.fa-times-to-slot::before {
  content: "\f771"; }

.fa-vote-nay::before {
  content: "\f771"; }

.fa-y::before {
  content: "\59"; }

.fa-yen-sign::before {
  content: "\a5"; }

.fa-cny::before {
  content: "\a5"; }

.fa-jpy::before {
  content: "\a5"; }

.fa-rmb::before {
  content: "\a5"; }

.fa-yen::before {
  content: "\a5"; }

.fa-yin-yang::before {
  content: "\262f"; }

.fa-z::before {
  content: "\5a"; }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

.fa-500px:before {
  content: "\f26e"; }

.fa-accessible-icon:before {
  content: "\f368"; }

.fa-accusoft:before {
  content: "\f369"; }

.fa-acquisitions-incorporated:before {
  content: "\f6af"; }

.fa-adn:before {
  content: "\f170"; }

.fa-adversal:before {
  content: "\f36a"; }

.fa-affiliatetheme:before {
  content: "\f36b"; }

.fa-airbnb:before {
  content: "\f834"; }

.fa-algolia:before {
  content: "\f36c"; }

.fa-alipay:before {
  content: "\f642"; }

.fa-amazon:before {
  content: "\f270"; }

.fa-amazon-pay:before {
  content: "\f42c"; }

.fa-amilia:before {
  content: "\f36d"; }

.fa-android:before {
  content: "\f17b"; }

.fa-angellist:before {
  content: "\f209"; }

.fa-angrycreative:before {
  content: "\f36e"; }

.fa-angular:before {
  content: "\f420"; }

.fa-app-store:before {
  content: "\f36f"; }

.fa-app-store-ios:before {
  content: "\f370"; }

.fa-apper:before {
  content: "\f371"; }

.fa-apple:before {
  content: "\f179"; }

.fa-apple-pay:before {
  content: "\f415"; }

.fa-artstation:before {
  content: "\f77a"; }

.fa-asymmetrik:before {
  content: "\f372"; }

.fa-atlassian:before {
  content: "\f77b"; }

.fa-audible:before {
  content: "\f373"; }

.fa-autoprefixer:before {
  content: "\f41c"; }

.fa-avianex:before {
  content: "\f374"; }

.fa-aviato:before {
  content: "\f421"; }

.fa-aws:before {
  content: "\f375"; }

.fa-bandcamp:before {
  content: "\f2d5"; }

.fa-battle-net:before {
  content: "\f835"; }

.fa-behance:before {
  content: "\f1b4"; }

.fa-behance-square:before {
  content: "\f1b5"; }

.fa-bimobject:before {
  content: "\f378"; }

.fa-bitbucket:before {
  content: "\f171"; }

.fa-bitcoin:before {
  content: "\f379"; }

.fa-bity:before {
  content: "\f37a"; }

.fa-black-tie:before {
  content: "\f27e"; }

.fa-blackberry:before {
  content: "\f37b"; }

.fa-blogger:before {
  content: "\f37c"; }

.fa-blogger-b:before {
  content: "\f37d"; }

.fa-bluetooth:before {
  content: "\f293"; }

.fa-bluetooth-b:before {
  content: "\f294"; }

.fa-bootstrap:before {
  content: "\f836"; }

.fa-bots:before {
  content: "\e340"; }

.fa-btc:before {
  content: "\f15a"; }

.fa-buffer:before {
  content: "\f837"; }

.fa-buromobelexperte:before {
  content: "\f37f"; }

.fa-buy-n-large:before {
  content: "\f8a6"; }

.fa-buysellads:before {
  content: "\f20d"; }

.fa-canadian-maple-leaf:before {
  content: "\f785"; }

.fa-cc-amazon-pay:before {
  content: "\f42d"; }

.fa-cc-amex:before {
  content: "\f1f3"; }

.fa-cc-apple-pay:before {
  content: "\f416"; }

.fa-cc-diners-club:before {
  content: "\f24c"; }

.fa-cc-discover:before {
  content: "\f1f2"; }

.fa-cc-jcb:before {
  content: "\f24b"; }

.fa-cc-mastercard:before {
  content: "\f1f1"; }

.fa-cc-paypal:before {
  content: "\f1f4"; }

.fa-cc-stripe:before {
  content: "\f1f5"; }

.fa-cc-visa:before {
  content: "\f1f0"; }

.fa-centercode:before {
  content: "\f380"; }

.fa-centos:before {
  content: "\f789"; }

.fa-chrome:before {
  content: "\f268"; }

.fa-chromecast:before {
  content: "\f838"; }

.fa-cloudflare:before {
  content: "\e07d"; }

.fa-cloudscale:before {
  content: "\f383"; }

.fa-cloudsmith:before {
  content: "\f384"; }

.fa-cloudversify:before {
  content: "\f385"; }

.fa-cmplid:before {
  content: "\e360"; }

.fa-codepen:before {
  content: "\f1cb"; }

.fa-codiepie:before {
  content: "\f284"; }

.fa-confluence:before {
  content: "\f78d"; }

.fa-connectdevelop:before {
  content: "\f20e"; }

.fa-contao:before {
  content: "\f26d"; }

.fa-cotton-bureau:before {
  content: "\f89e"; }

.fa-cpanel:before {
  content: "\f388"; }

.fa-creative-commons:before {
  content: "\f25e"; }

.fa-creative-commons-by:before {
  content: "\f4e7"; }

.fa-creative-commons-nc:before {
  content: "\f4e8"; }

.fa-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.fa-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.fa-creative-commons-nd:before {
  content: "\f4eb"; }

.fa-creative-commons-pd:before {
  content: "\f4ec"; }

.fa-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.fa-creative-commons-remix:before {
  content: "\f4ee"; }

.fa-creative-commons-sa:before {
  content: "\f4ef"; }

.fa-creative-commons-sampling:before {
  content: "\f4f0"; }

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.fa-creative-commons-share:before {
  content: "\f4f2"; }

.fa-creative-commons-zero:before {
  content: "\f4f3"; }

.fa-critical-role:before {
  content: "\f6c9"; }

.fa-css3:before {
  content: "\f13c"; }

.fa-css3-alt:before {
  content: "\f38b"; }

.fa-cuttlefish:before {
  content: "\f38c"; }

.fa-d-and-d:before {
  content: "\f38d"; }

.fa-d-and-d-beyond:before {
  content: "\f6ca"; }

.fa-dailymotion:before {
  content: "\e052"; }

.fa-dashcube:before {
  content: "\f210"; }

.fa-deezer:before {
  content: "\e077"; }

.fa-delicious:before {
  content: "\f1a5"; }

.fa-deploydog:before {
  content: "\f38e"; }

.fa-deskpro:before {
  content: "\f38f"; }

.fa-dev:before {
  content: "\f6cc"; }

.fa-deviantart:before {
  content: "\f1bd"; }

.fa-dhl:before {
  content: "\f790"; }

.fa-diaspora:before {
  content: "\f791"; }

.fa-digg:before {
  content: "\f1a6"; }

.fa-digital-ocean:before {
  content: "\f391"; }

.fa-discord:before {
  content: "\f392"; }

.fa-discourse:before {
  content: "\f393"; }

.fa-dochub:before {
  content: "\f394"; }

.fa-docker:before {
  content: "\f395"; }

.fa-draft2digital:before {
  content: "\f396"; }

.fa-dribbble:before {
  content: "\f17d"; }

.fa-dribbble-square:before {
  content: "\f397"; }

.fa-dropbox:before {
  content: "\f16b"; }

.fa-drupal:before {
  content: "\f1a9"; }

.fa-dyalog:before {
  content: "\f399"; }

.fa-earlybirds:before {
  content: "\f39a"; }

.fa-ebay:before {
  content: "\f4f4"; }

.fa-edge:before {
  content: "\f282"; }

.fa-edge-legacy:before {
  content: "\e078"; }

.fa-elementor:before {
  content: "\f430"; }

.fa-ello:before {
  content: "\f5f1"; }

.fa-ember:before {
  content: "\f423"; }

.fa-empire:before {
  content: "\f1d1"; }

.fa-envira:before {
  content: "\f299"; }

.fa-erlang:before {
  content: "\f39d"; }

.fa-ethereum:before {
  content: "\f42e"; }

.fa-etsy:before {
  content: "\f2d7"; }

.fa-evernote:before {
  content: "\f839"; }

.fa-expeditedssl:before {
  content: "\f23e"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-facebook-messenger:before {
  content: "\f39f"; }

.fa-facebook-square:before {
  content: "\f082"; }

.fa-fantasy-flight-games:before {
  content: "\f6dc"; }

.fa-fedex:before {
  content: "\f797"; }

.fa-fedora:before {
  content: "\f798"; }

.fa-figma:before {
  content: "\f799"; }

.fa-firefox:before {
  content: "\f269"; }

.fa-firefox-browser:before {
  content: "\e007"; }

.fa-first-order:before {
  content: "\f2b0"; }

.fa-first-order-alt:before {
  content: "\f50a"; }

.fa-firstdraft:before {
  content: "\f3a1"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-flipboard:before {
  content: "\f44d"; }

.fa-fly:before {
  content: "\f417"; }

.fa-font-awesome:before {
  content: "\f2b4"; }

.fa-font-awesome-flag:before {
  content: "\f2b4"; }

.fa-font-awesome-logo-full:before {
  content: "\f2b4"; }

.fa-fonticons:before {
  content: "\f280"; }

.fa-fonticons-fi:before {
  content: "\f3a2"; }

.fa-fort-awesome:before {
  content: "\f286"; }

.fa-fort-awesome-alt:before {
  content: "\f3a3"; }

.fa-forumbee:before {
  content: "\f211"; }

.fa-foursquare:before {
  content: "\f180"; }

.fa-free-code-camp:before {
  content: "\f2c5"; }

.fa-freebsd:before {
  content: "\f3a4"; }

.fa-fulcrum:before {
  content: "\f50b"; }

.fa-galactic-republic:before {
  content: "\f50c"; }

.fa-galactic-senate:before {
  content: "\f50d"; }

.fa-get-pocket:before {
  content: "\f265"; }

.fa-gg:before {
  content: "\f260"; }

.fa-gg-circle:before {
  content: "\f261"; }

.fa-git:before {
  content: "\f1d3"; }

.fa-git-alt:before {
  content: "\f841"; }

.fa-git-square:before {
  content: "\f1d2"; }

.fa-github:before {
  content: "\f09b"; }

.fa-github-alt:before {
  content: "\f113"; }

.fa-github-square:before {
  content: "\f092"; }

.fa-gitkraken:before {
  content: "\f3a6"; }

.fa-gitlab:before {
  content: "\f296"; }

.fa-gitter:before {
  content: "\f426"; }

.fa-glide:before {
  content: "\f2a5"; }

.fa-glide-g:before {
  content: "\f2a6"; }

.fa-gofore:before {
  content: "\f3a7"; }

.fa-goodreads:before {
  content: "\f3a8"; }

.fa-goodreads-g:before {
  content: "\f3a9"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-google-drive:before {
  content: "\f3aa"; }

.fa-google-pay:before {
  content: "\e079"; }

.fa-google-play:before {
  content: "\f3ab"; }

.fa-google-plus:before {
  content: "\f2b3"; }

.fa-google-plus-g:before {
  content: "\f0d5"; }

.fa-google-plus-square:before {
  content: "\f0d4"; }

.fa-google-wallet:before {
  content: "\f1ee"; }

.fa-gratipay:before {
  content: "\f184"; }

.fa-grav:before {
  content: "\f2d6"; }

.fa-gripfire:before {
  content: "\f3ac"; }

.fa-grunt:before {
  content: "\f3ad"; }

.fa-guilded:before {
  content: "\e07e"; }

.fa-gulp:before {
  content: "\f3ae"; }

.fa-hacker-news:before {
  content: "\f1d4"; }

.fa-hacker-news-square:before {
  content: "\f3af"; }

.fa-hackerrank:before {
  content: "\f5f7"; }

.fa-hips:before {
  content: "\f452"; }

.fa-hire-a-helper:before {
  content: "\f3b0"; }

.fa-hive:before {
  content: "\e07f"; }

.fa-hooli:before {
  content: "\f427"; }

.fa-hornbill:before {
  content: "\f592"; }

.fa-hotjar:before {
  content: "\f3b1"; }

.fa-houzz:before {
  content: "\f27c"; }

.fa-html5:before {
  content: "\f13b"; }

.fa-hubspot:before {
  content: "\f3b2"; }

.fa-ideal:before {
  content: "\e013"; }

.fa-imdb:before {
  content: "\f2d8"; }

.fa-innosoft:before {
  content: "\e080"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-instagram-square:before {
  content: "\e055"; }

.fa-instalod:before {
  content: "\e081"; }

.fa-intercom:before {
  content: "\f7af"; }

.fa-internet-explorer:before {
  content: "\f26b"; }

.fa-invision:before {
  content: "\f7b0"; }

.fa-ioxhost:before {
  content: "\f208"; }

.fa-itch-io:before {
  content: "\f83a"; }

.fa-itunes:before {
  content: "\f3b4"; }

.fa-itunes-note:before {
  content: "\f3b5"; }

.fa-java:before {
  content: "\f4e4"; }

.fa-jedi-order:before {
  content: "\f50e"; }

.fa-jenkins:before {
  content: "\f3b6"; }

.fa-jira:before {
  content: "\f7b1"; }

.fa-joget:before {
  content: "\f3b7"; }

.fa-joomla:before {
  content: "\f1aa"; }

.fa-js:before {
  content: "\f3b8"; }

.fa-js-square:before {
  content: "\f3b9"; }

.fa-jsfiddle:before {
  content: "\f1cc"; }

.fa-kaggle:before {
  content: "\f5fa"; }

.fa-keybase:before {
  content: "\f4f5"; }

.fa-keycdn:before {
  content: "\f3ba"; }

.fa-kickstarter:before {
  content: "\f3bb"; }

.fa-kickstarter-k:before {
  content: "\f3bc"; }

.fa-korvue:before {
  content: "\f42f"; }

.fa-laravel:before {
  content: "\f3bd"; }

.fa-lastfm:before {
  content: "\f202"; }

.fa-lastfm-square:before {
  content: "\f203"; }

.fa-leanpub:before {
  content: "\f212"; }

.fa-less:before {
  content: "\f41d"; }

.fa-line:before {
  content: "\f3c0"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-linkedin-in:before {
  content: "\f0e1"; }

.fa-linode:before {
  content: "\f2b8"; }

.fa-linux:before {
  content: "\f17c"; }

.fa-lyft:before {
  content: "\f3c3"; }

.fa-magento:before {
  content: "\f3c4"; }

.fa-mailchimp:before {
  content: "\f59e"; }

.fa-mandalorian:before {
  content: "\f50f"; }

.fa-markdown:before {
  content: "\f60f"; }

.fa-mastodon:before {
  content: "\f4f6"; }

.fa-maxcdn:before {
  content: "\f136"; }

.fa-mdb:before {
  content: "\f8ca"; }

.fa-medapps:before {
  content: "\f3c6"; }

.fa-medium:before {
  content: "\f23a"; }

.fa-medium-m:before {
  content: "\f23a"; }

.fa-medrt:before {
  content: "\f3c8"; }

.fa-meetup:before {
  content: "\f2e0"; }

.fa-megaport:before {
  content: "\f5a3"; }

.fa-mendeley:before {
  content: "\f7b3"; }

.fa-microblog:before {
  content: "\e01a"; }

.fa-microsoft:before {
  content: "\f3ca"; }

.fa-mix:before {
  content: "\f3cb"; }

.fa-mixcloud:before {
  content: "\f289"; }

.fa-mixer:before {
  content: "\e056"; }

.fa-mizuni:before {
  content: "\f3cc"; }

.fa-modx:before {
  content: "\f285"; }

.fa-monero:before {
  content: "\f3d0"; }

.fa-napster:before {
  content: "\f3d2"; }

.fa-neos:before {
  content: "\f612"; }

.fa-nimblr:before {
  content: "\f5a8"; }

.fa-node:before {
  content: "\f419"; }

.fa-node-js:before {
  content: "\f3d3"; }

.fa-npm:before {
  content: "\f3d4"; }

.fa-ns8:before {
  content: "\f3d5"; }

.fa-nutritionix:before {
  content: "\f3d6"; }

.fa-octopus-deploy:before {
  content: "\e082"; }

.fa-odnoklassniki:before {
  content: "\f263"; }

.fa-odnoklassniki-square:before {
  content: "\f264"; }

.fa-old-republic:before {
  content: "\f510"; }

.fa-opencart:before {
  content: "\f23d"; }

.fa-openid:before {
  content: "\f19b"; }

.fa-opera:before {
  content: "\f26a"; }

.fa-optin-monster:before {
  content: "\f23c"; }

.fa-orcid:before {
  content: "\f8d2"; }

.fa-osi:before {
  content: "\f41a"; }

.fa-page4:before {
  content: "\f3d7"; }

.fa-pagelines:before {
  content: "\f18c"; }

.fa-palfed:before {
  content: "\f3d8"; }

.fa-patreon:before {
  content: "\f3d9"; }

.fa-paypal:before {
  content: "\f1ed"; }

.fa-penny-arcade:before {
  content: "\f704"; }

.fa-perbyte:before {
  content: "\e083"; }

.fa-periscope:before {
  content: "\f3da"; }

.fa-phabricator:before {
  content: "\f3db"; }

.fa-phoenix-framework:before {
  content: "\f3dc"; }

.fa-phoenix-squadron:before {
  content: "\f511"; }

.fa-php:before {
  content: "\f457"; }

.fa-pied-piper:before {
  content: "\f2ae"; }

.fa-pied-piper-alt:before {
  content: "\f1a8"; }

.fa-pied-piper-hat:before {
  content: "\f4e5"; }

.fa-pied-piper-pp:before {
  content: "\f1a7"; }

.fa-pied-piper-square:before {
  content: "\e01e"; }

.fa-pinterest:before {
  content: "\f0d2"; }

.fa-pinterest-p:before {
  content: "\f231"; }

.fa-pinterest-square:before {
  content: "\f0d3"; }

.fa-playstation:before {
  content: "\f3df"; }

.fa-product-hunt:before {
  content: "\f288"; }

.fa-pushed:before {
  content: "\f3e1"; }

.fa-python:before {
  content: "\f3e2"; }

.fa-qq:before {
  content: "\f1d6"; }

.fa-quinscape:before {
  content: "\f459"; }

.fa-quora:before {
  content: "\f2c4"; }

.fa-r-project:before {
  content: "\f4f7"; }

.fa-raspberry-pi:before {
  content: "\f7bb"; }

.fa-ravelry:before {
  content: "\f2d9"; }

.fa-react:before {
  content: "\f41b"; }

.fa-reacteurope:before {
  content: "\f75d"; }

.fa-readme:before {
  content: "\f4d5"; }

.fa-rebel:before {
  content: "\f1d0"; }

.fa-red-river:before {
  content: "\f3e3"; }

.fa-reddit:before {
  content: "\f1a1"; }

.fa-reddit-alien:before {
  content: "\f281"; }

.fa-reddit-square:before {
  content: "\f1a2"; }

.fa-redhat:before {
  content: "\f7bc"; }

.fa-renren:before {
  content: "\f18b"; }

.fa-replyd:before {
  content: "\f3e6"; }

.fa-researchgate:before {
  content: "\f4f8"; }

.fa-resolving:before {
  content: "\f3e7"; }

.fa-rev:before {
  content: "\f5b2"; }

.fa-rocketchat:before {
  content: "\f3e8"; }

.fa-rockrms:before {
  content: "\f3e9"; }

.fa-rust:before {
  content: "\e07a"; }

.fa-safari:before {
  content: "\f267"; }

.fa-salesforce:before {
  content: "\f83b"; }

.fa-sass:before {
  content: "\f41e"; }

.fa-schlix:before {
  content: "\f3ea"; }

.fa-scribd:before {
  content: "\f28a"; }

.fa-searchengin:before {
  content: "\f3eb"; }

.fa-sellcast:before {
  content: "\f2da"; }

.fa-sellsy:before {
  content: "\f213"; }

.fa-servicestack:before {
  content: "\f3ec"; }

.fa-shirtsinbulk:before {
  content: "\f214"; }

.fa-shopify:before {
  content: "\e057"; }

.fa-shopware:before {
  content: "\f5b5"; }

.fa-simplybuilt:before {
  content: "\f215"; }

.fa-sistrix:before {
  content: "\f3ee"; }

.fa-sith:before {
  content: "\f512"; }

.fa-sketch:before {
  content: "\f7c6"; }

.fa-skyatlas:before {
  content: "\f216"; }

.fa-skype:before {
  content: "\f17e"; }

.fa-slack:before {
  content: "\f198"; }

.fa-slack-hash:before {
  content: "\f198"; }

.fa-slideshare:before {
  content: "\f1e7"; }

.fa-snapchat:before {
  content: "\f2ab"; }

.fa-snapchat-ghost:before {
  content: "\f2ab"; }

.fa-snapchat-square:before {
  content: "\f2ad"; }

.fa-soundcloud:before {
  content: "\f1be"; }

.fa-sourcetree:before {
  content: "\f7d3"; }

.fa-speakap:before {
  content: "\f3f3"; }

.fa-speaker-deck:before {
  content: "\f83c"; }

.fa-spotify:before {
  content: "\f1bc"; }

.fa-square-font-awesome:before {
  content: "\f425"; }

.fa-square-font-awesome-stroke:before {
  content: "\f35c"; }

.fa-font-awesome-alt:before {
  content: "\f35c"; }

.fa-squarespace:before {
  content: "\f5be"; }

.fa-stack-exchange:before {
  content: "\f18d"; }

.fa-stack-overflow:before {
  content: "\f16c"; }

.fa-stackpath:before {
  content: "\f842"; }

.fa-staylinked:before {
  content: "\f3f5"; }

.fa-steam:before {
  content: "\f1b6"; }

.fa-steam-square:before {
  content: "\f1b7"; }

.fa-steam-symbol:before {
  content: "\f3f6"; }

.fa-sticker-mule:before {
  content: "\f3f7"; }

.fa-strava:before {
  content: "\f428"; }

.fa-stripe:before {
  content: "\f429"; }

.fa-stripe-s:before {
  content: "\f42a"; }

.fa-studiovinari:before {
  content: "\f3f8"; }

.fa-stumbleupon:before {
  content: "\f1a4"; }

.fa-stumbleupon-circle:before {
  content: "\f1a3"; }

.fa-superpowers:before {
  content: "\f2dd"; }

.fa-supple:before {
  content: "\f3f9"; }

.fa-suse:before {
  content: "\f7d6"; }

.fa-swift:before {
  content: "\f8e1"; }

.fa-symfony:before {
  content: "\f83d"; }

.fa-teamspeak:before {
  content: "\f4f9"; }

.fa-telegram:before {
  content: "\f2c6"; }

.fa-telegram-plane:before {
  content: "\f2c6"; }

.fa-tencent-weibo:before {
  content: "\f1d5"; }

.fa-the-red-yeti:before {
  content: "\f69d"; }

.fa-themeco:before {
  content: "\f5c6"; }

.fa-themeisle:before {
  content: "\f2b2"; }

.fa-think-peaks:before {
  content: "\f731"; }

.fa-tiktok:before {
  content: "\e07b"; }

.fa-trade-federation:before {
  content: "\f513"; }

.fa-trello:before {
  content: "\f181"; }

.fa-tripadvisor:before {
  content: "\f262"; }

.fa-tumblr:before {
  content: "\f173"; }

.fa-tumblr-square:before {
  content: "\f174"; }

.fa-twitch:before {
  content: "\f1e8"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-twitter-square:before {
  content: "\f081"; }

.fa-typo3:before {
  content: "\f42b"; }

.fa-uber:before {
  content: "\f402"; }

.fa-ubuntu:before {
  content: "\f7df"; }

.fa-uikit:before {
  content: "\f403"; }

.fa-umbraco:before {
  content: "\f8e8"; }

.fa-uncharted:before {
  content: "\e084"; }

.fa-uniregistry:before {
  content: "\f404"; }

.fa-unity:before {
  content: "\e049"; }

.fa-unsplash:before {
  content: "\e07c"; }

.fa-untappd:before {
  content: "\f405"; }

.fa-ups:before {
  content: "\f7e0"; }

.fa-usb:before {
  content: "\f287"; }

.fa-usps:before {
  content: "\f7e1"; }

.fa-ussunnah:before {
  content: "\f407"; }

.fa-vaadin:before {
  content: "\f408"; }

.fa-viacoin:before {
  content: "\f237"; }

.fa-viadeo:before {
  content: "\f2a9"; }

.fa-viadeo-square:before {
  content: "\f2aa"; }

.fa-viber:before {
  content: "\f409"; }

.fa-vimeo:before {
  content: "\f40a"; }

.fa-vimeo-square:before {
  content: "\f194"; }

.fa-vimeo-v:before {
  content: "\f27d"; }

.fa-vine:before {
  content: "\f1ca"; }

.fa-vk:before {
  content: "\f189"; }

.fa-vnv:before {
  content: "\f40b"; }

.fa-vuejs:before {
  content: "\f41f"; }

.fa-watchman-monitoring:before {
  content: "\e087"; }

.fa-waze:before {
  content: "\f83f"; }

.fa-weebly:before {
  content: "\f5cc"; }

.fa-weibo:before {
  content: "\f18a"; }

.fa-weixin:before {
  content: "\f1d7"; }

.fa-whatsapp:before {
  content: "\f232"; }

.fa-whatsapp-square:before {
  content: "\f40c"; }

.fa-whmcs:before {
  content: "\f40d"; }

.fa-wikipedia-w:before {
  content: "\f266"; }

.fa-windows:before {
  content: "\f17a"; }

.fa-wirsindhanderk:before {
  content: "\e2d0"; }

.fa-wsh:before {
  content: "\e2d0"; }

.fa-wix:before {
  content: "\f5cf"; }

.fa-wizards-of-the-coast:before {
  content: "\f730"; }

.fa-wodu:before {
  content: "\e088"; }

.fa-wolf-pack-battalion:before {
  content: "\f514"; }

.fa-wordpress:before {
  content: "\f19a"; }

.fa-wordpress-simple:before {
  content: "\f411"; }

.fa-wpbeginner:before {
  content: "\f297"; }

.fa-wpexplorer:before {
  content: "\f2de"; }

.fa-wpforms:before {
  content: "\f298"; }

.fa-wpressr:before {
  content: "\f3e4"; }

.fa-xbox:before {
  content: "\f412"; }

.fa-xing:before {
  content: "\f168"; }

.fa-xing-square:before {
  content: "\f169"; }

.fa-y-combinator:before {
  content: "\f23b"; }

.fa-yahoo:before {
  content: "\f19e"; }

.fa-yammer:before {
  content: "\f840"; }

.fa-yandex:before {
  content: "\f413"; }

.fa-yandex-international:before {
  content: "\f414"; }

.fa-yarn:before {
  content: "\f7e3"; }

.fa-yelp:before {
  content: "\f1e9"; }

.fa-yoast:before {
  content: "\f2b1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-square:before {
  content: "\f431"; }

.fa-zhihu:before {
  content: "\f63f"; }
.fad,
.fa-duotone {
  position: relative;
  font-family: 'Font Awesome 6 Duotone';
  font-weight: 900; }

.fad:before,
.fa-duotone:before {
  position: absolute;
  color: inherit;
  color: var(--fa-primary-color, inherit);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.fad:after,
.fa-duotone:after {
  color: inherit;
  color: var(--fa-secondary-color, inherit);
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad:before,
.fa-swap-opacity .fa-duotone:before,
.fad.fa-swap-opacity:before,
.fa-duotone.fa-swap-opacity:before {
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4); }

.fa-swap-opacity .fad:after,
.fa-swap-opacity .fa-duotone:after,
.fad.fa-swap-opacity:after,
.fa-duotone.fa-swap-opacity:after {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1); }

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: #fff;
  color: var(--fa-inverse, #fff); }

.fad.fa-stack-1x, .fad.fa-stack-2x,
.fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute; }

.fad.fa-stack-1x:before,
.fa-duotone.fa-stack-1x:before,
.fad.fa-stack-2x:before,
.fa-duotone.fa-stack-2x:before,
.fad.fa-fw:before,
.fa-duotone.fa-fw:before {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.fad.fa-0:before, .fa-duotone.fa-0:before {
  content: "\30\fe01"; }

.fad.fa-0:after, .fa-duotone.fa-0:after {
  content: "\30\fe02"; }

.fad.fa-1:before, .fa-duotone.fa-1:before {
  content: "\31\fe01"; }

.fad.fa-1:after, .fa-duotone.fa-1:after {
  content: "\31\fe02"; }

.fad.fa-2:before, .fa-duotone.fa-2:before {
  content: "\32\fe01"; }

.fad.fa-2:after, .fa-duotone.fa-2:after {
  content: "\32\fe02"; }

.fad.fa-3:before, .fa-duotone.fa-3:before {
  content: "\33\fe01"; }

.fad.fa-3:after, .fa-duotone.fa-3:after {
  content: "\33\fe02"; }

.fad.fa-4:before, .fa-duotone.fa-4:before {
  content: "\34\fe01"; }

.fad.fa-4:after, .fa-duotone.fa-4:after {
  content: "\34\fe02"; }

.fad.fa-5:before, .fa-duotone.fa-5:before {
  content: "\35\fe01"; }

.fad.fa-5:after, .fa-duotone.fa-5:after {
  content: "\35\fe02"; }

.fad.fa-6:before, .fa-duotone.fa-6:before {
  content: "\36\fe01"; }

.fad.fa-6:after, .fa-duotone.fa-6:after {
  content: "\36\fe02"; }

.fad.fa-7:before, .fa-duotone.fa-7:before {
  content: "\37\fe01"; }

.fad.fa-7:after, .fa-duotone.fa-7:after {
  content: "\37\fe02"; }

.fad.fa-8:before, .fa-duotone.fa-8:before {
  content: "\38\fe01"; }

.fad.fa-8:after, .fa-duotone.fa-8:after {
  content: "\38\fe02"; }

.fad.fa-9:before, .fa-duotone.fa-9:before {
  content: "\39\fe01"; }

.fad.fa-9:after, .fa-duotone.fa-9:after {
  content: "\39\fe02"; }

.fad.fa-360-degrees:before, .fa-duotone.fa-360-degrees:before {
  content: "\e2dc\fe01"; }

.fad.fa-360-degrees:after, .fa-duotone.fa-360-degrees:after {
  content: "\e2dc\fe02"; }

.fad.fa-a:before, .fa-duotone.fa-a:before {
  content: "\41\fe01"; }

.fad.fa-a:after, .fa-duotone.fa-a:after {
  content: "\41\fe02"; }

.fad.fa-abacus:before, .fa-duotone.fa-abacus:before {
  content: "\f640"; }

.fad.fa-abacus:after, .fa-duotone.fa-abacus:after {
  content: "\10f640"; }

.fad.fa-accent-grave:before, .fa-duotone.fa-accent-grave:before {
  content: "\60\fe01"; }

.fad.fa-accent-grave:after, .fa-duotone.fa-accent-grave:after {
  content: "\60\fe02"; }

.fad.fa-acorn:before, .fa-duotone.fa-acorn:before {
  content: "\f6ae\fe01"; }

.fad.fa-acorn:after, .fa-duotone.fa-acorn:after {
  content: "\f6ae\fe02"; }

.fad.fa-address-book:before, .fa-duotone.fa-address-book:before {
  content: "\f2b9\fe01"; }

.fad.fa-address-book:after, .fa-duotone.fa-address-book:after {
  content: "\f2b9\fe02"; }

.fad.fa-contact-book:before, .fa-duotone.fa-contact-book:before {
  content: "\f2b9\fe01"; }

.fad.fa-contact-book:after, .fa-duotone.fa-contact-book:after {
  content: "\f2b9\fe02"; }

.fad.fa-address-card:before, .fa-duotone.fa-address-card:before {
  content: "\f2bb\fe01"; }

.fad.fa-address-card:after, .fa-duotone.fa-address-card:after {
  content: "\f2bb\fe02"; }

.fad.fa-contact-card:before, .fa-duotone.fa-contact-card:before {
  content: "\f2bb\fe01"; }

.fad.fa-contact-card:after, .fa-duotone.fa-contact-card:after {
  content: "\f2bb\fe02"; }

.fad.fa-vcard:before, .fa-duotone.fa-vcard:before {
  content: "\f2bb\fe01"; }

.fad.fa-vcard:after, .fa-duotone.fa-vcard:after {
  content: "\f2bb\fe02"; }

.fad.fa-air-conditioner:before, .fa-duotone.fa-air-conditioner:before {
  content: "\f8f4\fe01"; }

.fad.fa-air-conditioner:after, .fa-duotone.fa-air-conditioner:after {
  content: "\f8f4\fe02"; }

.fad.fa-airplay:before, .fa-duotone.fa-airplay:before {
  content: "\e089\fe01"; }

.fad.fa-airplay:after, .fa-duotone.fa-airplay:after {
  content: "\e089\fe02"; }

.fad.fa-alarm-clock:before, .fa-duotone.fa-alarm-clock:before {
  content: "\23f0\fe01"; }

.fad.fa-alarm-clock:after, .fa-duotone.fa-alarm-clock:after {
  content: "\23f0\fe02"; }

.fad.fa-alarm-exclamation:before, .fa-duotone.fa-alarm-exclamation:before {
  content: "\f843\fe01"; }

.fad.fa-alarm-exclamation:after, .fa-duotone.fa-alarm-exclamation:after {
  content: "\f843\fe02"; }

.fad.fa-alarm-plus:before, .fa-duotone.fa-alarm-plus:before {
  content: "\f844\fe01"; }

.fad.fa-alarm-plus:after, .fa-duotone.fa-alarm-plus:after {
  content: "\f844\fe02"; }

.fad.fa-alarm-snooze:before, .fa-duotone.fa-alarm-snooze:before {
  content: "\f845\fe01"; }

.fad.fa-alarm-snooze:after, .fa-duotone.fa-alarm-snooze:after {
  content: "\f845\fe02"; }

.fad.fa-album:before, .fa-duotone.fa-album:before {
  content: "\f89f"; }

.fad.fa-album:after, .fa-duotone.fa-album:after {
  content: "\10f89f"; }

.fad.fa-album-collection:before, .fa-duotone.fa-album-collection:before {
  content: "\f8a0\fe01"; }

.fad.fa-album-collection:after, .fa-duotone.fa-album-collection:after {
  content: "\f8a0\fe02"; }

.fad.fa-alicorn:before, .fa-duotone.fa-alicorn:before {
  content: "\f6b0\fe01"; }

.fad.fa-alicorn:after, .fa-duotone.fa-alicorn:after {
  content: "\f6b0\fe02"; }

.fad.fa-alien:before, .fa-duotone.fa-alien:before {
  content: "\f8f5"; }

.fad.fa-alien:after, .fa-duotone.fa-alien:after {
  content: "\10f8f5"; }

.fad.fa-alien-8bit:before, .fa-duotone.fa-alien-8bit:before {
  content: "\f8f6"; }

.fad.fa-alien-8bit:after, .fa-duotone.fa-alien-8bit:after {
  content: "\10f8f6"; }

.fad.fa-alien-monster:before, .fa-duotone.fa-alien-monster:before {
  content: "\f8f6"; }

.fad.fa-alien-monster:after, .fa-duotone.fa-alien-monster:after {
  content: "\10f8f6"; }

.fad.fa-align-center:before, .fa-duotone.fa-align-center:before {
  content: "\f037\fe01"; }

.fad.fa-align-center:after, .fa-duotone.fa-align-center:after {
  content: "\f037\fe02"; }

.fad.fa-align-justify:before, .fa-duotone.fa-align-justify:before {
  content: "\f039\fe01"; }

.fad.fa-align-justify:after, .fa-duotone.fa-align-justify:after {
  content: "\f039\fe02"; }

.fad.fa-align-left:before, .fa-duotone.fa-align-left:before {
  content: "\f036\fe01"; }

.fad.fa-align-left:after, .fa-duotone.fa-align-left:after {
  content: "\f036\fe02"; }

.fad.fa-align-right:before, .fa-duotone.fa-align-right:before {
  content: "\f038\fe01"; }

.fad.fa-align-right:after, .fa-duotone.fa-align-right:after {
  content: "\f038\fe02"; }

.fad.fa-align-slash:before, .fa-duotone.fa-align-slash:before {
  content: "\f846\fe01"; }

.fad.fa-align-slash:after, .fa-duotone.fa-align-slash:after {
  content: "\f846\fe02"; }

.fad.fa-alt:before, .fa-duotone.fa-alt:before {
  content: "\e08a\fe01"; }

.fad.fa-alt:after, .fa-duotone.fa-alt:after {
  content: "\e08a\fe02"; }

.fad.fa-amp-guitar:before, .fa-duotone.fa-amp-guitar:before {
  content: "\f8a1\fe01"; }

.fad.fa-amp-guitar:after, .fa-duotone.fa-amp-guitar:after {
  content: "\f8a1\fe02"; }

.fad.fa-ampersand:before, .fa-duotone.fa-ampersand:before {
  content: "\26\fe01"; }

.fad.fa-ampersand:after, .fa-duotone.fa-ampersand:after {
  content: "\26\fe02"; }

.fad.fa-anchor:before, .fa-duotone.fa-anchor:before {
  content: "\2693\fe01"; }

.fad.fa-anchor:after, .fa-duotone.fa-anchor:after {
  content: "\2693\fe02"; }

.fad.fa-angel:before, .fa-duotone.fa-angel:before {
  content: "\f779\fe01"; }

.fad.fa-angel:after, .fa-duotone.fa-angel:after {
  content: "\f779\fe02"; }

.fad.fa-angle:before, .fa-duotone.fa-angle:before {
  content: "\e08c\fe01"; }

.fad.fa-angle:after, .fa-duotone.fa-angle:after {
  content: "\e08c\fe02"; }

.fad.fa-angle-90:before, .fa-duotone.fa-angle-90:before {
  content: "\e08d\fe01"; }

.fad.fa-angle-90:after, .fa-duotone.fa-angle-90:after {
  content: "\e08d\fe02"; }

.fad.fa-angle-down:before, .fa-duotone.fa-angle-down:before {
  content: "\2304\fe01"; }

.fad.fa-angle-down:after, .fa-duotone.fa-angle-down:after {
  content: "\2304\fe02"; }

.fad.fa-angle-left:before, .fa-duotone.fa-angle-left:before {
  content: "\2039\fe01"; }

.fad.fa-angle-left:after, .fa-duotone.fa-angle-left:after {
  content: "\2039\fe02"; }

.fad.fa-angle-right:before, .fa-duotone.fa-angle-right:before {
  content: "\203a\fe01"; }

.fad.fa-angle-right:after, .fa-duotone.fa-angle-right:after {
  content: "\203a\fe02"; }

.fad.fa-angle-up:before, .fa-duotone.fa-angle-up:before {
  content: "\2303\fe01"; }

.fad.fa-angle-up:after, .fa-duotone.fa-angle-up:after {
  content: "\2303\fe02"; }

.fad.fa-angles-down:before, .fa-duotone.fa-angles-down:before {
  content: "\f103\fe01"; }

.fad.fa-angles-down:after, .fa-duotone.fa-angles-down:after {
  content: "\f103\fe02"; }

.fad.fa-angle-double-down:before, .fa-duotone.fa-angle-double-down:before {
  content: "\f103\fe01"; }

.fad.fa-angle-double-down:after, .fa-duotone.fa-angle-double-down:after {
  content: "\f103\fe02"; }

.fad.fa-angles-left:before, .fa-duotone.fa-angles-left:before {
  content: "\ab\fe01"; }

.fad.fa-angles-left:after, .fa-duotone.fa-angles-left:after {
  content: "\ab\fe02"; }

.fad.fa-angle-double-left:before, .fa-duotone.fa-angle-double-left:before {
  content: "\ab\fe01"; }

.fad.fa-angle-double-left:after, .fa-duotone.fa-angle-double-left:after {
  content: "\ab\fe02"; }

.fad.fa-angles-right:before, .fa-duotone.fa-angles-right:before {
  content: "\bb\fe01"; }

.fad.fa-angles-right:after, .fa-duotone.fa-angles-right:after {
  content: "\bb\fe02"; }

.fad.fa-angle-double-right:before, .fa-duotone.fa-angle-double-right:before {
  content: "\bb\fe01"; }

.fad.fa-angle-double-right:after, .fa-duotone.fa-angle-double-right:after {
  content: "\bb\fe02"; }

.fad.fa-angles-up:before, .fa-duotone.fa-angles-up:before {
  content: "\f102\fe01"; }

.fad.fa-angles-up:after, .fa-duotone.fa-angles-up:after {
  content: "\f102\fe02"; }

.fad.fa-angle-double-up:before, .fa-duotone.fa-angle-double-up:before {
  content: "\f102\fe01"; }

.fad.fa-angle-double-up:after, .fa-duotone.fa-angle-double-up:after {
  content: "\f102\fe02"; }

.fad.fa-ankh:before, .fa-duotone.fa-ankh:before {
  content: "\2625\fe01"; }

.fad.fa-ankh:after, .fa-duotone.fa-ankh:after {
  content: "\2625\fe02"; }

.fad.fa-aperture:before, .fa-duotone.fa-aperture:before {
  content: "\e2df\fe01"; }

.fad.fa-aperture:after, .fa-duotone.fa-aperture:after {
  content: "\e2df\fe02"; }

.fad.fa-apostrophe:before, .fa-duotone.fa-apostrophe:before {
  content: "\27\fe01"; }

.fad.fa-apostrophe:after, .fa-duotone.fa-apostrophe:after {
  content: "\27\fe02"; }

.fad.fa-apple-core:before, .fa-duotone.fa-apple-core:before {
  content: "\e08f\fe01"; }

.fad.fa-apple-core:after, .fa-duotone.fa-apple-core:after {
  content: "\e08f\fe02"; }

.fad.fa-apple-whole:before, .fa-duotone.fa-apple-whole:before {
  content: "\f5d1"; }

.fad.fa-apple-whole:after, .fa-duotone.fa-apple-whole:after {
  content: "\10f5d1"; }

.fad.fa-apple-alt:before, .fa-duotone.fa-apple-alt:before {
  content: "\f5d1"; }

.fad.fa-apple-alt:after, .fa-duotone.fa-apple-alt:after {
  content: "\10f5d1"; }

.fad.fa-archway:before, .fa-duotone.fa-archway:before {
  content: "\f557\fe01"; }

.fad.fa-archway:after, .fa-duotone.fa-archway:after {
  content: "\f557\fe02"; }

.fad.fa-arrow-down:before, .fa-duotone.fa-arrow-down:before {
  content: "\2193\fe01"; }

.fad.fa-arrow-down:after, .fa-duotone.fa-arrow-down:after {
  content: "\2193\fe02"; }

.fad.fa-arrow-down-1-9:before, .fa-duotone.fa-arrow-down-1-9:before {
  content: "\f162\fe01"; }

.fad.fa-arrow-down-1-9:after, .fa-duotone.fa-arrow-down-1-9:after {
  content: "\f162\fe02"; }

.fad.fa-sort-numeric-asc:before, .fa-duotone.fa-sort-numeric-asc:before {
  content: "\f162\fe01"; }

.fad.fa-sort-numeric-asc:after, .fa-duotone.fa-sort-numeric-asc:after {
  content: "\f162\fe02"; }

.fad.fa-sort-numeric-down:before, .fa-duotone.fa-sort-numeric-down:before {
  content: "\f162\fe01"; }

.fad.fa-sort-numeric-down:after, .fa-duotone.fa-sort-numeric-down:after {
  content: "\f162\fe02"; }

.fad.fa-arrow-down-9-1:before, .fa-duotone.fa-arrow-down-9-1:before {
  content: "\f886\fe01"; }

.fad.fa-arrow-down-9-1:after, .fa-duotone.fa-arrow-down-9-1:after {
  content: "\f886\fe02"; }

.fad.fa-sort-numeric-desc:before, .fa-duotone.fa-sort-numeric-desc:before {
  content: "\f886\fe01"; }

.fad.fa-sort-numeric-desc:after, .fa-duotone.fa-sort-numeric-desc:after {
  content: "\f886\fe02"; }

.fad.fa-sort-numeric-down-alt:before, .fa-duotone.fa-sort-numeric-down-alt:before {
  content: "\f886\fe01"; }

.fad.fa-sort-numeric-down-alt:after, .fa-duotone.fa-sort-numeric-down-alt:after {
  content: "\f886\fe02"; }

.fad.fa-arrow-down-a-z:before, .fa-duotone.fa-arrow-down-a-z:before {
  content: "\f15d\fe01"; }

.fad.fa-arrow-down-a-z:after, .fa-duotone.fa-arrow-down-a-z:after {
  content: "\f15d\fe02"; }

.fad.fa-sort-alpha-asc:before, .fa-duotone.fa-sort-alpha-asc:before {
  content: "\f15d\fe01"; }

.fad.fa-sort-alpha-asc:after, .fa-duotone.fa-sort-alpha-asc:after {
  content: "\f15d\fe02"; }

.fad.fa-sort-alpha-down:before, .fa-duotone.fa-sort-alpha-down:before {
  content: "\f15d\fe01"; }

.fad.fa-sort-alpha-down:after, .fa-duotone.fa-sort-alpha-down:after {
  content: "\f15d\fe02"; }

.fad.fa-arrow-down-arrow-up:before, .fa-duotone.fa-arrow-down-arrow-up:before {
  content: "\f883\fe01"; }

.fad.fa-arrow-down-arrow-up:after, .fa-duotone.fa-arrow-down-arrow-up:after {
  content: "\f883\fe02"; }

.fad.fa-sort-alt:before, .fa-duotone.fa-sort-alt:before {
  content: "\f883\fe01"; }

.fad.fa-sort-alt:after, .fa-duotone.fa-sort-alt:after {
  content: "\f883\fe02"; }

.fad.fa-arrow-down-big-small:before, .fa-duotone.fa-arrow-down-big-small:before {
  content: "\f88c\fe01"; }

.fad.fa-arrow-down-big-small:after, .fa-duotone.fa-arrow-down-big-small:after {
  content: "\f88c\fe02"; }

.fad.fa-sort-size-down:before, .fa-duotone.fa-sort-size-down:before {
  content: "\f88c\fe01"; }

.fad.fa-sort-size-down:after, .fa-duotone.fa-sort-size-down:after {
  content: "\f88c\fe02"; }

.fad.fa-arrow-down-from-dotted-line:before, .fa-duotone.fa-arrow-down-from-dotted-line:before {
  content: "\e090\fe01"; }

.fad.fa-arrow-down-from-dotted-line:after, .fa-duotone.fa-arrow-down-from-dotted-line:after {
  content: "\e090\fe02"; }

.fad.fa-arrow-down-from-line:before, .fa-duotone.fa-arrow-down-from-line:before {
  content: "\21a7\fe01"; }

.fad.fa-arrow-down-from-line:after, .fa-duotone.fa-arrow-down-from-line:after {
  content: "\21a7\fe02"; }

.fad.fa-arrow-from-top:before, .fa-duotone.fa-arrow-from-top:before {
  content: "\21a7\fe01"; }

.fad.fa-arrow-from-top:after, .fa-duotone.fa-arrow-from-top:after {
  content: "\21a7\fe02"; }

.fad.fa-arrow-down-left:before, .fa-duotone.fa-arrow-down-left:before {
  content: "\e091\fe01"; }

.fad.fa-arrow-down-left:after, .fa-duotone.fa-arrow-down-left:after {
  content: "\e091\fe02"; }

.fad.fa-arrow-down-left-and-arrow-up-right-to-center:before, .fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center:before {
  content: "\e092\fe01"; }

.fad.fa-arrow-down-left-and-arrow-up-right-to-center:after, .fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center:after {
  content: "\e092\fe02"; }

.fad.fa-arrow-down-long:before, .fa-duotone.fa-arrow-down-long:before {
  content: "\f175\fe01"; }

.fad.fa-arrow-down-long:after, .fa-duotone.fa-arrow-down-long:after {
  content: "\f175\fe02"; }

.fad.fa-long-arrow-down:before, .fa-duotone.fa-long-arrow-down:before {
  content: "\f175\fe01"; }

.fad.fa-long-arrow-down:after, .fa-duotone.fa-long-arrow-down:after {
  content: "\f175\fe02"; }

.fad.fa-arrow-down-right:before, .fa-duotone.fa-arrow-down-right:before {
  content: "\e093\fe01"; }

.fad.fa-arrow-down-right:after, .fa-duotone.fa-arrow-down-right:after {
  content: "\e093\fe02"; }

.fad.fa-arrow-down-short-wide:before, .fa-duotone.fa-arrow-down-short-wide:before {
  content: "\f884\fe01"; }

.fad.fa-arrow-down-short-wide:after, .fa-duotone.fa-arrow-down-short-wide:after {
  content: "\f884\fe02"; }

.fad.fa-sort-amount-desc:before, .fa-duotone.fa-sort-amount-desc:before {
  content: "\f884\fe01"; }

.fad.fa-sort-amount-desc:after, .fa-duotone.fa-sort-amount-desc:after {
  content: "\f884\fe02"; }

.fad.fa-sort-amount-down-alt:before, .fa-duotone.fa-sort-amount-down-alt:before {
  content: "\f884\fe01"; }

.fad.fa-sort-amount-down-alt:after, .fa-duotone.fa-sort-amount-down-alt:after {
  content: "\f884\fe02"; }

.fad.fa-arrow-down-small-big:before, .fa-duotone.fa-arrow-down-small-big:before {
  content: "\f88d\fe01"; }

.fad.fa-arrow-down-small-big:after, .fa-duotone.fa-arrow-down-small-big:after {
  content: "\f88d\fe02"; }

.fad.fa-sort-size-down-alt:before, .fa-duotone.fa-sort-size-down-alt:before {
  content: "\f88d\fe01"; }

.fad.fa-sort-size-down-alt:after, .fa-duotone.fa-sort-size-down-alt:after {
  content: "\f88d\fe02"; }

.fad.fa-arrow-down-square-triangle:before, .fa-duotone.fa-arrow-down-square-triangle:before {
  content: "\f889\fe01"; }

.fad.fa-arrow-down-square-triangle:after, .fa-duotone.fa-arrow-down-square-triangle:after {
  content: "\f889\fe02"; }

.fad.fa-sort-shapes-down-alt:before, .fa-duotone.fa-sort-shapes-down-alt:before {
  content: "\f889\fe01"; }

.fad.fa-sort-shapes-down-alt:after, .fa-duotone.fa-sort-shapes-down-alt:after {
  content: "\f889\fe02"; }

.fad.fa-arrow-down-to-bracket:before, .fa-duotone.fa-arrow-down-to-bracket:before {
  content: "\e094\fe01"; }

.fad.fa-arrow-down-to-bracket:after, .fa-duotone.fa-arrow-down-to-bracket:after {
  content: "\e094\fe02"; }

.fad.fa-arrow-down-to-dotted-line:before, .fa-duotone.fa-arrow-down-to-dotted-line:before {
  content: "\e095\fe01"; }

.fad.fa-arrow-down-to-dotted-line:after, .fa-duotone.fa-arrow-down-to-dotted-line:after {
  content: "\e095\fe02"; }

.fad.fa-arrow-down-to-line:before, .fa-duotone.fa-arrow-down-to-line:before {
  content: "\f33d\fe01"; }

.fad.fa-arrow-down-to-line:after, .fa-duotone.fa-arrow-down-to-line:after {
  content: "\f33d\fe02"; }

.fad.fa-arrow-to-bottom:before, .fa-duotone.fa-arrow-to-bottom:before {
  content: "\f33d\fe01"; }

.fad.fa-arrow-to-bottom:after, .fa-duotone.fa-arrow-to-bottom:after {
  content: "\f33d\fe02"; }

.fad.fa-arrow-down-to-square:before, .fa-duotone.fa-arrow-down-to-square:before {
  content: "\e096\fe01"; }

.fad.fa-arrow-down-to-square:after, .fa-duotone.fa-arrow-down-to-square:after {
  content: "\e096\fe02"; }

.fad.fa-arrow-down-triangle-square:before, .fa-duotone.fa-arrow-down-triangle-square:before {
  content: "\f888\fe01"; }

.fad.fa-arrow-down-triangle-square:after, .fa-duotone.fa-arrow-down-triangle-square:after {
  content: "\f888\fe02"; }

.fad.fa-sort-shapes-down:before, .fa-duotone.fa-sort-shapes-down:before {
  content: "\f888\fe01"; }

.fad.fa-sort-shapes-down:after, .fa-duotone.fa-sort-shapes-down:after {
  content: "\f888\fe02"; }

.fad.fa-arrow-down-wide-short:before, .fa-duotone.fa-arrow-down-wide-short:before {
  content: "\f160\fe01"; }

.fad.fa-arrow-down-wide-short:after, .fa-duotone.fa-arrow-down-wide-short:after {
  content: "\f160\fe02"; }

.fad.fa-sort-amount-asc:before, .fa-duotone.fa-sort-amount-asc:before {
  content: "\f160\fe01"; }

.fad.fa-sort-amount-asc:after, .fa-duotone.fa-sort-amount-asc:after {
  content: "\f160\fe02"; }

.fad.fa-sort-amount-down:before, .fa-duotone.fa-sort-amount-down:before {
  content: "\f160\fe01"; }

.fad.fa-sort-amount-down:after, .fa-duotone.fa-sort-amount-down:after {
  content: "\f160\fe02"; }

.fad.fa-arrow-down-z-a:before, .fa-duotone.fa-arrow-down-z-a:before {
  content: "\f881\fe01"; }

.fad.fa-arrow-down-z-a:after, .fa-duotone.fa-arrow-down-z-a:after {
  content: "\f881\fe02"; }

.fad.fa-sort-alpha-desc:before, .fa-duotone.fa-sort-alpha-desc:before {
  content: "\f881\fe01"; }

.fad.fa-sort-alpha-desc:after, .fa-duotone.fa-sort-alpha-desc:after {
  content: "\f881\fe02"; }

.fad.fa-sort-alpha-down-alt:before, .fa-duotone.fa-sort-alpha-down-alt:before {
  content: "\f881\fe01"; }

.fad.fa-sort-alpha-down-alt:after, .fa-duotone.fa-sort-alpha-down-alt:after {
  content: "\f881\fe02"; }

.fad.fa-arrow-left:before, .fa-duotone.fa-arrow-left:before {
  content: "\2190\fe01"; }

.fad.fa-arrow-left:after, .fa-duotone.fa-arrow-left:after {
  content: "\2190\fe02"; }

.fad.fa-arrow-left-from-line:before, .fa-duotone.fa-arrow-left-from-line:before {
  content: "\21a4\fe01"; }

.fad.fa-arrow-left-from-line:after, .fa-duotone.fa-arrow-left-from-line:after {
  content: "\21a4\fe02"; }

.fad.fa-arrow-from-right:before, .fa-duotone.fa-arrow-from-right:before {
  content: "\21a4\fe01"; }

.fad.fa-arrow-from-right:after, .fa-duotone.fa-arrow-from-right:after {
  content: "\21a4\fe02"; }

.fad.fa-arrow-left-long:before, .fa-duotone.fa-arrow-left-long:before {
  content: "\f177\fe01"; }

.fad.fa-arrow-left-long:after, .fa-duotone.fa-arrow-left-long:after {
  content: "\f177\fe02"; }

.fad.fa-long-arrow-left:before, .fa-duotone.fa-long-arrow-left:before {
  content: "\f177\fe01"; }

.fad.fa-long-arrow-left:after, .fa-duotone.fa-long-arrow-left:after {
  content: "\f177\fe02"; }

.fad.fa-arrow-left-to-line:before, .fa-duotone.fa-arrow-left-to-line:before {
  content: "\21e4\fe01"; }

.fad.fa-arrow-left-to-line:after, .fa-duotone.fa-arrow-left-to-line:after {
  content: "\21e4\fe02"; }

.fad.fa-arrow-to-left:before, .fa-duotone.fa-arrow-to-left:before {
  content: "\21e4\fe01"; }

.fad.fa-arrow-to-left:after, .fa-duotone.fa-arrow-to-left:after {
  content: "\21e4\fe02"; }

.fad.fa-arrow-pointer:before, .fa-duotone.fa-arrow-pointer:before {
  content: "\f245\fe01"; }

.fad.fa-arrow-pointer:after, .fa-duotone.fa-arrow-pointer:after {
  content: "\f245\fe02"; }

.fad.fa-mouse-pointer:before, .fa-duotone.fa-mouse-pointer:before {
  content: "\f245\fe01"; }

.fad.fa-mouse-pointer:after, .fa-duotone.fa-mouse-pointer:after {
  content: "\f245\fe02"; }

.fad.fa-arrow-right:before, .fa-duotone.fa-arrow-right:before {
  content: "\2192\fe01"; }

.fad.fa-arrow-right:after, .fa-duotone.fa-arrow-right:after {
  content: "\2192\fe02"; }

.fad.fa-arrow-right-arrow-left:before, .fa-duotone.fa-arrow-right-arrow-left:before {
  content: "\21c4\fe01"; }

.fad.fa-arrow-right-arrow-left:after, .fa-duotone.fa-arrow-right-arrow-left:after {
  content: "\21c4\fe02"; }

.fad.fa-exchange:before, .fa-duotone.fa-exchange:before {
  content: "\21c4\fe01"; }

.fad.fa-exchange:after, .fa-duotone.fa-exchange:after {
  content: "\21c4\fe02"; }

.fad.fa-arrow-right-from-bracket:before, .fa-duotone.fa-arrow-right-from-bracket:before {
  content: "\f08b\fe01"; }

.fad.fa-arrow-right-from-bracket:after, .fa-duotone.fa-arrow-right-from-bracket:after {
  content: "\f08b\fe02"; }

.fad.fa-sign-out:before, .fa-duotone.fa-sign-out:before {
  content: "\f08b\fe01"; }

.fad.fa-sign-out:after, .fa-duotone.fa-sign-out:after {
  content: "\f08b\fe02"; }

.fad.fa-arrow-right-from-line:before, .fa-duotone.fa-arrow-right-from-line:before {
  content: "\21a6\fe01"; }

.fad.fa-arrow-right-from-line:after, .fa-duotone.fa-arrow-right-from-line:after {
  content: "\21a6\fe02"; }

.fad.fa-arrow-from-left:before, .fa-duotone.fa-arrow-from-left:before {
  content: "\21a6\fe01"; }

.fad.fa-arrow-from-left:after, .fa-duotone.fa-arrow-from-left:after {
  content: "\21a6\fe02"; }

.fad.fa-arrow-right-long:before, .fa-duotone.fa-arrow-right-long:before {
  content: "\f178\fe01"; }

.fad.fa-arrow-right-long:after, .fa-duotone.fa-arrow-right-long:after {
  content: "\f178\fe02"; }

.fad.fa-long-arrow-right:before, .fa-duotone.fa-long-arrow-right:before {
  content: "\f178\fe01"; }

.fad.fa-long-arrow-right:after, .fa-duotone.fa-long-arrow-right:after {
  content: "\f178\fe02"; }

.fad.fa-arrow-right-to-bracket:before, .fa-duotone.fa-arrow-right-to-bracket:before {
  content: "\f090\fe01"; }

.fad.fa-arrow-right-to-bracket:after, .fa-duotone.fa-arrow-right-to-bracket:after {
  content: "\f090\fe02"; }

.fad.fa-sign-in:before, .fa-duotone.fa-sign-in:before {
  content: "\f090\fe01"; }

.fad.fa-sign-in:after, .fa-duotone.fa-sign-in:after {
  content: "\f090\fe02"; }

.fad.fa-arrow-right-to-line:before, .fa-duotone.fa-arrow-right-to-line:before {
  content: "\21e5\fe01"; }

.fad.fa-arrow-right-to-line:after, .fa-duotone.fa-arrow-right-to-line:after {
  content: "\21e5\fe02"; }

.fad.fa-arrow-to-right:before, .fa-duotone.fa-arrow-to-right:before {
  content: "\21e5\fe01"; }

.fad.fa-arrow-to-right:after, .fa-duotone.fa-arrow-to-right:after {
  content: "\21e5\fe02"; }

.fad.fa-arrow-rotate-left:before, .fa-duotone.fa-arrow-rotate-left:before {
  content: "\21ba\fe01"; }

.fad.fa-arrow-rotate-left:after, .fa-duotone.fa-arrow-rotate-left:after {
  content: "\21ba\fe02"; }

.fad.fa-arrow-left-rotate:before, .fa-duotone.fa-arrow-left-rotate:before {
  content: "\21ba\fe01"; }

.fad.fa-arrow-left-rotate:after, .fa-duotone.fa-arrow-left-rotate:after {
  content: "\21ba\fe02"; }

.fad.fa-arrow-rotate-back:before, .fa-duotone.fa-arrow-rotate-back:before {
  content: "\21ba\fe01"; }

.fad.fa-arrow-rotate-back:after, .fa-duotone.fa-arrow-rotate-back:after {
  content: "\21ba\fe02"; }

.fad.fa-arrow-rotate-backward:before, .fa-duotone.fa-arrow-rotate-backward:before {
  content: "\21ba\fe01"; }

.fad.fa-arrow-rotate-backward:after, .fa-duotone.fa-arrow-rotate-backward:after {
  content: "\21ba\fe02"; }

.fad.fa-undo:before, .fa-duotone.fa-undo:before {
  content: "\21ba\fe01"; }

.fad.fa-undo:after, .fa-duotone.fa-undo:after {
  content: "\21ba\fe02"; }

.fad.fa-arrow-rotate-right:before, .fa-duotone.fa-arrow-rotate-right:before {
  content: "\21bb\fe01"; }

.fad.fa-arrow-rotate-right:after, .fa-duotone.fa-arrow-rotate-right:after {
  content: "\21bb\fe02"; }

.fad.fa-arrow-right-rotate:before, .fa-duotone.fa-arrow-right-rotate:before {
  content: "\21bb\fe01"; }

.fad.fa-arrow-right-rotate:after, .fa-duotone.fa-arrow-right-rotate:after {
  content: "\21bb\fe02"; }

.fad.fa-arrow-rotate-forward:before, .fa-duotone.fa-arrow-rotate-forward:before {
  content: "\21bb\fe01"; }

.fad.fa-arrow-rotate-forward:after, .fa-duotone.fa-arrow-rotate-forward:after {
  content: "\21bb\fe02"; }

.fad.fa-redo:before, .fa-duotone.fa-redo:before {
  content: "\21bb\fe01"; }

.fad.fa-redo:after, .fa-duotone.fa-redo:after {
  content: "\21bb\fe02"; }

.fad.fa-arrow-trend-down:before, .fa-duotone.fa-arrow-trend-down:before {
  content: "\e097\fe01"; }

.fad.fa-arrow-trend-down:after, .fa-duotone.fa-arrow-trend-down:after {
  content: "\e097\fe02"; }

.fad.fa-arrow-trend-up:before, .fa-duotone.fa-arrow-trend-up:before {
  content: "\e098\fe01"; }

.fad.fa-arrow-trend-up:after, .fa-duotone.fa-arrow-trend-up:after {
  content: "\e098\fe02"; }

.fad.fa-arrow-turn-down:before, .fa-duotone.fa-arrow-turn-down:before {
  content: "\f149\fe01"; }

.fad.fa-arrow-turn-down:after, .fa-duotone.fa-arrow-turn-down:after {
  content: "\f149\fe02"; }

.fad.fa-level-down:before, .fa-duotone.fa-level-down:before {
  content: "\f149\fe01"; }

.fad.fa-level-down:after, .fa-duotone.fa-level-down:after {
  content: "\f149\fe02"; }

.fad.fa-arrow-turn-down-left:before, .fa-duotone.fa-arrow-turn-down-left:before {
  content: "\e2e1\fe01"; }

.fad.fa-arrow-turn-down-left:after, .fa-duotone.fa-arrow-turn-down-left:after {
  content: "\e2e1\fe02"; }

.fad.fa-arrow-turn-up:before, .fa-duotone.fa-arrow-turn-up:before {
  content: "\f148\fe01"; }

.fad.fa-arrow-turn-up:after, .fa-duotone.fa-arrow-turn-up:after {
  content: "\f148\fe02"; }

.fad.fa-level-up:before, .fa-duotone.fa-level-up:before {
  content: "\f148\fe01"; }

.fad.fa-level-up:after, .fa-duotone.fa-level-up:after {
  content: "\f148\fe02"; }

.fad.fa-arrow-up:before, .fa-duotone.fa-arrow-up:before {
  content: "\2191\fe01"; }

.fad.fa-arrow-up:after, .fa-duotone.fa-arrow-up:after {
  content: "\2191\fe02"; }

.fad.fa-arrow-up-1-9:before, .fa-duotone.fa-arrow-up-1-9:before {
  content: "\f163\fe01"; }

.fad.fa-arrow-up-1-9:after, .fa-duotone.fa-arrow-up-1-9:after {
  content: "\f163\fe02"; }

.fad.fa-sort-numeric-up:before, .fa-duotone.fa-sort-numeric-up:before {
  content: "\f163\fe01"; }

.fad.fa-sort-numeric-up:after, .fa-duotone.fa-sort-numeric-up:after {
  content: "\f163\fe02"; }

.fad.fa-arrow-up-9-1:before, .fa-duotone.fa-arrow-up-9-1:before {
  content: "\f887\fe01"; }

.fad.fa-arrow-up-9-1:after, .fa-duotone.fa-arrow-up-9-1:after {
  content: "\f887\fe02"; }

.fad.fa-sort-numeric-up-alt:before, .fa-duotone.fa-sort-numeric-up-alt:before {
  content: "\f887\fe01"; }

.fad.fa-sort-numeric-up-alt:after, .fa-duotone.fa-sort-numeric-up-alt:after {
  content: "\f887\fe02"; }

.fad.fa-arrow-up-a-z:before, .fa-duotone.fa-arrow-up-a-z:before {
  content: "\f15e\fe01"; }

.fad.fa-arrow-up-a-z:after, .fa-duotone.fa-arrow-up-a-z:after {
  content: "\f15e\fe02"; }

.fad.fa-sort-alpha-up:before, .fa-duotone.fa-sort-alpha-up:before {
  content: "\f15e\fe01"; }

.fad.fa-sort-alpha-up:after, .fa-duotone.fa-sort-alpha-up:after {
  content: "\f15e\fe02"; }

.fad.fa-arrow-up-arrow-down:before, .fa-duotone.fa-arrow-up-arrow-down:before {
  content: "\e099\fe01"; }

.fad.fa-arrow-up-arrow-down:after, .fa-duotone.fa-arrow-up-arrow-down:after {
  content: "\e099\fe02"; }

.fad.fa-sort-up-down:before, .fa-duotone.fa-sort-up-down:before {
  content: "\e099\fe01"; }

.fad.fa-sort-up-down:after, .fa-duotone.fa-sort-up-down:after {
  content: "\e099\fe02"; }

.fad.fa-arrow-up-big-small:before, .fa-duotone.fa-arrow-up-big-small:before {
  content: "\f88e\fe01"; }

.fad.fa-arrow-up-big-small:after, .fa-duotone.fa-arrow-up-big-small:after {
  content: "\f88e\fe02"; }

.fad.fa-sort-size-up:before, .fa-duotone.fa-sort-size-up:before {
  content: "\f88e\fe01"; }

.fad.fa-sort-size-up:after, .fa-duotone.fa-sort-size-up:after {
  content: "\f88e\fe02"; }

.fad.fa-arrow-up-from-bracket:before, .fa-duotone.fa-arrow-up-from-bracket:before {
  content: "\e09a\fe01"; }

.fad.fa-arrow-up-from-bracket:after, .fa-duotone.fa-arrow-up-from-bracket:after {
  content: "\e09a\fe02"; }

.fad.fa-arrow-up-from-dotted-line:before, .fa-duotone.fa-arrow-up-from-dotted-line:before {
  content: "\e09b\fe01"; }

.fad.fa-arrow-up-from-dotted-line:after, .fa-duotone.fa-arrow-up-from-dotted-line:after {
  content: "\e09b\fe02"; }

.fad.fa-arrow-up-from-line:before, .fa-duotone.fa-arrow-up-from-line:before {
  content: "\21a5\fe01"; }

.fad.fa-arrow-up-from-line:after, .fa-duotone.fa-arrow-up-from-line:after {
  content: "\21a5\fe02"; }

.fad.fa-arrow-from-bottom:before, .fa-duotone.fa-arrow-from-bottom:before {
  content: "\21a5\fe01"; }

.fad.fa-arrow-from-bottom:after, .fa-duotone.fa-arrow-from-bottom:after {
  content: "\21a5\fe02"; }

.fad.fa-arrow-up-from-square:before, .fa-duotone.fa-arrow-up-from-square:before {
  content: "\e09c\fe01"; }

.fad.fa-arrow-up-from-square:after, .fa-duotone.fa-arrow-up-from-square:after {
  content: "\e09c\fe02"; }

.fad.fa-arrow-up-left:before, .fa-duotone.fa-arrow-up-left:before {
  content: "\e09d\fe01"; }

.fad.fa-arrow-up-left:after, .fa-duotone.fa-arrow-up-left:after {
  content: "\e09d\fe02"; }

.fad.fa-arrow-up-left-from-circle:before, .fa-duotone.fa-arrow-up-left-from-circle:before {
  content: "\e09e\fe01"; }

.fad.fa-arrow-up-left-from-circle:after, .fa-duotone.fa-arrow-up-left-from-circle:after {
  content: "\e09e\fe02"; }

.fad.fa-arrow-up-long:before, .fa-duotone.fa-arrow-up-long:before {
  content: "\f176\fe01"; }

.fad.fa-arrow-up-long:after, .fa-duotone.fa-arrow-up-long:after {
  content: "\f176\fe02"; }

.fad.fa-long-arrow-up:before, .fa-duotone.fa-long-arrow-up:before {
  content: "\f176\fe01"; }

.fad.fa-long-arrow-up:after, .fa-duotone.fa-long-arrow-up:after {
  content: "\f176\fe02"; }

.fad.fa-arrow-up-right:before, .fa-duotone.fa-arrow-up-right:before {
  content: "\e09f\fe01"; }

.fad.fa-arrow-up-right:after, .fa-duotone.fa-arrow-up-right:after {
  content: "\e09f\fe02"; }

.fad.fa-arrow-up-right-and-arrow-down-left-from-center:before, .fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "\e0a0\fe01"; }

.fad.fa-arrow-up-right-and-arrow-down-left-from-center:after, .fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center:after {
  content: "\e0a0\fe02"; }

.fad.fa-arrow-up-right-from-square:before, .fa-duotone.fa-arrow-up-right-from-square:before {
  content: "\f08e\fe01"; }

.fad.fa-arrow-up-right-from-square:after, .fa-duotone.fa-arrow-up-right-from-square:after {
  content: "\f08e\fe02"; }

.fad.fa-external-link:before, .fa-duotone.fa-external-link:before {
  content: "\f08e\fe01"; }

.fad.fa-external-link:after, .fa-duotone.fa-external-link:after {
  content: "\f08e\fe02"; }

.fad.fa-arrow-up-short-wide:before, .fa-duotone.fa-arrow-up-short-wide:before {
  content: "\f885\fe01"; }

.fad.fa-arrow-up-short-wide:after, .fa-duotone.fa-arrow-up-short-wide:after {
  content: "\f885\fe02"; }

.fad.fa-sort-amount-up-alt:before, .fa-duotone.fa-sort-amount-up-alt:before {
  content: "\f885\fe01"; }

.fad.fa-sort-amount-up-alt:after, .fa-duotone.fa-sort-amount-up-alt:after {
  content: "\f885\fe02"; }

.fad.fa-arrow-up-small-big:before, .fa-duotone.fa-arrow-up-small-big:before {
  content: "\f88f\fe01"; }

.fad.fa-arrow-up-small-big:after, .fa-duotone.fa-arrow-up-small-big:after {
  content: "\f88f\fe02"; }

.fad.fa-sort-size-up-alt:before, .fa-duotone.fa-sort-size-up-alt:before {
  content: "\f88f\fe01"; }

.fad.fa-sort-size-up-alt:after, .fa-duotone.fa-sort-size-up-alt:after {
  content: "\f88f\fe02"; }

.fad.fa-arrow-up-square-triangle:before, .fa-duotone.fa-arrow-up-square-triangle:before {
  content: "\f88b\fe01"; }

.fad.fa-arrow-up-square-triangle:after, .fa-duotone.fa-arrow-up-square-triangle:after {
  content: "\f88b\fe02"; }

.fad.fa-sort-shapes-up-alt:before, .fa-duotone.fa-sort-shapes-up-alt:before {
  content: "\f88b\fe01"; }

.fad.fa-sort-shapes-up-alt:after, .fa-duotone.fa-sort-shapes-up-alt:after {
  content: "\f88b\fe02"; }

.fad.fa-arrow-up-to-dotted-line:before, .fa-duotone.fa-arrow-up-to-dotted-line:before {
  content: "\e0a1\fe01"; }

.fad.fa-arrow-up-to-dotted-line:after, .fa-duotone.fa-arrow-up-to-dotted-line:after {
  content: "\e0a1\fe02"; }

.fad.fa-arrow-up-to-line:before, .fa-duotone.fa-arrow-up-to-line:before {
  content: "\f341\fe01"; }

.fad.fa-arrow-up-to-line:after, .fa-duotone.fa-arrow-up-to-line:after {
  content: "\f341\fe02"; }

.fad.fa-arrow-to-top:before, .fa-duotone.fa-arrow-to-top:before {
  content: "\f341\fe01"; }

.fad.fa-arrow-to-top:after, .fa-duotone.fa-arrow-to-top:after {
  content: "\f341\fe02"; }

.fad.fa-arrow-up-triangle-square:before, .fa-duotone.fa-arrow-up-triangle-square:before {
  content: "\f88a\fe01"; }

.fad.fa-arrow-up-triangle-square:after, .fa-duotone.fa-arrow-up-triangle-square:after {
  content: "\f88a\fe02"; }

.fad.fa-sort-shapes-up:before, .fa-duotone.fa-sort-shapes-up:before {
  content: "\f88a\fe01"; }

.fad.fa-sort-shapes-up:after, .fa-duotone.fa-sort-shapes-up:after {
  content: "\f88a\fe02"; }

.fad.fa-arrow-up-wide-short:before, .fa-duotone.fa-arrow-up-wide-short:before {
  content: "\f161\fe01"; }

.fad.fa-arrow-up-wide-short:after, .fa-duotone.fa-arrow-up-wide-short:after {
  content: "\f161\fe02"; }

.fad.fa-sort-amount-up:before, .fa-duotone.fa-sort-amount-up:before {
  content: "\f161\fe01"; }

.fad.fa-sort-amount-up:after, .fa-duotone.fa-sort-amount-up:after {
  content: "\f161\fe02"; }

.fad.fa-arrow-up-z-a:before, .fa-duotone.fa-arrow-up-z-a:before {
  content: "\f882\fe01"; }

.fad.fa-arrow-up-z-a:after, .fa-duotone.fa-arrow-up-z-a:after {
  content: "\f882\fe02"; }

.fad.fa-sort-alpha-up-alt:before, .fa-duotone.fa-sort-alpha-up-alt:before {
  content: "\f882\fe01"; }

.fad.fa-sort-alpha-up-alt:after, .fa-duotone.fa-sort-alpha-up-alt:after {
  content: "\f882\fe02"; }

.fad.fa-arrows-cross:before, .fa-duotone.fa-arrows-cross:before {
  content: "\e0a2\fe01"; }

.fad.fa-arrows-cross:after, .fa-duotone.fa-arrows-cross:after {
  content: "\e0a2\fe02"; }

.fad.fa-arrows-from-dotted-line:before, .fa-duotone.fa-arrows-from-dotted-line:before {
  content: "\e0a3\fe01"; }

.fad.fa-arrows-from-dotted-line:after, .fa-duotone.fa-arrows-from-dotted-line:after {
  content: "\e0a3\fe02"; }

.fad.fa-arrows-from-line:before, .fa-duotone.fa-arrows-from-line:before {
  content: "\e0a4\fe01"; }

.fad.fa-arrows-from-line:after, .fa-duotone.fa-arrows-from-line:after {
  content: "\e0a4\fe02"; }

.fad.fa-arrows-left-right:before, .fa-duotone.fa-arrows-left-right:before {
  content: "\f07e\fe01"; }

.fad.fa-arrows-left-right:after, .fa-duotone.fa-arrows-left-right:after {
  content: "\f07e\fe02"; }

.fad.fa-arrows-h:before, .fa-duotone.fa-arrows-h:before {
  content: "\f07e\fe01"; }

.fad.fa-arrows-h:after, .fa-duotone.fa-arrows-h:after {
  content: "\f07e\fe02"; }

.fad.fa-arrows-maximize:before, .fa-duotone.fa-arrows-maximize:before {
  content: "\f31d\fe01"; }

.fad.fa-arrows-maximize:after, .fa-duotone.fa-arrows-maximize:after {
  content: "\f31d\fe02"; }

.fad.fa-expand-arrows:before, .fa-duotone.fa-expand-arrows:before {
  content: "\f31d\fe01"; }

.fad.fa-expand-arrows:after, .fa-duotone.fa-expand-arrows:after {
  content: "\f31d\fe02"; }

.fad.fa-arrows-minimize:before, .fa-duotone.fa-arrows-minimize:before {
  content: "\e0a5\fe01"; }

.fad.fa-arrows-minimize:after, .fa-duotone.fa-arrows-minimize:after {
  content: "\e0a5\fe02"; }

.fad.fa-compress-arrows:before, .fa-duotone.fa-compress-arrows:before {
  content: "\e0a5\fe01"; }

.fad.fa-compress-arrows:after, .fa-duotone.fa-compress-arrows:after {
  content: "\e0a5\fe02"; }

.fad.fa-arrows-repeat:before, .fa-duotone.fa-arrows-repeat:before {
  content: "\f364\fe01"; }

.fad.fa-arrows-repeat:after, .fa-duotone.fa-arrows-repeat:after {
  content: "\f364\fe02"; }

.fad.fa-repeat-alt:before, .fa-duotone.fa-repeat-alt:before {
  content: "\f364\fe01"; }

.fad.fa-repeat-alt:after, .fa-duotone.fa-repeat-alt:after {
  content: "\f364\fe02"; }

.fad.fa-arrows-repeat-1:before, .fa-duotone.fa-arrows-repeat-1:before {
  content: "\f366\fe01"; }

.fad.fa-arrows-repeat-1:after, .fa-duotone.fa-arrows-repeat-1:after {
  content: "\f366\fe02"; }

.fad.fa-repeat-1-alt:before, .fa-duotone.fa-repeat-1-alt:before {
  content: "\f366\fe01"; }

.fad.fa-repeat-1-alt:after, .fa-duotone.fa-repeat-1-alt:after {
  content: "\f366\fe02"; }

.fad.fa-arrows-retweet:before, .fa-duotone.fa-arrows-retweet:before {
  content: "\f361\fe01"; }

.fad.fa-arrows-retweet:after, .fa-duotone.fa-arrows-retweet:after {
  content: "\f361\fe02"; }

.fad.fa-retweet-alt:before, .fa-duotone.fa-retweet-alt:before {
  content: "\f361\fe01"; }

.fad.fa-retweet-alt:after, .fa-duotone.fa-retweet-alt:after {
  content: "\f361\fe02"; }

.fad.fa-arrows-rotate:before, .fa-duotone.fa-arrows-rotate:before {
  content: "\f021"; }

.fad.fa-arrows-rotate:after, .fa-duotone.fa-arrows-rotate:after {
  content: "\10f021"; }

.fad.fa-refresh:before, .fa-duotone.fa-refresh:before {
  content: "\f021"; }

.fad.fa-refresh:after, .fa-duotone.fa-refresh:after {
  content: "\10f021"; }

.fad.fa-sync:before, .fa-duotone.fa-sync:before {
  content: "\f021"; }

.fad.fa-sync:after, .fa-duotone.fa-sync:after {
  content: "\10f021"; }

.fad.fa-arrows-to-dotted-line:before, .fa-duotone.fa-arrows-to-dotted-line:before {
  content: "\e0a6\fe01"; }

.fad.fa-arrows-to-dotted-line:after, .fa-duotone.fa-arrows-to-dotted-line:after {
  content: "\e0a6\fe02"; }

.fad.fa-arrows-to-line:before, .fa-duotone.fa-arrows-to-line:before {
  content: "\e0a7\fe01"; }

.fad.fa-arrows-to-line:after, .fa-duotone.fa-arrows-to-line:after {
  content: "\e0a7\fe02"; }

.fad.fa-arrows-up-down:before, .fa-duotone.fa-arrows-up-down:before {
  content: "\f07d\fe01"; }

.fad.fa-arrows-up-down:after, .fa-duotone.fa-arrows-up-down:after {
  content: "\f07d\fe02"; }

.fad.fa-arrows-v:before, .fa-duotone.fa-arrows-v:before {
  content: "\f07d\fe01"; }

.fad.fa-arrows-v:after, .fa-duotone.fa-arrows-v:after {
  content: "\f07d\fe02"; }

.fad.fa-arrows-up-down-left-right:before, .fa-duotone.fa-arrows-up-down-left-right:before {
  content: "\f047\fe01"; }

.fad.fa-arrows-up-down-left-right:after, .fa-duotone.fa-arrows-up-down-left-right:after {
  content: "\f047\fe02"; }

.fad.fa-arrows:before, .fa-duotone.fa-arrows:before {
  content: "\f047\fe01"; }

.fad.fa-arrows:after, .fa-duotone.fa-arrows:after {
  content: "\f047\fe02"; }

.fad.fa-asterisk:before, .fa-duotone.fa-asterisk:before {
  content: "\2a\fe01"; }

.fad.fa-asterisk:after, .fa-duotone.fa-asterisk:after {
  content: "\2a\fe02"; }

.fad.fa-at:before, .fa-duotone.fa-at:before {
  content: "\40\fe01"; }

.fad.fa-at:after, .fa-duotone.fa-at:after {
  content: "\40\fe02"; }

.fad.fa-atom:before, .fa-duotone.fa-atom:before {
  content: "\269b\fe01"; }

.fad.fa-atom:after, .fa-duotone.fa-atom:after {
  content: "\269b\fe02"; }

.fad.fa-atom-simple:before, .fa-duotone.fa-atom-simple:before {
  content: "\f5d3\fe01"; }

.fad.fa-atom-simple:after, .fa-duotone.fa-atom-simple:after {
  content: "\f5d3\fe02"; }

.fad.fa-atom-alt:before, .fa-duotone.fa-atom-alt:before {
  content: "\f5d3\fe01"; }

.fad.fa-atom-alt:after, .fa-duotone.fa-atom-alt:after {
  content: "\f5d3\fe02"; }

.fad.fa-audio-description:before, .fa-duotone.fa-audio-description:before {
  content: "\f29e\fe01"; }

.fad.fa-audio-description:after, .fa-duotone.fa-audio-description:after {
  content: "\f29e\fe02"; }

.fad.fa-audio-description-slash:before, .fa-duotone.fa-audio-description-slash:before {
  content: "\e0a8\fe01"; }

.fad.fa-audio-description-slash:after, .fa-duotone.fa-audio-description-slash:after {
  content: "\e0a8\fe02"; }

.fad.fa-austral-sign:before, .fa-duotone.fa-austral-sign:before {
  content: "\e0a9\fe01"; }

.fad.fa-austral-sign:after, .fa-duotone.fa-austral-sign:after {
  content: "\e0a9\fe02"; }

.fad.fa-avocado:before, .fa-duotone.fa-avocado:before {
  content: "\e0aa\fe01"; }

.fad.fa-avocado:after, .fa-duotone.fa-avocado:after {
  content: "\e0aa\fe02"; }

.fad.fa-award:before, .fa-duotone.fa-award:before {
  content: "\f559\fe01"; }

.fad.fa-award:after, .fa-duotone.fa-award:after {
  content: "\f559\fe02"; }

.fad.fa-award-simple:before, .fa-duotone.fa-award-simple:before {
  content: "\e0ab\fe01"; }

.fad.fa-award-simple:after, .fa-duotone.fa-award-simple:after {
  content: "\e0ab\fe02"; }

.fad.fa-axe:before, .fa-duotone.fa-axe:before {
  content: "\f6b2"; }

.fad.fa-axe:after, .fa-duotone.fa-axe:after {
  content: "\10f6b2"; }

.fad.fa-axe-battle:before, .fa-duotone.fa-axe-battle:before {
  content: "\f6b3\fe01"; }

.fad.fa-axe-battle:after, .fa-duotone.fa-axe-battle:after {
  content: "\f6b3\fe02"; }

.fad.fa-b:before, .fa-duotone.fa-b:before {
  content: "\42\fe01"; }

.fad.fa-b:after, .fa-duotone.fa-b:after {
  content: "\42\fe02"; }

.fad.fa-baby:before, .fa-duotone.fa-baby:before {
  content: "\f77c\fe01"; }

.fad.fa-baby:after, .fa-duotone.fa-baby:after {
  content: "\f77c\fe02"; }

.fad.fa-baby-carriage:before, .fa-duotone.fa-baby-carriage:before {
  content: "\f77d\fe01"; }

.fad.fa-baby-carriage:after, .fa-duotone.fa-baby-carriage:after {
  content: "\f77d\fe02"; }

.fad.fa-carriage-baby:before, .fa-duotone.fa-carriage-baby:before {
  content: "\f77d\fe01"; }

.fad.fa-carriage-baby:after, .fa-duotone.fa-carriage-baby:after {
  content: "\f77d\fe02"; }

.fad.fa-backpack:before, .fa-duotone.fa-backpack:before {
  content: "\f5d4"; }

.fad.fa-backpack:after, .fa-duotone.fa-backpack:after {
  content: "\10f5d4"; }

.fad.fa-backward:before, .fa-duotone.fa-backward:before {
  content: "\23ea\fe01"; }

.fad.fa-backward:after, .fa-duotone.fa-backward:after {
  content: "\23ea\fe02"; }

.fad.fa-backward-fast:before, .fa-duotone.fa-backward-fast:before {
  content: "\23ee\fe01"; }

.fad.fa-backward-fast:after, .fa-duotone.fa-backward-fast:after {
  content: "\23ee\fe02"; }

.fad.fa-fast-backward:before, .fa-duotone.fa-fast-backward:before {
  content: "\23ee\fe01"; }

.fad.fa-fast-backward:after, .fa-duotone.fa-fast-backward:after {
  content: "\23ee\fe02"; }

.fad.fa-backward-step:before, .fa-duotone.fa-backward-step:before {
  content: "\f048\fe01"; }

.fad.fa-backward-step:after, .fa-duotone.fa-backward-step:after {
  content: "\f048\fe02"; }

.fad.fa-step-backward:before, .fa-duotone.fa-step-backward:before {
  content: "\f048\fe01"; }

.fad.fa-step-backward:after, .fa-duotone.fa-step-backward:after {
  content: "\f048\fe02"; }

.fad.fa-bacon:before, .fa-duotone.fa-bacon:before {
  content: "\f7e5"; }

.fad.fa-bacon:after, .fa-duotone.fa-bacon:after {
  content: "\10f7e5"; }

.fad.fa-bacteria:before, .fa-duotone.fa-bacteria:before {
  content: "\e059\fe01"; }

.fad.fa-bacteria:after, .fa-duotone.fa-bacteria:after {
  content: "\e059\fe02"; }

.fad.fa-bacterium:before, .fa-duotone.fa-bacterium:before {
  content: "\e05a\fe01"; }

.fad.fa-bacterium:after, .fa-duotone.fa-bacterium:after {
  content: "\e05a\fe02"; }

.fad.fa-badge:before, .fa-duotone.fa-badge:before {
  content: "\f335\fe01"; }

.fad.fa-badge:after, .fa-duotone.fa-badge:after {
  content: "\f335\fe02"; }

.fad.fa-badge-check:before, .fa-duotone.fa-badge-check:before {
  content: "\f336\fe01"; }

.fad.fa-badge-check:after, .fa-duotone.fa-badge-check:after {
  content: "\f336\fe02"; }

.fad.fa-badge-dollar:before, .fa-duotone.fa-badge-dollar:before {
  content: "\f645\fe01"; }

.fad.fa-badge-dollar:after, .fa-duotone.fa-badge-dollar:after {
  content: "\f645\fe02"; }

.fad.fa-badge-percent:before, .fa-duotone.fa-badge-percent:before {
  content: "\f646\fe01"; }

.fad.fa-badge-percent:after, .fa-duotone.fa-badge-percent:after {
  content: "\f646\fe02"; }

.fad.fa-badge-sheriff:before, .fa-duotone.fa-badge-sheriff:before {
  content: "\f8a2\fe01"; }

.fad.fa-badge-sheriff:after, .fa-duotone.fa-badge-sheriff:after {
  content: "\f8a2\fe02"; }

.fad.fa-badger-honey:before, .fa-duotone.fa-badger-honey:before {
  content: "\f6b4"; }

.fad.fa-badger-honey:after, .fa-duotone.fa-badger-honey:after {
  content: "\10f6b4"; }

.fad.fa-badminton:before, .fa-duotone.fa-badminton:before {
  content: "\e33a\fe01"; }

.fad.fa-badminton:after, .fa-duotone.fa-badminton:after {
  content: "\e33a\fe02"; }

.fad.fa-bag-shopping:before, .fa-duotone.fa-bag-shopping:before {
  content: "\f290\fe01"; }

.fad.fa-bag-shopping:after, .fa-duotone.fa-bag-shopping:after {
  content: "\f290\fe02"; }

.fad.fa-shopping-bag:before, .fa-duotone.fa-shopping-bag:before {
  content: "\f290\fe01"; }

.fad.fa-shopping-bag:after, .fa-duotone.fa-shopping-bag:after {
  content: "\f290\fe02"; }

.fad.fa-bags-shopping:before, .fa-duotone.fa-bags-shopping:before {
  content: "\f847"; }

.fad.fa-bags-shopping:after, .fa-duotone.fa-bags-shopping:after {
  content: "\10f847"; }

.fad.fa-bahai:before, .fa-duotone.fa-bahai:before {
  content: "\f666\fe01"; }

.fad.fa-bahai:after, .fa-duotone.fa-bahai:after {
  content: "\f666\fe02"; }

.fad.fa-baht-sign:before, .fa-duotone.fa-baht-sign:before {
  content: "\e0ac\fe01"; }

.fad.fa-baht-sign:after, .fa-duotone.fa-baht-sign:after {
  content: "\e0ac\fe02"; }

.fad.fa-ball-pile:before, .fa-duotone.fa-ball-pile:before {
  content: "\f77e\fe01"; }

.fad.fa-ball-pile:after, .fa-duotone.fa-ball-pile:after {
  content: "\f77e\fe02"; }

.fad.fa-balloon:before, .fa-duotone.fa-balloon:before {
  content: "\e2e3\fe01"; }

.fad.fa-balloon:after, .fa-duotone.fa-balloon:after {
  content: "\e2e3\fe02"; }

.fad.fa-balloons:before, .fa-duotone.fa-balloons:before {
  content: "\e2e4\fe01"; }

.fad.fa-balloons:after, .fa-duotone.fa-balloons:after {
  content: "\e2e4\fe02"; }

.fad.fa-ballot:before, .fa-duotone.fa-ballot:before {
  content: "\f732\fe01"; }

.fad.fa-ballot:after, .fa-duotone.fa-ballot:after {
  content: "\f732\fe02"; }

.fad.fa-ballot-check:before, .fa-duotone.fa-ballot-check:before {
  content: "\f733\fe01"; }

.fad.fa-ballot-check:after, .fa-duotone.fa-ballot-check:after {
  content: "\f733\fe02"; }

.fad.fa-ban:before, .fa-duotone.fa-ban:before {
  content: "\f05e"; }

.fad.fa-ban:after, .fa-duotone.fa-ban:after {
  content: "\10f05e"; }

.fad.fa-cancel:before, .fa-duotone.fa-cancel:before {
  content: "\f05e"; }

.fad.fa-cancel:after, .fa-duotone.fa-cancel:after {
  content: "\10f05e"; }

.fad.fa-ban-bug:before, .fa-duotone.fa-ban-bug:before {
  content: "\f7f9\fe01"; }

.fad.fa-ban-bug:after, .fa-duotone.fa-ban-bug:after {
  content: "\f7f9\fe02"; }

.fad.fa-debug:before, .fa-duotone.fa-debug:before {
  content: "\f7f9\fe01"; }

.fad.fa-debug:after, .fa-duotone.fa-debug:after {
  content: "\f7f9\fe02"; }

.fad.fa-ban-parking:before, .fa-duotone.fa-ban-parking:before {
  content: "\f616\fe01"; }

.fad.fa-ban-parking:after, .fa-duotone.fa-ban-parking:after {
  content: "\f616\fe02"; }

.fad.fa-parking-circle-slash:before, .fa-duotone.fa-parking-circle-slash:before {
  content: "\f616\fe01"; }

.fad.fa-parking-circle-slash:after, .fa-duotone.fa-parking-circle-slash:after {
  content: "\f616\fe02"; }

.fad.fa-ban-smoking:before, .fa-duotone.fa-ban-smoking:before {
  content: "\f54d"; }

.fad.fa-ban-smoking:after, .fa-duotone.fa-ban-smoking:after {
  content: "\10f54d"; }

.fad.fa-smoking-ban:before, .fa-duotone.fa-smoking-ban:before {
  content: "\f54d"; }

.fad.fa-smoking-ban:after, .fa-duotone.fa-smoking-ban:after {
  content: "\10f54d"; }

.fad.fa-banana:before, .fa-duotone.fa-banana:before {
  content: "\e2e5\fe01"; }

.fad.fa-banana:after, .fa-duotone.fa-banana:after {
  content: "\e2e5\fe02"; }

.fad.fa-bandage:before, .fa-duotone.fa-bandage:before {
  content: "\f462"; }

.fad.fa-bandage:after, .fa-duotone.fa-bandage:after {
  content: "\10f462"; }

.fad.fa-band-aid:before, .fa-duotone.fa-band-aid:before {
  content: "\f462"; }

.fad.fa-band-aid:after, .fa-duotone.fa-band-aid:after {
  content: "\10f462"; }

.fad.fa-bangladeshi-taka-sign:before, .fa-duotone.fa-bangladeshi-taka-sign:before {
  content: "\e2e6\fe01"; }

.fad.fa-bangladeshi-taka-sign:after, .fa-duotone.fa-bangladeshi-taka-sign:after {
  content: "\e2e6\fe02"; }

.fad.fa-banjo:before, .fa-duotone.fa-banjo:before {
  content: "\f8a3"; }

.fad.fa-banjo:after, .fa-duotone.fa-banjo:after {
  content: "\10f8a3"; }

.fad.fa-bank:before, .fa-duotone.fa-bank:before {
  content: "\f19c\fe01"; }

.fad.fa-bank:after, .fa-duotone.fa-bank:after {
  content: "\f19c\fe02"; }

.fad.fa-institution:before, .fa-duotone.fa-institution:before {
  content: "\f19c\fe01"; }

.fad.fa-institution:after, .fa-duotone.fa-institution:after {
  content: "\f19c\fe02"; }

.fad.fa-university:before, .fa-duotone.fa-university:before {
  content: "\f19c\fe01"; }

.fad.fa-university:after, .fa-duotone.fa-university:after {
  content: "\f19c\fe02"; }

.fad.fa-barcode:before, .fa-duotone.fa-barcode:before {
  content: "\f02a\fe01"; }

.fad.fa-barcode:after, .fa-duotone.fa-barcode:after {
  content: "\f02a\fe02"; }

.fad.fa-barcode-read:before, .fa-duotone.fa-barcode-read:before {
  content: "\f464\fe01"; }

.fad.fa-barcode-read:after, .fa-duotone.fa-barcode-read:after {
  content: "\f464\fe02"; }

.fad.fa-barcode-scan:before, .fa-duotone.fa-barcode-scan:before {
  content: "\f465\fe01"; }

.fad.fa-barcode-scan:after, .fa-duotone.fa-barcode-scan:after {
  content: "\f465\fe02"; }

.fad.fa-bars:before, .fa-duotone.fa-bars:before {
  content: "\f0c9\fe01"; }

.fad.fa-bars:after, .fa-duotone.fa-bars:after {
  content: "\f0c9\fe02"; }

.fad.fa-navicon:before, .fa-duotone.fa-navicon:before {
  content: "\f0c9\fe01"; }

.fad.fa-navicon:after, .fa-duotone.fa-navicon:after {
  content: "\f0c9\fe02"; }

.fad.fa-bars-filter:before, .fa-duotone.fa-bars-filter:before {
  content: "\e0ad\fe01"; }

.fad.fa-bars-filter:after, .fa-duotone.fa-bars-filter:after {
  content: "\e0ad\fe02"; }

.fad.fa-bars-progress:before, .fa-duotone.fa-bars-progress:before {
  content: "\f828\fe01"; }

.fad.fa-bars-progress:after, .fa-duotone.fa-bars-progress:after {
  content: "\f828\fe02"; }

.fad.fa-tasks-alt:before, .fa-duotone.fa-tasks-alt:before {
  content: "\f828\fe01"; }

.fad.fa-tasks-alt:after, .fa-duotone.fa-tasks-alt:after {
  content: "\f828\fe02"; }

.fad.fa-bars-sort:before, .fa-duotone.fa-bars-sort:before {
  content: "\e0ae\fe01"; }

.fad.fa-bars-sort:after, .fa-duotone.fa-bars-sort:after {
  content: "\e0ae\fe02"; }

.fad.fa-bars-staggered:before, .fa-duotone.fa-bars-staggered:before {
  content: "\f550\fe01"; }

.fad.fa-bars-staggered:after, .fa-duotone.fa-bars-staggered:after {
  content: "\f550\fe02"; }

.fad.fa-reorder:before, .fa-duotone.fa-reorder:before {
  content: "\f550\fe01"; }

.fad.fa-reorder:after, .fa-duotone.fa-reorder:after {
  content: "\f550\fe02"; }

.fad.fa-stream:before, .fa-duotone.fa-stream:before {
  content: "\f550\fe01"; }

.fad.fa-stream:after, .fa-duotone.fa-stream:after {
  content: "\f550\fe02"; }

.fad.fa-baseball:before, .fa-duotone.fa-baseball:before {
  content: "\26be\fe01"; }

.fad.fa-baseball:after, .fa-duotone.fa-baseball:after {
  content: "\26be\fe02"; }

.fad.fa-baseball-ball:before, .fa-duotone.fa-baseball-ball:before {
  content: "\26be\fe01"; }

.fad.fa-baseball-ball:after, .fa-duotone.fa-baseball-ball:after {
  content: "\26be\fe02"; }

.fad.fa-baseball-bat-ball:before, .fa-duotone.fa-baseball-bat-ball:before {
  content: "\f432\fe01"; }

.fad.fa-baseball-bat-ball:after, .fa-duotone.fa-baseball-bat-ball:after {
  content: "\f432\fe02"; }

.fad.fa-basket-shopping:before, .fa-duotone.fa-basket-shopping:before {
  content: "\f291\fe01"; }

.fad.fa-basket-shopping:after, .fa-duotone.fa-basket-shopping:after {
  content: "\f291\fe02"; }

.fad.fa-shopping-basket:before, .fa-duotone.fa-shopping-basket:before {
  content: "\f291\fe01"; }

.fad.fa-shopping-basket:after, .fa-duotone.fa-shopping-basket:after {
  content: "\f291\fe02"; }

.fad.fa-basket-shopping-simple:before, .fa-duotone.fa-basket-shopping-simple:before {
  content: "\e0af\fe01"; }

.fad.fa-basket-shopping-simple:after, .fa-duotone.fa-basket-shopping-simple:after {
  content: "\e0af\fe02"; }

.fad.fa-shopping-basket-alt:before, .fa-duotone.fa-shopping-basket-alt:before {
  content: "\e0af\fe01"; }

.fad.fa-shopping-basket-alt:after, .fa-duotone.fa-shopping-basket-alt:after {
  content: "\e0af\fe02"; }

.fad.fa-basketball:before, .fa-duotone.fa-basketball:before {
  content: "\f434"; }

.fad.fa-basketball:after, .fa-duotone.fa-basketball:after {
  content: "\10f434"; }

.fad.fa-basketball-ball:before, .fa-duotone.fa-basketball-ball:before {
  content: "\f434"; }

.fad.fa-basketball-ball:after, .fa-duotone.fa-basketball-ball:after {
  content: "\10f434"; }

.fad.fa-basketball-hoop:before, .fa-duotone.fa-basketball-hoop:before {
  content: "\f435\fe01"; }

.fad.fa-basketball-hoop:after, .fa-duotone.fa-basketball-hoop:after {
  content: "\f435\fe02"; }

.fad.fa-bat:before, .fa-duotone.fa-bat:before {
  content: "\f6b5\fe01"; }

.fad.fa-bat:after, .fa-duotone.fa-bat:after {
  content: "\f6b5\fe02"; }

.fad.fa-bath:before, .fa-duotone.fa-bath:before {
  content: "\f2cd"; }

.fad.fa-bath:after, .fa-duotone.fa-bath:after {
  content: "\10f2cd"; }

.fad.fa-bathtub:before, .fa-duotone.fa-bathtub:before {
  content: "\f2cd"; }

.fad.fa-bathtub:after, .fa-duotone.fa-bathtub:after {
  content: "\10f2cd"; }

.fad.fa-battery-bolt:before, .fa-duotone.fa-battery-bolt:before {
  content: "\f376\fe01"; }

.fad.fa-battery-bolt:after, .fa-duotone.fa-battery-bolt:after {
  content: "\f376\fe02"; }

.fad.fa-battery-empty:before, .fa-duotone.fa-battery-empty:before {
  content: "\f244\fe01"; }

.fad.fa-battery-empty:after, .fa-duotone.fa-battery-empty:after {
  content: "\f244\fe02"; }

.fad.fa-battery-0:before, .fa-duotone.fa-battery-0:before {
  content: "\f244\fe01"; }

.fad.fa-battery-0:after, .fa-duotone.fa-battery-0:after {
  content: "\f244\fe02"; }

.fad.fa-battery-exclamation:before, .fa-duotone.fa-battery-exclamation:before {
  content: "\e0b0\fe01"; }

.fad.fa-battery-exclamation:after, .fa-duotone.fa-battery-exclamation:after {
  content: "\e0b0\fe02"; }

.fad.fa-battery-full:before, .fa-duotone.fa-battery-full:before {
  content: "\f240"; }

.fad.fa-battery-full:after, .fa-duotone.fa-battery-full:after {
  content: "\10f240"; }

.fad.fa-battery:before, .fa-duotone.fa-battery:before {
  content: "\f240"; }

.fad.fa-battery:after, .fa-duotone.fa-battery:after {
  content: "\10f240"; }

.fad.fa-battery-5:before, .fa-duotone.fa-battery-5:before {
  content: "\f240"; }

.fad.fa-battery-5:after, .fa-duotone.fa-battery-5:after {
  content: "\10f240"; }

.fad.fa-battery-half:before, .fa-duotone.fa-battery-half:before {
  content: "\f242\fe01"; }

.fad.fa-battery-half:after, .fa-duotone.fa-battery-half:after {
  content: "\f242\fe02"; }

.fad.fa-battery-3:before, .fa-duotone.fa-battery-3:before {
  content: "\f242\fe01"; }

.fad.fa-battery-3:after, .fa-duotone.fa-battery-3:after {
  content: "\f242\fe02"; }

.fad.fa-battery-low:before, .fa-duotone.fa-battery-low:before {
  content: "\e0b1\fe01"; }

.fad.fa-battery-low:after, .fa-duotone.fa-battery-low:after {
  content: "\e0b1\fe02"; }

.fad.fa-battery-1:before, .fa-duotone.fa-battery-1:before {
  content: "\e0b1\fe01"; }

.fad.fa-battery-1:after, .fa-duotone.fa-battery-1:after {
  content: "\e0b1\fe02"; }

.fad.fa-battery-quarter:before, .fa-duotone.fa-battery-quarter:before {
  content: "\f243\fe01"; }

.fad.fa-battery-quarter:after, .fa-duotone.fa-battery-quarter:after {
  content: "\f243\fe02"; }

.fad.fa-battery-2:before, .fa-duotone.fa-battery-2:before {
  content: "\f243\fe01"; }

.fad.fa-battery-2:after, .fa-duotone.fa-battery-2:after {
  content: "\f243\fe02"; }

.fad.fa-battery-slash:before, .fa-duotone.fa-battery-slash:before {
  content: "\f377\fe01"; }

.fad.fa-battery-slash:after, .fa-duotone.fa-battery-slash:after {
  content: "\f377\fe02"; }

.fad.fa-battery-three-quarters:before, .fa-duotone.fa-battery-three-quarters:before {
  content: "\f241\fe01"; }

.fad.fa-battery-three-quarters:after, .fa-duotone.fa-battery-three-quarters:after {
  content: "\f241\fe02"; }

.fad.fa-battery-4:before, .fa-duotone.fa-battery-4:before {
  content: "\f241\fe01"; }

.fad.fa-battery-4:after, .fa-duotone.fa-battery-4:after {
  content: "\f241\fe02"; }

.fad.fa-bed:before, .fa-duotone.fa-bed:before {
  content: "\f236"; }

.fad.fa-bed:after, .fa-duotone.fa-bed:after {
  content: "\10f236"; }

.fad.fa-bed-bunk:before, .fa-duotone.fa-bed-bunk:before {
  content: "\f8f8\fe01"; }

.fad.fa-bed-bunk:after, .fa-duotone.fa-bed-bunk:after {
  content: "\f8f8\fe02"; }

.fad.fa-bed-empty:before, .fa-duotone.fa-bed-empty:before {
  content: "\f8f9"; }

.fad.fa-bed-empty:after, .fa-duotone.fa-bed-empty:after {
  content: "\10f8f9"; }

.fad.fa-bed-front:before, .fa-duotone.fa-bed-front:before {
  content: "\f8f7\fe01"; }

.fad.fa-bed-front:after, .fa-duotone.fa-bed-front:after {
  content: "\f8f7\fe02"; }

.fad.fa-bed-alt:before, .fa-duotone.fa-bed-alt:before {
  content: "\f8f7\fe01"; }

.fad.fa-bed-alt:after, .fa-duotone.fa-bed-alt:after {
  content: "\f8f7\fe02"; }

.fad.fa-bed-pulse:before, .fa-duotone.fa-bed-pulse:before {
  content: "\f487\fe01"; }

.fad.fa-bed-pulse:after, .fa-duotone.fa-bed-pulse:after {
  content: "\f487\fe02"; }

.fad.fa-procedures:before, .fa-duotone.fa-procedures:before {
  content: "\f487\fe01"; }

.fad.fa-procedures:after, .fa-duotone.fa-procedures:after {
  content: "\f487\fe02"; }

.fad.fa-bee:before, .fa-duotone.fa-bee:before {
  content: "\e0b2\fe01"; }

.fad.fa-bee:after, .fa-duotone.fa-bee:after {
  content: "\e0b2\fe02"; }

.fad.fa-beer-mug:before, .fa-duotone.fa-beer-mug:before {
  content: "\e0b3\fe01"; }

.fad.fa-beer-mug:after, .fa-duotone.fa-beer-mug:after {
  content: "\e0b3\fe02"; }

.fad.fa-beer-foam:before, .fa-duotone.fa-beer-foam:before {
  content: "\e0b3\fe01"; }

.fad.fa-beer-foam:after, .fa-duotone.fa-beer-foam:after {
  content: "\e0b3\fe02"; }

.fad.fa-beer-mug-empty:before, .fa-duotone.fa-beer-mug-empty:before {
  content: "\f0fc\fe01"; }

.fad.fa-beer-mug-empty:after, .fa-duotone.fa-beer-mug-empty:after {
  content: "\f0fc\fe02"; }

.fad.fa-beer:before, .fa-duotone.fa-beer:before {
  content: "\f0fc\fe01"; }

.fad.fa-beer:after, .fa-duotone.fa-beer:after {
  content: "\f0fc\fe02"; }

.fad.fa-bell:before, .fa-duotone.fa-bell:before {
  content: "\f0f3"; }

.fad.fa-bell:after, .fa-duotone.fa-bell:after {
  content: "\10f0f3"; }

.fad.fa-bell-concierge:before, .fa-duotone.fa-bell-concierge:before {
  content: "\f562"; }

.fad.fa-bell-concierge:after, .fa-duotone.fa-bell-concierge:after {
  content: "\10f562"; }

.fad.fa-concierge-bell:before, .fa-duotone.fa-concierge-bell:before {
  content: "\f562"; }

.fad.fa-concierge-bell:after, .fa-duotone.fa-concierge-bell:after {
  content: "\10f562"; }

.fad.fa-bell-exclamation:before, .fa-duotone.fa-bell-exclamation:before {
  content: "\f848\fe01"; }

.fad.fa-bell-exclamation:after, .fa-duotone.fa-bell-exclamation:after {
  content: "\f848\fe02"; }

.fad.fa-bell-on:before, .fa-duotone.fa-bell-on:before {
  content: "\f8fa"; }

.fad.fa-bell-on:after, .fa-duotone.fa-bell-on:after {
  content: "\10f8fa"; }

.fad.fa-bell-plus:before, .fa-duotone.fa-bell-plus:before {
  content: "\f849\fe01"; }

.fad.fa-bell-plus:after, .fa-duotone.fa-bell-plus:after {
  content: "\f849\fe02"; }

.fad.fa-bell-school:before, .fa-duotone.fa-bell-school:before {
  content: "\f5d5\fe01"; }

.fad.fa-bell-school:after, .fa-duotone.fa-bell-school:after {
  content: "\f5d5\fe02"; }

.fad.fa-bell-school-slash:before, .fa-duotone.fa-bell-school-slash:before {
  content: "\f5d6\fe01"; }

.fad.fa-bell-school-slash:after, .fa-duotone.fa-bell-school-slash:after {
  content: "\f5d6\fe02"; }

.fad.fa-bell-slash:before, .fa-duotone.fa-bell-slash:before {
  content: "\f1f6"; }

.fad.fa-bell-slash:after, .fa-duotone.fa-bell-slash:after {
  content: "\10f1f6"; }

.fad.fa-bells:before, .fa-duotone.fa-bells:before {
  content: "\f77f\fe01"; }

.fad.fa-bells:after, .fa-duotone.fa-bells:after {
  content: "\f77f\fe02"; }

.fad.fa-bench-tree:before, .fa-duotone.fa-bench-tree:before {
  content: "\e2e7\fe01"; }

.fad.fa-bench-tree:after, .fa-duotone.fa-bench-tree:after {
  content: "\e2e7\fe02"; }

.fad.fa-bezier-curve:before, .fa-duotone.fa-bezier-curve:before {
  content: "\f55b\fe01"; }

.fad.fa-bezier-curve:after, .fa-duotone.fa-bezier-curve:after {
  content: "\f55b\fe02"; }

.fad.fa-bicycle:before, .fa-duotone.fa-bicycle:before {
  content: "\f206"; }

.fad.fa-bicycle:after, .fa-duotone.fa-bicycle:after {
  content: "\10f206"; }

.fad.fa-binary:before, .fa-duotone.fa-binary:before {
  content: "\e33b\fe01"; }

.fad.fa-binary:after, .fa-duotone.fa-binary:after {
  content: "\e33b\fe02"; }

.fad.fa-binary-circle-check:before, .fa-duotone.fa-binary-circle-check:before {
  content: "\e33c\fe01"; }

.fad.fa-binary-circle-check:after, .fa-duotone.fa-binary-circle-check:after {
  content: "\e33c\fe02"; }

.fad.fa-binary-lock:before, .fa-duotone.fa-binary-lock:before {
  content: "\e33d\fe01"; }

.fad.fa-binary-lock:after, .fa-duotone.fa-binary-lock:after {
  content: "\e33d\fe02"; }

.fad.fa-binary-slash:before, .fa-duotone.fa-binary-slash:before {
  content: "\e33e\fe01"; }

.fad.fa-binary-slash:after, .fa-duotone.fa-binary-slash:after {
  content: "\e33e\fe02"; }

.fad.fa-binoculars:before, .fa-duotone.fa-binoculars:before {
  content: "\f1e5\fe01"; }

.fad.fa-binoculars:after, .fa-duotone.fa-binoculars:after {
  content: "\f1e5\fe02"; }

.fad.fa-biohazard:before, .fa-duotone.fa-biohazard:before {
  content: "\2623\fe01"; }

.fad.fa-biohazard:after, .fa-duotone.fa-biohazard:after {
  content: "\2623\fe02"; }

.fad.fa-bitcoin-sign:before, .fa-duotone.fa-bitcoin-sign:before {
  content: "\e0b4\fe01"; }

.fad.fa-bitcoin-sign:after, .fa-duotone.fa-bitcoin-sign:after {
  content: "\e0b4\fe02"; }

.fad.fa-blanket:before, .fa-duotone.fa-blanket:before {
  content: "\f498\fe01"; }

.fad.fa-blanket:after, .fa-duotone.fa-blanket:after {
  content: "\f498\fe02"; }

.fad.fa-blender:before, .fa-duotone.fa-blender:before {
  content: "\f517\fe01"; }

.fad.fa-blender:after, .fa-duotone.fa-blender:after {
  content: "\f517\fe02"; }

.fad.fa-blender-phone:before, .fa-duotone.fa-blender-phone:before {
  content: "\f6b6\fe01"; }

.fad.fa-blender-phone:after, .fa-duotone.fa-blender-phone:after {
  content: "\f6b6\fe02"; }

.fad.fa-blinds:before, .fa-duotone.fa-blinds:before {
  content: "\f8fb\fe01"; }

.fad.fa-blinds:after, .fa-duotone.fa-blinds:after {
  content: "\f8fb\fe02"; }

.fad.fa-blinds-open:before, .fa-duotone.fa-blinds-open:before {
  content: "\f8fc\fe01"; }

.fad.fa-blinds-open:after, .fa-duotone.fa-blinds-open:after {
  content: "\f8fc\fe02"; }

.fad.fa-blinds-raised:before, .fa-duotone.fa-blinds-raised:before {
  content: "\f8fd\fe01"; }

.fad.fa-blinds-raised:after, .fa-duotone.fa-blinds-raised:after {
  content: "\f8fd\fe02"; }

.fad.fa-block-quote:before, .fa-duotone.fa-block-quote:before {
  content: "\e0b5\fe01"; }

.fad.fa-block-quote:after, .fa-duotone.fa-block-quote:after {
  content: "\e0b5\fe02"; }

.fad.fa-blog:before, .fa-duotone.fa-blog:before {
  content: "\f781\fe01"; }

.fad.fa-blog:after, .fa-duotone.fa-blog:after {
  content: "\f781\fe02"; }

.fad.fa-blueberries:before, .fa-duotone.fa-blueberries:before {
  content: "\e2e8\fe01"; }

.fad.fa-blueberries:after, .fa-duotone.fa-blueberries:after {
  content: "\e2e8\fe02"; }

.fad.fa-bluetooth:before, .fa-duotone.fa-bluetooth:before {
  content: "\f293\fe01"; }

.fad.fa-bluetooth:after, .fa-duotone.fa-bluetooth:after {
  content: "\f293\fe02"; }

.fad.fa-bold:before, .fa-duotone.fa-bold:before {
  content: "\f032\fe01"; }

.fad.fa-bold:after, .fa-duotone.fa-bold:after {
  content: "\f032\fe02"; }

.fad.fa-bolt:before, .fa-duotone.fa-bolt:before {
  content: "\26a1\fe01"; }

.fad.fa-bolt:after, .fa-duotone.fa-bolt:after {
  content: "\26a1\fe02"; }

.fad.fa-flash:before, .fa-duotone.fa-flash:before {
  content: "\26a1\fe01"; }

.fad.fa-flash:after, .fa-duotone.fa-flash:after {
  content: "\26a1\fe02"; }

.fad.fa-bolt-auto:before, .fa-duotone.fa-bolt-auto:before {
  content: "\e0b6\fe01"; }

.fad.fa-bolt-auto:after, .fa-duotone.fa-bolt-auto:after {
  content: "\e0b6\fe02"; }

.fad.fa-bolt-lightning:before, .fa-duotone.fa-bolt-lightning:before {
  content: "\e0b7\fe01"; }

.fad.fa-bolt-lightning:after, .fa-duotone.fa-bolt-lightning:after {
  content: "\e0b7\fe02"; }

.fad.fa-bolt-slash:before, .fa-duotone.fa-bolt-slash:before {
  content: "\e0b8\fe01"; }

.fad.fa-bolt-slash:after, .fa-duotone.fa-bolt-slash:after {
  content: "\e0b8\fe02"; }

.fad.fa-bomb:before, .fa-duotone.fa-bomb:before {
  content: "\f1e2"; }

.fad.fa-bomb:after, .fa-duotone.fa-bomb:after {
  content: "\10f1e2"; }

.fad.fa-bone:before, .fa-duotone.fa-bone:before {
  content: "\f5d7"; }

.fad.fa-bone:after, .fa-duotone.fa-bone:after {
  content: "\10f5d7"; }

.fad.fa-bone-break:before, .fa-duotone.fa-bone-break:before {
  content: "\f5d8\fe01"; }

.fad.fa-bone-break:after, .fa-duotone.fa-bone-break:after {
  content: "\f5d8\fe02"; }

.fad.fa-bong:before, .fa-duotone.fa-bong:before {
  content: "\f55c\fe01"; }

.fad.fa-bong:after, .fa-duotone.fa-bong:after {
  content: "\f55c\fe02"; }

.fad.fa-book:before, .fa-duotone.fa-book:before {
  content: "\f02d"; }

.fad.fa-book:after, .fa-duotone.fa-book:after {
  content: "\10f02d"; }

.fad.fa-book-arrow-right:before, .fa-duotone.fa-book-arrow-right:before {
  content: "\e0b9\fe01"; }

.fad.fa-book-arrow-right:after, .fa-duotone.fa-book-arrow-right:after {
  content: "\e0b9\fe02"; }

.fad.fa-book-arrow-up:before, .fa-duotone.fa-book-arrow-up:before {
  content: "\e0ba\fe01"; }

.fad.fa-book-arrow-up:after, .fa-duotone.fa-book-arrow-up:after {
  content: "\e0ba\fe02"; }

.fad.fa-book-atlas:before, .fa-duotone.fa-book-atlas:before {
  content: "\f558\fe01"; }

.fad.fa-book-atlas:after, .fa-duotone.fa-book-atlas:after {
  content: "\f558\fe02"; }

.fad.fa-atlas:before, .fa-duotone.fa-atlas:before {
  content: "\f558\fe01"; }

.fad.fa-atlas:after, .fa-duotone.fa-atlas:after {
  content: "\f558\fe02"; }

.fad.fa-book-bible:before, .fa-duotone.fa-book-bible:before {
  content: "\f647\fe01"; }

.fad.fa-book-bible:after, .fa-duotone.fa-book-bible:after {
  content: "\f647\fe02"; }

.fad.fa-bible:before, .fa-duotone.fa-bible:before {
  content: "\f647\fe01"; }

.fad.fa-bible:after, .fa-duotone.fa-bible:after {
  content: "\f647\fe02"; }

.fad.fa-book-blank:before, .fa-duotone.fa-book-blank:before {
  content: "\f5d9"; }

.fad.fa-book-blank:after, .fa-duotone.fa-book-blank:after {
  content: "\10f5d9"; }

.fad.fa-book-alt:before, .fa-duotone.fa-book-alt:before {
  content: "\f5d9"; }

.fad.fa-book-alt:after, .fa-duotone.fa-book-alt:after {
  content: "\10f5d9"; }

.fad.fa-book-bookmark:before, .fa-duotone.fa-book-bookmark:before {
  content: "\e0bb\fe01"; }

.fad.fa-book-bookmark:after, .fa-duotone.fa-book-bookmark:after {
  content: "\e0bb\fe02"; }

.fad.fa-book-circle-arrow-right:before, .fa-duotone.fa-book-circle-arrow-right:before {
  content: "\e0bc\fe01"; }

.fad.fa-book-circle-arrow-right:after, .fa-duotone.fa-book-circle-arrow-right:after {
  content: "\e0bc\fe02"; }

.fad.fa-book-circle-arrow-up:before, .fa-duotone.fa-book-circle-arrow-up:before {
  content: "\e0bd\fe01"; }

.fad.fa-book-circle-arrow-up:after, .fa-duotone.fa-book-circle-arrow-up:after {
  content: "\e0bd\fe02"; }

.fad.fa-book-copy:before, .fa-duotone.fa-book-copy:before {
  content: "\e0be\fe01"; }

.fad.fa-book-copy:after, .fa-duotone.fa-book-copy:after {
  content: "\e0be\fe02"; }

.fad.fa-book-font:before, .fa-duotone.fa-book-font:before {
  content: "\e0bf\fe01"; }

.fad.fa-book-font:after, .fa-duotone.fa-book-font:after {
  content: "\e0bf\fe02"; }

.fad.fa-book-heart:before, .fa-duotone.fa-book-heart:before {
  content: "\f499\fe01"; }

.fad.fa-book-heart:after, .fa-duotone.fa-book-heart:after {
  content: "\f499\fe02"; }

.fad.fa-book-journal-whills:before, .fa-duotone.fa-book-journal-whills:before {
  content: "\f66a\fe01"; }

.fad.fa-book-journal-whills:after, .fa-duotone.fa-book-journal-whills:after {
  content: "\f66a\fe02"; }

.fad.fa-journal-whills:before, .fa-duotone.fa-journal-whills:before {
  content: "\f66a\fe01"; }

.fad.fa-journal-whills:after, .fa-duotone.fa-journal-whills:after {
  content: "\f66a\fe02"; }

.fad.fa-book-medical:before, .fa-duotone.fa-book-medical:before {
  content: "\f7e6\fe01"; }

.fad.fa-book-medical:after, .fa-duotone.fa-book-medical:after {
  content: "\f7e6\fe02"; }

.fad.fa-book-open:before, .fa-duotone.fa-book-open:before {
  content: "\f518"; }

.fad.fa-book-open:after, .fa-duotone.fa-book-open:after {
  content: "\10f518"; }

.fad.fa-book-open-cover:before, .fa-duotone.fa-book-open-cover:before {
  content: "\e0c0\fe01"; }

.fad.fa-book-open-cover:after, .fa-duotone.fa-book-open-cover:after {
  content: "\e0c0\fe02"; }

.fad.fa-book-open-alt:before, .fa-duotone.fa-book-open-alt:before {
  content: "\e0c0\fe01"; }

.fad.fa-book-open-alt:after, .fa-duotone.fa-book-open-alt:after {
  content: "\e0c0\fe02"; }

.fad.fa-book-open-reader:before, .fa-duotone.fa-book-open-reader:before {
  content: "\f5da\fe01"; }

.fad.fa-book-open-reader:after, .fa-duotone.fa-book-open-reader:after {
  content: "\f5da\fe02"; }

.fad.fa-book-reader:before, .fa-duotone.fa-book-reader:before {
  content: "\f5da\fe01"; }

.fad.fa-book-reader:after, .fa-duotone.fa-book-reader:after {
  content: "\f5da\fe02"; }

.fad.fa-book-quran:before, .fa-duotone.fa-book-quran:before {
  content: "\f687\fe01"; }

.fad.fa-book-quran:after, .fa-duotone.fa-book-quran:after {
  content: "\f687\fe02"; }

.fad.fa-quran:before, .fa-duotone.fa-quran:before {
  content: "\f687\fe01"; }

.fad.fa-quran:after, .fa-duotone.fa-quran:after {
  content: "\f687\fe02"; }

.fad.fa-book-section:before, .fa-duotone.fa-book-section:before {
  content: "\e0c1\fe01"; }

.fad.fa-book-section:after, .fa-duotone.fa-book-section:after {
  content: "\e0c1\fe02"; }

.fad.fa-book-law:before, .fa-duotone.fa-book-law:before {
  content: "\e0c1\fe01"; }

.fad.fa-book-law:after, .fa-duotone.fa-book-law:after {
  content: "\e0c1\fe02"; }

.fad.fa-book-skull:before, .fa-duotone.fa-book-skull:before {
  content: "\f6b7\fe01"; }

.fad.fa-book-skull:after, .fa-duotone.fa-book-skull:after {
  content: "\f6b7\fe02"; }

.fad.fa-book-dead:before, .fa-duotone.fa-book-dead:before {
  content: "\f6b7\fe01"; }

.fad.fa-book-dead:after, .fa-duotone.fa-book-dead:after {
  content: "\f6b7\fe02"; }

.fad.fa-book-sparkles:before, .fa-duotone.fa-book-sparkles:before {
  content: "\f6b8\fe01"; }

.fad.fa-book-sparkles:after, .fa-duotone.fa-book-sparkles:after {
  content: "\f6b8\fe02"; }

.fad.fa-book-spells:before, .fa-duotone.fa-book-spells:before {
  content: "\f6b8\fe01"; }

.fad.fa-book-spells:after, .fa-duotone.fa-book-spells:after {
  content: "\f6b8\fe02"; }

.fad.fa-book-tanakh:before, .fa-duotone.fa-book-tanakh:before {
  content: "\f827\fe01"; }

.fad.fa-book-tanakh:after, .fa-duotone.fa-book-tanakh:after {
  content: "\f827\fe02"; }

.fad.fa-tanakh:before, .fa-duotone.fa-tanakh:before {
  content: "\f827\fe01"; }

.fad.fa-tanakh:after, .fa-duotone.fa-tanakh:after {
  content: "\f827\fe02"; }

.fad.fa-book-user:before, .fa-duotone.fa-book-user:before {
  content: "\f7e7\fe01"; }

.fad.fa-book-user:after, .fa-duotone.fa-book-user:after {
  content: "\f7e7\fe02"; }

.fad.fa-bookmark:before, .fa-duotone.fa-bookmark:before {
  content: "\f02e"; }

.fad.fa-bookmark:after, .fa-duotone.fa-bookmark:after {
  content: "\10f02e"; }

.fad.fa-bookmark-slash:before, .fa-duotone.fa-bookmark-slash:before {
  content: "\e0c2\fe01"; }

.fad.fa-bookmark-slash:after, .fa-duotone.fa-bookmark-slash:after {
  content: "\e0c2\fe02"; }

.fad.fa-books:before, .fa-duotone.fa-books:before {
  content: "\f5db"; }

.fad.fa-books:after, .fa-duotone.fa-books:after {
  content: "\10f5db"; }

.fad.fa-books-medical:before, .fa-duotone.fa-books-medical:before {
  content: "\f7e8\fe01"; }

.fad.fa-books-medical:after, .fa-duotone.fa-books-medical:after {
  content: "\f7e8\fe02"; }

.fad.fa-boombox:before, .fa-duotone.fa-boombox:before {
  content: "\f8a5"; }

.fad.fa-boombox:after, .fa-duotone.fa-boombox:after {
  content: "\10f8a5"; }

.fad.fa-boot:before, .fa-duotone.fa-boot:before {
  content: "\f782"; }

.fad.fa-boot:after, .fa-duotone.fa-boot:after {
  content: "\10f782"; }

.fad.fa-boot-heeled:before, .fa-duotone.fa-boot-heeled:before {
  content: "\e33f\fe01"; }

.fad.fa-boot-heeled:after, .fa-duotone.fa-boot-heeled:after {
  content: "\e33f\fe02"; }

.fad.fa-booth-curtain:before, .fa-duotone.fa-booth-curtain:before {
  content: "\f734\fe01"; }

.fad.fa-booth-curtain:after, .fa-duotone.fa-booth-curtain:after {
  content: "\f734\fe02"; }

.fad.fa-border-all:before, .fa-duotone.fa-border-all:before {
  content: "\f84c\fe01"; }

.fad.fa-border-all:after, .fa-duotone.fa-border-all:after {
  content: "\f84c\fe02"; }

.fad.fa-border-bottom:before, .fa-duotone.fa-border-bottom:before {
  content: "\f84d\fe01"; }

.fad.fa-border-bottom:after, .fa-duotone.fa-border-bottom:after {
  content: "\f84d\fe02"; }

.fad.fa-border-bottom-right:before, .fa-duotone.fa-border-bottom-right:before {
  content: "\f854\fe01"; }

.fad.fa-border-bottom-right:after, .fa-duotone.fa-border-bottom-right:after {
  content: "\f854\fe02"; }

.fad.fa-border-style-alt:before, .fa-duotone.fa-border-style-alt:before {
  content: "\f854\fe01"; }

.fad.fa-border-style-alt:after, .fa-duotone.fa-border-style-alt:after {
  content: "\f854\fe02"; }

.fad.fa-border-center-h:before, .fa-duotone.fa-border-center-h:before {
  content: "\f89c\fe01"; }

.fad.fa-border-center-h:after, .fa-duotone.fa-border-center-h:after {
  content: "\f89c\fe02"; }

.fad.fa-border-center-v:before, .fa-duotone.fa-border-center-v:before {
  content: "\f89d\fe01"; }

.fad.fa-border-center-v:after, .fa-duotone.fa-border-center-v:after {
  content: "\f89d\fe02"; }

.fad.fa-border-inner:before, .fa-duotone.fa-border-inner:before {
  content: "\f84e\fe01"; }

.fad.fa-border-inner:after, .fa-duotone.fa-border-inner:after {
  content: "\f84e\fe02"; }

.fad.fa-border-left:before, .fa-duotone.fa-border-left:before {
  content: "\f84f\fe01"; }

.fad.fa-border-left:after, .fa-duotone.fa-border-left:after {
  content: "\f84f\fe02"; }

.fad.fa-border-none:before, .fa-duotone.fa-border-none:before {
  content: "\f850\fe01"; }

.fad.fa-border-none:after, .fa-duotone.fa-border-none:after {
  content: "\f850\fe02"; }

.fad.fa-border-outer:before, .fa-duotone.fa-border-outer:before {
  content: "\f851\fe01"; }

.fad.fa-border-outer:after, .fa-duotone.fa-border-outer:after {
  content: "\f851\fe02"; }

.fad.fa-border-right:before, .fa-duotone.fa-border-right:before {
  content: "\f852\fe01"; }

.fad.fa-border-right:after, .fa-duotone.fa-border-right:after {
  content: "\f852\fe02"; }

.fad.fa-border-top:before, .fa-duotone.fa-border-top:before {
  content: "\f855\fe01"; }

.fad.fa-border-top:after, .fa-duotone.fa-border-top:after {
  content: "\f855\fe02"; }

.fad.fa-border-top-left:before, .fa-duotone.fa-border-top-left:before {
  content: "\f853\fe01"; }

.fad.fa-border-top-left:after, .fa-duotone.fa-border-top-left:after {
  content: "\f853\fe02"; }

.fad.fa-border-style:before, .fa-duotone.fa-border-style:before {
  content: "\f853\fe01"; }

.fad.fa-border-style:after, .fa-duotone.fa-border-style:after {
  content: "\f853\fe02"; }

.fad.fa-bow-arrow:before, .fa-duotone.fa-bow-arrow:before {
  content: "\f6b9"; }

.fad.fa-bow-arrow:after, .fa-duotone.fa-bow-arrow:after {
  content: "\10f6b9"; }

.fad.fa-bowl-chopsticks:before, .fa-duotone.fa-bowl-chopsticks:before {
  content: "\e2e9\fe01"; }

.fad.fa-bowl-chopsticks:after, .fa-duotone.fa-bowl-chopsticks:after {
  content: "\e2e9\fe02"; }

.fad.fa-bowl-chopsticks-noodles:before, .fa-duotone.fa-bowl-chopsticks-noodles:before {
  content: "\e2ea\fe01"; }

.fad.fa-bowl-chopsticks-noodles:after, .fa-duotone.fa-bowl-chopsticks-noodles:after {
  content: "\e2ea\fe02"; }

.fad.fa-bowl-hot:before, .fa-duotone.fa-bowl-hot:before {
  content: "\f823\fe01"; }

.fad.fa-bowl-hot:after, .fa-duotone.fa-bowl-hot:after {
  content: "\f823\fe02"; }

.fad.fa-soup:before, .fa-duotone.fa-soup:before {
  content: "\f823\fe01"; }

.fad.fa-soup:after, .fa-duotone.fa-soup:after {
  content: "\f823\fe02"; }

.fad.fa-bowl-rice:before, .fa-duotone.fa-bowl-rice:before {
  content: "\e2eb\fe01"; }

.fad.fa-bowl-rice:after, .fa-duotone.fa-bowl-rice:after {
  content: "\e2eb\fe02"; }

.fad.fa-bowling-ball:before, .fa-duotone.fa-bowling-ball:before {
  content: "\f436\fe01"; }

.fad.fa-bowling-ball:after, .fa-duotone.fa-bowling-ball:after {
  content: "\f436\fe02"; }

.fad.fa-bowling-ball-pin:before, .fa-duotone.fa-bowling-ball-pin:before {
  content: "\e0c3\fe01"; }

.fad.fa-bowling-ball-pin:after, .fa-duotone.fa-bowling-ball-pin:after {
  content: "\e0c3\fe02"; }

.fad.fa-bowling-pins:before, .fa-duotone.fa-bowling-pins:before {
  content: "\f437\fe01"; }

.fad.fa-bowling-pins:after, .fa-duotone.fa-bowling-pins:after {
  content: "\f437\fe02"; }

.fad.fa-box:before, .fa-duotone.fa-box:before {
  content: "\f466"; }

.fad.fa-box:after, .fa-duotone.fa-box:after {
  content: "\10f466"; }

.fad.fa-box-archive:before, .fa-duotone.fa-box-archive:before {
  content: "\f187\fe01"; }

.fad.fa-box-archive:after, .fa-duotone.fa-box-archive:after {
  content: "\f187\fe02"; }

.fad.fa-archive:before, .fa-duotone.fa-archive:before {
  content: "\f187\fe01"; }

.fad.fa-archive:after, .fa-duotone.fa-archive:after {
  content: "\f187\fe02"; }

.fad.fa-box-ballot:before, .fa-duotone.fa-box-ballot:before {
  content: "\f735"; }

.fad.fa-box-ballot:after, .fa-duotone.fa-box-ballot:after {
  content: "\10f735"; }

.fad.fa-box-check:before, .fa-duotone.fa-box-check:before {
  content: "\f467\fe01"; }

.fad.fa-box-check:after, .fa-duotone.fa-box-check:after {
  content: "\f467\fe02"; }

.fad.fa-box-circle-check:before, .fa-duotone.fa-box-circle-check:before {
  content: "\e0c4\fe01"; }

.fad.fa-box-circle-check:after, .fa-duotone.fa-box-circle-check:after {
  content: "\e0c4\fe02"; }

.fad.fa-box-dollar:before, .fa-duotone.fa-box-dollar:before {
  content: "\f4a0\fe01"; }

.fad.fa-box-dollar:after, .fa-duotone.fa-box-dollar:after {
  content: "\f4a0\fe02"; }

.fad.fa-box-usd:before, .fa-duotone.fa-box-usd:before {
  content: "\f4a0\fe01"; }

.fad.fa-box-usd:after, .fa-duotone.fa-box-usd:after {
  content: "\f4a0\fe02"; }

.fad.fa-box-heart:before, .fa-duotone.fa-box-heart:before {
  content: "\f49d\fe01"; }

.fad.fa-box-heart:after, .fa-duotone.fa-box-heart:after {
  content: "\f49d\fe02"; }

.fad.fa-box-open:before, .fa-duotone.fa-box-open:before {
  content: "\f49e\fe01"; }

.fad.fa-box-open:after, .fa-duotone.fa-box-open:after {
  content: "\f49e\fe02"; }

.fad.fa-box-open-full:before, .fa-duotone.fa-box-open-full:before {
  content: "\f49c\fe01"; }

.fad.fa-box-open-full:after, .fa-duotone.fa-box-open-full:after {
  content: "\f49c\fe02"; }

.fad.fa-box-full:before, .fa-duotone.fa-box-full:before {
  content: "\f49c\fe01"; }

.fad.fa-box-full:after, .fa-duotone.fa-box-full:after {
  content: "\f49c\fe02"; }

.fad.fa-box-taped:before, .fa-duotone.fa-box-taped:before {
  content: "\f49a\fe01"; }

.fad.fa-box-taped:after, .fa-duotone.fa-box-taped:after {
  content: "\f49a\fe02"; }

.fad.fa-box-alt:before, .fa-duotone.fa-box-alt:before {
  content: "\f49a\fe01"; }

.fad.fa-box-alt:after, .fa-duotone.fa-box-alt:after {
  content: "\f49a\fe02"; }

.fad.fa-box-tissue:before, .fa-duotone.fa-box-tissue:before {
  content: "\e05b\fe01"; }

.fad.fa-box-tissue:after, .fa-duotone.fa-box-tissue:after {
  content: "\e05b\fe02"; }

.fad.fa-boxes-stacked:before, .fa-duotone.fa-boxes-stacked:before {
  content: "\f468\fe01"; }

.fad.fa-boxes-stacked:after, .fa-duotone.fa-boxes-stacked:after {
  content: "\f468\fe02"; }

.fad.fa-boxes:before, .fa-duotone.fa-boxes:before {
  content: "\f468\fe01"; }

.fad.fa-boxes:after, .fa-duotone.fa-boxes:after {
  content: "\f468\fe02"; }

.fad.fa-boxes-alt:before, .fa-duotone.fa-boxes-alt:before {
  content: "\f468\fe01"; }

.fad.fa-boxes-alt:after, .fa-duotone.fa-boxes-alt:after {
  content: "\f468\fe02"; }

.fad.fa-boxing-glove:before, .fa-duotone.fa-boxing-glove:before {
  content: "\f438"; }

.fad.fa-boxing-glove:after, .fa-duotone.fa-boxing-glove:after {
  content: "\10f438"; }

.fad.fa-glove-boxing:before, .fa-duotone.fa-glove-boxing:before {
  content: "\f438"; }

.fad.fa-glove-boxing:after, .fa-duotone.fa-glove-boxing:after {
  content: "\10f438"; }

.fad.fa-bracket-curly:before, .fa-duotone.fa-bracket-curly:before {
  content: "\7b\fe01"; }

.fad.fa-bracket-curly:after, .fa-duotone.fa-bracket-curly:after {
  content: "\7b\fe02"; }

.fad.fa-bracket-curly-left:before, .fa-duotone.fa-bracket-curly-left:before {
  content: "\7b\fe01"; }

.fad.fa-bracket-curly-left:after, .fa-duotone.fa-bracket-curly-left:after {
  content: "\7b\fe02"; }

.fad.fa-bracket-curly-right:before, .fa-duotone.fa-bracket-curly-right:before {
  content: "\7d\fe01"; }

.fad.fa-bracket-curly-right:after, .fa-duotone.fa-bracket-curly-right:after {
  content: "\7d\fe02"; }

.fad.fa-bracket-round:before, .fa-duotone.fa-bracket-round:before {
  content: "\28\fe01"; }

.fad.fa-bracket-round:after, .fa-duotone.fa-bracket-round:after {
  content: "\28\fe02"; }

.fad.fa-parenthesis:before, .fa-duotone.fa-parenthesis:before {
  content: "\28\fe01"; }

.fad.fa-parenthesis:after, .fa-duotone.fa-parenthesis:after {
  content: "\28\fe02"; }

.fad.fa-bracket-round-right:before, .fa-duotone.fa-bracket-round-right:before {
  content: "\29\fe01"; }

.fad.fa-bracket-round-right:after, .fa-duotone.fa-bracket-round-right:after {
  content: "\29\fe02"; }

.fad.fa-bracket-square:before, .fa-duotone.fa-bracket-square:before {
  content: "\5b\fe01"; }

.fad.fa-bracket-square:after, .fa-duotone.fa-bracket-square:after {
  content: "\5b\fe02"; }

.fad.fa-bracket:before, .fa-duotone.fa-bracket:before {
  content: "\5b\fe01"; }

.fad.fa-bracket:after, .fa-duotone.fa-bracket:after {
  content: "\5b\fe02"; }

.fad.fa-bracket-left:before, .fa-duotone.fa-bracket-left:before {
  content: "\5b\fe01"; }

.fad.fa-bracket-left:after, .fa-duotone.fa-bracket-left:after {
  content: "\5b\fe02"; }

.fad.fa-bracket-square-right:before, .fa-duotone.fa-bracket-square-right:before {
  content: "\5d\fe01"; }

.fad.fa-bracket-square-right:after, .fa-duotone.fa-bracket-square-right:after {
  content: "\5d\fe02"; }

.fad.fa-brackets-curly:before, .fa-duotone.fa-brackets-curly:before {
  content: "\f7ea\fe01"; }

.fad.fa-brackets-curly:after, .fa-duotone.fa-brackets-curly:after {
  content: "\f7ea\fe02"; }

.fad.fa-brackets-round:before, .fa-duotone.fa-brackets-round:before {
  content: "\e0c5\fe01"; }

.fad.fa-brackets-round:after, .fa-duotone.fa-brackets-round:after {
  content: "\e0c5\fe02"; }

.fad.fa-parentheses:before, .fa-duotone.fa-parentheses:before {
  content: "\e0c5\fe01"; }

.fad.fa-parentheses:after, .fa-duotone.fa-parentheses:after {
  content: "\e0c5\fe02"; }

.fad.fa-brackets-square:before, .fa-duotone.fa-brackets-square:before {
  content: "\f7e9\fe01"; }

.fad.fa-brackets-square:after, .fa-duotone.fa-brackets-square:after {
  content: "\f7e9\fe02"; }

.fad.fa-brackets:before, .fa-duotone.fa-brackets:before {
  content: "\f7e9\fe01"; }

.fad.fa-brackets:after, .fa-duotone.fa-brackets:after {
  content: "\f7e9\fe02"; }

.fad.fa-braille:before, .fa-duotone.fa-braille:before {
  content: "\f2a1\fe01"; }

.fad.fa-braille:after, .fa-duotone.fa-braille:after {
  content: "\f2a1\fe02"; }

.fad.fa-brain:before, .fa-duotone.fa-brain:before {
  content: "\f5dc"; }

.fad.fa-brain:after, .fa-duotone.fa-brain:after {
  content: "\10f5dc"; }

.fad.fa-brain-arrow-curved-right:before, .fa-duotone.fa-brain-arrow-curved-right:before {
  content: "\f677\fe01"; }

.fad.fa-brain-arrow-curved-right:after, .fa-duotone.fa-brain-arrow-curved-right:after {
  content: "\f677\fe02"; }

.fad.fa-mind-share:before, .fa-duotone.fa-mind-share:before {
  content: "\f677\fe01"; }

.fad.fa-mind-share:after, .fa-duotone.fa-mind-share:after {
  content: "\f677\fe02"; }

.fad.fa-brain-circuit:before, .fa-duotone.fa-brain-circuit:before {
  content: "\e0c6\fe01"; }

.fad.fa-brain-circuit:after, .fa-duotone.fa-brain-circuit:after {
  content: "\e0c6\fe02"; }

.fad.fa-brake-warning:before, .fa-duotone.fa-brake-warning:before {
  content: "\e0c7\fe01"; }

.fad.fa-brake-warning:after, .fa-duotone.fa-brake-warning:after {
  content: "\e0c7\fe02"; }

.fad.fa-bread-loaf:before, .fa-duotone.fa-bread-loaf:before {
  content: "\f7eb"; }

.fad.fa-bread-loaf:after, .fa-duotone.fa-bread-loaf:after {
  content: "\10f7eb"; }

.fad.fa-bread-slice:before, .fa-duotone.fa-bread-slice:before {
  content: "\f7ec\fe01"; }

.fad.fa-bread-slice:after, .fa-duotone.fa-bread-slice:after {
  content: "\f7ec\fe02"; }

.fad.fa-briefcase:before, .fa-duotone.fa-briefcase:before {
  content: "\f0b1"; }

.fad.fa-briefcase:after, .fa-duotone.fa-briefcase:after {
  content: "\10f0b1"; }

.fad.fa-briefcase-arrow-right:before, .fa-duotone.fa-briefcase-arrow-right:before {
  content: "\e2f2\fe01"; }

.fad.fa-briefcase-arrow-right:after, .fa-duotone.fa-briefcase-arrow-right:after {
  content: "\e2f2\fe02"; }

.fad.fa-briefcase-blank:before, .fa-duotone.fa-briefcase-blank:before {
  content: "\e0c8\fe01"; }

.fad.fa-briefcase-blank:after, .fa-duotone.fa-briefcase-blank:after {
  content: "\e0c8\fe02"; }

.fad.fa-briefcase-clock:before, .fa-duotone.fa-briefcase-clock:before {
  content: "\f64a\fe01"; }

.fad.fa-briefcase-clock:after, .fa-duotone.fa-briefcase-clock:after {
  content: "\f64a\fe02"; }

.fad.fa-business-time:before, .fa-duotone.fa-business-time:before {
  content: "\f64a\fe01"; }

.fad.fa-business-time:after, .fa-duotone.fa-business-time:after {
  content: "\f64a\fe02"; }

.fad.fa-briefcase-medical:before, .fa-duotone.fa-briefcase-medical:before {
  content: "\f469\fe01"; }

.fad.fa-briefcase-medical:after, .fa-duotone.fa-briefcase-medical:after {
  content: "\f469\fe02"; }

.fad.fa-brightness:before, .fa-duotone.fa-brightness:before {
  content: "\e0c9\fe01"; }

.fad.fa-brightness:after, .fa-duotone.fa-brightness:after {
  content: "\e0c9\fe02"; }

.fad.fa-brightness-low:before, .fa-duotone.fa-brightness-low:before {
  content: "\e0ca\fe01"; }

.fad.fa-brightness-low:after, .fa-duotone.fa-brightness-low:after {
  content: "\e0ca\fe02"; }

.fad.fa-bring-forward:before, .fa-duotone.fa-bring-forward:before {
  content: "\f856\fe01"; }

.fad.fa-bring-forward:after, .fa-duotone.fa-bring-forward:after {
  content: "\f856\fe02"; }

.fad.fa-bring-front:before, .fa-duotone.fa-bring-front:before {
  content: "\f857\fe01"; }

.fad.fa-bring-front:after, .fa-duotone.fa-bring-front:after {
  content: "\f857\fe02"; }

.fad.fa-broom:before, .fa-duotone.fa-broom:before {
  content: "\f51a"; }

.fad.fa-broom:after, .fa-duotone.fa-broom:after {
  content: "\10f51a"; }

.fad.fa-browser:before, .fa-duotone.fa-browser:before {
  content: "\f37e"; }

.fad.fa-browser:after, .fa-duotone.fa-browser:after {
  content: "\10f37e"; }

.fad.fa-browsers:before, .fa-duotone.fa-browsers:before {
  content: "\e0cb\fe01"; }

.fad.fa-browsers:after, .fa-duotone.fa-browsers:after {
  content: "\e0cb\fe02"; }

.fad.fa-brush:before, .fa-duotone.fa-brush:before {
  content: "\f55d\fe01"; }

.fad.fa-brush:after, .fa-duotone.fa-brush:after {
  content: "\f55d\fe02"; }

.fad.fa-bug:before, .fa-duotone.fa-bug:before {
  content: "\f188\fe01"; }

.fad.fa-bug:after, .fa-duotone.fa-bug:after {
  content: "\f188\fe02"; }

.fad.fa-building:before, .fa-duotone.fa-building:before {
  content: "\f1ad"; }

.fad.fa-building:after, .fa-duotone.fa-building:after {
  content: "\10f1ad"; }

.fad.fa-buildings:before, .fa-duotone.fa-buildings:before {
  content: "\e0cc\fe01"; }

.fad.fa-buildings:after, .fa-duotone.fa-buildings:after {
  content: "\e0cc\fe02"; }

.fad.fa-bullhorn:before, .fa-duotone.fa-bullhorn:before {
  content: "\f0a1"; }

.fad.fa-bullhorn:after, .fa-duotone.fa-bullhorn:after {
  content: "\10f0a1"; }

.fad.fa-bullseye:before, .fa-duotone.fa-bullseye:before {
  content: "\f140\fe01"; }

.fad.fa-bullseye:after, .fa-duotone.fa-bullseye:after {
  content: "\f140\fe02"; }

.fad.fa-bullseye-arrow:before, .fa-duotone.fa-bullseye-arrow:before {
  content: "\f648"; }

.fad.fa-bullseye-arrow:after, .fa-duotone.fa-bullseye-arrow:after {
  content: "\10f648"; }

.fad.fa-bullseye-pointer:before, .fa-duotone.fa-bullseye-pointer:before {
  content: "\f649\fe01"; }

.fad.fa-bullseye-pointer:after, .fa-duotone.fa-bullseye-pointer:after {
  content: "\f649\fe02"; }

.fad.fa-burger:before, .fa-duotone.fa-burger:before {
  content: "\f805\fe01"; }

.fad.fa-burger:after, .fa-duotone.fa-burger:after {
  content: "\f805\fe02"; }

.fad.fa-hamburger:before, .fa-duotone.fa-hamburger:before {
  content: "\f805\fe01"; }

.fad.fa-hamburger:after, .fa-duotone.fa-hamburger:after {
  content: "\f805\fe02"; }

.fad.fa-burger-cheese:before, .fa-duotone.fa-burger-cheese:before {
  content: "\f7f1"; }

.fad.fa-burger-cheese:after, .fa-duotone.fa-burger-cheese:after {
  content: "\10f7f1"; }

.fad.fa-cheeseburger:before, .fa-duotone.fa-cheeseburger:before {
  content: "\f7f1"; }

.fad.fa-cheeseburger:after, .fa-duotone.fa-cheeseburger:after {
  content: "\10f7f1"; }

.fad.fa-burger-fries:before, .fa-duotone.fa-burger-fries:before {
  content: "\e0cd\fe01"; }

.fad.fa-burger-fries:after, .fa-duotone.fa-burger-fries:after {
  content: "\e0cd\fe02"; }

.fad.fa-burger-glass:before, .fa-duotone.fa-burger-glass:before {
  content: "\e0ce\fe01"; }

.fad.fa-burger-glass:after, .fa-duotone.fa-burger-glass:after {
  content: "\e0ce\fe02"; }

.fad.fa-burger-soda:before, .fa-duotone.fa-burger-soda:before {
  content: "\f858\fe01"; }

.fad.fa-burger-soda:after, .fa-duotone.fa-burger-soda:after {
  content: "\f858\fe02"; }

.fad.fa-burrito:before, .fa-duotone.fa-burrito:before {
  content: "\f7ed"; }

.fad.fa-burrito:after, .fa-duotone.fa-burrito:after {
  content: "\10f7ed"; }

.fad.fa-bus:before, .fa-duotone.fa-bus:before {
  content: "\f207"; }

.fad.fa-bus:after, .fa-duotone.fa-bus:after {
  content: "\10f207"; }

.fad.fa-bus-school:before, .fa-duotone.fa-bus-school:before {
  content: "\f5dd\fe01"; }

.fad.fa-bus-school:after, .fa-duotone.fa-bus-school:after {
  content: "\f5dd\fe02"; }

.fad.fa-bus-simple:before, .fa-duotone.fa-bus-simple:before {
  content: "\f55e\fe01"; }

.fad.fa-bus-simple:after, .fa-duotone.fa-bus-simple:after {
  content: "\f55e\fe02"; }

.fad.fa-bus-alt:before, .fa-duotone.fa-bus-alt:before {
  content: "\f55e\fe01"; }

.fad.fa-bus-alt:after, .fa-duotone.fa-bus-alt:after {
  content: "\f55e\fe02"; }

.fad.fa-c:before, .fa-duotone.fa-c:before {
  content: "\43\fe01"; }

.fad.fa-c:after, .fa-duotone.fa-c:after {
  content: "\43\fe02"; }

.fad.fa-cabinet-filing:before, .fa-duotone.fa-cabinet-filing:before {
  content: "\f64b"; }

.fad.fa-cabinet-filing:after, .fa-duotone.fa-cabinet-filing:after {
  content: "\10f64b"; }

.fad.fa-cable-car:before, .fa-duotone.fa-cable-car:before {
  content: "\e0cf\fe01"; }

.fad.fa-cable-car:after, .fa-duotone.fa-cable-car:after {
  content: "\e0cf\fe02"; }

.fad.fa-cactus:before, .fa-duotone.fa-cactus:before {
  content: "\f8a7"; }

.fad.fa-cactus:after, .fa-duotone.fa-cactus:after {
  content: "\10f8a7"; }

.fad.fa-cake-candles:before, .fa-duotone.fa-cake-candles:before {
  content: "\f1fd"; }

.fad.fa-cake-candles:after, .fa-duotone.fa-cake-candles:after {
  content: "\10f1fd"; }

.fad.fa-birthday-cake:before, .fa-duotone.fa-birthday-cake:before {
  content: "\f1fd"; }

.fad.fa-birthday-cake:after, .fa-duotone.fa-birthday-cake:after {
  content: "\10f1fd"; }

.fad.fa-calculator:before, .fa-duotone.fa-calculator:before {
  content: "\f1ec"; }

.fad.fa-calculator:after, .fa-duotone.fa-calculator:after {
  content: "\10f1ec"; }

.fad.fa-calculator-simple:before, .fa-duotone.fa-calculator-simple:before {
  content: "\f64c\fe01"; }

.fad.fa-calculator-simple:after, .fa-duotone.fa-calculator-simple:after {
  content: "\f64c\fe02"; }

.fad.fa-calculator-alt:before, .fa-duotone.fa-calculator-alt:before {
  content: "\f64c\fe01"; }

.fad.fa-calculator-alt:after, .fa-duotone.fa-calculator-alt:after {
  content: "\f64c\fe02"; }

.fad.fa-calendar:before, .fa-duotone.fa-calendar:before {
  content: "\f133"; }

.fad.fa-calendar:after, .fa-duotone.fa-calendar:after {
  content: "\10f133"; }

.fad.fa-calendar-arrow-down:before, .fa-duotone.fa-calendar-arrow-down:before {
  content: "\e0d0\fe01"; }

.fad.fa-calendar-arrow-down:after, .fa-duotone.fa-calendar-arrow-down:after {
  content: "\e0d0\fe02"; }

.fad.fa-calendar-download:before, .fa-duotone.fa-calendar-download:before {
  content: "\e0d0\fe01"; }

.fad.fa-calendar-download:after, .fa-duotone.fa-calendar-download:after {
  content: "\e0d0\fe02"; }

.fad.fa-calendar-arrow-up:before, .fa-duotone.fa-calendar-arrow-up:before {
  content: "\e0d1\fe01"; }

.fad.fa-calendar-arrow-up:after, .fa-duotone.fa-calendar-arrow-up:after {
  content: "\e0d1\fe02"; }

.fad.fa-calendar-upload:before, .fa-duotone.fa-calendar-upload:before {
  content: "\e0d1\fe01"; }

.fad.fa-calendar-upload:after, .fa-duotone.fa-calendar-upload:after {
  content: "\e0d1\fe02"; }

.fad.fa-calendar-check:before, .fa-duotone.fa-calendar-check:before {
  content: "\f274\fe01"; }

.fad.fa-calendar-check:after, .fa-duotone.fa-calendar-check:after {
  content: "\f274\fe02"; }

.fad.fa-calendar-clock:before, .fa-duotone.fa-calendar-clock:before {
  content: "\e0d2\fe01"; }

.fad.fa-calendar-clock:after, .fa-duotone.fa-calendar-clock:after {
  content: "\e0d2\fe02"; }

.fad.fa-calendar-time:before, .fa-duotone.fa-calendar-time:before {
  content: "\e0d2\fe01"; }

.fad.fa-calendar-time:after, .fa-duotone.fa-calendar-time:after {
  content: "\e0d2\fe02"; }

.fad.fa-calendar-day:before, .fa-duotone.fa-calendar-day:before {
  content: "\f783\fe01"; }

.fad.fa-calendar-day:after, .fa-duotone.fa-calendar-day:after {
  content: "\f783\fe02"; }

.fad.fa-calendar-days:before, .fa-duotone.fa-calendar-days:before {
  content: "\f073\fe01"; }

.fad.fa-calendar-days:after, .fa-duotone.fa-calendar-days:after {
  content: "\f073\fe02"; }

.fad.fa-calendar-alt:before, .fa-duotone.fa-calendar-alt:before {
  content: "\f073\fe01"; }

.fad.fa-calendar-alt:after, .fa-duotone.fa-calendar-alt:after {
  content: "\f073\fe02"; }

.fad.fa-calendar-exclamation:before, .fa-duotone.fa-calendar-exclamation:before {
  content: "\f334\fe01"; }

.fad.fa-calendar-exclamation:after, .fa-duotone.fa-calendar-exclamation:after {
  content: "\f334\fe02"; }

.fad.fa-calendar-heart:before, .fa-duotone.fa-calendar-heart:before {
  content: "\e0d3\fe01"; }

.fad.fa-calendar-heart:after, .fa-duotone.fa-calendar-heart:after {
  content: "\e0d3\fe02"; }

.fad.fa-calendar-image:before, .fa-duotone.fa-calendar-image:before {
  content: "\e0d4\fe01"; }

.fad.fa-calendar-image:after, .fa-duotone.fa-calendar-image:after {
  content: "\e0d4\fe02"; }

.fad.fa-calendar-lines:before, .fa-duotone.fa-calendar-lines:before {
  content: "\e0d5\fe01"; }

.fad.fa-calendar-lines:after, .fa-duotone.fa-calendar-lines:after {
  content: "\e0d5\fe02"; }

.fad.fa-calendar-note:before, .fa-duotone.fa-calendar-note:before {
  content: "\e0d5\fe01"; }

.fad.fa-calendar-note:after, .fa-duotone.fa-calendar-note:after {
  content: "\e0d5\fe02"; }

.fad.fa-calendar-minus:before, .fa-duotone.fa-calendar-minus:before {
  content: "\f272\fe01"; }

.fad.fa-calendar-minus:after, .fa-duotone.fa-calendar-minus:after {
  content: "\f272\fe02"; }

.fad.fa-calendar-pen:before, .fa-duotone.fa-calendar-pen:before {
  content: "\f333\fe01"; }

.fad.fa-calendar-pen:after, .fa-duotone.fa-calendar-pen:after {
  content: "\f333\fe02"; }

.fad.fa-calendar-edit:before, .fa-duotone.fa-calendar-edit:before {
  content: "\f333\fe01"; }

.fad.fa-calendar-edit:after, .fa-duotone.fa-calendar-edit:after {
  content: "\f333\fe02"; }

.fad.fa-calendar-plus:before, .fa-duotone.fa-calendar-plus:before {
  content: "\f271\fe01"; }

.fad.fa-calendar-plus:after, .fa-duotone.fa-calendar-plus:after {
  content: "\f271\fe02"; }

.fad.fa-calendar-range:before, .fa-duotone.fa-calendar-range:before {
  content: "\e0d6\fe01"; }

.fad.fa-calendar-range:after, .fa-duotone.fa-calendar-range:after {
  content: "\e0d6\fe02"; }

.fad.fa-calendar-star:before, .fa-duotone.fa-calendar-star:before {
  content: "\f736\fe01"; }

.fad.fa-calendar-star:after, .fa-duotone.fa-calendar-star:after {
  content: "\f736\fe02"; }

.fad.fa-calendar-week:before, .fa-duotone.fa-calendar-week:before {
  content: "\f784\fe01"; }

.fad.fa-calendar-week:after, .fa-duotone.fa-calendar-week:after {
  content: "\f784\fe02"; }

.fad.fa-calendar-xmark:before, .fa-duotone.fa-calendar-xmark:before {
  content: "\f273\fe01"; }

.fad.fa-calendar-xmark:after, .fa-duotone.fa-calendar-xmark:after {
  content: "\f273\fe02"; }

.fad.fa-calendar-times:before, .fa-duotone.fa-calendar-times:before {
  content: "\f273\fe01"; }

.fad.fa-calendar-times:after, .fa-duotone.fa-calendar-times:after {
  content: "\f273\fe02"; }

.fad.fa-calendars:before, .fa-duotone.fa-calendars:before {
  content: "\e0d7\fe01"; }

.fad.fa-calendars:after, .fa-duotone.fa-calendars:after {
  content: "\e0d7\fe02"; }

.fad.fa-camcorder:before, .fa-duotone.fa-camcorder:before {
  content: "\f8a8"; }

.fad.fa-camcorder:after, .fa-duotone.fa-camcorder:after {
  content: "\10f8a8"; }

.fad.fa-video-handheld:before, .fa-duotone.fa-video-handheld:before {
  content: "\f8a8"; }

.fad.fa-video-handheld:after, .fa-duotone.fa-video-handheld:after {
  content: "\10f8a8"; }

.fad.fa-camera:before, .fa-duotone.fa-camera:before {
  content: "\f030\fe01"; }

.fad.fa-camera:after, .fa-duotone.fa-camera:after {
  content: "\f030\fe02"; }

.fad.fa-camera-alt:before, .fa-duotone.fa-camera-alt:before {
  content: "\f030\fe01"; }

.fad.fa-camera-alt:after, .fa-duotone.fa-camera-alt:after {
  content: "\f030\fe02"; }

.fad.fa-camera-cctv:before, .fa-duotone.fa-camera-cctv:before {
  content: "\f8ac\fe01"; }

.fad.fa-camera-cctv:after, .fa-duotone.fa-camera-cctv:after {
  content: "\f8ac\fe02"; }

.fad.fa-cctv:before, .fa-duotone.fa-cctv:before {
  content: "\f8ac\fe01"; }

.fad.fa-cctv:after, .fa-duotone.fa-cctv:after {
  content: "\f8ac\fe02"; }

.fad.fa-camera-movie:before, .fa-duotone.fa-camera-movie:before {
  content: "\f8a9"; }

.fad.fa-camera-movie:after, .fa-duotone.fa-camera-movie:after {
  content: "\10f8a9"; }

.fad.fa-camera-polaroid:before, .fa-duotone.fa-camera-polaroid:before {
  content: "\f8aa\fe01"; }

.fad.fa-camera-polaroid:after, .fa-duotone.fa-camera-polaroid:after {
  content: "\f8aa\fe02"; }

.fad.fa-camera-retro:before, .fa-duotone.fa-camera-retro:before {
  content: "\f083"; }

.fad.fa-camera-retro:after, .fa-duotone.fa-camera-retro:after {
  content: "\10f083"; }

.fad.fa-camera-rotate:before, .fa-duotone.fa-camera-rotate:before {
  content: "\e0d8\fe01"; }

.fad.fa-camera-rotate:after, .fa-duotone.fa-camera-rotate:after {
  content: "\e0d8\fe02"; }

.fad.fa-camera-security:before, .fa-duotone.fa-camera-security:before {
  content: "\f8fe\fe01"; }

.fad.fa-camera-security:after, .fa-duotone.fa-camera-security:after {
  content: "\f8fe\fe02"; }

.fad.fa-camera-home:before, .fa-duotone.fa-camera-home:before {
  content: "\f8fe\fe01"; }

.fad.fa-camera-home:after, .fa-duotone.fa-camera-home:after {
  content: "\f8fe\fe02"; }

.fad.fa-camera-slash:before, .fa-duotone.fa-camera-slash:before {
  content: "\e0d9\fe01"; }

.fad.fa-camera-slash:after, .fa-duotone.fa-camera-slash:after {
  content: "\e0d9\fe02"; }

.fad.fa-camera-viewfinder:before, .fa-duotone.fa-camera-viewfinder:before {
  content: "\e0da\fe01"; }

.fad.fa-camera-viewfinder:after, .fa-duotone.fa-camera-viewfinder:after {
  content: "\e0da\fe02"; }

.fad.fa-camera-web:before, .fa-duotone.fa-camera-web:before {
  content: "\f832\fe01"; }

.fad.fa-camera-web:after, .fa-duotone.fa-camera-web:after {
  content: "\f832\fe02"; }

.fad.fa-webcam:before, .fa-duotone.fa-webcam:before {
  content: "\f832\fe01"; }

.fad.fa-webcam:after, .fa-duotone.fa-webcam:after {
  content: "\f832\fe02"; }

.fad.fa-camera-web-slash:before, .fa-duotone.fa-camera-web-slash:before {
  content: "\f833\fe01"; }

.fad.fa-camera-web-slash:after, .fa-duotone.fa-camera-web-slash:after {
  content: "\f833\fe02"; }

.fad.fa-webcam-slash:before, .fa-duotone.fa-webcam-slash:before {
  content: "\f833\fe01"; }

.fad.fa-webcam-slash:after, .fa-duotone.fa-webcam-slash:after {
  content: "\f833\fe02"; }

.fad.fa-campfire:before, .fa-duotone.fa-campfire:before {
  content: "\f6ba\fe01"; }

.fad.fa-campfire:after, .fa-duotone.fa-campfire:after {
  content: "\f6ba\fe02"; }

.fad.fa-campground:before, .fa-duotone.fa-campground:before {
  content: "\26fa\fe01"; }

.fad.fa-campground:after, .fa-duotone.fa-campground:after {
  content: "\26fa\fe02"; }

.fad.fa-candle-holder:before, .fa-duotone.fa-candle-holder:before {
  content: "\f6bc"; }

.fad.fa-candle-holder:after, .fa-duotone.fa-candle-holder:after {
  content: "\10f6bc"; }

.fad.fa-candy-cane:before, .fa-duotone.fa-candy-cane:before {
  content: "\f786\fe01"; }

.fad.fa-candy-cane:after, .fa-duotone.fa-candy-cane:after {
  content: "\f786\fe02"; }

.fad.fa-candy-corn:before, .fa-duotone.fa-candy-corn:before {
  content: "\f6bd\fe01"; }

.fad.fa-candy-corn:after, .fa-duotone.fa-candy-corn:after {
  content: "\f6bd\fe02"; }

.fad.fa-cannabis:before, .fa-duotone.fa-cannabis:before {
  content: "\f55f\fe01"; }

.fad.fa-cannabis:after, .fa-duotone.fa-cannabis:after {
  content: "\f55f\fe02"; }

.fad.fa-capsules:before, .fa-duotone.fa-capsules:before {
  content: "\f46b\fe01"; }

.fad.fa-capsules:after, .fa-duotone.fa-capsules:after {
  content: "\f46b\fe02"; }

.fad.fa-car:before, .fa-duotone.fa-car:before {
  content: "\f1b9"; }

.fad.fa-car:after, .fa-duotone.fa-car:after {
  content: "\10f1b9"; }

.fad.fa-automobile:before, .fa-duotone.fa-automobile:before {
  content: "\f1b9"; }

.fad.fa-automobile:after, .fa-duotone.fa-automobile:after {
  content: "\10f1b9"; }

.fad.fa-car-battery:before, .fa-duotone.fa-car-battery:before {
  content: "\f5df\fe01"; }

.fad.fa-car-battery:after, .fa-duotone.fa-car-battery:after {
  content: "\f5df\fe02"; }

.fad.fa-battery-car:before, .fa-duotone.fa-battery-car:before {
  content: "\f5df\fe01"; }

.fad.fa-battery-car:after, .fa-duotone.fa-battery-car:after {
  content: "\f5df\fe02"; }

.fad.fa-car-bolt:before, .fa-duotone.fa-car-bolt:before {
  content: "\e341\fe01"; }

.fad.fa-car-bolt:after, .fa-duotone.fa-car-bolt:after {
  content: "\e341\fe02"; }

.fad.fa-car-building:before, .fa-duotone.fa-car-building:before {
  content: "\f859\fe01"; }

.fad.fa-car-building:after, .fa-duotone.fa-car-building:after {
  content: "\f859\fe02"; }

.fad.fa-car-bump:before, .fa-duotone.fa-car-bump:before {
  content: "\f5e0\fe01"; }

.fad.fa-car-bump:after, .fa-duotone.fa-car-bump:after {
  content: "\f5e0\fe02"; }

.fad.fa-car-bus:before, .fa-duotone.fa-car-bus:before {
  content: "\f85a\fe01"; }

.fad.fa-car-bus:after, .fa-duotone.fa-car-bus:after {
  content: "\f85a\fe02"; }

.fad.fa-car-circle-bolt:before, .fa-duotone.fa-car-circle-bolt:before {
  content: "\e342\fe01"; }

.fad.fa-car-circle-bolt:after, .fa-duotone.fa-car-circle-bolt:after {
  content: "\e342\fe02"; }

.fad.fa-car-crash:before, .fa-duotone.fa-car-crash:before {
  content: "\f5e1\fe01"; }

.fad.fa-car-crash:after, .fa-duotone.fa-car-crash:after {
  content: "\f5e1\fe02"; }

.fad.fa-car-garage:before, .fa-duotone.fa-car-garage:before {
  content: "\f5e2\fe01"; }

.fad.fa-car-garage:after, .fa-duotone.fa-car-garage:after {
  content: "\f5e2\fe02"; }

.fad.fa-car-mirrors:before, .fa-duotone.fa-car-mirrors:before {
  content: "\e343\fe01"; }

.fad.fa-car-mirrors:after, .fa-duotone.fa-car-mirrors:after {
  content: "\e343\fe02"; }

.fad.fa-car-rear:before, .fa-duotone.fa-car-rear:before {
  content: "\f5de\fe01"; }

.fad.fa-car-rear:after, .fa-duotone.fa-car-rear:after {
  content: "\f5de\fe02"; }

.fad.fa-car-alt:before, .fa-duotone.fa-car-alt:before {
  content: "\f5de\fe01"; }

.fad.fa-car-alt:after, .fa-duotone.fa-car-alt:after {
  content: "\f5de\fe02"; }

.fad.fa-car-side:before, .fa-duotone.fa-car-side:before {
  content: "\f5e4"; }

.fad.fa-car-side:after, .fa-duotone.fa-car-side:after {
  content: "\10f5e4"; }

.fad.fa-car-side-bolt:before, .fa-duotone.fa-car-side-bolt:before {
  content: "\e344\fe01"; }

.fad.fa-car-side-bolt:after, .fa-duotone.fa-car-side-bolt:after {
  content: "\e344\fe02"; }

.fad.fa-car-tilt:before, .fa-duotone.fa-car-tilt:before {
  content: "\f5e5\fe01"; }

.fad.fa-car-tilt:after, .fa-duotone.fa-car-tilt:after {
  content: "\f5e5\fe02"; }

.fad.fa-car-wash:before, .fa-duotone.fa-car-wash:before {
  content: "\f5e6\fe01"; }

.fad.fa-car-wash:after, .fa-duotone.fa-car-wash:after {
  content: "\f5e6\fe02"; }

.fad.fa-car-wrench:before, .fa-duotone.fa-car-wrench:before {
  content: "\f5e3\fe01"; }

.fad.fa-car-wrench:after, .fa-duotone.fa-car-wrench:after {
  content: "\f5e3\fe02"; }

.fad.fa-car-mechanic:before, .fa-duotone.fa-car-mechanic:before {
  content: "\f5e3\fe01"; }

.fad.fa-car-mechanic:after, .fa-duotone.fa-car-mechanic:after {
  content: "\f5e3\fe02"; }

.fad.fa-caravan:before, .fa-duotone.fa-caravan:before {
  content: "\f8ff\fe01"; }

.fad.fa-caravan:after, .fa-duotone.fa-caravan:after {
  content: "\f8ff\fe02"; }

.fad.fa-caravan-simple:before, .fa-duotone.fa-caravan-simple:before {
  content: "\e000\fe01"; }

.fad.fa-caravan-simple:after, .fa-duotone.fa-caravan-simple:after {
  content: "\e000\fe02"; }

.fad.fa-caravan-alt:before, .fa-duotone.fa-caravan-alt:before {
  content: "\e000\fe01"; }

.fad.fa-caravan-alt:after, .fa-duotone.fa-caravan-alt:after {
  content: "\e000\fe02"; }

.fad.fa-caret-down:before, .fa-duotone.fa-caret-down:before {
  content: "\f0d7\fe01"; }

.fad.fa-caret-down:after, .fa-duotone.fa-caret-down:after {
  content: "\f0d7\fe02"; }

.fad.fa-caret-left:before, .fa-duotone.fa-caret-left:before {
  content: "\f0d9\fe01"; }

.fad.fa-caret-left:after, .fa-duotone.fa-caret-left:after {
  content: "\f0d9\fe02"; }

.fad.fa-caret-right:before, .fa-duotone.fa-caret-right:before {
  content: "\f0da\fe01"; }

.fad.fa-caret-right:after, .fa-duotone.fa-caret-right:after {
  content: "\f0da\fe02"; }

.fad.fa-caret-up:before, .fa-duotone.fa-caret-up:before {
  content: "\f0d8\fe01"; }

.fad.fa-caret-up:after, .fa-duotone.fa-caret-up:after {
  content: "\f0d8\fe02"; }

.fad.fa-carrot:before, .fa-duotone.fa-carrot:before {
  content: "\f787"; }

.fad.fa-carrot:after, .fa-duotone.fa-carrot:after {
  content: "\10f787"; }

.fad.fa-cars:before, .fa-duotone.fa-cars:before {
  content: "\f85b\fe01"; }

.fad.fa-cars:after, .fa-duotone.fa-cars:after {
  content: "\f85b\fe02"; }

.fad.fa-cart-arrow-down:before, .fa-duotone.fa-cart-arrow-down:before {
  content: "\f218\fe01"; }

.fad.fa-cart-arrow-down:after, .fa-duotone.fa-cart-arrow-down:after {
  content: "\f218\fe02"; }

.fad.fa-cart-flatbed:before, .fa-duotone.fa-cart-flatbed:before {
  content: "\f474\fe01"; }

.fad.fa-cart-flatbed:after, .fa-duotone.fa-cart-flatbed:after {
  content: "\f474\fe02"; }

.fad.fa-dolly-flatbed:before, .fa-duotone.fa-dolly-flatbed:before {
  content: "\f474\fe01"; }

.fad.fa-dolly-flatbed:after, .fa-duotone.fa-dolly-flatbed:after {
  content: "\f474\fe02"; }

.fad.fa-cart-flatbed-boxes:before, .fa-duotone.fa-cart-flatbed-boxes:before {
  content: "\f475\fe01"; }

.fad.fa-cart-flatbed-boxes:after, .fa-duotone.fa-cart-flatbed-boxes:after {
  content: "\f475\fe02"; }

.fad.fa-dolly-flatbed-alt:before, .fa-duotone.fa-dolly-flatbed-alt:before {
  content: "\f475\fe01"; }

.fad.fa-dolly-flatbed-alt:after, .fa-duotone.fa-dolly-flatbed-alt:after {
  content: "\f475\fe02"; }

.fad.fa-cart-flatbed-empty:before, .fa-duotone.fa-cart-flatbed-empty:before {
  content: "\f476\fe01"; }

.fad.fa-cart-flatbed-empty:after, .fa-duotone.fa-cart-flatbed-empty:after {
  content: "\f476\fe02"; }

.fad.fa-dolly-flatbed-empty:before, .fa-duotone.fa-dolly-flatbed-empty:before {
  content: "\f476\fe01"; }

.fad.fa-dolly-flatbed-empty:after, .fa-duotone.fa-dolly-flatbed-empty:after {
  content: "\f476\fe02"; }

.fad.fa-cart-flatbed-suitcase:before, .fa-duotone.fa-cart-flatbed-suitcase:before {
  content: "\f59d\fe01"; }

.fad.fa-cart-flatbed-suitcase:after, .fa-duotone.fa-cart-flatbed-suitcase:after {
  content: "\f59d\fe02"; }

.fad.fa-luggage-cart:before, .fa-duotone.fa-luggage-cart:before {
  content: "\f59d\fe01"; }

.fad.fa-luggage-cart:after, .fa-duotone.fa-luggage-cart:after {
  content: "\f59d\fe02"; }

.fad.fa-cart-minus:before, .fa-duotone.fa-cart-minus:before {
  content: "\e0db\fe01"; }

.fad.fa-cart-minus:after, .fa-duotone.fa-cart-minus:after {
  content: "\e0db\fe02"; }

.fad.fa-cart-plus:before, .fa-duotone.fa-cart-plus:before {
  content: "\f217\fe01"; }

.fad.fa-cart-plus:after, .fa-duotone.fa-cart-plus:after {
  content: "\f217\fe02"; }

.fad.fa-cart-shopping:before, .fa-duotone.fa-cart-shopping:before {
  content: "\f07a"; }

.fad.fa-cart-shopping:after, .fa-duotone.fa-cart-shopping:after {
  content: "\10f07a"; }

.fad.fa-shopping-cart:before, .fa-duotone.fa-shopping-cart:before {
  content: "\f07a"; }

.fad.fa-shopping-cart:after, .fa-duotone.fa-shopping-cart:after {
  content: "\10f07a"; }

.fad.fa-cart-shopping-fast:before, .fa-duotone.fa-cart-shopping-fast:before {
  content: "\e0dc\fe01"; }

.fad.fa-cart-shopping-fast:after, .fa-duotone.fa-cart-shopping-fast:after {
  content: "\e0dc\fe02"; }

.fad.fa-cart-xmark:before, .fa-duotone.fa-cart-xmark:before {
  content: "\e0dd\fe01"; }

.fad.fa-cart-xmark:after, .fa-duotone.fa-cart-xmark:after {
  content: "\e0dd\fe02"; }

.fad.fa-cash-register:before, .fa-duotone.fa-cash-register:before {
  content: "\f788\fe01"; }

.fad.fa-cash-register:after, .fa-duotone.fa-cash-register:after {
  content: "\f788\fe02"; }

.fad.fa-cassette-betamax:before, .fa-duotone.fa-cassette-betamax:before {
  content: "\f8a4\fe01"; }

.fad.fa-cassette-betamax:after, .fa-duotone.fa-cassette-betamax:after {
  content: "\f8a4\fe02"; }

.fad.fa-betamax:before, .fa-duotone.fa-betamax:before {
  content: "\f8a4\fe01"; }

.fad.fa-betamax:after, .fa-duotone.fa-betamax:after {
  content: "\f8a4\fe02"; }

.fad.fa-cassette-tape:before, .fa-duotone.fa-cassette-tape:before {
  content: "\f8ab"; }

.fad.fa-cassette-tape:after, .fa-duotone.fa-cassette-tape:after {
  content: "\10f8ab"; }

.fad.fa-cassette-vhs:before, .fa-duotone.fa-cassette-vhs:before {
  content: "\f8ec"; }

.fad.fa-cassette-vhs:after, .fa-duotone.fa-cassette-vhs:after {
  content: "\10f8ec"; }

.fad.fa-vhs:before, .fa-duotone.fa-vhs:before {
  content: "\f8ec"; }

.fad.fa-vhs:after, .fa-duotone.fa-vhs:after {
  content: "\10f8ec"; }

.fad.fa-castle:before, .fa-duotone.fa-castle:before {
  content: "\e0de\fe01"; }

.fad.fa-castle:after, .fa-duotone.fa-castle:after {
  content: "\e0de\fe02"; }

.fad.fa-cat:before, .fa-duotone.fa-cat:before {
  content: "\f6be"; }

.fad.fa-cat:after, .fa-duotone.fa-cat:after {
  content: "\10f6be"; }

.fad.fa-cat-space:before, .fa-duotone.fa-cat-space:before {
  content: "\e001\fe01"; }

.fad.fa-cat-space:after, .fa-duotone.fa-cat-space:after {
  content: "\e001\fe02"; }

.fad.fa-cauldron:before, .fa-duotone.fa-cauldron:before {
  content: "\f6bf\fe01"; }

.fad.fa-cauldron:after, .fa-duotone.fa-cauldron:after {
  content: "\f6bf\fe02"; }

.fad.fa-cedi-sign:before, .fa-duotone.fa-cedi-sign:before {
  content: "\e0df\fe01"; }

.fad.fa-cedi-sign:after, .fa-duotone.fa-cedi-sign:after {
  content: "\e0df\fe02"; }

.fad.fa-cent-sign:before, .fa-duotone.fa-cent-sign:before {
  content: "\a2\fe01"; }

.fad.fa-cent-sign:after, .fa-duotone.fa-cent-sign:after {
  content: "\a2\fe02"; }

.fad.fa-certificate:before, .fa-duotone.fa-certificate:before {
  content: "\f0a3\fe01"; }

.fad.fa-certificate:after, .fa-duotone.fa-certificate:after {
  content: "\f0a3\fe02"; }

.fad.fa-chair:before, .fa-duotone.fa-chair:before {
  content: "\f6c0"; }

.fad.fa-chair:after, .fa-duotone.fa-chair:after {
  content: "\10f6c0"; }

.fad.fa-chair-office:before, .fa-duotone.fa-chair-office:before {
  content: "\f6c1\fe01"; }

.fad.fa-chair-office:after, .fa-duotone.fa-chair-office:after {
  content: "\f6c1\fe02"; }

.fad.fa-chalkboard:before, .fa-duotone.fa-chalkboard:before {
  content: "\f51b\fe01"; }

.fad.fa-chalkboard:after, .fa-duotone.fa-chalkboard:after {
  content: "\f51b\fe02"; }

.fad.fa-blackboard:before, .fa-duotone.fa-blackboard:before {
  content: "\f51b\fe01"; }

.fad.fa-blackboard:after, .fa-duotone.fa-blackboard:after {
  content: "\f51b\fe02"; }

.fad.fa-chalkboard-user:before, .fa-duotone.fa-chalkboard-user:before {
  content: "\f51c\fe01"; }

.fad.fa-chalkboard-user:after, .fa-duotone.fa-chalkboard-user:after {
  content: "\f51c\fe02"; }

.fad.fa-chalkboard-teacher:before, .fa-duotone.fa-chalkboard-teacher:before {
  content: "\f51c\fe01"; }

.fad.fa-chalkboard-teacher:after, .fa-duotone.fa-chalkboard-teacher:after {
  content: "\f51c\fe02"; }

.fad.fa-champagne-glass:before, .fa-duotone.fa-champagne-glass:before {
  content: "\f79e\fe01"; }

.fad.fa-champagne-glass:after, .fa-duotone.fa-champagne-glass:after {
  content: "\f79e\fe02"; }

.fad.fa-glass-champagne:before, .fa-duotone.fa-glass-champagne:before {
  content: "\f79e\fe01"; }

.fad.fa-glass-champagne:after, .fa-duotone.fa-glass-champagne:after {
  content: "\f79e\fe02"; }

.fad.fa-champagne-glasses:before, .fa-duotone.fa-champagne-glasses:before {
  content: "\f79f"; }

.fad.fa-champagne-glasses:after, .fa-duotone.fa-champagne-glasses:after {
  content: "\10f79f"; }

.fad.fa-glass-cheers:before, .fa-duotone.fa-glass-cheers:before {
  content: "\f79f"; }

.fad.fa-glass-cheers:after, .fa-duotone.fa-glass-cheers:after {
  content: "\10f79f"; }

.fad.fa-charging-station:before, .fa-duotone.fa-charging-station:before {
  content: "\f5e7\fe01"; }

.fad.fa-charging-station:after, .fa-duotone.fa-charging-station:after {
  content: "\f5e7\fe02"; }

.fad.fa-chart-area:before, .fa-duotone.fa-chart-area:before {
  content: "\f1fe\fe01"; }

.fad.fa-chart-area:after, .fa-duotone.fa-chart-area:after {
  content: "\f1fe\fe02"; }

.fad.fa-area-chart:before, .fa-duotone.fa-area-chart:before {
  content: "\f1fe\fe01"; }

.fad.fa-area-chart:after, .fa-duotone.fa-area-chart:after {
  content: "\f1fe\fe02"; }

.fad.fa-chart-bar:before, .fa-duotone.fa-chart-bar:before {
  content: "\f080\fe01"; }

.fad.fa-chart-bar:after, .fa-duotone.fa-chart-bar:after {
  content: "\f080\fe02"; }

.fad.fa-bar-chart:before, .fa-duotone.fa-bar-chart:before {
  content: "\f080\fe01"; }

.fad.fa-bar-chart:after, .fa-duotone.fa-bar-chart:after {
  content: "\f080\fe02"; }

.fad.fa-chart-bullet:before, .fa-duotone.fa-chart-bullet:before {
  content: "\e0e1\fe01"; }

.fad.fa-chart-bullet:after, .fa-duotone.fa-chart-bullet:after {
  content: "\e0e1\fe02"; }

.fad.fa-chart-candlestick:before, .fa-duotone.fa-chart-candlestick:before {
  content: "\e0e2\fe01"; }

.fad.fa-chart-candlestick:after, .fa-duotone.fa-chart-candlestick:after {
  content: "\e0e2\fe02"; }

.fad.fa-chart-column:before, .fa-duotone.fa-chart-column:before {
  content: "\e0e3\fe01"; }

.fad.fa-chart-column:after, .fa-duotone.fa-chart-column:after {
  content: "\e0e3\fe02"; }

.fad.fa-chart-gantt:before, .fa-duotone.fa-chart-gantt:before {
  content: "\e0e4\fe01"; }

.fad.fa-chart-gantt:after, .fa-duotone.fa-chart-gantt:after {
  content: "\e0e4\fe02"; }

.fad.fa-chart-line:before, .fa-duotone.fa-chart-line:before {
  content: "\f201\fe01"; }

.fad.fa-chart-line:after, .fa-duotone.fa-chart-line:after {
  content: "\f201\fe02"; }

.fad.fa-line-chart:before, .fa-duotone.fa-line-chart:before {
  content: "\f201\fe01"; }

.fad.fa-line-chart:after, .fa-duotone.fa-line-chart:after {
  content: "\f201\fe02"; }

.fad.fa-chart-line-down:before, .fa-duotone.fa-chart-line-down:before {
  content: "\f64d"; }

.fad.fa-chart-line-down:after, .fa-duotone.fa-chart-line-down:after {
  content: "\10f64d"; }

.fad.fa-chart-line-up:before, .fa-duotone.fa-chart-line-up:before {
  content: "\e0e5\fe01"; }

.fad.fa-chart-line-up:after, .fa-duotone.fa-chart-line-up:after {
  content: "\e0e5\fe02"; }

.fad.fa-chart-mixed:before, .fa-duotone.fa-chart-mixed:before {
  content: "\f643\fe01"; }

.fad.fa-chart-mixed:after, .fa-duotone.fa-chart-mixed:after {
  content: "\f643\fe02"; }

.fad.fa-analytics:before, .fa-duotone.fa-analytics:before {
  content: "\f643\fe01"; }

.fad.fa-analytics:after, .fa-duotone.fa-analytics:after {
  content: "\f643\fe02"; }

.fad.fa-chart-network:before, .fa-duotone.fa-chart-network:before {
  content: "\f78a\fe01"; }

.fad.fa-chart-network:after, .fa-duotone.fa-chart-network:after {
  content: "\f78a\fe02"; }

.fad.fa-chart-pie:before, .fa-duotone.fa-chart-pie:before {
  content: "\f200\fe01"; }

.fad.fa-chart-pie:after, .fa-duotone.fa-chart-pie:after {
  content: "\f200\fe02"; }

.fad.fa-pie-chart:before, .fa-duotone.fa-pie-chart:before {
  content: "\f200\fe01"; }

.fad.fa-pie-chart:after, .fa-duotone.fa-pie-chart:after {
  content: "\f200\fe02"; }

.fad.fa-chart-pie-simple:before, .fa-duotone.fa-chart-pie-simple:before {
  content: "\f64e\fe01"; }

.fad.fa-chart-pie-simple:after, .fa-duotone.fa-chart-pie-simple:after {
  content: "\f64e\fe02"; }

.fad.fa-chart-pie-alt:before, .fa-duotone.fa-chart-pie-alt:before {
  content: "\f64e\fe01"; }

.fad.fa-chart-pie-alt:after, .fa-duotone.fa-chart-pie-alt:after {
  content: "\f64e\fe02"; }

.fad.fa-chart-pyramid:before, .fa-duotone.fa-chart-pyramid:before {
  content: "\e0e6\fe01"; }

.fad.fa-chart-pyramid:after, .fa-duotone.fa-chart-pyramid:after {
  content: "\e0e6\fe02"; }

.fad.fa-chart-radar:before, .fa-duotone.fa-chart-radar:before {
  content: "\e0e7\fe01"; }

.fad.fa-chart-radar:after, .fa-duotone.fa-chart-radar:after {
  content: "\e0e7\fe02"; }

.fad.fa-chart-scatter:before, .fa-duotone.fa-chart-scatter:before {
  content: "\f7ee\fe01"; }

.fad.fa-chart-scatter:after, .fa-duotone.fa-chart-scatter:after {
  content: "\f7ee\fe02"; }

.fad.fa-chart-scatter-3d:before, .fa-duotone.fa-chart-scatter-3d:before {
  content: "\e0e8\fe01"; }

.fad.fa-chart-scatter-3d:after, .fa-duotone.fa-chart-scatter-3d:after {
  content: "\e0e8\fe02"; }

.fad.fa-chart-scatter-bubble:before, .fa-duotone.fa-chart-scatter-bubble:before {
  content: "\e0e9\fe01"; }

.fad.fa-chart-scatter-bubble:after, .fa-duotone.fa-chart-scatter-bubble:after {
  content: "\e0e9\fe02"; }

.fad.fa-chart-tree-map:before, .fa-duotone.fa-chart-tree-map:before {
  content: "\e0ea\fe01"; }

.fad.fa-chart-tree-map:after, .fa-duotone.fa-chart-tree-map:after {
  content: "\e0ea\fe02"; }

.fad.fa-chart-user:before, .fa-duotone.fa-chart-user:before {
  content: "\f6a3\fe01"; }

.fad.fa-chart-user:after, .fa-duotone.fa-chart-user:after {
  content: "\f6a3\fe02"; }

.fad.fa-user-chart:before, .fa-duotone.fa-user-chart:before {
  content: "\f6a3\fe01"; }

.fad.fa-user-chart:after, .fa-duotone.fa-user-chart:after {
  content: "\f6a3\fe02"; }

.fad.fa-chart-waterfall:before, .fa-duotone.fa-chart-waterfall:before {
  content: "\e0eb\fe01"; }

.fad.fa-chart-waterfall:after, .fa-duotone.fa-chart-waterfall:after {
  content: "\e0eb\fe02"; }

.fad.fa-check:before, .fa-duotone.fa-check:before {
  content: "\2713\fe01"; }

.fad.fa-check:after, .fa-duotone.fa-check:after {
  content: "\2713\fe02"; }

.fad.fa-check-double:before, .fa-duotone.fa-check-double:before {
  content: "\f560\fe01"; }

.fad.fa-check-double:after, .fa-duotone.fa-check-double:after {
  content: "\f560\fe02"; }

.fad.fa-check-to-slot:before, .fa-duotone.fa-check-to-slot:before {
  content: "\f772\fe01"; }

.fad.fa-check-to-slot:after, .fa-duotone.fa-check-to-slot:after {
  content: "\f772\fe02"; }

.fad.fa-vote-yea:before, .fa-duotone.fa-vote-yea:before {
  content: "\f772\fe01"; }

.fad.fa-vote-yea:after, .fa-duotone.fa-vote-yea:after {
  content: "\f772\fe02"; }

.fad.fa-cheese:before, .fa-duotone.fa-cheese:before {
  content: "\f7ef\fe01"; }

.fad.fa-cheese:after, .fa-duotone.fa-cheese:after {
  content: "\f7ef\fe02"; }

.fad.fa-cheese-swiss:before, .fa-duotone.fa-cheese-swiss:before {
  content: "\f7f0"; }

.fad.fa-cheese-swiss:after, .fa-duotone.fa-cheese-swiss:after {
  content: "\10f7f0"; }

.fad.fa-cherries:before, .fa-duotone.fa-cherries:before {
  content: "\e0ec\fe01"; }

.fad.fa-cherries:after, .fa-duotone.fa-cherries:after {
  content: "\e0ec\fe02"; }

.fad.fa-chess:before, .fa-duotone.fa-chess:before {
  content: "\f439\fe01"; }

.fad.fa-chess:after, .fa-duotone.fa-chess:after {
  content: "\f439\fe02"; }

.fad.fa-chess-bishop:before, .fa-duotone.fa-chess-bishop:before {
  content: "\265d\fe01"; }

.fad.fa-chess-bishop:after, .fa-duotone.fa-chess-bishop:after {
  content: "\265d\fe02"; }

.fad.fa-chess-bishop-piece:before, .fa-duotone.fa-chess-bishop-piece:before {
  content: "\f43b\fe01"; }

.fad.fa-chess-bishop-piece:after, .fa-duotone.fa-chess-bishop-piece:after {
  content: "\f43b\fe02"; }

.fad.fa-chess-bishop-alt:before, .fa-duotone.fa-chess-bishop-alt:before {
  content: "\f43b\fe01"; }

.fad.fa-chess-bishop-alt:after, .fa-duotone.fa-chess-bishop-alt:after {
  content: "\f43b\fe02"; }

.fad.fa-chess-board:before, .fa-duotone.fa-chess-board:before {
  content: "\f43c\fe01"; }

.fad.fa-chess-board:after, .fa-duotone.fa-chess-board:after {
  content: "\f43c\fe02"; }

.fad.fa-chess-clock:before, .fa-duotone.fa-chess-clock:before {
  content: "\f43d\fe01"; }

.fad.fa-chess-clock:after, .fa-duotone.fa-chess-clock:after {
  content: "\f43d\fe02"; }

.fad.fa-chess-clock-flip:before, .fa-duotone.fa-chess-clock-flip:before {
  content: "\f43e\fe01"; }

.fad.fa-chess-clock-flip:after, .fa-duotone.fa-chess-clock-flip:after {
  content: "\f43e\fe02"; }

.fad.fa-chess-clock-alt:before, .fa-duotone.fa-chess-clock-alt:before {
  content: "\f43e\fe01"; }

.fad.fa-chess-clock-alt:after, .fa-duotone.fa-chess-clock-alt:after {
  content: "\f43e\fe02"; }

.fad.fa-chess-king:before, .fa-duotone.fa-chess-king:before {
  content: "\265a\fe01"; }

.fad.fa-chess-king:after, .fa-duotone.fa-chess-king:after {
  content: "\265a\fe02"; }

.fad.fa-chess-king-piece:before, .fa-duotone.fa-chess-king-piece:before {
  content: "\f440\fe01"; }

.fad.fa-chess-king-piece:after, .fa-duotone.fa-chess-king-piece:after {
  content: "\f440\fe02"; }

.fad.fa-chess-king-alt:before, .fa-duotone.fa-chess-king-alt:before {
  content: "\f440\fe01"; }

.fad.fa-chess-king-alt:after, .fa-duotone.fa-chess-king-alt:after {
  content: "\f440\fe02"; }

.fad.fa-chess-knight:before, .fa-duotone.fa-chess-knight:before {
  content: "\265e\fe01"; }

.fad.fa-chess-knight:after, .fa-duotone.fa-chess-knight:after {
  content: "\265e\fe02"; }

.fad.fa-chess-knight-piece:before, .fa-duotone.fa-chess-knight-piece:before {
  content: "\f442\fe01"; }

.fad.fa-chess-knight-piece:after, .fa-duotone.fa-chess-knight-piece:after {
  content: "\f442\fe02"; }

.fad.fa-chess-knight-alt:before, .fa-duotone.fa-chess-knight-alt:before {
  content: "\f442\fe01"; }

.fad.fa-chess-knight-alt:after, .fa-duotone.fa-chess-knight-alt:after {
  content: "\f442\fe02"; }

.fad.fa-chess-pawn:before, .fa-duotone.fa-chess-pawn:before {
  content: "\265f\fe01"; }

.fad.fa-chess-pawn:after, .fa-duotone.fa-chess-pawn:after {
  content: "\265f\fe02"; }

.fad.fa-chess-pawn-piece:before, .fa-duotone.fa-chess-pawn-piece:before {
  content: "\f444\fe01"; }

.fad.fa-chess-pawn-piece:after, .fa-duotone.fa-chess-pawn-piece:after {
  content: "\f444\fe02"; }

.fad.fa-chess-pawn-alt:before, .fa-duotone.fa-chess-pawn-alt:before {
  content: "\f444\fe01"; }

.fad.fa-chess-pawn-alt:after, .fa-duotone.fa-chess-pawn-alt:after {
  content: "\f444\fe02"; }

.fad.fa-chess-queen:before, .fa-duotone.fa-chess-queen:before {
  content: "\265b\fe01"; }

.fad.fa-chess-queen:after, .fa-duotone.fa-chess-queen:after {
  content: "\265b\fe02"; }

.fad.fa-chess-queen-piece:before, .fa-duotone.fa-chess-queen-piece:before {
  content: "\f446\fe01"; }

.fad.fa-chess-queen-piece:after, .fa-duotone.fa-chess-queen-piece:after {
  content: "\f446\fe02"; }

.fad.fa-chess-queen-alt:before, .fa-duotone.fa-chess-queen-alt:before {
  content: "\f446\fe01"; }

.fad.fa-chess-queen-alt:after, .fa-duotone.fa-chess-queen-alt:after {
  content: "\f446\fe02"; }

.fad.fa-chess-rook:before, .fa-duotone.fa-chess-rook:before {
  content: "\265c\fe01"; }

.fad.fa-chess-rook:after, .fa-duotone.fa-chess-rook:after {
  content: "\265c\fe02"; }

.fad.fa-chess-rook-piece:before, .fa-duotone.fa-chess-rook-piece:before {
  content: "\f448\fe01"; }

.fad.fa-chess-rook-piece:after, .fa-duotone.fa-chess-rook-piece:after {
  content: "\f448\fe02"; }

.fad.fa-chess-rook-alt:before, .fa-duotone.fa-chess-rook-alt:before {
  content: "\f448\fe01"; }

.fad.fa-chess-rook-alt:after, .fa-duotone.fa-chess-rook-alt:after {
  content: "\f448\fe02"; }

.fad.fa-chevron-down:before, .fa-duotone.fa-chevron-down:before {
  content: "\f078\fe01"; }

.fad.fa-chevron-down:after, .fa-duotone.fa-chevron-down:after {
  content: "\f078\fe02"; }

.fad.fa-chevron-left:before, .fa-duotone.fa-chevron-left:before {
  content: "\2329\fe01"; }

.fad.fa-chevron-left:after, .fa-duotone.fa-chevron-left:after {
  content: "\2329\fe02"; }

.fad.fa-chevron-right:before, .fa-duotone.fa-chevron-right:before {
  content: "\232a\fe01"; }

.fad.fa-chevron-right:after, .fa-duotone.fa-chevron-right:after {
  content: "\232a\fe02"; }

.fad.fa-chevron-up:before, .fa-duotone.fa-chevron-up:before {
  content: "\f077\fe01"; }

.fad.fa-chevron-up:after, .fa-duotone.fa-chevron-up:after {
  content: "\f077\fe02"; }

.fad.fa-chevrons-down:before, .fa-duotone.fa-chevrons-down:before {
  content: "\f322\fe01"; }

.fad.fa-chevrons-down:after, .fa-duotone.fa-chevrons-down:after {
  content: "\f322\fe02"; }

.fad.fa-chevron-double-down:before, .fa-duotone.fa-chevron-double-down:before {
  content: "\f322\fe01"; }

.fad.fa-chevron-double-down:after, .fa-duotone.fa-chevron-double-down:after {
  content: "\f322\fe02"; }

.fad.fa-chevrons-left:before, .fa-duotone.fa-chevrons-left:before {
  content: "\f323\fe01"; }

.fad.fa-chevrons-left:after, .fa-duotone.fa-chevrons-left:after {
  content: "\f323\fe02"; }

.fad.fa-chevron-double-left:before, .fa-duotone.fa-chevron-double-left:before {
  content: "\f323\fe01"; }

.fad.fa-chevron-double-left:after, .fa-duotone.fa-chevron-double-left:after {
  content: "\f323\fe02"; }

.fad.fa-chevrons-right:before, .fa-duotone.fa-chevrons-right:before {
  content: "\f324\fe01"; }

.fad.fa-chevrons-right:after, .fa-duotone.fa-chevrons-right:after {
  content: "\f324\fe02"; }

.fad.fa-chevron-double-right:before, .fa-duotone.fa-chevron-double-right:before {
  content: "\f324\fe01"; }

.fad.fa-chevron-double-right:after, .fa-duotone.fa-chevron-double-right:after {
  content: "\f324\fe02"; }

.fad.fa-chevrons-up:before, .fa-duotone.fa-chevrons-up:before {
  content: "\f325\fe01"; }

.fad.fa-chevrons-up:after, .fa-duotone.fa-chevrons-up:after {
  content: "\f325\fe02"; }

.fad.fa-chevron-double-up:before, .fa-duotone.fa-chevron-double-up:before {
  content: "\f325\fe01"; }

.fad.fa-chevron-double-up:after, .fa-duotone.fa-chevron-double-up:after {
  content: "\f325\fe02"; }

.fad.fa-child:before, .fa-duotone.fa-child:before {
  content: "\f1ae\fe01"; }

.fad.fa-child:after, .fa-duotone.fa-child:after {
  content: "\f1ae\fe02"; }

.fad.fa-chimney:before, .fa-duotone.fa-chimney:before {
  content: "\f78b\fe01"; }

.fad.fa-chimney:after, .fa-duotone.fa-chimney:after {
  content: "\f78b\fe02"; }

.fad.fa-church:before, .fa-duotone.fa-church:before {
  content: "\26ea\fe01"; }

.fad.fa-church:after, .fa-duotone.fa-church:after {
  content: "\26ea\fe02"; }

.fad.fa-circle:before, .fa-duotone.fa-circle:before {
  content: "\25cf\fe01"; }

.fad.fa-circle:after, .fa-duotone.fa-circle:after {
  content: "\25cf\fe02"; }

.fad.fa-circle-0:before, .fa-duotone.fa-circle-0:before {
  content: "\e0ed\fe01"; }

.fad.fa-circle-0:after, .fa-duotone.fa-circle-0:after {
  content: "\e0ed\fe02"; }

.fad.fa-circle-1:before, .fa-duotone.fa-circle-1:before {
  content: "\e0ee\fe01"; }

.fad.fa-circle-1:after, .fa-duotone.fa-circle-1:after {
  content: "\e0ee\fe02"; }

.fad.fa-circle-2:before, .fa-duotone.fa-circle-2:before {
  content: "\e0ef\fe01"; }

.fad.fa-circle-2:after, .fa-duotone.fa-circle-2:after {
  content: "\e0ef\fe02"; }

.fad.fa-circle-3:before, .fa-duotone.fa-circle-3:before {
  content: "\e0f0\fe01"; }

.fad.fa-circle-3:after, .fa-duotone.fa-circle-3:after {
  content: "\e0f0\fe02"; }

.fad.fa-circle-4:before, .fa-duotone.fa-circle-4:before {
  content: "\e0f1\fe01"; }

.fad.fa-circle-4:after, .fa-duotone.fa-circle-4:after {
  content: "\e0f1\fe02"; }

.fad.fa-circle-5:before, .fa-duotone.fa-circle-5:before {
  content: "\e0f2\fe01"; }

.fad.fa-circle-5:after, .fa-duotone.fa-circle-5:after {
  content: "\e0f2\fe02"; }

.fad.fa-circle-6:before, .fa-duotone.fa-circle-6:before {
  content: "\e0f3\fe01"; }

.fad.fa-circle-6:after, .fa-duotone.fa-circle-6:after {
  content: "\e0f3\fe02"; }

.fad.fa-circle-7:before, .fa-duotone.fa-circle-7:before {
  content: "\e0f4\fe01"; }

.fad.fa-circle-7:after, .fa-duotone.fa-circle-7:after {
  content: "\e0f4\fe02"; }

.fad.fa-circle-8:before, .fa-duotone.fa-circle-8:before {
  content: "\e0f5\fe01"; }

.fad.fa-circle-8:after, .fa-duotone.fa-circle-8:after {
  content: "\e0f5\fe02"; }

.fad.fa-circle-9:before, .fa-duotone.fa-circle-9:before {
  content: "\e0f6\fe01"; }

.fad.fa-circle-9:after, .fa-duotone.fa-circle-9:after {
  content: "\e0f6\fe02"; }

.fad.fa-circle-a:before, .fa-duotone.fa-circle-a:before {
  content: "\e0f7\fe01"; }

.fad.fa-circle-a:after, .fa-duotone.fa-circle-a:after {
  content: "\e0f7\fe02"; }

.fad.fa-circle-ampersand:before, .fa-duotone.fa-circle-ampersand:before {
  content: "\e0f8\fe01"; }

.fad.fa-circle-ampersand:after, .fa-duotone.fa-circle-ampersand:after {
  content: "\e0f8\fe02"; }

.fad.fa-circle-arrow-down:before, .fa-duotone.fa-circle-arrow-down:before {
  content: "\f0ab\fe01"; }

.fad.fa-circle-arrow-down:after, .fa-duotone.fa-circle-arrow-down:after {
  content: "\f0ab\fe02"; }

.fad.fa-arrow-circle-down:before, .fa-duotone.fa-arrow-circle-down:before {
  content: "\f0ab\fe01"; }

.fad.fa-arrow-circle-down:after, .fa-duotone.fa-arrow-circle-down:after {
  content: "\f0ab\fe02"; }

.fad.fa-circle-arrow-down-left:before, .fa-duotone.fa-circle-arrow-down-left:before {
  content: "\e0f9\fe01"; }

.fad.fa-circle-arrow-down-left:after, .fa-duotone.fa-circle-arrow-down-left:after {
  content: "\e0f9\fe02"; }

.fad.fa-circle-arrow-down-right:before, .fa-duotone.fa-circle-arrow-down-right:before {
  content: "\e0fa\fe01"; }

.fad.fa-circle-arrow-down-right:after, .fa-duotone.fa-circle-arrow-down-right:after {
  content: "\e0fa\fe02"; }

.fad.fa-circle-arrow-left:before, .fa-duotone.fa-circle-arrow-left:before {
  content: "\f0a8\fe01"; }

.fad.fa-circle-arrow-left:after, .fa-duotone.fa-circle-arrow-left:after {
  content: "\f0a8\fe02"; }

.fad.fa-arrow-circle-left:before, .fa-duotone.fa-arrow-circle-left:before {
  content: "\f0a8\fe01"; }

.fad.fa-arrow-circle-left:after, .fa-duotone.fa-arrow-circle-left:after {
  content: "\f0a8\fe02"; }

.fad.fa-circle-arrow-right:before, .fa-duotone.fa-circle-arrow-right:before {
  content: "\f0a9\fe01"; }

.fad.fa-circle-arrow-right:after, .fa-duotone.fa-circle-arrow-right:after {
  content: "\f0a9\fe02"; }

.fad.fa-arrow-circle-right:before, .fa-duotone.fa-arrow-circle-right:before {
  content: "\f0a9\fe01"; }

.fad.fa-arrow-circle-right:after, .fa-duotone.fa-arrow-circle-right:after {
  content: "\f0a9\fe02"; }

.fad.fa-circle-arrow-up:before, .fa-duotone.fa-circle-arrow-up:before {
  content: "\f0aa\fe01"; }

.fad.fa-circle-arrow-up:after, .fa-duotone.fa-circle-arrow-up:after {
  content: "\f0aa\fe02"; }

.fad.fa-arrow-circle-up:before, .fa-duotone.fa-arrow-circle-up:before {
  content: "\f0aa\fe01"; }

.fad.fa-arrow-circle-up:after, .fa-duotone.fa-arrow-circle-up:after {
  content: "\f0aa\fe02"; }

.fad.fa-circle-arrow-up-left:before, .fa-duotone.fa-circle-arrow-up-left:before {
  content: "\e0fb\fe01"; }

.fad.fa-circle-arrow-up-left:after, .fa-duotone.fa-circle-arrow-up-left:after {
  content: "\e0fb\fe02"; }

.fad.fa-circle-arrow-up-right:before, .fa-duotone.fa-circle-arrow-up-right:before {
  content: "\e0fc\fe01"; }

.fad.fa-circle-arrow-up-right:after, .fa-duotone.fa-circle-arrow-up-right:after {
  content: "\e0fc\fe02"; }

.fad.fa-circle-b:before, .fa-duotone.fa-circle-b:before {
  content: "\e0fd\fe01"; }

.fad.fa-circle-b:after, .fa-duotone.fa-circle-b:after {
  content: "\e0fd\fe02"; }

.fad.fa-circle-bolt:before, .fa-duotone.fa-circle-bolt:before {
  content: "\e0fe\fe01"; }

.fad.fa-circle-bolt:after, .fa-duotone.fa-circle-bolt:after {
  content: "\e0fe\fe02"; }

.fad.fa-circle-book-open:before, .fa-duotone.fa-circle-book-open:before {
  content: "\e0ff\fe01"; }

.fad.fa-circle-book-open:after, .fa-duotone.fa-circle-book-open:after {
  content: "\e0ff\fe02"; }

.fad.fa-book-circle:before, .fa-duotone.fa-book-circle:before {
  content: "\e0ff\fe01"; }

.fad.fa-book-circle:after, .fa-duotone.fa-book-circle:after {
  content: "\e0ff\fe02"; }

.fad.fa-circle-bookmark:before, .fa-duotone.fa-circle-bookmark:before {
  content: "\e100\fe01"; }

.fad.fa-circle-bookmark:after, .fa-duotone.fa-circle-bookmark:after {
  content: "\e100\fe02"; }

.fad.fa-bookmark-circle:before, .fa-duotone.fa-bookmark-circle:before {
  content: "\e100\fe01"; }

.fad.fa-bookmark-circle:after, .fa-duotone.fa-bookmark-circle:after {
  content: "\e100\fe02"; }

.fad.fa-circle-c:before, .fa-duotone.fa-circle-c:before {
  content: "\e101\fe01"; }

.fad.fa-circle-c:after, .fa-duotone.fa-circle-c:after {
  content: "\e101\fe02"; }

.fad.fa-circle-calendar:before, .fa-duotone.fa-circle-calendar:before {
  content: "\e102\fe01"; }

.fad.fa-circle-calendar:after, .fa-duotone.fa-circle-calendar:after {
  content: "\e102\fe02"; }

.fad.fa-calendar-circle:before, .fa-duotone.fa-calendar-circle:before {
  content: "\e102\fe01"; }

.fad.fa-calendar-circle:after, .fa-duotone.fa-calendar-circle:after {
  content: "\e102\fe02"; }

.fad.fa-circle-camera:before, .fa-duotone.fa-circle-camera:before {
  content: "\e103\fe01"; }

.fad.fa-circle-camera:after, .fa-duotone.fa-circle-camera:after {
  content: "\e103\fe02"; }

.fad.fa-camera-circle:before, .fa-duotone.fa-camera-circle:before {
  content: "\e103\fe01"; }

.fad.fa-camera-circle:after, .fa-duotone.fa-camera-circle:after {
  content: "\e103\fe02"; }

.fad.fa-circle-caret-down:before, .fa-duotone.fa-circle-caret-down:before {
  content: "\f32d\fe01"; }

.fad.fa-circle-caret-down:after, .fa-duotone.fa-circle-caret-down:after {
  content: "\f32d\fe02"; }

.fad.fa-caret-circle-down:before, .fa-duotone.fa-caret-circle-down:before {
  content: "\f32d\fe01"; }

.fad.fa-caret-circle-down:after, .fa-duotone.fa-caret-circle-down:after {
  content: "\f32d\fe02"; }

.fad.fa-circle-caret-left:before, .fa-duotone.fa-circle-caret-left:before {
  content: "\f32e\fe01"; }

.fad.fa-circle-caret-left:after, .fa-duotone.fa-circle-caret-left:after {
  content: "\f32e\fe02"; }

.fad.fa-caret-circle-left:before, .fa-duotone.fa-caret-circle-left:before {
  content: "\f32e\fe01"; }

.fad.fa-caret-circle-left:after, .fa-duotone.fa-caret-circle-left:after {
  content: "\f32e\fe02"; }

.fad.fa-circle-caret-right:before, .fa-duotone.fa-circle-caret-right:before {
  content: "\f330\fe01"; }

.fad.fa-circle-caret-right:after, .fa-duotone.fa-circle-caret-right:after {
  content: "\f330\fe02"; }

.fad.fa-caret-circle-right:before, .fa-duotone.fa-caret-circle-right:before {
  content: "\f330\fe01"; }

.fad.fa-caret-circle-right:after, .fa-duotone.fa-caret-circle-right:after {
  content: "\f330\fe02"; }

.fad.fa-circle-caret-up:before, .fa-duotone.fa-circle-caret-up:before {
  content: "\f331\fe01"; }

.fad.fa-circle-caret-up:after, .fa-duotone.fa-circle-caret-up:after {
  content: "\f331\fe02"; }

.fad.fa-caret-circle-up:before, .fa-duotone.fa-caret-circle-up:before {
  content: "\f331\fe01"; }

.fad.fa-caret-circle-up:after, .fa-duotone.fa-caret-circle-up:after {
  content: "\f331\fe02"; }

.fad.fa-circle-check:before, .fa-duotone.fa-circle-check:before {
  content: "\f058\fe01"; }

.fad.fa-circle-check:after, .fa-duotone.fa-circle-check:after {
  content: "\f058\fe02"; }

.fad.fa-check-circle:before, .fa-duotone.fa-check-circle:before {
  content: "\f058\fe01"; }

.fad.fa-check-circle:after, .fa-duotone.fa-check-circle:after {
  content: "\f058\fe02"; }

.fad.fa-circle-chevron-down:before, .fa-duotone.fa-circle-chevron-down:before {
  content: "\f13a\fe01"; }

.fad.fa-circle-chevron-down:after, .fa-duotone.fa-circle-chevron-down:after {
  content: "\f13a\fe02"; }

.fad.fa-chevron-circle-down:before, .fa-duotone.fa-chevron-circle-down:before {
  content: "\f13a\fe01"; }

.fad.fa-chevron-circle-down:after, .fa-duotone.fa-chevron-circle-down:after {
  content: "\f13a\fe02"; }

.fad.fa-circle-chevron-left:before, .fa-duotone.fa-circle-chevron-left:before {
  content: "\f137\fe01"; }

.fad.fa-circle-chevron-left:after, .fa-duotone.fa-circle-chevron-left:after {
  content: "\f137\fe02"; }

.fad.fa-chevron-circle-left:before, .fa-duotone.fa-chevron-circle-left:before {
  content: "\f137\fe01"; }

.fad.fa-chevron-circle-left:after, .fa-duotone.fa-chevron-circle-left:after {
  content: "\f137\fe02"; }

.fad.fa-circle-chevron-right:before, .fa-duotone.fa-circle-chevron-right:before {
  content: "\f138\fe01"; }

.fad.fa-circle-chevron-right:after, .fa-duotone.fa-circle-chevron-right:after {
  content: "\f138\fe02"; }

.fad.fa-chevron-circle-right:before, .fa-duotone.fa-chevron-circle-right:before {
  content: "\f138\fe01"; }

.fad.fa-chevron-circle-right:after, .fa-duotone.fa-chevron-circle-right:after {
  content: "\f138\fe02"; }

.fad.fa-circle-chevron-up:before, .fa-duotone.fa-circle-chevron-up:before {
  content: "\f139\fe01"; }

.fad.fa-circle-chevron-up:after, .fa-duotone.fa-circle-chevron-up:after {
  content: "\f139\fe02"; }

.fad.fa-chevron-circle-up:before, .fa-duotone.fa-chevron-circle-up:before {
  content: "\f139\fe01"; }

.fad.fa-chevron-circle-up:after, .fa-duotone.fa-chevron-circle-up:after {
  content: "\f139\fe02"; }

.fad.fa-circle-d:before, .fa-duotone.fa-circle-d:before {
  content: "\e104\fe01"; }

.fad.fa-circle-d:after, .fa-duotone.fa-circle-d:after {
  content: "\e104\fe02"; }

.fad.fa-circle-dashed:before, .fa-duotone.fa-circle-dashed:before {
  content: "\e105\fe01"; }

.fad.fa-circle-dashed:after, .fa-duotone.fa-circle-dashed:after {
  content: "\e105\fe02"; }

.fad.fa-circle-divide:before, .fa-duotone.fa-circle-divide:before {
  content: "\e106\fe01"; }

.fad.fa-circle-divide:after, .fa-duotone.fa-circle-divide:after {
  content: "\e106\fe02"; }

.fad.fa-circle-dollar:before, .fa-duotone.fa-circle-dollar:before {
  content: "\f2e8\fe01"; }

.fad.fa-circle-dollar:after, .fa-duotone.fa-circle-dollar:after {
  content: "\f2e8\fe02"; }

.fad.fa-dollar-circle:before, .fa-duotone.fa-dollar-circle:before {
  content: "\f2e8\fe01"; }

.fad.fa-dollar-circle:after, .fa-duotone.fa-dollar-circle:after {
  content: "\f2e8\fe02"; }

.fad.fa-usd-circle:before, .fa-duotone.fa-usd-circle:before {
  content: "\f2e8\fe01"; }

.fad.fa-usd-circle:after, .fa-duotone.fa-usd-circle:after {
  content: "\f2e8\fe02"; }

.fad.fa-circle-dollar-to-slot:before, .fa-duotone.fa-circle-dollar-to-slot:before {
  content: "\f4b9\fe01"; }

.fad.fa-circle-dollar-to-slot:after, .fa-duotone.fa-circle-dollar-to-slot:after {
  content: "\f4b9\fe02"; }

.fad.fa-donate:before, .fa-duotone.fa-donate:before {
  content: "\f4b9\fe01"; }

.fad.fa-donate:after, .fa-duotone.fa-donate:after {
  content: "\f4b9\fe02"; }

.fad.fa-circle-dot:before, .fa-duotone.fa-circle-dot:before {
  content: "\f192"; }

.fad.fa-circle-dot:after, .fa-duotone.fa-circle-dot:after {
  content: "\10f192"; }

.fad.fa-dot-circle:before, .fa-duotone.fa-dot-circle:before {
  content: "\f192"; }

.fad.fa-dot-circle:after, .fa-duotone.fa-dot-circle:after {
  content: "\10f192"; }

.fad.fa-circle-down:before, .fa-duotone.fa-circle-down:before {
  content: "\f358\fe01"; }

.fad.fa-circle-down:after, .fa-duotone.fa-circle-down:after {
  content: "\f358\fe02"; }

.fad.fa-arrow-alt-circle-down:before, .fa-duotone.fa-arrow-alt-circle-down:before {
  content: "\f358\fe01"; }

.fad.fa-arrow-alt-circle-down:after, .fa-duotone.fa-arrow-alt-circle-down:after {
  content: "\f358\fe02"; }

.fad.fa-circle-down-left:before, .fa-duotone.fa-circle-down-left:before {
  content: "\e107\fe01"; }

.fad.fa-circle-down-left:after, .fa-duotone.fa-circle-down-left:after {
  content: "\e107\fe02"; }

.fad.fa-circle-down-right:before, .fa-duotone.fa-circle-down-right:before {
  content: "\e108\fe01"; }

.fad.fa-circle-down-right:after, .fa-duotone.fa-circle-down-right:after {
  content: "\e108\fe02"; }

.fad.fa-circle-e:before, .fa-duotone.fa-circle-e:before {
  content: "\e109\fe01"; }

.fad.fa-circle-e:after, .fa-duotone.fa-circle-e:after {
  content: "\e109\fe02"; }

.fad.fa-circle-ellipsis:before, .fa-duotone.fa-circle-ellipsis:before {
  content: "\e10a\fe01"; }

.fad.fa-circle-ellipsis:after, .fa-duotone.fa-circle-ellipsis:after {
  content: "\e10a\fe02"; }

.fad.fa-circle-ellipsis-vertical:before, .fa-duotone.fa-circle-ellipsis-vertical:before {
  content: "\e10b\fe01"; }

.fad.fa-circle-ellipsis-vertical:after, .fa-duotone.fa-circle-ellipsis-vertical:after {
  content: "\e10b\fe02"; }

.fad.fa-circle-envelope:before, .fa-duotone.fa-circle-envelope:before {
  content: "\e10c\fe01"; }

.fad.fa-circle-envelope:after, .fa-duotone.fa-circle-envelope:after {
  content: "\e10c\fe02"; }

.fad.fa-envelope-circle:before, .fa-duotone.fa-envelope-circle:before {
  content: "\e10c\fe01"; }

.fad.fa-envelope-circle:after, .fa-duotone.fa-envelope-circle:after {
  content: "\e10c\fe02"; }

.fad.fa-circle-exclamation:before, .fa-duotone.fa-circle-exclamation:before {
  content: "\f06a\fe01"; }

.fad.fa-circle-exclamation:after, .fa-duotone.fa-circle-exclamation:after {
  content: "\f06a\fe02"; }

.fad.fa-exclamation-circle:before, .fa-duotone.fa-exclamation-circle:before {
  content: "\f06a\fe01"; }

.fad.fa-exclamation-circle:after, .fa-duotone.fa-exclamation-circle:after {
  content: "\f06a\fe02"; }

.fad.fa-circle-exclamation-check:before, .fa-duotone.fa-circle-exclamation-check:before {
  content: "\e10d\fe01"; }

.fad.fa-circle-exclamation-check:after, .fa-duotone.fa-circle-exclamation-check:after {
  content: "\e10d\fe02"; }

.fad.fa-circle-f:before, .fa-duotone.fa-circle-f:before {
  content: "\e10e\fe01"; }

.fad.fa-circle-f:after, .fa-duotone.fa-circle-f:after {
  content: "\e10e\fe02"; }

.fad.fa-circle-g:before, .fa-duotone.fa-circle-g:before {
  content: "\e10f\fe01"; }

.fad.fa-circle-g:after, .fa-duotone.fa-circle-g:after {
  content: "\e10f\fe02"; }

.fad.fa-circle-h:before, .fa-duotone.fa-circle-h:before {
  content: "\24bd\fe01"; }

.fad.fa-circle-h:after, .fa-duotone.fa-circle-h:after {
  content: "\24bd\fe02"; }

.fad.fa-hospital-symbol:before, .fa-duotone.fa-hospital-symbol:before {
  content: "\24bd\fe01"; }

.fad.fa-hospital-symbol:after, .fa-duotone.fa-hospital-symbol:after {
  content: "\24bd\fe02"; }

.fad.fa-circle-half:before, .fa-duotone.fa-circle-half:before {
  content: "\e110\fe01"; }

.fad.fa-circle-half:after, .fa-duotone.fa-circle-half:after {
  content: "\e110\fe02"; }

.fad.fa-circle-half-stroke:before, .fa-duotone.fa-circle-half-stroke:before {
  content: "\25d0\fe01"; }

.fad.fa-circle-half-stroke:after, .fa-duotone.fa-circle-half-stroke:after {
  content: "\25d0\fe02"; }

.fad.fa-adjust:before, .fa-duotone.fa-adjust:before {
  content: "\25d0\fe01"; }

.fad.fa-adjust:after, .fa-duotone.fa-adjust:after {
  content: "\25d0\fe02"; }

.fad.fa-circle-heart:before, .fa-duotone.fa-circle-heart:before {
  content: "\f4c7\fe01"; }

.fad.fa-circle-heart:after, .fa-duotone.fa-circle-heart:after {
  content: "\f4c7\fe02"; }

.fad.fa-heart-circle:before, .fa-duotone.fa-heart-circle:before {
  content: "\f4c7\fe01"; }

.fad.fa-heart-circle:after, .fa-duotone.fa-heart-circle:after {
  content: "\f4c7\fe02"; }

.fad.fa-circle-i:before, .fa-duotone.fa-circle-i:before {
  content: "\e111\fe01"; }

.fad.fa-circle-i:after, .fa-duotone.fa-circle-i:after {
  content: "\e111\fe02"; }

.fad.fa-circle-info:before, .fa-duotone.fa-circle-info:before {
  content: "\f05a\fe01"; }

.fad.fa-circle-info:after, .fa-duotone.fa-circle-info:after {
  content: "\f05a\fe02"; }

.fad.fa-info-circle:before, .fa-duotone.fa-info-circle:before {
  content: "\f05a\fe01"; }

.fad.fa-info-circle:after, .fa-duotone.fa-info-circle:after {
  content: "\f05a\fe02"; }

.fad.fa-circle-j:before, .fa-duotone.fa-circle-j:before {
  content: "\e112\fe01"; }

.fad.fa-circle-j:after, .fa-duotone.fa-circle-j:after {
  content: "\e112\fe02"; }

.fad.fa-circle-k:before, .fa-duotone.fa-circle-k:before {
  content: "\e113\fe01"; }

.fad.fa-circle-k:after, .fa-duotone.fa-circle-k:after {
  content: "\e113\fe02"; }

.fad.fa-circle-l:before, .fa-duotone.fa-circle-l:before {
  content: "\e114\fe01"; }

.fad.fa-circle-l:after, .fa-duotone.fa-circle-l:after {
  content: "\e114\fe02"; }

.fad.fa-circle-left:before, .fa-duotone.fa-circle-left:before {
  content: "\f359\fe01"; }

.fad.fa-circle-left:after, .fa-duotone.fa-circle-left:after {
  content: "\f359\fe02"; }

.fad.fa-arrow-alt-circle-left:before, .fa-duotone.fa-arrow-alt-circle-left:before {
  content: "\f359\fe01"; }

.fad.fa-arrow-alt-circle-left:after, .fa-duotone.fa-arrow-alt-circle-left:after {
  content: "\f359\fe02"; }

.fad.fa-circle-location-arrow:before, .fa-duotone.fa-circle-location-arrow:before {
  content: "\f602\fe01"; }

.fad.fa-circle-location-arrow:after, .fa-duotone.fa-circle-location-arrow:after {
  content: "\f602\fe02"; }

.fad.fa-location-circle:before, .fa-duotone.fa-location-circle:before {
  content: "\f602\fe01"; }

.fad.fa-location-circle:after, .fa-duotone.fa-location-circle:after {
  content: "\f602\fe02"; }

.fad.fa-circle-m:before, .fa-duotone.fa-circle-m:before {
  content: "\e115\fe01"; }

.fad.fa-circle-m:after, .fa-duotone.fa-circle-m:after {
  content: "\e115\fe02"; }

.fad.fa-circle-microphone:before, .fa-duotone.fa-circle-microphone:before {
  content: "\e116\fe01"; }

.fad.fa-circle-microphone:after, .fa-duotone.fa-circle-microphone:after {
  content: "\e116\fe02"; }

.fad.fa-microphone-circle:before, .fa-duotone.fa-microphone-circle:before {
  content: "\e116\fe01"; }

.fad.fa-microphone-circle:after, .fa-duotone.fa-microphone-circle:after {
  content: "\e116\fe02"; }

.fad.fa-circle-microphone-lines:before, .fa-duotone.fa-circle-microphone-lines:before {
  content: "\e117\fe01"; }

.fad.fa-circle-microphone-lines:after, .fa-duotone.fa-circle-microphone-lines:after {
  content: "\e117\fe02"; }

.fad.fa-microphone-circle-alt:before, .fa-duotone.fa-microphone-circle-alt:before {
  content: "\e117\fe01"; }

.fad.fa-microphone-circle-alt:after, .fa-duotone.fa-microphone-circle-alt:after {
  content: "\e117\fe02"; }

.fad.fa-circle-minus:before, .fa-duotone.fa-circle-minus:before {
  content: "\f056\fe01"; }

.fad.fa-circle-minus:after, .fa-duotone.fa-circle-minus:after {
  content: "\f056\fe02"; }

.fad.fa-minus-circle:before, .fa-duotone.fa-minus-circle:before {
  content: "\f056\fe01"; }

.fad.fa-minus-circle:after, .fa-duotone.fa-minus-circle:after {
  content: "\f056\fe02"; }

.fad.fa-circle-n:before, .fa-duotone.fa-circle-n:before {
  content: "\e118\fe01"; }

.fad.fa-circle-n:after, .fa-duotone.fa-circle-n:after {
  content: "\e118\fe02"; }

.fad.fa-circle-notch:before, .fa-duotone.fa-circle-notch:before {
  content: "\f1ce\fe01"; }

.fad.fa-circle-notch:after, .fa-duotone.fa-circle-notch:after {
  content: "\f1ce\fe02"; }

.fad.fa-circle-o:before, .fa-duotone.fa-circle-o:before {
  content: "\e119\fe01"; }

.fad.fa-circle-o:after, .fa-duotone.fa-circle-o:after {
  content: "\e119\fe02"; }

.fad.fa-circle-p:before, .fa-duotone.fa-circle-p:before {
  content: "\e11a\fe01"; }

.fad.fa-circle-p:after, .fa-duotone.fa-circle-p:after {
  content: "\e11a\fe02"; }

.fad.fa-circle-parking:before, .fa-duotone.fa-circle-parking:before {
  content: "\f615\fe01"; }

.fad.fa-circle-parking:after, .fa-duotone.fa-circle-parking:after {
  content: "\f615\fe02"; }

.fad.fa-parking-circle:before, .fa-duotone.fa-parking-circle:before {
  content: "\f615\fe01"; }

.fad.fa-parking-circle:after, .fa-duotone.fa-parking-circle:after {
  content: "\f615\fe02"; }

.fad.fa-circle-pause:before, .fa-duotone.fa-circle-pause:before {
  content: "\f28b\fe01"; }

.fad.fa-circle-pause:after, .fa-duotone.fa-circle-pause:after {
  content: "\f28b\fe02"; }

.fad.fa-pause-circle:before, .fa-duotone.fa-pause-circle:before {
  content: "\f28b\fe01"; }

.fad.fa-pause-circle:after, .fa-duotone.fa-pause-circle:after {
  content: "\f28b\fe02"; }

.fad.fa-circle-phone:before, .fa-duotone.fa-circle-phone:before {
  content: "\e11b\fe01"; }

.fad.fa-circle-phone:after, .fa-duotone.fa-circle-phone:after {
  content: "\e11b\fe02"; }

.fad.fa-phone-circle:before, .fa-duotone.fa-phone-circle:before {
  content: "\e11b\fe01"; }

.fad.fa-phone-circle:after, .fa-duotone.fa-phone-circle:after {
  content: "\e11b\fe02"; }

.fad.fa-circle-phone-flip:before, .fa-duotone.fa-circle-phone-flip:before {
  content: "\e11c\fe01"; }

.fad.fa-circle-phone-flip:after, .fa-duotone.fa-circle-phone-flip:after {
  content: "\e11c\fe02"; }

.fad.fa-phone-circle-alt:before, .fa-duotone.fa-phone-circle-alt:before {
  content: "\e11c\fe01"; }

.fad.fa-phone-circle-alt:after, .fa-duotone.fa-phone-circle-alt:after {
  content: "\e11c\fe02"; }

.fad.fa-circle-phone-hangup:before, .fa-duotone.fa-circle-phone-hangup:before {
  content: "\e11d\fe01"; }

.fad.fa-circle-phone-hangup:after, .fa-duotone.fa-circle-phone-hangup:after {
  content: "\e11d\fe02"; }

.fad.fa-phone-circle-down:before, .fa-duotone.fa-phone-circle-down:before {
  content: "\e11d\fe01"; }

.fad.fa-phone-circle-down:after, .fa-duotone.fa-phone-circle-down:after {
  content: "\e11d\fe02"; }

.fad.fa-circle-play:before, .fa-duotone.fa-circle-play:before {
  content: "\f144\fe01"; }

.fad.fa-circle-play:after, .fa-duotone.fa-circle-play:after {
  content: "\f144\fe02"; }

.fad.fa-play-circle:before, .fa-duotone.fa-play-circle:before {
  content: "\f144\fe01"; }

.fad.fa-play-circle:after, .fa-duotone.fa-play-circle:after {
  content: "\f144\fe02"; }

.fad.fa-circle-plus:before, .fa-duotone.fa-circle-plus:before {
  content: "\f055\fe01"; }

.fad.fa-circle-plus:after, .fa-duotone.fa-circle-plus:after {
  content: "\f055\fe02"; }

.fad.fa-plus-circle:before, .fa-duotone.fa-plus-circle:before {
  content: "\f055\fe01"; }

.fad.fa-plus-circle:after, .fa-duotone.fa-plus-circle:after {
  content: "\f055\fe02"; }

.fad.fa-circle-q:before, .fa-duotone.fa-circle-q:before {
  content: "\e11e\fe01"; }

.fad.fa-circle-q:after, .fa-duotone.fa-circle-q:after {
  content: "\e11e\fe02"; }

.fad.fa-circle-quarter:before, .fa-duotone.fa-circle-quarter:before {
  content: "\e11f\fe01"; }

.fad.fa-circle-quarter:after, .fa-duotone.fa-circle-quarter:after {
  content: "\e11f\fe02"; }

.fad.fa-circle-question:before, .fa-duotone.fa-circle-question:before {
  content: "\f059\fe01"; }

.fad.fa-circle-question:after, .fa-duotone.fa-circle-question:after {
  content: "\f059\fe02"; }

.fad.fa-question-circle:before, .fa-duotone.fa-question-circle:before {
  content: "\f059\fe01"; }

.fad.fa-question-circle:after, .fa-duotone.fa-question-circle:after {
  content: "\f059\fe02"; }

.fad.fa-circle-r:before, .fa-duotone.fa-circle-r:before {
  content: "\e120\fe01"; }

.fad.fa-circle-r:after, .fa-duotone.fa-circle-r:after {
  content: "\e120\fe02"; }

.fad.fa-circle-radiation:before, .fa-duotone.fa-circle-radiation:before {
  content: "\2622\fe01"; }

.fad.fa-circle-radiation:after, .fa-duotone.fa-circle-radiation:after {
  content: "\2622\fe02"; }

.fad.fa-radiation-alt:before, .fa-duotone.fa-radiation-alt:before {
  content: "\2622\fe01"; }

.fad.fa-radiation-alt:after, .fa-duotone.fa-radiation-alt:after {
  content: "\2622\fe02"; }

.fad.fa-circle-right:before, .fa-duotone.fa-circle-right:before {
  content: "\f35a\fe01"; }

.fad.fa-circle-right:after, .fa-duotone.fa-circle-right:after {
  content: "\f35a\fe02"; }

.fad.fa-arrow-alt-circle-right:before, .fa-duotone.fa-arrow-alt-circle-right:before {
  content: "\f35a\fe01"; }

.fad.fa-arrow-alt-circle-right:after, .fa-duotone.fa-arrow-alt-circle-right:after {
  content: "\f35a\fe02"; }

.fad.fa-circle-s:before, .fa-duotone.fa-circle-s:before {
  content: "\e121\fe01"; }

.fad.fa-circle-s:after, .fa-duotone.fa-circle-s:after {
  content: "\e121\fe02"; }

.fad.fa-circle-small:before, .fa-duotone.fa-circle-small:before {
  content: "\e122\fe01"; }

.fad.fa-circle-small:after, .fa-duotone.fa-circle-small:after {
  content: "\e122\fe02"; }

.fad.fa-circle-sort:before, .fa-duotone.fa-circle-sort:before {
  content: "\e030\fe01"; }

.fad.fa-circle-sort:after, .fa-duotone.fa-circle-sort:after {
  content: "\e030\fe02"; }

.fad.fa-sort-circle:before, .fa-duotone.fa-sort-circle:before {
  content: "\e030\fe01"; }

.fad.fa-sort-circle:after, .fa-duotone.fa-sort-circle:after {
  content: "\e030\fe02"; }

.fad.fa-circle-sort-down:before, .fa-duotone.fa-circle-sort-down:before {
  content: "\e031\fe01"; }

.fad.fa-circle-sort-down:after, .fa-duotone.fa-circle-sort-down:after {
  content: "\e031\fe02"; }

.fad.fa-sort-circle-down:before, .fa-duotone.fa-sort-circle-down:before {
  content: "\e031\fe01"; }

.fad.fa-sort-circle-down:after, .fa-duotone.fa-sort-circle-down:after {
  content: "\e031\fe02"; }

.fad.fa-circle-sort-up:before, .fa-duotone.fa-circle-sort-up:before {
  content: "\e032\fe01"; }

.fad.fa-circle-sort-up:after, .fa-duotone.fa-circle-sort-up:after {
  content: "\e032\fe02"; }

.fad.fa-sort-circle-up:before, .fa-duotone.fa-sort-circle-up:before {
  content: "\e032\fe01"; }

.fad.fa-sort-circle-up:after, .fa-duotone.fa-sort-circle-up:after {
  content: "\e032\fe02"; }

.fad.fa-circle-star:before, .fa-duotone.fa-circle-star:before {
  content: "\e123\fe01"; }

.fad.fa-circle-star:after, .fa-duotone.fa-circle-star:after {
  content: "\e123\fe02"; }

.fad.fa-star-circle:before, .fa-duotone.fa-star-circle:before {
  content: "\e123\fe01"; }

.fad.fa-star-circle:after, .fa-duotone.fa-star-circle:after {
  content: "\e123\fe02"; }

.fad.fa-circle-stop:before, .fa-duotone.fa-circle-stop:before {
  content: "\f28d\fe01"; }

.fad.fa-circle-stop:after, .fa-duotone.fa-circle-stop:after {
  content: "\f28d\fe02"; }

.fad.fa-stop-circle:before, .fa-duotone.fa-stop-circle:before {
  content: "\f28d\fe01"; }

.fad.fa-stop-circle:after, .fa-duotone.fa-stop-circle:after {
  content: "\f28d\fe02"; }

.fad.fa-circle-t:before, .fa-duotone.fa-circle-t:before {
  content: "\e124\fe01"; }

.fad.fa-circle-t:after, .fa-duotone.fa-circle-t:after {
  content: "\e124\fe02"; }

.fad.fa-circle-three-quarters:before, .fa-duotone.fa-circle-three-quarters:before {
  content: "\e125\fe01"; }

.fad.fa-circle-three-quarters:after, .fa-duotone.fa-circle-three-quarters:after {
  content: "\e125\fe02"; }

.fad.fa-circle-trash:before, .fa-duotone.fa-circle-trash:before {
  content: "\e126\fe01"; }

.fad.fa-circle-trash:after, .fa-duotone.fa-circle-trash:after {
  content: "\e126\fe02"; }

.fad.fa-trash-circle:before, .fa-duotone.fa-trash-circle:before {
  content: "\e126\fe01"; }

.fad.fa-trash-circle:after, .fa-duotone.fa-trash-circle:after {
  content: "\e126\fe02"; }

.fad.fa-circle-u:before, .fa-duotone.fa-circle-u:before {
  content: "\e127\fe01"; }

.fad.fa-circle-u:after, .fa-duotone.fa-circle-u:after {
  content: "\e127\fe02"; }

.fad.fa-circle-up:before, .fa-duotone.fa-circle-up:before {
  content: "\f35b\fe01"; }

.fad.fa-circle-up:after, .fa-duotone.fa-circle-up:after {
  content: "\f35b\fe02"; }

.fad.fa-arrow-alt-circle-up:before, .fa-duotone.fa-arrow-alt-circle-up:before {
  content: "\f35b\fe01"; }

.fad.fa-arrow-alt-circle-up:after, .fa-duotone.fa-arrow-alt-circle-up:after {
  content: "\f35b\fe02"; }

.fad.fa-circle-up-left:before, .fa-duotone.fa-circle-up-left:before {
  content: "\e128\fe01"; }

.fad.fa-circle-up-left:after, .fa-duotone.fa-circle-up-left:after {
  content: "\e128\fe02"; }

.fad.fa-circle-up-right:before, .fa-duotone.fa-circle-up-right:before {
  content: "\e129\fe01"; }

.fad.fa-circle-up-right:after, .fa-duotone.fa-circle-up-right:after {
  content: "\e129\fe02"; }

.fad.fa-circle-user:before, .fa-duotone.fa-circle-user:before {
  content: "\f2bd\fe01"; }

.fad.fa-circle-user:after, .fa-duotone.fa-circle-user:after {
  content: "\f2bd\fe02"; }

.fad.fa-user-circle:before, .fa-duotone.fa-user-circle:before {
  content: "\f2bd\fe01"; }

.fad.fa-user-circle:after, .fa-duotone.fa-user-circle:after {
  content: "\f2bd\fe02"; }

.fad.fa-circle-v:before, .fa-duotone.fa-circle-v:before {
  content: "\e12a\fe01"; }

.fad.fa-circle-v:after, .fa-duotone.fa-circle-v:after {
  content: "\e12a\fe02"; }

.fad.fa-circle-video:before, .fa-duotone.fa-circle-video:before {
  content: "\e12b\fe01"; }

.fad.fa-circle-video:after, .fa-duotone.fa-circle-video:after {
  content: "\e12b\fe02"; }

.fad.fa-video-circle:before, .fa-duotone.fa-video-circle:before {
  content: "\e12b\fe01"; }

.fad.fa-video-circle:after, .fa-duotone.fa-video-circle:after {
  content: "\e12b\fe02"; }

.fad.fa-circle-w:before, .fa-duotone.fa-circle-w:before {
  content: "\e12c\fe01"; }

.fad.fa-circle-w:after, .fa-duotone.fa-circle-w:after {
  content: "\e12c\fe02"; }

.fad.fa-circle-waveform-lines:before, .fa-duotone.fa-circle-waveform-lines:before {
  content: "\e12d\fe01"; }

.fad.fa-circle-waveform-lines:after, .fa-duotone.fa-circle-waveform-lines:after {
  content: "\e12d\fe02"; }

.fad.fa-waveform-circle:before, .fa-duotone.fa-waveform-circle:before {
  content: "\e12d\fe01"; }

.fad.fa-waveform-circle:after, .fa-duotone.fa-waveform-circle:after {
  content: "\e12d\fe02"; }

.fad.fa-circle-x:before, .fa-duotone.fa-circle-x:before {
  content: "\e12e\fe01"; }

.fad.fa-circle-x:after, .fa-duotone.fa-circle-x:after {
  content: "\e12e\fe02"; }

.fad.fa-circle-xmark:before, .fa-duotone.fa-circle-xmark:before {
  content: "\f057\fe01"; }

.fad.fa-circle-xmark:after, .fa-duotone.fa-circle-xmark:after {
  content: "\f057\fe02"; }

.fad.fa-times-circle:before, .fa-duotone.fa-times-circle:before {
  content: "\f057\fe01"; }

.fad.fa-times-circle:after, .fa-duotone.fa-times-circle:after {
  content: "\f057\fe02"; }

.fad.fa-xmark-circle:before, .fa-duotone.fa-xmark-circle:before {
  content: "\f057\fe01"; }

.fad.fa-xmark-circle:after, .fa-duotone.fa-xmark-circle:after {
  content: "\f057\fe02"; }

.fad.fa-circle-y:before, .fa-duotone.fa-circle-y:before {
  content: "\e12f\fe01"; }

.fad.fa-circle-y:after, .fa-duotone.fa-circle-y:after {
  content: "\e12f\fe02"; }

.fad.fa-circle-z:before, .fa-duotone.fa-circle-z:before {
  content: "\e130\fe01"; }

.fad.fa-circle-z:after, .fa-duotone.fa-circle-z:after {
  content: "\e130\fe02"; }

.fad.fa-citrus:before, .fa-duotone.fa-citrus:before {
  content: "\e2f4\fe01"; }

.fad.fa-citrus:after, .fa-duotone.fa-citrus:after {
  content: "\e2f4\fe02"; }

.fad.fa-citrus-slice:before, .fa-duotone.fa-citrus-slice:before {
  content: "\e2f5\fe01"; }

.fad.fa-citrus-slice:after, .fa-duotone.fa-citrus-slice:after {
  content: "\e2f5\fe02"; }

.fad.fa-city:before, .fa-duotone.fa-city:before {
  content: "\f64f"; }

.fad.fa-city:after, .fa-duotone.fa-city:after {
  content: "\10f64f"; }

.fad.fa-clapperboard:before, .fa-duotone.fa-clapperboard:before {
  content: "\e131\fe01"; }

.fad.fa-clapperboard:after, .fa-duotone.fa-clapperboard:after {
  content: "\e131\fe02"; }

.fad.fa-clapperboard-play:before, .fa-duotone.fa-clapperboard-play:before {
  content: "\e132\fe01"; }

.fad.fa-clapperboard-play:after, .fa-duotone.fa-clapperboard-play:after {
  content: "\e132\fe02"; }

.fad.fa-clarinet:before, .fa-duotone.fa-clarinet:before {
  content: "\f8ad\fe01"; }

.fad.fa-clarinet:after, .fa-duotone.fa-clarinet:after {
  content: "\f8ad\fe02"; }

.fad.fa-claw-marks:before, .fa-duotone.fa-claw-marks:before {
  content: "\f6c2\fe01"; }

.fad.fa-claw-marks:after, .fa-duotone.fa-claw-marks:after {
  content: "\f6c2\fe02"; }

.fad.fa-clipboard:before, .fa-duotone.fa-clipboard:before {
  content: "\f328"; }

.fad.fa-clipboard:after, .fa-duotone.fa-clipboard:after {
  content: "\10f328"; }

.fad.fa-clipboard-check:before, .fa-duotone.fa-clipboard-check:before {
  content: "\f46c\fe01"; }

.fad.fa-clipboard-check:after, .fa-duotone.fa-clipboard-check:after {
  content: "\f46c\fe02"; }

.fad.fa-clipboard-list:before, .fa-duotone.fa-clipboard-list:before {
  content: "\f46d\fe01"; }

.fad.fa-clipboard-list:after, .fa-duotone.fa-clipboard-list:after {
  content: "\f46d\fe02"; }

.fad.fa-clipboard-list-check:before, .fa-duotone.fa-clipboard-list-check:before {
  content: "\f737\fe01"; }

.fad.fa-clipboard-list-check:after, .fa-duotone.fa-clipboard-list-check:after {
  content: "\f737\fe02"; }

.fad.fa-clipboard-medical:before, .fa-duotone.fa-clipboard-medical:before {
  content: "\e133\fe01"; }

.fad.fa-clipboard-medical:after, .fa-duotone.fa-clipboard-medical:after {
  content: "\e133\fe02"; }

.fad.fa-clipboard-prescription:before, .fa-duotone.fa-clipboard-prescription:before {
  content: "\f5e8\fe01"; }

.fad.fa-clipboard-prescription:after, .fa-duotone.fa-clipboard-prescription:after {
  content: "\f5e8\fe02"; }

.fad.fa-clipboard-user:before, .fa-duotone.fa-clipboard-user:before {
  content: "\f7f3\fe01"; }

.fad.fa-clipboard-user:after, .fa-duotone.fa-clipboard-user:after {
  content: "\f7f3\fe02"; }

.fad.fa-clock:before, .fa-duotone.fa-clock:before {
  content: "\f017"; }

.fad.fa-clock:after, .fa-duotone.fa-clock:after {
  content: "\10f017"; }

.fad.fa-clock-four:before, .fa-duotone.fa-clock-four:before {
  content: "\f017"; }

.fad.fa-clock-four:after, .fa-duotone.fa-clock-four:after {
  content: "\10f017"; }

.fad.fa-clock-desk:before, .fa-duotone.fa-clock-desk:before {
  content: "\e134\fe01"; }

.fad.fa-clock-desk:after, .fa-duotone.fa-clock-desk:after {
  content: "\e134\fe02"; }

.fad.fa-clock-eight:before, .fa-duotone.fa-clock-eight:before {
  content: "\e345\fe01"; }

.fad.fa-clock-eight:after, .fa-duotone.fa-clock-eight:after {
  content: "\e345\fe02"; }

.fad.fa-clock-eight-thirty:before, .fa-duotone.fa-clock-eight-thirty:before {
  content: "\e346\fe01"; }

.fad.fa-clock-eight-thirty:after, .fa-duotone.fa-clock-eight-thirty:after {
  content: "\e346\fe02"; }

.fad.fa-clock-eleven:before, .fa-duotone.fa-clock-eleven:before {
  content: "\e347\fe01"; }

.fad.fa-clock-eleven:after, .fa-duotone.fa-clock-eleven:after {
  content: "\e347\fe02"; }

.fad.fa-clock-eleven-thirty:before, .fa-duotone.fa-clock-eleven-thirty:before {
  content: "\e348\fe01"; }

.fad.fa-clock-eleven-thirty:after, .fa-duotone.fa-clock-eleven-thirty:after {
  content: "\e348\fe02"; }

.fad.fa-clock-five:before, .fa-duotone.fa-clock-five:before {
  content: "\e349\fe01"; }

.fad.fa-clock-five:after, .fa-duotone.fa-clock-five:after {
  content: "\e349\fe02"; }

.fad.fa-clock-five-thirty:before, .fa-duotone.fa-clock-five-thirty:before {
  content: "\e34a\fe01"; }

.fad.fa-clock-five-thirty:after, .fa-duotone.fa-clock-five-thirty:after {
  content: "\e34a\fe02"; }

.fad.fa-clock-four-thirty:before, .fa-duotone.fa-clock-four-thirty:before {
  content: "\e34b\fe01"; }

.fad.fa-clock-four-thirty:after, .fa-duotone.fa-clock-four-thirty:after {
  content: "\e34b\fe02"; }

.fad.fa-clock-nine:before, .fa-duotone.fa-clock-nine:before {
  content: "\e34c\fe01"; }

.fad.fa-clock-nine:after, .fa-duotone.fa-clock-nine:after {
  content: "\e34c\fe02"; }

.fad.fa-clock-nine-thirty:before, .fa-duotone.fa-clock-nine-thirty:before {
  content: "\e34d\fe01"; }

.fad.fa-clock-nine-thirty:after, .fa-duotone.fa-clock-nine-thirty:after {
  content: "\e34d\fe02"; }

.fad.fa-clock-one:before, .fa-duotone.fa-clock-one:before {
  content: "\e34e\fe01"; }

.fad.fa-clock-one:after, .fa-duotone.fa-clock-one:after {
  content: "\e34e\fe02"; }

.fad.fa-clock-one-thirty:before, .fa-duotone.fa-clock-one-thirty:before {
  content: "\e34f\fe01"; }

.fad.fa-clock-one-thirty:after, .fa-duotone.fa-clock-one-thirty:after {
  content: "\e34f\fe02"; }

.fad.fa-clock-rotate-left:before, .fa-duotone.fa-clock-rotate-left:before {
  content: "\f1da\fe01"; }

.fad.fa-clock-rotate-left:after, .fa-duotone.fa-clock-rotate-left:after {
  content: "\f1da\fe02"; }

.fad.fa-history:before, .fa-duotone.fa-history:before {
  content: "\f1da\fe01"; }

.fad.fa-history:after, .fa-duotone.fa-history:after {
  content: "\f1da\fe02"; }

.fad.fa-clock-seven:before, .fa-duotone.fa-clock-seven:before {
  content: "\e350\fe01"; }

.fad.fa-clock-seven:after, .fa-duotone.fa-clock-seven:after {
  content: "\e350\fe02"; }

.fad.fa-clock-seven-thirty:before, .fa-duotone.fa-clock-seven-thirty:before {
  content: "\e351\fe01"; }

.fad.fa-clock-seven-thirty:after, .fa-duotone.fa-clock-seven-thirty:after {
  content: "\e351\fe02"; }

.fad.fa-clock-six:before, .fa-duotone.fa-clock-six:before {
  content: "\e352\fe01"; }

.fad.fa-clock-six:after, .fa-duotone.fa-clock-six:after {
  content: "\e352\fe02"; }

.fad.fa-clock-six-thirty:before, .fa-duotone.fa-clock-six-thirty:before {
  content: "\e353\fe01"; }

.fad.fa-clock-six-thirty:after, .fa-duotone.fa-clock-six-thirty:after {
  content: "\e353\fe02"; }

.fad.fa-clock-ten:before, .fa-duotone.fa-clock-ten:before {
  content: "\e354\fe01"; }

.fad.fa-clock-ten:after, .fa-duotone.fa-clock-ten:after {
  content: "\e354\fe02"; }

.fad.fa-clock-ten-thirty:before, .fa-duotone.fa-clock-ten-thirty:before {
  content: "\e355\fe01"; }

.fad.fa-clock-ten-thirty:after, .fa-duotone.fa-clock-ten-thirty:after {
  content: "\e355\fe02"; }

.fad.fa-clock-three:before, .fa-duotone.fa-clock-three:before {
  content: "\e356\fe01"; }

.fad.fa-clock-three:after, .fa-duotone.fa-clock-three:after {
  content: "\e356\fe02"; }

.fad.fa-clock-three-thirty:before, .fa-duotone.fa-clock-three-thirty:before {
  content: "\e357\fe01"; }

.fad.fa-clock-three-thirty:after, .fa-duotone.fa-clock-three-thirty:after {
  content: "\e357\fe02"; }

.fad.fa-clock-twelve:before, .fa-duotone.fa-clock-twelve:before {
  content: "\e358\fe01"; }

.fad.fa-clock-twelve:after, .fa-duotone.fa-clock-twelve:after {
  content: "\e358\fe02"; }

.fad.fa-clock-twelve-thirty:before, .fa-duotone.fa-clock-twelve-thirty:before {
  content: "\e359\fe01"; }

.fad.fa-clock-twelve-thirty:after, .fa-duotone.fa-clock-twelve-thirty:after {
  content: "\e359\fe02"; }

.fad.fa-clock-two:before, .fa-duotone.fa-clock-two:before {
  content: "\e35a\fe01"; }

.fad.fa-clock-two:after, .fa-duotone.fa-clock-two:after {
  content: "\e35a\fe02"; }

.fad.fa-clock-two-thirty:before, .fa-duotone.fa-clock-two-thirty:before {
  content: "\e35b\fe01"; }

.fad.fa-clock-two-thirty:after, .fa-duotone.fa-clock-two-thirty:after {
  content: "\e35b\fe02"; }

.fad.fa-clone:before, .fa-duotone.fa-clone:before {
  content: "\f24d\fe01"; }

.fad.fa-clone:after, .fa-duotone.fa-clone:after {
  content: "\f24d\fe02"; }

.fad.fa-closed-captioning:before, .fa-duotone.fa-closed-captioning:before {
  content: "\f20a\fe01"; }

.fad.fa-closed-captioning:after, .fa-duotone.fa-closed-captioning:after {
  content: "\f20a\fe02"; }

.fad.fa-closed-captioning-slash:before, .fa-duotone.fa-closed-captioning-slash:before {
  content: "\e135\fe01"; }

.fad.fa-closed-captioning-slash:after, .fa-duotone.fa-closed-captioning-slash:after {
  content: "\e135\fe02"; }

.fad.fa-clothes-hanger:before, .fa-duotone.fa-clothes-hanger:before {
  content: "\e136\fe01"; }

.fad.fa-clothes-hanger:after, .fa-duotone.fa-clothes-hanger:after {
  content: "\e136\fe02"; }

.fad.fa-cloud:before, .fa-duotone.fa-cloud:before {
  content: "\2601\fe01"; }

.fad.fa-cloud:after, .fa-duotone.fa-cloud:after {
  content: "\2601\fe02"; }

.fad.fa-cloud-arrow-down:before, .fa-duotone.fa-cloud-arrow-down:before {
  content: "\f0ed\fe01"; }

.fad.fa-cloud-arrow-down:after, .fa-duotone.fa-cloud-arrow-down:after {
  content: "\f0ed\fe02"; }

.fad.fa-cloud-download:before, .fa-duotone.fa-cloud-download:before {
  content: "\f0ed\fe01"; }

.fad.fa-cloud-download:after, .fa-duotone.fa-cloud-download:after {
  content: "\f0ed\fe02"; }

.fad.fa-cloud-download-alt:before, .fa-duotone.fa-cloud-download-alt:before {
  content: "\f0ed\fe01"; }

.fad.fa-cloud-download-alt:after, .fa-duotone.fa-cloud-download-alt:after {
  content: "\f0ed\fe02"; }

.fad.fa-cloud-arrow-up:before, .fa-duotone.fa-cloud-arrow-up:before {
  content: "\f0ee\fe01"; }

.fad.fa-cloud-arrow-up:after, .fa-duotone.fa-cloud-arrow-up:after {
  content: "\f0ee\fe02"; }

.fad.fa-cloud-upload:before, .fa-duotone.fa-cloud-upload:before {
  content: "\f0ee\fe01"; }

.fad.fa-cloud-upload:after, .fa-duotone.fa-cloud-upload:after {
  content: "\f0ee\fe02"; }

.fad.fa-cloud-upload-alt:before, .fa-duotone.fa-cloud-upload-alt:before {
  content: "\f0ee\fe01"; }

.fad.fa-cloud-upload-alt:after, .fa-duotone.fa-cloud-upload-alt:after {
  content: "\f0ee\fe02"; }

.fad.fa-cloud-bolt:before, .fa-duotone.fa-cloud-bolt:before {
  content: "\f76c"; }

.fad.fa-cloud-bolt:after, .fa-duotone.fa-cloud-bolt:after {
  content: "\10f76c"; }

.fad.fa-thunderstorm:before, .fa-duotone.fa-thunderstorm:before {
  content: "\f76c"; }

.fad.fa-thunderstorm:after, .fa-duotone.fa-thunderstorm:after {
  content: "\10f76c"; }

.fad.fa-cloud-bolt-moon:before, .fa-duotone.fa-cloud-bolt-moon:before {
  content: "\f76d\fe01"; }

.fad.fa-cloud-bolt-moon:after, .fa-duotone.fa-cloud-bolt-moon:after {
  content: "\f76d\fe02"; }

.fad.fa-thunderstorm-moon:before, .fa-duotone.fa-thunderstorm-moon:before {
  content: "\f76d\fe01"; }

.fad.fa-thunderstorm-moon:after, .fa-duotone.fa-thunderstorm-moon:after {
  content: "\f76d\fe02"; }

.fad.fa-cloud-bolt-sun:before, .fa-duotone.fa-cloud-bolt-sun:before {
  content: "\f76e\fe01"; }

.fad.fa-cloud-bolt-sun:after, .fa-duotone.fa-cloud-bolt-sun:after {
  content: "\f76e\fe02"; }

.fad.fa-thunderstorm-sun:before, .fa-duotone.fa-thunderstorm-sun:before {
  content: "\f76e\fe01"; }

.fad.fa-thunderstorm-sun:after, .fa-duotone.fa-thunderstorm-sun:after {
  content: "\f76e\fe02"; }

.fad.fa-cloud-check:before, .fa-duotone.fa-cloud-check:before {
  content: "\e35c\fe01"; }

.fad.fa-cloud-check:after, .fa-duotone.fa-cloud-check:after {
  content: "\e35c\fe02"; }

.fad.fa-cloud-drizzle:before, .fa-duotone.fa-cloud-drizzle:before {
  content: "\f738\fe01"; }

.fad.fa-cloud-drizzle:after, .fa-duotone.fa-cloud-drizzle:after {
  content: "\f738\fe02"; }

.fad.fa-cloud-fog:before, .fa-duotone.fa-cloud-fog:before {
  content: "\f74e"; }

.fad.fa-cloud-fog:after, .fa-duotone.fa-cloud-fog:after {
  content: "\10f74e"; }

.fad.fa-fog:before, .fa-duotone.fa-fog:before {
  content: "\f74e"; }

.fad.fa-fog:after, .fa-duotone.fa-fog:after {
  content: "\10f74e"; }

.fad.fa-cloud-hail:before, .fa-duotone.fa-cloud-hail:before {
  content: "\f739\fe01"; }

.fad.fa-cloud-hail:after, .fa-duotone.fa-cloud-hail:after {
  content: "\f739\fe02"; }

.fad.fa-cloud-hail-mixed:before, .fa-duotone.fa-cloud-hail-mixed:before {
  content: "\f73a\fe01"; }

.fad.fa-cloud-hail-mixed:after, .fa-duotone.fa-cloud-hail-mixed:after {
  content: "\f73a\fe02"; }

.fad.fa-cloud-meatball:before, .fa-duotone.fa-cloud-meatball:before {
  content: "\f73b\fe01"; }

.fad.fa-cloud-meatball:after, .fa-duotone.fa-cloud-meatball:after {
  content: "\f73b\fe02"; }

.fad.fa-cloud-minus:before, .fa-duotone.fa-cloud-minus:before {
  content: "\e35d\fe01"; }

.fad.fa-cloud-minus:after, .fa-duotone.fa-cloud-minus:after {
  content: "\e35d\fe02"; }

.fad.fa-cloud-moon:before, .fa-duotone.fa-cloud-moon:before {
  content: "\f6c3\fe01"; }

.fad.fa-cloud-moon:after, .fa-duotone.fa-cloud-moon:after {
  content: "\f6c3\fe02"; }

.fad.fa-cloud-moon-rain:before, .fa-duotone.fa-cloud-moon-rain:before {
  content: "\f73c\fe01"; }

.fad.fa-cloud-moon-rain:after, .fa-duotone.fa-cloud-moon-rain:after {
  content: "\f73c\fe02"; }

.fad.fa-cloud-music:before, .fa-duotone.fa-cloud-music:before {
  content: "\f8ae\fe01"; }

.fad.fa-cloud-music:after, .fa-duotone.fa-cloud-music:after {
  content: "\f8ae\fe02"; }

.fad.fa-cloud-plus:before, .fa-duotone.fa-cloud-plus:before {
  content: "\e35e\fe01"; }

.fad.fa-cloud-plus:after, .fa-duotone.fa-cloud-plus:after {
  content: "\e35e\fe02"; }

.fad.fa-cloud-rain:before, .fa-duotone.fa-cloud-rain:before {
  content: "\26c6\fe01"; }

.fad.fa-cloud-rain:after, .fa-duotone.fa-cloud-rain:after {
  content: "\26c6\fe02"; }

.fad.fa-cloud-rainbow:before, .fa-duotone.fa-cloud-rainbow:before {
  content: "\f73e\fe01"; }

.fad.fa-cloud-rainbow:after, .fa-duotone.fa-cloud-rainbow:after {
  content: "\f73e\fe02"; }

.fad.fa-cloud-showers:before, .fa-duotone.fa-cloud-showers:before {
  content: "\f73f\fe01"; }

.fad.fa-cloud-showers:after, .fa-duotone.fa-cloud-showers:after {
  content: "\f73f\fe02"; }

.fad.fa-cloud-showers-heavy:before, .fa-duotone.fa-cloud-showers-heavy:before {
  content: "\f740\fe01"; }

.fad.fa-cloud-showers-heavy:after, .fa-duotone.fa-cloud-showers-heavy:after {
  content: "\f740\fe02"; }

.fad.fa-cloud-slash:before, .fa-duotone.fa-cloud-slash:before {
  content: "\e137\fe01"; }

.fad.fa-cloud-slash:after, .fa-duotone.fa-cloud-slash:after {
  content: "\e137\fe02"; }

.fad.fa-cloud-sleet:before, .fa-duotone.fa-cloud-sleet:before {
  content: "\f741\fe01"; }

.fad.fa-cloud-sleet:after, .fa-duotone.fa-cloud-sleet:after {
  content: "\f741\fe02"; }

.fad.fa-cloud-snow:before, .fa-duotone.fa-cloud-snow:before {
  content: "\f742"; }

.fad.fa-cloud-snow:after, .fa-duotone.fa-cloud-snow:after {
  content: "\10f742"; }

.fad.fa-cloud-sun:before, .fa-duotone.fa-cloud-sun:before {
  content: "\26c5\fe01"; }

.fad.fa-cloud-sun:after, .fa-duotone.fa-cloud-sun:after {
  content: "\26c5\fe02"; }

.fad.fa-cloud-sun-rain:before, .fa-duotone.fa-cloud-sun-rain:before {
  content: "\f743"; }

.fad.fa-cloud-sun-rain:after, .fa-duotone.fa-cloud-sun-rain:after {
  content: "\10f743"; }

.fad.fa-cloud-word:before, .fa-duotone.fa-cloud-word:before {
  content: "\e138\fe01"; }

.fad.fa-cloud-word:after, .fa-duotone.fa-cloud-word:after {
  content: "\e138\fe02"; }

.fad.fa-cloud-xmark:before, .fa-duotone.fa-cloud-xmark:before {
  content: "\e35f\fe01"; }

.fad.fa-cloud-xmark:after, .fa-duotone.fa-cloud-xmark:after {
  content: "\e35f\fe02"; }

.fad.fa-clouds:before, .fa-duotone.fa-clouds:before {
  content: "\f744\fe01"; }

.fad.fa-clouds:after, .fa-duotone.fa-clouds:after {
  content: "\f744\fe02"; }

.fad.fa-clouds-moon:before, .fa-duotone.fa-clouds-moon:before {
  content: "\f745\fe01"; }

.fad.fa-clouds-moon:after, .fa-duotone.fa-clouds-moon:after {
  content: "\f745\fe02"; }

.fad.fa-clouds-sun:before, .fa-duotone.fa-clouds-sun:before {
  content: "\f746\fe01"; }

.fad.fa-clouds-sun:after, .fa-duotone.fa-clouds-sun:after {
  content: "\f746\fe02"; }

.fad.fa-clover:before, .fa-duotone.fa-clover:before {
  content: "\e139\fe01"; }

.fad.fa-clover:after, .fa-duotone.fa-clover:after {
  content: "\e139\fe02"; }

.fad.fa-club:before, .fa-duotone.fa-club:before {
  content: "\2663\fe01"; }

.fad.fa-club:after, .fa-duotone.fa-club:after {
  content: "\2663\fe02"; }

.fad.fa-coconut:before, .fa-duotone.fa-coconut:before {
  content: "\e2f6\fe01"; }

.fad.fa-coconut:after, .fa-duotone.fa-coconut:after {
  content: "\e2f6\fe02"; }

.fad.fa-code:before, .fa-duotone.fa-code:before {
  content: "\f121\fe01"; }

.fad.fa-code:after, .fa-duotone.fa-code:after {
  content: "\f121\fe02"; }

.fad.fa-code-branch:before, .fa-duotone.fa-code-branch:before {
  content: "\f126\fe01"; }

.fad.fa-code-branch:after, .fa-duotone.fa-code-branch:after {
  content: "\f126\fe02"; }

.fad.fa-code-commit:before, .fa-duotone.fa-code-commit:before {
  content: "\f386\fe01"; }

.fad.fa-code-commit:after, .fa-duotone.fa-code-commit:after {
  content: "\f386\fe02"; }

.fad.fa-code-compare:before, .fa-duotone.fa-code-compare:before {
  content: "\e13a\fe01"; }

.fad.fa-code-compare:after, .fa-duotone.fa-code-compare:after {
  content: "\e13a\fe02"; }

.fad.fa-code-fork:before, .fa-duotone.fa-code-fork:before {
  content: "\e13b\fe01"; }

.fad.fa-code-fork:after, .fa-duotone.fa-code-fork:after {
  content: "\e13b\fe02"; }

.fad.fa-code-merge:before, .fa-duotone.fa-code-merge:before {
  content: "\f387\fe01"; }

.fad.fa-code-merge:after, .fa-duotone.fa-code-merge:after {
  content: "\f387\fe02"; }

.fad.fa-code-pull-request:before, .fa-duotone.fa-code-pull-request:before {
  content: "\e13c\fe01"; }

.fad.fa-code-pull-request:after, .fa-duotone.fa-code-pull-request:after {
  content: "\e13c\fe02"; }

.fad.fa-code-simple:before, .fa-duotone.fa-code-simple:before {
  content: "\e13d\fe01"; }

.fad.fa-code-simple:after, .fa-duotone.fa-code-simple:after {
  content: "\e13d\fe02"; }

.fad.fa-coffee-bean:before, .fa-duotone.fa-coffee-bean:before {
  content: "\e13e\fe01"; }

.fad.fa-coffee-bean:after, .fa-duotone.fa-coffee-bean:after {
  content: "\e13e\fe02"; }

.fad.fa-coffee-beans:before, .fa-duotone.fa-coffee-beans:before {
  content: "\e13f\fe01"; }

.fad.fa-coffee-beans:after, .fa-duotone.fa-coffee-beans:after {
  content: "\e13f\fe02"; }

.fad.fa-coffee-pot:before, .fa-duotone.fa-coffee-pot:before {
  content: "\e002\fe01"; }

.fad.fa-coffee-pot:after, .fa-duotone.fa-coffee-pot:after {
  content: "\e002\fe02"; }

.fad.fa-coffin:before, .fa-duotone.fa-coffin:before {
  content: "\26b0\fe01"; }

.fad.fa-coffin:after, .fa-duotone.fa-coffin:after {
  content: "\26b0\fe02"; }

.fad.fa-coffin-cross:before, .fa-duotone.fa-coffin-cross:before {
  content: "\e051\fe01"; }

.fad.fa-coffin-cross:after, .fa-duotone.fa-coffin-cross:after {
  content: "\e051\fe02"; }

.fad.fa-coin:before, .fa-duotone.fa-coin:before {
  content: "\f85c"; }

.fad.fa-coin:after, .fa-duotone.fa-coin:after {
  content: "\10f85c"; }

.fad.fa-coins:before, .fa-duotone.fa-coins:before {
  content: "\f51e\fe01"; }

.fad.fa-coins:after, .fa-duotone.fa-coins:after {
  content: "\f51e\fe02"; }

.fad.fa-colon:before, .fa-duotone.fa-colon:before {
  content: "\3a\fe01"; }

.fad.fa-colon:after, .fa-duotone.fa-colon:after {
  content: "\3a\fe02"; }

.fad.fa-colon-sign:before, .fa-duotone.fa-colon-sign:before {
  content: "\e140\fe01"; }

.fad.fa-colon-sign:after, .fa-duotone.fa-colon-sign:after {
  content: "\e140\fe02"; }

.fad.fa-columns-3:before, .fa-duotone.fa-columns-3:before {
  content: "\e361\fe01"; }

.fad.fa-columns-3:after, .fa-duotone.fa-columns-3:after {
  content: "\e361\fe02"; }

.fad.fa-comet:before, .fa-duotone.fa-comet:before {
  content: "\e003\fe01"; }

.fad.fa-comet:after, .fa-duotone.fa-comet:after {
  content: "\e003\fe02"; }

.fad.fa-comma:before, .fa-duotone.fa-comma:before {
  content: "\2c\fe01"; }

.fad.fa-comma:after, .fa-duotone.fa-comma:after {
  content: "\2c\fe02"; }

.fad.fa-command:before, .fa-duotone.fa-command:before {
  content: "\e142\fe01"; }

.fad.fa-command:after, .fa-duotone.fa-command:after {
  content: "\e142\fe02"; }

.fad.fa-comment:before, .fa-duotone.fa-comment:before {
  content: "\f075"; }

.fad.fa-comment:after, .fa-duotone.fa-comment:after {
  content: "\10f075"; }

.fad.fa-comment-arrow-down:before, .fa-duotone.fa-comment-arrow-down:before {
  content: "\e143\fe01"; }

.fad.fa-comment-arrow-down:after, .fa-duotone.fa-comment-arrow-down:after {
  content: "\e143\fe02"; }

.fad.fa-comment-arrow-up:before, .fa-duotone.fa-comment-arrow-up:before {
  content: "\e144\fe01"; }

.fad.fa-comment-arrow-up:after, .fa-duotone.fa-comment-arrow-up:after {
  content: "\e144\fe02"; }

.fad.fa-comment-arrow-up-right:before, .fa-duotone.fa-comment-arrow-up-right:before {
  content: "\e145\fe01"; }

.fad.fa-comment-arrow-up-right:after, .fa-duotone.fa-comment-arrow-up-right:after {
  content: "\e145\fe02"; }

.fad.fa-comment-captions:before, .fa-duotone.fa-comment-captions:before {
  content: "\e146\fe01"; }

.fad.fa-comment-captions:after, .fa-duotone.fa-comment-captions:after {
  content: "\e146\fe02"; }

.fad.fa-comment-check:before, .fa-duotone.fa-comment-check:before {
  content: "\f4ac\fe01"; }

.fad.fa-comment-check:after, .fa-duotone.fa-comment-check:after {
  content: "\f4ac\fe02"; }

.fad.fa-comment-code:before, .fa-duotone.fa-comment-code:before {
  content: "\e147\fe01"; }

.fad.fa-comment-code:after, .fa-duotone.fa-comment-code:after {
  content: "\e147\fe02"; }

.fad.fa-comment-dollar:before, .fa-duotone.fa-comment-dollar:before {
  content: "\f651\fe01"; }

.fad.fa-comment-dollar:after, .fa-duotone.fa-comment-dollar:after {
  content: "\f651\fe02"; }

.fad.fa-comment-dots:before, .fa-duotone.fa-comment-dots:before {
  content: "\f4ad"; }

.fad.fa-comment-dots:after, .fa-duotone.fa-comment-dots:after {
  content: "\10f4ad"; }

.fad.fa-commenting:before, .fa-duotone.fa-commenting:before {
  content: "\f4ad"; }

.fad.fa-commenting:after, .fa-duotone.fa-commenting:after {
  content: "\10f4ad"; }

.fad.fa-comment-exclamation:before, .fa-duotone.fa-comment-exclamation:before {
  content: "\f4af\fe01"; }

.fad.fa-comment-exclamation:after, .fa-duotone.fa-comment-exclamation:after {
  content: "\f4af\fe02"; }

.fad.fa-comment-image:before, .fa-duotone.fa-comment-image:before {
  content: "\e148\fe01"; }

.fad.fa-comment-image:after, .fa-duotone.fa-comment-image:after {
  content: "\e148\fe02"; }

.fad.fa-comment-lines:before, .fa-duotone.fa-comment-lines:before {
  content: "\f4b0\fe01"; }

.fad.fa-comment-lines:after, .fa-duotone.fa-comment-lines:after {
  content: "\f4b0\fe02"; }

.fad.fa-comment-medical:before, .fa-duotone.fa-comment-medical:before {
  content: "\f7f5\fe01"; }

.fad.fa-comment-medical:after, .fa-duotone.fa-comment-medical:after {
  content: "\f7f5\fe02"; }

.fad.fa-comment-middle:before, .fa-duotone.fa-comment-middle:before {
  content: "\e149\fe01"; }

.fad.fa-comment-middle:after, .fa-duotone.fa-comment-middle:after {
  content: "\e149\fe02"; }

.fad.fa-comment-middle-top:before, .fa-duotone.fa-comment-middle-top:before {
  content: "\e14a\fe01"; }

.fad.fa-comment-middle-top:after, .fa-duotone.fa-comment-middle-top:after {
  content: "\e14a\fe02"; }

.fad.fa-comment-minus:before, .fa-duotone.fa-comment-minus:before {
  content: "\f4b1\fe01"; }

.fad.fa-comment-minus:after, .fa-duotone.fa-comment-minus:after {
  content: "\f4b1\fe02"; }

.fad.fa-comment-music:before, .fa-duotone.fa-comment-music:before {
  content: "\f8b0\fe01"; }

.fad.fa-comment-music:after, .fa-duotone.fa-comment-music:after {
  content: "\f8b0\fe02"; }

.fad.fa-comment-pen:before, .fa-duotone.fa-comment-pen:before {
  content: "\f4ae\fe01"; }

.fad.fa-comment-pen:after, .fa-duotone.fa-comment-pen:after {
  content: "\f4ae\fe02"; }

.fad.fa-comment-edit:before, .fa-duotone.fa-comment-edit:before {
  content: "\f4ae\fe01"; }

.fad.fa-comment-edit:after, .fa-duotone.fa-comment-edit:after {
  content: "\f4ae\fe02"; }

.fad.fa-comment-plus:before, .fa-duotone.fa-comment-plus:before {
  content: "\f4b2\fe01"; }

.fad.fa-comment-plus:after, .fa-duotone.fa-comment-plus:after {
  content: "\f4b2\fe02"; }

.fad.fa-comment-question:before, .fa-duotone.fa-comment-question:before {
  content: "\e14b\fe01"; }

.fad.fa-comment-question:after, .fa-duotone.fa-comment-question:after {
  content: "\e14b\fe02"; }

.fad.fa-comment-quote:before, .fa-duotone.fa-comment-quote:before {
  content: "\e14c\fe01"; }

.fad.fa-comment-quote:after, .fa-duotone.fa-comment-quote:after {
  content: "\e14c\fe02"; }

.fad.fa-comment-slash:before, .fa-duotone.fa-comment-slash:before {
  content: "\f4b3\fe01"; }

.fad.fa-comment-slash:after, .fa-duotone.fa-comment-slash:after {
  content: "\f4b3\fe02"; }

.fad.fa-comment-smile:before, .fa-duotone.fa-comment-smile:before {
  content: "\f4b4\fe01"; }

.fad.fa-comment-smile:after, .fa-duotone.fa-comment-smile:after {
  content: "\f4b4\fe02"; }

.fad.fa-comment-sms:before, .fa-duotone.fa-comment-sms:before {
  content: "\f7cd\fe01"; }

.fad.fa-comment-sms:after, .fa-duotone.fa-comment-sms:after {
  content: "\f7cd\fe02"; }

.fad.fa-sms:before, .fa-duotone.fa-sms:before {
  content: "\f7cd\fe01"; }

.fad.fa-sms:after, .fa-duotone.fa-sms:after {
  content: "\f7cd\fe02"; }

.fad.fa-comment-text:before, .fa-duotone.fa-comment-text:before {
  content: "\e14d\fe01"; }

.fad.fa-comment-text:after, .fa-duotone.fa-comment-text:after {
  content: "\e14d\fe02"; }

.fad.fa-comment-xmark:before, .fa-duotone.fa-comment-xmark:before {
  content: "\f4b5\fe01"; }

.fad.fa-comment-xmark:after, .fa-duotone.fa-comment-xmark:after {
  content: "\f4b5\fe02"; }

.fad.fa-comment-times:before, .fa-duotone.fa-comment-times:before {
  content: "\f4b5\fe01"; }

.fad.fa-comment-times:after, .fa-duotone.fa-comment-times:after {
  content: "\f4b5\fe02"; }

.fad.fa-comments:before, .fa-duotone.fa-comments:before {
  content: "\f086"; }

.fad.fa-comments:after, .fa-duotone.fa-comments:after {
  content: "\10f086"; }

.fad.fa-comments-dollar:before, .fa-duotone.fa-comments-dollar:before {
  content: "\f653\fe01"; }

.fad.fa-comments-dollar:after, .fa-duotone.fa-comments-dollar:after {
  content: "\f653\fe02"; }

.fad.fa-comments-question:before, .fa-duotone.fa-comments-question:before {
  content: "\e14e\fe01"; }

.fad.fa-comments-question:after, .fa-duotone.fa-comments-question:after {
  content: "\e14e\fe02"; }

.fad.fa-comments-question-check:before, .fa-duotone.fa-comments-question-check:before {
  content: "\e14f\fe01"; }

.fad.fa-comments-question-check:after, .fa-duotone.fa-comments-question-check:after {
  content: "\e14f\fe02"; }

.fad.fa-compact-disc:before, .fa-duotone.fa-compact-disc:before {
  content: "\f51f"; }

.fad.fa-compact-disc:after, .fa-duotone.fa-compact-disc:after {
  content: "\10f51f"; }

.fad.fa-compass:before, .fa-duotone.fa-compass:before {
  content: "\f14e"; }

.fad.fa-compass:after, .fa-duotone.fa-compass:after {
  content: "\10f14e"; }

.fad.fa-compass-drafting:before, .fa-duotone.fa-compass-drafting:before {
  content: "\f568\fe01"; }

.fad.fa-compass-drafting:after, .fa-duotone.fa-compass-drafting:after {
  content: "\f568\fe02"; }

.fad.fa-drafting-compass:before, .fa-duotone.fa-drafting-compass:before {
  content: "\f568\fe01"; }

.fad.fa-drafting-compass:after, .fa-duotone.fa-drafting-compass:after {
  content: "\f568\fe02"; }

.fad.fa-compass-slash:before, .fa-duotone.fa-compass-slash:before {
  content: "\f5e9\fe01"; }

.fad.fa-compass-slash:after, .fa-duotone.fa-compass-slash:after {
  content: "\f5e9\fe02"; }

.fad.fa-compress:before, .fa-duotone.fa-compress:before {
  content: "\f066\fe01"; }

.fad.fa-compress:after, .fa-duotone.fa-compress:after {
  content: "\f066\fe02"; }

.fad.fa-compress-wide:before, .fa-duotone.fa-compress-wide:before {
  content: "\f326\fe01"; }

.fad.fa-compress-wide:after, .fa-duotone.fa-compress-wide:after {
  content: "\f326\fe02"; }

.fad.fa-computer-classic:before, .fa-duotone.fa-computer-classic:before {
  content: "\f8b1\fe01"; }

.fad.fa-computer-classic:after, .fa-duotone.fa-computer-classic:after {
  content: "\f8b1\fe02"; }

.fad.fa-computer-mouse:before, .fa-duotone.fa-computer-mouse:before {
  content: "\f8cc"; }

.fad.fa-computer-mouse:after, .fa-duotone.fa-computer-mouse:after {
  content: "\10f8cc"; }

.fad.fa-mouse:before, .fa-duotone.fa-mouse:before {
  content: "\f8cc"; }

.fad.fa-mouse:after, .fa-duotone.fa-mouse:after {
  content: "\10f8cc"; }

.fad.fa-computer-mouse-scrollwheel:before, .fa-duotone.fa-computer-mouse-scrollwheel:before {
  content: "\f8cd\fe01"; }

.fad.fa-computer-mouse-scrollwheel:after, .fa-duotone.fa-computer-mouse-scrollwheel:after {
  content: "\f8cd\fe02"; }

.fad.fa-mouse-alt:before, .fa-duotone.fa-mouse-alt:before {
  content: "\f8cd\fe01"; }

.fad.fa-mouse-alt:after, .fa-duotone.fa-mouse-alt:after {
  content: "\f8cd\fe02"; }

.fad.fa-computer-speaker:before, .fa-duotone.fa-computer-speaker:before {
  content: "\f8b2\fe01"; }

.fad.fa-computer-speaker:after, .fa-duotone.fa-computer-speaker:after {
  content: "\f8b2\fe02"; }

.fad.fa-container-storage:before, .fa-duotone.fa-container-storage:before {
  content: "\f4b7\fe01"; }

.fad.fa-container-storage:after, .fa-duotone.fa-container-storage:after {
  content: "\f4b7\fe02"; }

.fad.fa-conveyor-belt:before, .fa-duotone.fa-conveyor-belt:before {
  content: "\f46e\fe01"; }

.fad.fa-conveyor-belt:after, .fa-duotone.fa-conveyor-belt:after {
  content: "\f46e\fe02"; }

.fad.fa-conveyor-belt-boxes:before, .fa-duotone.fa-conveyor-belt-boxes:before {
  content: "\f46f\fe01"; }

.fad.fa-conveyor-belt-boxes:after, .fa-duotone.fa-conveyor-belt-boxes:after {
  content: "\f46f\fe02"; }

.fad.fa-conveyor-belt-alt:before, .fa-duotone.fa-conveyor-belt-alt:before {
  content: "\f46f\fe01"; }

.fad.fa-conveyor-belt-alt:after, .fa-duotone.fa-conveyor-belt-alt:after {
  content: "\f46f\fe02"; }

.fad.fa-conveyor-belt-empty:before, .fa-duotone.fa-conveyor-belt-empty:before {
  content: "\e150\fe01"; }

.fad.fa-conveyor-belt-empty:after, .fa-duotone.fa-conveyor-belt-empty:after {
  content: "\e150\fe02"; }

.fad.fa-cookie:before, .fa-duotone.fa-cookie:before {
  content: "\f563"; }

.fad.fa-cookie:after, .fa-duotone.fa-cookie:after {
  content: "\10f563"; }

.fad.fa-cookie-bite:before, .fa-duotone.fa-cookie-bite:before {
  content: "\f564\fe01"; }

.fad.fa-cookie-bite:after, .fa-duotone.fa-cookie-bite:after {
  content: "\f564\fe02"; }

.fad.fa-copy:before, .fa-duotone.fa-copy:before {
  content: "\f0c5\fe01"; }

.fad.fa-copy:after, .fa-duotone.fa-copy:after {
  content: "\f0c5\fe02"; }

.fad.fa-copyright:before, .fa-duotone.fa-copyright:before {
  content: "\a9\fe01"; }

.fad.fa-copyright:after, .fa-duotone.fa-copyright:after {
  content: "\a9\fe02"; }

.fad.fa-corn:before, .fa-duotone.fa-corn:before {
  content: "\f6c7"; }

.fad.fa-corn:after, .fa-duotone.fa-corn:after {
  content: "\10f6c7"; }

.fad.fa-corner:before, .fa-duotone.fa-corner:before {
  content: "\ac\fe01"; }

.fad.fa-corner:after, .fa-duotone.fa-corner:after {
  content: "\ac\fe02"; }

.fad.fa-couch:before, .fa-duotone.fa-couch:before {
  content: "\f4b8\fe01"; }

.fad.fa-couch:after, .fa-duotone.fa-couch:after {
  content: "\f4b8\fe02"; }

.fad.fa-cow:before, .fa-duotone.fa-cow:before {
  content: "\f6c8"; }

.fad.fa-cow:after, .fa-duotone.fa-cow:after {
  content: "\10f6c8"; }

.fad.fa-cowbell:before, .fa-duotone.fa-cowbell:before {
  content: "\f8b3\fe01"; }

.fad.fa-cowbell:after, .fa-duotone.fa-cowbell:after {
  content: "\f8b3\fe02"; }

.fad.fa-cowbell-circle-plus:before, .fa-duotone.fa-cowbell-circle-plus:before {
  content: "\f8b4\fe01"; }

.fad.fa-cowbell-circle-plus:after, .fa-duotone.fa-cowbell-circle-plus:after {
  content: "\f8b4\fe02"; }

.fad.fa-cowbell-more:before, .fa-duotone.fa-cowbell-more:before {
  content: "\f8b4\fe01"; }

.fad.fa-cowbell-more:after, .fa-duotone.fa-cowbell-more:after {
  content: "\f8b4\fe02"; }

.fad.fa-crate-apple:before, .fa-duotone.fa-crate-apple:before {
  content: "\f6b1\fe01"; }

.fad.fa-crate-apple:after, .fa-duotone.fa-crate-apple:after {
  content: "\f6b1\fe02"; }

.fad.fa-apple-crate:before, .fa-duotone.fa-apple-crate:before {
  content: "\f6b1\fe01"; }

.fad.fa-apple-crate:after, .fa-duotone.fa-apple-crate:after {
  content: "\f6b1\fe02"; }

.fad.fa-crate-empty:before, .fa-duotone.fa-crate-empty:before {
  content: "\e151\fe01"; }

.fad.fa-crate-empty:after, .fa-duotone.fa-crate-empty:after {
  content: "\e151\fe02"; }

.fad.fa-credit-card:before, .fa-duotone.fa-credit-card:before {
  content: "\f09d"; }

.fad.fa-credit-card:after, .fa-duotone.fa-credit-card:after {
  content: "\10f09d"; }

.fad.fa-credit-card-alt:before, .fa-duotone.fa-credit-card-alt:before {
  content: "\f09d"; }

.fad.fa-credit-card-alt:after, .fa-duotone.fa-credit-card-alt:after {
  content: "\10f09d"; }

.fad.fa-credit-card-blank:before, .fa-duotone.fa-credit-card-blank:before {
  content: "\f389\fe01"; }

.fad.fa-credit-card-blank:after, .fa-duotone.fa-credit-card-blank:after {
  content: "\f389\fe02"; }

.fad.fa-credit-card-front:before, .fa-duotone.fa-credit-card-front:before {
  content: "\f38a\fe01"; }

.fad.fa-credit-card-front:after, .fa-duotone.fa-credit-card-front:after {
  content: "\f38a\fe02"; }

.fad.fa-cricket-bat-ball:before, .fa-duotone.fa-cricket-bat-ball:before {
  content: "\f449"; }

.fad.fa-cricket-bat-ball:after, .fa-duotone.fa-cricket-bat-ball:after {
  content: "\10f449"; }

.fad.fa-cricket:before, .fa-duotone.fa-cricket:before {
  content: "\f449"; }

.fad.fa-cricket:after, .fa-duotone.fa-cricket:after {
  content: "\10f449"; }

.fad.fa-croissant:before, .fa-duotone.fa-croissant:before {
  content: "\f7f6"; }

.fad.fa-croissant:after, .fa-duotone.fa-croissant:after {
  content: "\10f7f6"; }

.fad.fa-crop:before, .fa-duotone.fa-crop:before {
  content: "\f125\fe01"; }

.fad.fa-crop:after, .fa-duotone.fa-crop:after {
  content: "\f125\fe02"; }

.fad.fa-crop-simple:before, .fa-duotone.fa-crop-simple:before {
  content: "\f565\fe01"; }

.fad.fa-crop-simple:after, .fa-duotone.fa-crop-simple:after {
  content: "\f565\fe02"; }

.fad.fa-crop-alt:before, .fa-duotone.fa-crop-alt:before {
  content: "\f565\fe01"; }

.fad.fa-crop-alt:after, .fa-duotone.fa-crop-alt:after {
  content: "\f565\fe02"; }

.fad.fa-cross:before, .fa-duotone.fa-cross:before {
  content: "\271d\fe01"; }

.fad.fa-cross:after, .fa-duotone.fa-cross:after {
  content: "\271d\fe02"; }

.fad.fa-crosshairs:before, .fa-duotone.fa-crosshairs:before {
  content: "\f05b\fe01"; }

.fad.fa-crosshairs:after, .fa-duotone.fa-crosshairs:after {
  content: "\f05b\fe02"; }

.fad.fa-crow:before, .fa-duotone.fa-crow:before {
  content: "\f520\fe01"; }

.fad.fa-crow:after, .fa-duotone.fa-crow:after {
  content: "\f520\fe02"; }

.fad.fa-crown:before, .fa-duotone.fa-crown:before {
  content: "\f521"; }

.fad.fa-crown:after, .fa-duotone.fa-crown:after {
  content: "\10f521"; }

.fad.fa-crutch:before, .fa-duotone.fa-crutch:before {
  content: "\f7f7\fe01"; }

.fad.fa-crutch:after, .fa-duotone.fa-crutch:after {
  content: "\f7f7\fe02"; }

.fad.fa-crutches:before, .fa-duotone.fa-crutches:before {
  content: "\f7f8\fe01"; }

.fad.fa-crutches:after, .fa-duotone.fa-crutches:after {
  content: "\f7f8\fe02"; }

.fad.fa-cruzeiro-sign:before, .fa-duotone.fa-cruzeiro-sign:before {
  content: "\e152\fe01"; }

.fad.fa-cruzeiro-sign:after, .fa-duotone.fa-cruzeiro-sign:after {
  content: "\e152\fe02"; }

.fad.fa-crystal-ball:before, .fa-duotone.fa-crystal-ball:before {
  content: "\e362\fe01"; }

.fad.fa-crystal-ball:after, .fa-duotone.fa-crystal-ball:after {
  content: "\e362\fe02"; }

.fad.fa-cube:before, .fa-duotone.fa-cube:before {
  content: "\f1b2\fe01"; }

.fad.fa-cube:after, .fa-duotone.fa-cube:after {
  content: "\f1b2\fe02"; }

.fad.fa-cubes:before, .fa-duotone.fa-cubes:before {
  content: "\f1b3\fe01"; }

.fad.fa-cubes:after, .fa-duotone.fa-cubes:after {
  content: "\f1b3\fe02"; }

.fad.fa-cup-straw:before, .fa-duotone.fa-cup-straw:before {
  content: "\e363\fe01"; }

.fad.fa-cup-straw:after, .fa-duotone.fa-cup-straw:after {
  content: "\e363\fe02"; }

.fad.fa-cup-straw-swoosh:before, .fa-duotone.fa-cup-straw-swoosh:before {
  content: "\e364\fe01"; }

.fad.fa-cup-straw-swoosh:after, .fa-duotone.fa-cup-straw-swoosh:after {
  content: "\e364\fe02"; }

.fad.fa-cup-togo:before, .fa-duotone.fa-cup-togo:before {
  content: "\f6c5\fe01"; }

.fad.fa-cup-togo:after, .fa-duotone.fa-cup-togo:after {
  content: "\f6c5\fe02"; }

.fad.fa-coffee-togo:before, .fa-duotone.fa-coffee-togo:before {
  content: "\f6c5\fe01"; }

.fad.fa-coffee-togo:after, .fa-duotone.fa-coffee-togo:after {
  content: "\f6c5\fe02"; }

.fad.fa-curling-stone:before, .fa-duotone.fa-curling-stone:before {
  content: "\f44a"; }

.fad.fa-curling-stone:after, .fa-duotone.fa-curling-stone:after {
  content: "\10f44a"; }

.fad.fa-curling:before, .fa-duotone.fa-curling:before {
  content: "\f44a"; }

.fad.fa-curling:after, .fa-duotone.fa-curling:after {
  content: "\10f44a"; }

.fad.fa-d:before, .fa-duotone.fa-d:before {
  content: "\44\fe01"; }

.fad.fa-d:after, .fa-duotone.fa-d:after {
  content: "\44\fe02"; }

.fad.fa-dagger:before, .fa-duotone.fa-dagger:before {
  content: "\f6cb\fe01"; }

.fad.fa-dagger:after, .fa-duotone.fa-dagger:after {
  content: "\f6cb\fe02"; }

.fad.fa-dash:before, .fa-duotone.fa-dash:before {
  content: "\2014\fe01"; }

.fad.fa-dash:after, .fa-duotone.fa-dash:after {
  content: "\2014\fe02"; }

.fad.fa-database:before, .fa-duotone.fa-database:before {
  content: "\f1c0\fe01"; }

.fad.fa-database:after, .fa-duotone.fa-database:after {
  content: "\f1c0\fe02"; }

.fad.fa-deer:before, .fa-duotone.fa-deer:before {
  content: "\f78e"; }

.fad.fa-deer:after, .fa-duotone.fa-deer:after {
  content: "\10f78e"; }

.fad.fa-deer-rudolph:before, .fa-duotone.fa-deer-rudolph:before {
  content: "\f78f\fe01"; }

.fad.fa-deer-rudolph:after, .fa-duotone.fa-deer-rudolph:after {
  content: "\f78f\fe02"; }

.fad.fa-delete-left:before, .fa-duotone.fa-delete-left:before {
  content: "\232b\fe01"; }

.fad.fa-delete-left:after, .fa-duotone.fa-delete-left:after {
  content: "\232b\fe02"; }

.fad.fa-backspace:before, .fa-duotone.fa-backspace:before {
  content: "\232b\fe01"; }

.fad.fa-backspace:after, .fa-duotone.fa-backspace:after {
  content: "\232b\fe02"; }

.fad.fa-delete-right:before, .fa-duotone.fa-delete-right:before {
  content: "\e154\fe01"; }

.fad.fa-delete-right:after, .fa-duotone.fa-delete-right:after {
  content: "\e154\fe02"; }

.fad.fa-democrat:before, .fa-duotone.fa-democrat:before {
  content: "\f747\fe01"; }

.fad.fa-democrat:after, .fa-duotone.fa-democrat:after {
  content: "\f747\fe02"; }

.fad.fa-desktop:before, .fa-duotone.fa-desktop:before {
  content: "\f390"; }

.fad.fa-desktop:after, .fa-duotone.fa-desktop:after {
  content: "\10f390"; }

.fad.fa-desktop-alt:before, .fa-duotone.fa-desktop-alt:before {
  content: "\f390"; }

.fad.fa-desktop-alt:after, .fa-duotone.fa-desktop-alt:after {
  content: "\10f390"; }

.fad.fa-desktop-arrow-down:before, .fa-duotone.fa-desktop-arrow-down:before {
  content: "\e155\fe01"; }

.fad.fa-desktop-arrow-down:after, .fa-duotone.fa-desktop-arrow-down:after {
  content: "\e155\fe02"; }

.fad.fa-dharmachakra:before, .fa-duotone.fa-dharmachakra:before {
  content: "\2638\fe01"; }

.fad.fa-dharmachakra:after, .fa-duotone.fa-dharmachakra:after {
  content: "\2638\fe02"; }

.fad.fa-diagram-lean-canvas:before, .fa-duotone.fa-diagram-lean-canvas:before {
  content: "\e156\fe01"; }

.fad.fa-diagram-lean-canvas:after, .fa-duotone.fa-diagram-lean-canvas:after {
  content: "\e156\fe02"; }

.fad.fa-diagram-nested:before, .fa-duotone.fa-diagram-nested:before {
  content: "\e157\fe01"; }

.fad.fa-diagram-nested:after, .fa-duotone.fa-diagram-nested:after {
  content: "\e157\fe02"; }

.fad.fa-diagram-project:before, .fa-duotone.fa-diagram-project:before {
  content: "\f542\fe01"; }

.fad.fa-diagram-project:after, .fa-duotone.fa-diagram-project:after {
  content: "\f542\fe02"; }

.fad.fa-project-diagram:before, .fa-duotone.fa-project-diagram:before {
  content: "\f542\fe01"; }

.fad.fa-project-diagram:after, .fa-duotone.fa-project-diagram:after {
  content: "\f542\fe02"; }

.fad.fa-diagram-sankey:before, .fa-duotone.fa-diagram-sankey:before {
  content: "\e158\fe01"; }

.fad.fa-diagram-sankey:after, .fa-duotone.fa-diagram-sankey:after {
  content: "\e158\fe02"; }

.fad.fa-diagram-venn:before, .fa-duotone.fa-diagram-venn:before {
  content: "\e15a\fe01"; }

.fad.fa-diagram-venn:after, .fa-duotone.fa-diagram-venn:after {
  content: "\e15a\fe02"; }

.fad.fa-dial:before, .fa-duotone.fa-dial:before {
  content: "\e15b\fe01"; }

.fad.fa-dial:after, .fa-duotone.fa-dial:after {
  content: "\e15b\fe02"; }

.fad.fa-dial-med-high:before, .fa-duotone.fa-dial-med-high:before {
  content: "\e15b\fe01"; }

.fad.fa-dial-med-high:after, .fa-duotone.fa-dial-med-high:after {
  content: "\e15b\fe02"; }

.fad.fa-dial-high:before, .fa-duotone.fa-dial-high:before {
  content: "\e15c\fe01"; }

.fad.fa-dial-high:after, .fa-duotone.fa-dial-high:after {
  content: "\e15c\fe02"; }

.fad.fa-dial-low:before, .fa-duotone.fa-dial-low:before {
  content: "\e15d\fe01"; }

.fad.fa-dial-low:after, .fa-duotone.fa-dial-low:after {
  content: "\e15d\fe02"; }

.fad.fa-dial-max:before, .fa-duotone.fa-dial-max:before {
  content: "\e15e\fe01"; }

.fad.fa-dial-max:after, .fa-duotone.fa-dial-max:after {
  content: "\e15e\fe02"; }

.fad.fa-dial-med:before, .fa-duotone.fa-dial-med:before {
  content: "\e15f\fe01"; }

.fad.fa-dial-med:after, .fa-duotone.fa-dial-med:after {
  content: "\e15f\fe02"; }

.fad.fa-dial-med-low:before, .fa-duotone.fa-dial-med-low:before {
  content: "\e160\fe01"; }

.fad.fa-dial-med-low:after, .fa-duotone.fa-dial-med-low:after {
  content: "\e160\fe02"; }

.fad.fa-dial-min:before, .fa-duotone.fa-dial-min:before {
  content: "\e161\fe01"; }

.fad.fa-dial-min:after, .fa-duotone.fa-dial-min:after {
  content: "\e161\fe02"; }

.fad.fa-dial-off:before, .fa-duotone.fa-dial-off:before {
  content: "\e162\fe01"; }

.fad.fa-dial-off:after, .fa-duotone.fa-dial-off:after {
  content: "\e162\fe02"; }

.fad.fa-diamond:before, .fa-duotone.fa-diamond:before {
  content: "\2666\fe01"; }

.fad.fa-diamond:after, .fa-duotone.fa-diamond:after {
  content: "\2666\fe02"; }

.fad.fa-diamond-turn-right:before, .fa-duotone.fa-diamond-turn-right:before {
  content: "\f5eb\fe01"; }

.fad.fa-diamond-turn-right:after, .fa-duotone.fa-diamond-turn-right:after {
  content: "\f5eb\fe02"; }

.fad.fa-directions:before, .fa-duotone.fa-directions:before {
  content: "\f5eb\fe01"; }

.fad.fa-directions:after, .fa-duotone.fa-directions:after {
  content: "\f5eb\fe02"; }

.fad.fa-dice:before, .fa-duotone.fa-dice:before {
  content: "\f522"; }

.fad.fa-dice:after, .fa-duotone.fa-dice:after {
  content: "\10f522"; }

.fad.fa-dice-d10:before, .fa-duotone.fa-dice-d10:before {
  content: "\f6cd\fe01"; }

.fad.fa-dice-d10:after, .fa-duotone.fa-dice-d10:after {
  content: "\f6cd\fe02"; }

.fad.fa-dice-d12:before, .fa-duotone.fa-dice-d12:before {
  content: "\f6ce\fe01"; }

.fad.fa-dice-d12:after, .fa-duotone.fa-dice-d12:after {
  content: "\f6ce\fe02"; }

.fad.fa-dice-d20:before, .fa-duotone.fa-dice-d20:before {
  content: "\f6cf\fe01"; }

.fad.fa-dice-d20:after, .fa-duotone.fa-dice-d20:after {
  content: "\f6cf\fe02"; }

.fad.fa-dice-d4:before, .fa-duotone.fa-dice-d4:before {
  content: "\f6d0\fe01"; }

.fad.fa-dice-d4:after, .fa-duotone.fa-dice-d4:after {
  content: "\f6d0\fe02"; }

.fad.fa-dice-d6:before, .fa-duotone.fa-dice-d6:before {
  content: "\f6d1\fe01"; }

.fad.fa-dice-d6:after, .fa-duotone.fa-dice-d6:after {
  content: "\f6d1\fe02"; }

.fad.fa-dice-d8:before, .fa-duotone.fa-dice-d8:before {
  content: "\f6d2\fe01"; }

.fad.fa-dice-d8:after, .fa-duotone.fa-dice-d8:after {
  content: "\f6d2\fe02"; }

.fad.fa-dice-five:before, .fa-duotone.fa-dice-five:before {
  content: "\2684\fe01"; }

.fad.fa-dice-five:after, .fa-duotone.fa-dice-five:after {
  content: "\2684\fe02"; }

.fad.fa-dice-four:before, .fa-duotone.fa-dice-four:before {
  content: "\2683\fe01"; }

.fad.fa-dice-four:after, .fa-duotone.fa-dice-four:after {
  content: "\2683\fe02"; }

.fad.fa-dice-one:before, .fa-duotone.fa-dice-one:before {
  content: "\2680\fe01"; }

.fad.fa-dice-one:after, .fa-duotone.fa-dice-one:after {
  content: "\2680\fe02"; }

.fad.fa-dice-six:before, .fa-duotone.fa-dice-six:before {
  content: "\2685\fe01"; }

.fad.fa-dice-six:after, .fa-duotone.fa-dice-six:after {
  content: "\2685\fe02"; }

.fad.fa-dice-three:before, .fa-duotone.fa-dice-three:before {
  content: "\2682\fe01"; }

.fad.fa-dice-three:after, .fa-duotone.fa-dice-three:after {
  content: "\2682\fe02"; }

.fad.fa-dice-two:before, .fa-duotone.fa-dice-two:before {
  content: "\2681\fe01"; }

.fad.fa-dice-two:after, .fa-duotone.fa-dice-two:after {
  content: "\2681\fe02"; }

.fad.fa-diploma:before, .fa-duotone.fa-diploma:before {
  content: "\f5ea\fe01"; }

.fad.fa-diploma:after, .fa-duotone.fa-diploma:after {
  content: "\f5ea\fe02"; }

.fad.fa-scroll-ribbon:before, .fa-duotone.fa-scroll-ribbon:before {
  content: "\f5ea\fe01"; }

.fad.fa-scroll-ribbon:after, .fa-duotone.fa-scroll-ribbon:after {
  content: "\f5ea\fe02"; }

.fad.fa-disc-drive:before, .fa-duotone.fa-disc-drive:before {
  content: "\f8b5\fe01"; }

.fad.fa-disc-drive:after, .fa-duotone.fa-disc-drive:after {
  content: "\f8b5\fe02"; }

.fad.fa-disease:before, .fa-duotone.fa-disease:before {
  content: "\f7fa\fe01"; }

.fad.fa-disease:after, .fa-duotone.fa-disease:after {
  content: "\f7fa\fe02"; }

.fad.fa-display:before, .fa-duotone.fa-display:before {
  content: "\e163\fe01"; }

.fad.fa-display:after, .fa-duotone.fa-display:after {
  content: "\e163\fe02"; }

.fad.fa-display-arrow-down:before, .fa-duotone.fa-display-arrow-down:before {
  content: "\e164\fe01"; }

.fad.fa-display-arrow-down:after, .fa-duotone.fa-display-arrow-down:after {
  content: "\e164\fe02"; }

.fad.fa-display-code:before, .fa-duotone.fa-display-code:before {
  content: "\e165\fe01"; }

.fad.fa-display-code:after, .fa-duotone.fa-display-code:after {
  content: "\e165\fe02"; }

.fad.fa-desktop-code:before, .fa-duotone.fa-desktop-code:before {
  content: "\e165\fe01"; }

.fad.fa-desktop-code:after, .fa-duotone.fa-desktop-code:after {
  content: "\e165\fe02"; }

.fad.fa-display-medical:before, .fa-duotone.fa-display-medical:before {
  content: "\e166\fe01"; }

.fad.fa-display-medical:after, .fa-duotone.fa-display-medical:after {
  content: "\e166\fe02"; }

.fad.fa-desktop-medical:before, .fa-duotone.fa-desktop-medical:before {
  content: "\e166\fe01"; }

.fad.fa-desktop-medical:after, .fa-duotone.fa-desktop-medical:after {
  content: "\e166\fe02"; }

.fad.fa-display-slash:before, .fa-duotone.fa-display-slash:before {
  content: "\e2fa\fe01"; }

.fad.fa-display-slash:after, .fa-duotone.fa-display-slash:after {
  content: "\e2fa\fe02"; }

.fad.fa-desktop-slash:before, .fa-duotone.fa-desktop-slash:before {
  content: "\e2fa\fe01"; }

.fad.fa-desktop-slash:after, .fa-duotone.fa-desktop-slash:after {
  content: "\e2fa\fe02"; }

.fad.fa-distribute-spacing-horizontal:before, .fa-duotone.fa-distribute-spacing-horizontal:before {
  content: "\e365\fe01"; }

.fad.fa-distribute-spacing-horizontal:after, .fa-duotone.fa-distribute-spacing-horizontal:after {
  content: "\e365\fe02"; }

.fad.fa-distribute-spacing-vertical:before, .fa-duotone.fa-distribute-spacing-vertical:before {
  content: "\e366\fe01"; }

.fad.fa-distribute-spacing-vertical:after, .fa-duotone.fa-distribute-spacing-vertical:after {
  content: "\e366\fe02"; }

.fad.fa-ditto:before, .fa-duotone.fa-ditto:before {
  content: "\22\fe01"; }

.fad.fa-ditto:after, .fa-duotone.fa-ditto:after {
  content: "\22\fe02"; }

.fad.fa-divide:before, .fa-duotone.fa-divide:before {
  content: "\f7\fe01"; }

.fad.fa-divide:after, .fa-duotone.fa-divide:after {
  content: "\f7\fe02"; }

.fad.fa-dna:before, .fa-duotone.fa-dna:before {
  content: "\f471"; }

.fad.fa-dna:after, .fa-duotone.fa-dna:after {
  content: "\10f471"; }

.fad.fa-do-not-enter:before, .fa-duotone.fa-do-not-enter:before {
  content: "\f5ec\fe01"; }

.fad.fa-do-not-enter:after, .fa-duotone.fa-do-not-enter:after {
  content: "\f5ec\fe02"; }

.fad.fa-dog:before, .fa-duotone.fa-dog:before {
  content: "\f6d3"; }

.fad.fa-dog:after, .fa-duotone.fa-dog:after {
  content: "\10f6d3"; }

.fad.fa-dog-leashed:before, .fa-duotone.fa-dog-leashed:before {
  content: "\f6d4"; }

.fad.fa-dog-leashed:after, .fa-duotone.fa-dog-leashed:after {
  content: "\10f6d4"; }

.fad.fa-dollar-sign:before, .fa-duotone.fa-dollar-sign:before {
  content: "\24\fe01"; }

.fad.fa-dollar-sign:after, .fa-duotone.fa-dollar-sign:after {
  content: "\24\fe02"; }

.fad.fa-dollar:before, .fa-duotone.fa-dollar:before {
  content: "\24\fe01"; }

.fad.fa-dollar:after, .fa-duotone.fa-dollar:after {
  content: "\24\fe02"; }

.fad.fa-usd:before, .fa-duotone.fa-usd:before {
  content: "\24\fe01"; }

.fad.fa-usd:after, .fa-duotone.fa-usd:after {
  content: "\24\fe02"; }

.fad.fa-dolly:before, .fa-duotone.fa-dolly:before {
  content: "\f472\fe01"; }

.fad.fa-dolly:after, .fa-duotone.fa-dolly:after {
  content: "\f472\fe02"; }

.fad.fa-dolly-box:before, .fa-duotone.fa-dolly-box:before {
  content: "\f472\fe01"; }

.fad.fa-dolly-box:after, .fa-duotone.fa-dolly-box:after {
  content: "\f472\fe02"; }

.fad.fa-dolly-empty:before, .fa-duotone.fa-dolly-empty:before {
  content: "\f473\fe01"; }

.fad.fa-dolly-empty:after, .fa-duotone.fa-dolly-empty:after {
  content: "\f473\fe02"; }

.fad.fa-dolphin:before, .fa-duotone.fa-dolphin:before {
  content: "\e168\fe01"; }

.fad.fa-dolphin:after, .fa-duotone.fa-dolphin:after {
  content: "\e168\fe02"; }

.fad.fa-dong-sign:before, .fa-duotone.fa-dong-sign:before {
  content: "\e169\fe01"; }

.fad.fa-dong-sign:after, .fa-duotone.fa-dong-sign:after {
  content: "\e169\fe02"; }

.fad.fa-door-closed:before, .fa-duotone.fa-door-closed:before {
  content: "\f52a"; }

.fad.fa-door-closed:after, .fa-duotone.fa-door-closed:after {
  content: "\10f52a"; }

.fad.fa-door-open:before, .fa-duotone.fa-door-open:before {
  content: "\f52b\fe01"; }

.fad.fa-door-open:after, .fa-duotone.fa-door-open:after {
  content: "\f52b\fe02"; }

.fad.fa-dove:before, .fa-duotone.fa-dove:before {
  content: "\f4ba"; }

.fad.fa-dove:after, .fa-duotone.fa-dove:after {
  content: "\10f4ba"; }

.fad.fa-down:before, .fa-duotone.fa-down:before {
  content: "\f354\fe01"; }

.fad.fa-down:after, .fa-duotone.fa-down:after {
  content: "\f354\fe02"; }

.fad.fa-arrow-alt-down:before, .fa-duotone.fa-arrow-alt-down:before {
  content: "\f354\fe01"; }

.fad.fa-arrow-alt-down:after, .fa-duotone.fa-arrow-alt-down:after {
  content: "\f354\fe02"; }

.fad.fa-down-from-line:before, .fa-duotone.fa-down-from-line:before {
  content: "\f349\fe01"; }

.fad.fa-down-from-line:after, .fa-duotone.fa-down-from-line:after {
  content: "\f349\fe02"; }

.fad.fa-arrow-alt-from-top:before, .fa-duotone.fa-arrow-alt-from-top:before {
  content: "\f349\fe01"; }

.fad.fa-arrow-alt-from-top:after, .fa-duotone.fa-arrow-alt-from-top:after {
  content: "\f349\fe02"; }

.fad.fa-down-left:before, .fa-duotone.fa-down-left:before {
  content: "\e16a\fe01"; }

.fad.fa-down-left:after, .fa-duotone.fa-down-left:after {
  content: "\e16a\fe02"; }

.fad.fa-down-left-and-up-right-to-center:before, .fa-duotone.fa-down-left-and-up-right-to-center:before {
  content: "\f422\fe01"; }

.fad.fa-down-left-and-up-right-to-center:after, .fa-duotone.fa-down-left-and-up-right-to-center:after {
  content: "\f422\fe02"; }

.fad.fa-compress-alt:before, .fa-duotone.fa-compress-alt:before {
  content: "\f422\fe01"; }

.fad.fa-compress-alt:after, .fa-duotone.fa-compress-alt:after {
  content: "\f422\fe02"; }

.fad.fa-down-long:before, .fa-duotone.fa-down-long:before {
  content: "\f309\fe01"; }

.fad.fa-down-long:after, .fa-duotone.fa-down-long:after {
  content: "\f309\fe02"; }

.fad.fa-long-arrow-alt-down:before, .fa-duotone.fa-long-arrow-alt-down:before {
  content: "\f309\fe01"; }

.fad.fa-long-arrow-alt-down:after, .fa-duotone.fa-long-arrow-alt-down:after {
  content: "\f309\fe02"; }

.fad.fa-down-right:before, .fa-duotone.fa-down-right:before {
  content: "\e16b\fe01"; }

.fad.fa-down-right:after, .fa-duotone.fa-down-right:after {
  content: "\e16b\fe02"; }

.fad.fa-down-to-line:before, .fa-duotone.fa-down-to-line:before {
  content: "\f34a\fe01"; }

.fad.fa-down-to-line:after, .fa-duotone.fa-down-to-line:after {
  content: "\f34a\fe02"; }

.fad.fa-arrow-alt-to-bottom:before, .fa-duotone.fa-arrow-alt-to-bottom:before {
  content: "\f34a\fe01"; }

.fad.fa-arrow-alt-to-bottom:after, .fa-duotone.fa-arrow-alt-to-bottom:after {
  content: "\f34a\fe02"; }

.fad.fa-download:before, .fa-duotone.fa-download:before {
  content: "\f019\fe01"; }

.fad.fa-download:after, .fa-duotone.fa-download:after {
  content: "\f019\fe02"; }

.fad.fa-dragon:before, .fa-duotone.fa-dragon:before {
  content: "\f6d5"; }

.fad.fa-dragon:after, .fa-duotone.fa-dragon:after {
  content: "\10f6d5"; }

.fad.fa-draw-circle:before, .fa-duotone.fa-draw-circle:before {
  content: "\f5ed\fe01"; }

.fad.fa-draw-circle:after, .fa-duotone.fa-draw-circle:after {
  content: "\f5ed\fe02"; }

.fad.fa-draw-polygon:before, .fa-duotone.fa-draw-polygon:before {
  content: "\f5ee\fe01"; }

.fad.fa-draw-polygon:after, .fa-duotone.fa-draw-polygon:after {
  content: "\f5ee\fe02"; }

.fad.fa-draw-square:before, .fa-duotone.fa-draw-square:before {
  content: "\f5ef\fe01"; }

.fad.fa-draw-square:after, .fa-duotone.fa-draw-square:after {
  content: "\f5ef\fe02"; }

.fad.fa-dreidel:before, .fa-duotone.fa-dreidel:before {
  content: "\f792\fe01"; }

.fad.fa-dreidel:after, .fa-duotone.fa-dreidel:after {
  content: "\f792\fe02"; }

.fad.fa-drone:before, .fa-duotone.fa-drone:before {
  content: "\f85f\fe01"; }

.fad.fa-drone:after, .fa-duotone.fa-drone:after {
  content: "\f85f\fe02"; }

.fad.fa-drone-front:before, .fa-duotone.fa-drone-front:before {
  content: "\f860\fe01"; }

.fad.fa-drone-front:after, .fa-duotone.fa-drone-front:after {
  content: "\f860\fe02"; }

.fad.fa-drone-alt:before, .fa-duotone.fa-drone-alt:before {
  content: "\f860\fe01"; }

.fad.fa-drone-alt:after, .fa-duotone.fa-drone-alt:after {
  content: "\f860\fe02"; }

.fad.fa-droplet:before, .fa-duotone.fa-droplet:before {
  content: "\f043"; }

.fad.fa-droplet:after, .fa-duotone.fa-droplet:after {
  content: "\10f043"; }

.fad.fa-tint:before, .fa-duotone.fa-tint:before {
  content: "\f043"; }

.fad.fa-tint:after, .fa-duotone.fa-tint:after {
  content: "\10f043"; }

.fad.fa-droplet-degree:before, .fa-duotone.fa-droplet-degree:before {
  content: "\f748\fe01"; }

.fad.fa-droplet-degree:after, .fa-duotone.fa-droplet-degree:after {
  content: "\f748\fe02"; }

.fad.fa-dewpoint:before, .fa-duotone.fa-dewpoint:before {
  content: "\f748\fe01"; }

.fad.fa-dewpoint:after, .fa-duotone.fa-dewpoint:after {
  content: "\f748\fe02"; }

.fad.fa-droplet-percent:before, .fa-duotone.fa-droplet-percent:before {
  content: "\f750\fe01"; }

.fad.fa-droplet-percent:after, .fa-duotone.fa-droplet-percent:after {
  content: "\f750\fe02"; }

.fad.fa-humidity:before, .fa-duotone.fa-humidity:before {
  content: "\f750\fe01"; }

.fad.fa-humidity:after, .fa-duotone.fa-humidity:after {
  content: "\f750\fe02"; }

.fad.fa-droplet-slash:before, .fa-duotone.fa-droplet-slash:before {
  content: "\f5c7\fe01"; }

.fad.fa-droplet-slash:after, .fa-duotone.fa-droplet-slash:after {
  content: "\f5c7\fe02"; }

.fad.fa-tint-slash:before, .fa-duotone.fa-tint-slash:before {
  content: "\f5c7\fe01"; }

.fad.fa-tint-slash:after, .fa-duotone.fa-tint-slash:after {
  content: "\f5c7\fe02"; }

.fad.fa-drum:before, .fa-duotone.fa-drum:before {
  content: "\f569"; }

.fad.fa-drum:after, .fa-duotone.fa-drum:after {
  content: "\10f569"; }

.fad.fa-drum-steelpan:before, .fa-duotone.fa-drum-steelpan:before {
  content: "\f56a\fe01"; }

.fad.fa-drum-steelpan:after, .fa-duotone.fa-drum-steelpan:after {
  content: "\f56a\fe02"; }

.fad.fa-drumstick:before, .fa-duotone.fa-drumstick:before {
  content: "\f6d6"; }

.fad.fa-drumstick:after, .fa-duotone.fa-drumstick:after {
  content: "\10f6d6"; }

.fad.fa-drumstick-bite:before, .fa-duotone.fa-drumstick-bite:before {
  content: "\f6d7\fe01"; }

.fad.fa-drumstick-bite:after, .fa-duotone.fa-drumstick-bite:after {
  content: "\f6d7\fe02"; }

.fad.fa-dryer:before, .fa-duotone.fa-dryer:before {
  content: "\f861\fe01"; }

.fad.fa-dryer:after, .fa-duotone.fa-dryer:after {
  content: "\f861\fe02"; }

.fad.fa-dryer-heat:before, .fa-duotone.fa-dryer-heat:before {
  content: "\f862\fe01"; }

.fad.fa-dryer-heat:after, .fa-duotone.fa-dryer-heat:after {
  content: "\f862\fe02"; }

.fad.fa-dryer-alt:before, .fa-duotone.fa-dryer-alt:before {
  content: "\f862\fe01"; }

.fad.fa-dryer-alt:after, .fa-duotone.fa-dryer-alt:after {
  content: "\f862\fe02"; }

.fad.fa-duck:before, .fa-duotone.fa-duck:before {
  content: "\f6d8"; }

.fad.fa-duck:after, .fa-duotone.fa-duck:after {
  content: "\10f6d8"; }

.fad.fa-dumbbell:before, .fa-duotone.fa-dumbbell:before {
  content: "\f44b\fe01"; }

.fad.fa-dumbbell:after, .fa-duotone.fa-dumbbell:after {
  content: "\f44b\fe02"; }

.fad.fa-dumpster:before, .fa-duotone.fa-dumpster:before {
  content: "\f793\fe01"; }

.fad.fa-dumpster:after, .fa-duotone.fa-dumpster:after {
  content: "\f793\fe02"; }

.fad.fa-dumpster-fire:before, .fa-duotone.fa-dumpster-fire:before {
  content: "\f794\fe01"; }

.fad.fa-dumpster-fire:after, .fa-duotone.fa-dumpster-fire:after {
  content: "\f794\fe02"; }

.fad.fa-dungeon:before, .fa-duotone.fa-dungeon:before {
  content: "\f6d9\fe01"; }

.fad.fa-dungeon:after, .fa-duotone.fa-dungeon:after {
  content: "\f6d9\fe02"; }

.fad.fa-e:before, .fa-duotone.fa-e:before {
  content: "\45\fe01"; }

.fad.fa-e:after, .fa-duotone.fa-e:after {
  content: "\45\fe02"; }

.fad.fa-ear:before, .fa-duotone.fa-ear:before {
  content: "\f5f0"; }

.fad.fa-ear:after, .fa-duotone.fa-ear:after {
  content: "\10f5f0"; }

.fad.fa-ear-deaf:before, .fa-duotone.fa-ear-deaf:before {
  content: "\f2a4\fe01"; }

.fad.fa-ear-deaf:after, .fa-duotone.fa-ear-deaf:after {
  content: "\f2a4\fe02"; }

.fad.fa-deaf:before, .fa-duotone.fa-deaf:before {
  content: "\f2a4\fe01"; }

.fad.fa-deaf:after, .fa-duotone.fa-deaf:after {
  content: "\f2a4\fe02"; }

.fad.fa-deafness:before, .fa-duotone.fa-deafness:before {
  content: "\f2a4\fe01"; }

.fad.fa-deafness:after, .fa-duotone.fa-deafness:after {
  content: "\f2a4\fe02"; }

.fad.fa-hard-of-hearing:before, .fa-duotone.fa-hard-of-hearing:before {
  content: "\f2a4\fe01"; }

.fad.fa-hard-of-hearing:after, .fa-duotone.fa-hard-of-hearing:after {
  content: "\f2a4\fe02"; }

.fad.fa-ear-listen:before, .fa-duotone.fa-ear-listen:before {
  content: "\f2a2\fe01"; }

.fad.fa-ear-listen:after, .fa-duotone.fa-ear-listen:after {
  content: "\f2a2\fe02"; }

.fad.fa-assistive-listening-systems:before, .fa-duotone.fa-assistive-listening-systems:before {
  content: "\f2a2\fe01"; }

.fad.fa-assistive-listening-systems:after, .fa-duotone.fa-assistive-listening-systems:after {
  content: "\f2a2\fe02"; }

.fad.fa-ear-muffs:before, .fa-duotone.fa-ear-muffs:before {
  content: "\f795\fe01"; }

.fad.fa-ear-muffs:after, .fa-duotone.fa-ear-muffs:after {
  content: "\f795\fe02"; }

.fad.fa-earth-africa:before, .fa-duotone.fa-earth-africa:before {
  content: "\f57c"; }

.fad.fa-earth-africa:after, .fa-duotone.fa-earth-africa:after {
  content: "\10f57c"; }

.fad.fa-globe-africa:before, .fa-duotone.fa-globe-africa:before {
  content: "\f57c"; }

.fad.fa-globe-africa:after, .fa-duotone.fa-globe-africa:after {
  content: "\10f57c"; }

.fad.fa-earth-americas:before, .fa-duotone.fa-earth-americas:before {
  content: "\f57d"; }

.fad.fa-earth-americas:after, .fa-duotone.fa-earth-americas:after {
  content: "\10f57d"; }

.fad.fa-earth:before, .fa-duotone.fa-earth:before {
  content: "\f57d"; }

.fad.fa-earth:after, .fa-duotone.fa-earth:after {
  content: "\10f57d"; }

.fad.fa-globe-americas:before, .fa-duotone.fa-globe-americas:before {
  content: "\f57d"; }

.fad.fa-globe-americas:after, .fa-duotone.fa-globe-americas:after {
  content: "\10f57d"; }

.fad.fa-earth-asia:before, .fa-duotone.fa-earth-asia:before {
  content: "\f57e"; }

.fad.fa-earth-asia:after, .fa-duotone.fa-earth-asia:after {
  content: "\10f57e"; }

.fad.fa-globe-asia:before, .fa-duotone.fa-globe-asia:before {
  content: "\f57e"; }

.fad.fa-globe-asia:after, .fa-duotone.fa-globe-asia:after {
  content: "\10f57e"; }

.fad.fa-earth-europa:before, .fa-duotone.fa-earth-europa:before {
  content: "\f7a2\fe01"; }

.fad.fa-earth-europa:after, .fa-duotone.fa-earth-europa:after {
  content: "\f7a2\fe02"; }

.fad.fa-globe-europe:before, .fa-duotone.fa-globe-europe:before {
  content: "\f7a2\fe01"; }

.fad.fa-globe-europe:after, .fa-duotone.fa-globe-europe:after {
  content: "\f7a2\fe02"; }

.fad.fa-eclipse:before, .fa-duotone.fa-eclipse:before {
  content: "\f749\fe01"; }

.fad.fa-eclipse:after, .fa-duotone.fa-eclipse:after {
  content: "\f749\fe02"; }

.fad.fa-egg:before, .fa-duotone.fa-egg:before {
  content: "\f7fb"; }

.fad.fa-egg:after, .fa-duotone.fa-egg:after {
  content: "\10f7fb"; }

.fad.fa-egg-fried:before, .fa-duotone.fa-egg-fried:before {
  content: "\f7fc\fe01"; }

.fad.fa-egg-fried:after, .fa-duotone.fa-egg-fried:after {
  content: "\f7fc\fe02"; }

.fad.fa-eggplant:before, .fa-duotone.fa-eggplant:before {
  content: "\e16c\fe01"; }

.fad.fa-eggplant:after, .fa-duotone.fa-eggplant:after {
  content: "\e16c\fe02"; }

.fad.fa-eject:before, .fa-duotone.fa-eject:before {
  content: "\23cf\fe01"; }

.fad.fa-eject:after, .fa-duotone.fa-eject:after {
  content: "\23cf\fe02"; }

.fad.fa-elephant:before, .fa-duotone.fa-elephant:before {
  content: "\f6da"; }

.fad.fa-elephant:after, .fa-duotone.fa-elephant:after {
  content: "\10f6da"; }

.fad.fa-elevator:before, .fa-duotone.fa-elevator:before {
  content: "\e16d\fe01"; }

.fad.fa-elevator:after, .fa-duotone.fa-elevator:after {
  content: "\e16d\fe02"; }

.fad.fa-ellipsis:before, .fa-duotone.fa-ellipsis:before {
  content: "\f141\fe01"; }

.fad.fa-ellipsis:after, .fa-duotone.fa-ellipsis:after {
  content: "\f141\fe02"; }

.fad.fa-ellipsis-h:before, .fa-duotone.fa-ellipsis-h:before {
  content: "\f141\fe01"; }

.fad.fa-ellipsis-h:after, .fa-duotone.fa-ellipsis-h:after {
  content: "\f141\fe02"; }

.fad.fa-ellipsis-stroke:before, .fa-duotone.fa-ellipsis-stroke:before {
  content: "\f39b\fe01"; }

.fad.fa-ellipsis-stroke:after, .fa-duotone.fa-ellipsis-stroke:after {
  content: "\f39b\fe02"; }

.fad.fa-ellipsis-h-alt:before, .fa-duotone.fa-ellipsis-h-alt:before {
  content: "\f39b\fe01"; }

.fad.fa-ellipsis-h-alt:after, .fa-duotone.fa-ellipsis-h-alt:after {
  content: "\f39b\fe02"; }

.fad.fa-ellipsis-stroke-vertical:before, .fa-duotone.fa-ellipsis-stroke-vertical:before {
  content: "\f39c\fe01"; }

.fad.fa-ellipsis-stroke-vertical:after, .fa-duotone.fa-ellipsis-stroke-vertical:after {
  content: "\f39c\fe02"; }

.fad.fa-ellipsis-v-alt:before, .fa-duotone.fa-ellipsis-v-alt:before {
  content: "\f39c\fe01"; }

.fad.fa-ellipsis-v-alt:after, .fa-duotone.fa-ellipsis-v-alt:after {
  content: "\f39c\fe02"; }

.fad.fa-ellipsis-vertical:before, .fa-duotone.fa-ellipsis-vertical:before {
  content: "\f142\fe01"; }

.fad.fa-ellipsis-vertical:after, .fa-duotone.fa-ellipsis-vertical:after {
  content: "\f142\fe02"; }

.fad.fa-ellipsis-v:before, .fa-duotone.fa-ellipsis-v:before {
  content: "\f142\fe01"; }

.fad.fa-ellipsis-v:after, .fa-duotone.fa-ellipsis-v:after {
  content: "\f142\fe02"; }

.fad.fa-empty-set:before, .fa-duotone.fa-empty-set:before {
  content: "\d8\fe01"; }

.fad.fa-empty-set:after, .fa-duotone.fa-empty-set:after {
  content: "\d8\fe02"; }

.fad.fa-engine:before, .fa-duotone.fa-engine:before {
  content: "\e16e\fe01"; }

.fad.fa-engine:after, .fa-duotone.fa-engine:after {
  content: "\e16e\fe02"; }

.fad.fa-engine-warning:before, .fa-duotone.fa-engine-warning:before {
  content: "\f5f2\fe01"; }

.fad.fa-engine-warning:after, .fa-duotone.fa-engine-warning:after {
  content: "\f5f2\fe02"; }

.fad.fa-engine-exclamation:before, .fa-duotone.fa-engine-exclamation:before {
  content: "\f5f2\fe01"; }

.fad.fa-engine-exclamation:after, .fa-duotone.fa-engine-exclamation:after {
  content: "\f5f2\fe02"; }

.fad.fa-envelope:before, .fa-duotone.fa-envelope:before {
  content: "\2709\fe01"; }

.fad.fa-envelope:after, .fa-duotone.fa-envelope:after {
  content: "\2709\fe02"; }

.fad.fa-envelope-dot:before, .fa-duotone.fa-envelope-dot:before {
  content: "\e16f\fe01"; }

.fad.fa-envelope-dot:after, .fa-duotone.fa-envelope-dot:after {
  content: "\e16f\fe02"; }

.fad.fa-envelope-badge:before, .fa-duotone.fa-envelope-badge:before {
  content: "\e16f\fe01"; }

.fad.fa-envelope-badge:after, .fa-duotone.fa-envelope-badge:after {
  content: "\e16f\fe02"; }

.fad.fa-envelope-open:before, .fa-duotone.fa-envelope-open:before {
  content: "\f2b6\fe01"; }

.fad.fa-envelope-open:after, .fa-duotone.fa-envelope-open:after {
  content: "\f2b6\fe02"; }

.fad.fa-envelope-open-dollar:before, .fa-duotone.fa-envelope-open-dollar:before {
  content: "\f657\fe01"; }

.fad.fa-envelope-open-dollar:after, .fa-duotone.fa-envelope-open-dollar:after {
  content: "\f657\fe02"; }

.fad.fa-envelope-open-text:before, .fa-duotone.fa-envelope-open-text:before {
  content: "\f658\fe01"; }

.fad.fa-envelope-open-text:after, .fa-duotone.fa-envelope-open-text:after {
  content: "\f658\fe02"; }

.fad.fa-envelopes:before, .fa-duotone.fa-envelopes:before {
  content: "\e170\fe01"; }

.fad.fa-envelopes:after, .fa-duotone.fa-envelopes:after {
  content: "\e170\fe02"; }

.fad.fa-envelopes-bulk:before, .fa-duotone.fa-envelopes-bulk:before {
  content: "\f674\fe01"; }

.fad.fa-envelopes-bulk:after, .fa-duotone.fa-envelopes-bulk:after {
  content: "\f674\fe02"; }

.fad.fa-mail-bulk:before, .fa-duotone.fa-mail-bulk:before {
  content: "\f674\fe01"; }

.fad.fa-mail-bulk:after, .fa-duotone.fa-mail-bulk:after {
  content: "\f674\fe02"; }

.fad.fa-equals:before, .fa-duotone.fa-equals:before {
  content: "\3d\fe01"; }

.fad.fa-equals:after, .fa-duotone.fa-equals:after {
  content: "\3d\fe02"; }

.fad.fa-eraser:before, .fa-duotone.fa-eraser:before {
  content: "\f12d\fe01"; }

.fad.fa-eraser:after, .fa-duotone.fa-eraser:after {
  content: "\f12d\fe02"; }

.fad.fa-escalator:before, .fa-duotone.fa-escalator:before {
  content: "\e171\fe01"; }

.fad.fa-escalator:after, .fa-duotone.fa-escalator:after {
  content: "\e171\fe02"; }

.fad.fa-ethernet:before, .fa-duotone.fa-ethernet:before {
  content: "\f796\fe01"; }

.fad.fa-ethernet:after, .fa-duotone.fa-ethernet:after {
  content: "\f796\fe02"; }

.fad.fa-euro-sign:before, .fa-duotone.fa-euro-sign:before {
  content: "\20ac\fe01"; }

.fad.fa-euro-sign:after, .fa-duotone.fa-euro-sign:after {
  content: "\20ac\fe02"; }

.fad.fa-eur:before, .fa-duotone.fa-eur:before {
  content: "\20ac\fe01"; }

.fad.fa-eur:after, .fa-duotone.fa-eur:after {
  content: "\20ac\fe02"; }

.fad.fa-euro:before, .fa-duotone.fa-euro:before {
  content: "\20ac\fe01"; }

.fad.fa-euro:after, .fa-duotone.fa-euro:after {
  content: "\20ac\fe02"; }

.fad.fa-exclamation:before, .fa-duotone.fa-exclamation:before {
  content: "\21\fe01"; }

.fad.fa-exclamation:after, .fa-duotone.fa-exclamation:after {
  content: "\21\fe02"; }

.fad.fa-expand:before, .fa-duotone.fa-expand:before {
  content: "\f065\fe01"; }

.fad.fa-expand:after, .fa-duotone.fa-expand:after {
  content: "\f065\fe02"; }

.fad.fa-expand-wide:before, .fa-duotone.fa-expand-wide:before {
  content: "\f320\fe01"; }

.fad.fa-expand-wide:after, .fa-duotone.fa-expand-wide:after {
  content: "\f320\fe02"; }

.fad.fa-eye:before, .fa-duotone.fa-eye:before {
  content: "\f06e"; }

.fad.fa-eye:after, .fa-duotone.fa-eye:after {
  content: "\10f06e"; }

.fad.fa-eye-dropper:before, .fa-duotone.fa-eye-dropper:before {
  content: "\f1fb\fe01"; }

.fad.fa-eye-dropper:after, .fa-duotone.fa-eye-dropper:after {
  content: "\f1fb\fe02"; }

.fad.fa-eye-dropper-empty:before, .fa-duotone.fa-eye-dropper-empty:before {
  content: "\f1fb\fe01"; }

.fad.fa-eye-dropper-empty:after, .fa-duotone.fa-eye-dropper-empty:after {
  content: "\f1fb\fe02"; }

.fad.fa-eyedropper:before, .fa-duotone.fa-eyedropper:before {
  content: "\f1fb\fe01"; }

.fad.fa-eyedropper:after, .fa-duotone.fa-eyedropper:after {
  content: "\f1fb\fe02"; }

.fad.fa-eye-dropper-full:before, .fa-duotone.fa-eye-dropper-full:before {
  content: "\e172\fe01"; }

.fad.fa-eye-dropper-full:after, .fa-duotone.fa-eye-dropper-full:after {
  content: "\e172\fe02"; }

.fad.fa-eye-dropper-half:before, .fa-duotone.fa-eye-dropper-half:before {
  content: "\e173\fe01"; }

.fad.fa-eye-dropper-half:after, .fa-duotone.fa-eye-dropper-half:after {
  content: "\e173\fe02"; }

.fad.fa-eye-evil:before, .fa-duotone.fa-eye-evil:before {
  content: "\f6db\fe01"; }

.fad.fa-eye-evil:after, .fa-duotone.fa-eye-evil:after {
  content: "\f6db\fe02"; }

.fad.fa-eye-low-vision:before, .fa-duotone.fa-eye-low-vision:before {
  content: "\f2a8\fe01"; }

.fad.fa-eye-low-vision:after, .fa-duotone.fa-eye-low-vision:after {
  content: "\f2a8\fe02"; }

.fad.fa-low-vision:before, .fa-duotone.fa-low-vision:before {
  content: "\f2a8\fe01"; }

.fad.fa-low-vision:after, .fa-duotone.fa-low-vision:after {
  content: "\f2a8\fe02"; }

.fad.fa-eye-slash:before, .fa-duotone.fa-eye-slash:before {
  content: "\f070\fe01"; }

.fad.fa-eye-slash:after, .fa-duotone.fa-eye-slash:after {
  content: "\f070\fe02"; }

.fad.fa-eyes:before, .fa-duotone.fa-eyes:before {
  content: "\e367\fe01"; }

.fad.fa-eyes:after, .fa-duotone.fa-eyes:after {
  content: "\e367\fe02"; }

.fad.fa-f:before, .fa-duotone.fa-f:before {
  content: "\46\fe01"; }

.fad.fa-f:after, .fa-duotone.fa-f:after {
  content: "\46\fe02"; }

.fad.fa-face-angry:before, .fa-duotone.fa-face-angry:before {
  content: "\f556"; }

.fad.fa-face-angry:after, .fa-duotone.fa-face-angry:after {
  content: "\10f556"; }

.fad.fa-angry:before, .fa-duotone.fa-angry:before {
  content: "\f556"; }

.fad.fa-angry:after, .fa-duotone.fa-angry:after {
  content: "\10f556"; }

.fad.fa-face-angry-horns:before, .fa-duotone.fa-face-angry-horns:before {
  content: "\e368\fe01"; }

.fad.fa-face-angry-horns:after, .fa-duotone.fa-face-angry-horns:after {
  content: "\e368\fe02"; }

.fad.fa-face-anguished:before, .fa-duotone.fa-face-anguished:before {
  content: "\e369\fe01"; }

.fad.fa-face-anguished:after, .fa-duotone.fa-face-anguished:after {
  content: "\e369\fe02"; }

.fad.fa-face-anxious-sweat:before, .fa-duotone.fa-face-anxious-sweat:before {
  content: "\e36a\fe01"; }

.fad.fa-face-anxious-sweat:after, .fa-duotone.fa-face-anxious-sweat:after {
  content: "\e36a\fe02"; }

.fad.fa-face-astonished:before, .fa-duotone.fa-face-astonished:before {
  content: "\e36b\fe01"; }

.fad.fa-face-astonished:after, .fa-duotone.fa-face-astonished:after {
  content: "\e36b\fe02"; }

.fad.fa-face-confounded:before, .fa-duotone.fa-face-confounded:before {
  content: "\e36c\fe01"; }

.fad.fa-face-confounded:after, .fa-duotone.fa-face-confounded:after {
  content: "\e36c\fe02"; }

.fad.fa-face-confused:before, .fa-duotone.fa-face-confused:before {
  content: "\e36d\fe01"; }

.fad.fa-face-confused:after, .fa-duotone.fa-face-confused:after {
  content: "\e36d\fe02"; }

.fad.fa-face-cowboy-hat:before, .fa-duotone.fa-face-cowboy-hat:before {
  content: "\e36e\fe01"; }

.fad.fa-face-cowboy-hat:after, .fa-duotone.fa-face-cowboy-hat:after {
  content: "\e36e\fe02"; }

.fad.fa-face-disappointed:before, .fa-duotone.fa-face-disappointed:before {
  content: "\e36f\fe01"; }

.fad.fa-face-disappointed:after, .fa-duotone.fa-face-disappointed:after {
  content: "\e36f\fe02"; }

.fad.fa-face-disguise:before, .fa-duotone.fa-face-disguise:before {
  content: "\e370\fe01"; }

.fad.fa-face-disguise:after, .fa-duotone.fa-face-disguise:after {
  content: "\e370\fe02"; }

.fad.fa-face-dizzy:before, .fa-duotone.fa-face-dizzy:before {
  content: "\f567\fe01"; }

.fad.fa-face-dizzy:after, .fa-duotone.fa-face-dizzy:after {
  content: "\f567\fe02"; }

.fad.fa-dizzy:before, .fa-duotone.fa-dizzy:before {
  content: "\f567\fe01"; }

.fad.fa-dizzy:after, .fa-duotone.fa-dizzy:after {
  content: "\f567\fe02"; }

.fad.fa-face-downcast-sweat:before, .fa-duotone.fa-face-downcast-sweat:before {
  content: "\e371\fe01"; }

.fad.fa-face-downcast-sweat:after, .fa-duotone.fa-face-downcast-sweat:after {
  content: "\e371\fe02"; }

.fad.fa-face-drooling:before, .fa-duotone.fa-face-drooling:before {
  content: "\e372\fe01"; }

.fad.fa-face-drooling:after, .fa-duotone.fa-face-drooling:after {
  content: "\e372\fe02"; }

.fad.fa-face-explode:before, .fa-duotone.fa-face-explode:before {
  content: "\e2fe\fe01"; }

.fad.fa-face-explode:after, .fa-duotone.fa-face-explode:after {
  content: "\e2fe\fe02"; }

.fad.fa-exploding-head:before, .fa-duotone.fa-exploding-head:before {
  content: "\e2fe\fe01"; }

.fad.fa-exploding-head:after, .fa-duotone.fa-exploding-head:after {
  content: "\e2fe\fe02"; }

.fad.fa-face-expressionless:before, .fa-duotone.fa-face-expressionless:before {
  content: "\e373\fe01"; }

.fad.fa-face-expressionless:after, .fa-duotone.fa-face-expressionless:after {
  content: "\e373\fe02"; }

.fad.fa-face-eyes-xmarks:before, .fa-duotone.fa-face-eyes-xmarks:before {
  content: "\e374\fe01"; }

.fad.fa-face-eyes-xmarks:after, .fa-duotone.fa-face-eyes-xmarks:after {
  content: "\e374\fe02"; }

.fad.fa-face-fearful:before, .fa-duotone.fa-face-fearful:before {
  content: "\e375\fe01"; }

.fad.fa-face-fearful:after, .fa-duotone.fa-face-fearful:after {
  content: "\e375\fe02"; }

.fad.fa-face-flushed:before, .fa-duotone.fa-face-flushed:before {
  content: "\f579"; }

.fad.fa-face-flushed:after, .fa-duotone.fa-face-flushed:after {
  content: "\10f579"; }

.fad.fa-flushed:before, .fa-duotone.fa-flushed:before {
  content: "\f579"; }

.fad.fa-flushed:after, .fa-duotone.fa-flushed:after {
  content: "\10f579"; }

.fad.fa-face-frown:before, .fa-duotone.fa-face-frown:before {
  content: "\2639\fe01"; }

.fad.fa-face-frown:after, .fa-duotone.fa-face-frown:after {
  content: "\2639\fe02"; }

.fad.fa-frown:before, .fa-duotone.fa-frown:before {
  content: "\2639\fe01"; }

.fad.fa-frown:after, .fa-duotone.fa-frown:after {
  content: "\2639\fe02"; }

.fad.fa-face-frown-open:before, .fa-duotone.fa-face-frown-open:before {
  content: "\f57a"; }

.fad.fa-face-frown-open:after, .fa-duotone.fa-face-frown-open:after {
  content: "\10f57a"; }

.fad.fa-frown-open:before, .fa-duotone.fa-frown-open:before {
  content: "\f57a"; }

.fad.fa-frown-open:after, .fa-duotone.fa-frown-open:after {
  content: "\10f57a"; }

.fad.fa-face-frown-slight:before, .fa-duotone.fa-face-frown-slight:before {
  content: "\e376\fe01"; }

.fad.fa-face-frown-slight:after, .fa-duotone.fa-face-frown-slight:after {
  content: "\e376\fe02"; }

.fad.fa-face-glasses:before, .fa-duotone.fa-face-glasses:before {
  content: "\e377\fe01"; }

.fad.fa-face-glasses:after, .fa-duotone.fa-face-glasses:after {
  content: "\e377\fe02"; }

.fad.fa-face-grimace:before, .fa-duotone.fa-face-grimace:before {
  content: "\f57f"; }

.fad.fa-face-grimace:after, .fa-duotone.fa-face-grimace:after {
  content: "\10f57f"; }

.fad.fa-grimace:before, .fa-duotone.fa-grimace:before {
  content: "\f57f"; }

.fad.fa-grimace:after, .fa-duotone.fa-grimace:after {
  content: "\10f57f"; }

.fad.fa-face-grin:before, .fa-duotone.fa-face-grin:before {
  content: "\f580"; }

.fad.fa-face-grin:after, .fa-duotone.fa-face-grin:after {
  content: "\10f580"; }

.fad.fa-grin:before, .fa-duotone.fa-grin:before {
  content: "\f580"; }

.fad.fa-grin:after, .fa-duotone.fa-grin:after {
  content: "\10f580"; }

.fad.fa-face-grin-beam:before, .fa-duotone.fa-face-grin-beam:before {
  content: "\f582"; }

.fad.fa-face-grin-beam:after, .fa-duotone.fa-face-grin-beam:after {
  content: "\10f582"; }

.fad.fa-grin-beam:before, .fa-duotone.fa-grin-beam:before {
  content: "\f582"; }

.fad.fa-grin-beam:after, .fa-duotone.fa-grin-beam:after {
  content: "\10f582"; }

.fad.fa-face-grin-beam-sweat:before, .fa-duotone.fa-face-grin-beam-sweat:before {
  content: "\f583"; }

.fad.fa-face-grin-beam-sweat:after, .fa-duotone.fa-face-grin-beam-sweat:after {
  content: "\10f583"; }

.fad.fa-grin-beam-sweat:before, .fa-duotone.fa-grin-beam-sweat:before {
  content: "\f583"; }

.fad.fa-grin-beam-sweat:after, .fa-duotone.fa-grin-beam-sweat:after {
  content: "\10f583"; }

.fad.fa-face-grin-hearts:before, .fa-duotone.fa-face-grin-hearts:before {
  content: "\f584"; }

.fad.fa-face-grin-hearts:after, .fa-duotone.fa-face-grin-hearts:after {
  content: "\10f584"; }

.fad.fa-grin-hearts:before, .fa-duotone.fa-grin-hearts:before {
  content: "\f584"; }

.fad.fa-grin-hearts:after, .fa-duotone.fa-grin-hearts:after {
  content: "\10f584"; }

.fad.fa-face-grin-squint:before, .fa-duotone.fa-face-grin-squint:before {
  content: "\f585"; }

.fad.fa-face-grin-squint:after, .fa-duotone.fa-face-grin-squint:after {
  content: "\10f585"; }

.fad.fa-grin-squint:before, .fa-duotone.fa-grin-squint:before {
  content: "\f585"; }

.fad.fa-grin-squint:after, .fa-duotone.fa-grin-squint:after {
  content: "\10f585"; }

.fad.fa-face-grin-squint-tears:before, .fa-duotone.fa-face-grin-squint-tears:before {
  content: "\f586"; }

.fad.fa-face-grin-squint-tears:after, .fa-duotone.fa-face-grin-squint-tears:after {
  content: "\10f586"; }

.fad.fa-grin-squint-tears:before, .fa-duotone.fa-grin-squint-tears:before {
  content: "\f586"; }

.fad.fa-grin-squint-tears:after, .fa-duotone.fa-grin-squint-tears:after {
  content: "\10f586"; }

.fad.fa-face-grin-stars:before, .fa-duotone.fa-face-grin-stars:before {
  content: "\f587"; }

.fad.fa-face-grin-stars:after, .fa-duotone.fa-face-grin-stars:after {
  content: "\10f587"; }

.fad.fa-grin-stars:before, .fa-duotone.fa-grin-stars:before {
  content: "\f587"; }

.fad.fa-grin-stars:after, .fa-duotone.fa-grin-stars:after {
  content: "\10f587"; }

.fad.fa-face-grin-tears:before, .fa-duotone.fa-face-grin-tears:before {
  content: "\f588"; }

.fad.fa-face-grin-tears:after, .fa-duotone.fa-face-grin-tears:after {
  content: "\10f588"; }

.fad.fa-grin-tears:before, .fa-duotone.fa-grin-tears:before {
  content: "\f588"; }

.fad.fa-grin-tears:after, .fa-duotone.fa-grin-tears:after {
  content: "\10f588"; }

.fad.fa-face-grin-tongue:before, .fa-duotone.fa-face-grin-tongue:before {
  content: "\f589"; }

.fad.fa-face-grin-tongue:after, .fa-duotone.fa-face-grin-tongue:after {
  content: "\10f589"; }

.fad.fa-grin-tongue:before, .fa-duotone.fa-grin-tongue:before {
  content: "\f589"; }

.fad.fa-grin-tongue:after, .fa-duotone.fa-grin-tongue:after {
  content: "\10f589"; }

.fad.fa-face-grin-tongue-squint:before, .fa-duotone.fa-face-grin-tongue-squint:before {
  content: "\f58a"; }

.fad.fa-face-grin-tongue-squint:after, .fa-duotone.fa-face-grin-tongue-squint:after {
  content: "\10f58a"; }

.fad.fa-grin-tongue-squint:before, .fa-duotone.fa-grin-tongue-squint:before {
  content: "\f58a"; }

.fad.fa-grin-tongue-squint:after, .fa-duotone.fa-grin-tongue-squint:after {
  content: "\10f58a"; }

.fad.fa-face-grin-tongue-wink:before, .fa-duotone.fa-face-grin-tongue-wink:before {
  content: "\f58b"; }

.fad.fa-face-grin-tongue-wink:after, .fa-duotone.fa-face-grin-tongue-wink:after {
  content: "\10f58b"; }

.fad.fa-grin-tongue-wink:before, .fa-duotone.fa-grin-tongue-wink:before {
  content: "\f58b"; }

.fad.fa-grin-tongue-wink:after, .fa-duotone.fa-grin-tongue-wink:after {
  content: "\10f58b"; }

.fad.fa-face-grin-wide:before, .fa-duotone.fa-face-grin-wide:before {
  content: "\f581"; }

.fad.fa-face-grin-wide:after, .fa-duotone.fa-face-grin-wide:after {
  content: "\10f581"; }

.fad.fa-grin-alt:before, .fa-duotone.fa-grin-alt:before {
  content: "\f581"; }

.fad.fa-grin-alt:after, .fa-duotone.fa-grin-alt:after {
  content: "\10f581"; }

.fad.fa-face-grin-wink:before, .fa-duotone.fa-face-grin-wink:before {
  content: "\f58c\fe01"; }

.fad.fa-face-grin-wink:after, .fa-duotone.fa-face-grin-wink:after {
  content: "\f58c\fe02"; }

.fad.fa-grin-wink:before, .fa-duotone.fa-grin-wink:before {
  content: "\f58c\fe01"; }

.fad.fa-grin-wink:after, .fa-duotone.fa-grin-wink:after {
  content: "\f58c\fe02"; }

.fad.fa-face-hand-over-mouth:before, .fa-duotone.fa-face-hand-over-mouth:before {
  content: "\e378\fe01"; }

.fad.fa-face-hand-over-mouth:after, .fa-duotone.fa-face-hand-over-mouth:after {
  content: "\e378\fe02"; }

.fad.fa-face-hand-yawn:before, .fa-duotone.fa-face-hand-yawn:before {
  content: "\e379\fe01"; }

.fad.fa-face-hand-yawn:after, .fa-duotone.fa-face-hand-yawn:after {
  content: "\e379\fe02"; }

.fad.fa-face-head-bandage:before, .fa-duotone.fa-face-head-bandage:before {
  content: "\e37a\fe01"; }

.fad.fa-face-head-bandage:after, .fa-duotone.fa-face-head-bandage:after {
  content: "\e37a\fe02"; }

.fad.fa-face-hushed:before, .fa-duotone.fa-face-hushed:before {
  content: "\e37b\fe01"; }

.fad.fa-face-hushed:after, .fa-duotone.fa-face-hushed:after {
  content: "\e37b\fe02"; }

.fad.fa-face-icicles:before, .fa-duotone.fa-face-icicles:before {
  content: "\e37c\fe01"; }

.fad.fa-face-icicles:after, .fa-duotone.fa-face-icicles:after {
  content: "\e37c\fe02"; }

.fad.fa-face-kiss:before, .fa-duotone.fa-face-kiss:before {
  content: "\f596"; }

.fad.fa-face-kiss:after, .fa-duotone.fa-face-kiss:after {
  content: "\10f596"; }

.fad.fa-kiss:before, .fa-duotone.fa-kiss:before {
  content: "\f596"; }

.fad.fa-kiss:after, .fa-duotone.fa-kiss:after {
  content: "\10f596"; }

.fad.fa-face-kiss-beam:before, .fa-duotone.fa-face-kiss-beam:before {
  content: "\f597"; }

.fad.fa-face-kiss-beam:after, .fa-duotone.fa-face-kiss-beam:after {
  content: "\10f597"; }

.fad.fa-kiss-beam:before, .fa-duotone.fa-kiss-beam:before {
  content: "\f597"; }

.fad.fa-kiss-beam:after, .fa-duotone.fa-kiss-beam:after {
  content: "\10f597"; }

.fad.fa-face-kiss-closed-eyes:before, .fa-duotone.fa-face-kiss-closed-eyes:before {
  content: "\e37d\fe01"; }

.fad.fa-face-kiss-closed-eyes:after, .fa-duotone.fa-face-kiss-closed-eyes:after {
  content: "\e37d\fe02"; }

.fad.fa-face-kiss-wink-heart:before, .fa-duotone.fa-face-kiss-wink-heart:before {
  content: "\f598"; }

.fad.fa-face-kiss-wink-heart:after, .fa-duotone.fa-face-kiss-wink-heart:after {
  content: "\10f598"; }

.fad.fa-kiss-wink-heart:before, .fa-duotone.fa-kiss-wink-heart:before {
  content: "\f598"; }

.fad.fa-kiss-wink-heart:after, .fa-duotone.fa-kiss-wink-heart:after {
  content: "\10f598"; }

.fad.fa-face-laugh:before, .fa-duotone.fa-face-laugh:before {
  content: "\f599\fe01"; }

.fad.fa-face-laugh:after, .fa-duotone.fa-face-laugh:after {
  content: "\f599\fe02"; }

.fad.fa-laugh:before, .fa-duotone.fa-laugh:before {
  content: "\f599\fe01"; }

.fad.fa-laugh:after, .fa-duotone.fa-laugh:after {
  content: "\f599\fe02"; }

.fad.fa-face-laugh-beam:before, .fa-duotone.fa-face-laugh-beam:before {
  content: "\f59a"; }

.fad.fa-face-laugh-beam:after, .fa-duotone.fa-face-laugh-beam:after {
  content: "\10f59a"; }

.fad.fa-laugh-beam:before, .fa-duotone.fa-laugh-beam:before {
  content: "\f59a"; }

.fad.fa-laugh-beam:after, .fa-duotone.fa-laugh-beam:after {
  content: "\10f59a"; }

.fad.fa-face-laugh-squint:before, .fa-duotone.fa-face-laugh-squint:before {
  content: "\f59b\fe01"; }

.fad.fa-face-laugh-squint:after, .fa-duotone.fa-face-laugh-squint:after {
  content: "\f59b\fe02"; }

.fad.fa-laugh-squint:before, .fa-duotone.fa-laugh-squint:before {
  content: "\f59b\fe01"; }

.fad.fa-laugh-squint:after, .fa-duotone.fa-laugh-squint:after {
  content: "\f59b\fe02"; }

.fad.fa-face-laugh-wink:before, .fa-duotone.fa-face-laugh-wink:before {
  content: "\f59c\fe01"; }

.fad.fa-face-laugh-wink:after, .fa-duotone.fa-face-laugh-wink:after {
  content: "\f59c\fe02"; }

.fad.fa-laugh-wink:before, .fa-duotone.fa-laugh-wink:before {
  content: "\f59c\fe01"; }

.fad.fa-laugh-wink:after, .fa-duotone.fa-laugh-wink:after {
  content: "\f59c\fe02"; }

.fad.fa-face-lying:before, .fa-duotone.fa-face-lying:before {
  content: "\e37e\fe01"; }

.fad.fa-face-lying:after, .fa-duotone.fa-face-lying:after {
  content: "\e37e\fe02"; }

.fad.fa-face-mask:before, .fa-duotone.fa-face-mask:before {
  content: "\e37f\fe01"; }

.fad.fa-face-mask:after, .fa-duotone.fa-face-mask:after {
  content: "\e37f\fe02"; }

.fad.fa-face-meh:before, .fa-duotone.fa-face-meh:before {
  content: "\f11a"; }

.fad.fa-face-meh:after, .fa-duotone.fa-face-meh:after {
  content: "\10f11a"; }

.fad.fa-meh:before, .fa-duotone.fa-meh:before {
  content: "\f11a"; }

.fad.fa-meh:after, .fa-duotone.fa-meh:after {
  content: "\10f11a"; }

.fad.fa-face-meh-blank:before, .fa-duotone.fa-face-meh-blank:before {
  content: "\f5a4"; }

.fad.fa-face-meh-blank:after, .fa-duotone.fa-face-meh-blank:after {
  content: "\10f5a4"; }

.fad.fa-meh-blank:before, .fa-duotone.fa-meh-blank:before {
  content: "\f5a4"; }

.fad.fa-meh-blank:after, .fa-duotone.fa-meh-blank:after {
  content: "\10f5a4"; }

.fad.fa-face-monocle:before, .fa-duotone.fa-face-monocle:before {
  content: "\e380\fe01"; }

.fad.fa-face-monocle:after, .fa-duotone.fa-face-monocle:after {
  content: "\e380\fe02"; }

.fad.fa-face-nauseated:before, .fa-duotone.fa-face-nauseated:before {
  content: "\e381\fe01"; }

.fad.fa-face-nauseated:after, .fa-duotone.fa-face-nauseated:after {
  content: "\e381\fe02"; }

.fad.fa-face-nose-steam:before, .fa-duotone.fa-face-nose-steam:before {
  content: "\e382\fe01"; }

.fad.fa-face-nose-steam:after, .fa-duotone.fa-face-nose-steam:after {
  content: "\e382\fe02"; }

.fad.fa-face-party:before, .fa-duotone.fa-face-party:before {
  content: "\e383\fe01"; }

.fad.fa-face-party:after, .fa-duotone.fa-face-party:after {
  content: "\e383\fe02"; }

.fad.fa-face-pensive:before, .fa-duotone.fa-face-pensive:before {
  content: "\e384\fe01"; }

.fad.fa-face-pensive:after, .fa-duotone.fa-face-pensive:after {
  content: "\e384\fe02"; }

.fad.fa-face-persevering:before, .fa-duotone.fa-face-persevering:before {
  content: "\e385\fe01"; }

.fad.fa-face-persevering:after, .fa-duotone.fa-face-persevering:after {
  content: "\e385\fe02"; }

.fad.fa-face-pleading:before, .fa-duotone.fa-face-pleading:before {
  content: "\e386\fe01"; }

.fad.fa-face-pleading:after, .fa-duotone.fa-face-pleading:after {
  content: "\e386\fe02"; }

.fad.fa-face-pouting:before, .fa-duotone.fa-face-pouting:before {
  content: "\e387\fe01"; }

.fad.fa-face-pouting:after, .fa-duotone.fa-face-pouting:after {
  content: "\e387\fe02"; }

.fad.fa-face-raised-eyebrow:before, .fa-duotone.fa-face-raised-eyebrow:before {
  content: "\e388\fe01"; }

.fad.fa-face-raised-eyebrow:after, .fa-duotone.fa-face-raised-eyebrow:after {
  content: "\e388\fe02"; }

.fad.fa-face-relieved:before, .fa-duotone.fa-face-relieved:before {
  content: "\e389\fe01"; }

.fad.fa-face-relieved:after, .fa-duotone.fa-face-relieved:after {
  content: "\e389\fe02"; }

.fad.fa-face-rolling-eyes:before, .fa-duotone.fa-face-rolling-eyes:before {
  content: "\f5a5"; }

.fad.fa-face-rolling-eyes:after, .fa-duotone.fa-face-rolling-eyes:after {
  content: "\10f5a5"; }

.fad.fa-meh-rolling-eyes:before, .fa-duotone.fa-meh-rolling-eyes:before {
  content: "\f5a5"; }

.fad.fa-meh-rolling-eyes:after, .fa-duotone.fa-meh-rolling-eyes:after {
  content: "\10f5a5"; }

.fad.fa-face-sad-cry:before, .fa-duotone.fa-face-sad-cry:before {
  content: "\f5b3"; }

.fad.fa-face-sad-cry:after, .fa-duotone.fa-face-sad-cry:after {
  content: "\10f5b3"; }

.fad.fa-sad-cry:before, .fa-duotone.fa-sad-cry:before {
  content: "\f5b3"; }

.fad.fa-sad-cry:after, .fa-duotone.fa-sad-cry:after {
  content: "\10f5b3"; }

.fad.fa-face-sad-sweat:before, .fa-duotone.fa-face-sad-sweat:before {
  content: "\e38a\fe01"; }

.fad.fa-face-sad-sweat:after, .fa-duotone.fa-face-sad-sweat:after {
  content: "\e38a\fe02"; }

.fad.fa-face-sad-tear:before, .fa-duotone.fa-face-sad-tear:before {
  content: "\f5b4"; }

.fad.fa-face-sad-tear:after, .fa-duotone.fa-face-sad-tear:after {
  content: "\10f5b4"; }

.fad.fa-sad-tear:before, .fa-duotone.fa-sad-tear:before {
  content: "\f5b4"; }

.fad.fa-sad-tear:after, .fa-duotone.fa-sad-tear:after {
  content: "\10f5b4"; }

.fad.fa-face-scream:before, .fa-duotone.fa-face-scream:before {
  content: "\e38b\fe01"; }

.fad.fa-face-scream:after, .fa-duotone.fa-face-scream:after {
  content: "\e38b\fe02"; }

.fad.fa-face-shush:before, .fa-duotone.fa-face-shush:before {
  content: "\e38c\fe01"; }

.fad.fa-face-shush:after, .fa-duotone.fa-face-shush:after {
  content: "\e38c\fe02"; }

.fad.fa-face-sleeping:before, .fa-duotone.fa-face-sleeping:before {
  content: "\e38d\fe01"; }

.fad.fa-face-sleeping:after, .fa-duotone.fa-face-sleeping:after {
  content: "\e38d\fe02"; }

.fad.fa-face-sleepy:before, .fa-duotone.fa-face-sleepy:before {
  content: "\e38e\fe01"; }

.fad.fa-face-sleepy:after, .fa-duotone.fa-face-sleepy:after {
  content: "\e38e\fe02"; }

.fad.fa-face-smile:before, .fa-duotone.fa-face-smile:before {
  content: "\f118"; }

.fad.fa-face-smile:after, .fa-duotone.fa-face-smile:after {
  content: "\10f118"; }

.fad.fa-smile:before, .fa-duotone.fa-smile:before {
  content: "\f118"; }

.fad.fa-smile:after, .fa-duotone.fa-smile:after {
  content: "\10f118"; }

.fad.fa-face-smile-beam:before, .fa-duotone.fa-face-smile-beam:before {
  content: "\f5b8"; }

.fad.fa-face-smile-beam:after, .fa-duotone.fa-face-smile-beam:after {
  content: "\10f5b8"; }

.fad.fa-smile-beam:before, .fa-duotone.fa-smile-beam:before {
  content: "\f5b8"; }

.fad.fa-smile-beam:after, .fa-duotone.fa-smile-beam:after {
  content: "\10f5b8"; }

.fad.fa-face-smile-halo:before, .fa-duotone.fa-face-smile-halo:before {
  content: "\e38f\fe01"; }

.fad.fa-face-smile-halo:after, .fa-duotone.fa-face-smile-halo:after {
  content: "\e38f\fe02"; }

.fad.fa-face-smile-hearts:before, .fa-duotone.fa-face-smile-hearts:before {
  content: "\e390\fe01"; }

.fad.fa-face-smile-hearts:after, .fa-duotone.fa-face-smile-hearts:after {
  content: "\e390\fe02"; }

.fad.fa-face-smile-horns:before, .fa-duotone.fa-face-smile-horns:before {
  content: "\e391\fe01"; }

.fad.fa-face-smile-horns:after, .fa-duotone.fa-face-smile-horns:after {
  content: "\e391\fe02"; }

.fad.fa-face-smile-plus:before, .fa-duotone.fa-face-smile-plus:before {
  content: "\f5b9\fe01"; }

.fad.fa-face-smile-plus:after, .fa-duotone.fa-face-smile-plus:after {
  content: "\f5b9\fe02"; }

.fad.fa-smile-plus:before, .fa-duotone.fa-smile-plus:before {
  content: "\f5b9\fe01"; }

.fad.fa-smile-plus:after, .fa-duotone.fa-smile-plus:after {
  content: "\f5b9\fe02"; }

.fad.fa-face-smile-relaxed:before, .fa-duotone.fa-face-smile-relaxed:before {
  content: "\e392\fe01"; }

.fad.fa-face-smile-relaxed:after, .fa-duotone.fa-face-smile-relaxed:after {
  content: "\e392\fe02"; }

.fad.fa-face-smile-tear:before, .fa-duotone.fa-face-smile-tear:before {
  content: "\e393\fe01"; }

.fad.fa-face-smile-tear:after, .fa-duotone.fa-face-smile-tear:after {
  content: "\e393\fe02"; }

.fad.fa-face-smile-tongue:before, .fa-duotone.fa-face-smile-tongue:before {
  content: "\e394\fe01"; }

.fad.fa-face-smile-tongue:after, .fa-duotone.fa-face-smile-tongue:after {
  content: "\e394\fe02"; }

.fad.fa-face-smile-upside-down:before, .fa-duotone.fa-face-smile-upside-down:before {
  content: "\e395\fe01"; }

.fad.fa-face-smile-upside-down:after, .fa-duotone.fa-face-smile-upside-down:after {
  content: "\e395\fe02"; }

.fad.fa-face-smile-wink:before, .fa-duotone.fa-face-smile-wink:before {
  content: "\f4da"; }

.fad.fa-face-smile-wink:after, .fa-duotone.fa-face-smile-wink:after {
  content: "\10f4da"; }

.fad.fa-smile-wink:before, .fa-duotone.fa-smile-wink:before {
  content: "\f4da"; }

.fad.fa-smile-wink:after, .fa-duotone.fa-smile-wink:after {
  content: "\10f4da"; }

.fad.fa-face-smiling-hands:before, .fa-duotone.fa-face-smiling-hands:before {
  content: "\e396\fe01"; }

.fad.fa-face-smiling-hands:after, .fa-duotone.fa-face-smiling-hands:after {
  content: "\e396\fe02"; }

.fad.fa-face-smirking:before, .fa-duotone.fa-face-smirking:before {
  content: "\e397\fe01"; }

.fad.fa-face-smirking:after, .fa-duotone.fa-face-smirking:after {
  content: "\e397\fe02"; }

.fad.fa-face-sunglasses:before, .fa-duotone.fa-face-sunglasses:before {
  content: "\e398\fe01"; }

.fad.fa-face-sunglasses:after, .fa-duotone.fa-face-sunglasses:after {
  content: "\e398\fe02"; }

.fad.fa-face-surprise:before, .fa-duotone.fa-face-surprise:before {
  content: "\f5c2"; }

.fad.fa-face-surprise:after, .fa-duotone.fa-face-surprise:after {
  content: "\10f5c2"; }

.fad.fa-surprise:before, .fa-duotone.fa-surprise:before {
  content: "\f5c2"; }

.fad.fa-surprise:after, .fa-duotone.fa-surprise:after {
  content: "\10f5c2"; }

.fad.fa-face-swear:before, .fa-duotone.fa-face-swear:before {
  content: "\e399\fe01"; }

.fad.fa-face-swear:after, .fa-duotone.fa-face-swear:after {
  content: "\e399\fe02"; }

.fad.fa-face-thermometer:before, .fa-duotone.fa-face-thermometer:before {
  content: "\e39a\fe01"; }

.fad.fa-face-thermometer:after, .fa-duotone.fa-face-thermometer:after {
  content: "\e39a\fe02"; }

.fad.fa-face-thinking:before, .fa-duotone.fa-face-thinking:before {
  content: "\e39b\fe01"; }

.fad.fa-face-thinking:after, .fa-duotone.fa-face-thinking:after {
  content: "\e39b\fe02"; }

.fad.fa-face-tired:before, .fa-duotone.fa-face-tired:before {
  content: "\f5c8"; }

.fad.fa-face-tired:after, .fa-duotone.fa-face-tired:after {
  content: "\10f5c8"; }

.fad.fa-tired:before, .fa-duotone.fa-tired:before {
  content: "\f5c8"; }

.fad.fa-tired:after, .fa-duotone.fa-tired:after {
  content: "\10f5c8"; }

.fad.fa-face-tissue:before, .fa-duotone.fa-face-tissue:before {
  content: "\e39c\fe01"; }

.fad.fa-face-tissue:after, .fa-duotone.fa-face-tissue:after {
  content: "\e39c\fe02"; }

.fad.fa-face-tongue-money:before, .fa-duotone.fa-face-tongue-money:before {
  content: "\e39d\fe01"; }

.fad.fa-face-tongue-money:after, .fa-duotone.fa-face-tongue-money:after {
  content: "\e39d\fe02"; }

.fad.fa-face-tongue-sweat:before, .fa-duotone.fa-face-tongue-sweat:before {
  content: "\e39e\fe01"; }

.fad.fa-face-tongue-sweat:after, .fa-duotone.fa-face-tongue-sweat:after {
  content: "\e39e\fe02"; }

.fad.fa-face-unamused:before, .fa-duotone.fa-face-unamused:before {
  content: "\e39f\fe01"; }

.fad.fa-face-unamused:after, .fa-duotone.fa-face-unamused:after {
  content: "\e39f\fe02"; }

.fad.fa-face-viewfinder:before, .fa-duotone.fa-face-viewfinder:before {
  content: "\e2ff\fe01"; }

.fad.fa-face-viewfinder:after, .fa-duotone.fa-face-viewfinder:after {
  content: "\e2ff\fe02"; }

.fad.fa-face-vomit:before, .fa-duotone.fa-face-vomit:before {
  content: "\e3a0\fe01"; }

.fad.fa-face-vomit:after, .fa-duotone.fa-face-vomit:after {
  content: "\e3a0\fe02"; }

.fad.fa-face-weary:before, .fa-duotone.fa-face-weary:before {
  content: "\e3a1\fe01"; }

.fad.fa-face-weary:after, .fa-duotone.fa-face-weary:after {
  content: "\e3a1\fe02"; }

.fad.fa-face-woozy:before, .fa-duotone.fa-face-woozy:before {
  content: "\e3a2\fe01"; }

.fad.fa-face-woozy:after, .fa-duotone.fa-face-woozy:after {
  content: "\e3a2\fe02"; }

.fad.fa-face-worried:before, .fa-duotone.fa-face-worried:before {
  content: "\e3a3\fe01"; }

.fad.fa-face-worried:after, .fa-duotone.fa-face-worried:after {
  content: "\e3a3\fe02"; }

.fad.fa-face-zany:before, .fa-duotone.fa-face-zany:before {
  content: "\e3a4\fe01"; }

.fad.fa-face-zany:after, .fa-duotone.fa-face-zany:after {
  content: "\e3a4\fe02"; }

.fad.fa-face-zipper:before, .fa-duotone.fa-face-zipper:before {
  content: "\e3a5\fe01"; }

.fad.fa-face-zipper:after, .fa-duotone.fa-face-zipper:after {
  content: "\e3a5\fe02"; }

.fad.fa-family:before, .fa-duotone.fa-family:before {
  content: "\e300\fe01"; }

.fad.fa-family:after, .fa-duotone.fa-family:after {
  content: "\e300\fe02"; }

.fad.fa-family-dress:before, .fa-duotone.fa-family-dress:before {
  content: "\e301\fe01"; }

.fad.fa-family-dress:after, .fa-duotone.fa-family-dress:after {
  content: "\e301\fe02"; }

.fad.fa-family-pants:before, .fa-duotone.fa-family-pants:before {
  content: "\e302\fe01"; }

.fad.fa-family-pants:after, .fa-duotone.fa-family-pants:after {
  content: "\e302\fe02"; }

.fad.fa-fan:before, .fa-duotone.fa-fan:before {
  content: "\f863\fe01"; }

.fad.fa-fan:after, .fa-duotone.fa-fan:after {
  content: "\f863\fe02"; }

.fad.fa-fan-table:before, .fa-duotone.fa-fan-table:before {
  content: "\e004\fe01"; }

.fad.fa-fan-table:after, .fa-duotone.fa-fan-table:after {
  content: "\e004\fe02"; }

.fad.fa-farm:before, .fa-duotone.fa-farm:before {
  content: "\f864\fe01"; }

.fad.fa-farm:after, .fa-duotone.fa-farm:after {
  content: "\f864\fe02"; }

.fad.fa-barn-silo:before, .fa-duotone.fa-barn-silo:before {
  content: "\f864\fe01"; }

.fad.fa-barn-silo:after, .fa-duotone.fa-barn-silo:after {
  content: "\f864\fe02"; }

.fad.fa-faucet:before, .fa-duotone.fa-faucet:before {
  content: "\e005\fe01"; }

.fad.fa-faucet:after, .fa-duotone.fa-faucet:after {
  content: "\e005\fe02"; }

.fad.fa-faucet-drip:before, .fa-duotone.fa-faucet-drip:before {
  content: "\e006"; }

.fad.fa-faucet-drip:after, .fa-duotone.fa-faucet-drip:after {
  content: "\10e006"; }

.fad.fa-fax:before, .fa-duotone.fa-fax:before {
  content: "\f1ac"; }

.fad.fa-fax:after, .fa-duotone.fa-fax:after {
  content: "\10f1ac"; }

.fad.fa-feather:before, .fa-duotone.fa-feather:before {
  content: "\f52d"; }

.fad.fa-feather:after, .fa-duotone.fa-feather:after {
  content: "\10f52d"; }

.fad.fa-feather-pointed:before, .fa-duotone.fa-feather-pointed:before {
  content: "\f56b\fe01"; }

.fad.fa-feather-pointed:after, .fa-duotone.fa-feather-pointed:after {
  content: "\f56b\fe02"; }

.fad.fa-feather-alt:before, .fa-duotone.fa-feather-alt:before {
  content: "\f56b\fe01"; }

.fad.fa-feather-alt:after, .fa-duotone.fa-feather-alt:after {
  content: "\f56b\fe02"; }

.fad.fa-fence:before, .fa-duotone.fa-fence:before {
  content: "\e303\fe01"; }

.fad.fa-fence:after, .fa-duotone.fa-fence:after {
  content: "\e303\fe02"; }

.fad.fa-ferris-wheel:before, .fa-duotone.fa-ferris-wheel:before {
  content: "\e174\fe01"; }

.fad.fa-ferris-wheel:after, .fa-duotone.fa-ferris-wheel:after {
  content: "\e174\fe02"; }

.fad.fa-field-hockey-stick-ball:before, .fa-duotone.fa-field-hockey-stick-ball:before {
  content: "\f44c"; }

.fad.fa-field-hockey-stick-ball:after, .fa-duotone.fa-field-hockey-stick-ball:after {
  content: "\10f44c"; }

.fad.fa-field-hockey:before, .fa-duotone.fa-field-hockey:before {
  content: "\f44c"; }

.fad.fa-field-hockey:after, .fa-duotone.fa-field-hockey:after {
  content: "\10f44c"; }

.fad.fa-file:before, .fa-duotone.fa-file:before {
  content: "\f15b"; }

.fad.fa-file:after, .fa-duotone.fa-file:after {
  content: "\10f15b"; }

.fad.fa-file-arrow-down:before, .fa-duotone.fa-file-arrow-down:before {
  content: "\f56d\fe01"; }

.fad.fa-file-arrow-down:after, .fa-duotone.fa-file-arrow-down:after {
  content: "\f56d\fe02"; }

.fad.fa-file-download:before, .fa-duotone.fa-file-download:before {
  content: "\f56d\fe01"; }

.fad.fa-file-download:after, .fa-duotone.fa-file-download:after {
  content: "\f56d\fe02"; }

.fad.fa-file-arrow-up:before, .fa-duotone.fa-file-arrow-up:before {
  content: "\f574\fe01"; }

.fad.fa-file-arrow-up:after, .fa-duotone.fa-file-arrow-up:after {
  content: "\f574\fe02"; }

.fad.fa-file-upload:before, .fa-duotone.fa-file-upload:before {
  content: "\f574\fe01"; }

.fad.fa-file-upload:after, .fa-duotone.fa-file-upload:after {
  content: "\f574\fe02"; }

.fad.fa-file-audio:before, .fa-duotone.fa-file-audio:before {
  content: "\f1c7\fe01"; }

.fad.fa-file-audio:after, .fa-duotone.fa-file-audio:after {
  content: "\f1c7\fe02"; }

.fad.fa-file-binary:before, .fa-duotone.fa-file-binary:before {
  content: "\e175\fe01"; }

.fad.fa-file-binary:after, .fa-duotone.fa-file-binary:after {
  content: "\e175\fe02"; }

.fad.fa-file-certificate:before, .fa-duotone.fa-file-certificate:before {
  content: "\f5f3\fe01"; }

.fad.fa-file-certificate:after, .fa-duotone.fa-file-certificate:after {
  content: "\f5f3\fe02"; }

.fad.fa-file-award:before, .fa-duotone.fa-file-award:before {
  content: "\f5f3\fe01"; }

.fad.fa-file-award:after, .fa-duotone.fa-file-award:after {
  content: "\f5f3\fe02"; }

.fad.fa-file-chart-column:before, .fa-duotone.fa-file-chart-column:before {
  content: "\f659\fe01"; }

.fad.fa-file-chart-column:after, .fa-duotone.fa-file-chart-column:after {
  content: "\f659\fe02"; }

.fad.fa-file-chart-line:before, .fa-duotone.fa-file-chart-line:before {
  content: "\f659\fe01"; }

.fad.fa-file-chart-line:after, .fa-duotone.fa-file-chart-line:after {
  content: "\f659\fe02"; }

.fad.fa-file-chart-pie:before, .fa-duotone.fa-file-chart-pie:before {
  content: "\f65a\fe01"; }

.fad.fa-file-chart-pie:after, .fa-duotone.fa-file-chart-pie:after {
  content: "\f65a\fe02"; }

.fad.fa-file-check:before, .fa-duotone.fa-file-check:before {
  content: "\f316\fe01"; }

.fad.fa-file-check:after, .fa-duotone.fa-file-check:after {
  content: "\f316\fe02"; }

.fad.fa-file-code:before, .fa-duotone.fa-file-code:before {
  content: "\f1c9\fe01"; }

.fad.fa-file-code:after, .fa-duotone.fa-file-code:after {
  content: "\f1c9\fe02"; }

.fad.fa-file-contract:before, .fa-duotone.fa-file-contract:before {
  content: "\f56c\fe01"; }

.fad.fa-file-contract:after, .fa-duotone.fa-file-contract:after {
  content: "\f56c\fe02"; }

.fad.fa-file-csv:before, .fa-duotone.fa-file-csv:before {
  content: "\f6dd\fe01"; }

.fad.fa-file-csv:after, .fa-duotone.fa-file-csv:after {
  content: "\f6dd\fe02"; }

.fad.fa-file-dashed-line:before, .fa-duotone.fa-file-dashed-line:before {
  content: "\f877\fe01"; }

.fad.fa-file-dashed-line:after, .fa-duotone.fa-file-dashed-line:after {
  content: "\f877\fe02"; }

.fad.fa-page-break:before, .fa-duotone.fa-page-break:before {
  content: "\f877\fe01"; }

.fad.fa-page-break:after, .fa-duotone.fa-page-break:after {
  content: "\f877\fe02"; }

.fad.fa-file-excel:before, .fa-duotone.fa-file-excel:before {
  content: "\f1c3\fe01"; }

.fad.fa-file-excel:after, .fa-duotone.fa-file-excel:after {
  content: "\f1c3\fe02"; }

.fad.fa-file-exclamation:before, .fa-duotone.fa-file-exclamation:before {
  content: "\f31a\fe01"; }

.fad.fa-file-exclamation:after, .fa-duotone.fa-file-exclamation:after {
  content: "\f31a\fe02"; }

.fad.fa-file-export:before, .fa-duotone.fa-file-export:before {
  content: "\f56e\fe01"; }

.fad.fa-file-export:after, .fa-duotone.fa-file-export:after {
  content: "\f56e\fe02"; }

.fad.fa-arrow-right-from-file:before, .fa-duotone.fa-arrow-right-from-file:before {
  content: "\f56e\fe01"; }

.fad.fa-arrow-right-from-file:after, .fa-duotone.fa-arrow-right-from-file:after {
  content: "\f56e\fe02"; }

.fad.fa-file-heart:before, .fa-duotone.fa-file-heart:before {
  content: "\e176\fe01"; }

.fad.fa-file-heart:after, .fa-duotone.fa-file-heart:after {
  content: "\e176\fe02"; }

.fad.fa-file-image:before, .fa-duotone.fa-file-image:before {
  content: "\f1c5"; }

.fad.fa-file-image:after, .fa-duotone.fa-file-image:after {
  content: "\10f1c5"; }

.fad.fa-file-import:before, .fa-duotone.fa-file-import:before {
  content: "\f56f\fe01"; }

.fad.fa-file-import:after, .fa-duotone.fa-file-import:after {
  content: "\f56f\fe02"; }

.fad.fa-arrow-right-to-file:before, .fa-duotone.fa-arrow-right-to-file:before {
  content: "\f56f\fe01"; }

.fad.fa-arrow-right-to-file:after, .fa-duotone.fa-arrow-right-to-file:after {
  content: "\f56f\fe02"; }

.fad.fa-file-invoice:before, .fa-duotone.fa-file-invoice:before {
  content: "\f570\fe01"; }

.fad.fa-file-invoice:after, .fa-duotone.fa-file-invoice:after {
  content: "\f570\fe02"; }

.fad.fa-file-invoice-dollar:before, .fa-duotone.fa-file-invoice-dollar:before {
  content: "\f571\fe01"; }

.fad.fa-file-invoice-dollar:after, .fa-duotone.fa-file-invoice-dollar:after {
  content: "\f571\fe02"; }

.fad.fa-file-lines:before, .fa-duotone.fa-file-lines:before {
  content: "\f15c"; }

.fad.fa-file-lines:after, .fa-duotone.fa-file-lines:after {
  content: "\10f15c"; }

.fad.fa-file-alt:before, .fa-duotone.fa-file-alt:before {
  content: "\f15c"; }

.fad.fa-file-alt:after, .fa-duotone.fa-file-alt:after {
  content: "\10f15c"; }

.fad.fa-file-text:before, .fa-duotone.fa-file-text:before {
  content: "\f15c"; }

.fad.fa-file-text:after, .fa-duotone.fa-file-text:after {
  content: "\10f15c"; }

.fad.fa-file-lock:before, .fa-duotone.fa-file-lock:before {
  content: "\e3a6\fe01"; }

.fad.fa-file-lock:after, .fa-duotone.fa-file-lock:after {
  content: "\e3a6\fe02"; }

.fad.fa-file-magnifying-glass:before, .fa-duotone.fa-file-magnifying-glass:before {
  content: "\f865\fe01"; }

.fad.fa-file-magnifying-glass:after, .fa-duotone.fa-file-magnifying-glass:after {
  content: "\f865\fe02"; }

.fad.fa-file-search:before, .fa-duotone.fa-file-search:before {
  content: "\f865\fe01"; }

.fad.fa-file-search:after, .fa-duotone.fa-file-search:after {
  content: "\f865\fe02"; }

.fad.fa-file-medical:before, .fa-duotone.fa-file-medical:before {
  content: "\f477\fe01"; }

.fad.fa-file-medical:after, .fa-duotone.fa-file-medical:after {
  content: "\f477\fe02"; }

.fad.fa-file-minus:before, .fa-duotone.fa-file-minus:before {
  content: "\f318\fe01"; }

.fad.fa-file-minus:after, .fa-duotone.fa-file-minus:after {
  content: "\f318\fe02"; }

.fad.fa-file-music:before, .fa-duotone.fa-file-music:before {
  content: "\f8b6\fe01"; }

.fad.fa-file-music:after, .fa-duotone.fa-file-music:after {
  content: "\f8b6\fe02"; }

.fad.fa-file-pdf:before, .fa-duotone.fa-file-pdf:before {
  content: "\f1c1\fe01"; }

.fad.fa-file-pdf:after, .fa-duotone.fa-file-pdf:after {
  content: "\f1c1\fe02"; }

.fad.fa-file-pen:before, .fa-duotone.fa-file-pen:before {
  content: "\f31c"; }

.fad.fa-file-pen:after, .fa-duotone.fa-file-pen:after {
  content: "\10f31c"; }

.fad.fa-file-edit:before, .fa-duotone.fa-file-edit:before {
  content: "\f31c"; }

.fad.fa-file-edit:after, .fa-duotone.fa-file-edit:after {
  content: "\10f31c"; }

.fad.fa-file-plus:before, .fa-duotone.fa-file-plus:before {
  content: "\f319\fe01"; }

.fad.fa-file-plus:after, .fa-duotone.fa-file-plus:after {
  content: "\f319\fe02"; }

.fad.fa-file-plus-minus:before, .fa-duotone.fa-file-plus-minus:before {
  content: "\e177\fe01"; }

.fad.fa-file-plus-minus:after, .fa-duotone.fa-file-plus-minus:after {
  content: "\e177\fe02"; }

.fad.fa-file-powerpoint:before, .fa-duotone.fa-file-powerpoint:before {
  content: "\f1c4\fe01"; }

.fad.fa-file-powerpoint:after, .fa-duotone.fa-file-powerpoint:after {
  content: "\f1c4\fe02"; }

.fad.fa-file-prescription:before, .fa-duotone.fa-file-prescription:before {
  content: "\f572\fe01"; }

.fad.fa-file-prescription:after, .fa-duotone.fa-file-prescription:after {
  content: "\f572\fe02"; }

.fad.fa-file-signature:before, .fa-duotone.fa-file-signature:before {
  content: "\f573\fe01"; }

.fad.fa-file-signature:after, .fa-duotone.fa-file-signature:after {
  content: "\f573\fe02"; }

.fad.fa-file-slash:before, .fa-duotone.fa-file-slash:before {
  content: "\e3a7\fe01"; }

.fad.fa-file-slash:after, .fa-duotone.fa-file-slash:after {
  content: "\e3a7\fe02"; }

.fad.fa-file-spreadsheet:before, .fa-duotone.fa-file-spreadsheet:before {
  content: "\f65b\fe01"; }

.fad.fa-file-spreadsheet:after, .fa-duotone.fa-file-spreadsheet:after {
  content: "\f65b\fe02"; }

.fad.fa-file-user:before, .fa-duotone.fa-file-user:before {
  content: "\f65c\fe01"; }

.fad.fa-file-user:after, .fa-duotone.fa-file-user:after {
  content: "\f65c\fe02"; }

.fad.fa-file-video:before, .fa-duotone.fa-file-video:before {
  content: "\f1c8\fe01"; }

.fad.fa-file-video:after, .fa-duotone.fa-file-video:after {
  content: "\f1c8\fe02"; }

.fad.fa-file-waveform:before, .fa-duotone.fa-file-waveform:before {
  content: "\f478\fe01"; }

.fad.fa-file-waveform:after, .fa-duotone.fa-file-waveform:after {
  content: "\f478\fe02"; }

.fad.fa-file-medical-alt:before, .fa-duotone.fa-file-medical-alt:before {
  content: "\f478\fe01"; }

.fad.fa-file-medical-alt:after, .fa-duotone.fa-file-medical-alt:after {
  content: "\f478\fe02"; }

.fad.fa-file-word:before, .fa-duotone.fa-file-word:before {
  content: "\f1c2\fe01"; }

.fad.fa-file-word:after, .fa-duotone.fa-file-word:after {
  content: "\f1c2\fe02"; }

.fad.fa-file-xmark:before, .fa-duotone.fa-file-xmark:before {
  content: "\f317\fe01"; }

.fad.fa-file-xmark:after, .fa-duotone.fa-file-xmark:after {
  content: "\f317\fe02"; }

.fad.fa-file-times:before, .fa-duotone.fa-file-times:before {
  content: "\f317\fe01"; }

.fad.fa-file-times:after, .fa-duotone.fa-file-times:after {
  content: "\f317\fe02"; }

.fad.fa-file-zipper:before, .fa-duotone.fa-file-zipper:before {
  content: "\f1c6\fe01"; }

.fad.fa-file-zipper:after, .fa-duotone.fa-file-zipper:after {
  content: "\f1c6\fe02"; }

.fad.fa-file-archive:before, .fa-duotone.fa-file-archive:before {
  content: "\f1c6\fe01"; }

.fad.fa-file-archive:after, .fa-duotone.fa-file-archive:after {
  content: "\f1c6\fe02"; }

.fad.fa-files:before, .fa-duotone.fa-files:before {
  content: "\e178\fe01"; }

.fad.fa-files:after, .fa-duotone.fa-files:after {
  content: "\e178\fe02"; }

.fad.fa-files-medical:before, .fa-duotone.fa-files-medical:before {
  content: "\f7fd\fe01"; }

.fad.fa-files-medical:after, .fa-duotone.fa-files-medical:after {
  content: "\f7fd\fe02"; }

.fad.fa-fill:before, .fa-duotone.fa-fill:before {
  content: "\f575\fe01"; }

.fad.fa-fill:after, .fa-duotone.fa-fill:after {
  content: "\f575\fe02"; }

.fad.fa-fill-drip:before, .fa-duotone.fa-fill-drip:before {
  content: "\f576\fe01"; }

.fad.fa-fill-drip:after, .fa-duotone.fa-fill-drip:after {
  content: "\f576\fe02"; }

.fad.fa-film:before, .fa-duotone.fa-film:before {
  content: "\f008"; }

.fad.fa-film:after, .fa-duotone.fa-film:after {
  content: "\10f008"; }

.fad.fa-film-canister:before, .fa-duotone.fa-film-canister:before {
  content: "\f8b7\fe01"; }

.fad.fa-film-canister:after, .fa-duotone.fa-film-canister:after {
  content: "\f8b7\fe02"; }

.fad.fa-film-simple:before, .fa-duotone.fa-film-simple:before {
  content: "\f3a0\fe01"; }

.fad.fa-film-simple:after, .fa-duotone.fa-film-simple:after {
  content: "\f3a0\fe02"; }

.fad.fa-film-alt:before, .fa-duotone.fa-film-alt:before {
  content: "\f3a0\fe01"; }

.fad.fa-film-alt:after, .fa-duotone.fa-film-alt:after {
  content: "\f3a0\fe02"; }

.fad.fa-film-slash:before, .fa-duotone.fa-film-slash:before {
  content: "\e179\fe01"; }

.fad.fa-film-slash:after, .fa-duotone.fa-film-slash:after {
  content: "\e179\fe02"; }

.fad.fa-films:before, .fa-duotone.fa-films:before {
  content: "\e17a\fe01"; }

.fad.fa-films:after, .fa-duotone.fa-films:after {
  content: "\e17a\fe02"; }

.fad.fa-filter:before, .fa-duotone.fa-filter:before {
  content: "\f0b0\fe01"; }

.fad.fa-filter:after, .fa-duotone.fa-filter:after {
  content: "\f0b0\fe02"; }

.fad.fa-filter-circle-dollar:before, .fa-duotone.fa-filter-circle-dollar:before {
  content: "\f662\fe01"; }

.fad.fa-filter-circle-dollar:after, .fa-duotone.fa-filter-circle-dollar:after {
  content: "\f662\fe02"; }

.fad.fa-funnel-dollar:before, .fa-duotone.fa-funnel-dollar:before {
  content: "\f662\fe01"; }

.fad.fa-funnel-dollar:after, .fa-duotone.fa-funnel-dollar:after {
  content: "\f662\fe02"; }

.fad.fa-filter-circle-xmark:before, .fa-duotone.fa-filter-circle-xmark:before {
  content: "\e17b\fe01"; }

.fad.fa-filter-circle-xmark:after, .fa-duotone.fa-filter-circle-xmark:after {
  content: "\e17b\fe02"; }

.fad.fa-filter-list:before, .fa-duotone.fa-filter-list:before {
  content: "\e17c\fe01"; }

.fad.fa-filter-list:after, .fa-duotone.fa-filter-list:after {
  content: "\e17c\fe02"; }

.fad.fa-filter-slash:before, .fa-duotone.fa-filter-slash:before {
  content: "\e17d\fe01"; }

.fad.fa-filter-slash:after, .fa-duotone.fa-filter-slash:after {
  content: "\e17d\fe02"; }

.fad.fa-filters:before, .fa-duotone.fa-filters:before {
  content: "\e17e\fe01"; }

.fad.fa-filters:after, .fa-duotone.fa-filters:after {
  content: "\e17e\fe02"; }

.fad.fa-fingerprint:before, .fa-duotone.fa-fingerprint:before {
  content: "\f577\fe01"; }

.fad.fa-fingerprint:after, .fa-duotone.fa-fingerprint:after {
  content: "\f577\fe02"; }

.fad.fa-fire:before, .fa-duotone.fa-fire:before {
  content: "\f06d"; }

.fad.fa-fire:after, .fa-duotone.fa-fire:after {
  content: "\10f06d"; }

.fad.fa-fire-extinguisher:before, .fa-duotone.fa-fire-extinguisher:before {
  content: "\f134"; }

.fad.fa-fire-extinguisher:after, .fa-duotone.fa-fire-extinguisher:after {
  content: "\10f134"; }

.fad.fa-fire-flame:before, .fa-duotone.fa-fire-flame:before {
  content: "\f6df\fe01"; }

.fad.fa-fire-flame:after, .fa-duotone.fa-fire-flame:after {
  content: "\f6df\fe02"; }

.fad.fa-flame:before, .fa-duotone.fa-flame:before {
  content: "\f6df\fe01"; }

.fad.fa-flame:after, .fa-duotone.fa-flame:after {
  content: "\f6df\fe02"; }

.fad.fa-fire-flame-curved:before, .fa-duotone.fa-fire-flame-curved:before {
  content: "\f7e4\fe01"; }

.fad.fa-fire-flame-curved:after, .fa-duotone.fa-fire-flame-curved:after {
  content: "\f7e4\fe02"; }

.fad.fa-fire-alt:before, .fa-duotone.fa-fire-alt:before {
  content: "\f7e4\fe01"; }

.fad.fa-fire-alt:after, .fa-duotone.fa-fire-alt:after {
  content: "\f7e4\fe02"; }

.fad.fa-fire-flame-simple:before, .fa-duotone.fa-fire-flame-simple:before {
  content: "\f46a\fe01"; }

.fad.fa-fire-flame-simple:after, .fa-duotone.fa-fire-flame-simple:after {
  content: "\f46a\fe02"; }

.fad.fa-burn:before, .fa-duotone.fa-burn:before {
  content: "\f46a\fe01"; }

.fad.fa-burn:after, .fa-duotone.fa-burn:after {
  content: "\f46a\fe02"; }

.fad.fa-fire-hydrant:before, .fa-duotone.fa-fire-hydrant:before {
  content: "\e17f\fe01"; }

.fad.fa-fire-hydrant:after, .fa-duotone.fa-fire-hydrant:after {
  content: "\e17f\fe02"; }

.fad.fa-fire-smoke:before, .fa-duotone.fa-fire-smoke:before {
  content: "\f74b\fe01"; }

.fad.fa-fire-smoke:after, .fa-duotone.fa-fire-smoke:after {
  content: "\f74b\fe02"; }

.fad.fa-fireplace:before, .fa-duotone.fa-fireplace:before {
  content: "\f79a\fe01"; }

.fad.fa-fireplace:after, .fa-duotone.fa-fireplace:after {
  content: "\f79a\fe02"; }

.fad.fa-fish:before, .fa-duotone.fa-fish:before {
  content: "\f578"; }

.fad.fa-fish:after, .fa-duotone.fa-fish:after {
  content: "\10f578"; }

.fad.fa-fish-bones:before, .fa-duotone.fa-fish-bones:before {
  content: "\e304\fe01"; }

.fad.fa-fish-bones:after, .fa-duotone.fa-fish-bones:after {
  content: "\e304\fe02"; }

.fad.fa-fish-cooked:before, .fa-duotone.fa-fish-cooked:before {
  content: "\f7fe\fe01"; }

.fad.fa-fish-cooked:after, .fa-duotone.fa-fish-cooked:after {
  content: "\f7fe\fe02"; }

.fad.fa-fishing-rod:before, .fa-duotone.fa-fishing-rod:before {
  content: "\e3a8\fe01"; }

.fad.fa-fishing-rod:after, .fa-duotone.fa-fishing-rod:after {
  content: "\e3a8\fe02"; }

.fad.fa-flag:before, .fa-duotone.fa-flag:before {
  content: "\f024"; }

.fad.fa-flag:after, .fa-duotone.fa-flag:after {
  content: "\10f024"; }

.fad.fa-flag-checkered:before, .fa-duotone.fa-flag-checkered:before {
  content: "\f11e"; }

.fad.fa-flag-checkered:after, .fa-duotone.fa-flag-checkered:after {
  content: "\10f11e"; }

.fad.fa-flag-pennant:before, .fa-duotone.fa-flag-pennant:before {
  content: "\f456"; }

.fad.fa-flag-pennant:after, .fa-duotone.fa-flag-pennant:after {
  content: "\10f456"; }

.fad.fa-pennant:before, .fa-duotone.fa-pennant:before {
  content: "\f456"; }

.fad.fa-pennant:after, .fa-duotone.fa-pennant:after {
  content: "\10f456"; }

.fad.fa-flag-swallowtail:before, .fa-duotone.fa-flag-swallowtail:before {
  content: "\f74c\fe01"; }

.fad.fa-flag-swallowtail:after, .fa-duotone.fa-flag-swallowtail:after {
  content: "\f74c\fe02"; }

.fad.fa-flag-alt:before, .fa-duotone.fa-flag-alt:before {
  content: "\f74c\fe01"; }

.fad.fa-flag-alt:after, .fa-duotone.fa-flag-alt:after {
  content: "\f74c\fe02"; }

.fad.fa-flag-usa:before, .fa-duotone.fa-flag-usa:before {
  content: "\f74d\fe01"; }

.fad.fa-flag-usa:after, .fa-duotone.fa-flag-usa:after {
  content: "\f74d\fe02"; }

.fad.fa-flashlight:before, .fa-duotone.fa-flashlight:before {
  content: "\f8b8"; }

.fad.fa-flashlight:after, .fa-duotone.fa-flashlight:after {
  content: "\10f8b8"; }

.fad.fa-flask:before, .fa-duotone.fa-flask:before {
  content: "\f0c3\fe01"; }

.fad.fa-flask:after, .fa-duotone.fa-flask:after {
  content: "\f0c3\fe02"; }

.fad.fa-flask-round-poison:before, .fa-duotone.fa-flask-round-poison:before {
  content: "\f6e0\fe01"; }

.fad.fa-flask-round-poison:after, .fa-duotone.fa-flask-round-poison:after {
  content: "\f6e0\fe02"; }

.fad.fa-flask-poison:before, .fa-duotone.fa-flask-poison:before {
  content: "\f6e0\fe01"; }

.fad.fa-flask-poison:after, .fa-duotone.fa-flask-poison:after {
  content: "\f6e0\fe02"; }

.fad.fa-flask-round-potion:before, .fa-duotone.fa-flask-round-potion:before {
  content: "\f6e1\fe01"; }

.fad.fa-flask-round-potion:after, .fa-duotone.fa-flask-round-potion:after {
  content: "\f6e1\fe02"; }

.fad.fa-flask-potion:before, .fa-duotone.fa-flask-potion:before {
  content: "\f6e1\fe01"; }

.fad.fa-flask-potion:after, .fa-duotone.fa-flask-potion:after {
  content: "\f6e1\fe02"; }

.fad.fa-floppy-disk:before, .fa-duotone.fa-floppy-disk:before {
  content: "\f0c7"; }

.fad.fa-floppy-disk:after, .fa-duotone.fa-floppy-disk:after {
  content: "\10f0c7"; }

.fad.fa-save:before, .fa-duotone.fa-save:before {
  content: "\f0c7"; }

.fad.fa-save:after, .fa-duotone.fa-save:after {
  content: "\10f0c7"; }

.fad.fa-floppy-disk-circle-arrow-right:before, .fa-duotone.fa-floppy-disk-circle-arrow-right:before {
  content: "\e180\fe01"; }

.fad.fa-floppy-disk-circle-arrow-right:after, .fa-duotone.fa-floppy-disk-circle-arrow-right:after {
  content: "\e180\fe02"; }

.fad.fa-save-circle-arrow-right:before, .fa-duotone.fa-save-circle-arrow-right:before {
  content: "\e180\fe01"; }

.fad.fa-save-circle-arrow-right:after, .fa-duotone.fa-save-circle-arrow-right:after {
  content: "\e180\fe02"; }

.fad.fa-floppy-disk-circle-xmark:before, .fa-duotone.fa-floppy-disk-circle-xmark:before {
  content: "\e181\fe01"; }

.fad.fa-floppy-disk-circle-xmark:after, .fa-duotone.fa-floppy-disk-circle-xmark:after {
  content: "\e181\fe02"; }

.fad.fa-floppy-disk-times:before, .fa-duotone.fa-floppy-disk-times:before {
  content: "\e181\fe01"; }

.fad.fa-floppy-disk-times:after, .fa-duotone.fa-floppy-disk-times:after {
  content: "\e181\fe02"; }

.fad.fa-save-circle-xmark:before, .fa-duotone.fa-save-circle-xmark:before {
  content: "\e181\fe01"; }

.fad.fa-save-circle-xmark:after, .fa-duotone.fa-save-circle-xmark:after {
  content: "\e181\fe02"; }

.fad.fa-save-times:before, .fa-duotone.fa-save-times:before {
  content: "\e181\fe01"; }

.fad.fa-save-times:after, .fa-duotone.fa-save-times:after {
  content: "\e181\fe02"; }

.fad.fa-floppy-disk-pen:before, .fa-duotone.fa-floppy-disk-pen:before {
  content: "\e182\fe01"; }

.fad.fa-floppy-disk-pen:after, .fa-duotone.fa-floppy-disk-pen:after {
  content: "\e182\fe02"; }

.fad.fa-floppy-disks:before, .fa-duotone.fa-floppy-disks:before {
  content: "\e183\fe01"; }

.fad.fa-floppy-disks:after, .fa-duotone.fa-floppy-disks:after {
  content: "\e183\fe02"; }

.fad.fa-florin-sign:before, .fa-duotone.fa-florin-sign:before {
  content: "\e184\fe01"; }

.fad.fa-florin-sign:after, .fa-duotone.fa-florin-sign:after {
  content: "\e184\fe02"; }

.fad.fa-flower:before, .fa-duotone.fa-flower:before {
  content: "\273f\fe01"; }

.fad.fa-flower:after, .fa-duotone.fa-flower:after {
  content: "\273f\fe02"; }

.fad.fa-flower-daffodil:before, .fa-duotone.fa-flower-daffodil:before {
  content: "\2698\fe01"; }

.fad.fa-flower-daffodil:after, .fa-duotone.fa-flower-daffodil:after {
  content: "\2698\fe02"; }

.fad.fa-flower-tulip:before, .fa-duotone.fa-flower-tulip:before {
  content: "\f801"; }

.fad.fa-flower-tulip:after, .fa-duotone.fa-flower-tulip:after {
  content: "\10f801"; }

.fad.fa-flute:before, .fa-duotone.fa-flute:before {
  content: "\f8b9\fe01"; }

.fad.fa-flute:after, .fa-duotone.fa-flute:after {
  content: "\f8b9\fe02"; }

.fad.fa-flux-capacitor:before, .fa-duotone.fa-flux-capacitor:before {
  content: "\f8ba\fe01"; }

.fad.fa-flux-capacitor:after, .fa-duotone.fa-flux-capacitor:after {
  content: "\f8ba\fe02"; }

.fad.fa-flying-disc:before, .fa-duotone.fa-flying-disc:before {
  content: "\e3a9\fe01"; }

.fad.fa-flying-disc:after, .fa-duotone.fa-flying-disc:after {
  content: "\e3a9\fe02"; }

.fad.fa-folder:before, .fa-duotone.fa-folder:before {
  content: "\f07b"; }

.fad.fa-folder:after, .fa-duotone.fa-folder:after {
  content: "\10f07b"; }

.fad.fa-folder-arrow-down:before, .fa-duotone.fa-folder-arrow-down:before {
  content: "\e053\fe01"; }

.fad.fa-folder-arrow-down:after, .fa-duotone.fa-folder-arrow-down:after {
  content: "\e053\fe02"; }

.fad.fa-folder-download:before, .fa-duotone.fa-folder-download:before {
  content: "\e053\fe01"; }

.fad.fa-folder-download:after, .fa-duotone.fa-folder-download:after {
  content: "\e053\fe02"; }

.fad.fa-folder-arrow-up:before, .fa-duotone.fa-folder-arrow-up:before {
  content: "\e054\fe01"; }

.fad.fa-folder-arrow-up:after, .fa-duotone.fa-folder-arrow-up:after {
  content: "\e054\fe02"; }

.fad.fa-folder-upload:before, .fa-duotone.fa-folder-upload:before {
  content: "\e054\fe01"; }

.fad.fa-folder-upload:after, .fa-duotone.fa-folder-upload:after {
  content: "\e054\fe02"; }

.fad.fa-folder-blank:before, .fa-duotone.fa-folder-blank:before {
  content: "\e185\fe01"; }

.fad.fa-folder-blank:after, .fa-duotone.fa-folder-blank:after {
  content: "\e185\fe02"; }

.fad.fa-folder-bookmark:before, .fa-duotone.fa-folder-bookmark:before {
  content: "\e186\fe01"; }

.fad.fa-folder-bookmark:after, .fa-duotone.fa-folder-bookmark:after {
  content: "\e186\fe02"; }

.fad.fa-folder-gear:before, .fa-duotone.fa-folder-gear:before {
  content: "\e187\fe01"; }

.fad.fa-folder-gear:after, .fa-duotone.fa-folder-gear:after {
  content: "\e187\fe02"; }

.fad.fa-folder-cog:before, .fa-duotone.fa-folder-cog:before {
  content: "\e187\fe01"; }

.fad.fa-folder-cog:after, .fa-duotone.fa-folder-cog:after {
  content: "\e187\fe02"; }

.fad.fa-folder-grid:before, .fa-duotone.fa-folder-grid:before {
  content: "\e188\fe01"; }

.fad.fa-folder-grid:after, .fa-duotone.fa-folder-grid:after {
  content: "\e188\fe02"; }

.fad.fa-folder-heart:before, .fa-duotone.fa-folder-heart:before {
  content: "\e189\fe01"; }

.fad.fa-folder-heart:after, .fa-duotone.fa-folder-heart:after {
  content: "\e189\fe02"; }

.fad.fa-folder-image:before, .fa-duotone.fa-folder-image:before {
  content: "\e18a\fe01"; }

.fad.fa-folder-image:after, .fa-duotone.fa-folder-image:after {
  content: "\e18a\fe02"; }

.fad.fa-folder-magnifying-glass:before, .fa-duotone.fa-folder-magnifying-glass:before {
  content: "\e18b\fe01"; }

.fad.fa-folder-magnifying-glass:after, .fa-duotone.fa-folder-magnifying-glass:after {
  content: "\e18b\fe02"; }

.fad.fa-folder-search:before, .fa-duotone.fa-folder-search:before {
  content: "\e18b\fe01"; }

.fad.fa-folder-search:after, .fa-duotone.fa-folder-search:after {
  content: "\e18b\fe02"; }

.fad.fa-folder-medical:before, .fa-duotone.fa-folder-medical:before {
  content: "\e18c\fe01"; }

.fad.fa-folder-medical:after, .fa-duotone.fa-folder-medical:after {
  content: "\e18c\fe02"; }

.fad.fa-folder-minus:before, .fa-duotone.fa-folder-minus:before {
  content: "\f65d\fe01"; }

.fad.fa-folder-minus:after, .fa-duotone.fa-folder-minus:after {
  content: "\f65d\fe02"; }

.fad.fa-folder-music:before, .fa-duotone.fa-folder-music:before {
  content: "\e18d\fe01"; }

.fad.fa-folder-music:after, .fa-duotone.fa-folder-music:after {
  content: "\e18d\fe02"; }

.fad.fa-folder-open:before, .fa-duotone.fa-folder-open:before {
  content: "\f07c"; }

.fad.fa-folder-open:after, .fa-duotone.fa-folder-open:after {
  content: "\10f07c"; }

.fad.fa-folder-plus:before, .fa-duotone.fa-folder-plus:before {
  content: "\f65e\fe01"; }

.fad.fa-folder-plus:after, .fa-duotone.fa-folder-plus:after {
  content: "\f65e\fe02"; }

.fad.fa-folder-tree:before, .fa-duotone.fa-folder-tree:before {
  content: "\f802\fe01"; }

.fad.fa-folder-tree:after, .fa-duotone.fa-folder-tree:after {
  content: "\f802\fe02"; }

.fad.fa-folder-user:before, .fa-duotone.fa-folder-user:before {
  content: "\e18e\fe01"; }

.fad.fa-folder-user:after, .fa-duotone.fa-folder-user:after {
  content: "\e18e\fe02"; }

.fad.fa-folder-xmark:before, .fa-duotone.fa-folder-xmark:before {
  content: "\f65f\fe01"; }

.fad.fa-folder-xmark:after, .fa-duotone.fa-folder-xmark:after {
  content: "\f65f\fe02"; }

.fad.fa-folder-times:before, .fa-duotone.fa-folder-times:before {
  content: "\f65f\fe01"; }

.fad.fa-folder-times:after, .fa-duotone.fa-folder-times:after {
  content: "\f65f\fe02"; }

.fad.fa-folders:before, .fa-duotone.fa-folders:before {
  content: "\f660\fe01"; }

.fad.fa-folders:after, .fa-duotone.fa-folders:after {
  content: "\f660\fe02"; }

.fad.fa-font:before, .fa-duotone.fa-font:before {
  content: "\f031\fe01"; }

.fad.fa-font:after, .fa-duotone.fa-font:after {
  content: "\f031\fe02"; }

.fad.fa-font-awesome:before, .fa-duotone.fa-font-awesome:before {
  content: "\f2b4\fe01"; }

.fad.fa-font-awesome:after, .fa-duotone.fa-font-awesome:after {
  content: "\f2b4\fe02"; }

.fad.fa-font-awesome-flag:before, .fa-duotone.fa-font-awesome-flag:before {
  content: "\f2b4\fe01"; }

.fad.fa-font-awesome-flag:after, .fa-duotone.fa-font-awesome-flag:after {
  content: "\f2b4\fe02"; }

.fad.fa-font-awesome-logo-full:before, .fa-duotone.fa-font-awesome-logo-full:before {
  content: "\f2b4\fe01"; }

.fad.fa-font-awesome-logo-full:after, .fa-duotone.fa-font-awesome-logo-full:after {
  content: "\f2b4\fe02"; }

.fad.fa-font-case:before, .fa-duotone.fa-font-case:before {
  content: "\f866\fe01"; }

.fad.fa-font-case:after, .fa-duotone.fa-font-case:after {
  content: "\f866\fe02"; }

.fad.fa-football:before, .fa-duotone.fa-football:before {
  content: "\f44e"; }

.fad.fa-football:after, .fa-duotone.fa-football:after {
  content: "\10f44e"; }

.fad.fa-football-ball:before, .fa-duotone.fa-football-ball:before {
  content: "\f44e"; }

.fad.fa-football-ball:after, .fa-duotone.fa-football-ball:after {
  content: "\10f44e"; }

.fad.fa-football-helmet:before, .fa-duotone.fa-football-helmet:before {
  content: "\f44f\fe01"; }

.fad.fa-football-helmet:after, .fa-duotone.fa-football-helmet:after {
  content: "\f44f\fe02"; }

.fad.fa-fork:before, .fa-duotone.fa-fork:before {
  content: "\f2e3\fe01"; }

.fad.fa-fork:after, .fa-duotone.fa-fork:after {
  content: "\f2e3\fe02"; }

.fad.fa-utensil-fork:before, .fa-duotone.fa-utensil-fork:before {
  content: "\f2e3\fe01"; }

.fad.fa-utensil-fork:after, .fa-duotone.fa-utensil-fork:after {
  content: "\f2e3\fe02"; }

.fad.fa-fork-knife:before, .fa-duotone.fa-fork-knife:before {
  content: "\f2e6\fe01"; }

.fad.fa-fork-knife:after, .fa-duotone.fa-fork-knife:after {
  content: "\f2e6\fe02"; }

.fad.fa-utensils-alt:before, .fa-duotone.fa-utensils-alt:before {
  content: "\f2e6\fe01"; }

.fad.fa-utensils-alt:after, .fa-duotone.fa-utensils-alt:after {
  content: "\f2e6\fe02"; }

.fad.fa-forklift:before, .fa-duotone.fa-forklift:before {
  content: "\f47a\fe01"; }

.fad.fa-forklift:after, .fa-duotone.fa-forklift:after {
  content: "\f47a\fe02"; }

.fad.fa-forward:before, .fa-duotone.fa-forward:before {
  content: "\23e9\fe01"; }

.fad.fa-forward:after, .fa-duotone.fa-forward:after {
  content: "\23e9\fe02"; }

.fad.fa-forward-fast:before, .fa-duotone.fa-forward-fast:before {
  content: "\23ed\fe01"; }

.fad.fa-forward-fast:after, .fa-duotone.fa-forward-fast:after {
  content: "\23ed\fe02"; }

.fad.fa-fast-forward:before, .fa-duotone.fa-fast-forward:before {
  content: "\23ed\fe01"; }

.fad.fa-fast-forward:after, .fa-duotone.fa-fast-forward:after {
  content: "\23ed\fe02"; }

.fad.fa-forward-step:before, .fa-duotone.fa-forward-step:before {
  content: "\f051\fe01"; }

.fad.fa-forward-step:after, .fa-duotone.fa-forward-step:after {
  content: "\f051\fe02"; }

.fad.fa-step-forward:before, .fa-duotone.fa-step-forward:before {
  content: "\f051\fe01"; }

.fad.fa-step-forward:after, .fa-duotone.fa-step-forward:after {
  content: "\f051\fe02"; }

.fad.fa-franc-sign:before, .fa-duotone.fa-franc-sign:before {
  content: "\e18f\fe01"; }

.fad.fa-franc-sign:after, .fa-duotone.fa-franc-sign:after {
  content: "\e18f\fe02"; }

.fad.fa-french-fries:before, .fa-duotone.fa-french-fries:before {
  content: "\f803"; }

.fad.fa-french-fries:after, .fa-duotone.fa-french-fries:after {
  content: "\10f803"; }

.fad.fa-frog:before, .fa-duotone.fa-frog:before {
  content: "\f52e\fe01"; }

.fad.fa-frog:after, .fa-duotone.fa-frog:after {
  content: "\f52e\fe02"; }

.fad.fa-function:before, .fa-duotone.fa-function:before {
  content: "\f661\fe01"; }

.fad.fa-function:after, .fa-duotone.fa-function:after {
  content: "\f661\fe02"; }

.fad.fa-futbol:before, .fa-duotone.fa-futbol:before {
  content: "\26bd\fe01"; }

.fad.fa-futbol:after, .fa-duotone.fa-futbol:after {
  content: "\26bd\fe02"; }

.fad.fa-futbol-ball:before, .fa-duotone.fa-futbol-ball:before {
  content: "\26bd\fe01"; }

.fad.fa-futbol-ball:after, .fa-duotone.fa-futbol-ball:after {
  content: "\26bd\fe02"; }

.fad.fa-soccer-ball:before, .fa-duotone.fa-soccer-ball:before {
  content: "\26bd\fe01"; }

.fad.fa-soccer-ball:after, .fa-duotone.fa-soccer-ball:after {
  content: "\26bd\fe02"; }

.fad.fa-g:before, .fa-duotone.fa-g:before {
  content: "\47\fe01"; }

.fad.fa-g:after, .fa-duotone.fa-g:after {
  content: "\47\fe02"; }

.fad.fa-galaxy:before, .fa-duotone.fa-galaxy:before {
  content: "\e008\fe01"; }

.fad.fa-galaxy:after, .fa-duotone.fa-galaxy:after {
  content: "\e008\fe02"; }

.fad.fa-gallery-thumbnails:before, .fa-duotone.fa-gallery-thumbnails:before {
  content: "\e3aa\fe01"; }

.fad.fa-gallery-thumbnails:after, .fa-duotone.fa-gallery-thumbnails:after {
  content: "\e3aa\fe02"; }

.fad.fa-game-board:before, .fa-duotone.fa-game-board:before {
  content: "\f867\fe01"; }

.fad.fa-game-board:after, .fa-duotone.fa-game-board:after {
  content: "\f867\fe02"; }

.fad.fa-game-board-simple:before, .fa-duotone.fa-game-board-simple:before {
  content: "\f868\fe01"; }

.fad.fa-game-board-simple:after, .fa-duotone.fa-game-board-simple:after {
  content: "\f868\fe02"; }

.fad.fa-game-board-alt:before, .fa-duotone.fa-game-board-alt:before {
  content: "\f868\fe01"; }

.fad.fa-game-board-alt:after, .fa-duotone.fa-game-board-alt:after {
  content: "\f868\fe02"; }

.fad.fa-game-console-handheld:before, .fa-duotone.fa-game-console-handheld:before {
  content: "\f8bb\fe01"; }

.fad.fa-game-console-handheld:after, .fa-duotone.fa-game-console-handheld:after {
  content: "\f8bb\fe02"; }

.fad.fa-gamepad:before, .fa-duotone.fa-gamepad:before {
  content: "\f11b\fe01"; }

.fad.fa-gamepad:after, .fa-duotone.fa-gamepad:after {
  content: "\f11b\fe02"; }

.fad.fa-gamepad-modern:before, .fa-duotone.fa-gamepad-modern:before {
  content: "\f8bc"; }

.fad.fa-gamepad-modern:after, .fa-duotone.fa-gamepad-modern:after {
  content: "\10f8bc"; }

.fad.fa-gamepad-alt:before, .fa-duotone.fa-gamepad-alt:before {
  content: "\f8bc"; }

.fad.fa-gamepad-alt:after, .fa-duotone.fa-gamepad-alt:after {
  content: "\10f8bc"; }

.fad.fa-garage:before, .fa-duotone.fa-garage:before {
  content: "\e009\fe01"; }

.fad.fa-garage:after, .fa-duotone.fa-garage:after {
  content: "\e009\fe02"; }

.fad.fa-garage-car:before, .fa-duotone.fa-garage-car:before {
  content: "\e00a\fe01"; }

.fad.fa-garage-car:after, .fa-duotone.fa-garage-car:after {
  content: "\e00a\fe02"; }

.fad.fa-garage-open:before, .fa-duotone.fa-garage-open:before {
  content: "\e00b\fe01"; }

.fad.fa-garage-open:after, .fa-duotone.fa-garage-open:after {
  content: "\e00b\fe02"; }

.fad.fa-gas-pump:before, .fa-duotone.fa-gas-pump:before {
  content: "\26fd\fe01"; }

.fad.fa-gas-pump:after, .fa-duotone.fa-gas-pump:after {
  content: "\26fd\fe02"; }

.fad.fa-gas-pump-slash:before, .fa-duotone.fa-gas-pump-slash:before {
  content: "\f5f4\fe01"; }

.fad.fa-gas-pump-slash:after, .fa-duotone.fa-gas-pump-slash:after {
  content: "\f5f4\fe02"; }

.fad.fa-gauge:before, .fa-duotone.fa-gauge:before {
  content: "\f625\fe01"; }

.fad.fa-gauge:after, .fa-duotone.fa-gauge:after {
  content: "\f625\fe02"; }

.fad.fa-dashboard:before, .fa-duotone.fa-dashboard:before {
  content: "\f625\fe01"; }

.fad.fa-dashboard:after, .fa-duotone.fa-dashboard:after {
  content: "\f625\fe02"; }

.fad.fa-gauge-high:before, .fa-duotone.fa-gauge-high:before {
  content: "\f625\fe01"; }

.fad.fa-gauge-high:after, .fa-duotone.fa-gauge-high:after {
  content: "\f625\fe02"; }

.fad.fa-tachometer-alt:before, .fa-duotone.fa-tachometer-alt:before {
  content: "\f625\fe01"; }

.fad.fa-tachometer-alt:after, .fa-duotone.fa-tachometer-alt:after {
  content: "\f625\fe02"; }

.fad.fa-tachometer-alt-fast:before, .fa-duotone.fa-tachometer-alt-fast:before {
  content: "\f625\fe01"; }

.fad.fa-tachometer-alt-fast:after, .fa-duotone.fa-tachometer-alt-fast:after {
  content: "\f625\fe02"; }

.fad.fa-gauge-low:before, .fa-duotone.fa-gauge-low:before {
  content: "\f627\fe01"; }

.fad.fa-gauge-low:after, .fa-duotone.fa-gauge-low:after {
  content: "\f627\fe02"; }

.fad.fa-tachometer-alt-slow:before, .fa-duotone.fa-tachometer-alt-slow:before {
  content: "\f627\fe01"; }

.fad.fa-tachometer-alt-slow:after, .fa-duotone.fa-tachometer-alt-slow:after {
  content: "\f627\fe02"; }

.fad.fa-gauge-max:before, .fa-duotone.fa-gauge-max:before {
  content: "\f626\fe01"; }

.fad.fa-gauge-max:after, .fa-duotone.fa-gauge-max:after {
  content: "\f626\fe02"; }

.fad.fa-tachometer-alt-fastest:before, .fa-duotone.fa-tachometer-alt-fastest:before {
  content: "\f626\fe01"; }

.fad.fa-tachometer-alt-fastest:after, .fa-duotone.fa-tachometer-alt-fastest:after {
  content: "\f626\fe02"; }

.fad.fa-gauge-med:before, .fa-duotone.fa-gauge-med:before {
  content: "\f624\fe01"; }

.fad.fa-gauge-med:after, .fa-duotone.fa-gauge-med:after {
  content: "\f624\fe02"; }

.fad.fa-tachometer-alt-average:before, .fa-duotone.fa-tachometer-alt-average:before {
  content: "\f624\fe01"; }

.fad.fa-tachometer-alt-average:after, .fa-duotone.fa-tachometer-alt-average:after {
  content: "\f624\fe02"; }

.fad.fa-gauge-min:before, .fa-duotone.fa-gauge-min:before {
  content: "\f628\fe01"; }

.fad.fa-gauge-min:after, .fa-duotone.fa-gauge-min:after {
  content: "\f628\fe02"; }

.fad.fa-tachometer-alt-slowest:before, .fa-duotone.fa-tachometer-alt-slowest:before {
  content: "\f628\fe01"; }

.fad.fa-tachometer-alt-slowest:after, .fa-duotone.fa-tachometer-alt-slowest:after {
  content: "\f628\fe02"; }

.fad.fa-gauge-simple:before, .fa-duotone.fa-gauge-simple:before {
  content: "\f62a\fe01"; }

.fad.fa-gauge-simple:after, .fa-duotone.fa-gauge-simple:after {
  content: "\f62a\fe02"; }

.fad.fa-gauge-simple-high:before, .fa-duotone.fa-gauge-simple-high:before {
  content: "\f62a\fe01"; }

.fad.fa-gauge-simple-high:after, .fa-duotone.fa-gauge-simple-high:after {
  content: "\f62a\fe02"; }

.fad.fa-tachometer:before, .fa-duotone.fa-tachometer:before {
  content: "\f62a\fe01"; }

.fad.fa-tachometer:after, .fa-duotone.fa-tachometer:after {
  content: "\f62a\fe02"; }

.fad.fa-gauge-simple-low:before, .fa-duotone.fa-gauge-simple-low:before {
  content: "\f62c\fe01"; }

.fad.fa-gauge-simple-low:after, .fa-duotone.fa-gauge-simple-low:after {
  content: "\f62c\fe02"; }

.fad.fa-tachometer-slow:before, .fa-duotone.fa-tachometer-slow:before {
  content: "\f62c\fe01"; }

.fad.fa-tachometer-slow:after, .fa-duotone.fa-tachometer-slow:after {
  content: "\f62c\fe02"; }

.fad.fa-gauge-simple-max:before, .fa-duotone.fa-gauge-simple-max:before {
  content: "\f62b\fe01"; }

.fad.fa-gauge-simple-max:after, .fa-duotone.fa-gauge-simple-max:after {
  content: "\f62b\fe02"; }

.fad.fa-tachometer-fastest:before, .fa-duotone.fa-tachometer-fastest:before {
  content: "\f62b\fe01"; }

.fad.fa-tachometer-fastest:after, .fa-duotone.fa-tachometer-fastest:after {
  content: "\f62b\fe02"; }

.fad.fa-gauge-simple-med:before, .fa-duotone.fa-gauge-simple-med:before {
  content: "\f629\fe01"; }

.fad.fa-gauge-simple-med:after, .fa-duotone.fa-gauge-simple-med:after {
  content: "\f629\fe02"; }

.fad.fa-tachometer-average:before, .fa-duotone.fa-tachometer-average:before {
  content: "\f629\fe01"; }

.fad.fa-tachometer-average:after, .fa-duotone.fa-tachometer-average:after {
  content: "\f629\fe02"; }

.fad.fa-gauge-simple-min:before, .fa-duotone.fa-gauge-simple-min:before {
  content: "\f62d\fe01"; }

.fad.fa-gauge-simple-min:after, .fa-duotone.fa-gauge-simple-min:after {
  content: "\f62d\fe02"; }

.fad.fa-tachometer-slowest:before, .fa-duotone.fa-tachometer-slowest:before {
  content: "\f62d\fe01"; }

.fad.fa-tachometer-slowest:after, .fa-duotone.fa-tachometer-slowest:after {
  content: "\f62d\fe02"; }

.fad.fa-gavel:before, .fa-duotone.fa-gavel:before {
  content: "\f0e3\fe01"; }

.fad.fa-gavel:after, .fa-duotone.fa-gavel:after {
  content: "\f0e3\fe02"; }

.fad.fa-legal:before, .fa-duotone.fa-legal:before {
  content: "\f0e3\fe01"; }

.fad.fa-legal:after, .fa-duotone.fa-legal:after {
  content: "\f0e3\fe02"; }

.fad.fa-gear:before, .fa-duotone.fa-gear:before {
  content: "\2699\fe01"; }

.fad.fa-gear:after, .fa-duotone.fa-gear:after {
  content: "\2699\fe02"; }

.fad.fa-cog:before, .fa-duotone.fa-cog:before {
  content: "\2699\fe01"; }

.fad.fa-cog:after, .fa-duotone.fa-cog:after {
  content: "\2699\fe02"; }

.fad.fa-gears:before, .fa-duotone.fa-gears:before {
  content: "\f085\fe01"; }

.fad.fa-gears:after, .fa-duotone.fa-gears:after {
  content: "\f085\fe02"; }

.fad.fa-cogs:before, .fa-duotone.fa-cogs:before {
  content: "\f085\fe01"; }

.fad.fa-cogs:after, .fa-duotone.fa-cogs:after {
  content: "\f085\fe02"; }

.fad.fa-gem:before, .fa-duotone.fa-gem:before {
  content: "\f3a5"; }

.fad.fa-gem:after, .fa-duotone.fa-gem:after {
  content: "\10f3a5"; }

.fad.fa-genderless:before, .fa-duotone.fa-genderless:before {
  content: "\f22d\fe01"; }

.fad.fa-genderless:after, .fa-duotone.fa-genderless:after {
  content: "\f22d\fe02"; }

.fad.fa-ghost:before, .fa-duotone.fa-ghost:before {
  content: "\f6e2"; }

.fad.fa-ghost:after, .fa-duotone.fa-ghost:after {
  content: "\10f6e2"; }

.fad.fa-gif:before, .fa-duotone.fa-gif:before {
  content: "\e190\fe01"; }

.fad.fa-gif:after, .fa-duotone.fa-gif:after {
  content: "\e190\fe02"; }

.fad.fa-gift:before, .fa-duotone.fa-gift:before {
  content: "\f06b"; }

.fad.fa-gift:after, .fa-duotone.fa-gift:after {
  content: "\10f06b"; }

.fad.fa-gift-card:before, .fa-duotone.fa-gift-card:before {
  content: "\f663\fe01"; }

.fad.fa-gift-card:after, .fa-duotone.fa-gift-card:after {
  content: "\f663\fe02"; }

.fad.fa-gifts:before, .fa-duotone.fa-gifts:before {
  content: "\f79c\fe01"; }

.fad.fa-gifts:after, .fa-duotone.fa-gifts:after {
  content: "\f79c\fe02"; }

.fad.fa-gingerbread-man:before, .fa-duotone.fa-gingerbread-man:before {
  content: "\f79d\fe01"; }

.fad.fa-gingerbread-man:after, .fa-duotone.fa-gingerbread-man:after {
  content: "\f79d\fe02"; }

.fad.fa-glass:before, .fa-duotone.fa-glass:before {
  content: "\f804"; }

.fad.fa-glass:after, .fa-duotone.fa-glass:after {
  content: "\10f804"; }

.fad.fa-glass-citrus:before, .fa-duotone.fa-glass-citrus:before {
  content: "\f869\fe01"; }

.fad.fa-glass-citrus:after, .fa-duotone.fa-glass-citrus:after {
  content: "\f869\fe02"; }

.fad.fa-glass-empty:before, .fa-duotone.fa-glass-empty:before {
  content: "\e191\fe01"; }

.fad.fa-glass-empty:after, .fa-duotone.fa-glass-empty:after {
  content: "\e191\fe02"; }

.fad.fa-glass-half:before, .fa-duotone.fa-glass-half:before {
  content: "\e192\fe01"; }

.fad.fa-glass-half:after, .fa-duotone.fa-glass-half:after {
  content: "\e192\fe02"; }

.fad.fa-glass-half-empty:before, .fa-duotone.fa-glass-half-empty:before {
  content: "\e192\fe01"; }

.fad.fa-glass-half-empty:after, .fa-duotone.fa-glass-half-empty:after {
  content: "\e192\fe02"; }

.fad.fa-glass-half-full:before, .fa-duotone.fa-glass-half-full:before {
  content: "\e192\fe01"; }

.fad.fa-glass-half-full:after, .fa-duotone.fa-glass-half-full:after {
  content: "\e192\fe02"; }

.fad.fa-glasses:before, .fa-duotone.fa-glasses:before {
  content: "\f530\fe01"; }

.fad.fa-glasses:after, .fa-duotone.fa-glasses:after {
  content: "\f530\fe02"; }

.fad.fa-glasses-round:before, .fa-duotone.fa-glasses-round:before {
  content: "\f5f5"; }

.fad.fa-glasses-round:after, .fa-duotone.fa-glasses-round:after {
  content: "\10f5f5"; }

.fad.fa-glasses-alt:before, .fa-duotone.fa-glasses-alt:before {
  content: "\f5f5"; }

.fad.fa-glasses-alt:after, .fa-duotone.fa-glasses-alt:after {
  content: "\10f5f5"; }

.fad.fa-globe:before, .fa-duotone.fa-globe:before {
  content: "\f0ac"; }

.fad.fa-globe:after, .fa-duotone.fa-globe:after {
  content: "\10f0ac"; }

.fad.fa-globe-snow:before, .fa-duotone.fa-globe-snow:before {
  content: "\f7a3\fe01"; }

.fad.fa-globe-snow:after, .fa-duotone.fa-globe-snow:after {
  content: "\f7a3\fe02"; }

.fad.fa-globe-stand:before, .fa-duotone.fa-globe-stand:before {
  content: "\f5f6\fe01"; }

.fad.fa-globe-stand:after, .fa-duotone.fa-globe-stand:after {
  content: "\f5f6\fe02"; }

.fad.fa-goal-net:before, .fa-duotone.fa-goal-net:before {
  content: "\e3ab\fe01"; }

.fad.fa-goal-net:after, .fa-duotone.fa-goal-net:after {
  content: "\e3ab\fe02"; }

.fad.fa-golf-ball-tee:before, .fa-duotone.fa-golf-ball-tee:before {
  content: "\f450\fe01"; }

.fad.fa-golf-ball-tee:after, .fa-duotone.fa-golf-ball-tee:after {
  content: "\f450\fe02"; }

.fad.fa-golf-ball:before, .fa-duotone.fa-golf-ball:before {
  content: "\f450\fe01"; }

.fad.fa-golf-ball:after, .fa-duotone.fa-golf-ball:after {
  content: "\f450\fe02"; }

.fad.fa-golf-club:before, .fa-duotone.fa-golf-club:before {
  content: "\f451\fe01"; }

.fad.fa-golf-club:after, .fa-duotone.fa-golf-club:after {
  content: "\f451\fe02"; }

.fad.fa-golf-flag-hole:before, .fa-duotone.fa-golf-flag-hole:before {
  content: "\e3ac\fe01"; }

.fad.fa-golf-flag-hole:after, .fa-duotone.fa-golf-flag-hole:after {
  content: "\e3ac\fe02"; }

.fad.fa-gopuram:before, .fa-duotone.fa-gopuram:before {
  content: "\f664\fe01"; }

.fad.fa-gopuram:after, .fa-duotone.fa-gopuram:after {
  content: "\f664\fe02"; }

.fad.fa-graduation-cap:before, .fa-duotone.fa-graduation-cap:before {
  content: "\f19d"; }

.fad.fa-graduation-cap:after, .fa-duotone.fa-graduation-cap:after {
  content: "\10f19d"; }

.fad.fa-mortar-board:before, .fa-duotone.fa-mortar-board:before {
  content: "\f19d"; }

.fad.fa-mortar-board:after, .fa-duotone.fa-mortar-board:after {
  content: "\10f19d"; }

.fad.fa-gramophone:before, .fa-duotone.fa-gramophone:before {
  content: "\f8bd\fe01"; }

.fad.fa-gramophone:after, .fa-duotone.fa-gramophone:after {
  content: "\f8bd\fe02"; }

.fad.fa-grapes:before, .fa-duotone.fa-grapes:before {
  content: "\e306\fe01"; }

.fad.fa-grapes:after, .fa-duotone.fa-grapes:after {
  content: "\e306\fe02"; }

.fad.fa-grate:before, .fa-duotone.fa-grate:before {
  content: "\e193\fe01"; }

.fad.fa-grate:after, .fa-duotone.fa-grate:after {
  content: "\e193\fe02"; }

.fad.fa-grate-droplet:before, .fa-duotone.fa-grate-droplet:before {
  content: "\e194\fe01"; }

.fad.fa-grate-droplet:after, .fa-duotone.fa-grate-droplet:after {
  content: "\e194\fe02"; }

.fad.fa-greater-than:before, .fa-duotone.fa-greater-than:before {
  content: "\3e\fe01"; }

.fad.fa-greater-than:after, .fa-duotone.fa-greater-than:after {
  content: "\3e\fe02"; }

.fad.fa-greater-than-equal:before, .fa-duotone.fa-greater-than-equal:before {
  content: "\f532\fe01"; }

.fad.fa-greater-than-equal:after, .fa-duotone.fa-greater-than-equal:after {
  content: "\f532\fe02"; }

.fad.fa-grid:before, .fa-duotone.fa-grid:before {
  content: "\e195\fe01"; }

.fad.fa-grid:after, .fa-duotone.fa-grid:after {
  content: "\e195\fe02"; }

.fad.fa-grid-3:before, .fa-duotone.fa-grid-3:before {
  content: "\e195\fe01"; }

.fad.fa-grid-3:after, .fa-duotone.fa-grid-3:after {
  content: "\e195\fe02"; }

.fad.fa-grid-2:before, .fa-duotone.fa-grid-2:before {
  content: "\e196\fe01"; }

.fad.fa-grid-2:after, .fa-duotone.fa-grid-2:after {
  content: "\e196\fe02"; }

.fad.fa-grid-2-plus:before, .fa-duotone.fa-grid-2-plus:before {
  content: "\e197\fe01"; }

.fad.fa-grid-2-plus:after, .fa-duotone.fa-grid-2-plus:after {
  content: "\e197\fe02"; }

.fad.fa-grid-4:before, .fa-duotone.fa-grid-4:before {
  content: "\e198\fe01"; }

.fad.fa-grid-4:after, .fa-duotone.fa-grid-4:after {
  content: "\e198\fe02"; }

.fad.fa-grid-5:before, .fa-duotone.fa-grid-5:before {
  content: "\e199\fe01"; }

.fad.fa-grid-5:after, .fa-duotone.fa-grid-5:after {
  content: "\e199\fe02"; }

.fad.fa-grid-dividers:before, .fa-duotone.fa-grid-dividers:before {
  content: "\e3ad\fe01"; }

.fad.fa-grid-dividers:after, .fa-duotone.fa-grid-dividers:after {
  content: "\e3ad\fe02"; }

.fad.fa-grid-horizontal:before, .fa-duotone.fa-grid-horizontal:before {
  content: "\e307\fe01"; }

.fad.fa-grid-horizontal:after, .fa-duotone.fa-grid-horizontal:after {
  content: "\e307\fe02"; }

.fad.fa-grip:before, .fa-duotone.fa-grip:before {
  content: "\f58d\fe01"; }

.fad.fa-grip:after, .fa-duotone.fa-grip:after {
  content: "\f58d\fe02"; }

.fad.fa-grip-horizontal:before, .fa-duotone.fa-grip-horizontal:before {
  content: "\f58d\fe01"; }

.fad.fa-grip-horizontal:after, .fa-duotone.fa-grip-horizontal:after {
  content: "\f58d\fe02"; }

.fad.fa-grip-lines:before, .fa-duotone.fa-grip-lines:before {
  content: "\f7a4\fe01"; }

.fad.fa-grip-lines:after, .fa-duotone.fa-grip-lines:after {
  content: "\f7a4\fe02"; }

.fad.fa-grip-lines-vertical:before, .fa-duotone.fa-grip-lines-vertical:before {
  content: "\f7a5\fe01"; }

.fad.fa-grip-lines-vertical:after, .fa-duotone.fa-grip-lines-vertical:after {
  content: "\f7a5\fe02"; }

.fad.fa-grip-vertical:before, .fa-duotone.fa-grip-vertical:before {
  content: "\f58e\fe01"; }

.fad.fa-grip-vertical:after, .fa-duotone.fa-grip-vertical:after {
  content: "\f58e\fe02"; }

.fad.fa-guarani-sign:before, .fa-duotone.fa-guarani-sign:before {
  content: "\e19a\fe01"; }

.fad.fa-guarani-sign:after, .fa-duotone.fa-guarani-sign:after {
  content: "\e19a\fe02"; }

.fad.fa-guitar:before, .fa-duotone.fa-guitar:before {
  content: "\f7a6\fe01"; }

.fad.fa-guitar:after, .fa-duotone.fa-guitar:after {
  content: "\f7a6\fe02"; }

.fad.fa-guitar-electric:before, .fa-duotone.fa-guitar-electric:before {
  content: "\f8be"; }

.fad.fa-guitar-electric:after, .fa-duotone.fa-guitar-electric:after {
  content: "\10f8be"; }

.fad.fa-guitars:before, .fa-duotone.fa-guitars:before {
  content: "\f8bf\fe01"; }

.fad.fa-guitars:after, .fa-duotone.fa-guitars:after {
  content: "\f8bf\fe02"; }

.fad.fa-gun:before, .fa-duotone.fa-gun:before {
  content: "\e19b\fe01"; }

.fad.fa-gun:after, .fa-duotone.fa-gun:after {
  content: "\e19b\fe02"; }

.fad.fa-gun-slash:before, .fa-duotone.fa-gun-slash:before {
  content: "\e19c\fe01"; }

.fad.fa-gun-slash:after, .fa-duotone.fa-gun-slash:after {
  content: "\e19c\fe02"; }

.fad.fa-gun-squirt:before, .fa-duotone.fa-gun-squirt:before {
  content: "\e19d\fe01"; }

.fad.fa-gun-squirt:after, .fa-duotone.fa-gun-squirt:after {
  content: "\e19d\fe02"; }

.fad.fa-h:before, .fa-duotone.fa-h:before {
  content: "\48\fe01"; }

.fad.fa-h:after, .fa-duotone.fa-h:after {
  content: "\48\fe02"; }

.fad.fa-h1:before, .fa-duotone.fa-h1:before {
  content: "\f313\fe01"; }

.fad.fa-h1:after, .fa-duotone.fa-h1:after {
  content: "\f313\fe02"; }

.fad.fa-h2:before, .fa-duotone.fa-h2:before {
  content: "\f314\fe01"; }

.fad.fa-h2:after, .fa-duotone.fa-h2:after {
  content: "\f314\fe02"; }

.fad.fa-h3:before, .fa-duotone.fa-h3:before {
  content: "\f315\fe01"; }

.fad.fa-h3:after, .fa-duotone.fa-h3:after {
  content: "\f315\fe02"; }

.fad.fa-h4:before, .fa-duotone.fa-h4:before {
  content: "\f86a\fe01"; }

.fad.fa-h4:after, .fa-duotone.fa-h4:after {
  content: "\f86a\fe02"; }

.fad.fa-hammer:before, .fa-duotone.fa-hammer:before {
  content: "\f6e3"; }

.fad.fa-hammer:after, .fa-duotone.fa-hammer:after {
  content: "\10f6e3"; }

.fad.fa-hammer-war:before, .fa-duotone.fa-hammer-war:before {
  content: "\f6e4\fe01"; }

.fad.fa-hammer-war:after, .fa-duotone.fa-hammer-war:after {
  content: "\f6e4\fe02"; }

.fad.fa-hamsa:before, .fa-duotone.fa-hamsa:before {
  content: "\f665\fe01"; }

.fad.fa-hamsa:after, .fa-duotone.fa-hamsa:after {
  content: "\f665\fe02"; }

.fad.fa-hand:before, .fa-duotone.fa-hand:before {
  content: "\270b\fe01"; }

.fad.fa-hand:after, .fa-duotone.fa-hand:after {
  content: "\270b\fe02"; }

.fad.fa-hand-paper:before, .fa-duotone.fa-hand-paper:before {
  content: "\270b\fe01"; }

.fad.fa-hand-paper:after, .fa-duotone.fa-hand-paper:after {
  content: "\270b\fe02"; }

.fad.fa-hand-back-fist:before, .fa-duotone.fa-hand-back-fist:before {
  content: "\f255\fe01"; }

.fad.fa-hand-back-fist:after, .fa-duotone.fa-hand-back-fist:after {
  content: "\f255\fe02"; }

.fad.fa-hand-rock:before, .fa-duotone.fa-hand-rock:before {
  content: "\f255\fe01"; }

.fad.fa-hand-rock:after, .fa-duotone.fa-hand-rock:after {
  content: "\f255\fe02"; }

.fad.fa-hand-back-point-down:before, .fa-duotone.fa-hand-back-point-down:before {
  content: "\e19e\fe01"; }

.fad.fa-hand-back-point-down:after, .fa-duotone.fa-hand-back-point-down:after {
  content: "\e19e\fe02"; }

.fad.fa-hand-back-point-left:before, .fa-duotone.fa-hand-back-point-left:before {
  content: "\e19f\fe01"; }

.fad.fa-hand-back-point-left:after, .fa-duotone.fa-hand-back-point-left:after {
  content: "\e19f\fe02"; }

.fad.fa-hand-back-point-ribbon:before, .fa-duotone.fa-hand-back-point-ribbon:before {
  content: "\e1a0\fe01"; }

.fad.fa-hand-back-point-ribbon:after, .fa-duotone.fa-hand-back-point-ribbon:after {
  content: "\e1a0\fe02"; }

.fad.fa-hand-back-point-right:before, .fa-duotone.fa-hand-back-point-right:before {
  content: "\e1a1\fe01"; }

.fad.fa-hand-back-point-right:after, .fa-duotone.fa-hand-back-point-right:after {
  content: "\e1a1\fe02"; }

.fad.fa-hand-back-point-up:before, .fa-duotone.fa-hand-back-point-up:before {
  content: "\e1a2\fe01"; }

.fad.fa-hand-back-point-up:after, .fa-duotone.fa-hand-back-point-up:after {
  content: "\e1a2\fe02"; }

.fad.fa-hand-dots:before, .fa-duotone.fa-hand-dots:before {
  content: "\f461\fe01"; }

.fad.fa-hand-dots:after, .fa-duotone.fa-hand-dots:after {
  content: "\f461\fe02"; }

.fad.fa-allergies:before, .fa-duotone.fa-allergies:before {
  content: "\f461\fe01"; }

.fad.fa-allergies:after, .fa-duotone.fa-allergies:after {
  content: "\f461\fe02"; }

.fad.fa-hand-fingers-crossed:before, .fa-duotone.fa-hand-fingers-crossed:before {
  content: "\e1a3\fe01"; }

.fad.fa-hand-fingers-crossed:after, .fa-duotone.fa-hand-fingers-crossed:after {
  content: "\e1a3\fe02"; }

.fad.fa-hand-fist:before, .fa-duotone.fa-hand-fist:before {
  content: "\270a\fe01"; }

.fad.fa-hand-fist:after, .fa-duotone.fa-hand-fist:after {
  content: "\270a\fe02"; }

.fad.fa-fist-raised:before, .fa-duotone.fa-fist-raised:before {
  content: "\270a\fe01"; }

.fad.fa-fist-raised:after, .fa-duotone.fa-fist-raised:after {
  content: "\270a\fe02"; }

.fad.fa-hand-heart:before, .fa-duotone.fa-hand-heart:before {
  content: "\f4bc\fe01"; }

.fad.fa-hand-heart:after, .fa-duotone.fa-hand-heart:after {
  content: "\f4bc\fe02"; }

.fad.fa-hand-holding:before, .fa-duotone.fa-hand-holding:before {
  content: "\f4bd\fe01"; }

.fad.fa-hand-holding:after, .fa-duotone.fa-hand-holding:after {
  content: "\f4bd\fe02"; }

.fad.fa-hand-holding-box:before, .fa-duotone.fa-hand-holding-box:before {
  content: "\f47b\fe01"; }

.fad.fa-hand-holding-box:after, .fa-duotone.fa-hand-holding-box:after {
  content: "\f47b\fe02"; }

.fad.fa-hand-holding-dollar:before, .fa-duotone.fa-hand-holding-dollar:before {
  content: "\f4c0\fe01"; }

.fad.fa-hand-holding-dollar:after, .fa-duotone.fa-hand-holding-dollar:after {
  content: "\f4c0\fe02"; }

.fad.fa-hand-holding-usd:before, .fa-duotone.fa-hand-holding-usd:before {
  content: "\f4c0\fe01"; }

.fad.fa-hand-holding-usd:after, .fa-duotone.fa-hand-holding-usd:after {
  content: "\f4c0\fe02"; }

.fad.fa-hand-holding-droplet:before, .fa-duotone.fa-hand-holding-droplet:before {
  content: "\f4c1\fe01"; }

.fad.fa-hand-holding-droplet:after, .fa-duotone.fa-hand-holding-droplet:after {
  content: "\f4c1\fe02"; }

.fad.fa-hand-holding-water:before, .fa-duotone.fa-hand-holding-water:before {
  content: "\f4c1\fe01"; }

.fad.fa-hand-holding-water:after, .fa-duotone.fa-hand-holding-water:after {
  content: "\f4c1\fe02"; }

.fad.fa-hand-holding-heart:before, .fa-duotone.fa-hand-holding-heart:before {
  content: "\f4be\fe01"; }

.fad.fa-hand-holding-heart:after, .fa-duotone.fa-hand-holding-heart:after {
  content: "\f4be\fe02"; }

.fad.fa-hand-holding-magic:before, .fa-duotone.fa-hand-holding-magic:before {
  content: "\f6e5\fe01"; }

.fad.fa-hand-holding-magic:after, .fa-duotone.fa-hand-holding-magic:after {
  content: "\f6e5\fe02"; }

.fad.fa-hand-holding-medical:before, .fa-duotone.fa-hand-holding-medical:before {
  content: "\e05c\fe01"; }

.fad.fa-hand-holding-medical:after, .fa-duotone.fa-hand-holding-medical:after {
  content: "\e05c\fe02"; }

.fad.fa-hand-holding-seedling:before, .fa-duotone.fa-hand-holding-seedling:before {
  content: "\f4bf\fe01"; }

.fad.fa-hand-holding-seedling:after, .fa-duotone.fa-hand-holding-seedling:after {
  content: "\f4bf\fe02"; }

.fad.fa-hand-holding-skull:before, .fa-duotone.fa-hand-holding-skull:before {
  content: "\e1a4\fe01"; }

.fad.fa-hand-holding-skull:after, .fa-duotone.fa-hand-holding-skull:after {
  content: "\e1a4\fe02"; }

.fad.fa-hand-horns:before, .fa-duotone.fa-hand-horns:before {
  content: "\e1a9\fe01"; }

.fad.fa-hand-horns:after, .fa-duotone.fa-hand-horns:after {
  content: "\e1a9\fe02"; }

.fad.fa-hand-lizard:before, .fa-duotone.fa-hand-lizard:before {
  content: "\f258\fe01"; }

.fad.fa-hand-lizard:after, .fa-duotone.fa-hand-lizard:after {
  content: "\f258\fe02"; }

.fad.fa-hand-love:before, .fa-duotone.fa-hand-love:before {
  content: "\e1a5\fe01"; }

.fad.fa-hand-love:after, .fa-duotone.fa-hand-love:after {
  content: "\e1a5\fe02"; }

.fad.fa-hand-middle-finger:before, .fa-duotone.fa-hand-middle-finger:before {
  content: "\f806"; }

.fad.fa-hand-middle-finger:after, .fa-duotone.fa-hand-middle-finger:after {
  content: "\10f806"; }

.fad.fa-hand-peace:before, .fa-duotone.fa-hand-peace:before {
  content: "\270c\fe01"; }

.fad.fa-hand-peace:after, .fa-duotone.fa-hand-peace:after {
  content: "\270c\fe02"; }

.fad.fa-hand-point-down:before, .fa-duotone.fa-hand-point-down:before {
  content: "\f0a7\fe01"; }

.fad.fa-hand-point-down:after, .fa-duotone.fa-hand-point-down:after {
  content: "\f0a7\fe02"; }

.fad.fa-hand-point-left:before, .fa-duotone.fa-hand-point-left:before {
  content: "\f0a5\fe01"; }

.fad.fa-hand-point-left:after, .fa-duotone.fa-hand-point-left:after {
  content: "\f0a5\fe02"; }

.fad.fa-hand-point-ribbon:before, .fa-duotone.fa-hand-point-ribbon:before {
  content: "\e1a6\fe01"; }

.fad.fa-hand-point-ribbon:after, .fa-duotone.fa-hand-point-ribbon:after {
  content: "\e1a6\fe02"; }

.fad.fa-hand-point-right:before, .fa-duotone.fa-hand-point-right:before {
  content: "\f0a4\fe01"; }

.fad.fa-hand-point-right:after, .fa-duotone.fa-hand-point-right:after {
  content: "\f0a4\fe02"; }

.fad.fa-hand-point-up:before, .fa-duotone.fa-hand-point-up:before {
  content: "\261d\fe01"; }

.fad.fa-hand-point-up:after, .fa-duotone.fa-hand-point-up:after {
  content: "\261d\fe02"; }

.fad.fa-hand-pointer:before, .fa-duotone.fa-hand-pointer:before {
  content: "\f25a\fe01"; }

.fad.fa-hand-pointer:after, .fa-duotone.fa-hand-pointer:after {
  content: "\f25a\fe02"; }

.fad.fa-hand-scissors:before, .fa-duotone.fa-hand-scissors:before {
  content: "\f257\fe01"; }

.fad.fa-hand-scissors:after, .fa-duotone.fa-hand-scissors:after {
  content: "\f257\fe02"; }

.fad.fa-hand-sparkles:before, .fa-duotone.fa-hand-sparkles:before {
  content: "\e05d\fe01"; }

.fad.fa-hand-sparkles:after, .fa-duotone.fa-hand-sparkles:after {
  content: "\e05d\fe02"; }

.fad.fa-hand-spock:before, .fa-duotone.fa-hand-spock:before {
  content: "\f259"; }

.fad.fa-hand-spock:after, .fa-duotone.fa-hand-spock:after {
  content: "\10f259"; }

.fad.fa-hand-wave:before, .fa-duotone.fa-hand-wave:before {
  content: "\e1a7\fe01"; }

.fad.fa-hand-wave:after, .fa-duotone.fa-hand-wave:after {
  content: "\e1a7\fe02"; }

.fad.fa-hands:before, .fa-duotone.fa-hands:before {
  content: "\f2a7\fe01"; }

.fad.fa-hands:after, .fa-duotone.fa-hands:after {
  content: "\f2a7\fe02"; }

.fad.fa-sign-language:before, .fa-duotone.fa-sign-language:before {
  content: "\f2a7\fe01"; }

.fad.fa-sign-language:after, .fa-duotone.fa-sign-language:after {
  content: "\f2a7\fe02"; }

.fad.fa-signing:before, .fa-duotone.fa-signing:before {
  content: "\f2a7\fe01"; }

.fad.fa-signing:after, .fa-duotone.fa-signing:after {
  content: "\f2a7\fe02"; }

.fad.fa-hands-asl-interpreting:before, .fa-duotone.fa-hands-asl-interpreting:before {
  content: "\f2a3\fe01"; }

.fad.fa-hands-asl-interpreting:after, .fa-duotone.fa-hands-asl-interpreting:after {
  content: "\f2a3\fe02"; }

.fad.fa-american-sign-language-interpreting:before, .fa-duotone.fa-american-sign-language-interpreting:before {
  content: "\f2a3\fe01"; }

.fad.fa-american-sign-language-interpreting:after, .fa-duotone.fa-american-sign-language-interpreting:after {
  content: "\f2a3\fe02"; }

.fad.fa-asl-interpreting:before, .fa-duotone.fa-asl-interpreting:before {
  content: "\f2a3\fe01"; }

.fad.fa-asl-interpreting:after, .fa-duotone.fa-asl-interpreting:after {
  content: "\f2a3\fe02"; }

.fad.fa-hands-american-sign-language-interpreting:before, .fa-duotone.fa-hands-american-sign-language-interpreting:before {
  content: "\f2a3\fe01"; }

.fad.fa-hands-american-sign-language-interpreting:after, .fa-duotone.fa-hands-american-sign-language-interpreting:after {
  content: "\f2a3\fe02"; }

.fad.fa-hands-bubbles:before, .fa-duotone.fa-hands-bubbles:before {
  content: "\e05e\fe01"; }

.fad.fa-hands-bubbles:after, .fa-duotone.fa-hands-bubbles:after {
  content: "\e05e\fe02"; }

.fad.fa-hands-wash:before, .fa-duotone.fa-hands-wash:before {
  content: "\e05e\fe01"; }

.fad.fa-hands-wash:after, .fa-duotone.fa-hands-wash:after {
  content: "\e05e\fe02"; }

.fad.fa-hands-clapping:before, .fa-duotone.fa-hands-clapping:before {
  content: "\e1a8\fe01"; }

.fad.fa-hands-clapping:after, .fa-duotone.fa-hands-clapping:after {
  content: "\e1a8\fe02"; }

.fad.fa-hands-holding:before, .fa-duotone.fa-hands-holding:before {
  content: "\f4c2\fe01"; }

.fad.fa-hands-holding:after, .fa-duotone.fa-hands-holding:after {
  content: "\f4c2\fe02"; }

.fad.fa-hands-holding-diamond:before, .fa-duotone.fa-hands-holding-diamond:before {
  content: "\f47c\fe01"; }

.fad.fa-hands-holding-diamond:after, .fa-duotone.fa-hands-holding-diamond:after {
  content: "\f47c\fe02"; }

.fad.fa-hand-receiving:before, .fa-duotone.fa-hand-receiving:before {
  content: "\f47c\fe01"; }

.fad.fa-hand-receiving:after, .fa-duotone.fa-hand-receiving:after {
  content: "\f47c\fe02"; }

.fad.fa-hands-holding-dollar:before, .fa-duotone.fa-hands-holding-dollar:before {
  content: "\f4c5\fe01"; }

.fad.fa-hands-holding-dollar:after, .fa-duotone.fa-hands-holding-dollar:after {
  content: "\f4c5\fe02"; }

.fad.fa-hands-usd:before, .fa-duotone.fa-hands-usd:before {
  content: "\f4c5\fe01"; }

.fad.fa-hands-usd:after, .fa-duotone.fa-hands-usd:after {
  content: "\f4c5\fe02"; }

.fad.fa-hands-holding-heart:before, .fa-duotone.fa-hands-holding-heart:before {
  content: "\f4c3\fe01"; }

.fad.fa-hands-holding-heart:after, .fa-duotone.fa-hands-holding-heart:after {
  content: "\f4c3\fe02"; }

.fad.fa-hands-heart:before, .fa-duotone.fa-hands-heart:before {
  content: "\f4c3\fe01"; }

.fad.fa-hands-heart:after, .fa-duotone.fa-hands-heart:after {
  content: "\f4c3\fe02"; }

.fad.fa-hands-praying:before, .fa-duotone.fa-hands-praying:before {
  content: "\f684\fe01"; }

.fad.fa-hands-praying:after, .fa-duotone.fa-hands-praying:after {
  content: "\f684\fe02"; }

.fad.fa-praying-hands:before, .fa-duotone.fa-praying-hands:before {
  content: "\f684\fe01"; }

.fad.fa-praying-hands:after, .fa-duotone.fa-praying-hands:after {
  content: "\f684\fe02"; }

.fad.fa-handshake:before, .fa-duotone.fa-handshake:before {
  content: "\f2b5\fe01"; }

.fad.fa-handshake:after, .fa-duotone.fa-handshake:after {
  content: "\f2b5\fe02"; }

.fad.fa-handshake-angle:before, .fa-duotone.fa-handshake-angle:before {
  content: "\f4c4\fe01"; }

.fad.fa-handshake-angle:after, .fa-duotone.fa-handshake-angle:after {
  content: "\f4c4\fe02"; }

.fad.fa-hands-helping:before, .fa-duotone.fa-hands-helping:before {
  content: "\f4c4\fe01"; }

.fad.fa-hands-helping:after, .fa-duotone.fa-hands-helping:after {
  content: "\f4c4\fe02"; }

.fad.fa-handshake-simple:before, .fa-duotone.fa-handshake-simple:before {
  content: "\f4c6"; }

.fad.fa-handshake-simple:after, .fa-duotone.fa-handshake-simple:after {
  content: "\10f4c6"; }

.fad.fa-handshake-alt:before, .fa-duotone.fa-handshake-alt:before {
  content: "\f4c6"; }

.fad.fa-handshake-alt:after, .fa-duotone.fa-handshake-alt:after {
  content: "\10f4c6"; }

.fad.fa-handshake-simple-slash:before, .fa-duotone.fa-handshake-simple-slash:before {
  content: "\e05f\fe01"; }

.fad.fa-handshake-simple-slash:after, .fa-duotone.fa-handshake-simple-slash:after {
  content: "\e05f\fe02"; }

.fad.fa-handshake-alt-slash:before, .fa-duotone.fa-handshake-alt-slash:before {
  content: "\e05f\fe01"; }

.fad.fa-handshake-alt-slash:after, .fa-duotone.fa-handshake-alt-slash:after {
  content: "\e05f\fe02"; }

.fad.fa-handshake-slash:before, .fa-duotone.fa-handshake-slash:before {
  content: "\e060\fe01"; }

.fad.fa-handshake-slash:after, .fa-duotone.fa-handshake-slash:after {
  content: "\e060\fe02"; }

.fad.fa-hanukiah:before, .fa-duotone.fa-hanukiah:before {
  content: "\f6e6"; }

.fad.fa-hanukiah:after, .fa-duotone.fa-hanukiah:after {
  content: "\10f6e6"; }

.fad.fa-hard-drive:before, .fa-duotone.fa-hard-drive:before {
  content: "\f0a0"; }

.fad.fa-hard-drive:after, .fa-duotone.fa-hard-drive:after {
  content: "\10f0a0"; }

.fad.fa-hdd:before, .fa-duotone.fa-hdd:before {
  content: "\f0a0"; }

.fad.fa-hdd:after, .fa-duotone.fa-hdd:after {
  content: "\10f0a0"; }

.fad.fa-hashtag:before, .fa-duotone.fa-hashtag:before {
  content: "\23\fe01"; }

.fad.fa-hashtag:after, .fa-duotone.fa-hashtag:after {
  content: "\23\fe02"; }

.fad.fa-hat-chef:before, .fa-duotone.fa-hat-chef:before {
  content: "\f86b\fe01"; }

.fad.fa-hat-chef:after, .fa-duotone.fa-hat-chef:after {
  content: "\f86b\fe02"; }

.fad.fa-hat-cowboy:before, .fa-duotone.fa-hat-cowboy:before {
  content: "\f8c0\fe01"; }

.fad.fa-hat-cowboy:after, .fa-duotone.fa-hat-cowboy:after {
  content: "\f8c0\fe02"; }

.fad.fa-hat-cowboy-side:before, .fa-duotone.fa-hat-cowboy-side:before {
  content: "\f8c1\fe01"; }

.fad.fa-hat-cowboy-side:after, .fa-duotone.fa-hat-cowboy-side:after {
  content: "\f8c1\fe02"; }

.fad.fa-hat-santa:before, .fa-duotone.fa-hat-santa:before {
  content: "\f7a7\fe01"; }

.fad.fa-hat-santa:after, .fa-duotone.fa-hat-santa:after {
  content: "\f7a7\fe02"; }

.fad.fa-hat-winter:before, .fa-duotone.fa-hat-winter:before {
  content: "\f7a8\fe01"; }

.fad.fa-hat-winter:after, .fa-duotone.fa-hat-winter:after {
  content: "\f7a8\fe02"; }

.fad.fa-hat-witch:before, .fa-duotone.fa-hat-witch:before {
  content: "\f6e7\fe01"; }

.fad.fa-hat-witch:after, .fa-duotone.fa-hat-witch:after {
  content: "\f6e7\fe02"; }

.fad.fa-hat-wizard:before, .fa-duotone.fa-hat-wizard:before {
  content: "\f6e8\fe01"; }

.fad.fa-hat-wizard:after, .fa-duotone.fa-hat-wizard:after {
  content: "\f6e8\fe02"; }

.fad.fa-head-side:before, .fa-duotone.fa-head-side:before {
  content: "\f6e9\fe01"; }

.fad.fa-head-side:after, .fa-duotone.fa-head-side:after {
  content: "\f6e9\fe02"; }

.fad.fa-head-side-brain:before, .fa-duotone.fa-head-side-brain:before {
  content: "\f808\fe01"; }

.fad.fa-head-side-brain:after, .fa-duotone.fa-head-side-brain:after {
  content: "\f808\fe02"; }

.fad.fa-head-side-cough:before, .fa-duotone.fa-head-side-cough:before {
  content: "\e061\fe01"; }

.fad.fa-head-side-cough:after, .fa-duotone.fa-head-side-cough:after {
  content: "\e061\fe02"; }

.fad.fa-head-side-cough-slash:before, .fa-duotone.fa-head-side-cough-slash:before {
  content: "\e062\fe01"; }

.fad.fa-head-side-cough-slash:after, .fa-duotone.fa-head-side-cough-slash:after {
  content: "\e062\fe02"; }

.fad.fa-head-side-goggles:before, .fa-duotone.fa-head-side-goggles:before {
  content: "\f6ea\fe01"; }

.fad.fa-head-side-goggles:after, .fa-duotone.fa-head-side-goggles:after {
  content: "\f6ea\fe02"; }

.fad.fa-head-vr:before, .fa-duotone.fa-head-vr:before {
  content: "\f6ea\fe01"; }

.fad.fa-head-vr:after, .fa-duotone.fa-head-vr:after {
  content: "\f6ea\fe02"; }

.fad.fa-head-side-headphones:before, .fa-duotone.fa-head-side-headphones:before {
  content: "\f8c2\fe01"; }

.fad.fa-head-side-headphones:after, .fa-duotone.fa-head-side-headphones:after {
  content: "\f8c2\fe02"; }

.fad.fa-head-side-heart:before, .fa-duotone.fa-head-side-heart:before {
  content: "\e1aa\fe01"; }

.fad.fa-head-side-heart:after, .fa-duotone.fa-head-side-heart:after {
  content: "\e1aa\fe02"; }

.fad.fa-head-side-mask:before, .fa-duotone.fa-head-side-mask:before {
  content: "\e063\fe01"; }

.fad.fa-head-side-mask:after, .fa-duotone.fa-head-side-mask:after {
  content: "\e063\fe02"; }

.fad.fa-head-side-medical:before, .fa-duotone.fa-head-side-medical:before {
  content: "\f809\fe01"; }

.fad.fa-head-side-medical:after, .fa-duotone.fa-head-side-medical:after {
  content: "\f809\fe02"; }

.fad.fa-head-side-virus:before, .fa-duotone.fa-head-side-virus:before {
  content: "\e064\fe01"; }

.fad.fa-head-side-virus:after, .fa-duotone.fa-head-side-virus:after {
  content: "\e064\fe02"; }

.fad.fa-heading:before, .fa-duotone.fa-heading:before {
  content: "\f1dc\fe01"; }

.fad.fa-heading:after, .fa-duotone.fa-heading:after {
  content: "\f1dc\fe02"; }

.fad.fa-header:before, .fa-duotone.fa-header:before {
  content: "\f1dc\fe01"; }

.fad.fa-header:after, .fa-duotone.fa-header:after {
  content: "\f1dc\fe02"; }

.fad.fa-headphones:before, .fa-duotone.fa-headphones:before {
  content: "\f025"; }

.fad.fa-headphones:after, .fa-duotone.fa-headphones:after {
  content: "\10f025"; }

.fad.fa-headphones-simple:before, .fa-duotone.fa-headphones-simple:before {
  content: "\f58f\fe01"; }

.fad.fa-headphones-simple:after, .fa-duotone.fa-headphones-simple:after {
  content: "\f58f\fe02"; }

.fad.fa-headphones-alt:before, .fa-duotone.fa-headphones-alt:before {
  content: "\f58f\fe01"; }

.fad.fa-headphones-alt:after, .fa-duotone.fa-headphones-alt:after {
  content: "\f58f\fe02"; }

.fad.fa-headset:before, .fa-duotone.fa-headset:before {
  content: "\f590\fe01"; }

.fad.fa-headset:after, .fa-duotone.fa-headset:after {
  content: "\f590\fe02"; }

.fad.fa-heart:before, .fa-duotone.fa-heart:before {
  content: "\2665\fe01"; }

.fad.fa-heart:after, .fa-duotone.fa-heart:after {
  content: "\2665\fe02"; }

.fad.fa-heart-crack:before, .fa-duotone.fa-heart-crack:before {
  content: "\f7a9"; }

.fad.fa-heart-crack:after, .fa-duotone.fa-heart-crack:after {
  content: "\10f7a9"; }

.fad.fa-heart-broken:before, .fa-duotone.fa-heart-broken:before {
  content: "\f7a9"; }

.fad.fa-heart-broken:after, .fa-duotone.fa-heart-broken:after {
  content: "\10f7a9"; }

.fad.fa-heart-half:before, .fa-duotone.fa-heart-half:before {
  content: "\e1ab\fe01"; }

.fad.fa-heart-half:after, .fa-duotone.fa-heart-half:after {
  content: "\e1ab\fe02"; }

.fad.fa-heart-half-stroke:before, .fa-duotone.fa-heart-half-stroke:before {
  content: "\e1ac\fe01"; }

.fad.fa-heart-half-stroke:after, .fa-duotone.fa-heart-half-stroke:after {
  content: "\e1ac\fe02"; }

.fad.fa-heart-half-alt:before, .fa-duotone.fa-heart-half-alt:before {
  content: "\e1ac\fe01"; }

.fad.fa-heart-half-alt:after, .fa-duotone.fa-heart-half-alt:after {
  content: "\e1ac\fe02"; }

.fad.fa-heart-pulse:before, .fa-duotone.fa-heart-pulse:before {
  content: "\f21e\fe01"; }

.fad.fa-heart-pulse:after, .fa-duotone.fa-heart-pulse:after {
  content: "\f21e\fe02"; }

.fad.fa-heartbeat:before, .fa-duotone.fa-heartbeat:before {
  content: "\f21e\fe01"; }

.fad.fa-heartbeat:after, .fa-duotone.fa-heartbeat:after {
  content: "\f21e\fe02"; }

.fad.fa-heat:before, .fa-duotone.fa-heat:before {
  content: "\e00c\fe01"; }

.fad.fa-heat:after, .fa-duotone.fa-heat:after {
  content: "\e00c\fe02"; }

.fad.fa-helicopter:before, .fa-duotone.fa-helicopter:before {
  content: "\f533"; }

.fad.fa-helicopter:after, .fa-duotone.fa-helicopter:after {
  content: "\10f533"; }

.fad.fa-helmet-battle:before, .fa-duotone.fa-helmet-battle:before {
  content: "\f6eb\fe01"; }

.fad.fa-helmet-battle:after, .fa-duotone.fa-helmet-battle:after {
  content: "\f6eb\fe02"; }

.fad.fa-helmet-safety:before, .fa-duotone.fa-helmet-safety:before {
  content: "\f807\fe01"; }

.fad.fa-helmet-safety:after, .fa-duotone.fa-helmet-safety:after {
  content: "\f807\fe02"; }

.fad.fa-hard-hat:before, .fa-duotone.fa-hard-hat:before {
  content: "\f807\fe01"; }

.fad.fa-hard-hat:after, .fa-duotone.fa-hard-hat:after {
  content: "\f807\fe02"; }

.fad.fa-hat-hard:before, .fa-duotone.fa-hat-hard:before {
  content: "\f807\fe01"; }

.fad.fa-hat-hard:after, .fa-duotone.fa-hat-hard:after {
  content: "\f807\fe02"; }

.fad.fa-hexagon:before, .fa-duotone.fa-hexagon:before {
  content: "\2b23\fe01"; }

.fad.fa-hexagon:after, .fa-duotone.fa-hexagon:after {
  content: "\2b23\fe02"; }

.fad.fa-hexagon-divide:before, .fa-duotone.fa-hexagon-divide:before {
  content: "\e1ad\fe01"; }

.fad.fa-hexagon-divide:after, .fa-duotone.fa-hexagon-divide:after {
  content: "\e1ad\fe02"; }

.fad.fa-hexagon-minus:before, .fa-duotone.fa-hexagon-minus:before {
  content: "\f307\fe01"; }

.fad.fa-hexagon-minus:after, .fa-duotone.fa-hexagon-minus:after {
  content: "\f307\fe02"; }

.fad.fa-minus-hexagon:before, .fa-duotone.fa-minus-hexagon:before {
  content: "\f307\fe01"; }

.fad.fa-minus-hexagon:after, .fa-duotone.fa-minus-hexagon:after {
  content: "\f307\fe02"; }

.fad.fa-hexagon-plus:before, .fa-duotone.fa-hexagon-plus:before {
  content: "\f300\fe01"; }

.fad.fa-hexagon-plus:after, .fa-duotone.fa-hexagon-plus:after {
  content: "\f300\fe02"; }

.fad.fa-plus-hexagon:before, .fa-duotone.fa-plus-hexagon:before {
  content: "\f300\fe01"; }

.fad.fa-plus-hexagon:after, .fa-duotone.fa-plus-hexagon:after {
  content: "\f300\fe02"; }

.fad.fa-hexagon-xmark:before, .fa-duotone.fa-hexagon-xmark:before {
  content: "\f2ee\fe01"; }

.fad.fa-hexagon-xmark:after, .fa-duotone.fa-hexagon-xmark:after {
  content: "\f2ee\fe02"; }

.fad.fa-times-hexagon:before, .fa-duotone.fa-times-hexagon:before {
  content: "\f2ee\fe01"; }

.fad.fa-times-hexagon:after, .fa-duotone.fa-times-hexagon:after {
  content: "\f2ee\fe02"; }

.fad.fa-xmark-hexagon:before, .fa-duotone.fa-xmark-hexagon:before {
  content: "\f2ee\fe01"; }

.fad.fa-xmark-hexagon:after, .fa-duotone.fa-xmark-hexagon:after {
  content: "\f2ee\fe02"; }

.fad.fa-high-definition:before, .fa-duotone.fa-high-definition:before {
  content: "\e1ae\fe01"; }

.fad.fa-high-definition:after, .fa-duotone.fa-high-definition:after {
  content: "\e1ae\fe02"; }

.fad.fa-rectangle-hd:before, .fa-duotone.fa-rectangle-hd:before {
  content: "\e1ae\fe01"; }

.fad.fa-rectangle-hd:after, .fa-duotone.fa-rectangle-hd:after {
  content: "\e1ae\fe02"; }

.fad.fa-highlighter:before, .fa-duotone.fa-highlighter:before {
  content: "\f591\fe01"; }

.fad.fa-highlighter:after, .fa-duotone.fa-highlighter:after {
  content: "\f591\fe02"; }

.fad.fa-highlighter-line:before, .fa-duotone.fa-highlighter-line:before {
  content: "\e1af\fe01"; }

.fad.fa-highlighter-line:after, .fa-duotone.fa-highlighter-line:after {
  content: "\e1af\fe02"; }

.fad.fa-hippo:before, .fa-duotone.fa-hippo:before {
  content: "\f6ed"; }

.fad.fa-hippo:after, .fa-duotone.fa-hippo:after {
  content: "\10f6ed"; }

.fad.fa-hockey-mask:before, .fa-duotone.fa-hockey-mask:before {
  content: "\f6ee\fe01"; }

.fad.fa-hockey-mask:after, .fa-duotone.fa-hockey-mask:after {
  content: "\f6ee\fe02"; }

.fad.fa-hockey-puck:before, .fa-duotone.fa-hockey-puck:before {
  content: "\f453\fe01"; }

.fad.fa-hockey-puck:after, .fa-duotone.fa-hockey-puck:after {
  content: "\f453\fe02"; }

.fad.fa-hockey-stick-puck:before, .fa-duotone.fa-hockey-stick-puck:before {
  content: "\e3ae\fe01"; }

.fad.fa-hockey-stick-puck:after, .fa-duotone.fa-hockey-stick-puck:after {
  content: "\e3ae\fe02"; }

.fad.fa-hockey-sticks:before, .fa-duotone.fa-hockey-sticks:before {
  content: "\f454\fe01"; }

.fad.fa-hockey-sticks:after, .fa-duotone.fa-hockey-sticks:after {
  content: "\f454\fe02"; }

.fad.fa-holly-berry:before, .fa-duotone.fa-holly-berry:before {
  content: "\f7aa\fe01"; }

.fad.fa-holly-berry:after, .fa-duotone.fa-holly-berry:after {
  content: "\f7aa\fe02"; }

.fad.fa-hood-cloak:before, .fa-duotone.fa-hood-cloak:before {
  content: "\f6ef\fe01"; }

.fad.fa-hood-cloak:after, .fa-duotone.fa-hood-cloak:after {
  content: "\f6ef\fe02"; }

.fad.fa-horizontal-rule:before, .fa-duotone.fa-horizontal-rule:before {
  content: "\2015\fe01"; }

.fad.fa-horizontal-rule:after, .fa-duotone.fa-horizontal-rule:after {
  content: "\2015\fe02"; }

.fad.fa-horse:before, .fa-duotone.fa-horse:before {
  content: "\f6f0"; }

.fad.fa-horse:after, .fa-duotone.fa-horse:after {
  content: "\10f6f0"; }

.fad.fa-horse-head:before, .fa-duotone.fa-horse-head:before {
  content: "\f7ab\fe01"; }

.fad.fa-horse-head:after, .fa-duotone.fa-horse-head:after {
  content: "\f7ab\fe02"; }

.fad.fa-horse-saddle:before, .fa-duotone.fa-horse-saddle:before {
  content: "\f8c3\fe01"; }

.fad.fa-horse-saddle:after, .fa-duotone.fa-horse-saddle:after {
  content: "\f8c3\fe02"; }

.fad.fa-hospital:before, .fa-duotone.fa-hospital:before {
  content: "\f0f8"; }

.fad.fa-hospital:after, .fa-duotone.fa-hospital:after {
  content: "\10f0f8"; }

.fad.fa-hospital-user:before, .fa-duotone.fa-hospital-user:before {
  content: "\f80d\fe01"; }

.fad.fa-hospital-user:after, .fa-duotone.fa-hospital-user:after {
  content: "\f80d\fe02"; }

.fad.fa-hospital-wide:before, .fa-duotone.fa-hospital-wide:before {
  content: "\f47d\fe01"; }

.fad.fa-hospital-wide:after, .fa-duotone.fa-hospital-wide:after {
  content: "\f47d\fe02"; }

.fad.fa-hospital-alt:before, .fa-duotone.fa-hospital-alt:before {
  content: "\f47d\fe01"; }

.fad.fa-hospital-alt:after, .fa-duotone.fa-hospital-alt:after {
  content: "\f47d\fe02"; }

.fad.fa-hospitals:before, .fa-duotone.fa-hospitals:before {
  content: "\f80e\fe01"; }

.fad.fa-hospitals:after, .fa-duotone.fa-hospitals:after {
  content: "\f80e\fe02"; }

.fad.fa-hot-tub-person:before, .fa-duotone.fa-hot-tub-person:before {
  content: "\f593\fe01"; }

.fad.fa-hot-tub-person:after, .fa-duotone.fa-hot-tub-person:after {
  content: "\f593\fe02"; }

.fad.fa-hot-tub:before, .fa-duotone.fa-hot-tub:before {
  content: "\f593\fe01"; }

.fad.fa-hot-tub:after, .fa-duotone.fa-hot-tub:after {
  content: "\f593\fe02"; }

.fad.fa-hotdog:before, .fa-duotone.fa-hotdog:before {
  content: "\f80f"; }

.fad.fa-hotdog:after, .fa-duotone.fa-hotdog:after {
  content: "\10f80f"; }

.fad.fa-hotel:before, .fa-duotone.fa-hotel:before {
  content: "\f594"; }

.fad.fa-hotel:after, .fa-duotone.fa-hotel:after {
  content: "\10f594"; }

.fad.fa-hourglass:before, .fa-duotone.fa-hourglass:before {
  content: "\23f3\fe01"; }

.fad.fa-hourglass:after, .fa-duotone.fa-hourglass:after {
  content: "\23f3\fe02"; }

.fad.fa-hourglass-2:before, .fa-duotone.fa-hourglass-2:before {
  content: "\23f3\fe01"; }

.fad.fa-hourglass-2:after, .fa-duotone.fa-hourglass-2:after {
  content: "\23f3\fe02"; }

.fad.fa-hourglass-half:before, .fa-duotone.fa-hourglass-half:before {
  content: "\23f3\fe01"; }

.fad.fa-hourglass-half:after, .fa-duotone.fa-hourglass-half:after {
  content: "\23f3\fe02"; }

.fad.fa-hourglass-empty:before, .fa-duotone.fa-hourglass-empty:before {
  content: "\f252\fe01"; }

.fad.fa-hourglass-empty:after, .fa-duotone.fa-hourglass-empty:after {
  content: "\f252\fe02"; }

.fad.fa-hourglass-end:before, .fa-duotone.fa-hourglass-end:before {
  content: "\231b\fe01"; }

.fad.fa-hourglass-end:after, .fa-duotone.fa-hourglass-end:after {
  content: "\231b\fe02"; }

.fad.fa-hourglass-3:before, .fa-duotone.fa-hourglass-3:before {
  content: "\231b\fe01"; }

.fad.fa-hourglass-3:after, .fa-duotone.fa-hourglass-3:after {
  content: "\231b\fe02"; }

.fad.fa-hourglass-start:before, .fa-duotone.fa-hourglass-start:before {
  content: "\f251\fe01"; }

.fad.fa-hourglass-start:after, .fa-duotone.fa-hourglass-start:after {
  content: "\f251\fe02"; }

.fad.fa-hourglass-1:before, .fa-duotone.fa-hourglass-1:before {
  content: "\f251\fe01"; }

.fad.fa-hourglass-1:after, .fa-duotone.fa-hourglass-1:after {
  content: "\f251\fe02"; }

.fad.fa-house:before, .fa-duotone.fa-house:before {
  content: "\f015"; }

.fad.fa-house:after, .fa-duotone.fa-house:after {
  content: "\10f015"; }

.fad.fa-home:before, .fa-duotone.fa-home:before {
  content: "\f015"; }

.fad.fa-home:after, .fa-duotone.fa-home:after {
  content: "\10f015"; }

.fad.fa-house-blank:before, .fa-duotone.fa-house-blank:before {
  content: "\f80a\fe01"; }

.fad.fa-house-blank:after, .fa-duotone.fa-house-blank:after {
  content: "\f80a\fe02"; }

.fad.fa-home-blank:before, .fa-duotone.fa-home-blank:before {
  content: "\f80a\fe01"; }

.fad.fa-home-blank:after, .fa-duotone.fa-home-blank:after {
  content: "\f80a\fe02"; }

.fad.fa-home-lg-alt:before, .fa-duotone.fa-home-lg-alt:before {
  content: "\f80a\fe01"; }

.fad.fa-home-lg-alt:after, .fa-duotone.fa-home-lg-alt:after {
  content: "\f80a\fe02"; }

.fad.fa-house-building:before, .fa-duotone.fa-house-building:before {
  content: "\e1b1\fe01"; }

.fad.fa-house-building:after, .fa-duotone.fa-house-building:after {
  content: "\e1b1\fe02"; }

.fad.fa-house-chimney:before, .fa-duotone.fa-house-chimney:before {
  content: "\e3af\fe01"; }

.fad.fa-house-chimney:after, .fa-duotone.fa-house-chimney:after {
  content: "\e3af\fe02"; }

.fad.fa-home-lg:before, .fa-duotone.fa-home-lg:before {
  content: "\e3af\fe01"; }

.fad.fa-home-lg:after, .fa-duotone.fa-home-lg:after {
  content: "\e3af\fe02"; }

.fad.fa-house-chimney-blank:before, .fa-duotone.fa-house-chimney-blank:before {
  content: "\e3b0\fe01"; }

.fad.fa-house-chimney-blank:after, .fa-duotone.fa-house-chimney-blank:after {
  content: "\e3b0\fe02"; }

.fad.fa-house-chimney-crack:before, .fa-duotone.fa-house-chimney-crack:before {
  content: "\f6f1\fe01"; }

.fad.fa-house-chimney-crack:after, .fa-duotone.fa-house-chimney-crack:after {
  content: "\f6f1\fe02"; }

.fad.fa-house-damage:before, .fa-duotone.fa-house-damage:before {
  content: "\f6f1\fe01"; }

.fad.fa-house-damage:after, .fa-duotone.fa-house-damage:after {
  content: "\f6f1\fe02"; }

.fad.fa-house-chimney-heart:before, .fa-duotone.fa-house-chimney-heart:before {
  content: "\e1b2\fe01"; }

.fad.fa-house-chimney-heart:after, .fa-duotone.fa-house-chimney-heart:after {
  content: "\e1b2\fe02"; }

.fad.fa-house-chimney-medical:before, .fa-duotone.fa-house-chimney-medical:before {
  content: "\f7f2\fe01"; }

.fad.fa-house-chimney-medical:after, .fa-duotone.fa-house-chimney-medical:after {
  content: "\f7f2\fe02"; }

.fad.fa-clinic-medical:before, .fa-duotone.fa-clinic-medical:before {
  content: "\f7f2\fe01"; }

.fad.fa-clinic-medical:after, .fa-duotone.fa-clinic-medical:after {
  content: "\f7f2\fe02"; }

.fad.fa-house-chimney-user:before, .fa-duotone.fa-house-chimney-user:before {
  content: "\e065\fe01"; }

.fad.fa-house-chimney-user:after, .fa-duotone.fa-house-chimney-user:after {
  content: "\e065\fe02"; }

.fad.fa-house-chimney-window:before, .fa-duotone.fa-house-chimney-window:before {
  content: "\e00d\fe01"; }

.fad.fa-house-chimney-window:after, .fa-duotone.fa-house-chimney-window:after {
  content: "\e00d\fe02"; }

.fad.fa-house-crack:before, .fa-duotone.fa-house-crack:before {
  content: "\e3b1\fe01"; }

.fad.fa-house-crack:after, .fa-duotone.fa-house-crack:after {
  content: "\e3b1\fe02"; }

.fad.fa-house-day:before, .fa-duotone.fa-house-day:before {
  content: "\e00e\fe01"; }

.fad.fa-house-day:after, .fa-duotone.fa-house-day:after {
  content: "\e00e\fe02"; }

.fad.fa-house-flood:before, .fa-duotone.fa-house-flood:before {
  content: "\f74f\fe01"; }

.fad.fa-house-flood:after, .fa-duotone.fa-house-flood:after {
  content: "\f74f\fe02"; }

.fad.fa-house-heart:before, .fa-duotone.fa-house-heart:before {
  content: "\f4c9\fe01"; }

.fad.fa-house-heart:after, .fa-duotone.fa-house-heart:after {
  content: "\f4c9\fe02"; }

.fad.fa-home-heart:before, .fa-duotone.fa-home-heart:before {
  content: "\f4c9\fe01"; }

.fad.fa-home-heart:after, .fa-duotone.fa-home-heart:after {
  content: "\f4c9\fe02"; }

.fad.fa-house-laptop:before, .fa-duotone.fa-house-laptop:before {
  content: "\e066\fe01"; }

.fad.fa-house-laptop:after, .fa-duotone.fa-house-laptop:after {
  content: "\e066\fe02"; }

.fad.fa-laptop-house:before, .fa-duotone.fa-laptop-house:before {
  content: "\e066\fe01"; }

.fad.fa-laptop-house:after, .fa-duotone.fa-laptop-house:after {
  content: "\e066\fe02"; }

.fad.fa-house-medical:before, .fa-duotone.fa-house-medical:before {
  content: "\e3b2\fe01"; }

.fad.fa-house-medical:after, .fa-duotone.fa-house-medical:after {
  content: "\e3b2\fe02"; }

.fad.fa-house-night:before, .fa-duotone.fa-house-night:before {
  content: "\e010\fe01"; }

.fad.fa-house-night:after, .fa-duotone.fa-house-night:after {
  content: "\e010\fe02"; }

.fad.fa-house-person-leave:before, .fa-duotone.fa-house-person-leave:before {
  content: "\e00f\fe01"; }

.fad.fa-house-person-leave:after, .fa-duotone.fa-house-person-leave:after {
  content: "\e00f\fe02"; }

.fad.fa-house-person-depart:before, .fa-duotone.fa-house-person-depart:before {
  content: "\e00f\fe01"; }

.fad.fa-house-person-depart:after, .fa-duotone.fa-house-person-depart:after {
  content: "\e00f\fe02"; }

.fad.fa-house-person-return:before, .fa-duotone.fa-house-person-return:before {
  content: "\e011\fe01"; }

.fad.fa-house-person-return:after, .fa-duotone.fa-house-person-return:after {
  content: "\e011\fe02"; }

.fad.fa-house-person-arrive:before, .fa-duotone.fa-house-person-arrive:before {
  content: "\e011\fe01"; }

.fad.fa-house-person-arrive:after, .fa-duotone.fa-house-person-arrive:after {
  content: "\e011\fe02"; }

.fad.fa-house-signal:before, .fa-duotone.fa-house-signal:before {
  content: "\e012\fe01"; }

.fad.fa-house-signal:after, .fa-duotone.fa-house-signal:after {
  content: "\e012\fe02"; }

.fad.fa-house-tree:before, .fa-duotone.fa-house-tree:before {
  content: "\e1b3\fe01"; }

.fad.fa-house-tree:after, .fa-duotone.fa-house-tree:after {
  content: "\e1b3\fe02"; }

.fad.fa-house-turret:before, .fa-duotone.fa-house-turret:before {
  content: "\e1b4\fe01"; }

.fad.fa-house-turret:after, .fa-duotone.fa-house-turret:after {
  content: "\e1b4\fe02"; }

.fad.fa-house-user:before, .fa-duotone.fa-house-user:before {
  content: "\e1b0\fe01"; }

.fad.fa-house-user:after, .fa-duotone.fa-house-user:after {
  content: "\e1b0\fe02"; }

.fad.fa-home-user:before, .fa-duotone.fa-home-user:before {
  content: "\e1b0\fe01"; }

.fad.fa-home-user:after, .fa-duotone.fa-home-user:after {
  content: "\e1b0\fe02"; }

.fad.fa-house-window:before, .fa-duotone.fa-house-window:before {
  content: "\e3b3\fe01"; }

.fad.fa-house-window:after, .fa-duotone.fa-house-window:after {
  content: "\e3b3\fe02"; }

.fad.fa-hryvnia-sign:before, .fa-duotone.fa-hryvnia-sign:before {
  content: "\20b4\fe01"; }

.fad.fa-hryvnia-sign:after, .fa-duotone.fa-hryvnia-sign:after {
  content: "\20b4\fe02"; }

.fad.fa-hryvnia:before, .fa-duotone.fa-hryvnia:before {
  content: "\20b4\fe01"; }

.fad.fa-hryvnia:after, .fa-duotone.fa-hryvnia:after {
  content: "\20b4\fe02"; }

.fad.fa-hurricane:before, .fa-duotone.fa-hurricane:before {
  content: "\f751\fe01"; }

.fad.fa-hurricane:after, .fa-duotone.fa-hurricane:after {
  content: "\f751\fe02"; }

.fad.fa-hyphen:before, .fa-duotone.fa-hyphen:before {
  content: "\2d\fe01"; }

.fad.fa-hyphen:after, .fa-duotone.fa-hyphen:after {
  content: "\2d\fe02"; }

.fad.fa-i:before, .fa-duotone.fa-i:before {
  content: "\49\fe01"; }

.fad.fa-i:after, .fa-duotone.fa-i:after {
  content: "\49\fe02"; }

.fad.fa-i-cursor:before, .fa-duotone.fa-i-cursor:before {
  content: "\f246\fe01"; }

.fad.fa-i-cursor:after, .fa-duotone.fa-i-cursor:after {
  content: "\f246\fe02"; }

.fad.fa-ice-cream:before, .fa-duotone.fa-ice-cream:before {
  content: "\f810"; }

.fad.fa-ice-cream:after, .fa-duotone.fa-ice-cream:after {
  content: "\10f810"; }

.fad.fa-ice-skate:before, .fa-duotone.fa-ice-skate:before {
  content: "\26f8\fe01"; }

.fad.fa-ice-skate:after, .fa-duotone.fa-ice-skate:after {
  content: "\26f8\fe02"; }

.fad.fa-icicles:before, .fa-duotone.fa-icicles:before {
  content: "\f7ad\fe01"; }

.fad.fa-icicles:after, .fa-duotone.fa-icicles:after {
  content: "\f7ad\fe02"; }

.fad.fa-icons:before, .fa-duotone.fa-icons:before {
  content: "\f86d\fe01"; }

.fad.fa-icons:after, .fa-duotone.fa-icons:after {
  content: "\f86d\fe02"; }

.fad.fa-heart-music-camera-bolt:before, .fa-duotone.fa-heart-music-camera-bolt:before {
  content: "\f86d\fe01"; }

.fad.fa-heart-music-camera-bolt:after, .fa-duotone.fa-heart-music-camera-bolt:after {
  content: "\f86d\fe02"; }

.fad.fa-id-badge:before, .fa-duotone.fa-id-badge:before {
  content: "\f2c1\fe01"; }

.fad.fa-id-badge:after, .fa-duotone.fa-id-badge:after {
  content: "\f2c1\fe02"; }

.fad.fa-id-card:before, .fa-duotone.fa-id-card:before {
  content: "\f2c2\fe01"; }

.fad.fa-id-card:after, .fa-duotone.fa-id-card:after {
  content: "\f2c2\fe02"; }

.fad.fa-drivers-license:before, .fa-duotone.fa-drivers-license:before {
  content: "\f2c2\fe01"; }

.fad.fa-drivers-license:after, .fa-duotone.fa-drivers-license:after {
  content: "\f2c2\fe02"; }

.fad.fa-id-card-clip:before, .fa-duotone.fa-id-card-clip:before {
  content: "\f47f\fe01"; }

.fad.fa-id-card-clip:after, .fa-duotone.fa-id-card-clip:after {
  content: "\f47f\fe02"; }

.fad.fa-id-card-alt:before, .fa-duotone.fa-id-card-alt:before {
  content: "\f47f\fe01"; }

.fad.fa-id-card-alt:after, .fa-duotone.fa-id-card-alt:after {
  content: "\f47f\fe02"; }

.fad.fa-igloo:before, .fa-duotone.fa-igloo:before {
  content: "\f7ae\fe01"; }

.fad.fa-igloo:after, .fa-duotone.fa-igloo:after {
  content: "\f7ae\fe02"; }

.fad.fa-image:before, .fa-duotone.fa-image:before {
  content: "\f03e\fe01"; }

.fad.fa-image:after, .fa-duotone.fa-image:after {
  content: "\f03e\fe02"; }

.fad.fa-image-landscape:before, .fa-duotone.fa-image-landscape:before {
  content: "\e1b5\fe01"; }

.fad.fa-image-landscape:after, .fa-duotone.fa-image-landscape:after {
  content: "\e1b5\fe02"; }

.fad.fa-landscape:before, .fa-duotone.fa-landscape:before {
  content: "\e1b5\fe01"; }

.fad.fa-landscape:after, .fa-duotone.fa-landscape:after {
  content: "\e1b5\fe02"; }

.fad.fa-image-polaroid:before, .fa-duotone.fa-image-polaroid:before {
  content: "\f8c4\fe01"; }

.fad.fa-image-polaroid:after, .fa-duotone.fa-image-polaroid:after {
  content: "\f8c4\fe02"; }

.fad.fa-image-polaroid-user:before, .fa-duotone.fa-image-polaroid-user:before {
  content: "\e1b6\fe01"; }

.fad.fa-image-polaroid-user:after, .fa-duotone.fa-image-polaroid-user:after {
  content: "\e1b6\fe02"; }

.fad.fa-image-portrait:before, .fa-duotone.fa-image-portrait:before {
  content: "\f3e0\fe01"; }

.fad.fa-image-portrait:after, .fa-duotone.fa-image-portrait:after {
  content: "\f3e0\fe02"; }

.fad.fa-portrait:before, .fa-duotone.fa-portrait:before {
  content: "\f3e0\fe01"; }

.fad.fa-portrait:after, .fa-duotone.fa-portrait:after {
  content: "\f3e0\fe02"; }

.fad.fa-image-slash:before, .fa-duotone.fa-image-slash:before {
  content: "\e1b7\fe01"; }

.fad.fa-image-slash:after, .fa-duotone.fa-image-slash:after {
  content: "\e1b7\fe02"; }

.fad.fa-image-user:before, .fa-duotone.fa-image-user:before {
  content: "\e1b8\fe01"; }

.fad.fa-image-user:after, .fa-duotone.fa-image-user:after {
  content: "\e1b8\fe02"; }

.fad.fa-images:before, .fa-duotone.fa-images:before {
  content: "\f302\fe01"; }

.fad.fa-images:after, .fa-duotone.fa-images:after {
  content: "\f302\fe02"; }

.fad.fa-images-user:before, .fa-duotone.fa-images-user:before {
  content: "\e1b9\fe01"; }

.fad.fa-images-user:after, .fa-duotone.fa-images-user:after {
  content: "\e1b9\fe02"; }

.fad.fa-inbox:before, .fa-duotone.fa-inbox:before {
  content: "\f01c\fe01"; }

.fad.fa-inbox:after, .fa-duotone.fa-inbox:after {
  content: "\f01c\fe02"; }

.fad.fa-inbox-full:before, .fa-duotone.fa-inbox-full:before {
  content: "\e1ba\fe01"; }

.fad.fa-inbox-full:after, .fa-duotone.fa-inbox-full:after {
  content: "\e1ba\fe02"; }

.fad.fa-inbox-in:before, .fa-duotone.fa-inbox-in:before {
  content: "\f310"; }

.fad.fa-inbox-in:after, .fa-duotone.fa-inbox-in:after {
  content: "\10f310"; }

.fad.fa-inbox-arrow-down:before, .fa-duotone.fa-inbox-arrow-down:before {
  content: "\f310"; }

.fad.fa-inbox-arrow-down:after, .fa-duotone.fa-inbox-arrow-down:after {
  content: "\10f310"; }

.fad.fa-inbox-out:before, .fa-duotone.fa-inbox-out:before {
  content: "\f311"; }

.fad.fa-inbox-out:after, .fa-duotone.fa-inbox-out:after {
  content: "\10f311"; }

.fad.fa-inbox-arrow-up:before, .fa-duotone.fa-inbox-arrow-up:before {
  content: "\f311"; }

.fad.fa-inbox-arrow-up:after, .fa-duotone.fa-inbox-arrow-up:after {
  content: "\10f311"; }

.fad.fa-inboxes:before, .fa-duotone.fa-inboxes:before {
  content: "\e1bb\fe01"; }

.fad.fa-inboxes:after, .fa-duotone.fa-inboxes:after {
  content: "\e1bb\fe02"; }

.fad.fa-indent:before, .fa-duotone.fa-indent:before {
  content: "\f03c\fe01"; }

.fad.fa-indent:after, .fa-duotone.fa-indent:after {
  content: "\f03c\fe02"; }

.fad.fa-indian-rupee-sign:before, .fa-duotone.fa-indian-rupee-sign:before {
  content: "\e1bc\fe01"; }

.fad.fa-indian-rupee-sign:after, .fa-duotone.fa-indian-rupee-sign:after {
  content: "\e1bc\fe02"; }

.fad.fa-indian-rupee:before, .fa-duotone.fa-indian-rupee:before {
  content: "\e1bc\fe01"; }

.fad.fa-indian-rupee:after, .fa-duotone.fa-indian-rupee:after {
  content: "\e1bc\fe02"; }

.fad.fa-inr:before, .fa-duotone.fa-inr:before {
  content: "\e1bc\fe01"; }

.fad.fa-inr:after, .fa-duotone.fa-inr:after {
  content: "\e1bc\fe02"; }

.fad.fa-industry:before, .fa-duotone.fa-industry:before {
  content: "\f275\fe01"; }

.fad.fa-industry:after, .fa-duotone.fa-industry:after {
  content: "\f275\fe02"; }

.fad.fa-industry-windows:before, .fa-duotone.fa-industry-windows:before {
  content: "\f3b3"; }

.fad.fa-industry-windows:after, .fa-duotone.fa-industry-windows:after {
  content: "\10f3b3"; }

.fad.fa-industry-alt:before, .fa-duotone.fa-industry-alt:before {
  content: "\f3b3"; }

.fad.fa-industry-alt:after, .fa-duotone.fa-industry-alt:after {
  content: "\10f3b3"; }

.fad.fa-infinity:before, .fa-duotone.fa-infinity:before {
  content: "\221e\fe01"; }

.fad.fa-infinity:after, .fa-duotone.fa-infinity:after {
  content: "\221e\fe02"; }

.fad.fa-info:before, .fa-duotone.fa-info:before {
  content: "\f129\fe01"; }

.fad.fa-info:after, .fa-duotone.fa-info:after {
  content: "\f129\fe02"; }

.fad.fa-inhaler:before, .fa-duotone.fa-inhaler:before {
  content: "\f5f9\fe01"; }

.fad.fa-inhaler:after, .fa-duotone.fa-inhaler:after {
  content: "\f5f9\fe02"; }

.fad.fa-input-numeric:before, .fa-duotone.fa-input-numeric:before {
  content: "\e1bd\fe01"; }

.fad.fa-input-numeric:after, .fa-duotone.fa-input-numeric:after {
  content: "\e1bd\fe02"; }

.fad.fa-input-pipe:before, .fa-duotone.fa-input-pipe:before {
  content: "\e1be\fe01"; }

.fad.fa-input-pipe:after, .fa-duotone.fa-input-pipe:after {
  content: "\e1be\fe02"; }

.fad.fa-input-text:before, .fa-duotone.fa-input-text:before {
  content: "\e1bf\fe01"; }

.fad.fa-input-text:after, .fa-duotone.fa-input-text:after {
  content: "\e1bf\fe02"; }

.fad.fa-integral:before, .fa-duotone.fa-integral:before {
  content: "\f667\fe01"; }

.fad.fa-integral:after, .fa-duotone.fa-integral:after {
  content: "\f667\fe02"; }

.fad.fa-intersection:before, .fa-duotone.fa-intersection:before {
  content: "\22c2\fe01"; }

.fad.fa-intersection:after, .fa-duotone.fa-intersection:after {
  content: "\22c2\fe02"; }

.fad.fa-island-tropical:before, .fa-duotone.fa-island-tropical:before {
  content: "\f811"; }

.fad.fa-island-tropical:after, .fa-duotone.fa-island-tropical:after {
  content: "\10f811"; }

.fad.fa-island-tree-palm:before, .fa-duotone.fa-island-tree-palm:before {
  content: "\f811"; }

.fad.fa-island-tree-palm:after, .fa-duotone.fa-island-tree-palm:after {
  content: "\10f811"; }

.fad.fa-italic:before, .fa-duotone.fa-italic:before {
  content: "\f033\fe01"; }

.fad.fa-italic:after, .fa-duotone.fa-italic:after {
  content: "\f033\fe02"; }

.fad.fa-j:before, .fa-duotone.fa-j:before {
  content: "\4a\fe01"; }

.fad.fa-j:after, .fa-duotone.fa-j:after {
  content: "\4a\fe02"; }

.fad.fa-jack-o-lantern:before, .fa-duotone.fa-jack-o-lantern:before {
  content: "\f30e"; }

.fad.fa-jack-o-lantern:after, .fa-duotone.fa-jack-o-lantern:after {
  content: "\10f30e"; }

.fad.fa-jedi:before, .fa-duotone.fa-jedi:before {
  content: "\f669\fe01"; }

.fad.fa-jedi:after, .fa-duotone.fa-jedi:after {
  content: "\f669\fe02"; }

.fad.fa-jet-fighter:before, .fa-duotone.fa-jet-fighter:before {
  content: "\f0fb\fe01"; }

.fad.fa-jet-fighter:after, .fa-duotone.fa-jet-fighter:after {
  content: "\f0fb\fe02"; }

.fad.fa-fighter-jet:before, .fa-duotone.fa-fighter-jet:before {
  content: "\f0fb\fe01"; }

.fad.fa-fighter-jet:after, .fa-duotone.fa-fighter-jet:after {
  content: "\f0fb\fe02"; }

.fad.fa-joint:before, .fa-duotone.fa-joint:before {
  content: "\f595\fe01"; }

.fad.fa-joint:after, .fa-duotone.fa-joint:after {
  content: "\f595\fe02"; }

.fad.fa-joystick:before, .fa-duotone.fa-joystick:before {
  content: "\f8c5"; }

.fad.fa-joystick:after, .fa-duotone.fa-joystick:after {
  content: "\10f8c5"; }

.fad.fa-jug:before, .fa-duotone.fa-jug:before {
  content: "\f8c6\fe01"; }

.fad.fa-jug:after, .fa-duotone.fa-jug:after {
  content: "\f8c6\fe02"; }

.fad.fa-k:before, .fa-duotone.fa-k:before {
  content: "\4b\fe01"; }

.fad.fa-k:after, .fa-duotone.fa-k:after {
  content: "\4b\fe02"; }

.fad.fa-kaaba:before, .fa-duotone.fa-kaaba:before {
  content: "\f66b"; }

.fad.fa-kaaba:after, .fa-duotone.fa-kaaba:after {
  content: "\10f66b"; }

.fad.fa-kazoo:before, .fa-duotone.fa-kazoo:before {
  content: "\f8c7\fe01"; }

.fad.fa-kazoo:after, .fa-duotone.fa-kazoo:after {
  content: "\f8c7\fe02"; }

.fad.fa-kerning:before, .fa-duotone.fa-kerning:before {
  content: "\f86f\fe01"; }

.fad.fa-kerning:after, .fa-duotone.fa-kerning:after {
  content: "\f86f\fe02"; }

.fad.fa-key:before, .fa-duotone.fa-key:before {
  content: "\f084"; }

.fad.fa-key:after, .fa-duotone.fa-key:after {
  content: "\10f084"; }

.fad.fa-key-skeleton:before, .fa-duotone.fa-key-skeleton:before {
  content: "\f6f3"; }

.fad.fa-key-skeleton:after, .fa-duotone.fa-key-skeleton:after {
  content: "\10f6f3"; }

.fad.fa-key-skeleton-left-right:before, .fa-duotone.fa-key-skeleton-left-right:before {
  content: "\e3b4\fe01"; }

.fad.fa-key-skeleton-left-right:after, .fa-duotone.fa-key-skeleton-left-right:after {
  content: "\e3b4\fe02"; }

.fad.fa-keyboard:before, .fa-duotone.fa-keyboard:before {
  content: "\2328\fe01"; }

.fad.fa-keyboard:after, .fa-duotone.fa-keyboard:after {
  content: "\2328\fe02"; }

.fad.fa-keyboard-brightness:before, .fa-duotone.fa-keyboard-brightness:before {
  content: "\e1c0\fe01"; }

.fad.fa-keyboard-brightness:after, .fa-duotone.fa-keyboard-brightness:after {
  content: "\e1c0\fe02"; }

.fad.fa-keyboard-brightness-low:before, .fa-duotone.fa-keyboard-brightness-low:before {
  content: "\e1c1\fe01"; }

.fad.fa-keyboard-brightness-low:after, .fa-duotone.fa-keyboard-brightness-low:after {
  content: "\e1c1\fe02"; }

.fad.fa-keyboard-down:before, .fa-duotone.fa-keyboard-down:before {
  content: "\e1c2\fe01"; }

.fad.fa-keyboard-down:after, .fa-duotone.fa-keyboard-down:after {
  content: "\e1c2\fe02"; }

.fad.fa-keyboard-left:before, .fa-duotone.fa-keyboard-left:before {
  content: "\e1c3\fe01"; }

.fad.fa-keyboard-left:after, .fa-duotone.fa-keyboard-left:after {
  content: "\e1c3\fe02"; }

.fad.fa-keynote:before, .fa-duotone.fa-keynote:before {
  content: "\f66c\fe01"; }

.fad.fa-keynote:after, .fa-duotone.fa-keynote:after {
  content: "\f66c\fe02"; }

.fad.fa-khanda:before, .fa-duotone.fa-khanda:before {
  content: "\262c\fe01"; }

.fad.fa-khanda:after, .fa-duotone.fa-khanda:after {
  content: "\262c\fe02"; }

.fad.fa-kidneys:before, .fa-duotone.fa-kidneys:before {
  content: "\f5fb\fe01"; }

.fad.fa-kidneys:after, .fa-duotone.fa-kidneys:after {
  content: "\f5fb\fe02"; }

.fad.fa-kip-sign:before, .fa-duotone.fa-kip-sign:before {
  content: "\e1c4\fe01"; }

.fad.fa-kip-sign:after, .fa-duotone.fa-kip-sign:after {
  content: "\e1c4\fe02"; }

.fad.fa-kit-medical:before, .fa-duotone.fa-kit-medical:before {
  content: "\f479\fe01"; }

.fad.fa-kit-medical:after, .fa-duotone.fa-kit-medical:after {
  content: "\f479\fe02"; }

.fad.fa-first-aid:before, .fa-duotone.fa-first-aid:before {
  content: "\f479\fe01"; }

.fad.fa-first-aid:after, .fa-duotone.fa-first-aid:after {
  content: "\f479\fe02"; }

.fad.fa-kite:before, .fa-duotone.fa-kite:before {
  content: "\f6f4"; }

.fad.fa-kite:after, .fa-duotone.fa-kite:after {
  content: "\10f6f4"; }

.fad.fa-kiwi-bird:before, .fa-duotone.fa-kiwi-bird:before {
  content: "\f535\fe01"; }

.fad.fa-kiwi-bird:after, .fa-duotone.fa-kiwi-bird:after {
  content: "\f535\fe02"; }

.fad.fa-kiwi-fruit:before, .fa-duotone.fa-kiwi-fruit:before {
  content: "\e30c\fe01"; }

.fad.fa-kiwi-fruit:after, .fa-duotone.fa-kiwi-fruit:after {
  content: "\e30c\fe02"; }

.fad.fa-knife:before, .fa-duotone.fa-knife:before {
  content: "\f2e4\fe01"; }

.fad.fa-knife:after, .fa-duotone.fa-knife:after {
  content: "\f2e4\fe02"; }

.fad.fa-utensil-knife:before, .fa-duotone.fa-utensil-knife:before {
  content: "\f2e4\fe01"; }

.fad.fa-utensil-knife:after, .fa-duotone.fa-utensil-knife:after {
  content: "\f2e4\fe02"; }

.fad.fa-knife-kitchen:before, .fa-duotone.fa-knife-kitchen:before {
  content: "\f6f5"; }

.fad.fa-knife-kitchen:after, .fa-duotone.fa-knife-kitchen:after {
  content: "\10f6f5"; }

.fad.fa-l:before, .fa-duotone.fa-l:before {
  content: "\4c\fe01"; }

.fad.fa-l:after, .fa-duotone.fa-l:after {
  content: "\4c\fe02"; }

.fad.fa-lacrosse-stick:before, .fa-duotone.fa-lacrosse-stick:before {
  content: "\e3b5\fe01"; }

.fad.fa-lacrosse-stick:after, .fa-duotone.fa-lacrosse-stick:after {
  content: "\e3b5\fe02"; }

.fad.fa-lacrosse-stick-ball:before, .fa-duotone.fa-lacrosse-stick-ball:before {
  content: "\e3b6\fe01"; }

.fad.fa-lacrosse-stick-ball:after, .fa-duotone.fa-lacrosse-stick-ball:after {
  content: "\e3b6\fe02"; }

.fad.fa-lambda:before, .fa-duotone.fa-lambda:before {
  content: "\f66e\fe01"; }

.fad.fa-lambda:after, .fa-duotone.fa-lambda:after {
  content: "\f66e\fe02"; }

.fad.fa-lamp:before, .fa-duotone.fa-lamp:before {
  content: "\f4ca\fe01"; }

.fad.fa-lamp:after, .fa-duotone.fa-lamp:after {
  content: "\f4ca\fe02"; }

.fad.fa-lamp-desk:before, .fa-duotone.fa-lamp-desk:before {
  content: "\e014\fe01"; }

.fad.fa-lamp-desk:after, .fa-duotone.fa-lamp-desk:after {
  content: "\e014\fe02"; }

.fad.fa-lamp-floor:before, .fa-duotone.fa-lamp-floor:before {
  content: "\e015\fe01"; }

.fad.fa-lamp-floor:after, .fa-duotone.fa-lamp-floor:after {
  content: "\e015\fe02"; }

.fad.fa-lamp-street:before, .fa-duotone.fa-lamp-street:before {
  content: "\e1c5\fe01"; }

.fad.fa-lamp-street:after, .fa-duotone.fa-lamp-street:after {
  content: "\e1c5\fe02"; }

.fad.fa-landmark:before, .fa-duotone.fa-landmark:before {
  content: "\f66f"; }

.fad.fa-landmark:after, .fa-duotone.fa-landmark:after {
  content: "\10f66f"; }

.fad.fa-landmark-dome:before, .fa-duotone.fa-landmark-dome:before {
  content: "\f752\fe01"; }

.fad.fa-landmark-dome:after, .fa-duotone.fa-landmark-dome:after {
  content: "\f752\fe02"; }

.fad.fa-landmark-alt:before, .fa-duotone.fa-landmark-alt:before {
  content: "\f752\fe01"; }

.fad.fa-landmark-alt:after, .fa-duotone.fa-landmark-alt:after {
  content: "\f752\fe02"; }

.fad.fa-language:before, .fa-duotone.fa-language:before {
  content: "\f1ab\fe01"; }

.fad.fa-language:after, .fa-duotone.fa-language:after {
  content: "\f1ab\fe02"; }

.fad.fa-laptop:before, .fa-duotone.fa-laptop:before {
  content: "\f109"; }

.fad.fa-laptop:after, .fa-duotone.fa-laptop:after {
  content: "\10f109"; }

.fad.fa-laptop-arrow-down:before, .fa-duotone.fa-laptop-arrow-down:before {
  content: "\e1c6\fe01"; }

.fad.fa-laptop-arrow-down:after, .fa-duotone.fa-laptop-arrow-down:after {
  content: "\e1c6\fe02"; }

.fad.fa-laptop-code:before, .fa-duotone.fa-laptop-code:before {
  content: "\f5fc\fe01"; }

.fad.fa-laptop-code:after, .fa-duotone.fa-laptop-code:after {
  content: "\f5fc\fe02"; }

.fad.fa-laptop-medical:before, .fa-duotone.fa-laptop-medical:before {
  content: "\f812\fe01"; }

.fad.fa-laptop-medical:after, .fa-duotone.fa-laptop-medical:after {
  content: "\f812\fe02"; }

.fad.fa-laptop-mobile:before, .fa-duotone.fa-laptop-mobile:before {
  content: "\f87a\fe01"; }

.fad.fa-laptop-mobile:after, .fa-duotone.fa-laptop-mobile:after {
  content: "\f87a\fe02"; }

.fad.fa-phone-laptop:before, .fa-duotone.fa-phone-laptop:before {
  content: "\f87a\fe01"; }

.fad.fa-phone-laptop:after, .fa-duotone.fa-phone-laptop:after {
  content: "\f87a\fe02"; }

.fad.fa-laptop-slash:before, .fa-duotone.fa-laptop-slash:before {
  content: "\e1c7\fe01"; }

.fad.fa-laptop-slash:after, .fa-duotone.fa-laptop-slash:after {
  content: "\e1c7\fe02"; }

.fad.fa-lari-sign:before, .fa-duotone.fa-lari-sign:before {
  content: "\e1c8\fe01"; }

.fad.fa-lari-sign:after, .fa-duotone.fa-lari-sign:after {
  content: "\e1c8\fe02"; }

.fad.fa-lasso:before, .fa-duotone.fa-lasso:before {
  content: "\f8c8\fe01"; }

.fad.fa-lasso:after, .fa-duotone.fa-lasso:after {
  content: "\f8c8\fe02"; }

.fad.fa-lasso-sparkles:before, .fa-duotone.fa-lasso-sparkles:before {
  content: "\e1c9\fe01"; }

.fad.fa-lasso-sparkles:after, .fa-duotone.fa-lasso-sparkles:after {
  content: "\e1c9\fe02"; }

.fad.fa-layer-group:before, .fa-duotone.fa-layer-group:before {
  content: "\f5fd\fe01"; }

.fad.fa-layer-group:after, .fa-duotone.fa-layer-group:after {
  content: "\f5fd\fe02"; }

.fad.fa-layer-minus:before, .fa-duotone.fa-layer-minus:before {
  content: "\f5fe\fe01"; }

.fad.fa-layer-minus:after, .fa-duotone.fa-layer-minus:after {
  content: "\f5fe\fe02"; }

.fad.fa-layer-group-minus:before, .fa-duotone.fa-layer-group-minus:before {
  content: "\f5fe\fe01"; }

.fad.fa-layer-group-minus:after, .fa-duotone.fa-layer-group-minus:after {
  content: "\f5fe\fe02"; }

.fad.fa-layer-plus:before, .fa-duotone.fa-layer-plus:before {
  content: "\f5ff\fe01"; }

.fad.fa-layer-plus:after, .fa-duotone.fa-layer-plus:after {
  content: "\f5ff\fe02"; }

.fad.fa-layer-group-plus:before, .fa-duotone.fa-layer-group-plus:before {
  content: "\f5ff\fe01"; }

.fad.fa-layer-group-plus:after, .fa-duotone.fa-layer-group-plus:after {
  content: "\f5ff\fe02"; }

.fad.fa-leaf:before, .fa-duotone.fa-leaf:before {
  content: "\f06c\fe01"; }

.fad.fa-leaf:after, .fa-duotone.fa-leaf:after {
  content: "\f06c\fe02"; }

.fad.fa-leaf-heart:before, .fa-duotone.fa-leaf-heart:before {
  content: "\f4cb\fe01"; }

.fad.fa-leaf-heart:after, .fa-duotone.fa-leaf-heart:after {
  content: "\f4cb\fe02"; }

.fad.fa-leaf-maple:before, .fa-duotone.fa-leaf-maple:before {
  content: "\f6f6"; }

.fad.fa-leaf-maple:after, .fa-duotone.fa-leaf-maple:after {
  content: "\10f6f6"; }

.fad.fa-leaf-oak:before, .fa-duotone.fa-leaf-oak:before {
  content: "\f6f7\fe01"; }

.fad.fa-leaf-oak:after, .fa-duotone.fa-leaf-oak:after {
  content: "\f6f7\fe02"; }

.fad.fa-left:before, .fa-duotone.fa-left:before {
  content: "\f355\fe01"; }

.fad.fa-left:after, .fa-duotone.fa-left:after {
  content: "\f355\fe02"; }

.fad.fa-arrow-alt-left:before, .fa-duotone.fa-arrow-alt-left:before {
  content: "\f355\fe01"; }

.fad.fa-arrow-alt-left:after, .fa-duotone.fa-arrow-alt-left:after {
  content: "\f355\fe02"; }

.fad.fa-left-from-line:before, .fa-duotone.fa-left-from-line:before {
  content: "\f348\fe01"; }

.fad.fa-left-from-line:after, .fa-duotone.fa-left-from-line:after {
  content: "\f348\fe02"; }

.fad.fa-arrow-alt-from-right:before, .fa-duotone.fa-arrow-alt-from-right:before {
  content: "\f348\fe01"; }

.fad.fa-arrow-alt-from-right:after, .fa-duotone.fa-arrow-alt-from-right:after {
  content: "\f348\fe02"; }

.fad.fa-left-long:before, .fa-duotone.fa-left-long:before {
  content: "\f30a\fe01"; }

.fad.fa-left-long:after, .fa-duotone.fa-left-long:after {
  content: "\f30a\fe02"; }

.fad.fa-long-arrow-alt-left:before, .fa-duotone.fa-long-arrow-alt-left:before {
  content: "\f30a\fe01"; }

.fad.fa-long-arrow-alt-left:after, .fa-duotone.fa-long-arrow-alt-left:after {
  content: "\f30a\fe02"; }

.fad.fa-left-right:before, .fa-duotone.fa-left-right:before {
  content: "\2194\fe01"; }

.fad.fa-left-right:after, .fa-duotone.fa-left-right:after {
  content: "\2194\fe02"; }

.fad.fa-arrows-alt-h:before, .fa-duotone.fa-arrows-alt-h:before {
  content: "\2194\fe01"; }

.fad.fa-arrows-alt-h:after, .fa-duotone.fa-arrows-alt-h:after {
  content: "\2194\fe02"; }

.fad.fa-left-to-line:before, .fa-duotone.fa-left-to-line:before {
  content: "\f34b\fe01"; }

.fad.fa-left-to-line:after, .fa-duotone.fa-left-to-line:after {
  content: "\f34b\fe02"; }

.fad.fa-arrow-alt-to-left:before, .fa-duotone.fa-arrow-alt-to-left:before {
  content: "\f34b\fe01"; }

.fad.fa-arrow-alt-to-left:after, .fa-duotone.fa-arrow-alt-to-left:after {
  content: "\f34b\fe02"; }

.fad.fa-lemon:before, .fa-duotone.fa-lemon:before {
  content: "\f094"; }

.fad.fa-lemon:after, .fa-duotone.fa-lemon:after {
  content: "\10f094"; }

.fad.fa-less-than:before, .fa-duotone.fa-less-than:before {
  content: "\3c\fe01"; }

.fad.fa-less-than:after, .fa-duotone.fa-less-than:after {
  content: "\3c\fe02"; }

.fad.fa-less-than-equal:before, .fa-duotone.fa-less-than-equal:before {
  content: "\f537\fe01"; }

.fad.fa-less-than-equal:after, .fa-duotone.fa-less-than-equal:after {
  content: "\f537\fe02"; }

.fad.fa-life-ring:before, .fa-duotone.fa-life-ring:before {
  content: "\f1cd\fe01"; }

.fad.fa-life-ring:after, .fa-duotone.fa-life-ring:after {
  content: "\f1cd\fe02"; }

.fad.fa-light-ceiling:before, .fa-duotone.fa-light-ceiling:before {
  content: "\e016\fe01"; }

.fad.fa-light-ceiling:after, .fa-duotone.fa-light-ceiling:after {
  content: "\e016\fe02"; }

.fad.fa-light-switch:before, .fa-duotone.fa-light-switch:before {
  content: "\e017\fe01"; }

.fad.fa-light-switch:after, .fa-duotone.fa-light-switch:after {
  content: "\e017\fe02"; }

.fad.fa-light-switch-off:before, .fa-duotone.fa-light-switch-off:before {
  content: "\e018\fe01"; }

.fad.fa-light-switch-off:after, .fa-duotone.fa-light-switch-off:after {
  content: "\e018\fe02"; }

.fad.fa-light-switch-on:before, .fa-duotone.fa-light-switch-on:before {
  content: "\e019\fe01"; }

.fad.fa-light-switch-on:after, .fa-duotone.fa-light-switch-on:after {
  content: "\e019\fe02"; }

.fad.fa-lightbulb:before, .fa-duotone.fa-lightbulb:before {
  content: "\f0eb"; }

.fad.fa-lightbulb:after, .fa-duotone.fa-lightbulb:after {
  content: "\10f0eb"; }

.fad.fa-lightbulb-dollar:before, .fa-duotone.fa-lightbulb-dollar:before {
  content: "\f670\fe01"; }

.fad.fa-lightbulb-dollar:after, .fa-duotone.fa-lightbulb-dollar:after {
  content: "\f670\fe02"; }

.fad.fa-lightbulb-exclamation:before, .fa-duotone.fa-lightbulb-exclamation:before {
  content: "\f671\fe01"; }

.fad.fa-lightbulb-exclamation:after, .fa-duotone.fa-lightbulb-exclamation:after {
  content: "\f671\fe02"; }

.fad.fa-lightbulb-exclamation-on:before, .fa-duotone.fa-lightbulb-exclamation-on:before {
  content: "\e1ca\fe01"; }

.fad.fa-lightbulb-exclamation-on:after, .fa-duotone.fa-lightbulb-exclamation-on:after {
  content: "\e1ca\fe02"; }

.fad.fa-lightbulb-on:before, .fa-duotone.fa-lightbulb-on:before {
  content: "\f672\fe01"; }

.fad.fa-lightbulb-on:after, .fa-duotone.fa-lightbulb-on:after {
  content: "\f672\fe02"; }

.fad.fa-lightbulb-slash:before, .fa-duotone.fa-lightbulb-slash:before {
  content: "\f673\fe01"; }

.fad.fa-lightbulb-slash:after, .fa-duotone.fa-lightbulb-slash:after {
  content: "\f673\fe02"; }

.fad.fa-lights-holiday:before, .fa-duotone.fa-lights-holiday:before {
  content: "\f7b2\fe01"; }

.fad.fa-lights-holiday:after, .fa-duotone.fa-lights-holiday:after {
  content: "\f7b2\fe02"; }

.fad.fa-line-columns:before, .fa-duotone.fa-line-columns:before {
  content: "\f870\fe01"; }

.fad.fa-line-columns:after, .fa-duotone.fa-line-columns:after {
  content: "\f870\fe02"; }

.fad.fa-line-height:before, .fa-duotone.fa-line-height:before {
  content: "\f871\fe01"; }

.fad.fa-line-height:after, .fa-duotone.fa-line-height:after {
  content: "\f871\fe02"; }

.fad.fa-link:before, .fa-duotone.fa-link:before {
  content: "\f0c1"; }

.fad.fa-link:after, .fa-duotone.fa-link:after {
  content: "\10f0c1"; }

.fad.fa-chain:before, .fa-duotone.fa-chain:before {
  content: "\f0c1"; }

.fad.fa-chain:after, .fa-duotone.fa-chain:after {
  content: "\10f0c1"; }

.fad.fa-link-horizontal:before, .fa-duotone.fa-link-horizontal:before {
  content: "\e1cb\fe01"; }

.fad.fa-link-horizontal:after, .fa-duotone.fa-link-horizontal:after {
  content: "\e1cb\fe02"; }

.fad.fa-chain-horizontal:before, .fa-duotone.fa-chain-horizontal:before {
  content: "\e1cb\fe01"; }

.fad.fa-chain-horizontal:after, .fa-duotone.fa-chain-horizontal:after {
  content: "\e1cb\fe02"; }

.fad.fa-link-horizontal-slash:before, .fa-duotone.fa-link-horizontal-slash:before {
  content: "\e1cc\fe01"; }

.fad.fa-link-horizontal-slash:after, .fa-duotone.fa-link-horizontal-slash:after {
  content: "\e1cc\fe02"; }

.fad.fa-chain-horizontal-slash:before, .fa-duotone.fa-chain-horizontal-slash:before {
  content: "\e1cc\fe01"; }

.fad.fa-chain-horizontal-slash:after, .fa-duotone.fa-chain-horizontal-slash:after {
  content: "\e1cc\fe02"; }

.fad.fa-link-simple:before, .fa-duotone.fa-link-simple:before {
  content: "\e1cd\fe01"; }

.fad.fa-link-simple:after, .fa-duotone.fa-link-simple:after {
  content: "\e1cd\fe02"; }

.fad.fa-link-simple-slash:before, .fa-duotone.fa-link-simple-slash:before {
  content: "\e1ce\fe01"; }

.fad.fa-link-simple-slash:after, .fa-duotone.fa-link-simple-slash:after {
  content: "\e1ce\fe02"; }

.fad.fa-link-slash:before, .fa-duotone.fa-link-slash:before {
  content: "\f127\fe01"; }

.fad.fa-link-slash:after, .fa-duotone.fa-link-slash:after {
  content: "\f127\fe02"; }

.fad.fa-chain-broken:before, .fa-duotone.fa-chain-broken:before {
  content: "\f127\fe01"; }

.fad.fa-chain-broken:after, .fa-duotone.fa-chain-broken:after {
  content: "\f127\fe02"; }

.fad.fa-chain-slash:before, .fa-duotone.fa-chain-slash:before {
  content: "\f127\fe01"; }

.fad.fa-chain-slash:after, .fa-duotone.fa-chain-slash:after {
  content: "\f127\fe02"; }

.fad.fa-unlink:before, .fa-duotone.fa-unlink:before {
  content: "\f127\fe01"; }

.fad.fa-unlink:after, .fa-duotone.fa-unlink:after {
  content: "\f127\fe02"; }

.fad.fa-lips:before, .fa-duotone.fa-lips:before {
  content: "\f600"; }

.fad.fa-lips:after, .fa-duotone.fa-lips:after {
  content: "\10f600"; }

.fad.fa-lira-sign:before, .fa-duotone.fa-lira-sign:before {
  content: "\20a4\fe01"; }

.fad.fa-lira-sign:after, .fa-duotone.fa-lira-sign:after {
  content: "\20a4\fe02"; }

.fad.fa-list:before, .fa-duotone.fa-list:before {
  content: "\f03a\fe01"; }

.fad.fa-list:after, .fa-duotone.fa-list:after {
  content: "\f03a\fe02"; }

.fad.fa-list-squares:before, .fa-duotone.fa-list-squares:before {
  content: "\f03a\fe01"; }

.fad.fa-list-squares:after, .fa-duotone.fa-list-squares:after {
  content: "\f03a\fe02"; }

.fad.fa-list-check:before, .fa-duotone.fa-list-check:before {
  content: "\f0ae\fe01"; }

.fad.fa-list-check:after, .fa-duotone.fa-list-check:after {
  content: "\f0ae\fe02"; }

.fad.fa-tasks:before, .fa-duotone.fa-tasks:before {
  content: "\f0ae\fe01"; }

.fad.fa-tasks:after, .fa-duotone.fa-tasks:after {
  content: "\f0ae\fe02"; }

.fad.fa-list-dropdown:before, .fa-duotone.fa-list-dropdown:before {
  content: "\e1cf\fe01"; }

.fad.fa-list-dropdown:after, .fa-duotone.fa-list-dropdown:after {
  content: "\e1cf\fe02"; }

.fad.fa-list-music:before, .fa-duotone.fa-list-music:before {
  content: "\f8c9\fe01"; }

.fad.fa-list-music:after, .fa-duotone.fa-list-music:after {
  content: "\f8c9\fe02"; }

.fad.fa-list-ol:before, .fa-duotone.fa-list-ol:before {
  content: "\f0cb\fe01"; }

.fad.fa-list-ol:after, .fa-duotone.fa-list-ol:after {
  content: "\f0cb\fe02"; }

.fad.fa-list-1-2:before, .fa-duotone.fa-list-1-2:before {
  content: "\f0cb\fe01"; }

.fad.fa-list-1-2:after, .fa-duotone.fa-list-1-2:after {
  content: "\f0cb\fe02"; }

.fad.fa-list-numeric:before, .fa-duotone.fa-list-numeric:before {
  content: "\f0cb\fe01"; }

.fad.fa-list-numeric:after, .fa-duotone.fa-list-numeric:after {
  content: "\f0cb\fe02"; }

.fad.fa-list-radio:before, .fa-duotone.fa-list-radio:before {
  content: "\e1d0\fe01"; }

.fad.fa-list-radio:after, .fa-duotone.fa-list-radio:after {
  content: "\e1d0\fe02"; }

.fad.fa-list-timeline:before, .fa-duotone.fa-list-timeline:before {
  content: "\e1d1\fe01"; }

.fad.fa-list-timeline:after, .fa-duotone.fa-list-timeline:after {
  content: "\e1d1\fe02"; }

.fad.fa-list-tree:before, .fa-duotone.fa-list-tree:before {
  content: "\e1d2\fe01"; }

.fad.fa-list-tree:after, .fa-duotone.fa-list-tree:after {
  content: "\e1d2\fe02"; }

.fad.fa-list-ul:before, .fa-duotone.fa-list-ul:before {
  content: "\f0ca\fe01"; }

.fad.fa-list-ul:after, .fa-duotone.fa-list-ul:after {
  content: "\f0ca\fe02"; }

.fad.fa-list-dots:before, .fa-duotone.fa-list-dots:before {
  content: "\f0ca\fe01"; }

.fad.fa-list-dots:after, .fa-duotone.fa-list-dots:after {
  content: "\f0ca\fe02"; }

.fad.fa-litecoin-sign:before, .fa-duotone.fa-litecoin-sign:before {
  content: "\e1d3\fe01"; }

.fad.fa-litecoin-sign:after, .fa-duotone.fa-litecoin-sign:after {
  content: "\e1d3\fe02"; }

.fad.fa-loader:before, .fa-duotone.fa-loader:before {
  content: "\e1d4\fe01"; }

.fad.fa-loader:after, .fa-duotone.fa-loader:after {
  content: "\e1d4\fe02"; }

.fad.fa-location:before, .fa-duotone.fa-location:before {
  content: "\f041\fe01"; }

.fad.fa-location:after, .fa-duotone.fa-location:after {
  content: "\f041\fe02"; }

.fad.fa-map-marker:before, .fa-duotone.fa-map-marker:before {
  content: "\f041\fe01"; }

.fad.fa-map-marker:after, .fa-duotone.fa-map-marker:after {
  content: "\f041\fe02"; }

.fad.fa-location-arrow:before, .fa-duotone.fa-location-arrow:before {
  content: "\f124\fe01"; }

.fad.fa-location-arrow:after, .fa-duotone.fa-location-arrow:after {
  content: "\f124\fe02"; }

.fad.fa-location-check:before, .fa-duotone.fa-location-check:before {
  content: "\f606\fe01"; }

.fad.fa-location-check:after, .fa-duotone.fa-location-check:after {
  content: "\f606\fe02"; }

.fad.fa-map-marker-check:before, .fa-duotone.fa-map-marker-check:before {
  content: "\f606\fe01"; }

.fad.fa-map-marker-check:after, .fa-duotone.fa-map-marker-check:after {
  content: "\f606\fe02"; }

.fad.fa-location-crosshairs:before, .fa-duotone.fa-location-crosshairs:before {
  content: "\f601\fe01"; }

.fad.fa-location-crosshairs:after, .fa-duotone.fa-location-crosshairs:after {
  content: "\f601\fe02"; }

.fad.fa-location-crosshairs-slash:before, .fa-duotone.fa-location-crosshairs-slash:before {
  content: "\f603\fe01"; }

.fad.fa-location-crosshairs-slash:after, .fa-duotone.fa-location-crosshairs-slash:after {
  content: "\f603\fe02"; }

.fad.fa-location-dot:before, .fa-duotone.fa-location-dot:before {
  content: "\f3c5\fe01"; }

.fad.fa-location-dot:after, .fa-duotone.fa-location-dot:after {
  content: "\f3c5\fe02"; }

.fad.fa-map-marker-alt:before, .fa-duotone.fa-map-marker-alt:before {
  content: "\f3c5\fe01"; }

.fad.fa-map-marker-alt:after, .fa-duotone.fa-map-marker-alt:after {
  content: "\f3c5\fe02"; }

.fad.fa-location-dot-slash:before, .fa-duotone.fa-location-dot-slash:before {
  content: "\f605\fe01"; }

.fad.fa-location-dot-slash:after, .fa-duotone.fa-location-dot-slash:after {
  content: "\f605\fe02"; }

.fad.fa-map-marker-alt-slash:before, .fa-duotone.fa-map-marker-alt-slash:before {
  content: "\f605\fe01"; }

.fad.fa-map-marker-alt-slash:after, .fa-duotone.fa-map-marker-alt-slash:after {
  content: "\f605\fe02"; }

.fad.fa-location-exclamation:before, .fa-duotone.fa-location-exclamation:before {
  content: "\f608\fe01"; }

.fad.fa-location-exclamation:after, .fa-duotone.fa-location-exclamation:after {
  content: "\f608\fe02"; }

.fad.fa-map-marker-exclamation:before, .fa-duotone.fa-map-marker-exclamation:before {
  content: "\f608\fe01"; }

.fad.fa-map-marker-exclamation:after, .fa-duotone.fa-map-marker-exclamation:after {
  content: "\f608\fe02"; }

.fad.fa-location-minus:before, .fa-duotone.fa-location-minus:before {
  content: "\f609\fe01"; }

.fad.fa-location-minus:after, .fa-duotone.fa-location-minus:after {
  content: "\f609\fe02"; }

.fad.fa-map-marker-minus:before, .fa-duotone.fa-map-marker-minus:before {
  content: "\f609\fe01"; }

.fad.fa-map-marker-minus:after, .fa-duotone.fa-map-marker-minus:after {
  content: "\f609\fe02"; }

.fad.fa-location-pen:before, .fa-duotone.fa-location-pen:before {
  content: "\f607\fe01"; }

.fad.fa-location-pen:after, .fa-duotone.fa-location-pen:after {
  content: "\f607\fe02"; }

.fad.fa-map-marker-edit:before, .fa-duotone.fa-map-marker-edit:before {
  content: "\f607\fe01"; }

.fad.fa-map-marker-edit:after, .fa-duotone.fa-map-marker-edit:after {
  content: "\f607\fe02"; }

.fad.fa-location-plus:before, .fa-duotone.fa-location-plus:before {
  content: "\f60a\fe01"; }

.fad.fa-location-plus:after, .fa-duotone.fa-location-plus:after {
  content: "\f60a\fe02"; }

.fad.fa-map-marker-plus:before, .fa-duotone.fa-map-marker-plus:before {
  content: "\f60a\fe01"; }

.fad.fa-map-marker-plus:after, .fa-duotone.fa-map-marker-plus:after {
  content: "\f60a\fe02"; }

.fad.fa-location-question:before, .fa-duotone.fa-location-question:before {
  content: "\f60b\fe01"; }

.fad.fa-location-question:after, .fa-duotone.fa-location-question:after {
  content: "\f60b\fe02"; }

.fad.fa-map-marker-question:before, .fa-duotone.fa-map-marker-question:before {
  content: "\f60b\fe01"; }

.fad.fa-map-marker-question:after, .fa-duotone.fa-map-marker-question:after {
  content: "\f60b\fe02"; }

.fad.fa-location-slash:before, .fa-duotone.fa-location-slash:before {
  content: "\f60c\fe01"; }

.fad.fa-location-slash:after, .fa-duotone.fa-location-slash:after {
  content: "\f60c\fe02"; }

.fad.fa-map-marker-slash:before, .fa-duotone.fa-map-marker-slash:before {
  content: "\f60c\fe01"; }

.fad.fa-map-marker-slash:after, .fa-duotone.fa-map-marker-slash:after {
  content: "\f60c\fe02"; }

.fad.fa-location-smile:before, .fa-duotone.fa-location-smile:before {
  content: "\f60d\fe01"; }

.fad.fa-location-smile:after, .fa-duotone.fa-location-smile:after {
  content: "\f60d\fe02"; }

.fad.fa-map-marker-smile:before, .fa-duotone.fa-map-marker-smile:before {
  content: "\f60d\fe01"; }

.fad.fa-map-marker-smile:after, .fa-duotone.fa-map-marker-smile:after {
  content: "\f60d\fe02"; }

.fad.fa-location-xmark:before, .fa-duotone.fa-location-xmark:before {
  content: "\f60e\fe01"; }

.fad.fa-location-xmark:after, .fa-duotone.fa-location-xmark:after {
  content: "\f60e\fe02"; }

.fad.fa-map-marker-times:before, .fa-duotone.fa-map-marker-times:before {
  content: "\f60e\fe01"; }

.fad.fa-map-marker-times:after, .fa-duotone.fa-map-marker-times:after {
  content: "\f60e\fe02"; }

.fad.fa-map-marker-xmark:before, .fa-duotone.fa-map-marker-xmark:before {
  content: "\f60e\fe01"; }

.fad.fa-map-marker-xmark:after, .fa-duotone.fa-map-marker-xmark:after {
  content: "\f60e\fe02"; }

.fad.fa-lock:before, .fa-duotone.fa-lock:before {
  content: "\f023"; }

.fad.fa-lock:after, .fa-duotone.fa-lock:after {
  content: "\10f023"; }

.fad.fa-lock-keyhole:before, .fa-duotone.fa-lock-keyhole:before {
  content: "\f30d\fe01"; }

.fad.fa-lock-keyhole:after, .fa-duotone.fa-lock-keyhole:after {
  content: "\f30d\fe02"; }

.fad.fa-lock-alt:before, .fa-duotone.fa-lock-alt:before {
  content: "\f30d\fe01"; }

.fad.fa-lock-alt:after, .fa-duotone.fa-lock-alt:after {
  content: "\f30d\fe02"; }

.fad.fa-lock-keyhole-open:before, .fa-duotone.fa-lock-keyhole-open:before {
  content: "\f3c2\fe01"; }

.fad.fa-lock-keyhole-open:after, .fa-duotone.fa-lock-keyhole-open:after {
  content: "\f3c2\fe02"; }

.fad.fa-lock-open-alt:before, .fa-duotone.fa-lock-open-alt:before {
  content: "\f3c2\fe01"; }

.fad.fa-lock-open-alt:after, .fa-duotone.fa-lock-open-alt:after {
  content: "\f3c2\fe02"; }

.fad.fa-lock-open:before, .fa-duotone.fa-lock-open:before {
  content: "\f3c1\fe01"; }

.fad.fa-lock-open:after, .fa-duotone.fa-lock-open:after {
  content: "\f3c1\fe02"; }

.fad.fa-loveseat:before, .fa-duotone.fa-loveseat:before {
  content: "\f4cc\fe01"; }

.fad.fa-loveseat:after, .fa-duotone.fa-loveseat:after {
  content: "\f4cc\fe02"; }

.fad.fa-couch-small:before, .fa-duotone.fa-couch-small:before {
  content: "\f4cc\fe01"; }

.fad.fa-couch-small:after, .fa-duotone.fa-couch-small:after {
  content: "\f4cc\fe02"; }

.fad.fa-luchador-mask:before, .fa-duotone.fa-luchador-mask:before {
  content: "\f455\fe01"; }

.fad.fa-luchador-mask:after, .fa-duotone.fa-luchador-mask:after {
  content: "\f455\fe02"; }

.fad.fa-luchador:before, .fa-duotone.fa-luchador:before {
  content: "\f455\fe01"; }

.fad.fa-luchador:after, .fa-duotone.fa-luchador:after {
  content: "\f455\fe02"; }

.fad.fa-mask-luchador:before, .fa-duotone.fa-mask-luchador:before {
  content: "\f455\fe01"; }

.fad.fa-mask-luchador:after, .fa-duotone.fa-mask-luchador:after {
  content: "\f455\fe02"; }

.fad.fa-lungs:before, .fa-duotone.fa-lungs:before {
  content: "\f604"; }

.fad.fa-lungs:after, .fa-duotone.fa-lungs:after {
  content: "\10f604"; }

.fad.fa-lungs-virus:before, .fa-duotone.fa-lungs-virus:before {
  content: "\e067\fe01"; }

.fad.fa-lungs-virus:after, .fa-duotone.fa-lungs-virus:after {
  content: "\e067\fe02"; }

.fad.fa-m:before, .fa-duotone.fa-m:before {
  content: "\4d\fe01"; }

.fad.fa-m:after, .fa-duotone.fa-m:after {
  content: "\4d\fe02"; }

.fad.fa-mace:before, .fa-duotone.fa-mace:before {
  content: "\f6f8\fe01"; }

.fad.fa-mace:after, .fa-duotone.fa-mace:after {
  content: "\f6f8\fe02"; }

.fad.fa-magnet:before, .fa-duotone.fa-magnet:before {
  content: "\f076"; }

.fad.fa-magnet:after, .fa-duotone.fa-magnet:after {
  content: "\10f076"; }

.fad.fa-magnifying-glass:before, .fa-duotone.fa-magnifying-glass:before {
  content: "\f002"; }

.fad.fa-magnifying-glass:after, .fa-duotone.fa-magnifying-glass:after {
  content: "\10f002"; }

.fad.fa-search:before, .fa-duotone.fa-search:before {
  content: "\f002"; }

.fad.fa-search:after, .fa-duotone.fa-search:after {
  content: "\10f002"; }

.fad.fa-magnifying-glass-dollar:before, .fa-duotone.fa-magnifying-glass-dollar:before {
  content: "\f688\fe01"; }

.fad.fa-magnifying-glass-dollar:after, .fa-duotone.fa-magnifying-glass-dollar:after {
  content: "\f688\fe02"; }

.fad.fa-search-dollar:before, .fa-duotone.fa-search-dollar:before {
  content: "\f688\fe01"; }

.fad.fa-search-dollar:after, .fa-duotone.fa-search-dollar:after {
  content: "\f688\fe02"; }

.fad.fa-magnifying-glass-location:before, .fa-duotone.fa-magnifying-glass-location:before {
  content: "\f689\fe01"; }

.fad.fa-magnifying-glass-location:after, .fa-duotone.fa-magnifying-glass-location:after {
  content: "\f689\fe02"; }

.fad.fa-search-location:before, .fa-duotone.fa-search-location:before {
  content: "\f689\fe01"; }

.fad.fa-search-location:after, .fa-duotone.fa-search-location:after {
  content: "\f689\fe02"; }

.fad.fa-magnifying-glass-minus:before, .fa-duotone.fa-magnifying-glass-minus:before {
  content: "\f010\fe01"; }

.fad.fa-magnifying-glass-minus:after, .fa-duotone.fa-magnifying-glass-minus:after {
  content: "\f010\fe02"; }

.fad.fa-search-minus:before, .fa-duotone.fa-search-minus:before {
  content: "\f010\fe01"; }

.fad.fa-search-minus:after, .fa-duotone.fa-search-minus:after {
  content: "\f010\fe02"; }

.fad.fa-magnifying-glass-plus:before, .fa-duotone.fa-magnifying-glass-plus:before {
  content: "\f00e\fe01"; }

.fad.fa-magnifying-glass-plus:after, .fa-duotone.fa-magnifying-glass-plus:after {
  content: "\f00e\fe02"; }

.fad.fa-search-plus:before, .fa-duotone.fa-search-plus:before {
  content: "\f00e\fe01"; }

.fad.fa-search-plus:after, .fa-duotone.fa-search-plus:after {
  content: "\f00e\fe02"; }

.fad.fa-mailbox:before, .fa-duotone.fa-mailbox:before {
  content: "\f813"; }

.fad.fa-mailbox:after, .fa-duotone.fa-mailbox:after {
  content: "\10f813"; }

.fad.fa-manat-sign:before, .fa-duotone.fa-manat-sign:before {
  content: "\e1d5\fe01"; }

.fad.fa-manat-sign:after, .fa-duotone.fa-manat-sign:after {
  content: "\e1d5\fe02"; }

.fad.fa-mandolin:before, .fa-duotone.fa-mandolin:before {
  content: "\f6f9\fe01"; }

.fad.fa-mandolin:after, .fa-duotone.fa-mandolin:after {
  content: "\f6f9\fe02"; }

.fad.fa-mango:before, .fa-duotone.fa-mango:before {
  content: "\e30f\fe01"; }

.fad.fa-mango:after, .fa-duotone.fa-mango:after {
  content: "\e30f\fe02"; }

.fad.fa-manhole:before, .fa-duotone.fa-manhole:before {
  content: "\e1d6\fe01"; }

.fad.fa-manhole:after, .fa-duotone.fa-manhole:after {
  content: "\e1d6\fe02"; }

.fad.fa-map:before, .fa-duotone.fa-map:before {
  content: "\f279"; }

.fad.fa-map:after, .fa-duotone.fa-map:after {
  content: "\10f279"; }

.fad.fa-map-location:before, .fa-duotone.fa-map-location:before {
  content: "\f59f\fe01"; }

.fad.fa-map-location:after, .fa-duotone.fa-map-location:after {
  content: "\f59f\fe02"; }

.fad.fa-map-marked:before, .fa-duotone.fa-map-marked:before {
  content: "\f59f\fe01"; }

.fad.fa-map-marked:after, .fa-duotone.fa-map-marked:after {
  content: "\f59f\fe02"; }

.fad.fa-map-location-dot:before, .fa-duotone.fa-map-location-dot:before {
  content: "\f5a0\fe01"; }

.fad.fa-map-location-dot:after, .fa-duotone.fa-map-location-dot:after {
  content: "\f5a0\fe02"; }

.fad.fa-map-marked-alt:before, .fa-duotone.fa-map-marked-alt:before {
  content: "\f5a0\fe01"; }

.fad.fa-map-marked-alt:after, .fa-duotone.fa-map-marked-alt:after {
  content: "\f5a0\fe02"; }

.fad.fa-map-pin:before, .fa-duotone.fa-map-pin:before {
  content: "\f276"; }

.fad.fa-map-pin:after, .fa-duotone.fa-map-pin:after {
  content: "\10f276"; }

.fad.fa-marker:before, .fa-duotone.fa-marker:before {
  content: "\f5a1\fe01"; }

.fad.fa-marker:after, .fa-duotone.fa-marker:after {
  content: "\f5a1\fe02"; }

.fad.fa-mars:before, .fa-duotone.fa-mars:before {
  content: "\2642\fe01"; }

.fad.fa-mars:after, .fa-duotone.fa-mars:after {
  content: "\2642\fe02"; }

.fad.fa-mars-and-venus:before, .fa-duotone.fa-mars-and-venus:before {
  content: "\26a5\fe01"; }

.fad.fa-mars-and-venus:after, .fa-duotone.fa-mars-and-venus:after {
  content: "\26a5\fe02"; }

.fad.fa-mars-double:before, .fa-duotone.fa-mars-double:before {
  content: "\26a3\fe01"; }

.fad.fa-mars-double:after, .fa-duotone.fa-mars-double:after {
  content: "\26a3\fe02"; }

.fad.fa-mars-stroke:before, .fa-duotone.fa-mars-stroke:before {
  content: "\26a6\fe01"; }

.fad.fa-mars-stroke:after, .fa-duotone.fa-mars-stroke:after {
  content: "\26a6\fe02"; }

.fad.fa-mars-stroke-right:before, .fa-duotone.fa-mars-stroke-right:before {
  content: "\26a9\fe01"; }

.fad.fa-mars-stroke-right:after, .fa-duotone.fa-mars-stroke-right:after {
  content: "\26a9\fe02"; }

.fad.fa-mars-stroke-h:before, .fa-duotone.fa-mars-stroke-h:before {
  content: "\26a9\fe01"; }

.fad.fa-mars-stroke-h:after, .fa-duotone.fa-mars-stroke-h:after {
  content: "\26a9\fe02"; }

.fad.fa-mars-stroke-up:before, .fa-duotone.fa-mars-stroke-up:before {
  content: "\26a8\fe01"; }

.fad.fa-mars-stroke-up:after, .fa-duotone.fa-mars-stroke-up:after {
  content: "\26a8\fe02"; }

.fad.fa-mars-stroke-v:before, .fa-duotone.fa-mars-stroke-v:before {
  content: "\26a8\fe01"; }

.fad.fa-mars-stroke-v:after, .fa-duotone.fa-mars-stroke-v:after {
  content: "\26a8\fe02"; }

.fad.fa-martini-glass:before, .fa-duotone.fa-martini-glass:before {
  content: "\f57b"; }

.fad.fa-martini-glass:after, .fa-duotone.fa-martini-glass:after {
  content: "\10f57b"; }

.fad.fa-glass-martini-alt:before, .fa-duotone.fa-glass-martini-alt:before {
  content: "\f57b"; }

.fad.fa-glass-martini-alt:after, .fa-duotone.fa-glass-martini-alt:after {
  content: "\10f57b"; }

.fad.fa-martini-glass-citrus:before, .fa-duotone.fa-martini-glass-citrus:before {
  content: "\f561\fe01"; }

.fad.fa-martini-glass-citrus:after, .fa-duotone.fa-martini-glass-citrus:after {
  content: "\f561\fe02"; }

.fad.fa-cocktail:before, .fa-duotone.fa-cocktail:before {
  content: "\f561\fe01"; }

.fad.fa-cocktail:after, .fa-duotone.fa-cocktail:after {
  content: "\f561\fe02"; }

.fad.fa-martini-glass-empty:before, .fa-duotone.fa-martini-glass-empty:before {
  content: "\f000\fe01"; }

.fad.fa-martini-glass-empty:after, .fa-duotone.fa-martini-glass-empty:after {
  content: "\f000\fe02"; }

.fad.fa-glass-martini:before, .fa-duotone.fa-glass-martini:before {
  content: "\f000\fe01"; }

.fad.fa-glass-martini:after, .fa-duotone.fa-glass-martini:after {
  content: "\f000\fe02"; }

.fad.fa-mask:before, .fa-duotone.fa-mask:before {
  content: "\f6fa\fe01"; }

.fad.fa-mask:after, .fa-duotone.fa-mask:after {
  content: "\f6fa\fe02"; }

.fad.fa-mask-face:before, .fa-duotone.fa-mask-face:before {
  content: "\e1d7\fe01"; }

.fad.fa-mask-face:after, .fa-duotone.fa-mask-face:after {
  content: "\e1d7\fe02"; }

.fad.fa-mask-snorkel:before, .fa-duotone.fa-mask-snorkel:before {
  content: "\e3b7\fe01"; }

.fad.fa-mask-snorkel:after, .fa-duotone.fa-mask-snorkel:after {
  content: "\e3b7\fe02"; }

.fad.fa-masks-theater:before, .fa-duotone.fa-masks-theater:before {
  content: "\f630"; }

.fad.fa-masks-theater:after, .fa-duotone.fa-masks-theater:after {
  content: "\10f630"; }

.fad.fa-theater-masks:before, .fa-duotone.fa-theater-masks:before {
  content: "\f630"; }

.fad.fa-theater-masks:after, .fa-duotone.fa-theater-masks:after {
  content: "\10f630"; }

.fad.fa-maximize:before, .fa-duotone.fa-maximize:before {
  content: "\f31e\fe01"; }

.fad.fa-maximize:after, .fa-duotone.fa-maximize:after {
  content: "\f31e\fe02"; }

.fad.fa-expand-arrows-alt:before, .fa-duotone.fa-expand-arrows-alt:before {
  content: "\f31e\fe01"; }

.fad.fa-expand-arrows-alt:after, .fa-duotone.fa-expand-arrows-alt:after {
  content: "\f31e\fe02"; }

.fad.fa-meat:before, .fa-duotone.fa-meat:before {
  content: "\f814"; }

.fad.fa-meat:after, .fa-duotone.fa-meat:after {
  content: "\10f814"; }

.fad.fa-medal:before, .fa-duotone.fa-medal:before {
  content: "\f5a2"; }

.fad.fa-medal:after, .fa-duotone.fa-medal:after {
  content: "\10f5a2"; }

.fad.fa-megaphone:before, .fa-duotone.fa-megaphone:before {
  content: "\f675"; }

.fad.fa-megaphone:after, .fa-duotone.fa-megaphone:after {
  content: "\10f675"; }

.fad.fa-melon:before, .fa-duotone.fa-melon:before {
  content: "\e310\fe01"; }

.fad.fa-melon:after, .fa-duotone.fa-melon:after {
  content: "\e310\fe02"; }

.fad.fa-melon-slice:before, .fa-duotone.fa-melon-slice:before {
  content: "\e311\fe01"; }

.fad.fa-melon-slice:after, .fa-duotone.fa-melon-slice:after {
  content: "\e311\fe02"; }

.fad.fa-memo:before, .fa-duotone.fa-memo:before {
  content: "\e1d8\fe01"; }

.fad.fa-memo:after, .fa-duotone.fa-memo:after {
  content: "\e1d8\fe02"; }

.fad.fa-memo-circle-check:before, .fa-duotone.fa-memo-circle-check:before {
  content: "\e1d9\fe01"; }

.fad.fa-memo-circle-check:after, .fa-duotone.fa-memo-circle-check:after {
  content: "\e1d9\fe02"; }

.fad.fa-memo-pad:before, .fa-duotone.fa-memo-pad:before {
  content: "\e1da\fe01"; }

.fad.fa-memo-pad:after, .fa-duotone.fa-memo-pad:after {
  content: "\e1da\fe02"; }

.fad.fa-memory:before, .fa-duotone.fa-memory:before {
  content: "\f538\fe01"; }

.fad.fa-memory:after, .fa-duotone.fa-memory:after {
  content: "\f538\fe02"; }

.fad.fa-menorah:before, .fa-duotone.fa-menorah:before {
  content: "\f676\fe01"; }

.fad.fa-menorah:after, .fa-duotone.fa-menorah:after {
  content: "\f676\fe02"; }

.fad.fa-mercury:before, .fa-duotone.fa-mercury:before {
  content: "\263f\fe01"; }

.fad.fa-mercury:after, .fa-duotone.fa-mercury:after {
  content: "\263f\fe02"; }

.fad.fa-message:before, .fa-duotone.fa-message:before {
  content: "\f27a\fe01"; }

.fad.fa-message:after, .fa-duotone.fa-message:after {
  content: "\f27a\fe02"; }

.fad.fa-comment-alt:before, .fa-duotone.fa-comment-alt:before {
  content: "\f27a\fe01"; }

.fad.fa-comment-alt:after, .fa-duotone.fa-comment-alt:after {
  content: "\f27a\fe02"; }

.fad.fa-message-arrow-down:before, .fa-duotone.fa-message-arrow-down:before {
  content: "\e1db\fe01"; }

.fad.fa-message-arrow-down:after, .fa-duotone.fa-message-arrow-down:after {
  content: "\e1db\fe02"; }

.fad.fa-comment-alt-arrow-down:before, .fa-duotone.fa-comment-alt-arrow-down:before {
  content: "\e1db\fe01"; }

.fad.fa-comment-alt-arrow-down:after, .fa-duotone.fa-comment-alt-arrow-down:after {
  content: "\e1db\fe02"; }

.fad.fa-message-arrow-up:before, .fa-duotone.fa-message-arrow-up:before {
  content: "\e1dc\fe01"; }

.fad.fa-message-arrow-up:after, .fa-duotone.fa-message-arrow-up:after {
  content: "\e1dc\fe02"; }

.fad.fa-comment-alt-arrow-up:before, .fa-duotone.fa-comment-alt-arrow-up:before {
  content: "\e1dc\fe01"; }

.fad.fa-comment-alt-arrow-up:after, .fa-duotone.fa-comment-alt-arrow-up:after {
  content: "\e1dc\fe02"; }

.fad.fa-message-arrow-up-right:before, .fa-duotone.fa-message-arrow-up-right:before {
  content: "\e1dd\fe01"; }

.fad.fa-message-arrow-up-right:after, .fa-duotone.fa-message-arrow-up-right:after {
  content: "\e1dd\fe02"; }

.fad.fa-message-bot:before, .fa-duotone.fa-message-bot:before {
  content: "\e3b8\fe01"; }

.fad.fa-message-bot:after, .fa-duotone.fa-message-bot:after {
  content: "\e3b8\fe02"; }

.fad.fa-message-captions:before, .fa-duotone.fa-message-captions:before {
  content: "\e1de\fe01"; }

.fad.fa-message-captions:after, .fa-duotone.fa-message-captions:after {
  content: "\e1de\fe02"; }

.fad.fa-comment-alt-captions:before, .fa-duotone.fa-comment-alt-captions:before {
  content: "\e1de\fe01"; }

.fad.fa-comment-alt-captions:after, .fa-duotone.fa-comment-alt-captions:after {
  content: "\e1de\fe02"; }

.fad.fa-message-check:before, .fa-duotone.fa-message-check:before {
  content: "\f4a2\fe01"; }

.fad.fa-message-check:after, .fa-duotone.fa-message-check:after {
  content: "\f4a2\fe02"; }

.fad.fa-comment-alt-check:before, .fa-duotone.fa-comment-alt-check:before {
  content: "\f4a2\fe01"; }

.fad.fa-comment-alt-check:after, .fa-duotone.fa-comment-alt-check:after {
  content: "\f4a2\fe02"; }

.fad.fa-message-code:before, .fa-duotone.fa-message-code:before {
  content: "\e1df\fe01"; }

.fad.fa-message-code:after, .fa-duotone.fa-message-code:after {
  content: "\e1df\fe02"; }

.fad.fa-message-dollar:before, .fa-duotone.fa-message-dollar:before {
  content: "\f650\fe01"; }

.fad.fa-message-dollar:after, .fa-duotone.fa-message-dollar:after {
  content: "\f650\fe02"; }

.fad.fa-comment-alt-dollar:before, .fa-duotone.fa-comment-alt-dollar:before {
  content: "\f650\fe01"; }

.fad.fa-comment-alt-dollar:after, .fa-duotone.fa-comment-alt-dollar:after {
  content: "\f650\fe02"; }

.fad.fa-message-dots:before, .fa-duotone.fa-message-dots:before {
  content: "\f4a3\fe01"; }

.fad.fa-message-dots:after, .fa-duotone.fa-message-dots:after {
  content: "\f4a3\fe02"; }

.fad.fa-comment-alt-dots:before, .fa-duotone.fa-comment-alt-dots:before {
  content: "\f4a3\fe01"; }

.fad.fa-comment-alt-dots:after, .fa-duotone.fa-comment-alt-dots:after {
  content: "\f4a3\fe02"; }

.fad.fa-messaging:before, .fa-duotone.fa-messaging:before {
  content: "\f4a3\fe01"; }

.fad.fa-messaging:after, .fa-duotone.fa-messaging:after {
  content: "\f4a3\fe02"; }

.fad.fa-message-exclamation:before, .fa-duotone.fa-message-exclamation:before {
  content: "\f4a5\fe01"; }

.fad.fa-message-exclamation:after, .fa-duotone.fa-message-exclamation:after {
  content: "\f4a5\fe02"; }

.fad.fa-comment-alt-exclamation:before, .fa-duotone.fa-comment-alt-exclamation:before {
  content: "\f4a5\fe01"; }

.fad.fa-comment-alt-exclamation:after, .fa-duotone.fa-comment-alt-exclamation:after {
  content: "\f4a5\fe02"; }

.fad.fa-message-image:before, .fa-duotone.fa-message-image:before {
  content: "\e1e0\fe01"; }

.fad.fa-message-image:after, .fa-duotone.fa-message-image:after {
  content: "\e1e0\fe02"; }

.fad.fa-comment-alt-image:before, .fa-duotone.fa-comment-alt-image:before {
  content: "\e1e0\fe01"; }

.fad.fa-comment-alt-image:after, .fa-duotone.fa-comment-alt-image:after {
  content: "\e1e0\fe02"; }

.fad.fa-message-lines:before, .fa-duotone.fa-message-lines:before {
  content: "\f4a6\fe01"; }

.fad.fa-message-lines:after, .fa-duotone.fa-message-lines:after {
  content: "\f4a6\fe02"; }

.fad.fa-comment-alt-lines:before, .fa-duotone.fa-comment-alt-lines:before {
  content: "\f4a6\fe01"; }

.fad.fa-comment-alt-lines:after, .fa-duotone.fa-comment-alt-lines:after {
  content: "\f4a6\fe02"; }

.fad.fa-message-medical:before, .fa-duotone.fa-message-medical:before {
  content: "\f7f4\fe01"; }

.fad.fa-message-medical:after, .fa-duotone.fa-message-medical:after {
  content: "\f7f4\fe02"; }

.fad.fa-comment-alt-medical:before, .fa-duotone.fa-comment-alt-medical:before {
  content: "\f7f4\fe01"; }

.fad.fa-comment-alt-medical:after, .fa-duotone.fa-comment-alt-medical:after {
  content: "\f7f4\fe02"; }

.fad.fa-message-middle:before, .fa-duotone.fa-message-middle:before {
  content: "\e1e1\fe01"; }

.fad.fa-message-middle:after, .fa-duotone.fa-message-middle:after {
  content: "\e1e1\fe02"; }

.fad.fa-comment-middle-alt:before, .fa-duotone.fa-comment-middle-alt:before {
  content: "\e1e1\fe01"; }

.fad.fa-comment-middle-alt:after, .fa-duotone.fa-comment-middle-alt:after {
  content: "\e1e1\fe02"; }

.fad.fa-message-middle-top:before, .fa-duotone.fa-message-middle-top:before {
  content: "\e1e2\fe01"; }

.fad.fa-message-middle-top:after, .fa-duotone.fa-message-middle-top:after {
  content: "\e1e2\fe02"; }

.fad.fa-comment-middle-top-alt:before, .fa-duotone.fa-comment-middle-top-alt:before {
  content: "\e1e2\fe01"; }

.fad.fa-comment-middle-top-alt:after, .fa-duotone.fa-comment-middle-top-alt:after {
  content: "\e1e2\fe02"; }

.fad.fa-message-minus:before, .fa-duotone.fa-message-minus:before {
  content: "\f4a7\fe01"; }

.fad.fa-message-minus:after, .fa-duotone.fa-message-minus:after {
  content: "\f4a7\fe02"; }

.fad.fa-comment-alt-minus:before, .fa-duotone.fa-comment-alt-minus:before {
  content: "\f4a7\fe01"; }

.fad.fa-comment-alt-minus:after, .fa-duotone.fa-comment-alt-minus:after {
  content: "\f4a7\fe02"; }

.fad.fa-message-music:before, .fa-duotone.fa-message-music:before {
  content: "\f8af\fe01"; }

.fad.fa-message-music:after, .fa-duotone.fa-message-music:after {
  content: "\f8af\fe02"; }

.fad.fa-comment-alt-music:before, .fa-duotone.fa-comment-alt-music:before {
  content: "\f8af\fe01"; }

.fad.fa-comment-alt-music:after, .fa-duotone.fa-comment-alt-music:after {
  content: "\f8af\fe02"; }

.fad.fa-message-pen:before, .fa-duotone.fa-message-pen:before {
  content: "\f4a4\fe01"; }

.fad.fa-message-pen:after, .fa-duotone.fa-message-pen:after {
  content: "\f4a4\fe02"; }

.fad.fa-comment-alt-edit:before, .fa-duotone.fa-comment-alt-edit:before {
  content: "\f4a4\fe01"; }

.fad.fa-comment-alt-edit:after, .fa-duotone.fa-comment-alt-edit:after {
  content: "\f4a4\fe02"; }

.fad.fa-message-edit:before, .fa-duotone.fa-message-edit:before {
  content: "\f4a4\fe01"; }

.fad.fa-message-edit:after, .fa-duotone.fa-message-edit:after {
  content: "\f4a4\fe02"; }

.fad.fa-message-plus:before, .fa-duotone.fa-message-plus:before {
  content: "\f4a8\fe01"; }

.fad.fa-message-plus:after, .fa-duotone.fa-message-plus:after {
  content: "\f4a8\fe02"; }

.fad.fa-comment-alt-plus:before, .fa-duotone.fa-comment-alt-plus:before {
  content: "\f4a8\fe01"; }

.fad.fa-comment-alt-plus:after, .fa-duotone.fa-comment-alt-plus:after {
  content: "\f4a8\fe02"; }

.fad.fa-message-question:before, .fa-duotone.fa-message-question:before {
  content: "\e1e3\fe01"; }

.fad.fa-message-question:after, .fa-duotone.fa-message-question:after {
  content: "\e1e3\fe02"; }

.fad.fa-message-quote:before, .fa-duotone.fa-message-quote:before {
  content: "\e1e4\fe01"; }

.fad.fa-message-quote:after, .fa-duotone.fa-message-quote:after {
  content: "\e1e4\fe02"; }

.fad.fa-comment-alt-quote:before, .fa-duotone.fa-comment-alt-quote:before {
  content: "\e1e4\fe01"; }

.fad.fa-comment-alt-quote:after, .fa-duotone.fa-comment-alt-quote:after {
  content: "\e1e4\fe02"; }

.fad.fa-message-slash:before, .fa-duotone.fa-message-slash:before {
  content: "\f4a9\fe01"; }

.fad.fa-message-slash:after, .fa-duotone.fa-message-slash:after {
  content: "\f4a9\fe02"; }

.fad.fa-comment-alt-slash:before, .fa-duotone.fa-comment-alt-slash:before {
  content: "\f4a9\fe01"; }

.fad.fa-comment-alt-slash:after, .fa-duotone.fa-comment-alt-slash:after {
  content: "\f4a9\fe02"; }

.fad.fa-message-smile:before, .fa-duotone.fa-message-smile:before {
  content: "\f4aa\fe01"; }

.fad.fa-message-smile:after, .fa-duotone.fa-message-smile:after {
  content: "\f4aa\fe02"; }

.fad.fa-comment-alt-smile:before, .fa-duotone.fa-comment-alt-smile:before {
  content: "\f4aa\fe01"; }

.fad.fa-comment-alt-smile:after, .fa-duotone.fa-comment-alt-smile:after {
  content: "\f4aa\fe02"; }

.fad.fa-message-sms:before, .fa-duotone.fa-message-sms:before {
  content: "\e1e5\fe01"; }

.fad.fa-message-sms:after, .fa-duotone.fa-message-sms:after {
  content: "\e1e5\fe02"; }

.fad.fa-message-text:before, .fa-duotone.fa-message-text:before {
  content: "\e1e6\fe01"; }

.fad.fa-message-text:after, .fa-duotone.fa-message-text:after {
  content: "\e1e6\fe02"; }

.fad.fa-comment-alt-text:before, .fa-duotone.fa-comment-alt-text:before {
  content: "\e1e6\fe01"; }

.fad.fa-comment-alt-text:after, .fa-duotone.fa-comment-alt-text:after {
  content: "\e1e6\fe02"; }

.fad.fa-message-xmark:before, .fa-duotone.fa-message-xmark:before {
  content: "\f4ab\fe01"; }

.fad.fa-message-xmark:after, .fa-duotone.fa-message-xmark:after {
  content: "\f4ab\fe02"; }

.fad.fa-comment-alt-times:before, .fa-duotone.fa-comment-alt-times:before {
  content: "\f4ab\fe01"; }

.fad.fa-comment-alt-times:after, .fa-duotone.fa-comment-alt-times:after {
  content: "\f4ab\fe02"; }

.fad.fa-message-times:before, .fa-duotone.fa-message-times:before {
  content: "\f4ab\fe01"; }

.fad.fa-message-times:after, .fa-duotone.fa-message-times:after {
  content: "\f4ab\fe02"; }

.fad.fa-messages:before, .fa-duotone.fa-messages:before {
  content: "\f4b6\fe01"; }

.fad.fa-messages:after, .fa-duotone.fa-messages:after {
  content: "\f4b6\fe02"; }

.fad.fa-comments-alt:before, .fa-duotone.fa-comments-alt:before {
  content: "\f4b6\fe01"; }

.fad.fa-comments-alt:after, .fa-duotone.fa-comments-alt:after {
  content: "\f4b6\fe02"; }

.fad.fa-messages-dollar:before, .fa-duotone.fa-messages-dollar:before {
  content: "\f652\fe01"; }

.fad.fa-messages-dollar:after, .fa-duotone.fa-messages-dollar:after {
  content: "\f652\fe02"; }

.fad.fa-comments-alt-dollar:before, .fa-duotone.fa-comments-alt-dollar:before {
  content: "\f652\fe01"; }

.fad.fa-comments-alt-dollar:after, .fa-duotone.fa-comments-alt-dollar:after {
  content: "\f652\fe02"; }

.fad.fa-messages-question:before, .fa-duotone.fa-messages-question:before {
  content: "\e1e7\fe01"; }

.fad.fa-messages-question:after, .fa-duotone.fa-messages-question:after {
  content: "\e1e7\fe02"; }

.fad.fa-meteor:before, .fa-duotone.fa-meteor:before {
  content: "\2604\fe01"; }

.fad.fa-meteor:after, .fa-duotone.fa-meteor:after {
  content: "\2604\fe02"; }

.fad.fa-meter:before, .fa-duotone.fa-meter:before {
  content: "\e1e8\fe01"; }

.fad.fa-meter:after, .fa-duotone.fa-meter:after {
  content: "\e1e8\fe02"; }

.fad.fa-meter-bolt:before, .fa-duotone.fa-meter-bolt:before {
  content: "\e1e9\fe01"; }

.fad.fa-meter-bolt:after, .fa-duotone.fa-meter-bolt:after {
  content: "\e1e9\fe02"; }

.fad.fa-meter-droplet:before, .fa-duotone.fa-meter-droplet:before {
  content: "\e1ea\fe01"; }

.fad.fa-meter-droplet:after, .fa-duotone.fa-meter-droplet:after {
  content: "\e1ea\fe02"; }

.fad.fa-meter-fire:before, .fa-duotone.fa-meter-fire:before {
  content: "\e1eb\fe01"; }

.fad.fa-meter-fire:after, .fa-duotone.fa-meter-fire:after {
  content: "\e1eb\fe02"; }

.fad.fa-microchip:before, .fa-duotone.fa-microchip:before {
  content: "\f2db\fe01"; }

.fad.fa-microchip:after, .fa-duotone.fa-microchip:after {
  content: "\f2db\fe02"; }

.fad.fa-microchip-ai:before, .fa-duotone.fa-microchip-ai:before {
  content: "\e1ec\fe01"; }

.fad.fa-microchip-ai:after, .fa-duotone.fa-microchip-ai:after {
  content: "\e1ec\fe02"; }

.fad.fa-microphone:before, .fa-duotone.fa-microphone:before {
  content: "\f130\fe01"; }

.fad.fa-microphone:after, .fa-duotone.fa-microphone:after {
  content: "\f130\fe02"; }

.fad.fa-microphone-lines:before, .fa-duotone.fa-microphone-lines:before {
  content: "\f3c9"; }

.fad.fa-microphone-lines:after, .fa-duotone.fa-microphone-lines:after {
  content: "\10f3c9"; }

.fad.fa-microphone-alt:before, .fa-duotone.fa-microphone-alt:before {
  content: "\f3c9"; }

.fad.fa-microphone-alt:after, .fa-duotone.fa-microphone-alt:after {
  content: "\10f3c9"; }

.fad.fa-microphone-lines-slash:before, .fa-duotone.fa-microphone-lines-slash:before {
  content: "\f539\fe01"; }

.fad.fa-microphone-lines-slash:after, .fa-duotone.fa-microphone-lines-slash:after {
  content: "\f539\fe02"; }

.fad.fa-microphone-alt-slash:before, .fa-duotone.fa-microphone-alt-slash:before {
  content: "\f539\fe01"; }

.fad.fa-microphone-alt-slash:after, .fa-duotone.fa-microphone-alt-slash:after {
  content: "\f539\fe02"; }

.fad.fa-microphone-slash:before, .fa-duotone.fa-microphone-slash:before {
  content: "\f131\fe01"; }

.fad.fa-microphone-slash:after, .fa-duotone.fa-microphone-slash:after {
  content: "\f131\fe02"; }

.fad.fa-microphone-stand:before, .fa-duotone.fa-microphone-stand:before {
  content: "\f8cb"; }

.fad.fa-microphone-stand:after, .fa-duotone.fa-microphone-stand:after {
  content: "\10f8cb"; }

.fad.fa-microscope:before, .fa-duotone.fa-microscope:before {
  content: "\f610"; }

.fad.fa-microscope:after, .fa-duotone.fa-microscope:after {
  content: "\10f610"; }

.fad.fa-microwave:before, .fa-duotone.fa-microwave:before {
  content: "\e01b\fe01"; }

.fad.fa-microwave:after, .fa-duotone.fa-microwave:after {
  content: "\e01b\fe02"; }

.fad.fa-mill-sign:before, .fa-duotone.fa-mill-sign:before {
  content: "\e1ed\fe01"; }

.fad.fa-mill-sign:after, .fa-duotone.fa-mill-sign:after {
  content: "\e1ed\fe02"; }

.fad.fa-minimize:before, .fa-duotone.fa-minimize:before {
  content: "\f78c\fe01"; }

.fad.fa-minimize:after, .fa-duotone.fa-minimize:after {
  content: "\f78c\fe02"; }

.fad.fa-compress-arrows-alt:before, .fa-duotone.fa-compress-arrows-alt:before {
  content: "\f78c\fe01"; }

.fad.fa-compress-arrows-alt:after, .fa-duotone.fa-compress-arrows-alt:after {
  content: "\f78c\fe02"; }

.fad.fa-minus:before, .fa-duotone.fa-minus:before {
  content: "\2013\fe01"; }

.fad.fa-minus:after, .fa-duotone.fa-minus:after {
  content: "\2013\fe02"; }

.fad.fa-subtract:before, .fa-duotone.fa-subtract:before {
  content: "\2013\fe01"; }

.fad.fa-subtract:after, .fa-duotone.fa-subtract:after {
  content: "\2013\fe02"; }

.fad.fa-mistletoe:before, .fa-duotone.fa-mistletoe:before {
  content: "\f7b4\fe01"; }

.fad.fa-mistletoe:after, .fa-duotone.fa-mistletoe:after {
  content: "\f7b4\fe02"; }

.fad.fa-mitten:before, .fa-duotone.fa-mitten:before {
  content: "\f7b5\fe01"; }

.fad.fa-mitten:after, .fa-duotone.fa-mitten:after {
  content: "\f7b5\fe02"; }

.fad.fa-mobile:before, .fa-duotone.fa-mobile:before {
  content: "\f3ce"; }

.fad.fa-mobile:after, .fa-duotone.fa-mobile:after {
  content: "\10f3ce"; }

.fad.fa-mobile-android:before, .fa-duotone.fa-mobile-android:before {
  content: "\f3ce"; }

.fad.fa-mobile-android:after, .fa-duotone.fa-mobile-android:after {
  content: "\10f3ce"; }

.fad.fa-mobile-phone:before, .fa-duotone.fa-mobile-phone:before {
  content: "\f3ce"; }

.fad.fa-mobile-phone:after, .fa-duotone.fa-mobile-phone:after {
  content: "\10f3ce"; }

.fad.fa-mobile-button:before, .fa-duotone.fa-mobile-button:before {
  content: "\f10b\fe01"; }

.fad.fa-mobile-button:after, .fa-duotone.fa-mobile-button:after {
  content: "\f10b\fe02"; }

.fad.fa-mobile-notch:before, .fa-duotone.fa-mobile-notch:before {
  content: "\e1ee\fe01"; }

.fad.fa-mobile-notch:after, .fa-duotone.fa-mobile-notch:after {
  content: "\e1ee\fe02"; }

.fad.fa-mobile-iphone:before, .fa-duotone.fa-mobile-iphone:before {
  content: "\e1ee\fe01"; }

.fad.fa-mobile-iphone:after, .fa-duotone.fa-mobile-iphone:after {
  content: "\e1ee\fe02"; }

.fad.fa-mobile-screen:before, .fa-duotone.fa-mobile-screen:before {
  content: "\f3cf\fe01"; }

.fad.fa-mobile-screen:after, .fa-duotone.fa-mobile-screen:after {
  content: "\f3cf\fe02"; }

.fad.fa-mobile-android-alt:before, .fa-duotone.fa-mobile-android-alt:before {
  content: "\f3cf\fe01"; }

.fad.fa-mobile-android-alt:after, .fa-duotone.fa-mobile-android-alt:after {
  content: "\f3cf\fe02"; }

.fad.fa-mobile-screen-button:before, .fa-duotone.fa-mobile-screen-button:before {
  content: "\f3cd\fe01"; }

.fad.fa-mobile-screen-button:after, .fa-duotone.fa-mobile-screen-button:after {
  content: "\f3cd\fe02"; }

.fad.fa-mobile-alt:before, .fa-duotone.fa-mobile-alt:before {
  content: "\f3cd\fe01"; }

.fad.fa-mobile-alt:after, .fa-duotone.fa-mobile-alt:after {
  content: "\f3cd\fe02"; }

.fad.fa-mobile-signal:before, .fa-duotone.fa-mobile-signal:before {
  content: "\e1ef\fe01"; }

.fad.fa-mobile-signal:after, .fa-duotone.fa-mobile-signal:after {
  content: "\e1ef\fe02"; }

.fad.fa-mobile-signal-out:before, .fa-duotone.fa-mobile-signal-out:before {
  content: "\e1f0\fe01"; }

.fad.fa-mobile-signal-out:after, .fa-duotone.fa-mobile-signal-out:after {
  content: "\e1f0\fe02"; }

.fad.fa-money-bill:before, .fa-duotone.fa-money-bill:before {
  content: "\f0d6\fe01"; }

.fad.fa-money-bill:after, .fa-duotone.fa-money-bill:after {
  content: "\f0d6\fe02"; }

.fad.fa-money-bill-1:before, .fa-duotone.fa-money-bill-1:before {
  content: "\f3d1\fe01"; }

.fad.fa-money-bill-1:after, .fa-duotone.fa-money-bill-1:after {
  content: "\f3d1\fe02"; }

.fad.fa-money-bill-alt:before, .fa-duotone.fa-money-bill-alt:before {
  content: "\f3d1\fe01"; }

.fad.fa-money-bill-alt:after, .fa-duotone.fa-money-bill-alt:after {
  content: "\f3d1\fe02"; }

.fad.fa-money-bill-1-wave:before, .fa-duotone.fa-money-bill-1-wave:before {
  content: "\f53b\fe01"; }

.fad.fa-money-bill-1-wave:after, .fa-duotone.fa-money-bill-1-wave:after {
  content: "\f53b\fe02"; }

.fad.fa-money-bill-wave-alt:before, .fa-duotone.fa-money-bill-wave-alt:before {
  content: "\f53b\fe01"; }

.fad.fa-money-bill-wave-alt:after, .fa-duotone.fa-money-bill-wave-alt:after {
  content: "\f53b\fe02"; }

.fad.fa-money-bill-simple:before, .fa-duotone.fa-money-bill-simple:before {
  content: "\e1f1\fe01"; }

.fad.fa-money-bill-simple:after, .fa-duotone.fa-money-bill-simple:after {
  content: "\e1f1\fe02"; }

.fad.fa-money-bill-simple-wave:before, .fa-duotone.fa-money-bill-simple-wave:before {
  content: "\e1f2\fe01"; }

.fad.fa-money-bill-simple-wave:after, .fa-duotone.fa-money-bill-simple-wave:after {
  content: "\e1f2\fe02"; }

.fad.fa-money-bill-wave:before, .fa-duotone.fa-money-bill-wave:before {
  content: "\f53a\fe01"; }

.fad.fa-money-bill-wave:after, .fa-duotone.fa-money-bill-wave:after {
  content: "\f53a\fe02"; }

.fad.fa-money-bills:before, .fa-duotone.fa-money-bills:before {
  content: "\e1f3\fe01"; }

.fad.fa-money-bills:after, .fa-duotone.fa-money-bills:after {
  content: "\e1f3\fe02"; }

.fad.fa-money-bills-simple:before, .fa-duotone.fa-money-bills-simple:before {
  content: "\e1f4\fe01"; }

.fad.fa-money-bills-simple:after, .fa-duotone.fa-money-bills-simple:after {
  content: "\e1f4\fe02"; }

.fad.fa-money-bills-alt:before, .fa-duotone.fa-money-bills-alt:before {
  content: "\e1f4\fe01"; }

.fad.fa-money-bills-alt:after, .fa-duotone.fa-money-bills-alt:after {
  content: "\e1f4\fe02"; }

.fad.fa-money-check:before, .fa-duotone.fa-money-check:before {
  content: "\f53c\fe01"; }

.fad.fa-money-check:after, .fa-duotone.fa-money-check:after {
  content: "\f53c\fe02"; }

.fad.fa-money-check-dollar:before, .fa-duotone.fa-money-check-dollar:before {
  content: "\f53d\fe01"; }

.fad.fa-money-check-dollar:after, .fa-duotone.fa-money-check-dollar:after {
  content: "\f53d\fe02"; }

.fad.fa-money-check-alt:before, .fa-duotone.fa-money-check-alt:before {
  content: "\f53d\fe01"; }

.fad.fa-money-check-alt:after, .fa-duotone.fa-money-check-alt:after {
  content: "\f53d\fe02"; }

.fad.fa-money-check-dollar-pen:before, .fa-duotone.fa-money-check-dollar-pen:before {
  content: "\f873\fe01"; }

.fad.fa-money-check-dollar-pen:after, .fa-duotone.fa-money-check-dollar-pen:after {
  content: "\f873\fe02"; }

.fad.fa-money-check-edit-alt:before, .fa-duotone.fa-money-check-edit-alt:before {
  content: "\f873\fe01"; }

.fad.fa-money-check-edit-alt:after, .fa-duotone.fa-money-check-edit-alt:after {
  content: "\f873\fe02"; }

.fad.fa-money-check-pen:before, .fa-duotone.fa-money-check-pen:before {
  content: "\f872\fe01"; }

.fad.fa-money-check-pen:after, .fa-duotone.fa-money-check-pen:after {
  content: "\f872\fe02"; }

.fad.fa-money-check-edit:before, .fa-duotone.fa-money-check-edit:before {
  content: "\f872\fe01"; }

.fad.fa-money-check-edit:after, .fa-duotone.fa-money-check-edit:after {
  content: "\f872\fe02"; }

.fad.fa-money-from-bracket:before, .fa-duotone.fa-money-from-bracket:before {
  content: "\e312\fe01"; }

.fad.fa-money-from-bracket:after, .fa-duotone.fa-money-from-bracket:after {
  content: "\e312\fe02"; }

.fad.fa-money-simple-from-bracket:before, .fa-duotone.fa-money-simple-from-bracket:before {
  content: "\e313\fe01"; }

.fad.fa-money-simple-from-bracket:after, .fa-duotone.fa-money-simple-from-bracket:after {
  content: "\e313\fe02"; }

.fad.fa-monitor-waveform:before, .fa-duotone.fa-monitor-waveform:before {
  content: "\f611\fe01"; }

.fad.fa-monitor-waveform:after, .fa-duotone.fa-monitor-waveform:after {
  content: "\f611\fe02"; }

.fad.fa-monitor-heart-rate:before, .fa-duotone.fa-monitor-heart-rate:before {
  content: "\f611\fe01"; }

.fad.fa-monitor-heart-rate:after, .fa-duotone.fa-monitor-heart-rate:after {
  content: "\f611\fe02"; }

.fad.fa-monkey:before, .fa-duotone.fa-monkey:before {
  content: "\f6fb"; }

.fad.fa-monkey:after, .fa-duotone.fa-monkey:after {
  content: "\10f6fb"; }

.fad.fa-monument:before, .fa-duotone.fa-monument:before {
  content: "\f5a6\fe01"; }

.fad.fa-monument:after, .fa-duotone.fa-monument:after {
  content: "\f5a6\fe02"; }

.fad.fa-moon:before, .fa-duotone.fa-moon:before {
  content: "\23fe\fe01"; }

.fad.fa-moon:after, .fa-duotone.fa-moon:after {
  content: "\23fe\fe02"; }

.fad.fa-moon-cloud:before, .fa-duotone.fa-moon-cloud:before {
  content: "\f754\fe01"; }

.fad.fa-moon-cloud:after, .fa-duotone.fa-moon-cloud:after {
  content: "\f754\fe02"; }

.fad.fa-moon-over-sun:before, .fa-duotone.fa-moon-over-sun:before {
  content: "\f74a\fe01"; }

.fad.fa-moon-over-sun:after, .fa-duotone.fa-moon-over-sun:after {
  content: "\f74a\fe02"; }

.fad.fa-eclipse-alt:before, .fa-duotone.fa-eclipse-alt:before {
  content: "\f74a\fe01"; }

.fad.fa-eclipse-alt:after, .fa-duotone.fa-eclipse-alt:after {
  content: "\f74a\fe02"; }

.fad.fa-moon-stars:before, .fa-duotone.fa-moon-stars:before {
  content: "\f755\fe01"; }

.fad.fa-moon-stars:after, .fa-duotone.fa-moon-stars:after {
  content: "\f755\fe02"; }

.fad.fa-moped:before, .fa-duotone.fa-moped:before {
  content: "\e3b9\fe01"; }

.fad.fa-moped:after, .fa-duotone.fa-moped:after {
  content: "\e3b9\fe02"; }

.fad.fa-mortar-pestle:before, .fa-duotone.fa-mortar-pestle:before {
  content: "\f5a7\fe01"; }

.fad.fa-mortar-pestle:after, .fa-duotone.fa-mortar-pestle:after {
  content: "\f5a7\fe02"; }

.fad.fa-mosque:before, .fa-duotone.fa-mosque:before {
  content: "\f678"; }

.fad.fa-mosque:after, .fa-duotone.fa-mosque:after {
  content: "\10f678"; }

.fad.fa-motorcycle:before, .fa-duotone.fa-motorcycle:before {
  content: "\f21c"; }

.fad.fa-motorcycle:after, .fa-duotone.fa-motorcycle:after {
  content: "\10f21c"; }

.fad.fa-mountain:before, .fa-duotone.fa-mountain:before {
  content: "\f6fc"; }

.fad.fa-mountain:after, .fa-duotone.fa-mountain:after {
  content: "\10f6fc"; }

.fad.fa-mountains:before, .fa-duotone.fa-mountains:before {
  content: "\26f0\fe01"; }

.fad.fa-mountains:after, .fa-duotone.fa-mountains:after {
  content: "\26f0\fe02"; }

.fad.fa-mp3-player:before, .fa-duotone.fa-mp3-player:before {
  content: "\f8ce\fe01"; }

.fad.fa-mp3-player:after, .fa-duotone.fa-mp3-player:after {
  content: "\f8ce\fe02"; }

.fad.fa-mug:before, .fa-duotone.fa-mug:before {
  content: "\f874\fe01"; }

.fad.fa-mug:after, .fa-duotone.fa-mug:after {
  content: "\f874\fe02"; }

.fad.fa-mug-hot:before, .fa-duotone.fa-mug-hot:before {
  content: "\2615\fe01"; }

.fad.fa-mug-hot:after, .fa-duotone.fa-mug-hot:after {
  content: "\2615\fe02"; }

.fad.fa-mug-marshmallows:before, .fa-duotone.fa-mug-marshmallows:before {
  content: "\f7b7\fe01"; }

.fad.fa-mug-marshmallows:after, .fa-duotone.fa-mug-marshmallows:after {
  content: "\f7b7\fe02"; }

.fad.fa-mug-saucer:before, .fa-duotone.fa-mug-saucer:before {
  content: "\f0f4\fe01"; }

.fad.fa-mug-saucer:after, .fa-duotone.fa-mug-saucer:after {
  content: "\f0f4\fe02"; }

.fad.fa-coffee:before, .fa-duotone.fa-coffee:before {
  content: "\f0f4\fe01"; }

.fad.fa-coffee:after, .fa-duotone.fa-coffee:after {
  content: "\f0f4\fe02"; }

.fad.fa-mug-tea:before, .fa-duotone.fa-mug-tea:before {
  content: "\f875\fe01"; }

.fad.fa-mug-tea:after, .fa-duotone.fa-mug-tea:after {
  content: "\f875\fe02"; }

.fad.fa-mug-tea-saucer:before, .fa-duotone.fa-mug-tea-saucer:before {
  content: "\e1f5\fe01"; }

.fad.fa-mug-tea-saucer:after, .fa-duotone.fa-mug-tea-saucer:after {
  content: "\e1f5\fe02"; }

.fad.fa-music:before, .fa-duotone.fa-music:before {
  content: "\f001"; }

.fad.fa-music:after, .fa-duotone.fa-music:after {
  content: "\10f001"; }

.fad.fa-music-note:before, .fa-duotone.fa-music-note:before {
  content: "\f8cf\fe01"; }

.fad.fa-music-note:after, .fa-duotone.fa-music-note:after {
  content: "\f8cf\fe02"; }

.fad.fa-music-alt:before, .fa-duotone.fa-music-alt:before {
  content: "\f8cf\fe01"; }

.fad.fa-music-alt:after, .fa-duotone.fa-music-alt:after {
  content: "\f8cf\fe02"; }

.fad.fa-music-note-slash:before, .fa-duotone.fa-music-note-slash:before {
  content: "\f8d0\fe01"; }

.fad.fa-music-note-slash:after, .fa-duotone.fa-music-note-slash:after {
  content: "\f8d0\fe02"; }

.fad.fa-music-alt-slash:before, .fa-duotone.fa-music-alt-slash:before {
  content: "\f8d0\fe01"; }

.fad.fa-music-alt-slash:after, .fa-duotone.fa-music-alt-slash:after {
  content: "\f8d0\fe02"; }

.fad.fa-music-slash:before, .fa-duotone.fa-music-slash:before {
  content: "\f8d1\fe01"; }

.fad.fa-music-slash:after, .fa-duotone.fa-music-slash:after {
  content: "\f8d1\fe02"; }

.fad.fa-n:before, .fa-duotone.fa-n:before {
  content: "\4e\fe01"; }

.fad.fa-n:after, .fa-duotone.fa-n:after {
  content: "\4e\fe02"; }

.fad.fa-naira-sign:before, .fa-duotone.fa-naira-sign:before {
  content: "\e1f6\fe01"; }

.fad.fa-naira-sign:after, .fa-duotone.fa-naira-sign:after {
  content: "\e1f6\fe02"; }

.fad.fa-narwhal:before, .fa-duotone.fa-narwhal:before {
  content: "\f6fe\fe01"; }

.fad.fa-narwhal:after, .fa-duotone.fa-narwhal:after {
  content: "\f6fe\fe02"; }

.fad.fa-nesting-dolls:before, .fa-duotone.fa-nesting-dolls:before {
  content: "\e3ba\fe01"; }

.fad.fa-nesting-dolls:after, .fa-duotone.fa-nesting-dolls:after {
  content: "\e3ba\fe02"; }

.fad.fa-network-wired:before, .fa-duotone.fa-network-wired:before {
  content: "\f6ff\fe01"; }

.fad.fa-network-wired:after, .fa-duotone.fa-network-wired:after {
  content: "\f6ff\fe02"; }

.fad.fa-neuter:before, .fa-duotone.fa-neuter:before {
  content: "\26b2\fe01"; }

.fad.fa-neuter:after, .fa-duotone.fa-neuter:after {
  content: "\26b2\fe02"; }

.fad.fa-newspaper:before, .fa-duotone.fa-newspaper:before {
  content: "\f1ea"; }

.fad.fa-newspaper:after, .fa-duotone.fa-newspaper:after {
  content: "\10f1ea"; }

.fad.fa-nfc:before, .fa-duotone.fa-nfc:before {
  content: "\e1f7\fe01"; }

.fad.fa-nfc:after, .fa-duotone.fa-nfc:after {
  content: "\e1f7\fe02"; }

.fad.fa-nfc-lock:before, .fa-duotone.fa-nfc-lock:before {
  content: "\e1f8\fe01"; }

.fad.fa-nfc-lock:after, .fa-duotone.fa-nfc-lock:after {
  content: "\e1f8\fe02"; }

.fad.fa-nfc-magnifying-glass:before, .fa-duotone.fa-nfc-magnifying-glass:before {
  content: "\e1f9\fe01"; }

.fad.fa-nfc-magnifying-glass:after, .fa-duotone.fa-nfc-magnifying-glass:after {
  content: "\e1f9\fe02"; }

.fad.fa-nfc-pen:before, .fa-duotone.fa-nfc-pen:before {
  content: "\e1fa\fe01"; }

.fad.fa-nfc-pen:after, .fa-duotone.fa-nfc-pen:after {
  content: "\e1fa\fe02"; }

.fad.fa-nfc-signal:before, .fa-duotone.fa-nfc-signal:before {
  content: "\e1fb\fe01"; }

.fad.fa-nfc-signal:after, .fa-duotone.fa-nfc-signal:after {
  content: "\e1fb\fe02"; }

.fad.fa-nfc-slash:before, .fa-duotone.fa-nfc-slash:before {
  content: "\e1fc\fe01"; }

.fad.fa-nfc-slash:after, .fa-duotone.fa-nfc-slash:after {
  content: "\e1fc\fe02"; }

.fad.fa-nfc-trash:before, .fa-duotone.fa-nfc-trash:before {
  content: "\e1fd\fe01"; }

.fad.fa-nfc-trash:after, .fa-duotone.fa-nfc-trash:after {
  content: "\e1fd\fe02"; }

.fad.fa-not-equal:before, .fa-duotone.fa-not-equal:before {
  content: "\f53e\fe01"; }

.fad.fa-not-equal:after, .fa-duotone.fa-not-equal:after {
  content: "\f53e\fe02"; }

.fad.fa-notdef:before, .fa-duotone.fa-notdef:before {
  content: "\e1fe\fe01"; }

.fad.fa-notdef:after, .fa-duotone.fa-notdef:after {
  content: "\e1fe\fe02"; }

.fad.fa-note:before, .fa-duotone.fa-note:before {
  content: "\e1ff\fe01"; }

.fad.fa-note:after, .fa-duotone.fa-note:after {
  content: "\e1ff\fe02"; }

.fad.fa-note-medical:before, .fa-duotone.fa-note-medical:before {
  content: "\e200\fe01"; }

.fad.fa-note-medical:after, .fa-duotone.fa-note-medical:after {
  content: "\e200\fe02"; }

.fad.fa-note-sticky:before, .fa-duotone.fa-note-sticky:before {
  content: "\f249\fe01"; }

.fad.fa-note-sticky:after, .fa-duotone.fa-note-sticky:after {
  content: "\f249\fe02"; }

.fad.fa-sticky-note:before, .fa-duotone.fa-sticky-note:before {
  content: "\f249\fe01"; }

.fad.fa-sticky-note:after, .fa-duotone.fa-sticky-note:after {
  content: "\f249\fe02"; }

.fad.fa-notebook:before, .fa-duotone.fa-notebook:before {
  content: "\e201\fe01"; }

.fad.fa-notebook:after, .fa-duotone.fa-notebook:after {
  content: "\e201\fe02"; }

.fad.fa-notes:before, .fa-duotone.fa-notes:before {
  content: "\e202\fe01"; }

.fad.fa-notes:after, .fa-duotone.fa-notes:after {
  content: "\e202\fe02"; }

.fad.fa-notes-medical:before, .fa-duotone.fa-notes-medical:before {
  content: "\f481\fe01"; }

.fad.fa-notes-medical:after, .fa-duotone.fa-notes-medical:after {
  content: "\f481\fe02"; }

.fad.fa-o:before, .fa-duotone.fa-o:before {
  content: "\4f\fe01"; }

.fad.fa-o:after, .fa-duotone.fa-o:after {
  content: "\4f\fe02"; }

.fad.fa-object-group:before, .fa-duotone.fa-object-group:before {
  content: "\f247\fe01"; }

.fad.fa-object-group:after, .fa-duotone.fa-object-group:after {
  content: "\f247\fe02"; }

.fad.fa-object-ungroup:before, .fa-duotone.fa-object-ungroup:before {
  content: "\f248\fe01"; }

.fad.fa-object-ungroup:after, .fa-duotone.fa-object-ungroup:after {
  content: "\f248\fe02"; }

.fad.fa-objects-align-bottom:before, .fa-duotone.fa-objects-align-bottom:before {
  content: "\e3bb\fe01"; }

.fad.fa-objects-align-bottom:after, .fa-duotone.fa-objects-align-bottom:after {
  content: "\e3bb\fe02"; }

.fad.fa-objects-align-center-horizontal:before, .fa-duotone.fa-objects-align-center-horizontal:before {
  content: "\e3bc\fe01"; }

.fad.fa-objects-align-center-horizontal:after, .fa-duotone.fa-objects-align-center-horizontal:after {
  content: "\e3bc\fe02"; }

.fad.fa-objects-align-center-vertical:before, .fa-duotone.fa-objects-align-center-vertical:before {
  content: "\e3bd\fe01"; }

.fad.fa-objects-align-center-vertical:after, .fa-duotone.fa-objects-align-center-vertical:after {
  content: "\e3bd\fe02"; }

.fad.fa-objects-align-left:before, .fa-duotone.fa-objects-align-left:before {
  content: "\e3be\fe01"; }

.fad.fa-objects-align-left:after, .fa-duotone.fa-objects-align-left:after {
  content: "\e3be\fe02"; }

.fad.fa-objects-align-right:before, .fa-duotone.fa-objects-align-right:before {
  content: "\e3bf\fe01"; }

.fad.fa-objects-align-right:after, .fa-duotone.fa-objects-align-right:after {
  content: "\e3bf\fe02"; }

.fad.fa-objects-align-top:before, .fa-duotone.fa-objects-align-top:before {
  content: "\e3c0\fe01"; }

.fad.fa-objects-align-top:after, .fa-duotone.fa-objects-align-top:after {
  content: "\e3c0\fe02"; }

.fad.fa-objects-column:before, .fa-duotone.fa-objects-column:before {
  content: "\e3c1\fe01"; }

.fad.fa-objects-column:after, .fa-duotone.fa-objects-column:after {
  content: "\e3c1\fe02"; }

.fad.fa-octagon:before, .fa-duotone.fa-octagon:before {
  content: "\f306"; }

.fad.fa-octagon:after, .fa-duotone.fa-octagon:after {
  content: "\10f306"; }

.fad.fa-octagon-divide:before, .fa-duotone.fa-octagon-divide:before {
  content: "\e203\fe01"; }

.fad.fa-octagon-divide:after, .fa-duotone.fa-octagon-divide:after {
  content: "\e203\fe02"; }

.fad.fa-octagon-exclamation:before, .fa-duotone.fa-octagon-exclamation:before {
  content: "\e204\fe01"; }

.fad.fa-octagon-exclamation:after, .fa-duotone.fa-octagon-exclamation:after {
  content: "\e204\fe02"; }

.fad.fa-octagon-minus:before, .fa-duotone.fa-octagon-minus:before {
  content: "\f308\fe01"; }

.fad.fa-octagon-minus:after, .fa-duotone.fa-octagon-minus:after {
  content: "\f308\fe02"; }

.fad.fa-minus-octagon:before, .fa-duotone.fa-minus-octagon:before {
  content: "\f308\fe01"; }

.fad.fa-minus-octagon:after, .fa-duotone.fa-minus-octagon:after {
  content: "\f308\fe02"; }

.fad.fa-octagon-plus:before, .fa-duotone.fa-octagon-plus:before {
  content: "\f301\fe01"; }

.fad.fa-octagon-plus:after, .fa-duotone.fa-octagon-plus:after {
  content: "\f301\fe02"; }

.fad.fa-plus-octagon:before, .fa-duotone.fa-plus-octagon:before {
  content: "\f301\fe01"; }

.fad.fa-plus-octagon:after, .fa-duotone.fa-plus-octagon:after {
  content: "\f301\fe02"; }

.fad.fa-octagon-xmark:before, .fa-duotone.fa-octagon-xmark:before {
  content: "\f2f0\fe01"; }

.fad.fa-octagon-xmark:after, .fa-duotone.fa-octagon-xmark:after {
  content: "\f2f0\fe02"; }

.fad.fa-times-octagon:before, .fa-duotone.fa-times-octagon:before {
  content: "\f2f0\fe01"; }

.fad.fa-times-octagon:after, .fa-duotone.fa-times-octagon:after {
  content: "\f2f0\fe02"; }

.fad.fa-xmark-octagon:before, .fa-duotone.fa-xmark-octagon:before {
  content: "\f2f0\fe01"; }

.fad.fa-xmark-octagon:after, .fa-duotone.fa-xmark-octagon:after {
  content: "\f2f0\fe02"; }

.fad.fa-oil-can:before, .fa-duotone.fa-oil-can:before {
  content: "\f613\fe01"; }

.fad.fa-oil-can:after, .fa-duotone.fa-oil-can:after {
  content: "\f613\fe02"; }

.fad.fa-oil-can-drip:before, .fa-duotone.fa-oil-can-drip:before {
  content: "\e205\fe01"; }

.fad.fa-oil-can-drip:after, .fa-duotone.fa-oil-can-drip:after {
  content: "\e205\fe02"; }

.fad.fa-oil-temperature:before, .fa-duotone.fa-oil-temperature:before {
  content: "\f614\fe01"; }

.fad.fa-oil-temperature:after, .fa-duotone.fa-oil-temperature:after {
  content: "\f614\fe02"; }

.fad.fa-oil-temp:before, .fa-duotone.fa-oil-temp:before {
  content: "\f614\fe01"; }

.fad.fa-oil-temp:after, .fa-duotone.fa-oil-temp:after {
  content: "\f614\fe02"; }

.fad.fa-olive:before, .fa-duotone.fa-olive:before {
  content: "\e316\fe01"; }

.fad.fa-olive:after, .fa-duotone.fa-olive:after {
  content: "\e316\fe02"; }

.fad.fa-olive-branch:before, .fa-duotone.fa-olive-branch:before {
  content: "\e317\fe01"; }

.fad.fa-olive-branch:after, .fa-duotone.fa-olive-branch:after {
  content: "\e317\fe02"; }

.fad.fa-om:before, .fa-duotone.fa-om:before {
  content: "\f679"; }

.fad.fa-om:after, .fa-duotone.fa-om:after {
  content: "\10f679"; }

.fad.fa-omega:before, .fa-duotone.fa-omega:before {
  content: "\f67a\fe01"; }

.fad.fa-omega:after, .fa-duotone.fa-omega:after {
  content: "\f67a\fe02"; }

.fad.fa-option:before, .fa-duotone.fa-option:before {
  content: "\e318\fe01"; }

.fad.fa-option:after, .fa-duotone.fa-option:after {
  content: "\e318\fe02"; }

.fad.fa-ornament:before, .fa-duotone.fa-ornament:before {
  content: "\f7b8\fe01"; }

.fad.fa-ornament:after, .fa-duotone.fa-ornament:after {
  content: "\f7b8\fe02"; }

.fad.fa-otter:before, .fa-duotone.fa-otter:before {
  content: "\f700"; }

.fad.fa-otter:after, .fa-duotone.fa-otter:after {
  content: "\10f700"; }

.fad.fa-outdent:before, .fa-duotone.fa-outdent:before {
  content: "\f03b\fe01"; }

.fad.fa-outdent:after, .fa-duotone.fa-outdent:after {
  content: "\f03b\fe02"; }

.fad.fa-dedent:before, .fa-duotone.fa-dedent:before {
  content: "\f03b\fe01"; }

.fad.fa-dedent:after, .fa-duotone.fa-dedent:after {
  content: "\f03b\fe02"; }

.fad.fa-outlet:before, .fa-duotone.fa-outlet:before {
  content: "\e01c\fe01"; }

.fad.fa-outlet:after, .fa-duotone.fa-outlet:after {
  content: "\e01c\fe02"; }

.fad.fa-oven:before, .fa-duotone.fa-oven:before {
  content: "\e01d\fe01"; }

.fad.fa-oven:after, .fa-duotone.fa-oven:after {
  content: "\e01d\fe02"; }

.fad.fa-overline:before, .fa-duotone.fa-overline:before {
  content: "\f876\fe01"; }

.fad.fa-overline:after, .fa-duotone.fa-overline:after {
  content: "\f876\fe02"; }

.fad.fa-p:before, .fa-duotone.fa-p:before {
  content: "\50\fe01"; }

.fad.fa-p:after, .fa-duotone.fa-p:after {
  content: "\50\fe02"; }

.fad.fa-pager:before, .fa-duotone.fa-pager:before {
  content: "\f815"; }

.fad.fa-pager:after, .fa-duotone.fa-pager:after {
  content: "\10f815"; }

.fad.fa-paint-brush:before, .fa-duotone.fa-paint-brush:before {
  content: "\f1fc"; }

.fad.fa-paint-brush:after, .fa-duotone.fa-paint-brush:after {
  content: "\10f1fc"; }

.fad.fa-paint-brush-fine:before, .fa-duotone.fa-paint-brush-fine:before {
  content: "\f5a9\fe01"; }

.fad.fa-paint-brush-fine:after, .fa-duotone.fa-paint-brush-fine:after {
  content: "\f5a9\fe02"; }

.fad.fa-paint-brush-alt:before, .fa-duotone.fa-paint-brush-alt:before {
  content: "\f5a9\fe01"; }

.fad.fa-paint-brush-alt:after, .fa-duotone.fa-paint-brush-alt:after {
  content: "\f5a9\fe02"; }

.fad.fa-paint-roller:before, .fa-duotone.fa-paint-roller:before {
  content: "\f5aa\fe01"; }

.fad.fa-paint-roller:after, .fa-duotone.fa-paint-roller:after {
  content: "\f5aa\fe02"; }

.fad.fa-paintbrush-pencil:before, .fa-duotone.fa-paintbrush-pencil:before {
  content: "\e206\fe01"; }

.fad.fa-paintbrush-pencil:after, .fa-duotone.fa-paintbrush-pencil:after {
  content: "\e206\fe02"; }

.fad.fa-palette:before, .fa-duotone.fa-palette:before {
  content: "\f53f"; }

.fad.fa-palette:after, .fa-duotone.fa-palette:after {
  content: "\10f53f"; }

.fad.fa-pallet:before, .fa-duotone.fa-pallet:before {
  content: "\f482\fe01"; }

.fad.fa-pallet:after, .fa-duotone.fa-pallet:after {
  content: "\f482\fe02"; }

.fad.fa-pallet-box:before, .fa-duotone.fa-pallet-box:before {
  content: "\e208\fe01"; }

.fad.fa-pallet-box:after, .fa-duotone.fa-pallet-box:after {
  content: "\e208\fe02"; }

.fad.fa-pallet-boxes:before, .fa-duotone.fa-pallet-boxes:before {
  content: "\f483\fe01"; }

.fad.fa-pallet-boxes:after, .fa-duotone.fa-pallet-boxes:after {
  content: "\f483\fe02"; }

.fad.fa-palette-boxes:before, .fa-duotone.fa-palette-boxes:before {
  content: "\f483\fe01"; }

.fad.fa-palette-boxes:after, .fa-duotone.fa-palette-boxes:after {
  content: "\f483\fe02"; }

.fad.fa-pallet-alt:before, .fa-duotone.fa-pallet-alt:before {
  content: "\f483\fe01"; }

.fad.fa-pallet-alt:after, .fa-duotone.fa-pallet-alt:after {
  content: "\f483\fe02"; }

.fad.fa-panorama:before, .fa-duotone.fa-panorama:before {
  content: "\e209\fe01"; }

.fad.fa-panorama:after, .fa-duotone.fa-panorama:after {
  content: "\e209\fe02"; }

.fad.fa-paper-plane:before, .fa-duotone.fa-paper-plane:before {
  content: "\f1d8\fe01"; }

.fad.fa-paper-plane:after, .fa-duotone.fa-paper-plane:after {
  content: "\f1d8\fe02"; }

.fad.fa-paper-plane-top:before, .fa-duotone.fa-paper-plane-top:before {
  content: "\e20a\fe01"; }

.fad.fa-paper-plane-top:after, .fa-duotone.fa-paper-plane-top:after {
  content: "\e20a\fe02"; }

.fad.fa-paper-plane-alt:before, .fa-duotone.fa-paper-plane-alt:before {
  content: "\e20a\fe01"; }

.fad.fa-paper-plane-alt:after, .fa-duotone.fa-paper-plane-alt:after {
  content: "\e20a\fe02"; }

.fad.fa-send:before, .fa-duotone.fa-send:before {
  content: "\e20a\fe01"; }

.fad.fa-send:after, .fa-duotone.fa-send:after {
  content: "\e20a\fe02"; }

.fad.fa-paperclip:before, .fa-duotone.fa-paperclip:before {
  content: "\f0c6"; }

.fad.fa-paperclip:after, .fa-duotone.fa-paperclip:after {
  content: "\10f0c6"; }

.fad.fa-paperclip-vertical:before, .fa-duotone.fa-paperclip-vertical:before {
  content: "\e3c2\fe01"; }

.fad.fa-paperclip-vertical:after, .fa-duotone.fa-paperclip-vertical:after {
  content: "\e3c2\fe02"; }

.fad.fa-parachute-box:before, .fa-duotone.fa-parachute-box:before {
  content: "\f4cd\fe01"; }

.fad.fa-parachute-box:after, .fa-duotone.fa-parachute-box:after {
  content: "\f4cd\fe02"; }

.fad.fa-paragraph:before, .fa-duotone.fa-paragraph:before {
  content: "\b6\fe01"; }

.fad.fa-paragraph:after, .fa-duotone.fa-paragraph:after {
  content: "\b6\fe02"; }

.fad.fa-paragraph-left:before, .fa-duotone.fa-paragraph-left:before {
  content: "\f878\fe01"; }

.fad.fa-paragraph-left:after, .fa-duotone.fa-paragraph-left:after {
  content: "\f878\fe02"; }

.fad.fa-paragraph-rtl:before, .fa-duotone.fa-paragraph-rtl:before {
  content: "\f878\fe01"; }

.fad.fa-paragraph-rtl:after, .fa-duotone.fa-paragraph-rtl:after {
  content: "\f878\fe02"; }

.fad.fa-party-bell:before, .fa-duotone.fa-party-bell:before {
  content: "\e31a\fe01"; }

.fad.fa-party-bell:after, .fa-duotone.fa-party-bell:after {
  content: "\e31a\fe02"; }

.fad.fa-party-horn:before, .fa-duotone.fa-party-horn:before {
  content: "\e31b\fe01"; }

.fad.fa-party-horn:after, .fa-duotone.fa-party-horn:after {
  content: "\e31b\fe02"; }

.fad.fa-passport:before, .fa-duotone.fa-passport:before {
  content: "\f5ab\fe01"; }

.fad.fa-passport:after, .fa-duotone.fa-passport:after {
  content: "\f5ab\fe02"; }

.fad.fa-paste:before, .fa-duotone.fa-paste:before {
  content: "\f0ea\fe01"; }

.fad.fa-paste:after, .fa-duotone.fa-paste:after {
  content: "\f0ea\fe02"; }

.fad.fa-file-clipboard:before, .fa-duotone.fa-file-clipboard:before {
  content: "\f0ea\fe01"; }

.fad.fa-file-clipboard:after, .fa-duotone.fa-file-clipboard:after {
  content: "\f0ea\fe02"; }

.fad.fa-pause:before, .fa-duotone.fa-pause:before {
  content: "\23f8\fe01"; }

.fad.fa-pause:after, .fa-duotone.fa-pause:after {
  content: "\23f8\fe02"; }

.fad.fa-paw:before, .fa-duotone.fa-paw:before {
  content: "\f1b0\fe01"; }

.fad.fa-paw:after, .fa-duotone.fa-paw:after {
  content: "\f1b0\fe02"; }

.fad.fa-paw-claws:before, .fa-duotone.fa-paw-claws:before {
  content: "\f702\fe01"; }

.fad.fa-paw-claws:after, .fa-duotone.fa-paw-claws:after {
  content: "\f702\fe02"; }

.fad.fa-paw-simple:before, .fa-duotone.fa-paw-simple:before {
  content: "\f701\fe01"; }

.fad.fa-paw-simple:after, .fa-duotone.fa-paw-simple:after {
  content: "\f701\fe02"; }

.fad.fa-paw-alt:before, .fa-duotone.fa-paw-alt:before {
  content: "\f701\fe01"; }

.fad.fa-paw-alt:after, .fa-duotone.fa-paw-alt:after {
  content: "\f701\fe02"; }

.fad.fa-peace:before, .fa-duotone.fa-peace:before {
  content: "\262e\fe01"; }

.fad.fa-peace:after, .fa-duotone.fa-peace:after {
  content: "\262e\fe02"; }

.fad.fa-peach:before, .fa-duotone.fa-peach:before {
  content: "\e20b\fe01"; }

.fad.fa-peach:after, .fa-duotone.fa-peach:after {
  content: "\e20b\fe02"; }

.fad.fa-peapod:before, .fa-duotone.fa-peapod:before {
  content: "\e31c\fe01"; }

.fad.fa-peapod:after, .fa-duotone.fa-peapod:after {
  content: "\e31c\fe02"; }

.fad.fa-pear:before, .fa-duotone.fa-pear:before {
  content: "\e20c\fe01"; }

.fad.fa-pear:after, .fa-duotone.fa-pear:after {
  content: "\e20c\fe02"; }

.fad.fa-pedestal:before, .fa-duotone.fa-pedestal:before {
  content: "\e20d\fe01"; }

.fad.fa-pedestal:after, .fa-duotone.fa-pedestal:after {
  content: "\e20d\fe02"; }

.fad.fa-pegasus:before, .fa-duotone.fa-pegasus:before {
  content: "\f703\fe01"; }

.fad.fa-pegasus:after, .fa-duotone.fa-pegasus:after {
  content: "\f703\fe02"; }

.fad.fa-pen:before, .fa-duotone.fa-pen:before {
  content: "\f304"; }

.fad.fa-pen:after, .fa-duotone.fa-pen:after {
  content: "\10f304"; }

.fad.fa-pen-circle:before, .fa-duotone.fa-pen-circle:before {
  content: "\e20e\fe01"; }

.fad.fa-pen-circle:after, .fa-duotone.fa-pen-circle:after {
  content: "\e20e\fe02"; }

.fad.fa-pen-clip:before, .fa-duotone.fa-pen-clip:before {
  content: "\f305\fe01"; }

.fad.fa-pen-clip:after, .fa-duotone.fa-pen-clip:after {
  content: "\f305\fe02"; }

.fad.fa-pen-alt:before, .fa-duotone.fa-pen-alt:before {
  content: "\f305\fe01"; }

.fad.fa-pen-alt:after, .fa-duotone.fa-pen-alt:after {
  content: "\f305\fe02"; }

.fad.fa-pen-clip-slash:before, .fa-duotone.fa-pen-clip-slash:before {
  content: "\e20f\fe01"; }

.fad.fa-pen-clip-slash:after, .fa-duotone.fa-pen-clip-slash:after {
  content: "\e20f\fe02"; }

.fad.fa-pen-alt-slash:before, .fa-duotone.fa-pen-alt-slash:before {
  content: "\e20f\fe01"; }

.fad.fa-pen-alt-slash:after, .fa-duotone.fa-pen-alt-slash:after {
  content: "\e20f\fe02"; }

.fad.fa-pen-fancy:before, .fa-duotone.fa-pen-fancy:before {
  content: "\2712\fe01"; }

.fad.fa-pen-fancy:after, .fa-duotone.fa-pen-fancy:after {
  content: "\2712\fe02"; }

.fad.fa-pen-fancy-slash:before, .fa-duotone.fa-pen-fancy-slash:before {
  content: "\e210\fe01"; }

.fad.fa-pen-fancy-slash:after, .fa-duotone.fa-pen-fancy-slash:after {
  content: "\e210\fe02"; }

.fad.fa-pen-field:before, .fa-duotone.fa-pen-field:before {
  content: "\e211\fe01"; }

.fad.fa-pen-field:after, .fa-duotone.fa-pen-field:after {
  content: "\e211\fe02"; }

.fad.fa-pen-line:before, .fa-duotone.fa-pen-line:before {
  content: "\e212\fe01"; }

.fad.fa-pen-line:after, .fa-duotone.fa-pen-line:after {
  content: "\e212\fe02"; }

.fad.fa-pen-nib:before, .fa-duotone.fa-pen-nib:before {
  content: "\2711\fe01"; }

.fad.fa-pen-nib:after, .fa-duotone.fa-pen-nib:after {
  content: "\2711\fe02"; }

.fad.fa-pen-paintbrush:before, .fa-duotone.fa-pen-paintbrush:before {
  content: "\f618\fe01"; }

.fad.fa-pen-paintbrush:after, .fa-duotone.fa-pen-paintbrush:after {
  content: "\f618\fe02"; }

.fad.fa-pencil-paintbrush:before, .fa-duotone.fa-pencil-paintbrush:before {
  content: "\f618\fe01"; }

.fad.fa-pencil-paintbrush:after, .fa-duotone.fa-pencil-paintbrush:after {
  content: "\f618\fe02"; }

.fad.fa-pen-ruler:before, .fa-duotone.fa-pen-ruler:before {
  content: "\f5ae\fe01"; }

.fad.fa-pen-ruler:after, .fa-duotone.fa-pen-ruler:after {
  content: "\f5ae\fe02"; }

.fad.fa-pencil-ruler:before, .fa-duotone.fa-pencil-ruler:before {
  content: "\f5ae\fe01"; }

.fad.fa-pencil-ruler:after, .fa-duotone.fa-pencil-ruler:after {
  content: "\f5ae\fe02"; }

.fad.fa-pen-slash:before, .fa-duotone.fa-pen-slash:before {
  content: "\e213\fe01"; }

.fad.fa-pen-slash:after, .fa-duotone.fa-pen-slash:after {
  content: "\e213\fe02"; }

.fad.fa-pen-swirl:before, .fa-duotone.fa-pen-swirl:before {
  content: "\e214\fe01"; }

.fad.fa-pen-swirl:after, .fa-duotone.fa-pen-swirl:after {
  content: "\e214\fe02"; }

.fad.fa-pen-to-square:before, .fa-duotone.fa-pen-to-square:before {
  content: "\f044\fe01"; }

.fad.fa-pen-to-square:after, .fa-duotone.fa-pen-to-square:after {
  content: "\f044\fe02"; }

.fad.fa-edit:before, .fa-duotone.fa-edit:before {
  content: "\f044\fe01"; }

.fad.fa-edit:after, .fa-duotone.fa-edit:after {
  content: "\f044\fe02"; }

.fad.fa-pencil:before, .fa-duotone.fa-pencil:before {
  content: "\270f\fe01"; }

.fad.fa-pencil:after, .fa-duotone.fa-pencil:after {
  content: "\270f\fe02"; }

.fad.fa-pencil-alt:before, .fa-duotone.fa-pencil-alt:before {
  content: "\270f\fe01"; }

.fad.fa-pencil-alt:after, .fa-duotone.fa-pencil-alt:after {
  content: "\270f\fe02"; }

.fad.fa-pencil-slash:before, .fa-duotone.fa-pencil-slash:before {
  content: "\e215\fe01"; }

.fad.fa-pencil-slash:after, .fa-duotone.fa-pencil-slash:after {
  content: "\e215\fe02"; }

.fad.fa-people:before, .fa-duotone.fa-people:before {
  content: "\e216\fe01"; }

.fad.fa-people:after, .fa-duotone.fa-people:after {
  content: "\e216\fe02"; }

.fad.fa-people-arrows-left-right:before, .fa-duotone.fa-people-arrows-left-right:before {
  content: "\e068\fe01"; }

.fad.fa-people-arrows-left-right:after, .fa-duotone.fa-people-arrows-left-right:after {
  content: "\e068\fe02"; }

.fad.fa-people-arrows:before, .fa-duotone.fa-people-arrows:before {
  content: "\e068\fe01"; }

.fad.fa-people-arrows:after, .fa-duotone.fa-people-arrows:after {
  content: "\e068\fe02"; }

.fad.fa-people-carry-box:before, .fa-duotone.fa-people-carry-box:before {
  content: "\f4ce\fe01"; }

.fad.fa-people-carry-box:after, .fa-duotone.fa-people-carry-box:after {
  content: "\f4ce\fe02"; }

.fad.fa-people-carry:before, .fa-duotone.fa-people-carry:before {
  content: "\f4ce\fe01"; }

.fad.fa-people-carry:after, .fa-duotone.fa-people-carry:after {
  content: "\f4ce\fe02"; }

.fad.fa-people-dress:before, .fa-duotone.fa-people-dress:before {
  content: "\e217\fe01"; }

.fad.fa-people-dress:after, .fa-duotone.fa-people-dress:after {
  content: "\e217\fe02"; }

.fad.fa-people-dress-simple:before, .fa-duotone.fa-people-dress-simple:before {
  content: "\e218\fe01"; }

.fad.fa-people-dress-simple:after, .fa-duotone.fa-people-dress-simple:after {
  content: "\e218\fe02"; }

.fad.fa-people-pants:before, .fa-duotone.fa-people-pants:before {
  content: "\e219\fe01"; }

.fad.fa-people-pants:after, .fa-duotone.fa-people-pants:after {
  content: "\e219\fe02"; }

.fad.fa-people-pants-simple:before, .fa-duotone.fa-people-pants-simple:before {
  content: "\e21a\fe01"; }

.fad.fa-people-pants-simple:after, .fa-duotone.fa-people-pants-simple:after {
  content: "\e21a\fe02"; }

.fad.fa-people-simple:before, .fa-duotone.fa-people-simple:before {
  content: "\e21b\fe01"; }

.fad.fa-people-simple:after, .fa-duotone.fa-people-simple:after {
  content: "\e21b\fe02"; }

.fad.fa-pepper-hot:before, .fa-duotone.fa-pepper-hot:before {
  content: "\f816"; }

.fad.fa-pepper-hot:after, .fa-duotone.fa-pepper-hot:after {
  content: "\10f816"; }

.fad.fa-percent:before, .fa-duotone.fa-percent:before {
  content: "\25\fe01"; }

.fad.fa-percent:after, .fa-duotone.fa-percent:after {
  content: "\25\fe02"; }

.fad.fa-percentage:before, .fa-duotone.fa-percentage:before {
  content: "\25\fe01"; }

.fad.fa-percentage:after, .fa-duotone.fa-percentage:after {
  content: "\25\fe02"; }

.fad.fa-period:before, .fa-duotone.fa-period:before {
  content: "\2e\fe01"; }

.fad.fa-period:after, .fa-duotone.fa-period:after {
  content: "\2e\fe02"; }

.fad.fa-person:before, .fa-duotone.fa-person:before {
  content: "\f183"; }

.fad.fa-person:after, .fa-duotone.fa-person:after {
  content: "\10f183"; }

.fad.fa-male:before, .fa-duotone.fa-male:before {
  content: "\f183"; }

.fad.fa-male:after, .fa-duotone.fa-male:after {
  content: "\10f183"; }

.fad.fa-person-biking:before, .fa-duotone.fa-person-biking:before {
  content: "\f84a"; }

.fad.fa-person-biking:after, .fa-duotone.fa-person-biking:after {
  content: "\10f84a"; }

.fad.fa-biking:before, .fa-duotone.fa-biking:before {
  content: "\f84a"; }

.fad.fa-biking:after, .fa-duotone.fa-biking:after {
  content: "\10f84a"; }

.fad.fa-person-biking-mountain:before, .fa-duotone.fa-person-biking-mountain:before {
  content: "\f84b"; }

.fad.fa-person-biking-mountain:after, .fa-duotone.fa-person-biking-mountain:after {
  content: "\10f84b"; }

.fad.fa-biking-mountain:before, .fa-duotone.fa-biking-mountain:before {
  content: "\f84b"; }

.fad.fa-biking-mountain:after, .fa-duotone.fa-biking-mountain:after {
  content: "\10f84b"; }

.fad.fa-person-booth:before, .fa-duotone.fa-person-booth:before {
  content: "\f756\fe01"; }

.fad.fa-person-booth:after, .fa-duotone.fa-person-booth:after {
  content: "\f756\fe02"; }

.fad.fa-person-carry-box:before, .fa-duotone.fa-person-carry-box:before {
  content: "\f4cf\fe01"; }

.fad.fa-person-carry-box:after, .fa-duotone.fa-person-carry-box:after {
  content: "\f4cf\fe02"; }

.fad.fa-person-carry:before, .fa-duotone.fa-person-carry:before {
  content: "\f4cf\fe01"; }

.fad.fa-person-carry:after, .fa-duotone.fa-person-carry:after {
  content: "\f4cf\fe02"; }

.fad.fa-person-digging:before, .fa-duotone.fa-person-digging:before {
  content: "\f85e\fe01"; }

.fad.fa-person-digging:after, .fa-duotone.fa-person-digging:after {
  content: "\f85e\fe02"; }

.fad.fa-digging:before, .fa-duotone.fa-digging:before {
  content: "\f85e\fe01"; }

.fad.fa-digging:after, .fa-duotone.fa-digging:after {
  content: "\f85e\fe02"; }

.fad.fa-person-dolly:before, .fa-duotone.fa-person-dolly:before {
  content: "\f4d0\fe01"; }

.fad.fa-person-dolly:after, .fa-duotone.fa-person-dolly:after {
  content: "\f4d0\fe02"; }

.fad.fa-person-dolly-empty:before, .fa-duotone.fa-person-dolly-empty:before {
  content: "\f4d1\fe01"; }

.fad.fa-person-dolly-empty:after, .fa-duotone.fa-person-dolly-empty:after {
  content: "\f4d1\fe02"; }

.fad.fa-person-dots-from-line:before, .fa-duotone.fa-person-dots-from-line:before {
  content: "\f470\fe01"; }

.fad.fa-person-dots-from-line:after, .fa-duotone.fa-person-dots-from-line:after {
  content: "\f470\fe02"; }

.fad.fa-diagnoses:before, .fa-duotone.fa-diagnoses:before {
  content: "\f470\fe01"; }

.fad.fa-diagnoses:after, .fa-duotone.fa-diagnoses:after {
  content: "\f470\fe02"; }

.fad.fa-person-dress:before, .fa-duotone.fa-person-dress:before {
  content: "\f182\fe01"; }

.fad.fa-person-dress:after, .fa-duotone.fa-person-dress:after {
  content: "\f182\fe02"; }

.fad.fa-female:before, .fa-duotone.fa-female:before {
  content: "\f182\fe01"; }

.fad.fa-female:after, .fa-duotone.fa-female:after {
  content: "\f182\fe02"; }

.fad.fa-person-dress-simple:before, .fa-duotone.fa-person-dress-simple:before {
  content: "\e21c\fe01"; }

.fad.fa-person-dress-simple:after, .fa-duotone.fa-person-dress-simple:after {
  content: "\e21c\fe02"; }

.fad.fa-person-from-portal:before, .fa-duotone.fa-person-from-portal:before {
  content: "\e023\fe01"; }

.fad.fa-person-from-portal:after, .fa-duotone.fa-person-from-portal:after {
  content: "\e023\fe02"; }

.fad.fa-portal-exit:before, .fa-duotone.fa-portal-exit:before {
  content: "\e023\fe01"; }

.fad.fa-portal-exit:after, .fa-duotone.fa-portal-exit:after {
  content: "\e023\fe02"; }

.fad.fa-person-hiking:before, .fa-duotone.fa-person-hiking:before {
  content: "\f6ec\fe01"; }

.fad.fa-person-hiking:after, .fa-duotone.fa-person-hiking:after {
  content: "\f6ec\fe02"; }

.fad.fa-hiking:before, .fa-duotone.fa-hiking:before {
  content: "\f6ec\fe01"; }

.fad.fa-hiking:after, .fa-duotone.fa-hiking:after {
  content: "\f6ec\fe02"; }

.fad.fa-person-pinball:before, .fa-duotone.fa-person-pinball:before {
  content: "\e21d\fe01"; }

.fad.fa-person-pinball:after, .fa-duotone.fa-person-pinball:after {
  content: "\e21d\fe02"; }

.fad.fa-person-praying:before, .fa-duotone.fa-person-praying:before {
  content: "\f683"; }

.fad.fa-person-praying:after, .fa-duotone.fa-person-praying:after {
  content: "\10f683"; }

.fad.fa-pray:before, .fa-duotone.fa-pray:before {
  content: "\f683"; }

.fad.fa-pray:after, .fa-duotone.fa-pray:after {
  content: "\10f683"; }

.fad.fa-person-pregnant:before, .fa-duotone.fa-person-pregnant:before {
  content: "\e31e\fe01"; }

.fad.fa-person-pregnant:after, .fa-duotone.fa-person-pregnant:after {
  content: "\e31e\fe02"; }

.fad.fa-person-running:before, .fa-duotone.fa-person-running:before {
  content: "\f70c"; }

.fad.fa-person-running:after, .fa-duotone.fa-person-running:after {
  content: "\10f70c"; }

.fad.fa-running:before, .fa-duotone.fa-running:before {
  content: "\f70c"; }

.fad.fa-running:after, .fa-duotone.fa-running:after {
  content: "\10f70c"; }

.fad.fa-person-seat:before, .fa-duotone.fa-person-seat:before {
  content: "\e21e\fe01"; }

.fad.fa-person-seat:after, .fa-duotone.fa-person-seat:after {
  content: "\e21e\fe02"; }

.fad.fa-person-seat-reclined:before, .fa-duotone.fa-person-seat-reclined:before {
  content: "\e21f\fe01"; }

.fad.fa-person-seat-reclined:after, .fa-duotone.fa-person-seat-reclined:after {
  content: "\e21f\fe02"; }

.fad.fa-person-sign:before, .fa-duotone.fa-person-sign:before {
  content: "\f757\fe01"; }

.fad.fa-person-sign:after, .fa-duotone.fa-person-sign:after {
  content: "\f757\fe02"; }

.fad.fa-person-simple:before, .fa-duotone.fa-person-simple:before {
  content: "\e220\fe01"; }

.fad.fa-person-simple:after, .fa-duotone.fa-person-simple:after {
  content: "\e220\fe02"; }

.fad.fa-person-skating:before, .fa-duotone.fa-person-skating:before {
  content: "\f7c5\fe01"; }

.fad.fa-person-skating:after, .fa-duotone.fa-person-skating:after {
  content: "\f7c5\fe02"; }

.fad.fa-skating:before, .fa-duotone.fa-skating:before {
  content: "\f7c5\fe01"; }

.fad.fa-skating:after, .fa-duotone.fa-skating:after {
  content: "\f7c5\fe02"; }

.fad.fa-person-ski-jumping:before, .fa-duotone.fa-person-ski-jumping:before {
  content: "\f7c7\fe01"; }

.fad.fa-person-ski-jumping:after, .fa-duotone.fa-person-ski-jumping:after {
  content: "\f7c7\fe02"; }

.fad.fa-ski-jump:before, .fa-duotone.fa-ski-jump:before {
  content: "\f7c7\fe01"; }

.fad.fa-ski-jump:after, .fa-duotone.fa-ski-jump:after {
  content: "\f7c7\fe02"; }

.fad.fa-person-ski-lift:before, .fa-duotone.fa-person-ski-lift:before {
  content: "\f7c8\fe01"; }

.fad.fa-person-ski-lift:after, .fa-duotone.fa-person-ski-lift:after {
  content: "\f7c8\fe02"; }

.fad.fa-ski-lift:before, .fa-duotone.fa-ski-lift:before {
  content: "\f7c8\fe01"; }

.fad.fa-ski-lift:after, .fa-duotone.fa-ski-lift:after {
  content: "\f7c8\fe02"; }

.fad.fa-person-skiing:before, .fa-duotone.fa-person-skiing:before {
  content: "\26f7\fe01"; }

.fad.fa-person-skiing:after, .fa-duotone.fa-person-skiing:after {
  content: "\26f7\fe02"; }

.fad.fa-skiing:before, .fa-duotone.fa-skiing:before {
  content: "\26f7\fe01"; }

.fad.fa-skiing:after, .fa-duotone.fa-skiing:after {
  content: "\26f7\fe02"; }

.fad.fa-person-skiing-nordic:before, .fa-duotone.fa-person-skiing-nordic:before {
  content: "\f7ca\fe01"; }

.fad.fa-person-skiing-nordic:after, .fa-duotone.fa-person-skiing-nordic:after {
  content: "\f7ca\fe02"; }

.fad.fa-skiing-nordic:before, .fa-duotone.fa-skiing-nordic:before {
  content: "\f7ca\fe01"; }

.fad.fa-skiing-nordic:after, .fa-duotone.fa-skiing-nordic:after {
  content: "\f7ca\fe02"; }

.fad.fa-person-sledding:before, .fa-duotone.fa-person-sledding:before {
  content: "\f7cb\fe01"; }

.fad.fa-person-sledding:after, .fa-duotone.fa-person-sledding:after {
  content: "\f7cb\fe02"; }

.fad.fa-sledding:before, .fa-duotone.fa-sledding:before {
  content: "\f7cb\fe01"; }

.fad.fa-sledding:after, .fa-duotone.fa-sledding:after {
  content: "\f7cb\fe02"; }

.fad.fa-person-snowboarding:before, .fa-duotone.fa-person-snowboarding:before {
  content: "\f7ce"; }

.fad.fa-person-snowboarding:after, .fa-duotone.fa-person-snowboarding:after {
  content: "\10f7ce"; }

.fad.fa-snowboarding:before, .fa-duotone.fa-snowboarding:before {
  content: "\f7ce"; }

.fad.fa-snowboarding:after, .fa-duotone.fa-snowboarding:after {
  content: "\10f7ce"; }

.fad.fa-person-snowmobiling:before, .fa-duotone.fa-person-snowmobiling:before {
  content: "\f7d1\fe01"; }

.fad.fa-person-snowmobiling:after, .fa-duotone.fa-person-snowmobiling:after {
  content: "\f7d1\fe02"; }

.fad.fa-snowmobile:before, .fa-duotone.fa-snowmobile:before {
  content: "\f7d1\fe01"; }

.fad.fa-snowmobile:after, .fa-duotone.fa-snowmobile:after {
  content: "\f7d1\fe02"; }

.fad.fa-person-swimming:before, .fa-duotone.fa-person-swimming:before {
  content: "\f5c4"; }

.fad.fa-person-swimming:after, .fa-duotone.fa-person-swimming:after {
  content: "\10f5c4"; }

.fad.fa-swimmer:before, .fa-duotone.fa-swimmer:before {
  content: "\f5c4"; }

.fad.fa-swimmer:after, .fa-duotone.fa-swimmer:after {
  content: "\10f5c4"; }

.fad.fa-person-to-portal:before, .fa-duotone.fa-person-to-portal:before {
  content: "\e022\fe01"; }

.fad.fa-person-to-portal:after, .fa-duotone.fa-person-to-portal:after {
  content: "\e022\fe02"; }

.fad.fa-portal-enter:before, .fa-duotone.fa-portal-enter:before {
  content: "\e022\fe01"; }

.fad.fa-portal-enter:after, .fa-duotone.fa-portal-enter:after {
  content: "\e022\fe02"; }

.fad.fa-person-walking:before, .fa-duotone.fa-person-walking:before {
  content: "\f554"; }

.fad.fa-person-walking:after, .fa-duotone.fa-person-walking:after {
  content: "\10f554"; }

.fad.fa-walking:before, .fa-duotone.fa-walking:before {
  content: "\f554"; }

.fad.fa-walking:after, .fa-duotone.fa-walking:after {
  content: "\10f554"; }

.fad.fa-person-walking-with-cane:before, .fa-duotone.fa-person-walking-with-cane:before {
  content: "\f29d\fe01"; }

.fad.fa-person-walking-with-cane:after, .fa-duotone.fa-person-walking-with-cane:after {
  content: "\f29d\fe02"; }

.fad.fa-blind:before, .fa-duotone.fa-blind:before {
  content: "\f29d\fe01"; }

.fad.fa-blind:after, .fa-duotone.fa-blind:after {
  content: "\f29d\fe02"; }

.fad.fa-peseta-sign:before, .fa-duotone.fa-peseta-sign:before {
  content: "\e221\fe01"; }

.fad.fa-peseta-sign:after, .fa-duotone.fa-peseta-sign:after {
  content: "\e221\fe02"; }

.fad.fa-peso-sign:before, .fa-duotone.fa-peso-sign:before {
  content: "\e222\fe01"; }

.fad.fa-peso-sign:after, .fa-duotone.fa-peso-sign:after {
  content: "\e222\fe02"; }

.fad.fa-phone:before, .fa-duotone.fa-phone:before {
  content: "\f095"; }

.fad.fa-phone:after, .fa-duotone.fa-phone:after {
  content: "\10f095"; }

.fad.fa-phone-arrow-down-left:before, .fa-duotone.fa-phone-arrow-down-left:before {
  content: "\e223\fe01"; }

.fad.fa-phone-arrow-down-left:after, .fa-duotone.fa-phone-arrow-down-left:after {
  content: "\e223\fe02"; }

.fad.fa-phone-arrow-down:before, .fa-duotone.fa-phone-arrow-down:before {
  content: "\e223\fe01"; }

.fad.fa-phone-arrow-down:after, .fa-duotone.fa-phone-arrow-down:after {
  content: "\e223\fe02"; }

.fad.fa-phone-incoming:before, .fa-duotone.fa-phone-incoming:before {
  content: "\e223\fe01"; }

.fad.fa-phone-incoming:after, .fa-duotone.fa-phone-incoming:after {
  content: "\e223\fe02"; }

.fad.fa-phone-arrow-up-right:before, .fa-duotone.fa-phone-arrow-up-right:before {
  content: "\e224\fe01"; }

.fad.fa-phone-arrow-up-right:after, .fa-duotone.fa-phone-arrow-up-right:after {
  content: "\e224\fe02"; }

.fad.fa-phone-arrow-up:before, .fa-duotone.fa-phone-arrow-up:before {
  content: "\e224\fe01"; }

.fad.fa-phone-arrow-up:after, .fa-duotone.fa-phone-arrow-up:after {
  content: "\e224\fe02"; }

.fad.fa-phone-outgoing:before, .fa-duotone.fa-phone-outgoing:before {
  content: "\e224\fe01"; }

.fad.fa-phone-outgoing:after, .fa-duotone.fa-phone-outgoing:after {
  content: "\e224\fe02"; }

.fad.fa-phone-flip:before, .fa-duotone.fa-phone-flip:before {
  content: "\f879"; }

.fad.fa-phone-flip:after, .fa-duotone.fa-phone-flip:after {
  content: "\10f879"; }

.fad.fa-phone-alt:before, .fa-duotone.fa-phone-alt:before {
  content: "\f879"; }

.fad.fa-phone-alt:after, .fa-duotone.fa-phone-alt:after {
  content: "\10f879"; }

.fad.fa-phone-hangup:before, .fa-duotone.fa-phone-hangup:before {
  content: "\e225\fe01"; }

.fad.fa-phone-hangup:after, .fa-duotone.fa-phone-hangup:after {
  content: "\e225\fe02"; }

.fad.fa-phone-missed:before, .fa-duotone.fa-phone-missed:before {
  content: "\e226\fe01"; }

.fad.fa-phone-missed:after, .fa-duotone.fa-phone-missed:after {
  content: "\e226\fe02"; }

.fad.fa-phone-office:before, .fa-duotone.fa-phone-office:before {
  content: "\f67d\fe01"; }

.fad.fa-phone-office:after, .fa-duotone.fa-phone-office:after {
  content: "\f67d\fe02"; }

.fad.fa-phone-plus:before, .fa-duotone.fa-phone-plus:before {
  content: "\f4d2\fe01"; }

.fad.fa-phone-plus:after, .fa-duotone.fa-phone-plus:after {
  content: "\f4d2\fe02"; }

.fad.fa-phone-rotary:before, .fa-duotone.fa-phone-rotary:before {
  content: "\260e\fe01"; }

.fad.fa-phone-rotary:after, .fa-duotone.fa-phone-rotary:after {
  content: "\260e\fe02"; }

.fad.fa-phone-slash:before, .fa-duotone.fa-phone-slash:before {
  content: "\f3dd\fe01"; }

.fad.fa-phone-slash:after, .fa-duotone.fa-phone-slash:after {
  content: "\f3dd\fe02"; }

.fad.fa-phone-volume:before, .fa-duotone.fa-phone-volume:before {
  content: "\f2a0\fe01"; }

.fad.fa-phone-volume:after, .fa-duotone.fa-phone-volume:after {
  content: "\f2a0\fe02"; }

.fad.fa-volume-control-phone:before, .fa-duotone.fa-volume-control-phone:before {
  content: "\f2a0\fe01"; }

.fad.fa-volume-control-phone:after, .fa-duotone.fa-volume-control-phone:after {
  content: "\f2a0\fe02"; }

.fad.fa-phone-xmark:before, .fa-duotone.fa-phone-xmark:before {
  content: "\e227\fe01"; }

.fad.fa-phone-xmark:after, .fa-duotone.fa-phone-xmark:after {
  content: "\e227\fe02"; }

.fad.fa-photo-film:before, .fa-duotone.fa-photo-film:before {
  content: "\f87c\fe01"; }

.fad.fa-photo-film:after, .fa-duotone.fa-photo-film:after {
  content: "\f87c\fe02"; }

.fad.fa-photo-video:before, .fa-duotone.fa-photo-video:before {
  content: "\f87c\fe01"; }

.fad.fa-photo-video:after, .fa-duotone.fa-photo-video:after {
  content: "\f87c\fe02"; }

.fad.fa-photo-film-music:before, .fa-duotone.fa-photo-film-music:before {
  content: "\e228\fe01"; }

.fad.fa-photo-film-music:after, .fa-duotone.fa-photo-film-music:after {
  content: "\e228\fe02"; }

.fad.fa-pi:before, .fa-duotone.fa-pi:before {
  content: "\f67e\fe01"; }

.fad.fa-pi:after, .fa-duotone.fa-pi:after {
  content: "\f67e\fe02"; }

.fad.fa-piano:before, .fa-duotone.fa-piano:before {
  content: "\f8d4\fe01"; }

.fad.fa-piano:after, .fa-duotone.fa-piano:after {
  content: "\f8d4\fe02"; }

.fad.fa-piano-keyboard:before, .fa-duotone.fa-piano-keyboard:before {
  content: "\f8d5"; }

.fad.fa-piano-keyboard:after, .fa-duotone.fa-piano-keyboard:after {
  content: "\10f8d5"; }

.fad.fa-pie:before, .fa-duotone.fa-pie:before {
  content: "\f705"; }

.fad.fa-pie:after, .fa-duotone.fa-pie:after {
  content: "\10f705"; }

.fad.fa-pig:before, .fa-duotone.fa-pig:before {
  content: "\f706"; }

.fad.fa-pig:after, .fa-duotone.fa-pig:after {
  content: "\10f706"; }

.fad.fa-piggy-bank:before, .fa-duotone.fa-piggy-bank:before {
  content: "\f4d3\fe01"; }

.fad.fa-piggy-bank:after, .fa-duotone.fa-piggy-bank:after {
  content: "\f4d3\fe02"; }

.fad.fa-pills:before, .fa-duotone.fa-pills:before {
  content: "\f484\fe01"; }

.fad.fa-pills:after, .fa-duotone.fa-pills:after {
  content: "\f484\fe02"; }

.fad.fa-pinata:before, .fa-duotone.fa-pinata:before {
  content: "\e3c3\fe01"; }

.fad.fa-pinata:after, .fa-duotone.fa-pinata:after {
  content: "\e3c3\fe02"; }

.fad.fa-pinball:before, .fa-duotone.fa-pinball:before {
  content: "\e229\fe01"; }

.fad.fa-pinball:after, .fa-duotone.fa-pinball:after {
  content: "\e229\fe02"; }

.fad.fa-pineapple:before, .fa-duotone.fa-pineapple:before {
  content: "\e31f\fe01"; }

.fad.fa-pineapple:after, .fa-duotone.fa-pineapple:after {
  content: "\e31f\fe02"; }

.fad.fa-pipe:before, .fa-duotone.fa-pipe:before {
  content: "\7c\fe01"; }

.fad.fa-pipe:after, .fa-duotone.fa-pipe:after {
  content: "\7c\fe02"; }

.fad.fa-pipe-smoking:before, .fa-duotone.fa-pipe-smoking:before {
  content: "\e3c4\fe01"; }

.fad.fa-pipe-smoking:after, .fa-duotone.fa-pipe-smoking:after {
  content: "\e3c4\fe02"; }

.fad.fa-pizza:before, .fa-duotone.fa-pizza:before {
  content: "\f817"; }

.fad.fa-pizza:after, .fa-duotone.fa-pizza:after {
  content: "\10f817"; }

.fad.fa-pizza-slice:before, .fa-duotone.fa-pizza-slice:before {
  content: "\f818\fe01"; }

.fad.fa-pizza-slice:after, .fa-duotone.fa-pizza-slice:after {
  content: "\f818\fe02"; }

.fad.fa-place-of-worship:before, .fa-duotone.fa-place-of-worship:before {
  content: "\f67f\fe01"; }

.fad.fa-place-of-worship:after, .fa-duotone.fa-place-of-worship:after {
  content: "\f67f\fe02"; }

.fad.fa-plane:before, .fa-duotone.fa-plane:before {
  content: "\f072\fe01"; }

.fad.fa-plane:after, .fa-duotone.fa-plane:after {
  content: "\f072\fe02"; }

.fad.fa-plane-arrival:before, .fa-duotone.fa-plane-arrival:before {
  content: "\f5af"; }

.fad.fa-plane-arrival:after, .fa-duotone.fa-plane-arrival:after {
  content: "\10f5af"; }

.fad.fa-plane-departure:before, .fa-duotone.fa-plane-departure:before {
  content: "\f5b0"; }

.fad.fa-plane-departure:after, .fa-duotone.fa-plane-departure:after {
  content: "\10f5b0"; }

.fad.fa-plane-engines:before, .fa-duotone.fa-plane-engines:before {
  content: "\2708\fe01"; }

.fad.fa-plane-engines:after, .fa-duotone.fa-plane-engines:after {
  content: "\2708\fe02"; }

.fad.fa-plane-alt:before, .fa-duotone.fa-plane-alt:before {
  content: "\2708\fe01"; }

.fad.fa-plane-alt:after, .fa-duotone.fa-plane-alt:after {
  content: "\2708\fe02"; }

.fad.fa-plane-prop:before, .fa-duotone.fa-plane-prop:before {
  content: "\e22b\fe01"; }

.fad.fa-plane-prop:after, .fa-duotone.fa-plane-prop:after {
  content: "\e22b\fe02"; }

.fad.fa-plane-slash:before, .fa-duotone.fa-plane-slash:before {
  content: "\e069\fe01"; }

.fad.fa-plane-slash:after, .fa-duotone.fa-plane-slash:after {
  content: "\e069\fe02"; }

.fad.fa-plane-tail:before, .fa-duotone.fa-plane-tail:before {
  content: "\e22c\fe01"; }

.fad.fa-plane-tail:after, .fa-duotone.fa-plane-tail:after {
  content: "\e22c\fe02"; }

.fad.fa-plane-up:before, .fa-duotone.fa-plane-up:before {
  content: "\e22d\fe01"; }

.fad.fa-plane-up:after, .fa-duotone.fa-plane-up:after {
  content: "\e22d\fe02"; }

.fad.fa-plane-up-slash:before, .fa-duotone.fa-plane-up-slash:before {
  content: "\e22e\fe01"; }

.fad.fa-plane-up-slash:after, .fa-duotone.fa-plane-up-slash:after {
  content: "\e22e\fe02"; }

.fad.fa-planet-moon:before, .fa-duotone.fa-planet-moon:before {
  content: "\e01f\fe01"; }

.fad.fa-planet-moon:after, .fa-duotone.fa-planet-moon:after {
  content: "\e01f\fe02"; }

.fad.fa-planet-ringed:before, .fa-duotone.fa-planet-ringed:before {
  content: "\e020"; }

.fad.fa-planet-ringed:after, .fa-duotone.fa-planet-ringed:after {
  content: "\10e020"; }

.fad.fa-play:before, .fa-duotone.fa-play:before {
  content: "\25b6\fe01"; }

.fad.fa-play:after, .fa-duotone.fa-play:after {
  content: "\25b6\fe02"; }

.fad.fa-play-pause:before, .fa-duotone.fa-play-pause:before {
  content: "\e22f\fe01"; }

.fad.fa-play-pause:after, .fa-duotone.fa-play-pause:after {
  content: "\e22f\fe02"; }

.fad.fa-plug:before, .fa-duotone.fa-plug:before {
  content: "\f1e6"; }

.fad.fa-plug:after, .fa-duotone.fa-plug:after {
  content: "\10f1e6"; }

.fad.fa-plus:before, .fa-duotone.fa-plus:before {
  content: "\2b\fe01"; }

.fad.fa-plus:after, .fa-duotone.fa-plus:after {
  content: "\2b\fe02"; }

.fad.fa-add:before, .fa-duotone.fa-add:before {
  content: "\2b\fe01"; }

.fad.fa-add:after, .fa-duotone.fa-add:after {
  content: "\2b\fe02"; }

.fad.fa-plus-minus:before, .fa-duotone.fa-plus-minus:before {
  content: "\b1\fe01"; }

.fad.fa-plus-minus:after, .fa-duotone.fa-plus-minus:after {
  content: "\b1\fe02"; }

.fad.fa-podcast:before, .fa-duotone.fa-podcast:before {
  content: "\f2ce\fe01"; }

.fad.fa-podcast:after, .fa-duotone.fa-podcast:after {
  content: "\f2ce\fe02"; }

.fad.fa-podium:before, .fa-duotone.fa-podium:before {
  content: "\f680\fe01"; }

.fad.fa-podium:after, .fa-duotone.fa-podium:after {
  content: "\f680\fe02"; }

.fad.fa-podium-star:before, .fa-duotone.fa-podium-star:before {
  content: "\f758\fe01"; }

.fad.fa-podium-star:after, .fa-duotone.fa-podium-star:after {
  content: "\f758\fe02"; }

.fad.fa-police-box:before, .fa-duotone.fa-police-box:before {
  content: "\e021\fe01"; }

.fad.fa-police-box:after, .fa-duotone.fa-police-box:after {
  content: "\e021\fe02"; }

.fad.fa-poll-people:before, .fa-duotone.fa-poll-people:before {
  content: "\f759\fe01"; }

.fad.fa-poll-people:after, .fa-duotone.fa-poll-people:after {
  content: "\f759\fe02"; }

.fad.fa-poo:before, .fa-duotone.fa-poo:before {
  content: "\f2fe"; }

.fad.fa-poo:after, .fa-duotone.fa-poo:after {
  content: "\10f2fe"; }

.fad.fa-poo-bolt:before, .fa-duotone.fa-poo-bolt:before {
  content: "\f75a\fe01"; }

.fad.fa-poo-bolt:after, .fa-duotone.fa-poo-bolt:after {
  content: "\f75a\fe02"; }

.fad.fa-poo-storm:before, .fa-duotone.fa-poo-storm:before {
  content: "\f75a\fe01"; }

.fad.fa-poo-storm:after, .fa-duotone.fa-poo-storm:after {
  content: "\f75a\fe02"; }

.fad.fa-pool-8-ball:before, .fa-duotone.fa-pool-8-ball:before {
  content: "\e3c5\fe01"; }

.fad.fa-pool-8-ball:after, .fa-duotone.fa-pool-8-ball:after {
  content: "\e3c5\fe02"; }

.fad.fa-poop:before, .fa-duotone.fa-poop:before {
  content: "\f619\fe01"; }

.fad.fa-poop:after, .fa-duotone.fa-poop:after {
  content: "\f619\fe02"; }

.fad.fa-popcorn:before, .fa-duotone.fa-popcorn:before {
  content: "\f819"; }

.fad.fa-popcorn:after, .fa-duotone.fa-popcorn:after {
  content: "\10f819"; }

.fad.fa-power-off:before, .fa-duotone.fa-power-off:before {
  content: "\23fb\fe01"; }

.fad.fa-power-off:after, .fa-duotone.fa-power-off:after {
  content: "\23fb\fe02"; }

.fad.fa-prescription:before, .fa-duotone.fa-prescription:before {
  content: "\f5b1\fe01"; }

.fad.fa-prescription:after, .fa-duotone.fa-prescription:after {
  content: "\f5b1\fe02"; }

.fad.fa-prescription-bottle:before, .fa-duotone.fa-prescription-bottle:before {
  content: "\f485\fe01"; }

.fad.fa-prescription-bottle:after, .fa-duotone.fa-prescription-bottle:after {
  content: "\f485\fe02"; }

.fad.fa-prescription-bottle-medical:before, .fa-duotone.fa-prescription-bottle-medical:before {
  content: "\f486\fe01"; }

.fad.fa-prescription-bottle-medical:after, .fa-duotone.fa-prescription-bottle-medical:after {
  content: "\f486\fe02"; }

.fad.fa-prescription-bottle-alt:before, .fa-duotone.fa-prescription-bottle-alt:before {
  content: "\f486\fe01"; }

.fad.fa-prescription-bottle-alt:after, .fa-duotone.fa-prescription-bottle-alt:after {
  content: "\f486\fe02"; }

.fad.fa-presentation-screen:before, .fa-duotone.fa-presentation-screen:before {
  content: "\f685\fe01"; }

.fad.fa-presentation-screen:after, .fa-duotone.fa-presentation-screen:after {
  content: "\f685\fe02"; }

.fad.fa-presentation:before, .fa-duotone.fa-presentation:before {
  content: "\f685\fe01"; }

.fad.fa-presentation:after, .fa-duotone.fa-presentation:after {
  content: "\f685\fe02"; }

.fad.fa-print:before, .fa-duotone.fa-print:before {
  content: "\2399\fe01"; }

.fad.fa-print:after, .fa-duotone.fa-print:after {
  content: "\2399\fe02"; }

.fad.fa-print-magnifying-glass:before, .fa-duotone.fa-print-magnifying-glass:before {
  content: "\f81a\fe01"; }

.fad.fa-print-magnifying-glass:after, .fa-duotone.fa-print-magnifying-glass:after {
  content: "\f81a\fe02"; }

.fad.fa-print-search:before, .fa-duotone.fa-print-search:before {
  content: "\f81a\fe01"; }

.fad.fa-print-search:after, .fa-duotone.fa-print-search:after {
  content: "\f81a\fe02"; }

.fad.fa-print-slash:before, .fa-duotone.fa-print-slash:before {
  content: "\f686\fe01"; }

.fad.fa-print-slash:after, .fa-duotone.fa-print-slash:after {
  content: "\f686\fe02"; }

.fad.fa-projector:before, .fa-duotone.fa-projector:before {
  content: "\f8d6\fe01"; }

.fad.fa-projector:after, .fa-duotone.fa-projector:after {
  content: "\f8d6\fe02"; }

.fad.fa-pump-medical:before, .fa-duotone.fa-pump-medical:before {
  content: "\e06a\fe01"; }

.fad.fa-pump-medical:after, .fa-duotone.fa-pump-medical:after {
  content: "\e06a\fe02"; }

.fad.fa-pump-soap:before, .fa-duotone.fa-pump-soap:before {
  content: "\e06b\fe01"; }

.fad.fa-pump-soap:after, .fa-duotone.fa-pump-soap:after {
  content: "\e06b\fe02"; }

.fad.fa-pumpkin:before, .fa-duotone.fa-pumpkin:before {
  content: "\f707\fe01"; }

.fad.fa-pumpkin:after, .fa-duotone.fa-pumpkin:after {
  content: "\f707\fe02"; }

.fad.fa-puzzle-piece:before, .fa-duotone.fa-puzzle-piece:before {
  content: "\f12e"; }

.fad.fa-puzzle-piece:after, .fa-duotone.fa-puzzle-piece:after {
  content: "\10f12e"; }

.fad.fa-puzzle-piece-simple:before, .fa-duotone.fa-puzzle-piece-simple:before {
  content: "\e231\fe01"; }

.fad.fa-puzzle-piece-simple:after, .fa-duotone.fa-puzzle-piece-simple:after {
  content: "\e231\fe02"; }

.fad.fa-puzzle-piece-alt:before, .fa-duotone.fa-puzzle-piece-alt:before {
  content: "\e231\fe01"; }

.fad.fa-puzzle-piece-alt:after, .fa-duotone.fa-puzzle-piece-alt:after {
  content: "\e231\fe02"; }

.fad.fa-q:before, .fa-duotone.fa-q:before {
  content: "\51\fe01"; }

.fad.fa-q:after, .fa-duotone.fa-q:after {
  content: "\51\fe02"; }

.fad.fa-qrcode:before, .fa-duotone.fa-qrcode:before {
  content: "\f029\fe01"; }

.fad.fa-qrcode:after, .fa-duotone.fa-qrcode:after {
  content: "\f029\fe02"; }

.fad.fa-question:before, .fa-duotone.fa-question:before {
  content: "\3f\fe01"; }

.fad.fa-question:after, .fa-duotone.fa-question:after {
  content: "\3f\fe02"; }

.fad.fa-quidditch:before, .fa-duotone.fa-quidditch:before {
  content: "\f458\fe01"; }

.fad.fa-quidditch:after, .fa-duotone.fa-quidditch:after {
  content: "\f458\fe02"; }

.fad.fa-broom-ball:before, .fa-duotone.fa-broom-ball:before {
  content: "\f458\fe01"; }

.fad.fa-broom-ball:after, .fa-duotone.fa-broom-ball:after {
  content: "\f458\fe02"; }

.fad.fa-quidditch-broom-ball:before, .fa-duotone.fa-quidditch-broom-ball:before {
  content: "\f458\fe01"; }

.fad.fa-quidditch-broom-ball:after, .fa-duotone.fa-quidditch-broom-ball:after {
  content: "\f458\fe02"; }

.fad.fa-quote-left:before, .fa-duotone.fa-quote-left:before {
  content: "\201c\fe01"; }

.fad.fa-quote-left:after, .fa-duotone.fa-quote-left:after {
  content: "\201c\fe02"; }

.fad.fa-quote-left-alt:before, .fa-duotone.fa-quote-left-alt:before {
  content: "\201c\fe01"; }

.fad.fa-quote-left-alt:after, .fa-duotone.fa-quote-left-alt:after {
  content: "\201c\fe02"; }

.fad.fa-quote-right:before, .fa-duotone.fa-quote-right:before {
  content: "\201d\fe01"; }

.fad.fa-quote-right:after, .fa-duotone.fa-quote-right:after {
  content: "\201d\fe02"; }

.fad.fa-quote-right-alt:before, .fa-duotone.fa-quote-right-alt:before {
  content: "\201d\fe01"; }

.fad.fa-quote-right-alt:after, .fa-duotone.fa-quote-right-alt:after {
  content: "\201d\fe02"; }

.fad.fa-quotes:before, .fa-duotone.fa-quotes:before {
  content: "\e234\fe01"; }

.fad.fa-quotes:after, .fa-duotone.fa-quotes:after {
  content: "\e234\fe02"; }

.fad.fa-r:before, .fa-duotone.fa-r:before {
  content: "\52\fe01"; }

.fad.fa-r:after, .fa-duotone.fa-r:after {
  content: "\52\fe02"; }

.fad.fa-rabbit:before, .fa-duotone.fa-rabbit:before {
  content: "\f708"; }

.fad.fa-rabbit:after, .fa-duotone.fa-rabbit:after {
  content: "\10f708"; }

.fad.fa-rabbit-running:before, .fa-duotone.fa-rabbit-running:before {
  content: "\f709\fe01"; }

.fad.fa-rabbit-running:after, .fa-duotone.fa-rabbit-running:after {
  content: "\f709\fe02"; }

.fad.fa-rabbit-fast:before, .fa-duotone.fa-rabbit-fast:before {
  content: "\f709\fe01"; }

.fad.fa-rabbit-fast:after, .fa-duotone.fa-rabbit-fast:after {
  content: "\f709\fe02"; }

.fad.fa-racquet:before, .fa-duotone.fa-racquet:before {
  content: "\f45a\fe01"; }

.fad.fa-racquet:after, .fa-duotone.fa-racquet:after {
  content: "\f45a\fe02"; }

.fad.fa-radar:before, .fa-duotone.fa-radar:before {
  content: "\e024\fe01"; }

.fad.fa-radar:after, .fa-duotone.fa-radar:after {
  content: "\e024\fe02"; }

.fad.fa-radiation:before, .fa-duotone.fa-radiation:before {
  content: "\f7b9\fe01"; }

.fad.fa-radiation:after, .fa-duotone.fa-radiation:after {
  content: "\f7b9\fe02"; }

.fad.fa-radio:before, .fa-duotone.fa-radio:before {
  content: "\f8d7"; }

.fad.fa-radio:after, .fa-duotone.fa-radio:after {
  content: "\10f8d7"; }

.fad.fa-radio-tuner:before, .fa-duotone.fa-radio-tuner:before {
  content: "\f8d8\fe01"; }

.fad.fa-radio-tuner:after, .fa-duotone.fa-radio-tuner:after {
  content: "\f8d8\fe02"; }

.fad.fa-radio-alt:before, .fa-duotone.fa-radio-alt:before {
  content: "\f8d8\fe01"; }

.fad.fa-radio-alt:after, .fa-duotone.fa-radio-alt:after {
  content: "\f8d8\fe02"; }

.fad.fa-rainbow:before, .fa-duotone.fa-rainbow:before {
  content: "\f75b"; }

.fad.fa-rainbow:after, .fa-duotone.fa-rainbow:after {
  content: "\10f75b"; }

.fad.fa-raindrops:before, .fa-duotone.fa-raindrops:before {
  content: "\f75c\fe01"; }

.fad.fa-raindrops:after, .fa-duotone.fa-raindrops:after {
  content: "\f75c\fe02"; }

.fad.fa-ram:before, .fa-duotone.fa-ram:before {
  content: "\f70a"; }

.fad.fa-ram:after, .fa-duotone.fa-ram:after {
  content: "\10f70a"; }

.fad.fa-ramp-loading:before, .fa-duotone.fa-ramp-loading:before {
  content: "\f4d4\fe01"; }

.fad.fa-ramp-loading:after, .fa-duotone.fa-ramp-loading:after {
  content: "\f4d4\fe02"; }

.fad.fa-raygun:before, .fa-duotone.fa-raygun:before {
  content: "\e025\fe01"; }

.fad.fa-raygun:after, .fa-duotone.fa-raygun:after {
  content: "\e025\fe02"; }

.fad.fa-receipt:before, .fa-duotone.fa-receipt:before {
  content: "\f543"; }

.fad.fa-receipt:after, .fa-duotone.fa-receipt:after {
  content: "\10f543"; }

.fad.fa-record-vinyl:before, .fa-duotone.fa-record-vinyl:before {
  content: "\f8d9\fe01"; }

.fad.fa-record-vinyl:after, .fa-duotone.fa-record-vinyl:after {
  content: "\f8d9\fe02"; }

.fad.fa-rectangle:before, .fa-duotone.fa-rectangle:before {
  content: "\25ac\fe01"; }

.fad.fa-rectangle:after, .fa-duotone.fa-rectangle:after {
  content: "\25ac\fe02"; }

.fad.fa-rectangle-landscape:before, .fa-duotone.fa-rectangle-landscape:before {
  content: "\25ac\fe01"; }

.fad.fa-rectangle-landscape:after, .fa-duotone.fa-rectangle-landscape:after {
  content: "\25ac\fe02"; }

.fad.fa-rectangle-ad:before, .fa-duotone.fa-rectangle-ad:before {
  content: "\f641\fe01"; }

.fad.fa-rectangle-ad:after, .fa-duotone.fa-rectangle-ad:after {
  content: "\f641\fe02"; }

.fad.fa-ad:before, .fa-duotone.fa-ad:before {
  content: "\f641\fe01"; }

.fad.fa-ad:after, .fa-duotone.fa-ad:after {
  content: "\f641\fe02"; }

.fad.fa-rectangle-barcode:before, .fa-duotone.fa-rectangle-barcode:before {
  content: "\f463\fe01"; }

.fad.fa-rectangle-barcode:after, .fa-duotone.fa-rectangle-barcode:after {
  content: "\f463\fe02"; }

.fad.fa-barcode-alt:before, .fa-duotone.fa-barcode-alt:before {
  content: "\f463\fe01"; }

.fad.fa-barcode-alt:after, .fa-duotone.fa-barcode-alt:after {
  content: "\f463\fe02"; }

.fad.fa-rectangle-code:before, .fa-duotone.fa-rectangle-code:before {
  content: "\e322\fe01"; }

.fad.fa-rectangle-code:after, .fa-duotone.fa-rectangle-code:after {
  content: "\e322\fe02"; }

.fad.fa-rectangle-list:before, .fa-duotone.fa-rectangle-list:before {
  content: "\f022\fe01"; }

.fad.fa-rectangle-list:after, .fa-duotone.fa-rectangle-list:after {
  content: "\f022\fe02"; }

.fad.fa-list-alt:before, .fa-duotone.fa-list-alt:before {
  content: "\f022\fe01"; }

.fad.fa-list-alt:after, .fa-duotone.fa-list-alt:after {
  content: "\f022\fe02"; }

.fad.fa-rectangle-pro:before, .fa-duotone.fa-rectangle-pro:before {
  content: "\e235\fe01"; }

.fad.fa-rectangle-pro:after, .fa-duotone.fa-rectangle-pro:after {
  content: "\e235\fe02"; }

.fad.fa-pro:before, .fa-duotone.fa-pro:before {
  content: "\e235\fe01"; }

.fad.fa-pro:after, .fa-duotone.fa-pro:after {
  content: "\e235\fe02"; }

.fad.fa-rectangle-terminal:before, .fa-duotone.fa-rectangle-terminal:before {
  content: "\e236\fe01"; }

.fad.fa-rectangle-terminal:after, .fa-duotone.fa-rectangle-terminal:after {
  content: "\e236\fe02"; }

.fad.fa-rectangle-vertical:before, .fa-duotone.fa-rectangle-vertical:before {
  content: "\f2fb\fe01"; }

.fad.fa-rectangle-vertical:after, .fa-duotone.fa-rectangle-vertical:after {
  content: "\f2fb\fe02"; }

.fad.fa-rectangle-portrait:before, .fa-duotone.fa-rectangle-portrait:before {
  content: "\f2fb\fe01"; }

.fad.fa-rectangle-portrait:after, .fa-duotone.fa-rectangle-portrait:after {
  content: "\f2fb\fe02"; }

.fad.fa-rectangle-vertical-history:before, .fa-duotone.fa-rectangle-vertical-history:before {
  content: "\e237\fe01"; }

.fad.fa-rectangle-vertical-history:after, .fa-duotone.fa-rectangle-vertical-history:after {
  content: "\e237\fe02"; }

.fad.fa-rectangle-wide:before, .fa-duotone.fa-rectangle-wide:before {
  content: "\f2fc\fe01"; }

.fad.fa-rectangle-wide:after, .fa-duotone.fa-rectangle-wide:after {
  content: "\f2fc\fe02"; }

.fad.fa-rectangle-xmark:before, .fa-duotone.fa-rectangle-xmark:before {
  content: "\f410\fe01"; }

.fad.fa-rectangle-xmark:after, .fa-duotone.fa-rectangle-xmark:after {
  content: "\f410\fe02"; }

.fad.fa-rectangle-times:before, .fa-duotone.fa-rectangle-times:before {
  content: "\f410\fe01"; }

.fad.fa-rectangle-times:after, .fa-duotone.fa-rectangle-times:after {
  content: "\f410\fe02"; }

.fad.fa-times-rectangle:before, .fa-duotone.fa-times-rectangle:before {
  content: "\f410\fe01"; }

.fad.fa-times-rectangle:after, .fa-duotone.fa-times-rectangle:after {
  content: "\f410\fe02"; }

.fad.fa-window-close:before, .fa-duotone.fa-window-close:before {
  content: "\f410\fe01"; }

.fad.fa-window-close:after, .fa-duotone.fa-window-close:after {
  content: "\f410\fe02"; }

.fad.fa-rectangles-mixed:before, .fa-duotone.fa-rectangles-mixed:before {
  content: "\e323\fe01"; }

.fad.fa-rectangles-mixed:after, .fa-duotone.fa-rectangles-mixed:after {
  content: "\e323\fe02"; }

.fad.fa-recycle:before, .fa-duotone.fa-recycle:before {
  content: "\2672\fe01"; }

.fad.fa-recycle:after, .fa-duotone.fa-recycle:after {
  content: "\2672\fe02"; }

.fad.fa-reel:before, .fa-duotone.fa-reel:before {
  content: "\e238\fe01"; }

.fad.fa-reel:after, .fa-duotone.fa-reel:after {
  content: "\e238\fe02"; }

.fad.fa-refrigerator:before, .fa-duotone.fa-refrigerator:before {
  content: "\e026\fe01"; }

.fad.fa-refrigerator:after, .fa-duotone.fa-refrigerator:after {
  content: "\e026\fe02"; }

.fad.fa-registered:before, .fa-duotone.fa-registered:before {
  content: "\ae\fe01"; }

.fad.fa-registered:after, .fa-duotone.fa-registered:after {
  content: "\ae\fe02"; }

.fad.fa-repeat:before, .fa-duotone.fa-repeat:before {
  content: "\f363"; }

.fad.fa-repeat:after, .fa-duotone.fa-repeat:after {
  content: "\10f363"; }

.fad.fa-repeat-1:before, .fa-duotone.fa-repeat-1:before {
  content: "\f365"; }

.fad.fa-repeat-1:after, .fa-duotone.fa-repeat-1:after {
  content: "\10f365"; }

.fad.fa-reply:before, .fa-duotone.fa-reply:before {
  content: "\f3e5\fe01"; }

.fad.fa-reply:after, .fa-duotone.fa-reply:after {
  content: "\f3e5\fe02"; }

.fad.fa-mail-reply:before, .fa-duotone.fa-mail-reply:before {
  content: "\f3e5\fe01"; }

.fad.fa-mail-reply:after, .fa-duotone.fa-mail-reply:after {
  content: "\f3e5\fe02"; }

.fad.fa-reply-all:before, .fa-duotone.fa-reply-all:before {
  content: "\f122\fe01"; }

.fad.fa-reply-all:after, .fa-duotone.fa-reply-all:after {
  content: "\f122\fe02"; }

.fad.fa-mail-reply-all:before, .fa-duotone.fa-mail-reply-all:before {
  content: "\f122\fe01"; }

.fad.fa-mail-reply-all:after, .fa-duotone.fa-mail-reply-all:after {
  content: "\f122\fe02"; }

.fad.fa-reply-clock:before, .fa-duotone.fa-reply-clock:before {
  content: "\e239\fe01"; }

.fad.fa-reply-clock:after, .fa-duotone.fa-reply-clock:after {
  content: "\e239\fe02"; }

.fad.fa-reply-time:before, .fa-duotone.fa-reply-time:before {
  content: "\e239\fe01"; }

.fad.fa-reply-time:after, .fa-duotone.fa-reply-time:after {
  content: "\e239\fe02"; }

.fad.fa-republican:before, .fa-duotone.fa-republican:before {
  content: "\f75e\fe01"; }

.fad.fa-republican:after, .fa-duotone.fa-republican:after {
  content: "\f75e\fe02"; }

.fad.fa-restroom:before, .fa-duotone.fa-restroom:before {
  content: "\f7bd\fe01"; }

.fad.fa-restroom:after, .fa-duotone.fa-restroom:after {
  content: "\f7bd\fe02"; }

.fad.fa-restroom-simple:before, .fa-duotone.fa-restroom-simple:before {
  content: "\e23a\fe01"; }

.fad.fa-restroom-simple:after, .fa-duotone.fa-restroom-simple:after {
  content: "\e23a\fe02"; }

.fad.fa-retweet:before, .fa-duotone.fa-retweet:before {
  content: "\f079\fe01"; }

.fad.fa-retweet:after, .fa-duotone.fa-retweet:after {
  content: "\f079\fe02"; }

.fad.fa-rhombus:before, .fa-duotone.fa-rhombus:before {
  content: "\e23b\fe01"; }

.fad.fa-rhombus:after, .fa-duotone.fa-rhombus:after {
  content: "\e23b\fe02"; }

.fad.fa-ribbon:before, .fa-duotone.fa-ribbon:before {
  content: "\f4d6"; }

.fad.fa-ribbon:after, .fa-duotone.fa-ribbon:after {
  content: "\10f4d6"; }

.fad.fa-right:before, .fa-duotone.fa-right:before {
  content: "\2b95\fe01"; }

.fad.fa-right:after, .fa-duotone.fa-right:after {
  content: "\2b95\fe02"; }

.fad.fa-arrow-alt-right:before, .fa-duotone.fa-arrow-alt-right:before {
  content: "\2b95\fe01"; }

.fad.fa-arrow-alt-right:after, .fa-duotone.fa-arrow-alt-right:after {
  content: "\2b95\fe02"; }

.fad.fa-right-from-bracket:before, .fa-duotone.fa-right-from-bracket:before {
  content: "\f2f5\fe01"; }

.fad.fa-right-from-bracket:after, .fa-duotone.fa-right-from-bracket:after {
  content: "\f2f5\fe02"; }

.fad.fa-sign-out-alt:before, .fa-duotone.fa-sign-out-alt:before {
  content: "\f2f5\fe01"; }

.fad.fa-sign-out-alt:after, .fa-duotone.fa-sign-out-alt:after {
  content: "\f2f5\fe02"; }

.fad.fa-right-from-line:before, .fa-duotone.fa-right-from-line:before {
  content: "\f347\fe01"; }

.fad.fa-right-from-line:after, .fa-duotone.fa-right-from-line:after {
  content: "\f347\fe02"; }

.fad.fa-arrow-alt-from-left:before, .fa-duotone.fa-arrow-alt-from-left:before {
  content: "\f347\fe01"; }

.fad.fa-arrow-alt-from-left:after, .fa-duotone.fa-arrow-alt-from-left:after {
  content: "\f347\fe02"; }

.fad.fa-right-left:before, .fa-duotone.fa-right-left:before {
  content: "\f362\fe01"; }

.fad.fa-right-left:after, .fa-duotone.fa-right-left:after {
  content: "\f362\fe02"; }

.fad.fa-exchange-alt:before, .fa-duotone.fa-exchange-alt:before {
  content: "\f362\fe01"; }

.fad.fa-exchange-alt:after, .fa-duotone.fa-exchange-alt:after {
  content: "\f362\fe02"; }

.fad.fa-right-long:before, .fa-duotone.fa-right-long:before {
  content: "\f30b\fe01"; }

.fad.fa-right-long:after, .fa-duotone.fa-right-long:after {
  content: "\f30b\fe02"; }

.fad.fa-long-arrow-alt-right:before, .fa-duotone.fa-long-arrow-alt-right:before {
  content: "\f30b\fe01"; }

.fad.fa-long-arrow-alt-right:after, .fa-duotone.fa-long-arrow-alt-right:after {
  content: "\f30b\fe02"; }

.fad.fa-right-to-bracket:before, .fa-duotone.fa-right-to-bracket:before {
  content: "\f2f6\fe01"; }

.fad.fa-right-to-bracket:after, .fa-duotone.fa-right-to-bracket:after {
  content: "\f2f6\fe02"; }

.fad.fa-sign-in-alt:before, .fa-duotone.fa-sign-in-alt:before {
  content: "\f2f6\fe01"; }

.fad.fa-sign-in-alt:after, .fa-duotone.fa-sign-in-alt:after {
  content: "\f2f6\fe02"; }

.fad.fa-right-to-line:before, .fa-duotone.fa-right-to-line:before {
  content: "\f34c\fe01"; }

.fad.fa-right-to-line:after, .fa-duotone.fa-right-to-line:after {
  content: "\f34c\fe02"; }

.fad.fa-arrow-alt-to-right:before, .fa-duotone.fa-arrow-alt-to-right:before {
  content: "\f34c\fe01"; }

.fad.fa-arrow-alt-to-right:after, .fa-duotone.fa-arrow-alt-to-right:after {
  content: "\f34c\fe02"; }

.fad.fa-ring:before, .fa-duotone.fa-ring:before {
  content: "\f70b\fe01"; }

.fad.fa-ring:after, .fa-duotone.fa-ring:after {
  content: "\f70b\fe02"; }

.fad.fa-rings-wedding:before, .fa-duotone.fa-rings-wedding:before {
  content: "\f81b\fe01"; }

.fad.fa-rings-wedding:after, .fa-duotone.fa-rings-wedding:after {
  content: "\f81b\fe02"; }

.fad.fa-road:before, .fa-duotone.fa-road:before {
  content: "\f018"; }

.fad.fa-road:after, .fa-duotone.fa-road:after {
  content: "\10f018"; }

.fad.fa-robot:before, .fa-duotone.fa-robot:before {
  content: "\f544"; }

.fad.fa-robot:after, .fa-duotone.fa-robot:after {
  content: "\10f544"; }

.fad.fa-robot-astromech:before, .fa-duotone.fa-robot-astromech:before {
  content: "\e2d2\fe01"; }

.fad.fa-robot-astromech:after, .fa-duotone.fa-robot-astromech:after {
  content: "\e2d2\fe02"; }

.fad.fa-rocket:before, .fa-duotone.fa-rocket:before {
  content: "\f135\fe01"; }

.fad.fa-rocket:after, .fa-duotone.fa-rocket:after {
  content: "\f135\fe02"; }

.fad.fa-rocket-launch:before, .fa-duotone.fa-rocket-launch:before {
  content: "\e027"; }

.fad.fa-rocket-launch:after, .fa-duotone.fa-rocket-launch:after {
  content: "\10e027"; }

.fad.fa-roller-coaster:before, .fa-duotone.fa-roller-coaster:before {
  content: "\e324\fe01"; }

.fad.fa-roller-coaster:after, .fa-duotone.fa-roller-coaster:after {
  content: "\e324\fe02"; }

.fad.fa-rotate:before, .fa-duotone.fa-rotate:before {
  content: "\f2f1"; }

.fad.fa-rotate:after, .fa-duotone.fa-rotate:after {
  content: "\10f2f1"; }

.fad.fa-sync-alt:before, .fa-duotone.fa-sync-alt:before {
  content: "\f2f1"; }

.fad.fa-sync-alt:after, .fa-duotone.fa-sync-alt:after {
  content: "\10f2f1"; }

.fad.fa-rotate-exclamation:before, .fa-duotone.fa-rotate-exclamation:before {
  content: "\e23c\fe01"; }

.fad.fa-rotate-exclamation:after, .fa-duotone.fa-rotate-exclamation:after {
  content: "\e23c\fe02"; }

.fad.fa-rotate-left:before, .fa-duotone.fa-rotate-left:before {
  content: "\f2ea\fe01"; }

.fad.fa-rotate-left:after, .fa-duotone.fa-rotate-left:after {
  content: "\f2ea\fe02"; }

.fad.fa-rotate-back:before, .fa-duotone.fa-rotate-back:before {
  content: "\f2ea\fe01"; }

.fad.fa-rotate-back:after, .fa-duotone.fa-rotate-back:after {
  content: "\f2ea\fe02"; }

.fad.fa-rotate-backward:before, .fa-duotone.fa-rotate-backward:before {
  content: "\f2ea\fe01"; }

.fad.fa-rotate-backward:after, .fa-duotone.fa-rotate-backward:after {
  content: "\f2ea\fe02"; }

.fad.fa-undo-alt:before, .fa-duotone.fa-undo-alt:before {
  content: "\f2ea\fe01"; }

.fad.fa-undo-alt:after, .fa-duotone.fa-undo-alt:after {
  content: "\f2ea\fe02"; }

.fad.fa-rotate-right:before, .fa-duotone.fa-rotate-right:before {
  content: "\f2f9\fe01"; }

.fad.fa-rotate-right:after, .fa-duotone.fa-rotate-right:after {
  content: "\f2f9\fe02"; }

.fad.fa-redo-alt:before, .fa-duotone.fa-redo-alt:before {
  content: "\f2f9\fe01"; }

.fad.fa-redo-alt:after, .fa-duotone.fa-redo-alt:after {
  content: "\f2f9\fe02"; }

.fad.fa-rotate-forward:before, .fa-duotone.fa-rotate-forward:before {
  content: "\f2f9\fe01"; }

.fad.fa-rotate-forward:after, .fa-duotone.fa-rotate-forward:after {
  content: "\f2f9\fe02"; }

.fad.fa-route:before, .fa-duotone.fa-route:before {
  content: "\f4d7\fe01"; }

.fad.fa-route:after, .fa-duotone.fa-route:after {
  content: "\f4d7\fe02"; }

.fad.fa-route-highway:before, .fa-duotone.fa-route-highway:before {
  content: "\f61a\fe01"; }

.fad.fa-route-highway:after, .fa-duotone.fa-route-highway:after {
  content: "\f61a\fe02"; }

.fad.fa-route-interstate:before, .fa-duotone.fa-route-interstate:before {
  content: "\f61b\fe01"; }

.fad.fa-route-interstate:after, .fa-duotone.fa-route-interstate:after {
  content: "\f61b\fe02"; }

.fad.fa-router:before, .fa-duotone.fa-router:before {
  content: "\f8da\fe01"; }

.fad.fa-router:after, .fa-duotone.fa-router:after {
  content: "\f8da\fe02"; }

.fad.fa-rss:before, .fa-duotone.fa-rss:before {
  content: "\f09e\fe01"; }

.fad.fa-rss:after, .fa-duotone.fa-rss:after {
  content: "\f09e\fe02"; }

.fad.fa-feed:before, .fa-duotone.fa-feed:before {
  content: "\f09e\fe01"; }

.fad.fa-feed:after, .fa-duotone.fa-feed:after {
  content: "\f09e\fe02"; }

.fad.fa-ruble-sign:before, .fa-duotone.fa-ruble-sign:before {
  content: "\20bd\fe01"; }

.fad.fa-ruble-sign:after, .fa-duotone.fa-ruble-sign:after {
  content: "\20bd\fe02"; }

.fad.fa-rouble:before, .fa-duotone.fa-rouble:before {
  content: "\20bd\fe01"; }

.fad.fa-rouble:after, .fa-duotone.fa-rouble:after {
  content: "\20bd\fe02"; }

.fad.fa-rub:before, .fa-duotone.fa-rub:before {
  content: "\20bd\fe01"; }

.fad.fa-rub:after, .fa-duotone.fa-rub:after {
  content: "\20bd\fe02"; }

.fad.fa-ruble:before, .fa-duotone.fa-ruble:before {
  content: "\20bd\fe01"; }

.fad.fa-ruble:after, .fa-duotone.fa-ruble:after {
  content: "\20bd\fe02"; }

.fad.fa-rugby-ball:before, .fa-duotone.fa-rugby-ball:before {
  content: "\e3c6\fe01"; }

.fad.fa-rugby-ball:after, .fa-duotone.fa-rugby-ball:after {
  content: "\e3c6\fe02"; }

.fad.fa-ruler:before, .fa-duotone.fa-ruler:before {
  content: "\f545"; }

.fad.fa-ruler:after, .fa-duotone.fa-ruler:after {
  content: "\10f545"; }

.fad.fa-ruler-combined:before, .fa-duotone.fa-ruler-combined:before {
  content: "\f546\fe01"; }

.fad.fa-ruler-combined:after, .fa-duotone.fa-ruler-combined:after {
  content: "\f546\fe02"; }

.fad.fa-ruler-horizontal:before, .fa-duotone.fa-ruler-horizontal:before {
  content: "\f547\fe01"; }

.fad.fa-ruler-horizontal:after, .fa-duotone.fa-ruler-horizontal:after {
  content: "\f547\fe02"; }

.fad.fa-ruler-triangle:before, .fa-duotone.fa-ruler-triangle:before {
  content: "\f61c"; }

.fad.fa-ruler-triangle:after, .fa-duotone.fa-ruler-triangle:after {
  content: "\10f61c"; }

.fad.fa-ruler-vertical:before, .fa-duotone.fa-ruler-vertical:before {
  content: "\f548\fe01"; }

.fad.fa-ruler-vertical:after, .fa-duotone.fa-ruler-vertical:after {
  content: "\f548\fe02"; }

.fad.fa-rupee-sign:before, .fa-duotone.fa-rupee-sign:before {
  content: "\20a8\fe01"; }

.fad.fa-rupee-sign:after, .fa-duotone.fa-rupee-sign:after {
  content: "\20a8\fe02"; }

.fad.fa-rupee:before, .fa-duotone.fa-rupee:before {
  content: "\20a8\fe01"; }

.fad.fa-rupee:after, .fa-duotone.fa-rupee:after {
  content: "\20a8\fe02"; }

.fad.fa-rupiah-sign:before, .fa-duotone.fa-rupiah-sign:before {
  content: "\e23d\fe01"; }

.fad.fa-rupiah-sign:after, .fa-duotone.fa-rupiah-sign:after {
  content: "\e23d\fe02"; }

.fad.fa-rv:before, .fa-duotone.fa-rv:before {
  content: "\f7be\fe01"; }

.fad.fa-rv:after, .fa-duotone.fa-rv:after {
  content: "\f7be\fe02"; }

.fad.fa-s:before, .fa-duotone.fa-s:before {
  content: "\53\fe01"; }

.fad.fa-s:after, .fa-duotone.fa-s:after {
  content: "\53\fe02"; }

.fad.fa-sack:before, .fa-duotone.fa-sack:before {
  content: "\f81c\fe01"; }

.fad.fa-sack:after, .fa-duotone.fa-sack:after {
  content: "\f81c\fe02"; }

.fad.fa-sack-dollar:before, .fa-duotone.fa-sack-dollar:before {
  content: "\f81d"; }

.fad.fa-sack-dollar:after, .fa-duotone.fa-sack-dollar:after {
  content: "\10f81d"; }

.fad.fa-salad:before, .fa-duotone.fa-salad:before {
  content: "\f81e"; }

.fad.fa-salad:after, .fa-duotone.fa-salad:after {
  content: "\10f81e"; }

.fad.fa-bowl-salad:before, .fa-duotone.fa-bowl-salad:before {
  content: "\f81e"; }

.fad.fa-bowl-salad:after, .fa-duotone.fa-bowl-salad:after {
  content: "\10f81e"; }

.fad.fa-sandwich:before, .fa-duotone.fa-sandwich:before {
  content: "\f81f"; }

.fad.fa-sandwich:after, .fa-duotone.fa-sandwich:after {
  content: "\10f81f"; }

.fad.fa-satellite:before, .fa-duotone.fa-satellite:before {
  content: "\f7bf"; }

.fad.fa-satellite:after, .fa-duotone.fa-satellite:after {
  content: "\10f7bf"; }

.fad.fa-satellite-dish:before, .fa-duotone.fa-satellite-dish:before {
  content: "\f7c0"; }

.fad.fa-satellite-dish:after, .fa-duotone.fa-satellite-dish:after {
  content: "\10f7c0"; }

.fad.fa-sausage:before, .fa-duotone.fa-sausage:before {
  content: "\f820\fe01"; }

.fad.fa-sausage:after, .fa-duotone.fa-sausage:after {
  content: "\f820\fe02"; }

.fad.fa-saxophone:before, .fa-duotone.fa-saxophone:before {
  content: "\f8dc"; }

.fad.fa-saxophone:after, .fa-duotone.fa-saxophone:after {
  content: "\10f8dc"; }

.fad.fa-saxophone-fire:before, .fa-duotone.fa-saxophone-fire:before {
  content: "\f8db\fe01"; }

.fad.fa-saxophone-fire:after, .fa-duotone.fa-saxophone-fire:after {
  content: "\f8db\fe02"; }

.fad.fa-sax-hot:before, .fa-duotone.fa-sax-hot:before {
  content: "\f8db\fe01"; }

.fad.fa-sax-hot:after, .fa-duotone.fa-sax-hot:after {
  content: "\f8db\fe02"; }

.fad.fa-scale-balanced:before, .fa-duotone.fa-scale-balanced:before {
  content: "\2696\fe01"; }

.fad.fa-scale-balanced:after, .fa-duotone.fa-scale-balanced:after {
  content: "\2696\fe02"; }

.fad.fa-balance-scale:before, .fa-duotone.fa-balance-scale:before {
  content: "\2696\fe01"; }

.fad.fa-balance-scale:after, .fa-duotone.fa-balance-scale:after {
  content: "\2696\fe02"; }

.fad.fa-scale-unbalanced:before, .fa-duotone.fa-scale-unbalanced:before {
  content: "\f515\fe01"; }

.fad.fa-scale-unbalanced:after, .fa-duotone.fa-scale-unbalanced:after {
  content: "\f515\fe02"; }

.fad.fa-balance-scale-left:before, .fa-duotone.fa-balance-scale-left:before {
  content: "\f515\fe01"; }

.fad.fa-balance-scale-left:after, .fa-duotone.fa-balance-scale-left:after {
  content: "\f515\fe02"; }

.fad.fa-scale-unbalanced-flip:before, .fa-duotone.fa-scale-unbalanced-flip:before {
  content: "\f516\fe01"; }

.fad.fa-scale-unbalanced-flip:after, .fa-duotone.fa-scale-unbalanced-flip:after {
  content: "\f516\fe02"; }

.fad.fa-balance-scale-right:before, .fa-duotone.fa-balance-scale-right:before {
  content: "\f516\fe01"; }

.fad.fa-balance-scale-right:after, .fa-duotone.fa-balance-scale-right:after {
  content: "\f516\fe02"; }

.fad.fa-scalpel:before, .fa-duotone.fa-scalpel:before {
  content: "\f61d\fe01"; }

.fad.fa-scalpel:after, .fa-duotone.fa-scalpel:after {
  content: "\f61d\fe02"; }

.fad.fa-scalpel-line-dashed:before, .fa-duotone.fa-scalpel-line-dashed:before {
  content: "\f61e\fe01"; }

.fad.fa-scalpel-line-dashed:after, .fa-duotone.fa-scalpel-line-dashed:after {
  content: "\f61e\fe02"; }

.fad.fa-scalpel-path:before, .fa-duotone.fa-scalpel-path:before {
  content: "\f61e\fe01"; }

.fad.fa-scalpel-path:after, .fa-duotone.fa-scalpel-path:after {
  content: "\f61e\fe02"; }

.fad.fa-scanner:before, .fa-duotone.fa-scanner:before {
  content: "\f8f3\fe01"; }

.fad.fa-scanner:after, .fa-duotone.fa-scanner:after {
  content: "\f8f3\fe02"; }

.fad.fa-scanner-image:before, .fa-duotone.fa-scanner-image:before {
  content: "\f8f3\fe01"; }

.fad.fa-scanner-image:after, .fa-duotone.fa-scanner-image:after {
  content: "\f8f3\fe02"; }

.fad.fa-scanner-gun:before, .fa-duotone.fa-scanner-gun:before {
  content: "\f488\fe01"; }

.fad.fa-scanner-gun:after, .fa-duotone.fa-scanner-gun:after {
  content: "\f488\fe02"; }

.fad.fa-scanner-keyboard:before, .fa-duotone.fa-scanner-keyboard:before {
  content: "\f489\fe01"; }

.fad.fa-scanner-keyboard:after, .fa-duotone.fa-scanner-keyboard:after {
  content: "\f489\fe02"; }

.fad.fa-scanner-touchscreen:before, .fa-duotone.fa-scanner-touchscreen:before {
  content: "\f48a\fe01"; }

.fad.fa-scanner-touchscreen:after, .fa-duotone.fa-scanner-touchscreen:after {
  content: "\f48a\fe02"; }

.fad.fa-scarecrow:before, .fa-duotone.fa-scarecrow:before {
  content: "\f70d\fe01"; }

.fad.fa-scarecrow:after, .fa-duotone.fa-scarecrow:after {
  content: "\f70d\fe02"; }

.fad.fa-scarf:before, .fa-duotone.fa-scarf:before {
  content: "\f7c1"; }

.fad.fa-scarf:after, .fa-duotone.fa-scarf:after {
  content: "\10f7c1"; }

.fad.fa-school:before, .fa-duotone.fa-school:before {
  content: "\f549"; }

.fad.fa-school:after, .fa-duotone.fa-school:after {
  content: "\10f549"; }

.fad.fa-scissors:before, .fa-duotone.fa-scissors:before {
  content: "\2700\fe01"; }

.fad.fa-scissors:after, .fa-duotone.fa-scissors:after {
  content: "\2700\fe02"; }

.fad.fa-cut:before, .fa-duotone.fa-cut:before {
  content: "\2700\fe01"; }

.fad.fa-cut:after, .fa-duotone.fa-cut:after {
  content: "\2700\fe02"; }

.fad.fa-screen-users:before, .fa-duotone.fa-screen-users:before {
  content: "\f63d\fe01"; }

.fad.fa-screen-users:after, .fa-duotone.fa-screen-users:after {
  content: "\f63d\fe02"; }

.fad.fa-users-class:before, .fa-duotone.fa-users-class:before {
  content: "\f63d\fe01"; }

.fad.fa-users-class:after, .fa-duotone.fa-users-class:after {
  content: "\f63d\fe02"; }

.fad.fa-screencast:before, .fa-duotone.fa-screencast:before {
  content: "\e23e\fe01"; }

.fad.fa-screencast:after, .fa-duotone.fa-screencast:after {
  content: "\e23e\fe02"; }

.fad.fa-screwdriver:before, .fa-duotone.fa-screwdriver:before {
  content: "\f54a"; }

.fad.fa-screwdriver:after, .fa-duotone.fa-screwdriver:after {
  content: "\10f54a"; }

.fad.fa-screwdriver-wrench:before, .fa-duotone.fa-screwdriver-wrench:before {
  content: "\f7d9\fe01"; }

.fad.fa-screwdriver-wrench:after, .fa-duotone.fa-screwdriver-wrench:after {
  content: "\f7d9\fe02"; }

.fad.fa-tools:before, .fa-duotone.fa-tools:before {
  content: "\f7d9\fe01"; }

.fad.fa-tools:after, .fa-duotone.fa-tools:after {
  content: "\f7d9\fe02"; }

.fad.fa-scribble:before, .fa-duotone.fa-scribble:before {
  content: "\e23f\fe01"; }

.fad.fa-scribble:after, .fa-duotone.fa-scribble:after {
  content: "\e23f\fe02"; }

.fad.fa-scroll:before, .fa-duotone.fa-scroll:before {
  content: "\f70e"; }

.fad.fa-scroll:after, .fa-duotone.fa-scroll:after {
  content: "\10f70e"; }

.fad.fa-scroll-old:before, .fa-duotone.fa-scroll-old:before {
  content: "\f70f\fe01"; }

.fad.fa-scroll-old:after, .fa-duotone.fa-scroll-old:after {
  content: "\f70f\fe02"; }

.fad.fa-scroll-torah:before, .fa-duotone.fa-scroll-torah:before {
  content: "\f6a0\fe01"; }

.fad.fa-scroll-torah:after, .fa-duotone.fa-scroll-torah:after {
  content: "\f6a0\fe02"; }

.fad.fa-torah:before, .fa-duotone.fa-torah:before {
  content: "\f6a0\fe01"; }

.fad.fa-torah:after, .fa-duotone.fa-torah:after {
  content: "\f6a0\fe02"; }

.fad.fa-scrubber:before, .fa-duotone.fa-scrubber:before {
  content: "\f2f8\fe01"; }

.fad.fa-scrubber:after, .fa-duotone.fa-scrubber:after {
  content: "\f2f8\fe02"; }

.fad.fa-scythe:before, .fa-duotone.fa-scythe:before {
  content: "\f710\fe01"; }

.fad.fa-scythe:after, .fa-duotone.fa-scythe:after {
  content: "\f710\fe02"; }

.fad.fa-sd-card:before, .fa-duotone.fa-sd-card:before {
  content: "\f7c2\fe01"; }

.fad.fa-sd-card:after, .fa-duotone.fa-sd-card:after {
  content: "\f7c2\fe02"; }

.fad.fa-sd-cards:before, .fa-duotone.fa-sd-cards:before {
  content: "\e240\fe01"; }

.fad.fa-sd-cards:after, .fa-duotone.fa-sd-cards:after {
  content: "\e240\fe02"; }

.fad.fa-seal:before, .fa-duotone.fa-seal:before {
  content: "\e241\fe01"; }

.fad.fa-seal:after, .fa-duotone.fa-seal:after {
  content: "\e241\fe02"; }

.fad.fa-seal-exclamation:before, .fa-duotone.fa-seal-exclamation:before {
  content: "\e242\fe01"; }

.fad.fa-seal-exclamation:after, .fa-duotone.fa-seal-exclamation:after {
  content: "\e242\fe02"; }

.fad.fa-seal-question:before, .fa-duotone.fa-seal-question:before {
  content: "\e243\fe01"; }

.fad.fa-seal-question:after, .fa-duotone.fa-seal-question:after {
  content: "\e243\fe02"; }

.fad.fa-seat-airline:before, .fa-duotone.fa-seat-airline:before {
  content: "\e244\fe01"; }

.fad.fa-seat-airline:after, .fa-duotone.fa-seat-airline:after {
  content: "\e244\fe02"; }

.fad.fa-section:before, .fa-duotone.fa-section:before {
  content: "\a7\fe01"; }

.fad.fa-section:after, .fa-duotone.fa-section:after {
  content: "\a7\fe02"; }

.fad.fa-seedling:before, .fa-duotone.fa-seedling:before {
  content: "\f4d8"; }

.fad.fa-seedling:after, .fa-duotone.fa-seedling:after {
  content: "\10f4d8"; }

.fad.fa-sprout:before, .fa-duotone.fa-sprout:before {
  content: "\f4d8"; }

.fad.fa-sprout:after, .fa-duotone.fa-sprout:after {
  content: "\10f4d8"; }

.fad.fa-semicolon:before, .fa-duotone.fa-semicolon:before {
  content: "\3b\fe01"; }

.fad.fa-semicolon:after, .fa-duotone.fa-semicolon:after {
  content: "\3b\fe02"; }

.fad.fa-send-back:before, .fa-duotone.fa-send-back:before {
  content: "\f87e\fe01"; }

.fad.fa-send-back:after, .fa-duotone.fa-send-back:after {
  content: "\f87e\fe02"; }

.fad.fa-send-backward:before, .fa-duotone.fa-send-backward:before {
  content: "\f87f\fe01"; }

.fad.fa-send-backward:after, .fa-duotone.fa-send-backward:after {
  content: "\f87f\fe02"; }

.fad.fa-sensor:before, .fa-duotone.fa-sensor:before {
  content: "\e028\fe01"; }

.fad.fa-sensor:after, .fa-duotone.fa-sensor:after {
  content: "\e028\fe02"; }

.fad.fa-sensor-cloud:before, .fa-duotone.fa-sensor-cloud:before {
  content: "\e02c\fe01"; }

.fad.fa-sensor-cloud:after, .fa-duotone.fa-sensor-cloud:after {
  content: "\e02c\fe02"; }

.fad.fa-sensor-smoke:before, .fa-duotone.fa-sensor-smoke:before {
  content: "\e02c\fe01"; }

.fad.fa-sensor-smoke:after, .fa-duotone.fa-sensor-smoke:after {
  content: "\e02c\fe02"; }

.fad.fa-sensor-fire:before, .fa-duotone.fa-sensor-fire:before {
  content: "\e02a\fe01"; }

.fad.fa-sensor-fire:after, .fa-duotone.fa-sensor-fire:after {
  content: "\e02a\fe02"; }

.fad.fa-sensor-on:before, .fa-duotone.fa-sensor-on:before {
  content: "\e02b\fe01"; }

.fad.fa-sensor-on:after, .fa-duotone.fa-sensor-on:after {
  content: "\e02b\fe02"; }

.fad.fa-sensor-triangle-exclamation:before, .fa-duotone.fa-sensor-triangle-exclamation:before {
  content: "\e029\fe01"; }

.fad.fa-sensor-triangle-exclamation:after, .fa-duotone.fa-sensor-triangle-exclamation:after {
  content: "\e029\fe02"; }

.fad.fa-sensor-alert:before, .fa-duotone.fa-sensor-alert:before {
  content: "\e029\fe01"; }

.fad.fa-sensor-alert:after, .fa-duotone.fa-sensor-alert:after {
  content: "\e029\fe02"; }

.fad.fa-server:before, .fa-duotone.fa-server:before {
  content: "\f233\fe01"; }

.fad.fa-server:after, .fa-duotone.fa-server:after {
  content: "\f233\fe02"; }

.fad.fa-shapes:before, .fa-duotone.fa-shapes:before {
  content: "\f61f\fe01"; }

.fad.fa-shapes:after, .fa-duotone.fa-shapes:after {
  content: "\f61f\fe02"; }

.fad.fa-triangle-circle-square:before, .fa-duotone.fa-triangle-circle-square:before {
  content: "\f61f\fe01"; }

.fad.fa-triangle-circle-square:after, .fa-duotone.fa-triangle-circle-square:after {
  content: "\f61f\fe02"; }

.fad.fa-share:before, .fa-duotone.fa-share:before {
  content: "\f064\fe01"; }

.fad.fa-share:after, .fa-duotone.fa-share:after {
  content: "\f064\fe02"; }

.fad.fa-arrow-turn-right:before, .fa-duotone.fa-arrow-turn-right:before {
  content: "\f064\fe01"; }

.fad.fa-arrow-turn-right:after, .fa-duotone.fa-arrow-turn-right:after {
  content: "\f064\fe02"; }

.fad.fa-mail-forward:before, .fa-duotone.fa-mail-forward:before {
  content: "\f064\fe01"; }

.fad.fa-mail-forward:after, .fa-duotone.fa-mail-forward:after {
  content: "\f064\fe02"; }

.fad.fa-share-all:before, .fa-duotone.fa-share-all:before {
  content: "\f367\fe01"; }

.fad.fa-share-all:after, .fa-duotone.fa-share-all:after {
  content: "\f367\fe02"; }

.fad.fa-arrows-turn-right:before, .fa-duotone.fa-arrows-turn-right:before {
  content: "\f367\fe01"; }

.fad.fa-arrows-turn-right:after, .fa-duotone.fa-arrows-turn-right:after {
  content: "\f367\fe02"; }

.fad.fa-share-from-square:before, .fa-duotone.fa-share-from-square:before {
  content: "\f14d\fe01"; }

.fad.fa-share-from-square:after, .fa-duotone.fa-share-from-square:after {
  content: "\f14d\fe02"; }

.fad.fa-share-square:before, .fa-duotone.fa-share-square:before {
  content: "\f14d\fe01"; }

.fad.fa-share-square:after, .fa-duotone.fa-share-square:after {
  content: "\f14d\fe02"; }

.fad.fa-share-nodes:before, .fa-duotone.fa-share-nodes:before {
  content: "\f1e0\fe01"; }

.fad.fa-share-nodes:after, .fa-duotone.fa-share-nodes:after {
  content: "\f1e0\fe02"; }

.fad.fa-share-alt:before, .fa-duotone.fa-share-alt:before {
  content: "\f1e0\fe01"; }

.fad.fa-share-alt:after, .fa-duotone.fa-share-alt:after {
  content: "\f1e0\fe02"; }

.fad.fa-sheep:before, .fa-duotone.fa-sheep:before {
  content: "\f711"; }

.fad.fa-sheep:after, .fa-duotone.fa-sheep:after {
  content: "\10f711"; }

.fad.fa-shekel-sign:before, .fa-duotone.fa-shekel-sign:before {
  content: "\20aa\fe01"; }

.fad.fa-shekel-sign:after, .fa-duotone.fa-shekel-sign:after {
  content: "\20aa\fe02"; }

.fad.fa-ils:before, .fa-duotone.fa-ils:before {
  content: "\20aa\fe01"; }

.fad.fa-ils:after, .fa-duotone.fa-ils:after {
  content: "\20aa\fe02"; }

.fad.fa-shekel:before, .fa-duotone.fa-shekel:before {
  content: "\20aa\fe01"; }

.fad.fa-shekel:after, .fa-duotone.fa-shekel:after {
  content: "\20aa\fe02"; }

.fad.fa-sheqel:before, .fa-duotone.fa-sheqel:before {
  content: "\20aa\fe01"; }

.fad.fa-sheqel:after, .fa-duotone.fa-sheqel:after {
  content: "\20aa\fe02"; }

.fad.fa-sheqel-sign:before, .fa-duotone.fa-sheqel-sign:before {
  content: "\20aa\fe01"; }

.fad.fa-sheqel-sign:after, .fa-duotone.fa-sheqel-sign:after {
  content: "\20aa\fe02"; }

.fad.fa-shelves:before, .fa-duotone.fa-shelves:before {
  content: "\f480\fe01"; }

.fad.fa-shelves:after, .fa-duotone.fa-shelves:after {
  content: "\f480\fe02"; }

.fad.fa-inventory:before, .fa-duotone.fa-inventory:before {
  content: "\f480\fe01"; }

.fad.fa-inventory:after, .fa-duotone.fa-inventory:after {
  content: "\f480\fe02"; }

.fad.fa-shelves-empty:before, .fa-duotone.fa-shelves-empty:before {
  content: "\e246\fe01"; }

.fad.fa-shelves-empty:after, .fa-duotone.fa-shelves-empty:after {
  content: "\e246\fe02"; }

.fad.fa-shield:before, .fa-duotone.fa-shield:before {
  content: "\f132"; }

.fad.fa-shield:after, .fa-duotone.fa-shield:after {
  content: "\10f132"; }

.fad.fa-shield-blank:before, .fa-duotone.fa-shield-blank:before {
  content: "\f3ed\fe01"; }

.fad.fa-shield-blank:after, .fa-duotone.fa-shield-blank:after {
  content: "\f3ed\fe02"; }

.fad.fa-shield-alt:before, .fa-duotone.fa-shield-alt:before {
  content: "\f3ed\fe01"; }

.fad.fa-shield-alt:after, .fa-duotone.fa-shield-alt:after {
  content: "\f3ed\fe02"; }

.fad.fa-shield-check:before, .fa-duotone.fa-shield-check:before {
  content: "\f2f7\fe01"; }

.fad.fa-shield-check:after, .fa-duotone.fa-shield-check:after {
  content: "\f2f7\fe02"; }

.fad.fa-shield-cross:before, .fa-duotone.fa-shield-cross:before {
  content: "\26e8\fe01"; }

.fad.fa-shield-cross:after, .fa-duotone.fa-shield-cross:after {
  content: "\26e8\fe02"; }

.fad.fa-shield-exclamation:before, .fa-duotone.fa-shield-exclamation:before {
  content: "\e247\fe01"; }

.fad.fa-shield-exclamation:after, .fa-duotone.fa-shield-exclamation:after {
  content: "\e247\fe02"; }

.fad.fa-shield-keyhole:before, .fa-duotone.fa-shield-keyhole:before {
  content: "\e248\fe01"; }

.fad.fa-shield-keyhole:after, .fa-duotone.fa-shield-keyhole:after {
  content: "\e248\fe02"; }

.fad.fa-shield-minus:before, .fa-duotone.fa-shield-minus:before {
  content: "\e249\fe01"; }

.fad.fa-shield-minus:after, .fa-duotone.fa-shield-minus:after {
  content: "\e249\fe02"; }

.fad.fa-shield-plus:before, .fa-duotone.fa-shield-plus:before {
  content: "\e24a\fe01"; }

.fad.fa-shield-plus:after, .fa-duotone.fa-shield-plus:after {
  content: "\e24a\fe02"; }

.fad.fa-shield-slash:before, .fa-duotone.fa-shield-slash:before {
  content: "\e24b\fe01"; }

.fad.fa-shield-slash:after, .fa-duotone.fa-shield-slash:after {
  content: "\e24b\fe02"; }

.fad.fa-shield-virus:before, .fa-duotone.fa-shield-virus:before {
  content: "\e06c\fe01"; }

.fad.fa-shield-virus:after, .fa-duotone.fa-shield-virus:after {
  content: "\e06c\fe02"; }

.fad.fa-shield-xmark:before, .fa-duotone.fa-shield-xmark:before {
  content: "\e24c\fe01"; }

.fad.fa-shield-xmark:after, .fa-duotone.fa-shield-xmark:after {
  content: "\e24c\fe02"; }

.fad.fa-shield-times:before, .fa-duotone.fa-shield-times:before {
  content: "\e24c\fe01"; }

.fad.fa-shield-times:after, .fa-duotone.fa-shield-times:after {
  content: "\e24c\fe02"; }

.fad.fa-ship:before, .fa-duotone.fa-ship:before {
  content: "\f21a"; }

.fad.fa-ship:after, .fa-duotone.fa-ship:after {
  content: "\10f21a"; }

.fad.fa-shirt:before, .fa-duotone.fa-shirt:before {
  content: "\f553"; }

.fad.fa-shirt:after, .fa-duotone.fa-shirt:after {
  content: "\10f553"; }

.fad.fa-t-shirt:before, .fa-duotone.fa-t-shirt:before {
  content: "\f553"; }

.fad.fa-t-shirt:after, .fa-duotone.fa-t-shirt:after {
  content: "\10f553"; }

.fad.fa-tshirt:before, .fa-duotone.fa-tshirt:before {
  content: "\f553"; }

.fad.fa-tshirt:after, .fa-duotone.fa-tshirt:after {
  content: "\10f553"; }

.fad.fa-shirt-long-sleeve:before, .fa-duotone.fa-shirt-long-sleeve:before {
  content: "\e3c7\fe01"; }

.fad.fa-shirt-long-sleeve:after, .fa-duotone.fa-shirt-long-sleeve:after {
  content: "\e3c7\fe02"; }

.fad.fa-shirt-running:before, .fa-duotone.fa-shirt-running:before {
  content: "\e3c8\fe01"; }

.fad.fa-shirt-running:after, .fa-duotone.fa-shirt-running:after {
  content: "\e3c8\fe02"; }

.fad.fa-shirt-tank-top:before, .fa-duotone.fa-shirt-tank-top:before {
  content: "\e3c9\fe01"; }

.fad.fa-shirt-tank-top:after, .fa-duotone.fa-shirt-tank-top:after {
  content: "\e3c9\fe02"; }

.fad.fa-shish-kebab:before, .fa-duotone.fa-shish-kebab:before {
  content: "\f821\fe01"; }

.fad.fa-shish-kebab:after, .fa-duotone.fa-shish-kebab:after {
  content: "\f821\fe02"; }

.fad.fa-shoe-prints:before, .fa-duotone.fa-shoe-prints:before {
  content: "\f54b\fe01"; }

.fad.fa-shoe-prints:after, .fa-duotone.fa-shoe-prints:after {
  content: "\f54b\fe02"; }

.fad.fa-shop:before, .fa-duotone.fa-shop:before {
  content: "\f54f\fe01"; }

.fad.fa-shop:after, .fa-duotone.fa-shop:after {
  content: "\f54f\fe02"; }

.fad.fa-store-alt:before, .fa-duotone.fa-store-alt:before {
  content: "\f54f\fe01"; }

.fad.fa-store-alt:after, .fa-duotone.fa-store-alt:after {
  content: "\f54f\fe02"; }

.fad.fa-shop-slash:before, .fa-duotone.fa-shop-slash:before {
  content: "\e070\fe01"; }

.fad.fa-shop-slash:after, .fa-duotone.fa-shop-slash:after {
  content: "\e070\fe02"; }

.fad.fa-store-alt-slash:before, .fa-duotone.fa-store-alt-slash:before {
  content: "\e070\fe01"; }

.fad.fa-store-alt-slash:after, .fa-duotone.fa-store-alt-slash:after {
  content: "\e070\fe02"; }

.fad.fa-shovel:before, .fa-duotone.fa-shovel:before {
  content: "\f713\fe01"; }

.fad.fa-shovel:after, .fa-duotone.fa-shovel:after {
  content: "\f713\fe02"; }

.fad.fa-shovel-snow:before, .fa-duotone.fa-shovel-snow:before {
  content: "\f7c3\fe01"; }

.fad.fa-shovel-snow:after, .fa-duotone.fa-shovel-snow:after {
  content: "\f7c3\fe02"; }

.fad.fa-shower:before, .fa-duotone.fa-shower:before {
  content: "\f2cc"; }

.fad.fa-shower:after, .fa-duotone.fa-shower:after {
  content: "\10f2cc"; }

.fad.fa-shower-down:before, .fa-duotone.fa-shower-down:before {
  content: "\e24d\fe01"; }

.fad.fa-shower-down:after, .fa-duotone.fa-shower-down:after {
  content: "\e24d\fe02"; }

.fad.fa-shower-alt:before, .fa-duotone.fa-shower-alt:before {
  content: "\e24d\fe01"; }

.fad.fa-shower-alt:after, .fa-duotone.fa-shower-alt:after {
  content: "\e24d\fe02"; }

.fad.fa-shredder:before, .fa-duotone.fa-shredder:before {
  content: "\f68a\fe01"; }

.fad.fa-shredder:after, .fa-duotone.fa-shredder:after {
  content: "\f68a\fe02"; }

.fad.fa-shuffle:before, .fa-duotone.fa-shuffle:before {
  content: "\f074"; }

.fad.fa-shuffle:after, .fa-duotone.fa-shuffle:after {
  content: "\10f074"; }

.fad.fa-random:before, .fa-duotone.fa-random:before {
  content: "\f074"; }

.fad.fa-random:after, .fa-duotone.fa-random:after {
  content: "\10f074"; }

.fad.fa-shuttle-space:before, .fa-duotone.fa-shuttle-space:before {
  content: "\f197\fe01"; }

.fad.fa-shuttle-space:after, .fa-duotone.fa-shuttle-space:after {
  content: "\f197\fe02"; }

.fad.fa-space-shuttle:before, .fa-duotone.fa-space-shuttle:before {
  content: "\f197\fe01"; }

.fad.fa-space-shuttle:after, .fa-duotone.fa-space-shuttle:after {
  content: "\f197\fe02"; }

.fad.fa-shuttlecock:before, .fa-duotone.fa-shuttlecock:before {
  content: "\f45b\fe01"; }

.fad.fa-shuttlecock:after, .fa-duotone.fa-shuttlecock:after {
  content: "\f45b\fe02"; }

.fad.fa-sickle:before, .fa-duotone.fa-sickle:before {
  content: "\f822\fe01"; }

.fad.fa-sickle:after, .fa-duotone.fa-sickle:after {
  content: "\f822\fe02"; }

.fad.fa-sidebar:before, .fa-duotone.fa-sidebar:before {
  content: "\e24e\fe01"; }

.fad.fa-sidebar:after, .fa-duotone.fa-sidebar:after {
  content: "\e24e\fe02"; }

.fad.fa-sidebar-flip:before, .fa-duotone.fa-sidebar-flip:before {
  content: "\e24f\fe01"; }

.fad.fa-sidebar-flip:after, .fa-duotone.fa-sidebar-flip:after {
  content: "\e24f\fe02"; }

.fad.fa-sigma:before, .fa-duotone.fa-sigma:before {
  content: "\2211\fe01"; }

.fad.fa-sigma:after, .fa-duotone.fa-sigma:after {
  content: "\2211\fe02"; }

.fad.fa-sign-hanging:before, .fa-duotone.fa-sign-hanging:before {
  content: "\f4d9\fe01"; }

.fad.fa-sign-hanging:after, .fa-duotone.fa-sign-hanging:after {
  content: "\f4d9\fe02"; }

.fad.fa-sign:before, .fa-duotone.fa-sign:before {
  content: "\f4d9\fe01"; }

.fad.fa-sign:after, .fa-duotone.fa-sign:after {
  content: "\f4d9\fe02"; }

.fad.fa-signal:before, .fa-duotone.fa-signal:before {
  content: "\f012"; }

.fad.fa-signal:after, .fa-duotone.fa-signal:after {
  content: "\10f012"; }

.fad.fa-signal-5:before, .fa-duotone.fa-signal-5:before {
  content: "\f012"; }

.fad.fa-signal-5:after, .fa-duotone.fa-signal-5:after {
  content: "\10f012"; }

.fad.fa-signal-perfect:before, .fa-duotone.fa-signal-perfect:before {
  content: "\f012"; }

.fad.fa-signal-perfect:after, .fa-duotone.fa-signal-perfect:after {
  content: "\10f012"; }

.fad.fa-signal-bars:before, .fa-duotone.fa-signal-bars:before {
  content: "\f690\fe01"; }

.fad.fa-signal-bars:after, .fa-duotone.fa-signal-bars:after {
  content: "\f690\fe02"; }

.fad.fa-signal-alt:before, .fa-duotone.fa-signal-alt:before {
  content: "\f690\fe01"; }

.fad.fa-signal-alt:after, .fa-duotone.fa-signal-alt:after {
  content: "\f690\fe02"; }

.fad.fa-signal-alt-4:before, .fa-duotone.fa-signal-alt-4:before {
  content: "\f690\fe01"; }

.fad.fa-signal-alt-4:after, .fa-duotone.fa-signal-alt-4:after {
  content: "\f690\fe02"; }

.fad.fa-signal-bars-strong:before, .fa-duotone.fa-signal-bars-strong:before {
  content: "\f690\fe01"; }

.fad.fa-signal-bars-strong:after, .fa-duotone.fa-signal-bars-strong:after {
  content: "\f690\fe02"; }

.fad.fa-signal-bars-fair:before, .fa-duotone.fa-signal-bars-fair:before {
  content: "\f692\fe01"; }

.fad.fa-signal-bars-fair:after, .fa-duotone.fa-signal-bars-fair:after {
  content: "\f692\fe02"; }

.fad.fa-signal-alt-2:before, .fa-duotone.fa-signal-alt-2:before {
  content: "\f692\fe01"; }

.fad.fa-signal-alt-2:after, .fa-duotone.fa-signal-alt-2:after {
  content: "\f692\fe02"; }

.fad.fa-signal-bars-good:before, .fa-duotone.fa-signal-bars-good:before {
  content: "\f693\fe01"; }

.fad.fa-signal-bars-good:after, .fa-duotone.fa-signal-bars-good:after {
  content: "\f693\fe02"; }

.fad.fa-signal-alt-3:before, .fa-duotone.fa-signal-alt-3:before {
  content: "\f693\fe01"; }

.fad.fa-signal-alt-3:after, .fa-duotone.fa-signal-alt-3:after {
  content: "\f693\fe02"; }

.fad.fa-signal-bars-slash:before, .fa-duotone.fa-signal-bars-slash:before {
  content: "\f694\fe01"; }

.fad.fa-signal-bars-slash:after, .fa-duotone.fa-signal-bars-slash:after {
  content: "\f694\fe02"; }

.fad.fa-signal-alt-slash:before, .fa-duotone.fa-signal-alt-slash:before {
  content: "\f694\fe01"; }

.fad.fa-signal-alt-slash:after, .fa-duotone.fa-signal-alt-slash:after {
  content: "\f694\fe02"; }

.fad.fa-signal-bars-weak:before, .fa-duotone.fa-signal-bars-weak:before {
  content: "\f691\fe01"; }

.fad.fa-signal-bars-weak:after, .fa-duotone.fa-signal-bars-weak:after {
  content: "\f691\fe02"; }

.fad.fa-signal-alt-1:before, .fa-duotone.fa-signal-alt-1:before {
  content: "\f691\fe01"; }

.fad.fa-signal-alt-1:after, .fa-duotone.fa-signal-alt-1:after {
  content: "\f691\fe02"; }

.fad.fa-signal-fair:before, .fa-duotone.fa-signal-fair:before {
  content: "\f68d\fe01"; }

.fad.fa-signal-fair:after, .fa-duotone.fa-signal-fair:after {
  content: "\f68d\fe02"; }

.fad.fa-signal-2:before, .fa-duotone.fa-signal-2:before {
  content: "\f68d\fe01"; }

.fad.fa-signal-2:after, .fa-duotone.fa-signal-2:after {
  content: "\f68d\fe02"; }

.fad.fa-signal-good:before, .fa-duotone.fa-signal-good:before {
  content: "\f68e\fe01"; }

.fad.fa-signal-good:after, .fa-duotone.fa-signal-good:after {
  content: "\f68e\fe02"; }

.fad.fa-signal-3:before, .fa-duotone.fa-signal-3:before {
  content: "\f68e\fe01"; }

.fad.fa-signal-3:after, .fa-duotone.fa-signal-3:after {
  content: "\f68e\fe02"; }

.fad.fa-signal-slash:before, .fa-duotone.fa-signal-slash:before {
  content: "\f695\fe01"; }

.fad.fa-signal-slash:after, .fa-duotone.fa-signal-slash:after {
  content: "\f695\fe02"; }

.fad.fa-signal-stream:before, .fa-duotone.fa-signal-stream:before {
  content: "\f8dd\fe01"; }

.fad.fa-signal-stream:after, .fa-duotone.fa-signal-stream:after {
  content: "\f8dd\fe02"; }

.fad.fa-signal-stream-slash:before, .fa-duotone.fa-signal-stream-slash:before {
  content: "\e250\fe01"; }

.fad.fa-signal-stream-slash:after, .fa-duotone.fa-signal-stream-slash:after {
  content: "\e250\fe02"; }

.fad.fa-signal-strong:before, .fa-duotone.fa-signal-strong:before {
  content: "\f68f\fe01"; }

.fad.fa-signal-strong:after, .fa-duotone.fa-signal-strong:after {
  content: "\f68f\fe02"; }

.fad.fa-signal-4:before, .fa-duotone.fa-signal-4:before {
  content: "\f68f\fe01"; }

.fad.fa-signal-4:after, .fa-duotone.fa-signal-4:after {
  content: "\f68f\fe02"; }

.fad.fa-signal-weak:before, .fa-duotone.fa-signal-weak:before {
  content: "\f68c\fe01"; }

.fad.fa-signal-weak:after, .fa-duotone.fa-signal-weak:after {
  content: "\f68c\fe02"; }

.fad.fa-signal-1:before, .fa-duotone.fa-signal-1:before {
  content: "\f68c\fe01"; }

.fad.fa-signal-1:after, .fa-duotone.fa-signal-1:after {
  content: "\f68c\fe02"; }

.fad.fa-signature:before, .fa-duotone.fa-signature:before {
  content: "\f5b7\fe01"; }

.fad.fa-signature:after, .fa-duotone.fa-signature:after {
  content: "\f5b7\fe02"; }

.fad.fa-signature-lock:before, .fa-duotone.fa-signature-lock:before {
  content: "\e3ca\fe01"; }

.fad.fa-signature-lock:after, .fa-duotone.fa-signature-lock:after {
  content: "\e3ca\fe02"; }

.fad.fa-signature-slash:before, .fa-duotone.fa-signature-slash:before {
  content: "\e3cb\fe01"; }

.fad.fa-signature-slash:after, .fa-duotone.fa-signature-slash:after {
  content: "\e3cb\fe02"; }

.fad.fa-signs-post:before, .fa-duotone.fa-signs-post:before {
  content: "\f277\fe01"; }

.fad.fa-signs-post:after, .fa-duotone.fa-signs-post:after {
  content: "\f277\fe02"; }

.fad.fa-map-signs:before, .fa-duotone.fa-map-signs:before {
  content: "\f277\fe01"; }

.fad.fa-map-signs:after, .fa-duotone.fa-map-signs:after {
  content: "\f277\fe02"; }

.fad.fa-sim-card:before, .fa-duotone.fa-sim-card:before {
  content: "\f7c4\fe01"; }

.fad.fa-sim-card:after, .fa-duotone.fa-sim-card:after {
  content: "\f7c4\fe02"; }

.fad.fa-sim-cards:before, .fa-duotone.fa-sim-cards:before {
  content: "\e251\fe01"; }

.fad.fa-sim-cards:after, .fa-duotone.fa-sim-cards:after {
  content: "\e251\fe02"; }

.fad.fa-sink:before, .fa-duotone.fa-sink:before {
  content: "\e06d\fe01"; }

.fad.fa-sink:after, .fa-duotone.fa-sink:after {
  content: "\e06d\fe02"; }

.fad.fa-siren:before, .fa-duotone.fa-siren:before {
  content: "\e02d\fe01"; }

.fad.fa-siren:after, .fa-duotone.fa-siren:after {
  content: "\e02d\fe02"; }

.fad.fa-siren-on:before, .fa-duotone.fa-siren-on:before {
  content: "\e02e\fe01"; }

.fad.fa-siren-on:after, .fa-duotone.fa-siren-on:after {
  content: "\e02e\fe02"; }

.fad.fa-sitemap:before, .fa-duotone.fa-sitemap:before {
  content: "\f0e8\fe01"; }

.fad.fa-sitemap:after, .fa-duotone.fa-sitemap:after {
  content: "\f0e8\fe02"; }

.fad.fa-skeleton:before, .fa-duotone.fa-skeleton:before {
  content: "\f620\fe01"; }

.fad.fa-skeleton:after, .fa-duotone.fa-skeleton:after {
  content: "\f620\fe02"; }

.fad.fa-ski-boot:before, .fa-duotone.fa-ski-boot:before {
  content: "\e3cc\fe01"; }

.fad.fa-ski-boot:after, .fa-duotone.fa-ski-boot:after {
  content: "\e3cc\fe02"; }

.fad.fa-ski-boot-ski:before, .fa-duotone.fa-ski-boot-ski:before {
  content: "\e3cd\fe01"; }

.fad.fa-ski-boot-ski:after, .fa-duotone.fa-ski-boot-ski:after {
  content: "\e3cd\fe02"; }

.fad.fa-skull:before, .fa-duotone.fa-skull:before {
  content: "\f54c"; }

.fad.fa-skull:after, .fa-duotone.fa-skull:after {
  content: "\10f54c"; }

.fad.fa-skull-cow:before, .fa-duotone.fa-skull-cow:before {
  content: "\f8de\fe01"; }

.fad.fa-skull-cow:after, .fa-duotone.fa-skull-cow:after {
  content: "\f8de\fe02"; }

.fad.fa-skull-crossbones:before, .fa-duotone.fa-skull-crossbones:before {
  content: "\2620\fe01"; }

.fad.fa-skull-crossbones:after, .fa-duotone.fa-skull-crossbones:after {
  content: "\2620\fe02"; }

.fad.fa-slash:before, .fa-duotone.fa-slash:before {
  content: "\f715\fe01"; }

.fad.fa-slash:after, .fa-duotone.fa-slash:after {
  content: "\f715\fe02"; }

.fad.fa-slash-back:before, .fa-duotone.fa-slash-back:before {
  content: "\5c\fe01"; }

.fad.fa-slash-back:after, .fa-duotone.fa-slash-back:after {
  content: "\5c\fe02"; }

.fad.fa-slash-forward:before, .fa-duotone.fa-slash-forward:before {
  content: "\2f\fe01"; }

.fad.fa-slash-forward:after, .fa-duotone.fa-slash-forward:after {
  content: "\2f\fe02"; }

.fad.fa-sleigh:before, .fa-duotone.fa-sleigh:before {
  content: "\f7cc\fe01"; }

.fad.fa-sleigh:after, .fa-duotone.fa-sleigh:after {
  content: "\f7cc\fe02"; }

.fad.fa-slider:before, .fa-duotone.fa-slider:before {
  content: "\e252\fe01"; }

.fad.fa-slider:after, .fa-duotone.fa-slider:after {
  content: "\e252\fe02"; }

.fad.fa-sliders:before, .fa-duotone.fa-sliders:before {
  content: "\f1de\fe01"; }

.fad.fa-sliders:after, .fa-duotone.fa-sliders:after {
  content: "\f1de\fe02"; }

.fad.fa-sliders-h:before, .fa-duotone.fa-sliders-h:before {
  content: "\f1de\fe01"; }

.fad.fa-sliders-h:after, .fa-duotone.fa-sliders-h:after {
  content: "\f1de\fe02"; }

.fad.fa-sliders-simple:before, .fa-duotone.fa-sliders-simple:before {
  content: "\e253\fe01"; }

.fad.fa-sliders-simple:after, .fa-duotone.fa-sliders-simple:after {
  content: "\e253\fe02"; }

.fad.fa-sliders-up:before, .fa-duotone.fa-sliders-up:before {
  content: "\f3f1\fe01"; }

.fad.fa-sliders-up:after, .fa-duotone.fa-sliders-up:after {
  content: "\f3f1\fe02"; }

.fad.fa-sliders-v:before, .fa-duotone.fa-sliders-v:before {
  content: "\f3f1\fe01"; }

.fad.fa-sliders-v:after, .fa-duotone.fa-sliders-v:after {
  content: "\f3f1\fe02"; }

.fad.fa-slot-machine:before, .fa-duotone.fa-slot-machine:before {
  content: "\e3ce\fe01"; }

.fad.fa-slot-machine:after, .fa-duotone.fa-slot-machine:after {
  content: "\e3ce\fe02"; }

.fad.fa-smog:before, .fa-duotone.fa-smog:before {
  content: "\f75f\fe01"; }

.fad.fa-smog:after, .fa-duotone.fa-smog:after {
  content: "\f75f\fe02"; }

.fad.fa-smoke:before, .fa-duotone.fa-smoke:before {
  content: "\f760\fe01"; }

.fad.fa-smoke:after, .fa-duotone.fa-smoke:after {
  content: "\f760\fe02"; }

.fad.fa-smoking:before, .fa-duotone.fa-smoking:before {
  content: "\f48d"; }

.fad.fa-smoking:after, .fa-duotone.fa-smoking:after {
  content: "\10f48d"; }

.fad.fa-snake:before, .fa-duotone.fa-snake:before {
  content: "\f716"; }

.fad.fa-snake:after, .fa-duotone.fa-snake:after {
  content: "\10f716"; }

.fad.fa-snooze:before, .fa-duotone.fa-snooze:before {
  content: "\f880"; }

.fad.fa-snooze:after, .fa-duotone.fa-snooze:after {
  content: "\10f880"; }

.fad.fa-zzz:before, .fa-duotone.fa-zzz:before {
  content: "\f880"; }

.fad.fa-zzz:after, .fa-duotone.fa-zzz:after {
  content: "\10f880"; }

.fad.fa-snow-blowing:before, .fa-duotone.fa-snow-blowing:before {
  content: "\f761\fe01"; }

.fad.fa-snow-blowing:after, .fa-duotone.fa-snow-blowing:after {
  content: "\f761\fe02"; }

.fad.fa-snowflake:before, .fa-duotone.fa-snowflake:before {
  content: "\2744\fe01"; }

.fad.fa-snowflake:after, .fa-duotone.fa-snowflake:after {
  content: "\2744\fe02"; }

.fad.fa-snowflakes:before, .fa-duotone.fa-snowflakes:before {
  content: "\f7cf\fe01"; }

.fad.fa-snowflakes:after, .fa-duotone.fa-snowflakes:after {
  content: "\f7cf\fe02"; }

.fad.fa-snowman:before, .fa-duotone.fa-snowman:before {
  content: "\2603\fe01"; }

.fad.fa-snowman:after, .fa-duotone.fa-snowman:after {
  content: "\2603\fe02"; }

.fad.fa-snowman-head:before, .fa-duotone.fa-snowman-head:before {
  content: "\f79b\fe01"; }

.fad.fa-snowman-head:after, .fa-duotone.fa-snowman-head:after {
  content: "\f79b\fe02"; }

.fad.fa-frosty-head:before, .fa-duotone.fa-frosty-head:before {
  content: "\f79b\fe01"; }

.fad.fa-frosty-head:after, .fa-duotone.fa-frosty-head:after {
  content: "\f79b\fe02"; }

.fad.fa-snowplow:before, .fa-duotone.fa-snowplow:before {
  content: "\f7d2\fe01"; }

.fad.fa-snowplow:after, .fa-duotone.fa-snowplow:after {
  content: "\f7d2\fe02"; }

.fad.fa-soap:before, .fa-duotone.fa-soap:before {
  content: "\e06e"; }

.fad.fa-soap:after, .fa-duotone.fa-soap:after {
  content: "\10e06e"; }

.fad.fa-socks:before, .fa-duotone.fa-socks:before {
  content: "\f696"; }

.fad.fa-socks:after, .fa-duotone.fa-socks:after {
  content: "\10f696"; }

.fad.fa-solar-panel:before, .fa-duotone.fa-solar-panel:before {
  content: "\f5ba\fe01"; }

.fad.fa-solar-panel:after, .fa-duotone.fa-solar-panel:after {
  content: "\f5ba\fe02"; }

.fad.fa-solar-system:before, .fa-duotone.fa-solar-system:before {
  content: "\e02f\fe01"; }

.fad.fa-solar-system:after, .fa-duotone.fa-solar-system:after {
  content: "\e02f\fe02"; }

.fad.fa-sort:before, .fa-duotone.fa-sort:before {
  content: "\f0dc\fe01"; }

.fad.fa-sort:after, .fa-duotone.fa-sort:after {
  content: "\f0dc\fe02"; }

.fad.fa-unsorted:before, .fa-duotone.fa-unsorted:before {
  content: "\f0dc\fe01"; }

.fad.fa-unsorted:after, .fa-duotone.fa-unsorted:after {
  content: "\f0dc\fe02"; }

.fad.fa-sort-down:before, .fa-duotone.fa-sort-down:before {
  content: "\f0dd\fe01"; }

.fad.fa-sort-down:after, .fa-duotone.fa-sort-down:after {
  content: "\f0dd\fe02"; }

.fad.fa-sort-desc:before, .fa-duotone.fa-sort-desc:before {
  content: "\f0dd\fe01"; }

.fad.fa-sort-desc:after, .fa-duotone.fa-sort-desc:after {
  content: "\f0dd\fe02"; }

.fad.fa-sort-up:before, .fa-duotone.fa-sort-up:before {
  content: "\f0de\fe01"; }

.fad.fa-sort-up:after, .fa-duotone.fa-sort-up:after {
  content: "\f0de\fe02"; }

.fad.fa-sort-asc:before, .fa-duotone.fa-sort-asc:before {
  content: "\f0de\fe01"; }

.fad.fa-sort-asc:after, .fa-duotone.fa-sort-asc:after {
  content: "\f0de\fe02"; }

.fad.fa-spa:before, .fa-duotone.fa-spa:before {
  content: "\f5bb\fe01"; }

.fad.fa-spa:after, .fa-duotone.fa-spa:after {
  content: "\f5bb\fe02"; }

.fad.fa-space-station-moon:before, .fa-duotone.fa-space-station-moon:before {
  content: "\e033\fe01"; }

.fad.fa-space-station-moon:after, .fa-duotone.fa-space-station-moon:after {
  content: "\e033\fe02"; }

.fad.fa-space-station-moon-construction:before, .fa-duotone.fa-space-station-moon-construction:before {
  content: "\e034\fe01"; }

.fad.fa-space-station-moon-construction:after, .fa-duotone.fa-space-station-moon-construction:after {
  content: "\e034\fe02"; }

.fad.fa-space-station-moon-alt:before, .fa-duotone.fa-space-station-moon-alt:before {
  content: "\e034\fe01"; }

.fad.fa-space-station-moon-alt:after, .fa-duotone.fa-space-station-moon-alt:after {
  content: "\e034\fe02"; }

.fad.fa-spade:before, .fa-duotone.fa-spade:before {
  content: "\2660\fe01"; }

.fad.fa-spade:after, .fa-duotone.fa-spade:after {
  content: "\2660\fe02"; }

.fad.fa-spaghetti-monster-flying:before, .fa-duotone.fa-spaghetti-monster-flying:before {
  content: "\f67b\fe01"; }

.fad.fa-spaghetti-monster-flying:after, .fa-duotone.fa-spaghetti-monster-flying:after {
  content: "\f67b\fe02"; }

.fad.fa-pastafarianism:before, .fa-duotone.fa-pastafarianism:before {
  content: "\f67b\fe01"; }

.fad.fa-pastafarianism:after, .fa-duotone.fa-pastafarianism:after {
  content: "\f67b\fe02"; }

.fad.fa-sparkles:before, .fa-duotone.fa-sparkles:before {
  content: "\2728\fe01"; }

.fad.fa-sparkles:after, .fa-duotone.fa-sparkles:after {
  content: "\2728\fe02"; }

.fad.fa-speaker:before, .fa-duotone.fa-speaker:before {
  content: "\f8df\fe01"; }

.fad.fa-speaker:after, .fa-duotone.fa-speaker:after {
  content: "\f8df\fe02"; }

.fad.fa-speakers:before, .fa-duotone.fa-speakers:before {
  content: "\f8e0\fe01"; }

.fad.fa-speakers:after, .fa-duotone.fa-speakers:after {
  content: "\f8e0\fe02"; }

.fad.fa-spell-check:before, .fa-duotone.fa-spell-check:before {
  content: "\f891\fe01"; }

.fad.fa-spell-check:after, .fa-duotone.fa-spell-check:after {
  content: "\f891\fe02"; }

.fad.fa-spider:before, .fa-duotone.fa-spider:before {
  content: "\f717"; }

.fad.fa-spider:after, .fa-duotone.fa-spider:after {
  content: "\10f717"; }

.fad.fa-spider-black-widow:before, .fa-duotone.fa-spider-black-widow:before {
  content: "\f718\fe01"; }

.fad.fa-spider-black-widow:after, .fa-duotone.fa-spider-black-widow:after {
  content: "\f718\fe02"; }

.fad.fa-spider-web:before, .fa-duotone.fa-spider-web:before {
  content: "\f719"; }

.fad.fa-spider-web:after, .fa-duotone.fa-spider-web:after {
  content: "\10f719"; }

.fad.fa-spinner:before, .fa-duotone.fa-spinner:before {
  content: "\f110\fe01"; }

.fad.fa-spinner:after, .fa-duotone.fa-spinner:after {
  content: "\f110\fe02"; }

.fad.fa-spinner-third:before, .fa-duotone.fa-spinner-third:before {
  content: "\f3f4\fe01"; }

.fad.fa-spinner-third:after, .fa-duotone.fa-spinner-third:after {
  content: "\f3f4\fe02"; }

.fad.fa-split:before, .fa-duotone.fa-split:before {
  content: "\e254\fe01"; }

.fad.fa-split:after, .fa-duotone.fa-split:after {
  content: "\e254\fe02"; }

.fad.fa-splotch:before, .fa-duotone.fa-splotch:before {
  content: "\f5bc\fe01"; }

.fad.fa-splotch:after, .fa-duotone.fa-splotch:after {
  content: "\f5bc\fe02"; }

.fad.fa-spoon:before, .fa-duotone.fa-spoon:before {
  content: "\f2e5"; }

.fad.fa-spoon:after, .fa-duotone.fa-spoon:after {
  content: "\10f2e5"; }

.fad.fa-utensil-spoon:before, .fa-duotone.fa-utensil-spoon:before {
  content: "\f2e5"; }

.fad.fa-utensil-spoon:after, .fa-duotone.fa-utensil-spoon:after {
  content: "\10f2e5"; }

.fad.fa-spray-can:before, .fa-duotone.fa-spray-can:before {
  content: "\f5bd\fe01"; }

.fad.fa-spray-can:after, .fa-duotone.fa-spray-can:after {
  content: "\f5bd\fe02"; }

.fad.fa-spray-can-sparkles:before, .fa-duotone.fa-spray-can-sparkles:before {
  content: "\f5d0\fe01"; }

.fad.fa-spray-can-sparkles:after, .fa-duotone.fa-spray-can-sparkles:after {
  content: "\f5d0\fe02"; }

.fad.fa-air-freshener:before, .fa-duotone.fa-air-freshener:before {
  content: "\f5d0\fe01"; }

.fad.fa-air-freshener:after, .fa-duotone.fa-air-freshener:after {
  content: "\f5d0\fe02"; }

.fad.fa-sprinkler:before, .fa-duotone.fa-sprinkler:before {
  content: "\e035\fe01"; }

.fad.fa-sprinkler:after, .fa-duotone.fa-sprinkler:after {
  content: "\e035\fe02"; }

.fad.fa-square:before, .fa-duotone.fa-square:before {
  content: "\25a0\fe01"; }

.fad.fa-square:after, .fa-duotone.fa-square:after {
  content: "\25a0\fe02"; }

.fad.fa-square-0:before, .fa-duotone.fa-square-0:before {
  content: "\e255\fe01"; }

.fad.fa-square-0:after, .fa-duotone.fa-square-0:after {
  content: "\e255\fe02"; }

.fad.fa-square-1:before, .fa-duotone.fa-square-1:before {
  content: "\e256\fe01"; }

.fad.fa-square-1:after, .fa-duotone.fa-square-1:after {
  content: "\e256\fe02"; }

.fad.fa-square-2:before, .fa-duotone.fa-square-2:before {
  content: "\e257\fe01"; }

.fad.fa-square-2:after, .fa-duotone.fa-square-2:after {
  content: "\e257\fe02"; }

.fad.fa-square-3:before, .fa-duotone.fa-square-3:before {
  content: "\e258\fe01"; }

.fad.fa-square-3:after, .fa-duotone.fa-square-3:after {
  content: "\e258\fe02"; }

.fad.fa-square-4:before, .fa-duotone.fa-square-4:before {
  content: "\e259\fe01"; }

.fad.fa-square-4:after, .fa-duotone.fa-square-4:after {
  content: "\e259\fe02"; }

.fad.fa-square-5:before, .fa-duotone.fa-square-5:before {
  content: "\e25a\fe01"; }

.fad.fa-square-5:after, .fa-duotone.fa-square-5:after {
  content: "\e25a\fe02"; }

.fad.fa-square-6:before, .fa-duotone.fa-square-6:before {
  content: "\e25b\fe01"; }

.fad.fa-square-6:after, .fa-duotone.fa-square-6:after {
  content: "\e25b\fe02"; }

.fad.fa-square-7:before, .fa-duotone.fa-square-7:before {
  content: "\e25c\fe01"; }

.fad.fa-square-7:after, .fa-duotone.fa-square-7:after {
  content: "\e25c\fe02"; }

.fad.fa-square-8:before, .fa-duotone.fa-square-8:before {
  content: "\e25d\fe01"; }

.fad.fa-square-8:after, .fa-duotone.fa-square-8:after {
  content: "\e25d\fe02"; }

.fad.fa-square-9:before, .fa-duotone.fa-square-9:before {
  content: "\e25e\fe01"; }

.fad.fa-square-9:after, .fa-duotone.fa-square-9:after {
  content: "\e25e\fe02"; }

.fad.fa-square-a:before, .fa-duotone.fa-square-a:before {
  content: "\e25f\fe01"; }

.fad.fa-square-a:after, .fa-duotone.fa-square-a:after {
  content: "\e25f\fe02"; }

.fad.fa-square-ampersand:before, .fa-duotone.fa-square-ampersand:before {
  content: "\e260\fe01"; }

.fad.fa-square-ampersand:after, .fa-duotone.fa-square-ampersand:after {
  content: "\e260\fe02"; }

.fad.fa-square-arrow-down:before, .fa-duotone.fa-square-arrow-down:before {
  content: "\f339\fe01"; }

.fad.fa-square-arrow-down:after, .fa-duotone.fa-square-arrow-down:after {
  content: "\f339\fe02"; }

.fad.fa-arrow-square-down:before, .fa-duotone.fa-arrow-square-down:before {
  content: "\f339\fe01"; }

.fad.fa-arrow-square-down:after, .fa-duotone.fa-arrow-square-down:after {
  content: "\f339\fe02"; }

.fad.fa-square-arrow-down-left:before, .fa-duotone.fa-square-arrow-down-left:before {
  content: "\e261\fe01"; }

.fad.fa-square-arrow-down-left:after, .fa-duotone.fa-square-arrow-down-left:after {
  content: "\e261\fe02"; }

.fad.fa-square-arrow-down-right:before, .fa-duotone.fa-square-arrow-down-right:before {
  content: "\e262\fe01"; }

.fad.fa-square-arrow-down-right:after, .fa-duotone.fa-square-arrow-down-right:after {
  content: "\e262\fe02"; }

.fad.fa-square-arrow-left:before, .fa-duotone.fa-square-arrow-left:before {
  content: "\f33a\fe01"; }

.fad.fa-square-arrow-left:after, .fa-duotone.fa-square-arrow-left:after {
  content: "\f33a\fe02"; }

.fad.fa-arrow-square-left:before, .fa-duotone.fa-arrow-square-left:before {
  content: "\f33a\fe01"; }

.fad.fa-arrow-square-left:after, .fa-duotone.fa-arrow-square-left:after {
  content: "\f33a\fe02"; }

.fad.fa-square-arrow-right:before, .fa-duotone.fa-square-arrow-right:before {
  content: "\f33b\fe01"; }

.fad.fa-square-arrow-right:after, .fa-duotone.fa-square-arrow-right:after {
  content: "\f33b\fe02"; }

.fad.fa-arrow-square-right:before, .fa-duotone.fa-arrow-square-right:before {
  content: "\f33b\fe01"; }

.fad.fa-arrow-square-right:after, .fa-duotone.fa-arrow-square-right:after {
  content: "\f33b\fe02"; }

.fad.fa-square-arrow-up:before, .fa-duotone.fa-square-arrow-up:before {
  content: "\f33c\fe01"; }

.fad.fa-square-arrow-up:after, .fa-duotone.fa-square-arrow-up:after {
  content: "\f33c\fe02"; }

.fad.fa-arrow-square-up:before, .fa-duotone.fa-arrow-square-up:before {
  content: "\f33c\fe01"; }

.fad.fa-arrow-square-up:after, .fa-duotone.fa-arrow-square-up:after {
  content: "\f33c\fe02"; }

.fad.fa-square-arrow-up-left:before, .fa-duotone.fa-square-arrow-up-left:before {
  content: "\e263\fe01"; }

.fad.fa-square-arrow-up-left:after, .fa-duotone.fa-square-arrow-up-left:after {
  content: "\e263\fe02"; }

.fad.fa-square-arrow-up-right:before, .fa-duotone.fa-square-arrow-up-right:before {
  content: "\f14c\fe01"; }

.fad.fa-square-arrow-up-right:after, .fa-duotone.fa-square-arrow-up-right:after {
  content: "\f14c\fe02"; }

.fad.fa-external-link-square:before, .fa-duotone.fa-external-link-square:before {
  content: "\f14c\fe01"; }

.fad.fa-external-link-square:after, .fa-duotone.fa-external-link-square:after {
  content: "\f14c\fe02"; }

.fad.fa-square-b:before, .fa-duotone.fa-square-b:before {
  content: "\e264\fe01"; }

.fad.fa-square-b:after, .fa-duotone.fa-square-b:after {
  content: "\e264\fe02"; }

.fad.fa-square-bolt:before, .fa-duotone.fa-square-bolt:before {
  content: "\e265\fe01"; }

.fad.fa-square-bolt:after, .fa-duotone.fa-square-bolt:after {
  content: "\e265\fe02"; }

.fad.fa-square-c:before, .fa-duotone.fa-square-c:before {
  content: "\e266\fe01"; }

.fad.fa-square-c:after, .fa-duotone.fa-square-c:after {
  content: "\e266\fe02"; }

.fad.fa-square-caret-down:before, .fa-duotone.fa-square-caret-down:before {
  content: "\f150\fe01"; }

.fad.fa-square-caret-down:after, .fa-duotone.fa-square-caret-down:after {
  content: "\f150\fe02"; }

.fad.fa-caret-square-down:before, .fa-duotone.fa-caret-square-down:before {
  content: "\f150\fe01"; }

.fad.fa-caret-square-down:after, .fa-duotone.fa-caret-square-down:after {
  content: "\f150\fe02"; }

.fad.fa-square-caret-left:before, .fa-duotone.fa-square-caret-left:before {
  content: "\f191\fe01"; }

.fad.fa-square-caret-left:after, .fa-duotone.fa-square-caret-left:after {
  content: "\f191\fe02"; }

.fad.fa-caret-square-left:before, .fa-duotone.fa-caret-square-left:before {
  content: "\f191\fe01"; }

.fad.fa-caret-square-left:after, .fa-duotone.fa-caret-square-left:after {
  content: "\f191\fe02"; }

.fad.fa-square-caret-right:before, .fa-duotone.fa-square-caret-right:before {
  content: "\f152\fe01"; }

.fad.fa-square-caret-right:after, .fa-duotone.fa-square-caret-right:after {
  content: "\f152\fe02"; }

.fad.fa-caret-square-right:before, .fa-duotone.fa-caret-square-right:before {
  content: "\f152\fe01"; }

.fad.fa-caret-square-right:after, .fa-duotone.fa-caret-square-right:after {
  content: "\f152\fe02"; }

.fad.fa-square-caret-up:before, .fa-duotone.fa-square-caret-up:before {
  content: "\f151\fe01"; }

.fad.fa-square-caret-up:after, .fa-duotone.fa-square-caret-up:after {
  content: "\f151\fe02"; }

.fad.fa-caret-square-up:before, .fa-duotone.fa-caret-square-up:before {
  content: "\f151\fe01"; }

.fad.fa-caret-square-up:after, .fa-duotone.fa-caret-square-up:after {
  content: "\f151\fe02"; }

.fad.fa-square-check:before, .fa-duotone.fa-square-check:before {
  content: "\2611\fe01"; }

.fad.fa-square-check:after, .fa-duotone.fa-square-check:after {
  content: "\2611\fe02"; }

.fad.fa-check-square:before, .fa-duotone.fa-check-square:before {
  content: "\2611\fe01"; }

.fad.fa-check-square:after, .fa-duotone.fa-check-square:after {
  content: "\2611\fe02"; }

.fad.fa-square-chevron-down:before, .fa-duotone.fa-square-chevron-down:before {
  content: "\f329\fe01"; }

.fad.fa-square-chevron-down:after, .fa-duotone.fa-square-chevron-down:after {
  content: "\f329\fe02"; }

.fad.fa-chevron-square-down:before, .fa-duotone.fa-chevron-square-down:before {
  content: "\f329\fe01"; }

.fad.fa-chevron-square-down:after, .fa-duotone.fa-chevron-square-down:after {
  content: "\f329\fe02"; }

.fad.fa-square-chevron-left:before, .fa-duotone.fa-square-chevron-left:before {
  content: "\f32a\fe01"; }

.fad.fa-square-chevron-left:after, .fa-duotone.fa-square-chevron-left:after {
  content: "\f32a\fe02"; }

.fad.fa-chevron-square-left:before, .fa-duotone.fa-chevron-square-left:before {
  content: "\f32a\fe01"; }

.fad.fa-chevron-square-left:after, .fa-duotone.fa-chevron-square-left:after {
  content: "\f32a\fe02"; }

.fad.fa-square-chevron-right:before, .fa-duotone.fa-square-chevron-right:before {
  content: "\f32b\fe01"; }

.fad.fa-square-chevron-right:after, .fa-duotone.fa-square-chevron-right:after {
  content: "\f32b\fe02"; }

.fad.fa-chevron-square-right:before, .fa-duotone.fa-chevron-square-right:before {
  content: "\f32b\fe01"; }

.fad.fa-chevron-square-right:after, .fa-duotone.fa-chevron-square-right:after {
  content: "\f32b\fe02"; }

.fad.fa-square-chevron-up:before, .fa-duotone.fa-square-chevron-up:before {
  content: "\f32c\fe01"; }

.fad.fa-square-chevron-up:after, .fa-duotone.fa-square-chevron-up:after {
  content: "\f32c\fe02"; }

.fad.fa-chevron-square-up:before, .fa-duotone.fa-chevron-square-up:before {
  content: "\f32c\fe01"; }

.fad.fa-chevron-square-up:after, .fa-duotone.fa-chevron-square-up:after {
  content: "\f32c\fe02"; }

.fad.fa-square-code:before, .fa-duotone.fa-square-code:before {
  content: "\e267\fe01"; }

.fad.fa-square-code:after, .fa-duotone.fa-square-code:after {
  content: "\e267\fe02"; }

.fad.fa-square-d:before, .fa-duotone.fa-square-d:before {
  content: "\e268\fe01"; }

.fad.fa-square-d:after, .fa-duotone.fa-square-d:after {
  content: "\e268\fe02"; }

.fad.fa-square-dashed:before, .fa-duotone.fa-square-dashed:before {
  content: "\e269\fe01"; }

.fad.fa-square-dashed:after, .fa-duotone.fa-square-dashed:after {
  content: "\e269\fe02"; }

.fad.fa-square-divide:before, .fa-duotone.fa-square-divide:before {
  content: "\e26a\fe01"; }

.fad.fa-square-divide:after, .fa-duotone.fa-square-divide:after {
  content: "\e26a\fe02"; }

.fad.fa-square-dollar:before, .fa-duotone.fa-square-dollar:before {
  content: "\f2e9\fe01"; }

.fad.fa-square-dollar:after, .fa-duotone.fa-square-dollar:after {
  content: "\f2e9\fe02"; }

.fad.fa-dollar-square:before, .fa-duotone.fa-dollar-square:before {
  content: "\f2e9\fe01"; }

.fad.fa-dollar-square:after, .fa-duotone.fa-dollar-square:after {
  content: "\f2e9\fe02"; }

.fad.fa-usd-square:before, .fa-duotone.fa-usd-square:before {
  content: "\f2e9\fe01"; }

.fad.fa-usd-square:after, .fa-duotone.fa-usd-square:after {
  content: "\f2e9\fe02"; }

.fad.fa-square-down:before, .fa-duotone.fa-square-down:before {
  content: "\2b07\fe01"; }

.fad.fa-square-down:after, .fa-duotone.fa-square-down:after {
  content: "\2b07\fe02"; }

.fad.fa-arrow-alt-square-down:before, .fa-duotone.fa-arrow-alt-square-down:before {
  content: "\2b07\fe01"; }

.fad.fa-arrow-alt-square-down:after, .fa-duotone.fa-arrow-alt-square-down:after {
  content: "\2b07\fe02"; }

.fad.fa-square-down-left:before, .fa-duotone.fa-square-down-left:before {
  content: "\e26b\fe01"; }

.fad.fa-square-down-left:after, .fa-duotone.fa-square-down-left:after {
  content: "\e26b\fe02"; }

.fad.fa-square-down-right:before, .fa-duotone.fa-square-down-right:before {
  content: "\e26c\fe01"; }

.fad.fa-square-down-right:after, .fa-duotone.fa-square-down-right:after {
  content: "\e26c\fe02"; }

.fad.fa-square-e:before, .fa-duotone.fa-square-e:before {
  content: "\e26d\fe01"; }

.fad.fa-square-e:after, .fa-duotone.fa-square-e:after {
  content: "\e26d\fe02"; }

.fad.fa-square-ellipsis:before, .fa-duotone.fa-square-ellipsis:before {
  content: "\e26e\fe01"; }

.fad.fa-square-ellipsis:after, .fa-duotone.fa-square-ellipsis:after {
  content: "\e26e\fe02"; }

.fad.fa-square-ellipsis-vertical:before, .fa-duotone.fa-square-ellipsis-vertical:before {
  content: "\e26f\fe01"; }

.fad.fa-square-ellipsis-vertical:after, .fa-duotone.fa-square-ellipsis-vertical:after {
  content: "\e26f\fe02"; }

.fad.fa-square-envelope:before, .fa-duotone.fa-square-envelope:before {
  content: "\f199\fe01"; }

.fad.fa-square-envelope:after, .fa-duotone.fa-square-envelope:after {
  content: "\f199\fe02"; }

.fad.fa-envelope-square:before, .fa-duotone.fa-envelope-square:before {
  content: "\f199\fe01"; }

.fad.fa-envelope-square:after, .fa-duotone.fa-envelope-square:after {
  content: "\f199\fe02"; }

.fad.fa-square-exclamation:before, .fa-duotone.fa-square-exclamation:before {
  content: "\f321\fe01"; }

.fad.fa-square-exclamation:after, .fa-duotone.fa-square-exclamation:after {
  content: "\f321\fe02"; }

.fad.fa-exclamation-square:before, .fa-duotone.fa-exclamation-square:before {
  content: "\f321\fe01"; }

.fad.fa-exclamation-square:after, .fa-duotone.fa-exclamation-square:after {
  content: "\f321\fe02"; }

.fad.fa-square-f:before, .fa-duotone.fa-square-f:before {
  content: "\e270\fe01"; }

.fad.fa-square-f:after, .fa-duotone.fa-square-f:after {
  content: "\e270\fe02"; }

.fad.fa-square-fragile:before, .fa-duotone.fa-square-fragile:before {
  content: "\f49b\fe01"; }

.fad.fa-square-fragile:after, .fa-duotone.fa-square-fragile:after {
  content: "\f49b\fe02"; }

.fad.fa-box-fragile:before, .fa-duotone.fa-box-fragile:before {
  content: "\f49b\fe01"; }

.fad.fa-box-fragile:after, .fa-duotone.fa-box-fragile:after {
  content: "\f49b\fe02"; }

.fad.fa-square-wine-glass-crack:before, .fa-duotone.fa-square-wine-glass-crack:before {
  content: "\f49b\fe01"; }

.fad.fa-square-wine-glass-crack:after, .fa-duotone.fa-square-wine-glass-crack:after {
  content: "\f49b\fe02"; }

.fad.fa-square-full:before, .fa-duotone.fa-square-full:before {
  content: "\2b1b\fe01"; }

.fad.fa-square-full:after, .fa-duotone.fa-square-full:after {
  content: "\2b1b\fe02"; }

.fad.fa-square-g:before, .fa-duotone.fa-square-g:before {
  content: "\e271\fe01"; }

.fad.fa-square-g:after, .fa-duotone.fa-square-g:after {
  content: "\e271\fe02"; }

.fad.fa-square-h:before, .fa-duotone.fa-square-h:before {
  content: "\f0fd\fe01"; }

.fad.fa-square-h:after, .fa-duotone.fa-square-h:after {
  content: "\f0fd\fe02"; }

.fad.fa-h-square:before, .fa-duotone.fa-h-square:before {
  content: "\f0fd\fe01"; }

.fad.fa-h-square:after, .fa-duotone.fa-h-square:after {
  content: "\f0fd\fe02"; }

.fad.fa-square-heart:before, .fa-duotone.fa-square-heart:before {
  content: "\f4c8"; }

.fad.fa-square-heart:after, .fa-duotone.fa-square-heart:after {
  content: "\10f4c8"; }

.fad.fa-heart-square:before, .fa-duotone.fa-heart-square:before {
  content: "\f4c8"; }

.fad.fa-heart-square:after, .fa-duotone.fa-heart-square:after {
  content: "\10f4c8"; }

.fad.fa-square-i:before, .fa-duotone.fa-square-i:before {
  content: "\e272\fe01"; }

.fad.fa-square-i:after, .fa-duotone.fa-square-i:after {
  content: "\e272\fe02"; }

.fad.fa-square-info:before, .fa-duotone.fa-square-info:before {
  content: "\2139\fe01"; }

.fad.fa-square-info:after, .fa-duotone.fa-square-info:after {
  content: "\2139\fe02"; }

.fad.fa-info-square:before, .fa-duotone.fa-info-square:before {
  content: "\2139\fe01"; }

.fad.fa-info-square:after, .fa-duotone.fa-info-square:after {
  content: "\2139\fe02"; }

.fad.fa-square-j:before, .fa-duotone.fa-square-j:before {
  content: "\e273\fe01"; }

.fad.fa-square-j:after, .fa-duotone.fa-square-j:after {
  content: "\e273\fe02"; }

.fad.fa-square-k:before, .fa-duotone.fa-square-k:before {
  content: "\e274\fe01"; }

.fad.fa-square-k:after, .fa-duotone.fa-square-k:after {
  content: "\e274\fe02"; }

.fad.fa-square-l:before, .fa-duotone.fa-square-l:before {
  content: "\e275\fe01"; }

.fad.fa-square-l:after, .fa-duotone.fa-square-l:after {
  content: "\e275\fe02"; }

.fad.fa-square-left:before, .fa-duotone.fa-square-left:before {
  content: "\2b05\fe01"; }

.fad.fa-square-left:after, .fa-duotone.fa-square-left:after {
  content: "\2b05\fe02"; }

.fad.fa-arrow-alt-square-left:before, .fa-duotone.fa-arrow-alt-square-left:before {
  content: "\2b05\fe01"; }

.fad.fa-arrow-alt-square-left:after, .fa-duotone.fa-arrow-alt-square-left:after {
  content: "\2b05\fe02"; }

.fad.fa-square-m:before, .fa-duotone.fa-square-m:before {
  content: "\e276\fe01"; }

.fad.fa-square-m:after, .fa-duotone.fa-square-m:after {
  content: "\e276\fe02"; }

.fad.fa-square-minus:before, .fa-duotone.fa-square-minus:before {
  content: "\f146\fe01"; }

.fad.fa-square-minus:after, .fa-duotone.fa-square-minus:after {
  content: "\f146\fe02"; }

.fad.fa-minus-square:before, .fa-duotone.fa-minus-square:before {
  content: "\f146\fe01"; }

.fad.fa-minus-square:after, .fa-duotone.fa-minus-square:after {
  content: "\f146\fe02"; }

.fad.fa-square-n:before, .fa-duotone.fa-square-n:before {
  content: "\e277\fe01"; }

.fad.fa-square-n:after, .fa-duotone.fa-square-n:after {
  content: "\e277\fe02"; }

.fad.fa-square-o:before, .fa-duotone.fa-square-o:before {
  content: "\e278\fe01"; }

.fad.fa-square-o:after, .fa-duotone.fa-square-o:after {
  content: "\e278\fe02"; }

.fad.fa-square-p:before, .fa-duotone.fa-square-p:before {
  content: "\e279\fe01"; }

.fad.fa-square-p:after, .fa-duotone.fa-square-p:after {
  content: "\e279\fe02"; }

.fad.fa-square-parking:before, .fa-duotone.fa-square-parking:before {
  content: "\f540"; }

.fad.fa-square-parking:after, .fa-duotone.fa-square-parking:after {
  content: "\10f540"; }

.fad.fa-parking:before, .fa-duotone.fa-parking:before {
  content: "\f540"; }

.fad.fa-parking:after, .fa-duotone.fa-parking:after {
  content: "\10f540"; }

.fad.fa-square-parking-slash:before, .fa-duotone.fa-square-parking-slash:before {
  content: "\f617\fe01"; }

.fad.fa-square-parking-slash:after, .fa-duotone.fa-square-parking-slash:after {
  content: "\f617\fe02"; }

.fad.fa-parking-slash:before, .fa-duotone.fa-parking-slash:before {
  content: "\f617\fe01"; }

.fad.fa-parking-slash:after, .fa-duotone.fa-parking-slash:after {
  content: "\f617\fe02"; }

.fad.fa-square-pen:before, .fa-duotone.fa-square-pen:before {
  content: "\f14b\fe01"; }

.fad.fa-square-pen:after, .fa-duotone.fa-square-pen:after {
  content: "\f14b\fe02"; }

.fad.fa-pen-square:before, .fa-duotone.fa-pen-square:before {
  content: "\f14b\fe01"; }

.fad.fa-pen-square:after, .fa-duotone.fa-pen-square:after {
  content: "\f14b\fe02"; }

.fad.fa-pencil-square:before, .fa-duotone.fa-pencil-square:before {
  content: "\f14b\fe01"; }

.fad.fa-pencil-square:after, .fa-duotone.fa-pencil-square:after {
  content: "\f14b\fe02"; }

.fad.fa-square-phone:before, .fa-duotone.fa-square-phone:before {
  content: "\f098\fe01"; }

.fad.fa-square-phone:after, .fa-duotone.fa-square-phone:after {
  content: "\f098\fe02"; }

.fad.fa-phone-square:before, .fa-duotone.fa-phone-square:before {
  content: "\f098\fe01"; }

.fad.fa-phone-square:after, .fa-duotone.fa-phone-square:after {
  content: "\f098\fe02"; }

.fad.fa-square-phone-flip:before, .fa-duotone.fa-square-phone-flip:before {
  content: "\f87b\fe01"; }

.fad.fa-square-phone-flip:after, .fa-duotone.fa-square-phone-flip:after {
  content: "\f87b\fe02"; }

.fad.fa-phone-square-alt:before, .fa-duotone.fa-phone-square-alt:before {
  content: "\f87b\fe01"; }

.fad.fa-phone-square-alt:after, .fa-duotone.fa-phone-square-alt:after {
  content: "\f87b\fe02"; }

.fad.fa-square-phone-hangup:before, .fa-duotone.fa-square-phone-hangup:before {
  content: "\e27a\fe01"; }

.fad.fa-square-phone-hangup:after, .fa-duotone.fa-square-phone-hangup:after {
  content: "\e27a\fe02"; }

.fad.fa-phone-square-down:before, .fa-duotone.fa-phone-square-down:before {
  content: "\e27a\fe01"; }

.fad.fa-phone-square-down:after, .fa-duotone.fa-phone-square-down:after {
  content: "\e27a\fe02"; }

.fad.fa-square-plus:before, .fa-duotone.fa-square-plus:before {
  content: "\f0fe\fe01"; }

.fad.fa-square-plus:after, .fa-duotone.fa-square-plus:after {
  content: "\f0fe\fe02"; }

.fad.fa-plus-square:before, .fa-duotone.fa-plus-square:before {
  content: "\f0fe\fe01"; }

.fad.fa-plus-square:after, .fa-duotone.fa-plus-square:after {
  content: "\f0fe\fe02"; }

.fad.fa-square-poll-horizontal:before, .fa-duotone.fa-square-poll-horizontal:before {
  content: "\f682\fe01"; }

.fad.fa-square-poll-horizontal:after, .fa-duotone.fa-square-poll-horizontal:after {
  content: "\f682\fe02"; }

.fad.fa-poll-h:before, .fa-duotone.fa-poll-h:before {
  content: "\f682\fe01"; }

.fad.fa-poll-h:after, .fa-duotone.fa-poll-h:after {
  content: "\f682\fe02"; }

.fad.fa-square-poll-vertical:before, .fa-duotone.fa-square-poll-vertical:before {
  content: "\f681\fe01"; }

.fad.fa-square-poll-vertical:after, .fa-duotone.fa-square-poll-vertical:after {
  content: "\f681\fe02"; }

.fad.fa-poll:before, .fa-duotone.fa-poll:before {
  content: "\f681\fe01"; }

.fad.fa-poll:after, .fa-duotone.fa-poll:after {
  content: "\f681\fe02"; }

.fad.fa-square-q:before, .fa-duotone.fa-square-q:before {
  content: "\e27b\fe01"; }

.fad.fa-square-q:after, .fa-duotone.fa-square-q:after {
  content: "\e27b\fe02"; }

.fad.fa-square-question:before, .fa-duotone.fa-square-question:before {
  content: "\f2fd\fe01"; }

.fad.fa-square-question:after, .fa-duotone.fa-square-question:after {
  content: "\f2fd\fe02"; }

.fad.fa-question-square:before, .fa-duotone.fa-question-square:before {
  content: "\f2fd\fe01"; }

.fad.fa-question-square:after, .fa-duotone.fa-question-square:after {
  content: "\f2fd\fe02"; }

.fad.fa-square-quote:before, .fa-duotone.fa-square-quote:before {
  content: "\e329\fe01"; }

.fad.fa-square-quote:after, .fa-duotone.fa-square-quote:after {
  content: "\e329\fe02"; }

.fad.fa-square-r:before, .fa-duotone.fa-square-r:before {
  content: "\e27c\fe01"; }

.fad.fa-square-r:after, .fa-duotone.fa-square-r:after {
  content: "\e27c\fe02"; }

.fad.fa-square-right:before, .fa-duotone.fa-square-right:before {
  content: "\27a1\fe01"; }

.fad.fa-square-right:after, .fa-duotone.fa-square-right:after {
  content: "\27a1\fe02"; }

.fad.fa-arrow-alt-square-right:before, .fa-duotone.fa-arrow-alt-square-right:before {
  content: "\27a1\fe01"; }

.fad.fa-arrow-alt-square-right:after, .fa-duotone.fa-arrow-alt-square-right:after {
  content: "\27a1\fe02"; }

.fad.fa-square-root:before, .fa-duotone.fa-square-root:before {
  content: "\221a\fe01"; }

.fad.fa-square-root:after, .fa-duotone.fa-square-root:after {
  content: "\221a\fe02"; }

.fad.fa-square-root-variable:before, .fa-duotone.fa-square-root-variable:before {
  content: "\f698\fe01"; }

.fad.fa-square-root-variable:after, .fa-duotone.fa-square-root-variable:after {
  content: "\f698\fe02"; }

.fad.fa-square-root-alt:before, .fa-duotone.fa-square-root-alt:before {
  content: "\f698\fe01"; }

.fad.fa-square-root-alt:after, .fa-duotone.fa-square-root-alt:after {
  content: "\f698\fe02"; }

.fad.fa-square-rss:before, .fa-duotone.fa-square-rss:before {
  content: "\f143\fe01"; }

.fad.fa-square-rss:after, .fa-duotone.fa-square-rss:after {
  content: "\f143\fe02"; }

.fad.fa-rss-square:before, .fa-duotone.fa-rss-square:before {
  content: "\f143\fe01"; }

.fad.fa-rss-square:after, .fa-duotone.fa-rss-square:after {
  content: "\f143\fe02"; }

.fad.fa-square-s:before, .fa-duotone.fa-square-s:before {
  content: "\e27d\fe01"; }

.fad.fa-square-s:after, .fa-duotone.fa-square-s:after {
  content: "\e27d\fe02"; }

.fad.fa-square-share-nodes:before, .fa-duotone.fa-square-share-nodes:before {
  content: "\f1e1\fe01"; }

.fad.fa-square-share-nodes:after, .fa-duotone.fa-square-share-nodes:after {
  content: "\f1e1\fe02"; }

.fad.fa-share-alt-square:before, .fa-duotone.fa-share-alt-square:before {
  content: "\f1e1\fe01"; }

.fad.fa-share-alt-square:after, .fa-duotone.fa-share-alt-square:after {
  content: "\f1e1\fe02"; }

.fad.fa-square-sliders:before, .fa-duotone.fa-square-sliders:before {
  content: "\f3f0\fe01"; }

.fad.fa-square-sliders:after, .fa-duotone.fa-square-sliders:after {
  content: "\f3f0\fe02"; }

.fad.fa-sliders-h-square:before, .fa-duotone.fa-sliders-h-square:before {
  content: "\f3f0\fe01"; }

.fad.fa-sliders-h-square:after, .fa-duotone.fa-sliders-h-square:after {
  content: "\f3f0\fe02"; }

.fad.fa-square-sliders-vertical:before, .fa-duotone.fa-square-sliders-vertical:before {
  content: "\f3f2\fe01"; }

.fad.fa-square-sliders-vertical:after, .fa-duotone.fa-square-sliders-vertical:after {
  content: "\f3f2\fe02"; }

.fad.fa-sliders-v-square:before, .fa-duotone.fa-sliders-v-square:before {
  content: "\f3f2\fe01"; }

.fad.fa-sliders-v-square:after, .fa-duotone.fa-sliders-v-square:after {
  content: "\f3f2\fe02"; }

.fad.fa-square-small:before, .fa-duotone.fa-square-small:before {
  content: "\e27e\fe01"; }

.fad.fa-square-small:after, .fa-duotone.fa-square-small:after {
  content: "\e27e\fe02"; }

.fad.fa-square-star:before, .fa-duotone.fa-square-star:before {
  content: "\e27f\fe01"; }

.fad.fa-square-star:after, .fa-duotone.fa-square-star:after {
  content: "\e27f\fe02"; }

.fad.fa-square-t:before, .fa-duotone.fa-square-t:before {
  content: "\e280\fe01"; }

.fad.fa-square-t:after, .fa-duotone.fa-square-t:after {
  content: "\e280\fe02"; }

.fad.fa-square-terminal:before, .fa-duotone.fa-square-terminal:before {
  content: "\e32a\fe01"; }

.fad.fa-square-terminal:after, .fa-duotone.fa-square-terminal:after {
  content: "\e32a\fe02"; }

.fad.fa-square-this-way-up:before, .fa-duotone.fa-square-this-way-up:before {
  content: "\f49f\fe01"; }

.fad.fa-square-this-way-up:after, .fa-duotone.fa-square-this-way-up:after {
  content: "\f49f\fe02"; }

.fad.fa-box-up:before, .fa-duotone.fa-box-up:before {
  content: "\f49f\fe01"; }

.fad.fa-box-up:after, .fa-duotone.fa-box-up:after {
  content: "\f49f\fe02"; }

.fad.fa-square-u:before, .fa-duotone.fa-square-u:before {
  content: "\e281\fe01"; }

.fad.fa-square-u:after, .fa-duotone.fa-square-u:after {
  content: "\e281\fe02"; }

.fad.fa-square-up:before, .fa-duotone.fa-square-up:before {
  content: "\2b06\fe01"; }

.fad.fa-square-up:after, .fa-duotone.fa-square-up:after {
  content: "\2b06\fe02"; }

.fad.fa-arrow-alt-square-up:before, .fa-duotone.fa-arrow-alt-square-up:before {
  content: "\2b06\fe01"; }

.fad.fa-arrow-alt-square-up:after, .fa-duotone.fa-arrow-alt-square-up:after {
  content: "\2b06\fe02"; }

.fad.fa-square-up-left:before, .fa-duotone.fa-square-up-left:before {
  content: "\e282\fe01"; }

.fad.fa-square-up-left:after, .fa-duotone.fa-square-up-left:after {
  content: "\e282\fe02"; }

.fad.fa-square-up-right:before, .fa-duotone.fa-square-up-right:before {
  content: "\2197\fe01"; }

.fad.fa-square-up-right:after, .fa-duotone.fa-square-up-right:after {
  content: "\2197\fe02"; }

.fad.fa-external-link-square-alt:before, .fa-duotone.fa-external-link-square-alt:before {
  content: "\2197\fe01"; }

.fad.fa-external-link-square-alt:after, .fa-duotone.fa-external-link-square-alt:after {
  content: "\2197\fe02"; }

.fad.fa-square-user:before, .fa-duotone.fa-square-user:before {
  content: "\e283\fe01"; }

.fad.fa-square-user:after, .fa-duotone.fa-square-user:after {
  content: "\e283\fe02"; }

.fad.fa-square-v:before, .fa-duotone.fa-square-v:before {
  content: "\e284\fe01"; }

.fad.fa-square-v:after, .fa-duotone.fa-square-v:after {
  content: "\e284\fe02"; }

.fad.fa-square-w:before, .fa-duotone.fa-square-w:before {
  content: "\e285\fe01"; }

.fad.fa-square-w:after, .fa-duotone.fa-square-w:after {
  content: "\e285\fe02"; }

.fad.fa-square-x:before, .fa-duotone.fa-square-x:before {
  content: "\e286\fe01"; }

.fad.fa-square-x:after, .fa-duotone.fa-square-x:after {
  content: "\e286\fe02"; }

.fad.fa-square-xmark:before, .fa-duotone.fa-square-xmark:before {
  content: "\274e\fe01"; }

.fad.fa-square-xmark:after, .fa-duotone.fa-square-xmark:after {
  content: "\274e\fe02"; }

.fad.fa-times-square:before, .fa-duotone.fa-times-square:before {
  content: "\274e\fe01"; }

.fad.fa-times-square:after, .fa-duotone.fa-times-square:after {
  content: "\274e\fe02"; }

.fad.fa-xmark-square:before, .fa-duotone.fa-xmark-square:before {
  content: "\274e\fe01"; }

.fad.fa-xmark-square:after, .fa-duotone.fa-xmark-square:after {
  content: "\274e\fe02"; }

.fad.fa-square-y:before, .fa-duotone.fa-square-y:before {
  content: "\e287\fe01"; }

.fad.fa-square-y:after, .fa-duotone.fa-square-y:after {
  content: "\e287\fe02"; }

.fad.fa-square-z:before, .fa-duotone.fa-square-z:before {
  content: "\e288\fe01"; }

.fad.fa-square-z:after, .fa-duotone.fa-square-z:after {
  content: "\e288\fe02"; }

.fad.fa-squirrel:before, .fa-duotone.fa-squirrel:before {
  content: "\f71a\fe01"; }

.fad.fa-squirrel:after, .fa-duotone.fa-squirrel:after {
  content: "\f71a\fe02"; }

.fad.fa-staff:before, .fa-duotone.fa-staff:before {
  content: "\f71b\fe01"; }

.fad.fa-staff:after, .fa-duotone.fa-staff:after {
  content: "\f71b\fe02"; }

.fad.fa-stairs:before, .fa-duotone.fa-stairs:before {
  content: "\e289\fe01"; }

.fad.fa-stairs:after, .fa-duotone.fa-stairs:after {
  content: "\e289\fe02"; }

.fad.fa-stamp:before, .fa-duotone.fa-stamp:before {
  content: "\f5bf\fe01"; }

.fad.fa-stamp:after, .fa-duotone.fa-stamp:after {
  content: "\f5bf\fe02"; }

.fad.fa-standard-definition:before, .fa-duotone.fa-standard-definition:before {
  content: "\e28a\fe01"; }

.fad.fa-standard-definition:after, .fa-duotone.fa-standard-definition:after {
  content: "\e28a\fe02"; }

.fad.fa-rectangle-sd:before, .fa-duotone.fa-rectangle-sd:before {
  content: "\e28a\fe01"; }

.fad.fa-rectangle-sd:after, .fa-duotone.fa-rectangle-sd:after {
  content: "\e28a\fe02"; }

.fad.fa-star:before, .fa-duotone.fa-star:before {
  content: "\2b50\fe01"; }

.fad.fa-star:after, .fa-duotone.fa-star:after {
  content: "\2b50\fe02"; }

.fad.fa-star-and-crescent:before, .fa-duotone.fa-star-and-crescent:before {
  content: "\262a\fe01"; }

.fad.fa-star-and-crescent:after, .fa-duotone.fa-star-and-crescent:after {
  content: "\262a\fe02"; }

.fad.fa-star-christmas:before, .fa-duotone.fa-star-christmas:before {
  content: "\f7d4\fe01"; }

.fad.fa-star-christmas:after, .fa-duotone.fa-star-christmas:after {
  content: "\f7d4\fe02"; }

.fad.fa-star-exclamation:before, .fa-duotone.fa-star-exclamation:before {
  content: "\f2f3\fe01"; }

.fad.fa-star-exclamation:after, .fa-duotone.fa-star-exclamation:after {
  content: "\f2f3\fe02"; }

.fad.fa-star-half:before, .fa-duotone.fa-star-half:before {
  content: "\f089\fe01"; }

.fad.fa-star-half:after, .fa-duotone.fa-star-half:after {
  content: "\f089\fe02"; }

.fad.fa-star-half-stroke:before, .fa-duotone.fa-star-half-stroke:before {
  content: "\f5c0\fe01"; }

.fad.fa-star-half-stroke:after, .fa-duotone.fa-star-half-stroke:after {
  content: "\f5c0\fe02"; }

.fad.fa-star-half-alt:before, .fa-duotone.fa-star-half-alt:before {
  content: "\f5c0\fe01"; }

.fad.fa-star-half-alt:after, .fa-duotone.fa-star-half-alt:after {
  content: "\f5c0\fe02"; }

.fad.fa-star-of-david:before, .fa-duotone.fa-star-of-david:before {
  content: "\2721\fe01"; }

.fad.fa-star-of-david:after, .fa-duotone.fa-star-of-david:after {
  content: "\2721\fe02"; }

.fad.fa-star-of-life:before, .fa-duotone.fa-star-of-life:before {
  content: "\f621\fe01"; }

.fad.fa-star-of-life:after, .fa-duotone.fa-star-of-life:after {
  content: "\f621\fe02"; }

.fad.fa-star-sharp:before, .fa-duotone.fa-star-sharp:before {
  content: "\e28b\fe01"; }

.fad.fa-star-sharp:after, .fa-duotone.fa-star-sharp:after {
  content: "\e28b\fe02"; }

.fad.fa-star-sharp-half:before, .fa-duotone.fa-star-sharp-half:before {
  content: "\e28c\fe01"; }

.fad.fa-star-sharp-half:after, .fa-duotone.fa-star-sharp-half:after {
  content: "\e28c\fe02"; }

.fad.fa-star-sharp-half-stroke:before, .fa-duotone.fa-star-sharp-half-stroke:before {
  content: "\e28d\fe01"; }

.fad.fa-star-sharp-half-stroke:after, .fa-duotone.fa-star-sharp-half-stroke:after {
  content: "\e28d\fe02"; }

.fad.fa-star-sharp-half-alt:before, .fa-duotone.fa-star-sharp-half-alt:before {
  content: "\e28d\fe01"; }

.fad.fa-star-sharp-half-alt:after, .fa-duotone.fa-star-sharp-half-alt:after {
  content: "\e28d\fe02"; }

.fad.fa-star-shooting:before, .fa-duotone.fa-star-shooting:before {
  content: "\e036"; }

.fad.fa-star-shooting:after, .fa-duotone.fa-star-shooting:after {
  content: "\10e036"; }

.fad.fa-starfighter:before, .fa-duotone.fa-starfighter:before {
  content: "\e037\fe01"; }

.fad.fa-starfighter:after, .fa-duotone.fa-starfighter:after {
  content: "\e037\fe02"; }

.fad.fa-starfighter-twin-ion-engine:before, .fa-duotone.fa-starfighter-twin-ion-engine:before {
  content: "\e038\fe01"; }

.fad.fa-starfighter-twin-ion-engine:after, .fa-duotone.fa-starfighter-twin-ion-engine:after {
  content: "\e038\fe02"; }

.fad.fa-starfighter-alt:before, .fa-duotone.fa-starfighter-alt:before {
  content: "\e038\fe01"; }

.fad.fa-starfighter-alt:after, .fa-duotone.fa-starfighter-alt:after {
  content: "\e038\fe02"; }

.fad.fa-starfighter-twin-ion-engine-advanced:before, .fa-duotone.fa-starfighter-twin-ion-engine-advanced:before {
  content: "\e28e\fe01"; }

.fad.fa-starfighter-twin-ion-engine-advanced:after, .fa-duotone.fa-starfighter-twin-ion-engine-advanced:after {
  content: "\e28e\fe02"; }

.fad.fa-starfighter-alt-advanced:before, .fa-duotone.fa-starfighter-alt-advanced:before {
  content: "\e28e\fe01"; }

.fad.fa-starfighter-alt-advanced:after, .fa-duotone.fa-starfighter-alt-advanced:after {
  content: "\e28e\fe02"; }

.fad.fa-stars:before, .fa-duotone.fa-stars:before {
  content: "\f762\fe01"; }

.fad.fa-stars:after, .fa-duotone.fa-stars:after {
  content: "\f762\fe02"; }

.fad.fa-starship:before, .fa-duotone.fa-starship:before {
  content: "\e039\fe01"; }

.fad.fa-starship:after, .fa-duotone.fa-starship:after {
  content: "\e039\fe02"; }

.fad.fa-starship-freighter:before, .fa-duotone.fa-starship-freighter:before {
  content: "\e03a\fe01"; }

.fad.fa-starship-freighter:after, .fa-duotone.fa-starship-freighter:after {
  content: "\e03a\fe02"; }

.fad.fa-steak:before, .fa-duotone.fa-steak:before {
  content: "\f824"; }

.fad.fa-steak:after, .fa-duotone.fa-steak:after {
  content: "\10f824"; }

.fad.fa-steering-wheel:before, .fa-duotone.fa-steering-wheel:before {
  content: "\f622\fe01"; }

.fad.fa-steering-wheel:after, .fa-duotone.fa-steering-wheel:after {
  content: "\f622\fe02"; }

.fad.fa-sterling-sign:before, .fa-duotone.fa-sterling-sign:before {
  content: "\a3\fe01"; }

.fad.fa-sterling-sign:after, .fa-duotone.fa-sterling-sign:after {
  content: "\a3\fe02"; }

.fad.fa-gbp:before, .fa-duotone.fa-gbp:before {
  content: "\a3\fe01"; }

.fad.fa-gbp:after, .fa-duotone.fa-gbp:after {
  content: "\a3\fe02"; }

.fad.fa-pound-sign:before, .fa-duotone.fa-pound-sign:before {
  content: "\a3\fe01"; }

.fad.fa-pound-sign:after, .fa-duotone.fa-pound-sign:after {
  content: "\a3\fe02"; }

.fad.fa-stethoscope:before, .fa-duotone.fa-stethoscope:before {
  content: "\f0f1"; }

.fad.fa-stethoscope:after, .fa-duotone.fa-stethoscope:after {
  content: "\10f0f1"; }

.fad.fa-stocking:before, .fa-duotone.fa-stocking:before {
  content: "\f7d5\fe01"; }

.fad.fa-stocking:after, .fa-duotone.fa-stocking:after {
  content: "\f7d5\fe02"; }

.fad.fa-stomach:before, .fa-duotone.fa-stomach:before {
  content: "\f623\fe01"; }

.fad.fa-stomach:after, .fa-duotone.fa-stomach:after {
  content: "\f623\fe02"; }

.fad.fa-stop:before, .fa-duotone.fa-stop:before {
  content: "\23f9\fe01"; }

.fad.fa-stop:after, .fa-duotone.fa-stop:after {
  content: "\23f9\fe02"; }

.fad.fa-stopwatch:before, .fa-duotone.fa-stopwatch:before {
  content: "\23f1\fe01"; }

.fad.fa-stopwatch:after, .fa-duotone.fa-stopwatch:after {
  content: "\23f1\fe02"; }

.fad.fa-stopwatch-20:before, .fa-duotone.fa-stopwatch-20:before {
  content: "\e06f\fe01"; }

.fad.fa-stopwatch-20:after, .fa-duotone.fa-stopwatch-20:after {
  content: "\e06f\fe02"; }

.fad.fa-store:before, .fa-duotone.fa-store:before {
  content: "\f54e\fe01"; }

.fad.fa-store:after, .fa-duotone.fa-store:after {
  content: "\f54e\fe02"; }

.fad.fa-store-slash:before, .fa-duotone.fa-store-slash:before {
  content: "\e071\fe01"; }

.fad.fa-store-slash:after, .fa-duotone.fa-store-slash:after {
  content: "\e071\fe02"; }

.fad.fa-strawberry:before, .fa-duotone.fa-strawberry:before {
  content: "\e32b\fe01"; }

.fad.fa-strawberry:after, .fa-duotone.fa-strawberry:after {
  content: "\e32b\fe02"; }

.fad.fa-street-view:before, .fa-duotone.fa-street-view:before {
  content: "\f21d\fe01"; }

.fad.fa-street-view:after, .fa-duotone.fa-street-view:after {
  content: "\f21d\fe02"; }

.fad.fa-stretcher:before, .fa-duotone.fa-stretcher:before {
  content: "\f825\fe01"; }

.fad.fa-stretcher:after, .fa-duotone.fa-stretcher:after {
  content: "\f825\fe02"; }

.fad.fa-strikethrough:before, .fa-duotone.fa-strikethrough:before {
  content: "\f0cc\fe01"; }

.fad.fa-strikethrough:after, .fa-duotone.fa-strikethrough:after {
  content: "\f0cc\fe02"; }

.fad.fa-stroopwafel:before, .fa-duotone.fa-stroopwafel:before {
  content: "\f551\fe01"; }

.fad.fa-stroopwafel:after, .fa-duotone.fa-stroopwafel:after {
  content: "\f551\fe02"; }

.fad.fa-subscript:before, .fa-duotone.fa-subscript:before {
  content: "\f12c\fe01"; }

.fad.fa-subscript:after, .fa-duotone.fa-subscript:after {
  content: "\f12c\fe02"; }

.fad.fa-suitcase:before, .fa-duotone.fa-suitcase:before {
  content: "\f0f2"; }

.fad.fa-suitcase:after, .fa-duotone.fa-suitcase:after {
  content: "\10f0f2"; }

.fad.fa-suitcase-medical:before, .fa-duotone.fa-suitcase-medical:before {
  content: "\f0fa\fe01"; }

.fad.fa-suitcase-medical:after, .fa-duotone.fa-suitcase-medical:after {
  content: "\f0fa\fe02"; }

.fad.fa-medkit:before, .fa-duotone.fa-medkit:before {
  content: "\f0fa\fe01"; }

.fad.fa-medkit:after, .fa-duotone.fa-medkit:after {
  content: "\f0fa\fe02"; }

.fad.fa-suitcase-rolling:before, .fa-duotone.fa-suitcase-rolling:before {
  content: "\f5c1\fe01"; }

.fad.fa-suitcase-rolling:after, .fa-duotone.fa-suitcase-rolling:after {
  content: "\f5c1\fe02"; }

.fad.fa-sun:before, .fa-duotone.fa-sun:before {
  content: "\2600\fe01"; }

.fad.fa-sun:after, .fa-duotone.fa-sun:after {
  content: "\2600\fe02"; }

.fad.fa-sun-bright:before, .fa-duotone.fa-sun-bright:before {
  content: "\e28f\fe01"; }

.fad.fa-sun-bright:after, .fa-duotone.fa-sun-bright:after {
  content: "\e28f\fe02"; }

.fad.fa-sun-alt:before, .fa-duotone.fa-sun-alt:before {
  content: "\e28f\fe01"; }

.fad.fa-sun-alt:after, .fa-duotone.fa-sun-alt:after {
  content: "\e28f\fe02"; }

.fad.fa-sun-cloud:before, .fa-duotone.fa-sun-cloud:before {
  content: "\f763"; }

.fad.fa-sun-cloud:after, .fa-duotone.fa-sun-cloud:after {
  content: "\10f763"; }

.fad.fa-sun-dust:before, .fa-duotone.fa-sun-dust:before {
  content: "\f764\fe01"; }

.fad.fa-sun-dust:after, .fa-duotone.fa-sun-dust:after {
  content: "\f764\fe02"; }

.fad.fa-sun-haze:before, .fa-duotone.fa-sun-haze:before {
  content: "\f765\fe01"; }

.fad.fa-sun-haze:after, .fa-duotone.fa-sun-haze:after {
  content: "\f765\fe02"; }

.fad.fa-sunglasses:before, .fa-duotone.fa-sunglasses:before {
  content: "\f892"; }

.fad.fa-sunglasses:after, .fa-duotone.fa-sunglasses:after {
  content: "\10f892"; }

.fad.fa-sunrise:before, .fa-duotone.fa-sunrise:before {
  content: "\f766"; }

.fad.fa-sunrise:after, .fa-duotone.fa-sunrise:after {
  content: "\10f766"; }

.fad.fa-sunset:before, .fa-duotone.fa-sunset:before {
  content: "\f767"; }

.fad.fa-sunset:after, .fa-duotone.fa-sunset:after {
  content: "\10f767"; }

.fad.fa-superscript:before, .fa-duotone.fa-superscript:before {
  content: "\f12b\fe01"; }

.fad.fa-superscript:after, .fa-duotone.fa-superscript:after {
  content: "\f12b\fe02"; }

.fad.fa-swatchbook:before, .fa-duotone.fa-swatchbook:before {
  content: "\f5c3\fe01"; }

.fad.fa-swatchbook:after, .fa-duotone.fa-swatchbook:after {
  content: "\f5c3\fe02"; }

.fad.fa-sword:before, .fa-duotone.fa-sword:before {
  content: "\f71c"; }

.fad.fa-sword:after, .fa-duotone.fa-sword:after {
  content: "\10f71c"; }

.fad.fa-sword-laser:before, .fa-duotone.fa-sword-laser:before {
  content: "\e03b\fe01"; }

.fad.fa-sword-laser:after, .fa-duotone.fa-sword-laser:after {
  content: "\e03b\fe02"; }

.fad.fa-sword-laser-alt:before, .fa-duotone.fa-sword-laser-alt:before {
  content: "\e03c\fe01"; }

.fad.fa-sword-laser-alt:after, .fa-duotone.fa-sword-laser-alt:after {
  content: "\e03c\fe02"; }

.fad.fa-swords:before, .fa-duotone.fa-swords:before {
  content: "\2694\fe01"; }

.fad.fa-swords:after, .fa-duotone.fa-swords:after {
  content: "\2694\fe02"; }

.fad.fa-swords-laser:before, .fa-duotone.fa-swords-laser:before {
  content: "\e03d\fe01"; }

.fad.fa-swords-laser:after, .fa-duotone.fa-swords-laser:after {
  content: "\e03d\fe02"; }

.fad.fa-symbols:before, .fa-duotone.fa-symbols:before {
  content: "\f86e"; }

.fad.fa-symbols:after, .fa-duotone.fa-symbols:after {
  content: "\10f86e"; }

.fad.fa-icons-alt:before, .fa-duotone.fa-icons-alt:before {
  content: "\f86e"; }

.fad.fa-icons-alt:after, .fa-duotone.fa-icons-alt:after {
  content: "\10f86e"; }

.fad.fa-synagogue:before, .fa-duotone.fa-synagogue:before {
  content: "\f69b"; }

.fad.fa-synagogue:after, .fa-duotone.fa-synagogue:after {
  content: "\10f69b"; }

.fad.fa-syringe:before, .fa-duotone.fa-syringe:before {
  content: "\f48e"; }

.fad.fa-syringe:after, .fa-duotone.fa-syringe:after {
  content: "\10f48e"; }

.fad.fa-t:before, .fa-duotone.fa-t:before {
  content: "\54\fe01"; }

.fad.fa-t:after, .fa-duotone.fa-t:after {
  content: "\54\fe02"; }

.fad.fa-table:before, .fa-duotone.fa-table:before {
  content: "\f0ce\fe01"; }

.fad.fa-table:after, .fa-duotone.fa-table:after {
  content: "\f0ce\fe02"; }

.fad.fa-table-cells:before, .fa-duotone.fa-table-cells:before {
  content: "\f00a\fe01"; }

.fad.fa-table-cells:after, .fa-duotone.fa-table-cells:after {
  content: "\f00a\fe02"; }

.fad.fa-th:before, .fa-duotone.fa-th:before {
  content: "\f00a\fe01"; }

.fad.fa-th:after, .fa-duotone.fa-th:after {
  content: "\f00a\fe02"; }

.fad.fa-table-cells-large:before, .fa-duotone.fa-table-cells-large:before {
  content: "\f009\fe01"; }

.fad.fa-table-cells-large:after, .fa-duotone.fa-table-cells-large:after {
  content: "\f009\fe02"; }

.fad.fa-th-large:before, .fa-duotone.fa-th-large:before {
  content: "\f009\fe01"; }

.fad.fa-th-large:after, .fa-duotone.fa-th-large:after {
  content: "\f009\fe02"; }

.fad.fa-table-columns:before, .fa-duotone.fa-table-columns:before {
  content: "\f0db\fe01"; }

.fad.fa-table-columns:after, .fa-duotone.fa-table-columns:after {
  content: "\f0db\fe02"; }

.fad.fa-columns:before, .fa-duotone.fa-columns:before {
  content: "\f0db\fe01"; }

.fad.fa-columns:after, .fa-duotone.fa-columns:after {
  content: "\f0db\fe02"; }

.fad.fa-table-layout:before, .fa-duotone.fa-table-layout:before {
  content: "\e290\fe01"; }

.fad.fa-table-layout:after, .fa-duotone.fa-table-layout:after {
  content: "\e290\fe02"; }

.fad.fa-table-list:before, .fa-duotone.fa-table-list:before {
  content: "\f00b\fe01"; }

.fad.fa-table-list:after, .fa-duotone.fa-table-list:after {
  content: "\f00b\fe02"; }

.fad.fa-th-list:before, .fa-duotone.fa-th-list:before {
  content: "\f00b\fe01"; }

.fad.fa-th-list:after, .fa-duotone.fa-th-list:after {
  content: "\f00b\fe02"; }

.fad.fa-table-picnic:before, .fa-duotone.fa-table-picnic:before {
  content: "\e32d\fe01"; }

.fad.fa-table-picnic:after, .fa-duotone.fa-table-picnic:after {
  content: "\e32d\fe02"; }

.fad.fa-table-pivot:before, .fa-duotone.fa-table-pivot:before {
  content: "\e291\fe01"; }

.fad.fa-table-pivot:after, .fa-duotone.fa-table-pivot:after {
  content: "\e291\fe02"; }

.fad.fa-table-rows:before, .fa-duotone.fa-table-rows:before {
  content: "\e292\fe01"; }

.fad.fa-table-rows:after, .fa-duotone.fa-table-rows:after {
  content: "\e292\fe02"; }

.fad.fa-rows:before, .fa-duotone.fa-rows:before {
  content: "\e292\fe01"; }

.fad.fa-rows:after, .fa-duotone.fa-rows:after {
  content: "\e292\fe02"; }

.fad.fa-table-tennis-paddle-ball:before, .fa-duotone.fa-table-tennis-paddle-ball:before {
  content: "\f45d"; }

.fad.fa-table-tennis-paddle-ball:after, .fa-duotone.fa-table-tennis-paddle-ball:after {
  content: "\10f45d"; }

.fad.fa-ping-pong-paddle-ball:before, .fa-duotone.fa-ping-pong-paddle-ball:before {
  content: "\f45d"; }

.fad.fa-ping-pong-paddle-ball:after, .fa-duotone.fa-ping-pong-paddle-ball:after {
  content: "\10f45d"; }

.fad.fa-table-tennis:before, .fa-duotone.fa-table-tennis:before {
  content: "\f45d"; }

.fad.fa-table-tennis:after, .fa-duotone.fa-table-tennis:after {
  content: "\10f45d"; }

.fad.fa-table-tree:before, .fa-duotone.fa-table-tree:before {
  content: "\e293\fe01"; }

.fad.fa-table-tree:after, .fa-duotone.fa-table-tree:after {
  content: "\e293\fe02"; }

.fad.fa-tablet:before, .fa-duotone.fa-tablet:before {
  content: "\f3fb\fe01"; }

.fad.fa-tablet:after, .fa-duotone.fa-tablet:after {
  content: "\f3fb\fe02"; }

.fad.fa-tablet-android:before, .fa-duotone.fa-tablet-android:before {
  content: "\f3fb\fe01"; }

.fad.fa-tablet-android:after, .fa-duotone.fa-tablet-android:after {
  content: "\f3fb\fe02"; }

.fad.fa-tablet-button:before, .fa-duotone.fa-tablet-button:before {
  content: "\f10a\fe01"; }

.fad.fa-tablet-button:after, .fa-duotone.fa-tablet-button:after {
  content: "\f10a\fe02"; }

.fad.fa-tablet-rugged:before, .fa-duotone.fa-tablet-rugged:before {
  content: "\f48f\fe01"; }

.fad.fa-tablet-rugged:after, .fa-duotone.fa-tablet-rugged:after {
  content: "\f48f\fe02"; }

.fad.fa-tablet-screen:before, .fa-duotone.fa-tablet-screen:before {
  content: "\f3fc\fe01"; }

.fad.fa-tablet-screen:after, .fa-duotone.fa-tablet-screen:after {
  content: "\f3fc\fe02"; }

.fad.fa-tablet-android-alt:before, .fa-duotone.fa-tablet-android-alt:before {
  content: "\f3fc\fe01"; }

.fad.fa-tablet-android-alt:after, .fa-duotone.fa-tablet-android-alt:after {
  content: "\f3fc\fe02"; }

.fad.fa-tablet-screen-button:before, .fa-duotone.fa-tablet-screen-button:before {
  content: "\f3fa\fe01"; }

.fad.fa-tablet-screen-button:after, .fa-duotone.fa-tablet-screen-button:after {
  content: "\f3fa\fe02"; }

.fad.fa-tablet-alt:before, .fa-duotone.fa-tablet-alt:before {
  content: "\f3fa\fe01"; }

.fad.fa-tablet-alt:after, .fa-duotone.fa-tablet-alt:after {
  content: "\f3fa\fe02"; }

.fad.fa-tablets:before, .fa-duotone.fa-tablets:before {
  content: "\f490\fe01"; }

.fad.fa-tablets:after, .fa-duotone.fa-tablets:after {
  content: "\f490\fe02"; }

.fad.fa-tachograph-digital:before, .fa-duotone.fa-tachograph-digital:before {
  content: "\f566\fe01"; }

.fad.fa-tachograph-digital:after, .fa-duotone.fa-tachograph-digital:after {
  content: "\f566\fe02"; }

.fad.fa-digital-tachograph:before, .fa-duotone.fa-digital-tachograph:before {
  content: "\f566\fe01"; }

.fad.fa-digital-tachograph:after, .fa-duotone.fa-digital-tachograph:after {
  content: "\f566\fe02"; }

.fad.fa-taco:before, .fa-duotone.fa-taco:before {
  content: "\f826"; }

.fad.fa-taco:after, .fa-duotone.fa-taco:after {
  content: "\10f826"; }

.fad.fa-tag:before, .fa-duotone.fa-tag:before {
  content: "\f02b"; }

.fad.fa-tag:after, .fa-duotone.fa-tag:after {
  content: "\10f02b"; }

.fad.fa-tags:before, .fa-duotone.fa-tags:before {
  content: "\f02c\fe01"; }

.fad.fa-tags:after, .fa-duotone.fa-tags:after {
  content: "\f02c\fe02"; }

.fad.fa-tally:before, .fa-duotone.fa-tally:before {
  content: "\f69c\fe01"; }

.fad.fa-tally:after, .fa-duotone.fa-tally:after {
  content: "\f69c\fe02"; }

.fad.fa-tally-5:before, .fa-duotone.fa-tally-5:before {
  content: "\f69c\fe01"; }

.fad.fa-tally-5:after, .fa-duotone.fa-tally-5:after {
  content: "\f69c\fe02"; }

.fad.fa-tally-1:before, .fa-duotone.fa-tally-1:before {
  content: "\e294\fe01"; }

.fad.fa-tally-1:after, .fa-duotone.fa-tally-1:after {
  content: "\e294\fe02"; }

.fad.fa-tally-2:before, .fa-duotone.fa-tally-2:before {
  content: "\e295\fe01"; }

.fad.fa-tally-2:after, .fa-duotone.fa-tally-2:after {
  content: "\e295\fe02"; }

.fad.fa-tally-3:before, .fa-duotone.fa-tally-3:before {
  content: "\e296\fe01"; }

.fad.fa-tally-3:after, .fa-duotone.fa-tally-3:after {
  content: "\e296\fe02"; }

.fad.fa-tally-4:before, .fa-duotone.fa-tally-4:before {
  content: "\e297\fe01"; }

.fad.fa-tally-4:after, .fa-duotone.fa-tally-4:after {
  content: "\e297\fe02"; }

.fad.fa-tape:before, .fa-duotone.fa-tape:before {
  content: "\f4db\fe01"; }

.fad.fa-tape:after, .fa-duotone.fa-tape:after {
  content: "\f4db\fe02"; }

.fad.fa-taxi:before, .fa-duotone.fa-taxi:before {
  content: "\f1ba"; }

.fad.fa-taxi:after, .fa-duotone.fa-taxi:after {
  content: "\10f1ba"; }

.fad.fa-cab:before, .fa-duotone.fa-cab:before {
  content: "\f1ba"; }

.fad.fa-cab:after, .fa-duotone.fa-cab:after {
  content: "\10f1ba"; }

.fad.fa-taxi-bus:before, .fa-duotone.fa-taxi-bus:before {
  content: "\e298\fe01"; }

.fad.fa-taxi-bus:after, .fa-duotone.fa-taxi-bus:after {
  content: "\e298\fe02"; }

.fad.fa-teddy-bear:before, .fa-duotone.fa-teddy-bear:before {
  content: "\e3cf\fe01"; }

.fad.fa-teddy-bear:after, .fa-duotone.fa-teddy-bear:after {
  content: "\e3cf\fe02"; }

.fad.fa-teeth:before, .fa-duotone.fa-teeth:before {
  content: "\f62e\fe01"; }

.fad.fa-teeth:after, .fa-duotone.fa-teeth:after {
  content: "\f62e\fe02"; }

.fad.fa-teeth-open:before, .fa-duotone.fa-teeth-open:before {
  content: "\f62f\fe01"; }

.fad.fa-teeth-open:after, .fa-duotone.fa-teeth-open:after {
  content: "\f62f\fe02"; }

.fad.fa-telescope:before, .fa-duotone.fa-telescope:before {
  content: "\e03e"; }

.fad.fa-telescope:after, .fa-duotone.fa-telescope:after {
  content: "\10e03e"; }

.fad.fa-temperature-arrow-down:before, .fa-duotone.fa-temperature-arrow-down:before {
  content: "\e03f\fe01"; }

.fad.fa-temperature-arrow-down:after, .fa-duotone.fa-temperature-arrow-down:after {
  content: "\e03f\fe02"; }

.fad.fa-temperature-down:before, .fa-duotone.fa-temperature-down:before {
  content: "\e03f\fe01"; }

.fad.fa-temperature-down:after, .fa-duotone.fa-temperature-down:after {
  content: "\e03f\fe02"; }

.fad.fa-temperature-arrow-up:before, .fa-duotone.fa-temperature-arrow-up:before {
  content: "\e040\fe01"; }

.fad.fa-temperature-arrow-up:after, .fa-duotone.fa-temperature-arrow-up:after {
  content: "\e040\fe02"; }

.fad.fa-temperature-up:before, .fa-duotone.fa-temperature-up:before {
  content: "\e040\fe01"; }

.fad.fa-temperature-up:after, .fa-duotone.fa-temperature-up:after {
  content: "\e040\fe02"; }

.fad.fa-temperature-empty:before, .fa-duotone.fa-temperature-empty:before {
  content: "\f2cb\fe01"; }

.fad.fa-temperature-empty:after, .fa-duotone.fa-temperature-empty:after {
  content: "\f2cb\fe02"; }

.fad.fa-temperature-0:before, .fa-duotone.fa-temperature-0:before {
  content: "\f2cb\fe01"; }

.fad.fa-temperature-0:after, .fa-duotone.fa-temperature-0:after {
  content: "\f2cb\fe02"; }

.fad.fa-thermometer-0:before, .fa-duotone.fa-thermometer-0:before {
  content: "\f2cb\fe01"; }

.fad.fa-thermometer-0:after, .fa-duotone.fa-thermometer-0:after {
  content: "\f2cb\fe02"; }

.fad.fa-thermometer-empty:before, .fa-duotone.fa-thermometer-empty:before {
  content: "\f2cb\fe01"; }

.fad.fa-thermometer-empty:after, .fa-duotone.fa-thermometer-empty:after {
  content: "\f2cb\fe02"; }

.fad.fa-temperature-full:before, .fa-duotone.fa-temperature-full:before {
  content: "\f2c7\fe01"; }

.fad.fa-temperature-full:after, .fa-duotone.fa-temperature-full:after {
  content: "\f2c7\fe02"; }

.fad.fa-temperature-4:before, .fa-duotone.fa-temperature-4:before {
  content: "\f2c7\fe01"; }

.fad.fa-temperature-4:after, .fa-duotone.fa-temperature-4:after {
  content: "\f2c7\fe02"; }

.fad.fa-thermometer-4:before, .fa-duotone.fa-thermometer-4:before {
  content: "\f2c7\fe01"; }

.fad.fa-thermometer-4:after, .fa-duotone.fa-thermometer-4:after {
  content: "\f2c7\fe02"; }

.fad.fa-thermometer-full:before, .fa-duotone.fa-thermometer-full:before {
  content: "\f2c7\fe01"; }

.fad.fa-thermometer-full:after, .fa-duotone.fa-thermometer-full:after {
  content: "\f2c7\fe02"; }

.fad.fa-temperature-half:before, .fa-duotone.fa-temperature-half:before {
  content: "\f2c9"; }

.fad.fa-temperature-half:after, .fa-duotone.fa-temperature-half:after {
  content: "\10f2c9"; }

.fad.fa-temperature-2:before, .fa-duotone.fa-temperature-2:before {
  content: "\f2c9"; }

.fad.fa-temperature-2:after, .fa-duotone.fa-temperature-2:after {
  content: "\10f2c9"; }

.fad.fa-thermometer-2:before, .fa-duotone.fa-thermometer-2:before {
  content: "\f2c9"; }

.fad.fa-thermometer-2:after, .fa-duotone.fa-thermometer-2:after {
  content: "\10f2c9"; }

.fad.fa-thermometer-half:before, .fa-duotone.fa-thermometer-half:before {
  content: "\f2c9"; }

.fad.fa-thermometer-half:after, .fa-duotone.fa-thermometer-half:after {
  content: "\10f2c9"; }

.fad.fa-temperature-high:before, .fa-duotone.fa-temperature-high:before {
  content: "\f769\fe01"; }

.fad.fa-temperature-high:after, .fa-duotone.fa-temperature-high:after {
  content: "\f769\fe02"; }

.fad.fa-temperature-list:before, .fa-duotone.fa-temperature-list:before {
  content: "\e299\fe01"; }

.fad.fa-temperature-list:after, .fa-duotone.fa-temperature-list:after {
  content: "\e299\fe02"; }

.fad.fa-temperature-low:before, .fa-duotone.fa-temperature-low:before {
  content: "\f76b\fe01"; }

.fad.fa-temperature-low:after, .fa-duotone.fa-temperature-low:after {
  content: "\f76b\fe02"; }

.fad.fa-temperature-quarter:before, .fa-duotone.fa-temperature-quarter:before {
  content: "\f2ca\fe01"; }

.fad.fa-temperature-quarter:after, .fa-duotone.fa-temperature-quarter:after {
  content: "\f2ca\fe02"; }

.fad.fa-temperature-1:before, .fa-duotone.fa-temperature-1:before {
  content: "\f2ca\fe01"; }

.fad.fa-temperature-1:after, .fa-duotone.fa-temperature-1:after {
  content: "\f2ca\fe02"; }

.fad.fa-thermometer-1:before, .fa-duotone.fa-thermometer-1:before {
  content: "\f2ca\fe01"; }

.fad.fa-thermometer-1:after, .fa-duotone.fa-thermometer-1:after {
  content: "\f2ca\fe02"; }

.fad.fa-thermometer-quarter:before, .fa-duotone.fa-thermometer-quarter:before {
  content: "\f2ca\fe01"; }

.fad.fa-thermometer-quarter:after, .fa-duotone.fa-thermometer-quarter:after {
  content: "\f2ca\fe02"; }

.fad.fa-temperature-snow:before, .fa-duotone.fa-temperature-snow:before {
  content: "\f768\fe01"; }

.fad.fa-temperature-snow:after, .fa-duotone.fa-temperature-snow:after {
  content: "\f768\fe02"; }

.fad.fa-temperature-frigid:before, .fa-duotone.fa-temperature-frigid:before {
  content: "\f768\fe01"; }

.fad.fa-temperature-frigid:after, .fa-duotone.fa-temperature-frigid:after {
  content: "\f768\fe02"; }

.fad.fa-temperature-sun:before, .fa-duotone.fa-temperature-sun:before {
  content: "\f76a\fe01"; }

.fad.fa-temperature-sun:after, .fa-duotone.fa-temperature-sun:after {
  content: "\f76a\fe02"; }

.fad.fa-temperature-hot:before, .fa-duotone.fa-temperature-hot:before {
  content: "\f76a\fe01"; }

.fad.fa-temperature-hot:after, .fa-duotone.fa-temperature-hot:after {
  content: "\f76a\fe02"; }

.fad.fa-temperature-three-quarters:before, .fa-duotone.fa-temperature-three-quarters:before {
  content: "\f2c8\fe01"; }

.fad.fa-temperature-three-quarters:after, .fa-duotone.fa-temperature-three-quarters:after {
  content: "\f2c8\fe02"; }

.fad.fa-temperature-3:before, .fa-duotone.fa-temperature-3:before {
  content: "\f2c8\fe01"; }

.fad.fa-temperature-3:after, .fa-duotone.fa-temperature-3:after {
  content: "\f2c8\fe02"; }

.fad.fa-thermometer-3:before, .fa-duotone.fa-thermometer-3:before {
  content: "\f2c8\fe01"; }

.fad.fa-thermometer-3:after, .fa-duotone.fa-thermometer-3:after {
  content: "\f2c8\fe02"; }

.fad.fa-thermometer-three-quarters:before, .fa-duotone.fa-thermometer-three-quarters:before {
  content: "\f2c8\fe01"; }

.fad.fa-thermometer-three-quarters:after, .fa-duotone.fa-thermometer-three-quarters:after {
  content: "\f2c8\fe02"; }

.fad.fa-tenge-sign:before, .fa-duotone.fa-tenge-sign:before {
  content: "\20b8\fe01"; }

.fad.fa-tenge-sign:after, .fa-duotone.fa-tenge-sign:after {
  content: "\20b8\fe02"; }

.fad.fa-tenge:before, .fa-duotone.fa-tenge:before {
  content: "\20b8\fe01"; }

.fad.fa-tenge:after, .fa-duotone.fa-tenge:after {
  content: "\20b8\fe02"; }

.fad.fa-tennis-ball:before, .fa-duotone.fa-tennis-ball:before {
  content: "\f45e"; }

.fad.fa-tennis-ball:after, .fa-duotone.fa-tennis-ball:after {
  content: "\10f45e"; }

.fad.fa-terminal:before, .fa-duotone.fa-terminal:before {
  content: "\f120\fe01"; }

.fad.fa-terminal:after, .fa-duotone.fa-terminal:after {
  content: "\f120\fe02"; }

.fad.fa-text:before, .fa-duotone.fa-text:before {
  content: "\f893\fe01"; }

.fad.fa-text:after, .fa-duotone.fa-text:after {
  content: "\f893\fe02"; }

.fad.fa-text-height:before, .fa-duotone.fa-text-height:before {
  content: "\f034\fe01"; }

.fad.fa-text-height:after, .fa-duotone.fa-text-height:after {
  content: "\f034\fe02"; }

.fad.fa-text-size:before, .fa-duotone.fa-text-size:before {
  content: "\f894\fe01"; }

.fad.fa-text-size:after, .fa-duotone.fa-text-size:after {
  content: "\f894\fe02"; }

.fad.fa-text-slash:before, .fa-duotone.fa-text-slash:before {
  content: "\f87d\fe01"; }

.fad.fa-text-slash:after, .fa-duotone.fa-text-slash:after {
  content: "\f87d\fe02"; }

.fad.fa-remove-format:before, .fa-duotone.fa-remove-format:before {
  content: "\f87d\fe01"; }

.fad.fa-remove-format:after, .fa-duotone.fa-remove-format:after {
  content: "\f87d\fe02"; }

.fad.fa-text-width:before, .fa-duotone.fa-text-width:before {
  content: "\f035\fe01"; }

.fad.fa-text-width:after, .fa-duotone.fa-text-width:after {
  content: "\f035\fe02"; }

.fad.fa-thermometer:before, .fa-duotone.fa-thermometer:before {
  content: "\f491\fe01"; }

.fad.fa-thermometer:after, .fa-duotone.fa-thermometer:after {
  content: "\f491\fe02"; }

.fad.fa-theta:before, .fa-duotone.fa-theta:before {
  content: "\f69e\fe01"; }

.fad.fa-theta:after, .fa-duotone.fa-theta:after {
  content: "\f69e\fe02"; }

.fad.fa-thought-bubble:before, .fa-duotone.fa-thought-bubble:before {
  content: "\e32e\fe01"; }

.fad.fa-thought-bubble:after, .fa-duotone.fa-thought-bubble:after {
  content: "\e32e\fe02"; }

.fad.fa-thumbs-down:before, .fa-duotone.fa-thumbs-down:before {
  content: "\f165"; }

.fad.fa-thumbs-down:after, .fa-duotone.fa-thumbs-down:after {
  content: "\10f165"; }

.fad.fa-thumbs-up:before, .fa-duotone.fa-thumbs-up:before {
  content: "\f164"; }

.fad.fa-thumbs-up:after, .fa-duotone.fa-thumbs-up:after {
  content: "\10f164"; }

.fad.fa-thumbtack:before, .fa-duotone.fa-thumbtack:before {
  content: "\f08d"; }

.fad.fa-thumbtack:after, .fa-duotone.fa-thumbtack:after {
  content: "\10f08d"; }

.fad.fa-thumb-tack:before, .fa-duotone.fa-thumb-tack:before {
  content: "\f08d"; }

.fad.fa-thumb-tack:after, .fa-duotone.fa-thumb-tack:after {
  content: "\10f08d"; }

.fad.fa-tick:before, .fa-duotone.fa-tick:before {
  content: "\e32f\fe01"; }

.fad.fa-tick:after, .fa-duotone.fa-tick:after {
  content: "\e32f\fe02"; }

.fad.fa-ticket:before, .fa-duotone.fa-ticket:before {
  content: "\f145"; }

.fad.fa-ticket:after, .fa-duotone.fa-ticket:after {
  content: "\10f145"; }

.fad.fa-ticket-airline:before, .fa-duotone.fa-ticket-airline:before {
  content: "\e29a\fe01"; }

.fad.fa-ticket-airline:after, .fa-duotone.fa-ticket-airline:after {
  content: "\e29a\fe02"; }

.fad.fa-ticket-simple:before, .fa-duotone.fa-ticket-simple:before {
  content: "\f3ff\fe01"; }

.fad.fa-ticket-simple:after, .fa-duotone.fa-ticket-simple:after {
  content: "\f3ff\fe02"; }

.fad.fa-ticket-alt:before, .fa-duotone.fa-ticket-alt:before {
  content: "\f3ff\fe01"; }

.fad.fa-ticket-alt:after, .fa-duotone.fa-ticket-alt:after {
  content: "\f3ff\fe02"; }

.fad.fa-tickets-airline:before, .fa-duotone.fa-tickets-airline:before {
  content: "\e29b\fe01"; }

.fad.fa-tickets-airline:after, .fa-duotone.fa-tickets-airline:after {
  content: "\e29b\fe02"; }

.fad.fa-tilde:before, .fa-duotone.fa-tilde:before {
  content: "\7e\fe01"; }

.fad.fa-tilde:after, .fa-duotone.fa-tilde:after {
  content: "\7e\fe02"; }

.fad.fa-timeline:before, .fa-duotone.fa-timeline:before {
  content: "\e29c\fe01"; }

.fad.fa-timeline:after, .fa-duotone.fa-timeline:after {
  content: "\e29c\fe02"; }

.fad.fa-timeline-arrow:before, .fa-duotone.fa-timeline-arrow:before {
  content: "\e29d\fe01"; }

.fad.fa-timeline-arrow:after, .fa-duotone.fa-timeline-arrow:after {
  content: "\e29d\fe02"; }

.fad.fa-timer:before, .fa-duotone.fa-timer:before {
  content: "\e29e\fe01"; }

.fad.fa-timer:after, .fa-duotone.fa-timer:after {
  content: "\e29e\fe02"; }

.fad.fa-tire:before, .fa-duotone.fa-tire:before {
  content: "\f631\fe01"; }

.fad.fa-tire:after, .fa-duotone.fa-tire:after {
  content: "\f631\fe02"; }

.fad.fa-tire-flat:before, .fa-duotone.fa-tire-flat:before {
  content: "\f632\fe01"; }

.fad.fa-tire-flat:after, .fa-duotone.fa-tire-flat:after {
  content: "\f632\fe02"; }

.fad.fa-tire-pressure-warning:before, .fa-duotone.fa-tire-pressure-warning:before {
  content: "\f633\fe01"; }

.fad.fa-tire-pressure-warning:after, .fa-duotone.fa-tire-pressure-warning:after {
  content: "\f633\fe02"; }

.fad.fa-tire-rugged:before, .fa-duotone.fa-tire-rugged:before {
  content: "\f634\fe01"; }

.fad.fa-tire-rugged:after, .fa-duotone.fa-tire-rugged:after {
  content: "\f634\fe02"; }

.fad.fa-toggle-off:before, .fa-duotone.fa-toggle-off:before {
  content: "\f204\fe01"; }

.fad.fa-toggle-off:after, .fa-duotone.fa-toggle-off:after {
  content: "\f204\fe02"; }

.fad.fa-toggle-on:before, .fa-duotone.fa-toggle-on:before {
  content: "\f205\fe01"; }

.fad.fa-toggle-on:after, .fa-duotone.fa-toggle-on:after {
  content: "\f205\fe02"; }

.fad.fa-toilet:before, .fa-duotone.fa-toilet:before {
  content: "\f7d8"; }

.fad.fa-toilet:after, .fa-duotone.fa-toilet:after {
  content: "\10f7d8"; }

.fad.fa-toilet-paper:before, .fa-duotone.fa-toilet-paper:before {
  content: "\f71e"; }

.fad.fa-toilet-paper:after, .fa-duotone.fa-toilet-paper:after {
  content: "\10f71e"; }

.fad.fa-toilet-paper-blank:before, .fa-duotone.fa-toilet-paper-blank:before {
  content: "\f71f\fe01"; }

.fad.fa-toilet-paper-blank:after, .fa-duotone.fa-toilet-paper-blank:after {
  content: "\f71f\fe02"; }

.fad.fa-toilet-paper-alt:before, .fa-duotone.fa-toilet-paper-alt:before {
  content: "\f71f\fe01"; }

.fad.fa-toilet-paper-alt:after, .fa-duotone.fa-toilet-paper-alt:after {
  content: "\f71f\fe02"; }

.fad.fa-toilet-paper-blank-under:before, .fa-duotone.fa-toilet-paper-blank-under:before {
  content: "\e29f\fe01"; }

.fad.fa-toilet-paper-blank-under:after, .fa-duotone.fa-toilet-paper-blank-under:after {
  content: "\e29f\fe02"; }

.fad.fa-toilet-paper-reverse-alt:before, .fa-duotone.fa-toilet-paper-reverse-alt:before {
  content: "\e29f\fe01"; }

.fad.fa-toilet-paper-reverse-alt:after, .fa-duotone.fa-toilet-paper-reverse-alt:after {
  content: "\e29f\fe02"; }

.fad.fa-toilet-paper-slash:before, .fa-duotone.fa-toilet-paper-slash:before {
  content: "\e072\fe01"; }

.fad.fa-toilet-paper-slash:after, .fa-duotone.fa-toilet-paper-slash:after {
  content: "\e072\fe02"; }

.fad.fa-toilet-paper-under:before, .fa-duotone.fa-toilet-paper-under:before {
  content: "\e2a0\fe01"; }

.fad.fa-toilet-paper-under:after, .fa-duotone.fa-toilet-paper-under:after {
  content: "\e2a0\fe02"; }

.fad.fa-toilet-paper-reverse:before, .fa-duotone.fa-toilet-paper-reverse:before {
  content: "\e2a0\fe01"; }

.fad.fa-toilet-paper-reverse:after, .fa-duotone.fa-toilet-paper-reverse:after {
  content: "\e2a0\fe02"; }

.fad.fa-toilet-paper-under-slash:before, .fa-duotone.fa-toilet-paper-under-slash:before {
  content: "\e2a1\fe01"; }

.fad.fa-toilet-paper-under-slash:after, .fa-duotone.fa-toilet-paper-under-slash:after {
  content: "\e2a1\fe02"; }

.fad.fa-toilet-paper-reverse-slash:before, .fa-duotone.fa-toilet-paper-reverse-slash:before {
  content: "\e2a1\fe01"; }

.fad.fa-toilet-paper-reverse-slash:after, .fa-duotone.fa-toilet-paper-reverse-slash:after {
  content: "\e2a1\fe02"; }

.fad.fa-tomato:before, .fa-duotone.fa-tomato:before {
  content: "\e330\fe01"; }

.fad.fa-tomato:after, .fa-duotone.fa-tomato:after {
  content: "\e330\fe02"; }

.fad.fa-tombstone:before, .fa-duotone.fa-tombstone:before {
  content: "\f720\fe01"; }

.fad.fa-tombstone:after, .fa-duotone.fa-tombstone:after {
  content: "\f720\fe02"; }

.fad.fa-tombstone-blank:before, .fa-duotone.fa-tombstone-blank:before {
  content: "\f721"; }

.fad.fa-tombstone-blank:after, .fa-duotone.fa-tombstone-blank:after {
  content: "\10f721"; }

.fad.fa-tombstone-alt:before, .fa-duotone.fa-tombstone-alt:before {
  content: "\f721"; }

.fad.fa-tombstone-alt:after, .fa-duotone.fa-tombstone-alt:after {
  content: "\10f721"; }

.fad.fa-toolbox:before, .fa-duotone.fa-toolbox:before {
  content: "\f552"; }

.fad.fa-toolbox:after, .fa-duotone.fa-toolbox:after {
  content: "\10f552"; }

.fad.fa-tooth:before, .fa-duotone.fa-tooth:before {
  content: "\f5c9"; }

.fad.fa-tooth:after, .fa-duotone.fa-tooth:after {
  content: "\10f5c9"; }

.fad.fa-toothbrush:before, .fa-duotone.fa-toothbrush:before {
  content: "\f635"; }

.fad.fa-toothbrush:after, .fa-duotone.fa-toothbrush:after {
  content: "\10f635"; }

.fad.fa-torii-gate:before, .fa-duotone.fa-torii-gate:before {
  content: "\26e9\fe01"; }

.fad.fa-torii-gate:after, .fa-duotone.fa-torii-gate:after {
  content: "\26e9\fe02"; }

.fad.fa-tornado:before, .fa-duotone.fa-tornado:before {
  content: "\f76f"; }

.fad.fa-tornado:after, .fa-duotone.fa-tornado:after {
  content: "\10f76f"; }

.fad.fa-tower-broadcast:before, .fa-duotone.fa-tower-broadcast:before {
  content: "\f519\fe01"; }

.fad.fa-tower-broadcast:after, .fa-duotone.fa-tower-broadcast:after {
  content: "\f519\fe02"; }

.fad.fa-broadcast-tower:before, .fa-duotone.fa-broadcast-tower:before {
  content: "\f519\fe01"; }

.fad.fa-broadcast-tower:after, .fa-duotone.fa-broadcast-tower:after {
  content: "\f519\fe02"; }

.fad.fa-tower-control:before, .fa-duotone.fa-tower-control:before {
  content: "\e2a2\fe01"; }

.fad.fa-tower-control:after, .fa-duotone.fa-tower-control:after {
  content: "\e2a2\fe02"; }

.fad.fa-tractor:before, .fa-duotone.fa-tractor:before {
  content: "\f722"; }

.fad.fa-tractor:after, .fa-duotone.fa-tractor:after {
  content: "\10f722"; }

.fad.fa-trademark:before, .fa-duotone.fa-trademark:before {
  content: "\2122\fe01"; }

.fad.fa-trademark:after, .fa-duotone.fa-trademark:after {
  content: "\2122\fe02"; }

.fad.fa-traffic-cone:before, .fa-duotone.fa-traffic-cone:before {
  content: "\f636\fe01"; }

.fad.fa-traffic-cone:after, .fa-duotone.fa-traffic-cone:after {
  content: "\f636\fe02"; }

.fad.fa-traffic-light:before, .fa-duotone.fa-traffic-light:before {
  content: "\f637"; }

.fad.fa-traffic-light:after, .fa-duotone.fa-traffic-light:after {
  content: "\10f637"; }

.fad.fa-traffic-light-go:before, .fa-duotone.fa-traffic-light-go:before {
  content: "\f638\fe01"; }

.fad.fa-traffic-light-go:after, .fa-duotone.fa-traffic-light-go:after {
  content: "\f638\fe02"; }

.fad.fa-traffic-light-slow:before, .fa-duotone.fa-traffic-light-slow:before {
  content: "\f639\fe01"; }

.fad.fa-traffic-light-slow:after, .fa-duotone.fa-traffic-light-slow:after {
  content: "\f639\fe02"; }

.fad.fa-traffic-light-stop:before, .fa-duotone.fa-traffic-light-stop:before {
  content: "\f63a\fe01"; }

.fad.fa-traffic-light-stop:after, .fa-duotone.fa-traffic-light-stop:after {
  content: "\f63a\fe02"; }

.fad.fa-trailer:before, .fa-duotone.fa-trailer:before {
  content: "\e041\fe01"; }

.fad.fa-trailer:after, .fa-duotone.fa-trailer:after {
  content: "\e041\fe02"; }

.fad.fa-train:before, .fa-duotone.fa-train:before {
  content: "\f238"; }

.fad.fa-train:after, .fa-duotone.fa-train:after {
  content: "\10f238"; }

.fad.fa-train-subway:before, .fa-duotone.fa-train-subway:before {
  content: "\f239\fe01"; }

.fad.fa-train-subway:after, .fa-duotone.fa-train-subway:after {
  content: "\f239\fe02"; }

.fad.fa-subway:before, .fa-duotone.fa-subway:before {
  content: "\f239\fe01"; }

.fad.fa-subway:after, .fa-duotone.fa-subway:after {
  content: "\f239\fe02"; }

.fad.fa-train-subway-tunnel:before, .fa-duotone.fa-train-subway-tunnel:before {
  content: "\e2a3\fe01"; }

.fad.fa-train-subway-tunnel:after, .fa-duotone.fa-train-subway-tunnel:after {
  content: "\e2a3\fe02"; }

.fad.fa-subway-tunnel:before, .fa-duotone.fa-subway-tunnel:before {
  content: "\e2a3\fe01"; }

.fad.fa-subway-tunnel:after, .fa-duotone.fa-subway-tunnel:after {
  content: "\e2a3\fe02"; }

.fad.fa-train-tram:before, .fa-duotone.fa-train-tram:before {
  content: "\f7da"; }

.fad.fa-train-tram:after, .fa-duotone.fa-train-tram:after {
  content: "\10f7da"; }

.fad.fa-tram:before, .fa-duotone.fa-tram:before {
  content: "\f7da"; }

.fad.fa-tram:after, .fa-duotone.fa-tram:after {
  content: "\10f7da"; }

.fad.fa-transformer-bolt:before, .fa-duotone.fa-transformer-bolt:before {
  content: "\e2a4\fe01"; }

.fad.fa-transformer-bolt:after, .fa-duotone.fa-transformer-bolt:after {
  content: "\e2a4\fe02"; }

.fad.fa-transgender:before, .fa-duotone.fa-transgender:before {
  content: "\26a7\fe01"; }

.fad.fa-transgender:after, .fa-duotone.fa-transgender:after {
  content: "\26a7\fe02"; }

.fad.fa-transgender-alt:before, .fa-duotone.fa-transgender-alt:before {
  content: "\26a7\fe01"; }

.fad.fa-transgender-alt:after, .fa-duotone.fa-transgender-alt:after {
  content: "\26a7\fe02"; }

.fad.fa-transporter:before, .fa-duotone.fa-transporter:before {
  content: "\e042\fe01"; }

.fad.fa-transporter:after, .fa-duotone.fa-transporter:after {
  content: "\e042\fe02"; }

.fad.fa-transporter-1:before, .fa-duotone.fa-transporter-1:before {
  content: "\e043\fe01"; }

.fad.fa-transporter-1:after, .fa-duotone.fa-transporter-1:after {
  content: "\e043\fe02"; }

.fad.fa-transporter-2:before, .fa-duotone.fa-transporter-2:before {
  content: "\e044\fe01"; }

.fad.fa-transporter-2:after, .fa-duotone.fa-transporter-2:after {
  content: "\e044\fe02"; }

.fad.fa-transporter-3:before, .fa-duotone.fa-transporter-3:before {
  content: "\e045\fe01"; }

.fad.fa-transporter-3:after, .fa-duotone.fa-transporter-3:after {
  content: "\e045\fe02"; }

.fad.fa-transporter-4:before, .fa-duotone.fa-transporter-4:before {
  content: "\e2a5\fe01"; }

.fad.fa-transporter-4:after, .fa-duotone.fa-transporter-4:after {
  content: "\e2a5\fe02"; }

.fad.fa-transporter-5:before, .fa-duotone.fa-transporter-5:before {
  content: "\e2a6\fe01"; }

.fad.fa-transporter-5:after, .fa-duotone.fa-transporter-5:after {
  content: "\e2a6\fe02"; }

.fad.fa-transporter-6:before, .fa-duotone.fa-transporter-6:before {
  content: "\e2a7\fe01"; }

.fad.fa-transporter-6:after, .fa-duotone.fa-transporter-6:after {
  content: "\e2a7\fe02"; }

.fad.fa-transporter-7:before, .fa-duotone.fa-transporter-7:before {
  content: "\e2a8\fe01"; }

.fad.fa-transporter-7:after, .fa-duotone.fa-transporter-7:after {
  content: "\e2a8\fe02"; }

.fad.fa-transporter-empty:before, .fa-duotone.fa-transporter-empty:before {
  content: "\e046\fe01"; }

.fad.fa-transporter-empty:after, .fa-duotone.fa-transporter-empty:after {
  content: "\e046\fe02"; }

.fad.fa-trash:before, .fa-duotone.fa-trash:before {
  content: "\f1f8\fe01"; }

.fad.fa-trash:after, .fa-duotone.fa-trash:after {
  content: "\f1f8\fe02"; }

.fad.fa-trash-arrow-up:before, .fa-duotone.fa-trash-arrow-up:before {
  content: "\f829\fe01"; }

.fad.fa-trash-arrow-up:after, .fa-duotone.fa-trash-arrow-up:after {
  content: "\f829\fe02"; }

.fad.fa-trash-restore:before, .fa-duotone.fa-trash-restore:before {
  content: "\f829\fe01"; }

.fad.fa-trash-restore:after, .fa-duotone.fa-trash-restore:after {
  content: "\f829\fe02"; }

.fad.fa-trash-can:before, .fa-duotone.fa-trash-can:before {
  content: "\f2ed\fe01"; }

.fad.fa-trash-can:after, .fa-duotone.fa-trash-can:after {
  content: "\f2ed\fe02"; }

.fad.fa-trash-alt:before, .fa-duotone.fa-trash-alt:before {
  content: "\f2ed\fe01"; }

.fad.fa-trash-alt:after, .fa-duotone.fa-trash-alt:after {
  content: "\f2ed\fe02"; }

.fad.fa-trash-can-arrow-up:before, .fa-duotone.fa-trash-can-arrow-up:before {
  content: "\f82a\fe01"; }

.fad.fa-trash-can-arrow-up:after, .fa-duotone.fa-trash-can-arrow-up:after {
  content: "\f82a\fe02"; }

.fad.fa-trash-restore-alt:before, .fa-duotone.fa-trash-restore-alt:before {
  content: "\f82a\fe01"; }

.fad.fa-trash-restore-alt:after, .fa-duotone.fa-trash-restore-alt:after {
  content: "\f82a\fe02"; }

.fad.fa-trash-can-check:before, .fa-duotone.fa-trash-can-check:before {
  content: "\e2a9\fe01"; }

.fad.fa-trash-can-check:after, .fa-duotone.fa-trash-can-check:after {
  content: "\e2a9\fe02"; }

.fad.fa-trash-can-clock:before, .fa-duotone.fa-trash-can-clock:before {
  content: "\e2aa\fe01"; }

.fad.fa-trash-can-clock:after, .fa-duotone.fa-trash-can-clock:after {
  content: "\e2aa\fe02"; }

.fad.fa-trash-can-list:before, .fa-duotone.fa-trash-can-list:before {
  content: "\e2ab\fe01"; }

.fad.fa-trash-can-list:after, .fa-duotone.fa-trash-can-list:after {
  content: "\e2ab\fe02"; }

.fad.fa-trash-can-plus:before, .fa-duotone.fa-trash-can-plus:before {
  content: "\e2ac\fe01"; }

.fad.fa-trash-can-plus:after, .fa-duotone.fa-trash-can-plus:after {
  content: "\e2ac\fe02"; }

.fad.fa-trash-can-slash:before, .fa-duotone.fa-trash-can-slash:before {
  content: "\e2ad\fe01"; }

.fad.fa-trash-can-slash:after, .fa-duotone.fa-trash-can-slash:after {
  content: "\e2ad\fe02"; }

.fad.fa-trash-alt-slash:before, .fa-duotone.fa-trash-alt-slash:before {
  content: "\e2ad\fe01"; }

.fad.fa-trash-alt-slash:after, .fa-duotone.fa-trash-alt-slash:after {
  content: "\e2ad\fe02"; }

.fad.fa-trash-can-undo:before, .fa-duotone.fa-trash-can-undo:before {
  content: "\f896\fe01"; }

.fad.fa-trash-can-undo:after, .fa-duotone.fa-trash-can-undo:after {
  content: "\f896\fe02"; }

.fad.fa-trash-can-arrow-turn-left:before, .fa-duotone.fa-trash-can-arrow-turn-left:before {
  content: "\f896\fe01"; }

.fad.fa-trash-can-arrow-turn-left:after, .fa-duotone.fa-trash-can-arrow-turn-left:after {
  content: "\f896\fe02"; }

.fad.fa-trash-undo-alt:before, .fa-duotone.fa-trash-undo-alt:before {
  content: "\f896\fe01"; }

.fad.fa-trash-undo-alt:after, .fa-duotone.fa-trash-undo-alt:after {
  content: "\f896\fe02"; }

.fad.fa-trash-can-xmark:before, .fa-duotone.fa-trash-can-xmark:before {
  content: "\e2ae\fe01"; }

.fad.fa-trash-can-xmark:after, .fa-duotone.fa-trash-can-xmark:after {
  content: "\e2ae\fe02"; }

.fad.fa-trash-check:before, .fa-duotone.fa-trash-check:before {
  content: "\e2af\fe01"; }

.fad.fa-trash-check:after, .fa-duotone.fa-trash-check:after {
  content: "\e2af\fe02"; }

.fad.fa-trash-clock:before, .fa-duotone.fa-trash-clock:before {
  content: "\e2b0\fe01"; }

.fad.fa-trash-clock:after, .fa-duotone.fa-trash-clock:after {
  content: "\e2b0\fe02"; }

.fad.fa-trash-list:before, .fa-duotone.fa-trash-list:before {
  content: "\e2b1\fe01"; }

.fad.fa-trash-list:after, .fa-duotone.fa-trash-list:after {
  content: "\e2b1\fe02"; }

.fad.fa-trash-plus:before, .fa-duotone.fa-trash-plus:before {
  content: "\e2b2\fe01"; }

.fad.fa-trash-plus:after, .fa-duotone.fa-trash-plus:after {
  content: "\e2b2\fe02"; }

.fad.fa-trash-slash:before, .fa-duotone.fa-trash-slash:before {
  content: "\e2b3\fe01"; }

.fad.fa-trash-slash:after, .fa-duotone.fa-trash-slash:after {
  content: "\e2b3\fe02"; }

.fad.fa-trash-undo:before, .fa-duotone.fa-trash-undo:before {
  content: "\f895\fe01"; }

.fad.fa-trash-undo:after, .fa-duotone.fa-trash-undo:after {
  content: "\f895\fe02"; }

.fad.fa-trash-arrow-turn-left:before, .fa-duotone.fa-trash-arrow-turn-left:before {
  content: "\f895\fe01"; }

.fad.fa-trash-arrow-turn-left:after, .fa-duotone.fa-trash-arrow-turn-left:after {
  content: "\f895\fe02"; }

.fad.fa-trash-xmark:before, .fa-duotone.fa-trash-xmark:before {
  content: "\e2b4\fe01"; }

.fad.fa-trash-xmark:after, .fa-duotone.fa-trash-xmark:after {
  content: "\e2b4\fe02"; }

.fad.fa-treasure-chest:before, .fa-duotone.fa-treasure-chest:before {
  content: "\f723\fe01"; }

.fad.fa-treasure-chest:after, .fa-duotone.fa-treasure-chest:after {
  content: "\f723\fe02"; }

.fad.fa-tree:before, .fa-duotone.fa-tree:before {
  content: "\f1bb"; }

.fad.fa-tree:after, .fa-duotone.fa-tree:after {
  content: "\10f1bb"; }

.fad.fa-tree-christmas:before, .fa-duotone.fa-tree-christmas:before {
  content: "\f7db"; }

.fad.fa-tree-christmas:after, .fa-duotone.fa-tree-christmas:after {
  content: "\10f7db"; }

.fad.fa-tree-deciduous:before, .fa-duotone.fa-tree-deciduous:before {
  content: "\f400"; }

.fad.fa-tree-deciduous:after, .fa-duotone.fa-tree-deciduous:after {
  content: "\10f400"; }

.fad.fa-tree-alt:before, .fa-duotone.fa-tree-alt:before {
  content: "\f400"; }

.fad.fa-tree-alt:after, .fa-duotone.fa-tree-alt:after {
  content: "\10f400"; }

.fad.fa-tree-decorated:before, .fa-duotone.fa-tree-decorated:before {
  content: "\f7dc\fe01"; }

.fad.fa-tree-decorated:after, .fa-duotone.fa-tree-decorated:after {
  content: "\f7dc\fe02"; }

.fad.fa-tree-large:before, .fa-duotone.fa-tree-large:before {
  content: "\f7dd\fe01"; }

.fad.fa-tree-large:after, .fa-duotone.fa-tree-large:after {
  content: "\f7dd\fe02"; }

.fad.fa-tree-palm:before, .fa-duotone.fa-tree-palm:before {
  content: "\f82b"; }

.fad.fa-tree-palm:after, .fa-duotone.fa-tree-palm:after {
  content: "\10f82b"; }

.fad.fa-trees:before, .fa-duotone.fa-trees:before {
  content: "\f724\fe01"; }

.fad.fa-trees:after, .fa-duotone.fa-trees:after {
  content: "\f724\fe02"; }

.fad.fa-triangle:before, .fa-duotone.fa-triangle:before {
  content: "\25b2\fe01"; }

.fad.fa-triangle:after, .fa-duotone.fa-triangle:after {
  content: "\25b2\fe02"; }

.fad.fa-triangle-exclamation:before, .fa-duotone.fa-triangle-exclamation:before {
  content: "\26a0\fe01"; }

.fad.fa-triangle-exclamation:after, .fa-duotone.fa-triangle-exclamation:after {
  content: "\26a0\fe02"; }

.fad.fa-exclamation-triangle:before, .fa-duotone.fa-exclamation-triangle:before {
  content: "\26a0\fe01"; }

.fad.fa-exclamation-triangle:after, .fa-duotone.fa-exclamation-triangle:after {
  content: "\26a0\fe02"; }

.fad.fa-warning:before, .fa-duotone.fa-warning:before {
  content: "\26a0\fe01"; }

.fad.fa-warning:after, .fa-duotone.fa-warning:after {
  content: "\26a0\fe02"; }

.fad.fa-triangle-instrument:before, .fa-duotone.fa-triangle-instrument:before {
  content: "\f8e2\fe01"; }

.fad.fa-triangle-instrument:after, .fa-duotone.fa-triangle-instrument:after {
  content: "\f8e2\fe02"; }

.fad.fa-triangle-music:before, .fa-duotone.fa-triangle-music:before {
  content: "\f8e2\fe01"; }

.fad.fa-triangle-music:after, .fa-duotone.fa-triangle-music:after {
  content: "\f8e2\fe02"; }

.fad.fa-triangle-person-digging:before, .fa-duotone.fa-triangle-person-digging:before {
  content: "\f85d\fe01"; }

.fad.fa-triangle-person-digging:after, .fa-duotone.fa-triangle-person-digging:after {
  content: "\f85d\fe02"; }

.fad.fa-construction:before, .fa-duotone.fa-construction:before {
  content: "\f85d\fe01"; }

.fad.fa-construction:after, .fa-duotone.fa-construction:after {
  content: "\f85d\fe02"; }

.fad.fa-trophy:before, .fa-duotone.fa-trophy:before {
  content: "\f091"; }

.fad.fa-trophy:after, .fa-duotone.fa-trophy:after {
  content: "\10f091"; }

.fad.fa-trophy-star:before, .fa-duotone.fa-trophy-star:before {
  content: "\f2eb\fe01"; }

.fad.fa-trophy-star:after, .fa-duotone.fa-trophy-star:after {
  content: "\f2eb\fe02"; }

.fad.fa-trophy-alt:before, .fa-duotone.fa-trophy-alt:before {
  content: "\f2eb\fe01"; }

.fad.fa-trophy-alt:after, .fa-duotone.fa-trophy-alt:after {
  content: "\f2eb\fe02"; }

.fad.fa-truck:before, .fa-duotone.fa-truck:before {
  content: "\26df\fe01"; }

.fad.fa-truck:after, .fa-duotone.fa-truck:after {
  content: "\26df\fe02"; }

.fad.fa-truck-bolt:before, .fa-duotone.fa-truck-bolt:before {
  content: "\e3d0\fe01"; }

.fad.fa-truck-bolt:after, .fa-duotone.fa-truck-bolt:after {
  content: "\e3d0\fe02"; }

.fad.fa-truck-clock:before, .fa-duotone.fa-truck-clock:before {
  content: "\f48c\fe01"; }

.fad.fa-truck-clock:after, .fa-duotone.fa-truck-clock:after {
  content: "\f48c\fe02"; }

.fad.fa-shipping-timed:before, .fa-duotone.fa-shipping-timed:before {
  content: "\f48c\fe01"; }

.fad.fa-shipping-timed:after, .fa-duotone.fa-shipping-timed:after {
  content: "\f48c\fe02"; }

.fad.fa-truck-container:before, .fa-duotone.fa-truck-container:before {
  content: "\f4dc\fe01"; }

.fad.fa-truck-container:after, .fa-duotone.fa-truck-container:after {
  content: "\f4dc\fe02"; }

.fad.fa-truck-container-empty:before, .fa-duotone.fa-truck-container-empty:before {
  content: "\e2b5\fe01"; }

.fad.fa-truck-container-empty:after, .fa-duotone.fa-truck-container-empty:after {
  content: "\e2b5\fe02"; }

.fad.fa-truck-fast:before, .fa-duotone.fa-truck-fast:before {
  content: "\f48b\fe01"; }

.fad.fa-truck-fast:after, .fa-duotone.fa-truck-fast:after {
  content: "\f48b\fe02"; }

.fad.fa-shipping-fast:before, .fa-duotone.fa-shipping-fast:before {
  content: "\f48b\fe01"; }

.fad.fa-shipping-fast:after, .fa-duotone.fa-shipping-fast:after {
  content: "\f48b\fe02"; }

.fad.fa-truck-flatbed:before, .fa-duotone.fa-truck-flatbed:before {
  content: "\e2b6\fe01"; }

.fad.fa-truck-flatbed:after, .fa-duotone.fa-truck-flatbed:after {
  content: "\e2b6\fe02"; }

.fad.fa-truck-front:before, .fa-duotone.fa-truck-front:before {
  content: "\e2b7\fe01"; }

.fad.fa-truck-front:after, .fa-duotone.fa-truck-front:after {
  content: "\e2b7\fe02"; }

.fad.fa-truck-medical:before, .fa-duotone.fa-truck-medical:before {
  content: "\f0f9"; }

.fad.fa-truck-medical:after, .fa-duotone.fa-truck-medical:after {
  content: "\10f0f9"; }

.fad.fa-ambulance:before, .fa-duotone.fa-ambulance:before {
  content: "\f0f9"; }

.fad.fa-ambulance:after, .fa-duotone.fa-ambulance:after {
  content: "\10f0f9"; }

.fad.fa-truck-monster:before, .fa-duotone.fa-truck-monster:before {
  content: "\f63b\fe01"; }

.fad.fa-truck-monster:after, .fa-duotone.fa-truck-monster:after {
  content: "\f63b\fe02"; }

.fad.fa-truck-moving:before, .fa-duotone.fa-truck-moving:before {
  content: "\f4df\fe01"; }

.fad.fa-truck-moving:after, .fa-duotone.fa-truck-moving:after {
  content: "\f4df\fe02"; }

.fad.fa-truck-pickup:before, .fa-duotone.fa-truck-pickup:before {
  content: "\f63c"; }

.fad.fa-truck-pickup:after, .fa-duotone.fa-truck-pickup:after {
  content: "\10f63c"; }

.fad.fa-truck-plow:before, .fa-duotone.fa-truck-plow:before {
  content: "\f7de\fe01"; }

.fad.fa-truck-plow:after, .fa-duotone.fa-truck-plow:after {
  content: "\f7de\fe02"; }

.fad.fa-truck-ramp:before, .fa-duotone.fa-truck-ramp:before {
  content: "\f4e0\fe01"; }

.fad.fa-truck-ramp:after, .fa-duotone.fa-truck-ramp:after {
  content: "\f4e0\fe02"; }

.fad.fa-truck-ramp-box:before, .fa-duotone.fa-truck-ramp-box:before {
  content: "\f4de\fe01"; }

.fad.fa-truck-ramp-box:after, .fa-duotone.fa-truck-ramp-box:after {
  content: "\f4de\fe02"; }

.fad.fa-truck-loading:before, .fa-duotone.fa-truck-loading:before {
  content: "\f4de\fe01"; }

.fad.fa-truck-loading:after, .fa-duotone.fa-truck-loading:after {
  content: "\f4de\fe02"; }

.fad.fa-truck-ramp-couch:before, .fa-duotone.fa-truck-ramp-couch:before {
  content: "\f4dd\fe01"; }

.fad.fa-truck-ramp-couch:after, .fa-duotone.fa-truck-ramp-couch:after {
  content: "\f4dd\fe02"; }

.fad.fa-truck-couch:before, .fa-duotone.fa-truck-couch:before {
  content: "\f4dd\fe01"; }

.fad.fa-truck-couch:after, .fa-duotone.fa-truck-couch:after {
  content: "\f4dd\fe02"; }

.fad.fa-truck-tow:before, .fa-duotone.fa-truck-tow:before {
  content: "\e2b8\fe01"; }

.fad.fa-truck-tow:after, .fa-duotone.fa-truck-tow:after {
  content: "\e2b8\fe02"; }

.fad.fa-trumpet:before, .fa-duotone.fa-trumpet:before {
  content: "\f8e3"; }

.fad.fa-trumpet:after, .fa-duotone.fa-trumpet:after {
  content: "\10f8e3"; }

.fad.fa-tty:before, .fa-duotone.fa-tty:before {
  content: "\f1e4\fe01"; }

.fad.fa-tty:after, .fa-duotone.fa-tty:after {
  content: "\f1e4\fe02"; }

.fad.fa-teletype:before, .fa-duotone.fa-teletype:before {
  content: "\f1e4\fe01"; }

.fad.fa-teletype:after, .fa-duotone.fa-teletype:after {
  content: "\f1e4\fe02"; }

.fad.fa-tty-answer:before, .fa-duotone.fa-tty-answer:before {
  content: "\e2b9\fe01"; }

.fad.fa-tty-answer:after, .fa-duotone.fa-tty-answer:after {
  content: "\e2b9\fe02"; }

.fad.fa-teletype-answer:before, .fa-duotone.fa-teletype-answer:before {
  content: "\e2b9\fe01"; }

.fad.fa-teletype-answer:after, .fa-duotone.fa-teletype-answer:after {
  content: "\e2b9\fe02"; }

.fad.fa-tugrik-sign:before, .fa-duotone.fa-tugrik-sign:before {
  content: "\e2ba\fe01"; }

.fad.fa-tugrik-sign:after, .fa-duotone.fa-tugrik-sign:after {
  content: "\e2ba\fe02"; }

.fad.fa-turkey:before, .fa-duotone.fa-turkey:before {
  content: "\f725\fe01"; }

.fad.fa-turkey:after, .fa-duotone.fa-turkey:after {
  content: "\f725\fe02"; }

.fad.fa-turkish-lira-sign:before, .fa-duotone.fa-turkish-lira-sign:before {
  content: "\e2bb\fe01"; }

.fad.fa-turkish-lira-sign:after, .fa-duotone.fa-turkish-lira-sign:after {
  content: "\e2bb\fe02"; }

.fad.fa-try:before, .fa-duotone.fa-try:before {
  content: "\e2bb\fe01"; }

.fad.fa-try:after, .fa-duotone.fa-try:after {
  content: "\e2bb\fe02"; }

.fad.fa-turkish-lira:before, .fa-duotone.fa-turkish-lira:before {
  content: "\e2bb\fe01"; }

.fad.fa-turkish-lira:after, .fa-duotone.fa-turkish-lira:after {
  content: "\e2bb\fe02"; }

.fad.fa-turn-down:before, .fa-duotone.fa-turn-down:before {
  content: "\2935\fe01"; }

.fad.fa-turn-down:after, .fa-duotone.fa-turn-down:after {
  content: "\2935\fe02"; }

.fad.fa-level-down-alt:before, .fa-duotone.fa-level-down-alt:before {
  content: "\2935\fe01"; }

.fad.fa-level-down-alt:after, .fa-duotone.fa-level-down-alt:after {
  content: "\2935\fe02"; }

.fad.fa-turn-down-left:before, .fa-duotone.fa-turn-down-left:before {
  content: "\e331\fe01"; }

.fad.fa-turn-down-left:after, .fa-duotone.fa-turn-down-left:after {
  content: "\e331\fe02"; }

.fad.fa-turn-up:before, .fa-duotone.fa-turn-up:before {
  content: "\2934\fe01"; }

.fad.fa-turn-up:after, .fa-duotone.fa-turn-up:after {
  content: "\2934\fe02"; }

.fad.fa-level-up-alt:before, .fa-duotone.fa-level-up-alt:before {
  content: "\2934\fe01"; }

.fad.fa-level-up-alt:after, .fa-duotone.fa-level-up-alt:after {
  content: "\2934\fe02"; }

.fad.fa-turntable:before, .fa-duotone.fa-turntable:before {
  content: "\f8e4\fe01"; }

.fad.fa-turntable:after, .fa-duotone.fa-turntable:after {
  content: "\f8e4\fe02"; }

.fad.fa-turtle:before, .fa-duotone.fa-turtle:before {
  content: "\f726"; }

.fad.fa-turtle:after, .fa-duotone.fa-turtle:after {
  content: "\10f726"; }

.fad.fa-tv:before, .fa-duotone.fa-tv:before {
  content: "\f26c\fe01"; }

.fad.fa-tv:after, .fa-duotone.fa-tv:after {
  content: "\f26c\fe02"; }

.fad.fa-television:before, .fa-duotone.fa-television:before {
  content: "\f26c\fe01"; }

.fad.fa-television:after, .fa-duotone.fa-television:after {
  content: "\f26c\fe02"; }

.fad.fa-tv-alt:before, .fa-duotone.fa-tv-alt:before {
  content: "\f26c\fe01"; }

.fad.fa-tv-alt:after, .fa-duotone.fa-tv-alt:after {
  content: "\f26c\fe02"; }

.fad.fa-tv-music:before, .fa-duotone.fa-tv-music:before {
  content: "\f8e6\fe01"; }

.fad.fa-tv-music:after, .fa-duotone.fa-tv-music:after {
  content: "\f8e6\fe02"; }

.fad.fa-tv-retro:before, .fa-duotone.fa-tv-retro:before {
  content: "\f401"; }

.fad.fa-tv-retro:after, .fa-duotone.fa-tv-retro:after {
  content: "\10f401"; }

.fad.fa-typewriter:before, .fa-duotone.fa-typewriter:before {
  content: "\f8e7\fe01"; }

.fad.fa-typewriter:after, .fa-duotone.fa-typewriter:after {
  content: "\f8e7\fe02"; }

.fad.fa-u:before, .fa-duotone.fa-u:before {
  content: "\55\fe01"; }

.fad.fa-u:after, .fa-duotone.fa-u:after {
  content: "\55\fe02"; }

.fad.fa-ufo:before, .fa-duotone.fa-ufo:before {
  content: "\e047"; }

.fad.fa-ufo:after, .fa-duotone.fa-ufo:after {
  content: "\10e047"; }

.fad.fa-ufo-beam:before, .fa-duotone.fa-ufo-beam:before {
  content: "\e048\fe01"; }

.fad.fa-ufo-beam:after, .fa-duotone.fa-ufo-beam:after {
  content: "\e048\fe02"; }

.fad.fa-umbrella:before, .fa-duotone.fa-umbrella:before {
  content: "\f0e9\fe01"; }

.fad.fa-umbrella:after, .fa-duotone.fa-umbrella:after {
  content: "\f0e9\fe02"; }

.fad.fa-umbrella-beach:before, .fa-duotone.fa-umbrella-beach:before {
  content: "\f5ca"; }

.fad.fa-umbrella-beach:after, .fa-duotone.fa-umbrella-beach:after {
  content: "\10f5ca"; }

.fad.fa-umbrella-simple:before, .fa-duotone.fa-umbrella-simple:before {
  content: "\e2bc\fe01"; }

.fad.fa-umbrella-simple:after, .fa-duotone.fa-umbrella-simple:after {
  content: "\e2bc\fe02"; }

.fad.fa-umbrella-alt:before, .fa-duotone.fa-umbrella-alt:before {
  content: "\e2bc\fe01"; }

.fad.fa-umbrella-alt:after, .fa-duotone.fa-umbrella-alt:after {
  content: "\e2bc\fe02"; }

.fad.fa-underline:before, .fa-duotone.fa-underline:before {
  content: "\f0cd\fe01"; }

.fad.fa-underline:after, .fa-duotone.fa-underline:after {
  content: "\f0cd\fe02"; }

.fad.fa-unicorn:before, .fa-duotone.fa-unicorn:before {
  content: "\f727"; }

.fad.fa-unicorn:after, .fa-duotone.fa-unicorn:after {
  content: "\10f727"; }

.fad.fa-uniform-martial-arts:before, .fa-duotone.fa-uniform-martial-arts:before {
  content: "\e3d1\fe01"; }

.fad.fa-uniform-martial-arts:after, .fa-duotone.fa-uniform-martial-arts:after {
  content: "\e3d1\fe02"; }

.fad.fa-union:before, .fa-duotone.fa-union:before {
  content: "\22c3\fe01"; }

.fad.fa-union:after, .fa-duotone.fa-union:after {
  content: "\22c3\fe02"; }

.fad.fa-universal-access:before, .fa-duotone.fa-universal-access:before {
  content: "\f29a\fe01"; }

.fad.fa-universal-access:after, .fa-duotone.fa-universal-access:after {
  content: "\f29a\fe02"; }

.fad.fa-unlock:before, .fa-duotone.fa-unlock:before {
  content: "\f09c"; }

.fad.fa-unlock:after, .fa-duotone.fa-unlock:after {
  content: "\10f09c"; }

.fad.fa-unlock-keyhole:before, .fa-duotone.fa-unlock-keyhole:before {
  content: "\f13e\fe01"; }

.fad.fa-unlock-keyhole:after, .fa-duotone.fa-unlock-keyhole:after {
  content: "\f13e\fe02"; }

.fad.fa-unlock-alt:before, .fa-duotone.fa-unlock-alt:before {
  content: "\f13e\fe01"; }

.fad.fa-unlock-alt:after, .fa-duotone.fa-unlock-alt:after {
  content: "\f13e\fe02"; }

.fad.fa-up:before, .fa-duotone.fa-up:before {
  content: "\f357\fe01"; }

.fad.fa-up:after, .fa-duotone.fa-up:after {
  content: "\f357\fe02"; }

.fad.fa-arrow-alt-up:before, .fa-duotone.fa-arrow-alt-up:before {
  content: "\f357\fe01"; }

.fad.fa-arrow-alt-up:after, .fa-duotone.fa-arrow-alt-up:after {
  content: "\f357\fe02"; }

.fad.fa-up-down:before, .fa-duotone.fa-up-down:before {
  content: "\2195\fe01"; }

.fad.fa-up-down:after, .fa-duotone.fa-up-down:after {
  content: "\2195\fe02"; }

.fad.fa-arrows-alt-v:before, .fa-duotone.fa-arrows-alt-v:before {
  content: "\2195\fe01"; }

.fad.fa-arrows-alt-v:after, .fa-duotone.fa-arrows-alt-v:after {
  content: "\2195\fe02"; }

.fad.fa-up-down-left-right:before, .fa-duotone.fa-up-down-left-right:before {
  content: "\f0b2\fe01"; }

.fad.fa-up-down-left-right:after, .fa-duotone.fa-up-down-left-right:after {
  content: "\f0b2\fe02"; }

.fad.fa-arrows-alt:before, .fa-duotone.fa-arrows-alt:before {
  content: "\f0b2\fe01"; }

.fad.fa-arrows-alt:after, .fa-duotone.fa-arrows-alt:after {
  content: "\f0b2\fe02"; }

.fad.fa-up-from-line:before, .fa-duotone.fa-up-from-line:before {
  content: "\f346\fe01"; }

.fad.fa-up-from-line:after, .fa-duotone.fa-up-from-line:after {
  content: "\f346\fe02"; }

.fad.fa-arrow-alt-from-bottom:before, .fa-duotone.fa-arrow-alt-from-bottom:before {
  content: "\f346\fe01"; }

.fad.fa-arrow-alt-from-bottom:after, .fa-duotone.fa-arrow-alt-from-bottom:after {
  content: "\f346\fe02"; }

.fad.fa-up-left:before, .fa-duotone.fa-up-left:before {
  content: "\e2bd\fe01"; }

.fad.fa-up-left:after, .fa-duotone.fa-up-left:after {
  content: "\e2bd\fe02"; }

.fad.fa-up-long:before, .fa-duotone.fa-up-long:before {
  content: "\f30c\fe01"; }

.fad.fa-up-long:after, .fa-duotone.fa-up-long:after {
  content: "\f30c\fe02"; }

.fad.fa-long-arrow-alt-up:before, .fa-duotone.fa-long-arrow-alt-up:before {
  content: "\f30c\fe01"; }

.fad.fa-long-arrow-alt-up:after, .fa-duotone.fa-long-arrow-alt-up:after {
  content: "\f30c\fe02"; }

.fad.fa-up-right:before, .fa-duotone.fa-up-right:before {
  content: "\e2be\fe01"; }

.fad.fa-up-right:after, .fa-duotone.fa-up-right:after {
  content: "\e2be\fe02"; }

.fad.fa-up-right-and-down-left-from-center:before, .fa-duotone.fa-up-right-and-down-left-from-center:before {
  content: "\f424\fe01"; }

.fad.fa-up-right-and-down-left-from-center:after, .fa-duotone.fa-up-right-and-down-left-from-center:after {
  content: "\f424\fe02"; }

.fad.fa-expand-alt:before, .fa-duotone.fa-expand-alt:before {
  content: "\f424\fe01"; }

.fad.fa-expand-alt:after, .fa-duotone.fa-expand-alt:after {
  content: "\f424\fe02"; }

.fad.fa-up-right-from-square:before, .fa-duotone.fa-up-right-from-square:before {
  content: "\f35d\fe01"; }

.fad.fa-up-right-from-square:after, .fa-duotone.fa-up-right-from-square:after {
  content: "\f35d\fe02"; }

.fad.fa-external-link-alt:before, .fa-duotone.fa-external-link-alt:before {
  content: "\f35d\fe01"; }

.fad.fa-external-link-alt:after, .fa-duotone.fa-external-link-alt:after {
  content: "\f35d\fe02"; }

.fad.fa-up-to-line:before, .fa-duotone.fa-up-to-line:before {
  content: "\f34d\fe01"; }

.fad.fa-up-to-line:after, .fa-duotone.fa-up-to-line:after {
  content: "\f34d\fe02"; }

.fad.fa-arrow-alt-to-top:before, .fa-duotone.fa-arrow-alt-to-top:before {
  content: "\f34d\fe01"; }

.fad.fa-arrow-alt-to-top:after, .fa-duotone.fa-arrow-alt-to-top:after {
  content: "\f34d\fe02"; }

.fad.fa-upload:before, .fa-duotone.fa-upload:before {
  content: "\f093\fe01"; }

.fad.fa-upload:after, .fa-duotone.fa-upload:after {
  content: "\f093\fe02"; }

.fad.fa-usb-drive:before, .fa-duotone.fa-usb-drive:before {
  content: "\f8e9\fe01"; }

.fad.fa-usb-drive:after, .fa-duotone.fa-usb-drive:after {
  content: "\f8e9\fe02"; }

.fad.fa-user:before, .fa-duotone.fa-user:before {
  content: "\f007"; }

.fad.fa-user:after, .fa-duotone.fa-user:after {
  content: "\10f007"; }

.fad.fa-user-alien:before, .fa-duotone.fa-user-alien:before {
  content: "\e04a\fe01"; }

.fad.fa-user-alien:after, .fa-duotone.fa-user-alien:after {
  content: "\e04a\fe02"; }

.fad.fa-user-astronaut:before, .fa-duotone.fa-user-astronaut:before {
  content: "\f4fb\fe01"; }

.fad.fa-user-astronaut:after, .fa-duotone.fa-user-astronaut:after {
  content: "\f4fb\fe02"; }

.fad.fa-user-bounty-hunter:before, .fa-duotone.fa-user-bounty-hunter:before {
  content: "\e2bf\fe01"; }

.fad.fa-user-bounty-hunter:after, .fa-duotone.fa-user-bounty-hunter:after {
  content: "\e2bf\fe02"; }

.fad.fa-user-check:before, .fa-duotone.fa-user-check:before {
  content: "\f4fc\fe01"; }

.fad.fa-user-check:after, .fa-duotone.fa-user-check:after {
  content: "\f4fc\fe02"; }

.fad.fa-user-chef:before, .fa-duotone.fa-user-chef:before {
  content: "\e3d2\fe01"; }

.fad.fa-user-chef:after, .fa-duotone.fa-user-chef:after {
  content: "\e3d2\fe02"; }

.fad.fa-user-clock:before, .fa-duotone.fa-user-clock:before {
  content: "\f4fd\fe01"; }

.fad.fa-user-clock:after, .fa-duotone.fa-user-clock:after {
  content: "\f4fd\fe02"; }

.fad.fa-user-cowboy:before, .fa-duotone.fa-user-cowboy:before {
  content: "\f8ea\fe01"; }

.fad.fa-user-cowboy:after, .fa-duotone.fa-user-cowboy:after {
  content: "\f8ea\fe02"; }

.fad.fa-user-crown:before, .fa-duotone.fa-user-crown:before {
  content: "\f6a4\fe01"; }

.fad.fa-user-crown:after, .fa-duotone.fa-user-crown:after {
  content: "\f6a4\fe02"; }

.fad.fa-user-doctor:before, .fa-duotone.fa-user-doctor:before {
  content: "\f0f0\fe01"; }

.fad.fa-user-doctor:after, .fa-duotone.fa-user-doctor:after {
  content: "\f0f0\fe02"; }

.fad.fa-user-md:before, .fa-duotone.fa-user-md:before {
  content: "\f0f0\fe01"; }

.fad.fa-user-md:after, .fa-duotone.fa-user-md:after {
  content: "\f0f0\fe02"; }

.fad.fa-user-doctor-message:before, .fa-duotone.fa-user-doctor-message:before {
  content: "\f82e\fe01"; }

.fad.fa-user-doctor-message:after, .fa-duotone.fa-user-doctor-message:after {
  content: "\f82e\fe02"; }

.fad.fa-user-md-chat:before, .fa-duotone.fa-user-md-chat:before {
  content: "\f82e\fe01"; }

.fad.fa-user-md-chat:after, .fa-duotone.fa-user-md-chat:after {
  content: "\f82e\fe02"; }

.fad.fa-user-gear:before, .fa-duotone.fa-user-gear:before {
  content: "\f4fe\fe01"; }

.fad.fa-user-gear:after, .fa-duotone.fa-user-gear:after {
  content: "\f4fe\fe02"; }

.fad.fa-user-cog:before, .fa-duotone.fa-user-cog:before {
  content: "\f4fe\fe01"; }

.fad.fa-user-cog:after, .fa-duotone.fa-user-cog:after {
  content: "\f4fe\fe02"; }

.fad.fa-user-graduate:before, .fa-duotone.fa-user-graduate:before {
  content: "\f501\fe01"; }

.fad.fa-user-graduate:after, .fa-duotone.fa-user-graduate:after {
  content: "\f501\fe02"; }

.fad.fa-user-group:before, .fa-duotone.fa-user-group:before {
  content: "\f500"; }

.fad.fa-user-group:after, .fa-duotone.fa-user-group:after {
  content: "\10f500"; }

.fad.fa-user-friends:before, .fa-duotone.fa-user-friends:before {
  content: "\f500"; }

.fad.fa-user-friends:after, .fa-duotone.fa-user-friends:after {
  content: "\10f500"; }

.fad.fa-user-group-crown:before, .fa-duotone.fa-user-group-crown:before {
  content: "\f6a5\fe01"; }

.fad.fa-user-group-crown:after, .fa-duotone.fa-user-group-crown:after {
  content: "\f6a5\fe02"; }

.fad.fa-users-crown:before, .fa-duotone.fa-users-crown:before {
  content: "\f6a5\fe01"; }

.fad.fa-users-crown:after, .fa-duotone.fa-users-crown:after {
  content: "\f6a5\fe02"; }

.fad.fa-user-hair-buns:before, .fa-duotone.fa-user-hair-buns:before {
  content: "\e3d3\fe01"; }

.fad.fa-user-hair-buns:after, .fa-duotone.fa-user-hair-buns:after {
  content: "\e3d3\fe02"; }

.fad.fa-user-headset:before, .fa-duotone.fa-user-headset:before {
  content: "\f82d\fe01"; }

.fad.fa-user-headset:after, .fa-duotone.fa-user-headset:after {
  content: "\f82d\fe02"; }

.fad.fa-user-helmet-safety:before, .fa-duotone.fa-user-helmet-safety:before {
  content: "\f82c"; }

.fad.fa-user-helmet-safety:after, .fa-duotone.fa-user-helmet-safety:after {
  content: "\10f82c"; }

.fad.fa-user-construction:before, .fa-duotone.fa-user-construction:before {
  content: "\f82c"; }

.fad.fa-user-construction:after, .fa-duotone.fa-user-construction:after {
  content: "\10f82c"; }

.fad.fa-user-hard-hat:before, .fa-duotone.fa-user-hard-hat:before {
  content: "\f82c"; }

.fad.fa-user-hard-hat:after, .fa-duotone.fa-user-hard-hat:after {
  content: "\10f82c"; }

.fad.fa-user-injured:before, .fa-duotone.fa-user-injured:before {
  content: "\f728\fe01"; }

.fad.fa-user-injured:after, .fa-duotone.fa-user-injured:after {
  content: "\f728\fe02"; }

.fad.fa-user-large:before, .fa-duotone.fa-user-large:before {
  content: "\f406\fe01"; }

.fad.fa-user-large:after, .fa-duotone.fa-user-large:after {
  content: "\f406\fe02"; }

.fad.fa-user-alt:before, .fa-duotone.fa-user-alt:before {
  content: "\f406\fe01"; }

.fad.fa-user-alt:after, .fa-duotone.fa-user-alt:after {
  content: "\f406\fe02"; }

.fad.fa-user-large-slash:before, .fa-duotone.fa-user-large-slash:before {
  content: "\f4fa\fe01"; }

.fad.fa-user-large-slash:after, .fa-duotone.fa-user-large-slash:after {
  content: "\f4fa\fe02"; }

.fad.fa-user-alt-slash:before, .fa-duotone.fa-user-alt-slash:before {
  content: "\f4fa\fe01"; }

.fad.fa-user-alt-slash:after, .fa-duotone.fa-user-alt-slash:after {
  content: "\f4fa\fe02"; }

.fad.fa-user-lock:before, .fa-duotone.fa-user-lock:before {
  content: "\f502\fe01"; }

.fad.fa-user-lock:after, .fa-duotone.fa-user-lock:after {
  content: "\f502\fe02"; }

.fad.fa-user-minus:before, .fa-duotone.fa-user-minus:before {
  content: "\f503\fe01"; }

.fad.fa-user-minus:after, .fa-duotone.fa-user-minus:after {
  content: "\f503\fe02"; }

.fad.fa-user-music:before, .fa-duotone.fa-user-music:before {
  content: "\f8eb\fe01"; }

.fad.fa-user-music:after, .fa-duotone.fa-user-music:after {
  content: "\f8eb\fe02"; }

.fad.fa-user-ninja:before, .fa-duotone.fa-user-ninja:before {
  content: "\f504"; }

.fad.fa-user-ninja:after, .fa-duotone.fa-user-ninja:after {
  content: "\10f504"; }

.fad.fa-user-nurse:before, .fa-duotone.fa-user-nurse:before {
  content: "\f82f\fe01"; }

.fad.fa-user-nurse:after, .fa-duotone.fa-user-nurse:after {
  content: "\f82f\fe02"; }

.fad.fa-user-pen:before, .fa-duotone.fa-user-pen:before {
  content: "\f4ff\fe01"; }

.fad.fa-user-pen:after, .fa-duotone.fa-user-pen:after {
  content: "\f4ff\fe02"; }

.fad.fa-user-edit:before, .fa-duotone.fa-user-edit:before {
  content: "\f4ff\fe01"; }

.fad.fa-user-edit:after, .fa-duotone.fa-user-edit:after {
  content: "\f4ff\fe02"; }

.fad.fa-user-pilot:before, .fa-duotone.fa-user-pilot:before {
  content: "\e2c0\fe01"; }

.fad.fa-user-pilot:after, .fa-duotone.fa-user-pilot:after {
  content: "\e2c0\fe02"; }

.fad.fa-user-pilot-tie:before, .fa-duotone.fa-user-pilot-tie:before {
  content: "\e2c1\fe01"; }

.fad.fa-user-pilot-tie:after, .fa-duotone.fa-user-pilot-tie:after {
  content: "\e2c1\fe02"; }

.fad.fa-user-plus:before, .fa-duotone.fa-user-plus:before {
  content: "\f234\fe01"; }

.fad.fa-user-plus:after, .fa-duotone.fa-user-plus:after {
  content: "\f234\fe02"; }

.fad.fa-user-police:before, .fa-duotone.fa-user-police:before {
  content: "\e333\fe01"; }

.fad.fa-user-police:after, .fa-duotone.fa-user-police:after {
  content: "\e333\fe02"; }

.fad.fa-user-police-tie:before, .fa-duotone.fa-user-police-tie:before {
  content: "\e334\fe01"; }

.fad.fa-user-police-tie:after, .fa-duotone.fa-user-police-tie:after {
  content: "\e334\fe02"; }

.fad.fa-user-robot:before, .fa-duotone.fa-user-robot:before {
  content: "\e04b\fe01"; }

.fad.fa-user-robot:after, .fa-duotone.fa-user-robot:after {
  content: "\e04b\fe02"; }

.fad.fa-user-secret:before, .fa-duotone.fa-user-secret:before {
  content: "\f21b"; }

.fad.fa-user-secret:after, .fa-duotone.fa-user-secret:after {
  content: "\10f21b"; }

.fad.fa-user-shakespeare:before, .fa-duotone.fa-user-shakespeare:before {
  content: "\e2c2\fe01"; }

.fad.fa-user-shakespeare:after, .fa-duotone.fa-user-shakespeare:after {
  content: "\e2c2\fe02"; }

.fad.fa-user-shield:before, .fa-duotone.fa-user-shield:before {
  content: "\f505\fe01"; }

.fad.fa-user-shield:after, .fa-duotone.fa-user-shield:after {
  content: "\f505\fe02"; }

.fad.fa-user-slash:before, .fa-duotone.fa-user-slash:before {
  content: "\f506\fe01"; }

.fad.fa-user-slash:after, .fa-duotone.fa-user-slash:after {
  content: "\f506\fe02"; }

.fad.fa-user-tag:before, .fa-duotone.fa-user-tag:before {
  content: "\f507\fe01"; }

.fad.fa-user-tag:after, .fa-duotone.fa-user-tag:after {
  content: "\f507\fe02"; }

.fad.fa-user-tie:before, .fa-duotone.fa-user-tie:before {
  content: "\f508\fe01"; }

.fad.fa-user-tie:after, .fa-duotone.fa-user-tie:after {
  content: "\f508\fe02"; }

.fad.fa-user-unlock:before, .fa-duotone.fa-user-unlock:before {
  content: "\e058\fe01"; }

.fad.fa-user-unlock:after, .fa-duotone.fa-user-unlock:after {
  content: "\e058\fe02"; }

.fad.fa-user-visor:before, .fa-duotone.fa-user-visor:before {
  content: "\e04c\fe01"; }

.fad.fa-user-visor:after, .fa-duotone.fa-user-visor:after {
  content: "\e04c\fe02"; }

.fad.fa-user-xmark:before, .fa-duotone.fa-user-xmark:before {
  content: "\f235\fe01"; }

.fad.fa-user-xmark:after, .fa-duotone.fa-user-xmark:after {
  content: "\f235\fe02"; }

.fad.fa-user-times:before, .fa-duotone.fa-user-times:before {
  content: "\f235\fe01"; }

.fad.fa-user-times:after, .fa-duotone.fa-user-times:after {
  content: "\f235\fe02"; }

.fad.fa-users:before, .fa-duotone.fa-users:before {
  content: "\f0c0\fe01"; }

.fad.fa-users:after, .fa-duotone.fa-users:after {
  content: "\f0c0\fe02"; }

.fad.fa-group:before, .fa-duotone.fa-group:before {
  content: "\f0c0\fe01"; }

.fad.fa-group:after, .fa-duotone.fa-group:after {
  content: "\f0c0\fe02"; }

.fad.fa-users-gear:before, .fa-duotone.fa-users-gear:before {
  content: "\f509\fe01"; }

.fad.fa-users-gear:after, .fa-duotone.fa-users-gear:after {
  content: "\f509\fe02"; }

.fad.fa-users-cog:before, .fa-duotone.fa-users-cog:before {
  content: "\f509\fe01"; }

.fad.fa-users-cog:after, .fa-duotone.fa-users-cog:after {
  content: "\f509\fe02"; }

.fad.fa-users-medical:before, .fa-duotone.fa-users-medical:before {
  content: "\f830\fe01"; }

.fad.fa-users-medical:after, .fa-duotone.fa-users-medical:after {
  content: "\f830\fe02"; }

.fad.fa-users-slash:before, .fa-duotone.fa-users-slash:before {
  content: "\e073\fe01"; }

.fad.fa-users-slash:after, .fa-duotone.fa-users-slash:after {
  content: "\e073\fe02"; }

.fad.fa-utensils:before, .fa-duotone.fa-utensils:before {
  content: "\f2e7"; }

.fad.fa-utensils:after, .fa-duotone.fa-utensils:after {
  content: "\10f2e7"; }

.fad.fa-cutlery:before, .fa-duotone.fa-cutlery:before {
  content: "\f2e7"; }

.fad.fa-cutlery:after, .fa-duotone.fa-cutlery:after {
  content: "\10f2e7"; }

.fad.fa-utility-pole:before, .fa-duotone.fa-utility-pole:before {
  content: "\e2c3\fe01"; }

.fad.fa-utility-pole:after, .fa-duotone.fa-utility-pole:after {
  content: "\e2c3\fe02"; }

.fad.fa-utility-pole-double:before, .fa-duotone.fa-utility-pole-double:before {
  content: "\e2c4\fe01"; }

.fad.fa-utility-pole-double:after, .fa-duotone.fa-utility-pole-double:after {
  content: "\e2c4\fe02"; }

.fad.fa-v:before, .fa-duotone.fa-v:before {
  content: "\56\fe01"; }

.fad.fa-v:after, .fa-duotone.fa-v:after {
  content: "\56\fe02"; }

.fad.fa-vacuum:before, .fa-duotone.fa-vacuum:before {
  content: "\e04d\fe01"; }

.fad.fa-vacuum:after, .fa-duotone.fa-vacuum:after {
  content: "\e04d\fe02"; }

.fad.fa-vacuum-robot:before, .fa-duotone.fa-vacuum-robot:before {
  content: "\e04e\fe01"; }

.fad.fa-vacuum-robot:after, .fa-duotone.fa-vacuum-robot:after {
  content: "\e04e\fe02"; }

.fad.fa-value-absolute:before, .fa-duotone.fa-value-absolute:before {
  content: "\f6a6\fe01"; }

.fad.fa-value-absolute:after, .fa-duotone.fa-value-absolute:after {
  content: "\f6a6\fe02"; }

.fad.fa-van-shuttle:before, .fa-duotone.fa-van-shuttle:before {
  content: "\f5b6"; }

.fad.fa-van-shuttle:after, .fa-duotone.fa-van-shuttle:after {
  content: "\10f5b6"; }

.fad.fa-shuttle-van:before, .fa-duotone.fa-shuttle-van:before {
  content: "\f5b6"; }

.fad.fa-shuttle-van:after, .fa-duotone.fa-shuttle-van:after {
  content: "\10f5b6"; }

.fad.fa-vault:before, .fa-duotone.fa-vault:before {
  content: "\e2c5\fe01"; }

.fad.fa-vault:after, .fa-duotone.fa-vault:after {
  content: "\e2c5\fe02"; }

.fad.fa-vector-circle:before, .fa-duotone.fa-vector-circle:before {
  content: "\e2c6\fe01"; }

.fad.fa-vector-circle:after, .fa-duotone.fa-vector-circle:after {
  content: "\e2c6\fe02"; }

.fad.fa-vector-polygon:before, .fa-duotone.fa-vector-polygon:before {
  content: "\e2c7\fe01"; }

.fad.fa-vector-polygon:after, .fa-duotone.fa-vector-polygon:after {
  content: "\e2c7\fe02"; }

.fad.fa-vector-square:before, .fa-duotone.fa-vector-square:before {
  content: "\f5cb\fe01"; }

.fad.fa-vector-square:after, .fa-duotone.fa-vector-square:after {
  content: "\f5cb\fe02"; }

.fad.fa-venus:before, .fa-duotone.fa-venus:before {
  content: "\2640\fe01"; }

.fad.fa-venus:after, .fa-duotone.fa-venus:after {
  content: "\2640\fe02"; }

.fad.fa-venus-double:before, .fa-duotone.fa-venus-double:before {
  content: "\26a2\fe01"; }

.fad.fa-venus-double:after, .fa-duotone.fa-venus-double:after {
  content: "\26a2\fe02"; }

.fad.fa-venus-mars:before, .fa-duotone.fa-venus-mars:before {
  content: "\26a4\fe01"; }

.fad.fa-venus-mars:after, .fa-duotone.fa-venus-mars:after {
  content: "\26a4\fe02"; }

.fad.fa-vest:before, .fa-duotone.fa-vest:before {
  content: "\e085\fe01"; }

.fad.fa-vest:after, .fa-duotone.fa-vest:after {
  content: "\e085\fe02"; }

.fad.fa-vest-patches:before, .fa-duotone.fa-vest-patches:before {
  content: "\e086\fe01"; }

.fad.fa-vest-patches:after, .fa-duotone.fa-vest-patches:after {
  content: "\e086\fe02"; }

.fad.fa-vial:before, .fa-duotone.fa-vial:before {
  content: "\f492"; }

.fad.fa-vial:after, .fa-duotone.fa-vial:after {
  content: "\10f492"; }

.fad.fa-vials:before, .fa-duotone.fa-vials:before {
  content: "\f493\fe01"; }

.fad.fa-vials:after, .fa-duotone.fa-vials:after {
  content: "\f493\fe02"; }

.fad.fa-video:before, .fa-duotone.fa-video:before {
  content: "\f03d\fe01"; }

.fad.fa-video:after, .fa-duotone.fa-video:after {
  content: "\f03d\fe02"; }

.fad.fa-video-camera:before, .fa-duotone.fa-video-camera:before {
  content: "\f03d\fe01"; }

.fad.fa-video-camera:after, .fa-duotone.fa-video-camera:after {
  content: "\f03d\fe02"; }

.fad.fa-video-arrow-down-left:before, .fa-duotone.fa-video-arrow-down-left:before {
  content: "\e2c8\fe01"; }

.fad.fa-video-arrow-down-left:after, .fa-duotone.fa-video-arrow-down-left:after {
  content: "\e2c8\fe02"; }

.fad.fa-video-arrow-up-right:before, .fa-duotone.fa-video-arrow-up-right:before {
  content: "\e2c9\fe01"; }

.fad.fa-video-arrow-up-right:after, .fa-duotone.fa-video-arrow-up-right:after {
  content: "\e2c9\fe02"; }

.fad.fa-video-plus:before, .fa-duotone.fa-video-plus:before {
  content: "\f4e1\fe01"; }

.fad.fa-video-plus:after, .fa-duotone.fa-video-plus:after {
  content: "\f4e1\fe02"; }

.fad.fa-video-slash:before, .fa-duotone.fa-video-slash:before {
  content: "\f4e2\fe01"; }

.fad.fa-video-slash:after, .fa-duotone.fa-video-slash:after {
  content: "\f4e2\fe02"; }

.fad.fa-vihara:before, .fa-duotone.fa-vihara:before {
  content: "\f6a7\fe01"; }

.fad.fa-vihara:after, .fa-duotone.fa-vihara:after {
  content: "\f6a7\fe02"; }

.fad.fa-violin:before, .fa-duotone.fa-violin:before {
  content: "\f8ed"; }

.fad.fa-violin:after, .fa-duotone.fa-violin:after {
  content: "\10f8ed"; }

.fad.fa-virus:before, .fa-duotone.fa-virus:before {
  content: "\e074\fe01"; }

.fad.fa-virus:after, .fa-duotone.fa-virus:after {
  content: "\e074\fe02"; }

.fad.fa-virus-slash:before, .fa-duotone.fa-virus-slash:before {
  content: "\e075\fe01"; }

.fad.fa-virus-slash:after, .fa-duotone.fa-virus-slash:after {
  content: "\e075\fe02"; }

.fad.fa-viruses:before, .fa-duotone.fa-viruses:before {
  content: "\e076\fe01"; }

.fad.fa-viruses:after, .fa-duotone.fa-viruses:after {
  content: "\e076\fe02"; }

.fad.fa-voicemail:before, .fa-duotone.fa-voicemail:before {
  content: "\f897\fe01"; }

.fad.fa-voicemail:after, .fa-duotone.fa-voicemail:after {
  content: "\f897\fe02"; }

.fad.fa-volcano:before, .fa-duotone.fa-volcano:before {
  content: "\f770"; }

.fad.fa-volcano:after, .fa-duotone.fa-volcano:after {
  content: "\10f770"; }

.fad.fa-volleyball:before, .fa-duotone.fa-volleyball:before {
  content: "\f45f"; }

.fad.fa-volleyball:after, .fa-duotone.fa-volleyball:after {
  content: "\10f45f"; }

.fad.fa-volleyball-ball:before, .fa-duotone.fa-volleyball-ball:before {
  content: "\f45f"; }

.fad.fa-volleyball-ball:after, .fa-duotone.fa-volleyball-ball:after {
  content: "\10f45f"; }

.fad.fa-volume:before, .fa-duotone.fa-volume:before {
  content: "\f6a8"; }

.fad.fa-volume:after, .fa-duotone.fa-volume:after {
  content: "\10f6a8"; }

.fad.fa-volume-medium:before, .fa-duotone.fa-volume-medium:before {
  content: "\f6a8"; }

.fad.fa-volume-medium:after, .fa-duotone.fa-volume-medium:after {
  content: "\10f6a8"; }

.fad.fa-volume-high:before, .fa-duotone.fa-volume-high:before {
  content: "\f028"; }

.fad.fa-volume-high:after, .fa-duotone.fa-volume-high:after {
  content: "\10f028"; }

.fad.fa-volume-up:before, .fa-duotone.fa-volume-up:before {
  content: "\f028"; }

.fad.fa-volume-up:after, .fa-duotone.fa-volume-up:after {
  content: "\10f028"; }

.fad.fa-volume-low:before, .fa-duotone.fa-volume-low:before {
  content: "\f027"; }

.fad.fa-volume-low:after, .fa-duotone.fa-volume-low:after {
  content: "\10f027"; }

.fad.fa-volume-down:before, .fa-duotone.fa-volume-down:before {
  content: "\f027"; }

.fad.fa-volume-down:after, .fa-duotone.fa-volume-down:after {
  content: "\10f027"; }

.fad.fa-volume-off:before, .fa-duotone.fa-volume-off:before {
  content: "\f026\fe01"; }

.fad.fa-volume-off:after, .fa-duotone.fa-volume-off:after {
  content: "\f026\fe02"; }

.fad.fa-volume-slash:before, .fa-duotone.fa-volume-slash:before {
  content: "\f2e2"; }

.fad.fa-volume-slash:after, .fa-duotone.fa-volume-slash:after {
  content: "\10f2e2"; }

.fad.fa-volume-xmark:before, .fa-duotone.fa-volume-xmark:before {
  content: "\f6a9\fe01"; }

.fad.fa-volume-xmark:after, .fa-duotone.fa-volume-xmark:after {
  content: "\f6a9\fe02"; }

.fad.fa-volume-mute:before, .fa-duotone.fa-volume-mute:before {
  content: "\f6a9\fe01"; }

.fad.fa-volume-mute:after, .fa-duotone.fa-volume-mute:after {
  content: "\f6a9\fe02"; }

.fad.fa-volume-times:before, .fa-duotone.fa-volume-times:before {
  content: "\f6a9\fe01"; }

.fad.fa-volume-times:after, .fa-duotone.fa-volume-times:after {
  content: "\f6a9\fe02"; }

.fad.fa-vr-cardboard:before, .fa-duotone.fa-vr-cardboard:before {
  content: "\f729\fe01"; }

.fad.fa-vr-cardboard:after, .fa-duotone.fa-vr-cardboard:after {
  content: "\f729\fe02"; }

.fad.fa-w:before, .fa-duotone.fa-w:before {
  content: "\57\fe01"; }

.fad.fa-w:after, .fa-duotone.fa-w:after {
  content: "\57\fe02"; }

.fad.fa-wagon-covered:before, .fa-duotone.fa-wagon-covered:before {
  content: "\f8ee\fe01"; }

.fad.fa-wagon-covered:after, .fa-duotone.fa-wagon-covered:after {
  content: "\f8ee\fe02"; }

.fad.fa-walker:before, .fa-duotone.fa-walker:before {
  content: "\f831\fe01"; }

.fad.fa-walker:after, .fa-duotone.fa-walker:after {
  content: "\f831\fe02"; }

.fad.fa-walkie-talkie:before, .fa-duotone.fa-walkie-talkie:before {
  content: "\f8ef\fe01"; }

.fad.fa-walkie-talkie:after, .fa-duotone.fa-walkie-talkie:after {
  content: "\f8ef\fe02"; }

.fad.fa-wallet:before, .fa-duotone.fa-wallet:before {
  content: "\f555\fe01"; }

.fad.fa-wallet:after, .fa-duotone.fa-wallet:after {
  content: "\f555\fe02"; }

.fad.fa-wand:before, .fa-duotone.fa-wand:before {
  content: "\f72a\fe01"; }

.fad.fa-wand:after, .fa-duotone.fa-wand:after {
  content: "\f72a\fe02"; }

.fad.fa-wand-magic:before, .fa-duotone.fa-wand-magic:before {
  content: "\f0d0\fe01"; }

.fad.fa-wand-magic:after, .fa-duotone.fa-wand-magic:after {
  content: "\f0d0\fe02"; }

.fad.fa-magic:before, .fa-duotone.fa-magic:before {
  content: "\f0d0\fe01"; }

.fad.fa-magic:after, .fa-duotone.fa-magic:after {
  content: "\f0d0\fe02"; }

.fad.fa-wand-magic-sparkles:before, .fa-duotone.fa-wand-magic-sparkles:before {
  content: "\e2ca\fe01"; }

.fad.fa-wand-magic-sparkles:after, .fa-duotone.fa-wand-magic-sparkles:after {
  content: "\e2ca\fe02"; }

.fad.fa-magic-wand-sparkles:before, .fa-duotone.fa-magic-wand-sparkles:before {
  content: "\e2ca\fe01"; }

.fad.fa-magic-wand-sparkles:after, .fa-duotone.fa-magic-wand-sparkles:after {
  content: "\e2ca\fe02"; }

.fad.fa-wand-sparkles:before, .fa-duotone.fa-wand-sparkles:before {
  content: "\f72b\fe01"; }

.fad.fa-wand-sparkles:after, .fa-duotone.fa-wand-sparkles:after {
  content: "\f72b\fe02"; }

.fad.fa-warehouse:before, .fa-duotone.fa-warehouse:before {
  content: "\f494\fe01"; }

.fad.fa-warehouse:after, .fa-duotone.fa-warehouse:after {
  content: "\f494\fe02"; }

.fad.fa-warehouse-full:before, .fa-duotone.fa-warehouse-full:before {
  content: "\f495\fe01"; }

.fad.fa-warehouse-full:after, .fa-duotone.fa-warehouse-full:after {
  content: "\f495\fe02"; }

.fad.fa-warehouse-alt:before, .fa-duotone.fa-warehouse-alt:before {
  content: "\f495\fe01"; }

.fad.fa-warehouse-alt:after, .fa-duotone.fa-warehouse-alt:after {
  content: "\f495\fe02"; }

.fad.fa-washing-machine:before, .fa-duotone.fa-washing-machine:before {
  content: "\f898\fe01"; }

.fad.fa-washing-machine:after, .fa-duotone.fa-washing-machine:after {
  content: "\f898\fe02"; }

.fad.fa-washer:before, .fa-duotone.fa-washer:before {
  content: "\f898\fe01"; }

.fad.fa-washer:after, .fa-duotone.fa-washer:after {
  content: "\f898\fe02"; }

.fad.fa-watch:before, .fa-duotone.fa-watch:before {
  content: "\231a\fe01"; }

.fad.fa-watch:after, .fa-duotone.fa-watch:after {
  content: "\231a\fe02"; }

.fad.fa-watch-apple:before, .fa-duotone.fa-watch-apple:before {
  content: "\e2cb\fe01"; }

.fad.fa-watch-apple:after, .fa-duotone.fa-watch-apple:after {
  content: "\e2cb\fe02"; }

.fad.fa-watch-calculator:before, .fa-duotone.fa-watch-calculator:before {
  content: "\f8f0\fe01"; }

.fad.fa-watch-calculator:after, .fa-duotone.fa-watch-calculator:after {
  content: "\f8f0\fe02"; }

.fad.fa-watch-fitness:before, .fa-duotone.fa-watch-fitness:before {
  content: "\f63e\fe01"; }

.fad.fa-watch-fitness:after, .fa-duotone.fa-watch-fitness:after {
  content: "\f63e\fe02"; }

.fad.fa-watch-smart:before, .fa-duotone.fa-watch-smart:before {
  content: "\e2cc\fe01"; }

.fad.fa-watch-smart:after, .fa-duotone.fa-watch-smart:after {
  content: "\e2cc\fe02"; }

.fad.fa-water:before, .fa-duotone.fa-water:before {
  content: "\f773\fe01"; }

.fad.fa-water:after, .fa-duotone.fa-water:after {
  content: "\f773\fe02"; }

.fad.fa-water-arrow-down:before, .fa-duotone.fa-water-arrow-down:before {
  content: "\f774\fe01"; }

.fad.fa-water-arrow-down:after, .fa-duotone.fa-water-arrow-down:after {
  content: "\f774\fe02"; }

.fad.fa-water-lower:before, .fa-duotone.fa-water-lower:before {
  content: "\f774\fe01"; }

.fad.fa-water-lower:after, .fa-duotone.fa-water-lower:after {
  content: "\f774\fe02"; }

.fad.fa-water-arrow-up:before, .fa-duotone.fa-water-arrow-up:before {
  content: "\f775\fe01"; }

.fad.fa-water-arrow-up:after, .fa-duotone.fa-water-arrow-up:after {
  content: "\f775\fe02"; }

.fad.fa-water-rise:before, .fa-duotone.fa-water-rise:before {
  content: "\f775\fe01"; }

.fad.fa-water-rise:after, .fa-duotone.fa-water-rise:after {
  content: "\f775\fe02"; }

.fad.fa-water-ladder:before, .fa-duotone.fa-water-ladder:before {
  content: "\f5c5\fe01"; }

.fad.fa-water-ladder:after, .fa-duotone.fa-water-ladder:after {
  content: "\f5c5\fe02"; }

.fad.fa-ladder-water:before, .fa-duotone.fa-ladder-water:before {
  content: "\f5c5\fe01"; }

.fad.fa-ladder-water:after, .fa-duotone.fa-ladder-water:after {
  content: "\f5c5\fe02"; }

.fad.fa-swimming-pool:before, .fa-duotone.fa-swimming-pool:before {
  content: "\f5c5\fe01"; }

.fad.fa-swimming-pool:after, .fa-duotone.fa-swimming-pool:after {
  content: "\f5c5\fe02"; }

.fad.fa-watermelon-slice:before, .fa-duotone.fa-watermelon-slice:before {
  content: "\e337\fe01"; }

.fad.fa-watermelon-slice:after, .fa-duotone.fa-watermelon-slice:after {
  content: "\e337\fe02"; }

.fad.fa-wave-pulse:before, .fa-duotone.fa-wave-pulse:before {
  content: "\f5f8\fe01"; }

.fad.fa-wave-pulse:after, .fa-duotone.fa-wave-pulse:after {
  content: "\f5f8\fe02"; }

.fad.fa-heart-rate:before, .fa-duotone.fa-heart-rate:before {
  content: "\f5f8\fe01"; }

.fad.fa-heart-rate:after, .fa-duotone.fa-heart-rate:after {
  content: "\f5f8\fe02"; }

.fad.fa-wave-sine:before, .fa-duotone.fa-wave-sine:before {
  content: "\223f\fe01"; }

.fad.fa-wave-sine:after, .fa-duotone.fa-wave-sine:after {
  content: "\223f\fe02"; }

.fad.fa-wave-square:before, .fa-duotone.fa-wave-square:before {
  content: "\f83e\fe01"; }

.fad.fa-wave-square:after, .fa-duotone.fa-wave-square:after {
  content: "\f83e\fe02"; }

.fad.fa-wave-triangle:before, .fa-duotone.fa-wave-triangle:before {
  content: "\f89a\fe01"; }

.fad.fa-wave-triangle:after, .fa-duotone.fa-wave-triangle:after {
  content: "\f89a\fe02"; }

.fad.fa-waveform:before, .fa-duotone.fa-waveform:before {
  content: "\f8f1\fe01"; }

.fad.fa-waveform:after, .fa-duotone.fa-waveform:after {
  content: "\f8f1\fe02"; }

.fad.fa-waveform-lines:before, .fa-duotone.fa-waveform-lines:before {
  content: "\f8f2\fe01"; }

.fad.fa-waveform-lines:after, .fa-duotone.fa-waveform-lines:after {
  content: "\f8f2\fe02"; }

.fad.fa-weight-hanging:before, .fa-duotone.fa-weight-hanging:before {
  content: "\f5cd\fe01"; }

.fad.fa-weight-hanging:after, .fa-duotone.fa-weight-hanging:after {
  content: "\f5cd\fe02"; }

.fad.fa-weight-scale:before, .fa-duotone.fa-weight-scale:before {
  content: "\f496\fe01"; }

.fad.fa-weight-scale:after, .fa-duotone.fa-weight-scale:after {
  content: "\f496\fe02"; }

.fad.fa-weight:before, .fa-duotone.fa-weight:before {
  content: "\f496\fe01"; }

.fad.fa-weight:after, .fa-duotone.fa-weight:after {
  content: "\f496\fe02"; }

.fad.fa-whale:before, .fa-duotone.fa-whale:before {
  content: "\f72c"; }

.fad.fa-whale:after, .fa-duotone.fa-whale:after {
  content: "\10f72c"; }

.fad.fa-wheat:before, .fa-duotone.fa-wheat:before {
  content: "\f72d\fe01"; }

.fad.fa-wheat:after, .fa-duotone.fa-wheat:after {
  content: "\f72d\fe02"; }

.fad.fa-wheat-awn:before, .fa-duotone.fa-wheat-awn:before {
  content: "\e2cd\fe01"; }

.fad.fa-wheat-awn:after, .fa-duotone.fa-wheat-awn:after {
  content: "\e2cd\fe02"; }

.fad.fa-wheat-alt:before, .fa-duotone.fa-wheat-alt:before {
  content: "\e2cd\fe01"; }

.fad.fa-wheat-alt:after, .fa-duotone.fa-wheat-alt:after {
  content: "\e2cd\fe02"; }

.fad.fa-wheat-awn-slash:before, .fa-duotone.fa-wheat-awn-slash:before {
  content: "\e338\fe01"; }

.fad.fa-wheat-awn-slash:after, .fa-duotone.fa-wheat-awn-slash:after {
  content: "\e338\fe02"; }

.fad.fa-wheat-slash:before, .fa-duotone.fa-wheat-slash:before {
  content: "\e339\fe01"; }

.fad.fa-wheat-slash:after, .fa-duotone.fa-wheat-slash:after {
  content: "\e339\fe02"; }

.fad.fa-wheelchair:before, .fa-duotone.fa-wheelchair:before {
  content: "\f193\fe01"; }

.fad.fa-wheelchair:after, .fa-duotone.fa-wheelchair:after {
  content: "\f193\fe02"; }

.fad.fa-wheelchair-move:before, .fa-duotone.fa-wheelchair-move:before {
  content: "\e2ce\fe01"; }

.fad.fa-wheelchair-move:after, .fa-duotone.fa-wheelchair-move:after {
  content: "\e2ce\fe02"; }

.fad.fa-wheelchair-alt:before, .fa-duotone.fa-wheelchair-alt:before {
  content: "\e2ce\fe01"; }

.fad.fa-wheelchair-alt:after, .fa-duotone.fa-wheelchair-alt:after {
  content: "\e2ce\fe02"; }

.fad.fa-whiskey-glass:before, .fa-duotone.fa-whiskey-glass:before {
  content: "\f7a0"; }

.fad.fa-whiskey-glass:after, .fa-duotone.fa-whiskey-glass:after {
  content: "\10f7a0"; }

.fad.fa-glass-whiskey:before, .fa-duotone.fa-glass-whiskey:before {
  content: "\f7a0"; }

.fad.fa-glass-whiskey:after, .fa-duotone.fa-glass-whiskey:after {
  content: "\10f7a0"; }

.fad.fa-whiskey-glass-ice:before, .fa-duotone.fa-whiskey-glass-ice:before {
  content: "\f7a1\fe01"; }

.fad.fa-whiskey-glass-ice:after, .fa-duotone.fa-whiskey-glass-ice:after {
  content: "\f7a1\fe02"; }

.fad.fa-glass-whiskey-rocks:before, .fa-duotone.fa-glass-whiskey-rocks:before {
  content: "\f7a1\fe01"; }

.fad.fa-glass-whiskey-rocks:after, .fa-duotone.fa-glass-whiskey-rocks:after {
  content: "\f7a1\fe02"; }

.fad.fa-whistle:before, .fa-duotone.fa-whistle:before {
  content: "\f460\fe01"; }

.fad.fa-whistle:after, .fa-duotone.fa-whistle:after {
  content: "\f460\fe02"; }

.fad.fa-wifi:before, .fa-duotone.fa-wifi:before {
  content: "\f1eb\fe01"; }

.fad.fa-wifi:after, .fa-duotone.fa-wifi:after {
  content: "\f1eb\fe02"; }

.fad.fa-wifi-3:before, .fa-duotone.fa-wifi-3:before {
  content: "\f1eb\fe01"; }

.fad.fa-wifi-3:after, .fa-duotone.fa-wifi-3:after {
  content: "\f1eb\fe02"; }

.fad.fa-wifi-strong:before, .fa-duotone.fa-wifi-strong:before {
  content: "\f1eb\fe01"; }

.fad.fa-wifi-strong:after, .fa-duotone.fa-wifi-strong:after {
  content: "\f1eb\fe02"; }

.fad.fa-wifi-exclamation:before, .fa-duotone.fa-wifi-exclamation:before {
  content: "\e2cf\fe01"; }

.fad.fa-wifi-exclamation:after, .fa-duotone.fa-wifi-exclamation:after {
  content: "\e2cf\fe02"; }

.fad.fa-wifi-fair:before, .fa-duotone.fa-wifi-fair:before {
  content: "\f6ab\fe01"; }

.fad.fa-wifi-fair:after, .fa-duotone.fa-wifi-fair:after {
  content: "\f6ab\fe02"; }

.fad.fa-wifi-2:before, .fa-duotone.fa-wifi-2:before {
  content: "\f6ab\fe01"; }

.fad.fa-wifi-2:after, .fa-duotone.fa-wifi-2:after {
  content: "\f6ab\fe02"; }

.fad.fa-wifi-slash:before, .fa-duotone.fa-wifi-slash:before {
  content: "\f6ac\fe01"; }

.fad.fa-wifi-slash:after, .fa-duotone.fa-wifi-slash:after {
  content: "\f6ac\fe02"; }

.fad.fa-wifi-weak:before, .fa-duotone.fa-wifi-weak:before {
  content: "\f6aa\fe01"; }

.fad.fa-wifi-weak:after, .fa-duotone.fa-wifi-weak:after {
  content: "\f6aa\fe02"; }

.fad.fa-wifi-1:before, .fa-duotone.fa-wifi-1:before {
  content: "\f6aa\fe01"; }

.fad.fa-wifi-1:after, .fa-duotone.fa-wifi-1:after {
  content: "\f6aa\fe02"; }

.fad.fa-wind:before, .fa-duotone.fa-wind:before {
  content: "\f72e\fe01"; }

.fad.fa-wind:after, .fa-duotone.fa-wind:after {
  content: "\f72e\fe02"; }

.fad.fa-wind-turbine:before, .fa-duotone.fa-wind-turbine:before {
  content: "\f89b\fe01"; }

.fad.fa-wind-turbine:after, .fa-duotone.fa-wind-turbine:after {
  content: "\f89b\fe02"; }

.fad.fa-wind-warning:before, .fa-duotone.fa-wind-warning:before {
  content: "\f776\fe01"; }

.fad.fa-wind-warning:after, .fa-duotone.fa-wind-warning:after {
  content: "\f776\fe02"; }

.fad.fa-wind-circle-exclamation:before, .fa-duotone.fa-wind-circle-exclamation:before {
  content: "\f776\fe01"; }

.fad.fa-wind-circle-exclamation:after, .fa-duotone.fa-wind-circle-exclamation:after {
  content: "\f776\fe02"; }

.fad.fa-window:before, .fa-duotone.fa-window:before {
  content: "\f40e\fe01"; }

.fad.fa-window:after, .fa-duotone.fa-window:after {
  content: "\f40e\fe02"; }

.fad.fa-window-flip:before, .fa-duotone.fa-window-flip:before {
  content: "\f40f\fe01"; }

.fad.fa-window-flip:after, .fa-duotone.fa-window-flip:after {
  content: "\f40f\fe02"; }

.fad.fa-window-alt:before, .fa-duotone.fa-window-alt:before {
  content: "\f40f\fe01"; }

.fad.fa-window-alt:after, .fa-duotone.fa-window-alt:after {
  content: "\f40f\fe02"; }

.fad.fa-window-frame:before, .fa-duotone.fa-window-frame:before {
  content: "\e04f"; }

.fad.fa-window-frame:after, .fa-duotone.fa-window-frame:after {
  content: "\10e04f"; }

.fad.fa-window-frame-open:before, .fa-duotone.fa-window-frame-open:before {
  content: "\e050\fe01"; }

.fad.fa-window-frame-open:after, .fa-duotone.fa-window-frame-open:after {
  content: "\e050\fe02"; }

.fad.fa-window-maximize:before, .fa-duotone.fa-window-maximize:before {
  content: "\f2d0"; }

.fad.fa-window-maximize:after, .fa-duotone.fa-window-maximize:after {
  content: "\10f2d0"; }

.fad.fa-window-minimize:before, .fa-duotone.fa-window-minimize:before {
  content: "\f2d1"; }

.fad.fa-window-minimize:after, .fa-duotone.fa-window-minimize:after {
  content: "\10f2d1"; }

.fad.fa-window-restore:before, .fa-duotone.fa-window-restore:before {
  content: "\f2d2\fe01"; }

.fad.fa-window-restore:after, .fa-duotone.fa-window-restore:after {
  content: "\f2d2\fe02"; }

.fad.fa-windsock:before, .fa-duotone.fa-windsock:before {
  content: "\f777\fe01"; }

.fad.fa-windsock:after, .fa-duotone.fa-windsock:after {
  content: "\f777\fe02"; }

.fad.fa-wine-bottle:before, .fa-duotone.fa-wine-bottle:before {
  content: "\f72f\fe01"; }

.fad.fa-wine-bottle:after, .fa-duotone.fa-wine-bottle:after {
  content: "\f72f\fe02"; }

.fad.fa-wine-glass:before, .fa-duotone.fa-wine-glass:before {
  content: "\f4e3"; }

.fad.fa-wine-glass:after, .fa-duotone.fa-wine-glass:after {
  content: "\10f4e3"; }

.fad.fa-wine-glass-crack:before, .fa-duotone.fa-wine-glass-crack:before {
  content: "\f4bb\fe01"; }

.fad.fa-wine-glass-crack:after, .fa-duotone.fa-wine-glass-crack:after {
  content: "\f4bb\fe02"; }

.fad.fa-fragile:before, .fa-duotone.fa-fragile:before {
  content: "\f4bb\fe01"; }

.fad.fa-fragile:after, .fa-duotone.fa-fragile:after {
  content: "\f4bb\fe02"; }

.fad.fa-wine-glass-empty:before, .fa-duotone.fa-wine-glass-empty:before {
  content: "\f5ce\fe01"; }

.fad.fa-wine-glass-empty:after, .fa-duotone.fa-wine-glass-empty:after {
  content: "\f5ce\fe02"; }

.fad.fa-wine-glass-alt:before, .fa-duotone.fa-wine-glass-alt:before {
  content: "\f5ce\fe01"; }

.fad.fa-wine-glass-alt:after, .fa-duotone.fa-wine-glass-alt:after {
  content: "\f5ce\fe02"; }

.fad.fa-won-sign:before, .fa-duotone.fa-won-sign:before {
  content: "\20a9\fe01"; }

.fad.fa-won-sign:after, .fa-duotone.fa-won-sign:after {
  content: "\20a9\fe02"; }

.fad.fa-krw:before, .fa-duotone.fa-krw:before {
  content: "\20a9\fe01"; }

.fad.fa-krw:after, .fa-duotone.fa-krw:after {
  content: "\20a9\fe02"; }

.fad.fa-won:before, .fa-duotone.fa-won:before {
  content: "\20a9\fe01"; }

.fad.fa-won:after, .fa-duotone.fa-won:after {
  content: "\20a9\fe02"; }

.fad.fa-wreath:before, .fa-duotone.fa-wreath:before {
  content: "\f7e2\fe01"; }

.fad.fa-wreath:after, .fa-duotone.fa-wreath:after {
  content: "\f7e2\fe02"; }

.fad.fa-wrench:before, .fa-duotone.fa-wrench:before {
  content: "\f0ad"; }

.fad.fa-wrench:after, .fa-duotone.fa-wrench:after {
  content: "\10f0ad"; }

.fad.fa-wrench-simple:before, .fa-duotone.fa-wrench-simple:before {
  content: "\e2d1\fe01"; }

.fad.fa-wrench-simple:after, .fa-duotone.fa-wrench-simple:after {
  content: "\e2d1\fe02"; }

.fad.fa-x:before, .fa-duotone.fa-x:before {
  content: "\58\fe01"; }

.fad.fa-x:after, .fa-duotone.fa-x:after {
  content: "\58\fe02"; }

.fad.fa-x-ray:before, .fa-duotone.fa-x-ray:before {
  content: "\f497\fe01"; }

.fad.fa-x-ray:after, .fa-duotone.fa-x-ray:after {
  content: "\f497\fe02"; }

.fad.fa-xmark:before, .fa-duotone.fa-xmark:before {
  content: "\d7\fe01"; }

.fad.fa-xmark:after, .fa-duotone.fa-xmark:after {
  content: "\d7\fe02"; }

.fad.fa-close:before, .fa-duotone.fa-close:before {
  content: "\d7\fe01"; }

.fad.fa-close:after, .fa-duotone.fa-close:after {
  content: "\d7\fe02"; }

.fad.fa-multiply:before, .fa-duotone.fa-multiply:before {
  content: "\d7\fe01"; }

.fad.fa-multiply:after, .fa-duotone.fa-multiply:after {
  content: "\d7\fe02"; }

.fad.fa-remove:before, .fa-duotone.fa-remove:before {
  content: "\d7\fe01"; }

.fad.fa-remove:after, .fa-duotone.fa-remove:after {
  content: "\d7\fe02"; }

.fad.fa-times:before, .fa-duotone.fa-times:before {
  content: "\d7\fe01"; }

.fad.fa-times:after, .fa-duotone.fa-times:after {
  content: "\d7\fe02"; }

.fad.fa-xmark-to-slot:before, .fa-duotone.fa-xmark-to-slot:before {
  content: "\f771\fe01"; }

.fad.fa-xmark-to-slot:after, .fa-duotone.fa-xmark-to-slot:after {
  content: "\f771\fe02"; }

.fad.fa-times-to-slot:before, .fa-duotone.fa-times-to-slot:before {
  content: "\f771\fe01"; }

.fad.fa-times-to-slot:after, .fa-duotone.fa-times-to-slot:after {
  content: "\f771\fe02"; }

.fad.fa-vote-nay:before, .fa-duotone.fa-vote-nay:before {
  content: "\f771\fe01"; }

.fad.fa-vote-nay:after, .fa-duotone.fa-vote-nay:after {
  content: "\f771\fe02"; }

.fad.fa-y:before, .fa-duotone.fa-y:before {
  content: "\59\fe01"; }

.fad.fa-y:after, .fa-duotone.fa-y:after {
  content: "\59\fe02"; }

.fad.fa-yen-sign:before, .fa-duotone.fa-yen-sign:before {
  content: "\a5\fe01"; }

.fad.fa-yen-sign:after, .fa-duotone.fa-yen-sign:after {
  content: "\a5\fe02"; }

.fad.fa-cny:before, .fa-duotone.fa-cny:before {
  content: "\a5\fe01"; }

.fad.fa-cny:after, .fa-duotone.fa-cny:after {
  content: "\a5\fe02"; }

.fad.fa-jpy:before, .fa-duotone.fa-jpy:before {
  content: "\a5\fe01"; }

.fad.fa-jpy:after, .fa-duotone.fa-jpy:after {
  content: "\a5\fe02"; }

.fad.fa-rmb:before, .fa-duotone.fa-rmb:before {
  content: "\a5\fe01"; }

.fad.fa-rmb:after, .fa-duotone.fa-rmb:after {
  content: "\a5\fe02"; }

.fad.fa-yen:before, .fa-duotone.fa-yen:before {
  content: "\a5\fe01"; }

.fad.fa-yen:after, .fa-duotone.fa-yen:after {
  content: "\a5\fe02"; }

.fad.fa-yin-yang:before, .fa-duotone.fa-yin-yang:before {
  content: "\262f\fe01"; }

.fad.fa-yin-yang:after, .fa-duotone.fa-yin-yang:after {
  content: "\262f\fe02"; }

.fad.fa-z:before, .fa-duotone.fa-z:before {
  content: "\5a\fe01"; }

.fad.fa-z:after, .fa-duotone.fa-z:after {
  content: "\5a\fe02"; }
@font-face {
  font-family: 'Font Awesome 6 Pro light';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../webfonts/fa-light-300.woff2") format("woff2"), url("../webfonts/fa-light-300.woff") format("woff"), url("../webfonts/fa-light-300.ttf") format("truetype"); }

.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro light';
  font-weight: 600; }
@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-regular-400.woff2") format("woff2"), url("../webfonts/fa-regular-400.woff") format("woff"), url("../webfonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }
.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 100; }
